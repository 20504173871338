import { Form, Row, Col, Button, Container } from 'react-bootstrap';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_PATHOLOGY } from '../../../graphql/mutations';
import { QUERY_PENDING_PATHOLOGY } from '../../../graphql/queries';
import Spinner from '../../Spinner';
import { AiFillPlusCircle } from 'react-icons/ai';
import swal from 'sweetalert';
import AWS from 'aws-sdk';
import uniqid from 'uniqid';
import { FiPlus, FiMinus } from "react-icons/fi";

const ID = process.env.REACT_APP_AWS_ACCESSS_ID;
const SECRET = process.env.REACT_APP_AWS_ACCESSS_KEY;
const BUCKET_NAME = 'bharp-hospital';

const s3 = new AWS.S3({
    accessKeyId: ID,
    secretAccessKey: SECRET,
});

let stateArray = ['Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jammu and Kashmir', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttarakhand', 'Uttar Pradesh', 'West Bengal', 'Andaman and Nicobar Islands', 'Chandigarh', 'Dadra and Nagar Haveli', 'Daman and Diu', 'Delhi', 'Lakshadweep', 'Puducherry'];
const imageKey = []
const andhraPradeshDistrictArray = ["Ananthapur", "Chittoor", "Cuddapah", "East Godavari", "Guntur", "Krishna", "Kurnool", "Nellore", "Prakasam", "Srikakulam", "Visakhapatnam", "Vizianagaram", "West Godavari"];
const andamanAndNicobarIslandsDistrictArray = ["Nicobar", "North And Middle Andaman", "South Andaman"];
const arunachalPradeshDistrictArray = ["Changlang", "Dibang Valley", "East Kameng", "East Siang", "Kurung Kumey", "Lohit", "Lower Dibang Valley", "Lower Subansiri", "Papum Pare", "Tawang", "Tirap", "Upper Siang", "Upper Subansiri", "West Kameng", "West Siang"];
const assamDistrictArray = ["Barpeta", "Bongaigaon", "Cachar", "Darrang", "Dhemaji", "Dhubri", "Dibrugarh", "Goalpara", "Golaghat", "Hailakandi", "Jorhat", "Kamrup", "Karbi Anglong", "Karimganj", "Kokrajhar", "Lakhimpur", "Marigaon", "Nagaon", "Nalbari", "North Cachar Hills", "Sibsagar", "Sonitpur", "Tinsukia"];
const biharDistrictArray = ["Araria", "Arwal", "Aurangabad(BH)", "Banka", "Begusarai", "Bhagalpur", "Bhojpur", "Buxar", "Darbhanga", "East Champaran", "Gaya", "Gopalganj", "Jamui", "Jehanabad", "Kaimur (Bhabua)", "Katihar", "Khagaria", "Kishanganj", "Lakhisarai", "Madhepura", "Madhubani", "Munger", "Muzaffarpur", "Nalanda", "Nawada", "Patna", "Purnia", "Rohtas", "Saharsa", "Samastipur", "Saran", "Sheikhpura", "Sheohar", "Sitamarhi", "Siwan", "Supaul", "Vaishali", "West Champaran"];
const chandigarhDistrictArray = ["Chandigarh"];
const chhattisgarhDistrictArray = ["Bastar", "Bijapur(CGH)", " Bilaspur(CGH)", "Dantewada", "Dhamtari", "Durg", "Gariaband", "Janjgir-champa", "Jashpur", "Kanker", "Kawardha", "Korba", "Koriya", "Mahasamund", "Narayanpur", "Raigarh", "Raipur", "Rajnandgaon", "Surguja"];
const dadarAndNagarHaveliDistrictArray = ["Dadra & Nagar Haveli"];
const damanAndDiuDistrictArray = ["Daman", "Diu"];
const delhiDistrictArray = ["Central Delhi", "East Delhi", "New Delhi", "North Delhi", "North East Delhi", "North West Delhi", " South Delhi", "South West Delhi", "West Delhi"];
const LakshadweepDistrictArray = ["North Goa", "South Goa"];
const puducherryDistrictArray = ["Karaikal", "Mahe", "Pondicherry"];
const goaDistrictArray = ["North Goa", "South Goa"];
const gujaratDistrictArray = ["Ahmedabad", "Amreli", "Anand", "Banaskantha", "Bharuch", "Bhavnagar", "Dahod", "Gandhi Nagar", "Jamnagar", "Junagadh", "Kachchh", "Kheda", "Mahesana", "Narmada", "Navsari", "Panch Mahals", "Patan", "Porbandar", "Rajkot", "Sabarkantha", "Surat", "Surendra Nagar", "Tapi", "The Dangs", "Vadodara", "Valsad"];
const jammuAndKashmirDistrictArray = ["Ananthnag", "Bandipur", "Baramulla", "Budgam", "Doda", "Jammu", "Kargil", "Kathua", "Kulgam", "Kupwara", "Leh", "Poonch", "Pulwama", "Rajauri", "Reasi", "Shopian", "Srinagar", "Udhampur"];
const himachalPradeshDistrictArray = ["Bilaspur(HP)", "Chamba", "Hamirpur(HP)", "Kangra", "Kinnaur", "Kullu", "Lahul & Spiti", "Mandi", "Shimla", "Sirmaur", "Solan", "Una"];
const jharkhandDistrictArray = ["Bokaro", "Chatra", "Deoghar", "Dhanbad", "Dumka", "East Singhbhum", "Garhwa", "Giridh", "Godda", "Gumla", "Hazaribag", "Jamtara", "Khunti", "Koderma", "Latehar", "Lohardaga", "Pakur", "Palamau", "Ramgarh", "Ranchi", "Sahibganj", "Seraikela - kharsawan", "Simdega", "West Singhbhum"];
const karnatakaDistrictArray = ["Bagalkot", "Bangalore", "Rural", "Belgaum", "Bellary", "Bidar", "Bijapur(KAR)", "Chamrajnagar", "Chickmagalur", "Chikkaballapur", "Chitradurga", "Dakshina Kannada", "Davangere", "Dharwad", "Gadag", "Gulbarga", "Hassan", "Haveri", "Kodagu", "Kolar", "Koppal", "Mandya", "Mysore", "Raichur", "Ramanagar", "Shimoga", "Tumkur", "Udupi", "Uttara Kannada", "Yadgir"];
const keralaDistrictArray = ["Alappuzha", "Ernakulam", "Idukki", "Kannur", "Kasargod", "Kollam", "Kottayam", "Kozhikode", "Malappuram", "Palakkad", "Pathanamthitta", "Thiruvananthapuram", "Thrissur", "Wayanad"];
const madhyaPradeshDistrictArray = ["Alirajpur", "Anuppur", "Ashok Nagar", "Balaghat", "Barwani", "Betul", "Bhind", "Bhopal", "Burhanpur", "Chhatarpur", "Damoh", "Datia", "Dewas", "Dhar", "Dindori", "East Nimar", "Guna", "Gwalior", "Harda", "Hoshangabad", "Indore", "Jabalpur", "Jhabua", "Katni", "Khandwa", "Khargone", "Mandla", "Mandsaur", "Morena", "Narsinghpur", "Neemuch", "Panna", "Raisen", "Rajgarh", "Ratlam", "Rewa", "Sagar", "Satna", "Sehore", "Seoni", "Shahdol", "Shajapur", "Sheopur", "Shivpuri", "Sidhi", "Singrauli", "Tikamgarh", "Ujjain", "Umaria", "Vidisha", "West Nimar"];
const maharashtraDistrictArray = ["Ahmed Nagar", "Akola", "Amravati", "Aurangabad", "Beed", "Bhandara", "Buldhana", "Chandrapur", "Dhule", "Gadchiroli", "Gondia", "Hingoli", "Jalgaon", "Jalna", "Kolhapur", "Latur", "Mumbai", "Nagpur", "Nanded", "Nandurbar", "Nashik", "Osmanabad", "Parbhani", "Pune", "Raigarh(MH)", "Ratnagiri", "Sangli", "Satara", "Sindhudurg", "Solapur", "Thane", "Wardha", "Washim", "Yavatmal"];
const manipurDistrictArray = ["Bishnupur", "Chandel", "Churachandpur", " Imphal East", "Imphal West", "Senapati", "Tamenglong", "Thoubal", "Ukhrul"];
const meghalayaDistrictArray = ["East Garo Hills", "East Khasi Hills", "Jaintia Hills", "Ri Bhoi", "South Garo Hills", "West Garo Hills", "West Khasi Hills"];
const mizoramDistrictArray = ["Aizawl", "Champhai", "Kolasib", "Lawngtlai", "Lunglei", "Mammit", "Saiha", "Serchhip"];
const nagalandDistrictArray = ["Dimapur", "Kiphire", "Kohima", "Longleng", "Mokokchung", "Mon", "Peren", "Phek", "Tuensang", "Wokha", "Zunhebotto"];
const odishaDistrictArray = ["Angul", "Balangir", "Baleswar", "Bargarh", "Bhadrak", "Boudh", "Cuttack", "Debagarh", "Dhenkanal", "Gajapati", "Ganjam", "Jagatsinghapur", "Jajapur", "Jharsuguda", "Kalahandi", "Kandhamal", "Kendrapara", "Kendujhar", "Khorda", "Koraput", "Malkangiri", "Mayurbhanj", "Nabarangapur", "Nayagarh", "Nuapada", "Puri", "Rayagada", "Sambalpur", "Sonapur", "Sundergarh"];
const punjabDistrictArray = ["Amritsar", "Barnala", "Bathinda", "Faridkot", "Fatehgarh Sahib", "Fazilka", "Firozpur", "Gurdaspur", "Hoshiarpur", "Jalandhar", "Kapurthala", "Ludhiana", "Mansa", "Moga", "Mohali", "Muktsar", "Nawanshahr", "Pathankot", "Patiala", "Ropar", "Rupnagar", "Sangrur", "Tarn Taran"];
const rajasthanDistrictArray = ["Ajmer", "Alwar", "Banswara", "Baran", "Barmer", "Bharatpur", "Bhilwara", "Bikaner", "Bundi", "Chittorgarh", "Churu", "Dausa", "Dholpur", "Dungarpur", "Ganganagar", "Hanumangarh", "Jaipur", "Jaisalmer", "Jalor", "Jhalawar", "Jhujhunu", "Jodhpur", "Karauli", "Kota", "Nagaur", "Pali", "Rajsamand", "Sikar", "Sawai Madhopur", "Sirohi", "Tonk", "Udaipur"];
const sikkimDistrictArray = ["East Sikkim", "North Sikkim", "South Sikkim", "West Sikkim"];
const tamilNaduDistrictArray = ["Ariyalur", "Chennai", "Coimbatore", "Cuddalore", "Dharmapuri", "Dindigul", "Erode", "Kanchipuram", "Kanyakumari", "Karur", "Krishnagiri", "Madurai", "Nagapattinam", "Namakkal", "Nilgiris", "Perambalur", "Pudukkottai", "Ramanathapuram", "Salem", "Sivaganga", "Thanjavur", "Theni", "Tiruchirappalli", "Tirunelveli", "Tiruvallur", "Tiruvannamalai", "Tiruvarur", "Tuticorin", "Vellore", "Villupuram", "Virudhunagar"];
const telanganaDistrictArray = ["Adilabad", "Hyderabad", "K.V.Rangareddy", "Karim Nagar", "Khammam", "Mahabub Nagar", "Medak", "Nalgonda", "Nizamabad", "Warangal"];
const tripuraDistrictArray = ["Dhalai", "North Tripura", "South Tripura", "West Tripura"];
const uttarPradeshDistrictArray = ["Agra", "Aligarh", "Allahabad", "Ambedkar Nagar", "Auraiya", "Azamgarh", "Bagpat", "Bahraich", "Ballia", "Balrampur", "Banda", "Barabanki", "Bareilly", "Basti", "Bijnor", "Budaun", "Bulandshahr", "Chandauli", "Chitrakoot", "Deoria", "Etah", "Etawah", "Faizabad", "Farrukhabad", "Fatehpur", "Firozabad", "Gautam Buddha Nagar", "Ghaziabad", "Ghazipur", "Gonda", "Gorakhpur", "Hamirpur", "Hardoi", "Hathras", "Jalaun", "Jaunpur", "Jhansi", "Jyotiba Phule Nagar", "Kannauj", "Kanpur Dehat", "Kanpur Nagar", "Kaushambi", "Kheri", "Kushinagar", "Lalitpur", "Lucknow", "Maharajganj", "Mahoba", "Mainpuri", "Mathura", "Mau", "Meerut", "Mirzapur", "Moradabad", "Muzaffarnagar", "Pilibhit", "Pratapgarh", "Raebareli", "Rampur", "Saharanpur", "Sant Kabir Nagar", "Sant Ravidas Nagar", "Shahjahanpur", "Shrawasti", "Siddharthnagar", "Sitapur", "Sonbhadra", "Sultanpur", "Unnao", "Varanasi"];
const uttarakhandDistrictArray = ["Almora", "Bageshwar", "Chamoli", "Champawat", "Dehradun", "Haridwar", "Nainital", "Pauri Garhwal", "Pithoragarh", "Rudraprayag", "Tehri Garhwal", "Udham Singh Nagar", "Uttarkashi"];
const westBengalDistrictArray = ["Bankura", "Bardhaman", "Birbhum", "Cooch Behar", "Darjiling", "East Midnapore", "Hooghly", "Howrah", "Jalpaiguri", "Kolkata", "Malda", "Medinipur", "Murshidabad", "Nadia", "North 24 Parganas", "North Dinajpur", "Puruliya", "South 24 Parganas", "South Dinajpur", "West Midnapore",];

export default function AddPathology() {
    let counter = 0;
    const [validated, setValidated] = useState(false);
    const [addPathologyMutation, { loading }] = useMutation(CREATE_PATHOLOGY, {
        refetchQueries: [
            QUERY_PENDING_PATHOLOGY
        ]
    });

    const [centerName, setCenterName] = useState('');
    const [centerAddress, setCenterAddress] = useState('');
    const [villageName, setVillageName] = useState('');
    const [state, setState] = useState('');
    const [district, setDistrict] = useState('');
    const [pincode, setPincode] = useState('');
    const [contact, setContact] = useState('');
    const [email, setEmail] = useState('');
    const [websiteLink, setWebsiteLink] = useState('');
    const [publicSector, setPublicSector] = useState('');
    const [publicSectorOther, setPublicSectorOther] = useState('');
    const [privateSector, setPrivateSector] = useState('');
    const [privateSectorOther, setPrivateSectorOther] = useState('');
    const [registrationNo, setRegistrationNo] = useState('');
    const [ownerName, setOwnerName] = useState('');
    const [ownerContact, setOwnerContact] = useState('');
    const [ownerEmail, setOwnerEmail] = useState('');
    const [latitude, setLatitude] = useState('');
    const [bharpDiscount, setBharpDiscount] = useState('');
     const [vendorMargin, setVendorMargin] = useState('');
    const [longitude, setLongitude] = useState('');
    const [licenseBy, setLicenseBy] = useState([]);
    const [services, setServices] = useState([]);
    const [pathologistDetails, setPathologistDetails] = useState([{ name: '', qualification: '', experience: '' }]);

    const [selectedPathologyImg, setSelectedPathologyImg] = useState(null);
    const [pathologyImgLoading, setPathologyImgLoading] = useState(false);
    const [pathologyImgDone, setPathologyImgDone] = useState(false);
    const [pathologyImgKey, setPathologyImgKey] = useState('');
    const [homeCollectionAvailability,setHomeCollectionAvailability] =useState("")
    const [serviceTypeState, setServiceTypeState] = useState([]);
    const [serviceTypeStateOther, setServiceTypeStateOther] = useState([]);
    const [otherLicence, setOtherLicence] = useState([])


    const [checkedLiverFunction, setCheckedLiverFunction] = useState(false);

    const [checkedCompleteBloodCount, setCheckedCompleteBloodCount] = useState(false);

    const [checkedCholesterol, setCheckedCholesterol] = useState(false);

    const [checkedBloodTest, setCheckedBloodTest] = useState(false);

    const [checkedCreactiveProtein, setCheckedCreactiveProtein] = useState(false);

    const [checkedBloodSuger, setCheckedBloodSuger] = useState(false);

    const [checkedPapSmear, setCheckedPapSmear] = useState(false);

    const [checkedArterialBloodGas, setCheckedArterialBloodGas] = useState(false);

    const [checkedBloodCulture, setCheckedBloodCulture] = useState(false);

    const [checkedThromboplastic, setCheckedThromboplastic] = useState(false);

    const [checkedGlucoseTolerance, setCheckedGlucoseTolerance] = useState(false);

    const [checkedMonteux, setCheckedMonteux] = useState(false);

    const [checkedChloride, setCheckedChloride] = useState(false);

    const [checkedCreatinine, setCheckedCreatinine] = useState(false);

    const [checkedBetaHcg, setCheckedBetaHcg] = useState(false);

    const [checkedLumbarPuncture, setCheckedLumbarPuncture] = useState(false);

    const [checkedGastricFluidAnalysis, setCheckedGastricFluidAnalysis] = useState(false);

    const [checkedKidneyFunction, setCheckedKidneyFunction] = useState(false);

    const [checkedMalabsorption, setCheckedMalabsorption] = useState(false);

    const [checkedPhenolsulfonphthalein, setCheckedPhenolsulfonphthalein] = useState(false);

    const [checkedPregancy, setCheckedPregancy] = useState(false);

    const [checkedPrenatal, setCheckedPrenatal] = useState(false);

    const [checkedSyphilis, setCheckedSyphilis] = useState(false);

    const [checkedThyroidFunction, setCheckedThyroidFunction] = useState(false);

    const [checkedToxicology, setCheckedToxicology] = useState(false);

    const [checkedUrine, setCheckedUrine] = useState(false);

    const [checkedComplementation, setCheckedComplementation] = useState(false);

    const [checkedFluorecenceInSituHybridization, setCheckedFluorecenceInSituHybridization] = useState(false);

    const [checkedPreimplantationGeneticDiagnosis, setCheckedPreimplantationGeneticDiagnosis] = useState(false);

    const [checkedSkinTest, setCheckedSkinTest] = useState(false);

    const [checkedSemenAnalysis, setCheckedSemenAnalysis] = useState(false);

    const [checkedRubinTest, setCheckedRubinTest] = useState(false);


    const [liverFunctionTestPrice, setLiverFunctionTestPrice] = useState('');
    const [checkCompleteBloodCountPrice, setCheckCompleteBloodCountPrice] = useState('');
    const [checkCholesterolTestPrice, setCheckCholesterolTestPrice] = useState('');
    const [checkBloodTestPrice, setCheckBloodTestPrice] = useState('');
    const [checkCreactiveProteinPrice, setCheckCreactiveProteinPrice] = useState('');
    const [checkBloodSugerPrice, setCheckBloodSugerPrice] = useState('');
    const [checkPapSmearPrice, setCheckPapSmearPrice] = useState('');
    const [checkArterialBloodGasPrice, setCheckArterialBloodGasPrice] = useState('');
    const [checkBloodCulturePrice, setCheckBloodCulturePrice] = useState('');
    const [checkThromboplasticPrice, setCheckThromboplasticPrice] = useState('');
    const [checkGlucoseTolerancePrice, setCheckGlucoseTolerancePrice] = useState('');
    const [checkMonteuxPrice, setCheckMonteuxPrice] = useState('');
    const [checkChloridePrice, setCheckChloridePrice] = useState('');
    const [checkCreatininePrice, setCheckCreatininePrice] = useState('');
    const [checkBetaHcgPrice, setCheckBetaHcgPrice] = useState('');
    const [checkLumbarPuncturePrice, setCheckLumbarPuncturePrice] = useState('');
    const [checkGastricFluidAnalysisPrice, setCheckGastricFluidAnalysisPrice] = useState('');
    const [checkKidneyFunctionPrice, setCheckKidneyFunctionPrice] = useState('');
    const [checkMalabsorptionPrice, setCheckMalabsorptionPrice] = useState('');
    const [checkPhenolsulfonphthaleinPrice, setCheckPhenolsulfonphthaleinPrice] = useState('');
    const [checkPregnancyTestPrice, setCheckPregnancyTestPrice] = useState('');
    const [checkPrenatalTestPrice, setCheckPrenatalTestPrice] = useState('');
    const [checkSyphilisTestPrice, setCheckSyphilisTestPrice] = useState('');
    const [checkThyroidFunctionPrice, setCheckThyroidFunctionPrice] = useState('');
    const [checkToxicologyTestPrice, setCheckToxicologyTestPrice] = useState('');
    const [checkUrineTestPrice, setCheckUrineTestPrice] = useState('');
    const [checkComplementationTestPrice, setCheckComplementationTestPrice] = useState('');
    const [checkFluorescenceInSituHybridizationPrice, setCheckFluorescenceInSituHybridizationPrice] = useState('');
    const [checkPreimplantationGeneticDiagnosisPrice, setCheckPreimplantationGeneticDiagnosisPrice] = useState('');
    const [checkSkinTestPrice, setCheckSkinTestPrice] = useState('');
    const [checkSemenAnalysisPrice, setCheckSemenAnalysisPrice] = useState('');
    const [checkRubinTestPrice, setCheckRubinTestPrice] = useState('');

    const [facilitiesState, setFacilitiesState] = useState([]);
    const [mFacilitiesState, setMfacilitiesState] = useState([]);
    const [acceptanceState, setAcceptancesState] = useState([]);

    const [selectImage, setSelectImage] = useState()
    const [imageBox, setImageBox] = useState()
    const [uploadDone, setUploadDone] = useState(false)
    const [uploadLoading, setUploadLoading] = useState(false)
    const [multipleImgKey, setMultipleImgKey] = useState([])

    function multipleImg(e) {
        setSelectImage([...e.target.files])
    }



    console.log("facilitiestate", facilitiesState)

    const handleChangeforFacilities = (event) => {
        const { checked, value } = event.currentTarget;

        if (value === 'Liver function test') {
            setCheckedLiverFunction(checked);
        } else if (value === 'Complete blood count') {
            setCheckedCompleteBloodCount(checked)
        } else if (value === 'Cholesterol test') {
            setCheckedCholesterol(checked)
        } else if (value === 'Blood test') {
            setCheckedBloodTest(checked)
        } else if (value === 'C-reactive protein test') {
            setCheckedCreactiveProtein(checked)
        } else if (value === 'Blood sugar test') {
            setCheckedBloodSuger(checked)
        } else if (value === 'Pap smear') {
            setCheckedPapSmear(checked)
        } else if (value === 'Arterial blood gas test') {
            setCheckedArterialBloodGas(checked)
        } else if (value === 'Blood culture') {
            setCheckedBloodCulture(checked)
        } else if (value === 'Thromboplastic test') {
            setCheckedThromboplastic(checked)
        } else if (value === 'Glucose tolerance test') {
            setCheckedGlucoseTolerance(checked)
        } else if (value === 'Monteux test') {
            setCheckedMonteux(checked)
        } else if (value === 'Chloride test') {
            setCheckedChloride(checked)
        } else if (value === 'Creatinine test') {
            setCheckedCreatinine(checked)
        } else if (value === 'Beta hCG test') {
            setCheckedBetaHcg(checked)
        } else if (value === 'Lumbar puncture') {
            setCheckedLumbarPuncture(checked)
        } else if (value === 'Gastric fluid analysis') {
            setCheckedGastricFluidAnalysis(checked)
        } else if (value === 'Kidney function test') {
            setCheckedKidneyFunction(checked)
        } else if (value === 'Malabsorption test') {
            setCheckedMalabsorption(checked)
        } else if (value === 'Phenolsulfonphthalein test') {
            setCheckedPhenolsulfonphthalein(checked)
        } else if (value === 'Pregnancy test') {
            setCheckedPregancy(checked)
        } else if (value === 'Prenatal test') {
            setCheckPrenatalTestPrice("")
        } else if (value === 'Syphilis test') {
            setCheckedSyphilis(checked)
        } else if (value === 'Thyroid function test') {
            setCheckedThyroidFunction(checked)
        } else if (value === 'Toxicology test') {
            setCheckedToxicology(checked)
        } else if (value === 'Urine test') {
            setCheckedUrine(checked)
        } else if (value === 'Complementation test') {
            setCheckedComplementation(checked)
        } else if (value === 'Fluorescence in situ hybridization') {
            setCheckedFluorecenceInSituHybridization(checked)
        } else if (value === 'Preimplantation genetic diagnosis') {
            setCheckedPreimplantationGeneticDiagnosis(checked)
        } else if (value === 'Skin test') {
            setCheckedSkinTest(checked)
        } else if (value === 'Semen analysis') {
            setCheckedSemenAnalysis(checked)
        } else if (value === 'Rubin’s test') {
            setCheckedRubinTest(checked)
        }

        else {
            alert('Somthing error!!!')
        }




        if (checked === true) {
            if (value === 'Liver function test') {
                setLiverFunctionTestPrice("");
            }

            else if (value === 'Complete blood count') {
                setCheckCompleteBloodCountPrice("")

            } else if (value === 'Cholesterol test') {
                setCheckCholesterolTestPrice("")
            } else if (value === 'Blood test') {
                setCheckBloodTestPrice("")
            } else if (value === 'C-reactive protein test') {
                setCheckCreactiveProteinPrice("")
            } else if (value === 'Blood sugar test') {
                setCheckBloodSugerPrice("")
            } else if (value === 'Pap smear') {
                setCheckPapSmearPrice("")
            } else if (value === 'Arterial blood gas test') {
                setCheckArterialBloodGasPrice("")
            } else if (value === 'Blood culture') {
                setCheckBloodCulturePrice("")
            } else if (value === 'Thromboplastic test') {
                setCheckThromboplasticPrice("")
            } else if (value === 'Glucose tolerance test') {
                setCheckGlucoseTolerancePrice("")
            } else if (value === 'Monteux test') {
                setCheckMonteuxPrice("")
            } else if (value === 'Chloride test') {
                setCheckChloridePrice("")
            } else if (value === 'Creatinine test') {
                setCheckCreatininePrice("")
            } else if (value === 'Beta hCG test') {
                setCheckBetaHcgPrice("")
            } else if (value === 'Lumbar puncture') {
                setCheckLumbarPuncturePrice("")
            } else if (value === 'Gastric fluid analysis') {
                setCheckGastricFluidAnalysisPrice("")
            } else if (value === 'Kidney function test') {
                setCheckKidneyFunctionPrice("")
            } else if (value === 'Malabsorption test') {
                setCheckMalabsorptionPrice("")
            } else if (value === 'Phenolsulfonphthalein test') {
                setCheckPhenolsulfonphthaleinPrice("")
            } else if (value === 'Pregnancy test') {
                setCheckPregnancyTestPrice("")
            } else if (value === 'Prenatal test') {
                setCheckPrenatalTestPrice("")
            } else if (value === 'Syphilis test') {
                setCheckSyphilisTestPrice("")
            } else if (value === 'Thyroid function test') {
                setCheckThyroidFunctionPrice("")
            } else if (value === 'Toxicology test') {
                setCheckToxicologyTestPrice("")
            } else if (value === 'Urine test') {
                setCheckUrineTestPrice("")
            } else if (value === 'Complementation test') {
                setCheckComplementationTestPrice("")
            } else if (value === 'Fluorescence in situ hybridization') {
                setCheckFluorescenceInSituHybridizationPrice("")
            } else if (value === 'Preimplantation genetic diagnosis') {
                setCheckPreimplantationGeneticDiagnosisPrice("")
            } else if (value === 'Skin test') {
                setCheckSkinTestPrice("")
            } else if (value === 'Semen analysis') {
                setCheckSemenAnalysisPrice("")
            } else if (value === 'Rubin’s test') {
                setCheckRubinTestPrice("")
            }

            else {
                alert('Somthing error!!!')
            }

            setFacilitiesState(current => [...current, { serviceName: value, servicePrice: "" }]);
        }
        if (checked === false) {
            setFacilitiesState(current =>
                current.filter(element => {
                    return element.serviceName !== value;
                }),
            );
        }
    };


    const handleChangeforFacilitiesPrice = (price, name) => {
        facilitiesState.filter(elempricet => {
            if (name === 'Liver function test') {
                setLiverFunctionTestPrice(price)
            }

            else if (name === 'Complete blood count') {
                setCheckCompleteBloodCountPrice(price)
            } else if (name === 'Cholesterol test') {
                setCheckCholesterolTestPrice(price)
            } else if (name === 'Blood test') {
                setCheckBloodTestPrice(price)
            } else if (name === 'C-reactive protein test') {
                setCheckCreactiveProteinPrice(price)
            } else if (name === 'Blood sugar test') {
                setCheckBloodSugerPrice(price)
            } else if (name === 'Pap smear') {
                setCheckPapSmearPrice(price)
            } else if (name === 'Arterial blood gas test') {
                setCheckArterialBloodGasPrice(price)
            } else if (name === 'Blood culture') {
                setCheckBloodCulturePrice(price)
            } else if (name === 'Thromboplastic test') {
                setCheckThromboplasticPrice(price)
            } else if (name === 'Glucose tolerance test') {
                setCheckGlucoseTolerancePrice(price)
            } else if (name === 'Monteux test') {
                setCheckMonteuxPrice(price)
            } else if (name === 'Chloride test') {
                setCheckChloridePrice(price)
            } else if (name === 'Creatinine test') {
                setCheckCreatininePrice(price)
            } else if (name === 'Beta hCG test') {
                setCheckBetaHcgPrice(price)
            } else if (name === 'Lumbar puncture') {
                setCheckLumbarPuncturePrice(price)
            } else if (name === 'Gastric fluid analysis') {
                setCheckGastricFluidAnalysisPrice(price)
            } else if (name === 'Kidney function test') {
                setCheckKidneyFunctionPrice(price)
            } else if (name === 'Malabsorption test') {
                setCheckMalabsorptionPrice(price)
            } else if (name === 'Phenolsulfonphthalein test') {
                setCheckPhenolsulfonphthaleinPrice(price)
            } else if (name === 'Pregnancy test') {
                setCheckPregnancyTestPrice(price)
            } else if (name === 'Prenatal test') {
                setCheckPrenatalTestPrice(price)
            }
            else if (name === 'Syphilis test') {
                setCheckSyphilisTestPrice(price)
            } else if (name === 'Thyroid function test') {
                setCheckThyroidFunctionPrice(price)
            } else if (name === 'Toxicology test') {
                setCheckToxicologyTestPrice(price)
            } else if (name === 'Urine test') {
                setCheckUrineTestPrice(price)
            } else if (name === 'Complementation test') {
                setCheckComplementationTestPrice(price)
            } else if (name === 'Fluorescence in situ hybridization') {
                setCheckFluorescenceInSituHybridizationPrice(price)
            } else if (name === 'Preimplantation genetic diagnosis') {
                setCheckPreimplantationGeneticDiagnosisPrice(price)
            } else if (name === 'Skin test') {
                setCheckSkinTestPrice(price)
            } else if (name === 'Semen analysis') {
                setCheckSemenAnalysisPrice(price)
            } else if (name === 'Rubin’s test') {
                setCheckRubinTestPrice(price)
            }


            else {
                alert('Somthing error!!!')
            }
            const newState = facilitiesState.map(obj => {
                console.log("obj", obj)
                if (obj.serviceName === name) {
                    return { ...obj, servicePrice: price };
                }
                return obj;
            });
            setFacilitiesState(newState);
        });
    };






    let addServiceTypeField = () => {
        setServiceTypeStateOther([...serviceTypeStateOther, { serviceName: "", servicePrice: "" }]);
    };

    let removeServiceTypeField = (i) => {
        let newServiceFormValues = [...serviceTypeStateOther];
        newServiceFormValues.splice(i, 1);
        setServiceTypeStateOther(newServiceFormValues);
    };

    let handleServiceTypeChange = (e, i, nameValue) => {
        console.log("e", e)
        let newServiceFormValues = [...serviceTypeStateOther];
        newServiceFormValues[i][nameValue] = e;
        setServiceTypeStateOther(newServiceFormValues);
    };

    function mergeClick() {
        setFacilitiesState(prev => [...prev, ...serviceTypeStateOther])
        setServiceTypeStateOther([])
        alert('Service Added Successfully...')
    }


    // license other
    let addLicenseTypeField = () => {
        setOtherLicence([...otherLicence, ""]);
    };

    let removeLicenseField = (i) => {
        let newServiceFormValues = [...otherLicence];
        newServiceFormValues.splice(i, 1);
        setOtherLicence(newServiceFormValues);
    };

    console.log("jhjh", licenseBy)
    let handleLicenceChange = (e, i, nameValue) => {
        console.log("e", e)
        setOtherLicence(e)
    };

    function mergelicenseClick() {
        setLicenseBy(prev => [...prev, ...otherLicence])
        setOtherLicence([])
        alert('Service Added Successfully...')
    }

    console.log(otherLicence)

    let addFormFields = () => {
        setPathologistDetails([...pathologistDetails, { name: '', qualification: '', experience: '' }]);
    };

    let handleChange = (e, i, nameValue) => {
        let newFormValues = [...pathologistDetails];
        newFormValues[i][nameValue] = e;
        setPathologistDetails(newFormValues);
    };


    const handleSubmit = (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            e.preventDefault();
            e.stopPropagation();
        }
        else {
            e.preventDefault();
            addPathologyMutation({
                variables: {
                    "pathologyInput": {
                        "nameOfCenter": `${centerName}`,
                        "centerAddress": `${centerAddress}`,
                        "villageName": `${villageName}`,
                        "state": `${state}`,
                        "district": `${district}`,
                        "pincode": `${pincode}`,
                        "contact": `${contact}`,
                        "email": `${email}`,
                        "websiteLink": `${websiteLink}`,
                        "ownerName": `${ownerName}`,
                        "ownerContact": `${ownerContact}`,
                        "ownerEmail": `${ownerEmail}`,
                        "picture": imageKey,
                        "bharpDiscount":`${bharpDiscount}`,
                        "vendorMargin": `${vendorMargin}`,
                        "pathologistDetail": pathologistDetails,
                        "privateSectorCentralGov": `${privateSector}`,
                        "publicSectorOther": `${publicSectorOther}`,
                        "privateSectorIndividual": `${publicSector}`,
                        "privateSectorOther": `${privateSectorOther}`,
                        "registrationNo": `${registrationNo}`,
                        "lisenceBy": licenseBy,
                        "services": facilitiesState,
                        "latitude": `${latitude}`,
                        "longitude": `${longitude}`,
                        "homeCollectionAvailability": `${homeCollectionAvailability}`,
                        "agentId": null,
                        "rejectStatus": "false",
                        "rejectComment": "",
                    }
                },
            });

            setValidated(false);
            swal({ title: 'Successfull!!!', text: 'Pathology Add Successfully', icon: 'success' });
            setCenterName('');
            setCenterAddress('');
            setVillageName('');
            setState('');
            setDistrict('');
            setPincode('');
            setContact('');
            setEmail('');
            setWebsiteLink('');
            setPublicSector('');
            setPrivateSector('');
            setRegistrationNo('');
            setOwnerName('');
            setOwnerContact('');
            setOwnerEmail('');
            setImageBox("")
            setLatitude('');
            setLongitude('');
            setLicenseBy([]);
            setServices([]);
            setBharpDiscount("")
            setVendorMargin("")
            setSelectedPathologyImg(null);
            setPathologyImgLoading(false)
            setPathologyImgDone(false)
            setPathologyImgKey('')
            setPublicSectorOther('')
            setPrivateSectorOther('')
            setPathologistDetails([{ name: '', qualification: '', experience: '' }]);
        }
    };

    const handleChangeLicenseChange = (event) => {
        const { checked, value } = event.currentTarget;
        setLicenseBy((prev) =>
            checked ? [...prev, value] : prev.filter((val) => val !== value)
        );
    };
    const handleChangeServicesChange = (event) => {
        const { checked, value } = event.currentTarget;
        setServices((prev) =>
            checked ? [...prev, value] : prev.filter((val) => val !== value)
        );
    };

    const handlePathologyImage = (e) => {
        try {
            selectImage.map(async (fileData) => {
                const uniqueId = uniqid();
                var regex = new RegExp('[^.]+$');
                var extension = fileData.name.match(regex);
                var uniqueFileName = "Bharp" + '-' + uniqueId + '-' + uniqueId + '.' + extension[0];
                imageKey.push(uniqueFileName)
                const params = {
                    Bucket: BUCKET_NAME,
                    Key: uniqueFileName,
                    Body: fileData,
                };
                s3.upload(params, function (err, data) {
                    if (err) {
                        throw err;
                    } else {
                        setImageBox(uniqueFileName)
                        swal({
                            title: 'Successfull!!!',
                            text: ' Uploaded Successfully',
                            icon: 'success',
                        });
                        setMultipleImgKey([])
                        setSelectImage('')
                        setUploadLoading(false)
                        setUploadDone(true)
                    }
                });
            })
        } catch (err) {
            swal({
                title: 'Error!!!',
                text: 'Error please try again',
                icon: 'error',
            });
            console.log(err);
            setUploadLoading(false)
        }

    };

    return (
        <>
            <div style={{ marginTop: '30px' }}>
                <Container>
                    <h1 style={{ fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                        Add Pathology
                    </h1>
                    <hr />
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>

                        {/*----------------- Pathology Details ---------------------*/}
                        <Row>
                            <h4 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
                                Center Details
                                <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '19%', marginTop: 10 }}>
                                    {' '}
                                </div>
                            </h4>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Center Name</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setCenterName(e.target.value)} value={centerName} placeholder="Center Name" />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Center Address</Form.Label>
                                    <Form.Control required as="textarea" rows={1} style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setCenterAddress(e.target.value)} value={centerAddress} placeholder="Center Address" />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Village Name</Form.Label>
                                    <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setVillageName(e.target.value)} value={villageName} placeholder="Village Name" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>State</Form.Label>
                                    <Form.Select required style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setState(e.target.value)} value={state}>
                                        <option value="" selected="selected" disabled="disabled">Select State</option>
                                        {stateArray.map((d) => (
                                            <option value={d}>{d}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            {
                                state === '' ?
                                    <Col md={6} lg={4} className="mt-5">
                                        <Form.Group controlId="hospitalState">
                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                <option value="" selected="selected" disabled="disabled">Select State To Display District</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col> :
                                    state === 'Andhra Pradesh' ?
                                        <Col md={6} lg={4} className="mt-5">
                                            <Form.Group controlId="hospitalState">
                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                    {andhraPradeshDistrictArray.map((d) => (
                                                        <option value={d}>{d}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col> :
                                        state === 'Andaman and Nicobar Islands' ?
                                            <Col md={6} lg={4} className="mt-5">
                                                <Form.Group controlId="hospitalState">
                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                        {andamanAndNicobarIslandsDistrictArray.map((d) => (
                                                            <option value={d}>{d}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col> :
                                            state === 'Arunachal Pradesh' ?
                                                <Col md={6} lg={4} className="mt-5">
                                                    <Form.Group controlId="hospitalState">
                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                            {arunachalPradeshDistrictArray.map((d) => (
                                                                <option value={d}>{d}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col> :
                                                state === 'Assam' ?
                                                    <Col md={6} lg={4} className="mt-5">
                                                        <Form.Group controlId="hospitalState">
                                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                {assamDistrictArray.map((d) => (
                                                                    <option value={d}>{d}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col> :
                                                    state === 'Bihar' ?
                                                        <Col md={6} lg={4} className="mt-5">
                                                            <Form.Group controlId="hospitalState">
                                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                    {biharDistrictArray.map((d) => (
                                                                        <option value={d}>{d}</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Col> :
                                                        state === 'Chandigarh' ?
                                                            <Col md={6} lg={4} className="mt-5">
                                                                <Form.Group controlId="hospitalState">
                                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                        {chandigarhDistrictArray.map((d) => (
                                                                            <option value={d}>{d}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </Form.Group>
                                                            </Col> :
                                                            state === 'Chhattisgarh' ?
                                                                <Col md={6} lg={4} className="mt-5">
                                                                    <Form.Group controlId="hospitalState">
                                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                            {chhattisgarhDistrictArray.map((d) => (
                                                                                <option value={d}>{d}</option>
                                                                            ))}
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                </Col> :
                                                                state === 'Dadar and Nagar Haveli' ?
                                                                    <Col md={6} lg={4} className="mt-5">
                                                                        <Form.Group controlId="hospitalState">
                                                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                {dadarAndNagarHaveliDistrictArray.map((d) => (
                                                                                    <option value={d}>{d}</option>
                                                                                ))}
                                                                            </Form.Select>
                                                                        </Form.Group>
                                                                    </Col> :
                                                                    state === 'Daman and Diu' ?
                                                                        <Col md={6} lg={4} className="mt-5">
                                                                            <Form.Group controlId="hospitalState">
                                                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                    {damanAndDiuDistrictArray.map((d) => (
                                                                                        <option value={d}>{d}</option>
                                                                                    ))}
                                                                                </Form.Select>
                                                                            </Form.Group>
                                                                        </Col> :
                                                                        state === 'Delhi' ?
                                                                            <Col md={6} lg={4} className="mt-5">
                                                                                <Form.Group controlId="hospitalState">
                                                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                        {delhiDistrictArray.map((d) => (
                                                                                            <option value={d}>{d}</option>
                                                                                        ))}
                                                                                    </Form.Select>
                                                                                </Form.Group>
                                                                            </Col> :
                                                                            state === 'Lakshadweep' ?
                                                                                <Col md={6} lg={4} className="mt-5">
                                                                                    <Form.Group controlId="hospitalState">
                                                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                            {LakshadweepDistrictArray.map((d) => (
                                                                                                <option value={d}>{d}</option>
                                                                                            ))}
                                                                                        </Form.Select>
                                                                                    </Form.Group>
                                                                                </Col> :
                                                                                state === 'Puducherry' ?
                                                                                    <Col md={6} lg={4} className="mt-5">
                                                                                        <Form.Group controlId="hospitalState">
                                                                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                {puducherryDistrictArray.map((d) => (
                                                                                                    <option value={d}>{d}</option>
                                                                                                ))}
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col> :
                                                                                    state === 'Goa' ?
                                                                                        <Col md={6} lg={4} className="mt-5">
                                                                                            <Form.Group controlId="hospitalState">
                                                                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                    {goaDistrictArray.map((d) => (
                                                                                                        <option value={d}>{d}</option>
                                                                                                    ))}
                                                                                                </Form.Select>
                                                                                            </Form.Group>
                                                                                        </Col> :
                                                                                        state === 'Gujarat' ?
                                                                                            <Col md={6} lg={4} className="mt-5">
                                                                                                <Form.Group controlId="hospitalState">
                                                                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                        {gujaratDistrictArray.map((d) => (
                                                                                                            <option value={d}>{d}</option>
                                                                                                        ))}
                                                                                                    </Form.Select>
                                                                                                </Form.Group>
                                                                                            </Col> :
                                                                                            state === 'Jammu and Kashmir' ?
                                                                                                <Col md={6} lg={4} className="mt-5">
                                                                                                    <Form.Group controlId="hospitalState">
                                                                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                            {jammuAndKashmirDistrictArray.map((d) => (
                                                                                                                <option value={d}>{d}</option>
                                                                                                            ))}
                                                                                                        </Form.Select>
                                                                                                    </Form.Group>
                                                                                                </Col> :
                                                                                                state === 'Himachal Pradesh' ?
                                                                                                    <Col md={6} lg={4} className="mt-5">
                                                                                                        <Form.Group controlId="hospitalState">
                                                                                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                {himachalPradeshDistrictArray.map((d) => (
                                                                                                                    <option value={d}>{d}</option>
                                                                                                                ))}
                                                                                                            </Form.Select>
                                                                                                        </Form.Group>
                                                                                                    </Col> :
                                                                                                    state === 'Jharkhand' ?
                                                                                                        <Col md={6} lg={4} className="mt-5">
                                                                                                            <Form.Group controlId="hospitalState">
                                                                                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                    {jharkhandDistrictArray.map((d) => (
                                                                                                                        <option value={d}>{d}</option>
                                                                                                                    ))}
                                                                                                                </Form.Select>
                                                                                                            </Form.Group>
                                                                                                        </Col> :
                                                                                                        state === 'Karnataka' ?
                                                                                                            <Col md={6} lg={4} className="mt-5">
                                                                                                                <Form.Group controlId="hospitalState">
                                                                                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                        {karnatakaDistrictArray.map((d) => (
                                                                                                                            <option value={d}>{d}</option>
                                                                                                                        ))}
                                                                                                                    </Form.Select>
                                                                                                                </Form.Group>
                                                                                                            </Col> :
                                                                                                            state === 'Kerala' ?
                                                                                                                <Col md={6} lg={4} className="mt-5">
                                                                                                                    <Form.Group controlId="hospitalState">
                                                                                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                            {keralaDistrictArray.map((d) => (
                                                                                                                                <option value={d}>{d}</option>
                                                                                                                            ))}
                                                                                                                        </Form.Select>
                                                                                                                    </Form.Group>
                                                                                                                </Col> :
                                                                                                                state === 'Madhya Pradesh' ?
                                                                                                                    <Col md={6} lg={4} className="mt-5">
                                                                                                                        <Form.Group controlId="hospitalState">
                                                                                                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                {madhyaPradeshDistrictArray.map((d) => (
                                                                                                                                    <option value={d}>{d}</option>
                                                                                                                                ))}
                                                                                                                            </Form.Select>
                                                                                                                        </Form.Group>
                                                                                                                    </Col> :
                                                                                                                    state === 'Maharashtra' ?
                                                                                                                        <Col md={6} lg={4} className="mt-5">
                                                                                                                            <Form.Group controlId="hospitalState">
                                                                                                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                    {maharashtraDistrictArray.map((d) => (
                                                                                                                                        <option value={d}>{d}</option>
                                                                                                                                    ))}
                                                                                                                                </Form.Select>
                                                                                                                            </Form.Group>
                                                                                                                        </Col> :
                                                                                                                        state === 'Manipur' ?
                                                                                                                            <Col md={6} lg={4} className="mt-5">
                                                                                                                                <Form.Group controlId="hospitalState">
                                                                                                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                        {manipurDistrictArray.map((d) => (
                                                                                                                                            <option value={d}>{d}</option>
                                                                                                                                        ))}
                                                                                                                                    </Form.Select>
                                                                                                                                </Form.Group>
                                                                                                                            </Col> :
                                                                                                                            state === 'Meghalaya' ?
                                                                                                                                <Col md={6} lg={4} className="mt-5">
                                                                                                                                    <Form.Group controlId="hospitalState">
                                                                                                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                            {meghalayaDistrictArray.map((d) => (
                                                                                                                                                <option value={d}>{d}</option>
                                                                                                                                            ))}
                                                                                                                                        </Form.Select>
                                                                                                                                    </Form.Group>
                                                                                                                                </Col> :
                                                                                                                                state === 'Mizoram' ?
                                                                                                                                    <Col md={6} lg={4} className="mt-5">
                                                                                                                                        <Form.Group controlId="hospitalState">
                                                                                                                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                {mizoramDistrictArray.map((d) => (
                                                                                                                                                    <option value={d}>{d}</option>
                                                                                                                                                ))}
                                                                                                                                            </Form.Select>
                                                                                                                                        </Form.Group>
                                                                                                                                    </Col> :
                                                                                                                                    state === 'Nagaland' ?
                                                                                                                                        <Col md={6} lg={4} className="mt-5">
                                                                                                                                            <Form.Group controlId="hospitalState">
                                                                                                                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                    {nagalandDistrictArray.map((d) => (
                                                                                                                                                        <option value={d}>{d}</option>
                                                                                                                                                    ))}
                                                                                                                                                </Form.Select>
                                                                                                                                            </Form.Group>
                                                                                                                                        </Col> :
                                                                                                                                        state === 'Odisha' ?
                                                                                                                                            <Col md={6} lg={4} className="mt-5">
                                                                                                                                                <Form.Group controlId="hospitalState">
                                                                                                                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                        {odishaDistrictArray.map((d) => (
                                                                                                                                                            <option value={d}>{d}</option>
                                                                                                                                                        ))}
                                                                                                                                                    </Form.Select>
                                                                                                                                                </Form.Group>
                                                                                                                                            </Col> :
                                                                                                                                            state === 'Punjab' ?
                                                                                                                                                <Col md={6} lg={4} className="mt-5">
                                                                                                                                                    <Form.Group controlId="hospitalState">
                                                                                                                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                            {punjabDistrictArray.map((d) => (
                                                                                                                                                                <option value={d}>{d}</option>
                                                                                                                                                            ))}
                                                                                                                                                        </Form.Select>
                                                                                                                                                    </Form.Group>
                                                                                                                                                </Col> :
                                                                                                                                                state === 'Rajasthan' ?
                                                                                                                                                    <Col md={6} lg={4} className="mt-5">
                                                                                                                                                        <Form.Group controlId="hospitalState">
                                                                                                                                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                                <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                                {rajasthanDistrictArray.map((d) => (
                                                                                                                                                                    <option value={d}>{d}</option>
                                                                                                                                                                ))}
                                                                                                                                                            </Form.Select>
                                                                                                                                                        </Form.Group>
                                                                                                                                                    </Col> :
                                                                                                                                                    state === 'Sikkim' ?
                                                                                                                                                        <Col md={6} lg={4} className="mt-5">
                                                                                                                                                            <Form.Group controlId="hospitalState">
                                                                                                                                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                                    {sikkimDistrictArray.map((d) => (
                                                                                                                                                                        <option value={d}>{d}</option>
                                                                                                                                                                    ))}
                                                                                                                                                                </Form.Select>
                                                                                                                                                            </Form.Group>
                                                                                                                                                        </Col> :
                                                                                                                                                        state === 'Tamil Nadu' ?
                                                                                                                                                            <Col md={6} lg={4} className="mt-5">
                                                                                                                                                                <Form.Group controlId="hospitalState">
                                                                                                                                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                                        {tamilNaduDistrictArray.map((d) => (
                                                                                                                                                                            <option value={d}>{d}</option>
                                                                                                                                                                        ))}
                                                                                                                                                                    </Form.Select>
                                                                                                                                                                </Form.Group>
                                                                                                                                                            </Col> :
                                                                                                                                                            state === 'Telangana' ?
                                                                                                                                                                <Col md={6} lg={4} className="mt-5">
                                                                                                                                                                    <Form.Group controlId="hospitalState">
                                                                                                                                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                                            {telanganaDistrictArray.map((d) => (
                                                                                                                                                                                <option value={d}>{d}</option>
                                                                                                                                                                            ))}
                                                                                                                                                                        </Form.Select>
                                                                                                                                                                    </Form.Group>
                                                                                                                                                                </Col> :
                                                                                                                                                                state === 'Tripura' ?
                                                                                                                                                                    <Col md={6} lg={4} className="mt-5">
                                                                                                                                                                        <Form.Group controlId="hospitalState">
                                                                                                                                                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                                                <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                                                {tripuraDistrictArray.map((d) => (
                                                                                                                                                                                    <option value={d}>{d}</option>
                                                                                                                                                                                ))}
                                                                                                                                                                            </Form.Select>
                                                                                                                                                                        </Form.Group>
                                                                                                                                                                    </Col> :
                                                                                                                                                                    state === 'Uttar Pradesh' ?
                                                                                                                                                                        <Col md={6} lg={4} className="mt-5">
                                                                                                                                                                            <Form.Group controlId="hospitalState">
                                                                                                                                                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                                                    {uttarPradeshDistrictArray.map((d) => (
                                                                                                                                                                                        <option value={d}>{d}</option>
                                                                                                                                                                                    ))}
                                                                                                                                                                                </Form.Select>
                                                                                                                                                                            </Form.Group>
                                                                                                                                                                        </Col> :
                                                                                                                                                                        state === 'Uttarakhand' ?
                                                                                                                                                                            <Col md={6} lg={4} className="mt-5">
                                                                                                                                                                                <Form.Group controlId="hospitalState">
                                                                                                                                                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                                                        {uttarakhandDistrictArray.map((d) => (
                                                                                                                                                                                            <option value={d}>{d}</option>
                                                                                                                                                                                        ))}
                                                                                                                                                                                    </Form.Select>
                                                                                                                                                                                </Form.Group>
                                                                                                                                                                            </Col> :
                                                                                                                                                                            state === 'West Bengal' ?
                                                                                                                                                                                <Col md={6} lg={4} className="mt-5">
                                                                                                                                                                                    <Form.Group controlId="hospitalState">
                                                                                                                                                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                                                            {westBengalDistrictArray.map((d) => (
                                                                                                                                                                                                <option value={d}>{d}</option>
                                                                                                                                                                                            ))}
                                                                                                                                                                                        </Form.Select>
                                                                                                                                                                                    </Form.Group>
                                                                                                                                                                                </Col> : ""
                            }

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Pincode</Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setPincode(e.target.value)} value={pincode} placeholder="Pin Code" onWheel={(e) => e.target.blur()} />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Contact</Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setContact(e.target.value)} value={contact} placeholder="Contact" onWheel={(e) => e.target.blur()} />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Email</Form.Label>
                                    <Form.Control required type="email" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setEmail(e.target.value)} value={email} placeholder="Email" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Pathology Website Link</Form.Label>
                                    <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setWebsiteLink(e.target.value)} value={websiteLink} placeholder="Pathology Website Link" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Select Public Sector</Form.Label>
                                    <Form.Select required style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setPublicSector(e.target.value)} value={publicSector}>
                                        <option value="" selected="selected" disabled="disabled">Select Public Sector</option>
                                        <option value="Central Government">Central Government</option>
                                        <option value="State Government">State Government</option>
                                        <option value="Local Government">Local Government</option>
                                        <option value="Public Sector Undertaken">Public Sector Undertaken</option>
                                        <option value="publicSectorOther">Other</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            {
                                publicSector === 'publicSectorOther' ?
                                    <Col md={6} lg={4} className="mt-5">
                                        <Form.Group>
                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Public Other Sector</Form.Label>
                                            <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setPublicSectorOther(e.target.value)} value={publicSectorOther} placeholder="Public Other Sector" />
                                        </Form.Group>
                                    </Col> : ''
                            }
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Select Private Sector</Form.Label>
                                    <Form.Select required style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setPrivateSector(e.target.value)} value={privateSector}>
                                        <option value="" selected="selected" disabled="disabled">Select Private Sector</option>
                                        <option value="Individual Proprietorship">Individual Proprietorship</option>
                                        <option value="Registered Partnership">Registered Partnership</option>
                                        <option value="Registered Company">Registered Company</option>
                                        <option value="Co-operation Society">Co-operation Society</option>
                                        <option value="Trust and Charitable">Trust and Charitable</option>
                                        <option value="privateSectorOther">Other</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            {
                                privateSector === 'privateSectorOther' ?
                                    <Col md={6} lg={4} className="mt-5">
                                        <Form.Group>
                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Private Other Sector</Form.Label>
                                            <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setPrivateSectorOther(e.target.value)} value={privateSectorOther} placeholder="Private Other Sector" />
                                        </Form.Group>
                                    </Col> : ''
                            }

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Registration No</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setRegistrationNo(e.target.value)} value={registrationNo} placeholder="Registration No" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Latitude</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setLatitude(e.target.value)} value={latitude} placeholder="Latitude" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Longitude</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setLongitude(e.target.value)} value={longitude} placeholder="Longitude" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group className="position-relative mb-3" controlId="contactPersonName">
                                    <Form.Label style={{ fontSize: '12px', fontWeight: 'bold' }}>Upload Pathology Image</Form.Label>
                                    <Form.Control style={{ fontSize: '12px' }} type="file" name="file" onChange={multipleImg} multiple accept="image/*" />
                                    {
                                        uploadDone ?
                                            <h1 style={{ fontSize: '12px', fontFamily: 'DM Sans', color: 'green', marginTop: '10px' }}>Pathology Image Uploaded Successfully</h1> : ""
                                    }
                                    {
                                        uploadLoading ?
                                            <Spinner animation="border" role="status" size="small" color="#000" style={{ marginTop: '10px' }}>
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner> :
                                            imageKey === 0 ?
                                                <Button size="sm" style={{ fontFamily: 'DM Sans', fontSize: '10px', marginTop: '10px' }} disabled>Upload</Button> :
                                                <Button size="sm" style={{ fontFamily: 'DM Sans', fontSize: '10px', marginTop: '10px' }} onClick={() => handlePathologyImage()}>Upload</Button>
                                    }
                                </Form.Group>
                            </Col>
                        </Row>

                        {/*----------------- Owner Details ---------------------*/}

                        <Row>
                            <h4 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
                                Owner Details
                                <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '17%', marginTop: 10 }}>
                                    {' '}
                                </div>
                            </h4>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Owner Name</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Owner Name" onChange={(e) => setOwnerName(e.target.value)} value={ownerName} />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Owner Contact</Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Owner Contact" onChange={(e) => setOwnerContact(e.target.value)} value={ownerContact} onWheel={(e) => e.target.blur()} />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Owner Email</Form.Label>
                                    <Form.Control required type="email" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setOwnerEmail(e.target.value)} value={ownerEmail} placeholder="Owner Email" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}> Bharp Discount</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setBharpDiscount(e.target.value)} value={bharpDiscount} placeholder="Bharp Discount" />
                                </Form.Group>
                            </Col>

                            
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}> Vendor Margin</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setVendorMargin(e.target.value)} value={vendorMargin} placeholder="Vendor Margin" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}> Home Collection Availability </Form.Label>
                                    <Form.Select required style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHomeCollectionAvailability(e.target.value)} value={homeCollectionAvailability}>
                                        <option value="" selected="selected" disabled="disabled">Select </option>
                                        <option value="No">No</option>
                                        <option value="Yes">Yes</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>

                        {/*----------------- License By ---------------------*/}

                        <Row>
                            <h4 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
                                License By
                                <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '17%', marginTop: 10 }}>
                                    {' '}
                                </div>
                            </h4>

                            <Col md={6} lg={4} className="mt-2">
                                <Form.Group controlId="hospitalServices">
                                    <input type="checkbox" value="NABL" checked={licenseBy.some(val => val === "NABL")} onChange={handleChangeLicenseChange} />
                                    <label style={{ marginLeft: '10px' }}>NABL</label><br></br>

                                    <input type="checkbox" value="AERB" checked={licenseBy.some(val => val === "AERB")} onChange={handleChangeLicenseChange} />
                                    <label style={{ marginLeft: '10px' }}>AERB</label><br></br>

                                    <input type="checkbox" value="RSO" checked={licenseBy.some(val => val === "RSO")} onChange={handleChangeLicenseChange} />
                                    <label style={{ marginLeft: '10px' }}>RSO</label><br></br>
                                    {
                                        otherLicence.length === 0 ?
                                            <Button onClick={() => addLicenseTypeField()}>Add Other License</Button> : ""
                                    }
                                    {
                                        otherLicence.map((input, key) => (
                                            <>
                                                <br></br>
                                                <input placeholder="License Other" type="text" style={{ color: "#000", borderColor: '#000', borderWidth: 1, fontSize: 10, height: 40, width: 200 }} onChange={(text) => handleLicenceChange(text.target.value, key, 'licenseBy')} value={input.licenseBy} />
                                                <br></br><br></br>
                                                <div style={{ display: "flex", marginTop: 10 }}>

                                                    <Button onClick={() => addLicenseTypeField()}><FiPlus /></Button>
                                                    <Button onClick={() => removeLicenseField(key)} style={{ marginLeft: 35 }}><FiMinus /></Button>
                                                </div>
                                                <br></br><br></br>
                                            </>
                                        ))}

                                    {
                                        otherLicence.length !== 0 ?
                                            <Button variant='warning' onClick={() => mergelicenseClick()}>Add</Button>
                                            : ""
                                    }

                                </Form.Group>
                            </Col>
                        </Row>

                        {/*----------------- Services Details ---------------------*/}

                        <Row>
                            <h4 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
                                Services
                                <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '17%', marginTop: 10 }}>
                                    {' '}
                                </div>
                            </h4>

                            <Col md={4} lg={4} className="mt-2">
                                <Form.Group controlId="hospitalServices">
                                    <input type="checkbox" value="Liver function test" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Liver function test</label><br></br>
                                    {
                                        checkedLiverFunction ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Liver function test</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Liver function test')} value={liverFunctionTestPrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }

                                    <input type="checkbox" value="Complete blood count" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Complete blood count</label><br></br>

                                    {
                                        checkedCompleteBloodCount ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Complete blood count</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Complete blood count')} value={checkCompleteBloodCountPrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }

                                    <input type="checkbox" value="Cholesterol test" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Cholesterol test</label><br></br>

                                    {
                                        checkedCholesterol ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Cholesterol test</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Cholesterol test')} value={checkCholesterolTestPrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }

                                    <input type="checkbox" value="Blood test" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Blood test</label><br></br>
                                    {
                                        checkedBloodTest ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Blood test</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Blood test')} value={checkBloodTestPrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }
                                    <input type="checkbox" value="C-reactive protein test" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>C-reactive protein test</label><br></br>
                                    {
                                        checkedCreactiveProtein ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>C-reactive protein test</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'C-reactive protein test')} value={checkCreactiveProteinPrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }
                                    <input type="checkbox" value="Blood sugar test" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Blood sugar test</label><br></br>
                                    {
                                        checkedBloodSuger ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Blood sugar test</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Blood sugar test')} value={checkBloodSugerPrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }
                                    <input type="checkbox" value="Pap smear" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Pap smear</label><br></br>
                                    {
                                        checkedPapSmear ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Pap smear</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Pap smear')} value={checkPapSmearPrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }
                                    <input type="checkbox" value="Arterial blood gas test" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Arterial blood gas test</label><br></br>
                                    {
                                        checkedArterialBloodGas ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Arterial blood gas test</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Arterial blood gas test')} value={checkArterialBloodGasPrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }
                                    <input type="checkbox" value="Blood culture" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Blood culture</label><br></br>
                                    {
                                        checkedBloodCulture ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Blood culture</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Blood culture')} value={checkBloodCulturePrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }
                                    <input type="checkbox" value="Thromboplastic test" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Thromboplastic test</label><br></br>
                                    {
                                        checkedThromboplastic ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Thromboplastic test</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Thromboplastic test')} value={checkThromboplasticPrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }
                                    <input type="checkbox" value="Glucose tolerance test" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Glucose tolerance test</label><br></br>
                                    {
                                        checkedGlucoseTolerance ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Glucose tolerance test</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Glucose tolerance test')} value={checkGlucoseTolerancePrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }
                                    <input type="checkbox" value="Monteux test" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Monteux test</label><br></br>
                                    {
                                        checkedMonteux ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Monteux test</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Monteux test')} value={checkMonteuxPrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }
                                    <input type="checkbox" value="Chloride test" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Chloride test</label><br></br>
                                    {
                                        checkedChloride ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Chloride test</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Chloride test')} value={checkChloridePrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }
                                    <input type="checkbox" value="Creatinine test" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Creatinine test</label><br></br>
                                    {
                                        checkedCreatinine ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Creatinine test</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Creatinine test')} value={checkCreatininePrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }
                                    <input type="checkbox" value="Beta hCG test" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Beta hCG test</label><br></br>
                                    {
                                        checkedBetaHcg ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Beta hCG test</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Beta hCG test')} value={checkBetaHcgPrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }
                                    <input type="checkbox" value="Lumbar puncture" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Lumbar puncture</label><br></br>
                                    {
                                        checkedLumbarPuncture ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Lumbar puncture</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Lumbar puncture')} value={checkLumbarPuncturePrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }
                                    <input type="checkbox" value="Gastric fluid analysis" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Gastric fluid analysis</label><br></br>

                                    {
                                        checkedGastricFluidAnalysis ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Gastric fluid analysis</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Gastric fluid analysis')} value={checkGastricFluidAnalysisPrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }

                                </Form.Group>
                            </Col>

                            <Col lg={4} className="mt-2">
                                <Form.Group controlId="hospitalServices">





                                    <input type="checkbox" value="Kidney function test" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Kidney function test</label><br></br>
                                    {
                                        checkedKidneyFunction ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Kidney function test</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Kidney function test')} value={checkKidneyFunctionPrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }

                                    <input type="checkbox" value="Malabsorption test" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Malabsorption test</label><br></br>

                                    {
                                        checkedMalabsorption ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Malabsorption test</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Malabsorption test')} value={checkMalabsorptionPrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }

                                    <input type="checkbox" value="Phenolsulfonphthalein test" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Phenolsulfonphthalein test</label><br></br>

                                    {
                                        checkedPhenolsulfonphthalein ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Phenolsulfonphthalein test</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Phenolsulfonphthalein test')} value={checkPhenolsulfonphthaleinPrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }

                                    <input type="checkbox" value="Pregnancy test" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Pregnancy test</label><br></br>
                                    {
                                        checkedPregancy ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Pregnancy test</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Pregnancy test')} value={checkPregnancyTestPrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }


                                    <input type="checkbox" value="Prenatal test" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Prenatal test</label><br></br>
                                    {
                                        checkedPrenatal ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Prenatal test</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Prenatal test')} value={checkPrenatalTestPrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }

                                    <input type="checkbox" value="Syphilis test" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Syphilis test</label><br></br>
                                    {
                                        checkedSyphilis ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Syphilis test</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Syphilis test')} value={checkSyphilisTestPrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }

                                    <input type="checkbox" value="Thyroid function test" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Thyroid function test</label><br></br>
                                    {
                                        checkedThyroidFunction ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Thyroid function test</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Thyroid function test')} value={checkThyroidFunctionPrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }

                                    <input type="checkbox" value="Toxicology test" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Toxicology test</label><br></br>
                                    {
                                        checkedToxicology ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Toxicology test</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Toxicology test')} value={checkToxicologyTestPrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }

                                    <input type="checkbox" value="Urine test" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Urine test</label><br></br>
                                    {
                                        checkedUrine ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Urine test</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Urine test')} value={checkUrineTestPrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }

                                    <input type="checkbox" value="Complementation test" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Complementation test</label><br></br>
                                    {
                                        checkedComplementation ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Complementation test</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Complementation test')} value={checkComplementationTestPrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }

                                    <input type="checkbox" value="Fluorescence in situ hybridization" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Fluorescence in situ hybridization</label><br></br>
                                    {
                                        checkedFluorecenceInSituHybridization ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Fluorescence in situ hybridization</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Fluorescence in situ hybridization')} value={checkFluorescenceInSituHybridizationPrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }

                                    <input type="checkbox" value="Preimplantation genetic diagnosis" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Preimplantation genetic diagnosis</label><br></br>
                                    {
                                        checkedPreimplantationGeneticDiagnosis ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Preimplantation genetic diagnosis</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Preimplantation genetic diagnosis')} value={checkPreimplantationGeneticDiagnosisPrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }

                                    <input type="checkbox" value="Skin test" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Skin test</label><br></br>
                                    {
                                        checkedSkinTest ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Skin test</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Skin test')} value={checkSkinTestPrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }

                                    <input type="checkbox" value="Semen analysis" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Semen analysis</label><br></br>
                                    {
                                        checkedSemenAnalysis ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Semen analysis</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Semen analysis')} value={checkSemenAnalysisPrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }

                                    <input type="checkbox" value="Rubin’s test" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Rubin’s test</label><br></br>
                                    {
                                        checkedRubinTest ?
                                            <>
                                                <label style={{ marginLeft: '10px' }}>Rubin’s test</label><br></br>
                                                <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Rubin’s test')} value={checkRubinTestPrice} />
                                                <br></br>
                                            </>

                                            : ""
                                    }
                                    {
                                        serviceTypeStateOther.length === 0 ?
                                            <Button onClick={() => addServiceTypeField()}>Add Other Services</Button> : ""
                                    }
                                    {
                                        serviceTypeStateOther.map((input, key) => (
                                            <>
                                                <br></br>
                                                <input placeholder="Service Name" type="text" style={{ color: "#000", borderColor: '#000', borderWidth: 1, fontSize: 10, height: 40, width: 200 }} onChange={(text) => handleServiceTypeChange(text.target.value, key, 'serviceName')} value={input.serviceName} />
                                                <br></br><br></br>
                                                <input placeholder="Service Price" type="number" style={{ color: "#000", borderColor: '#000', borderWidth: 1, fontSize: 10, height: 40, width: 200 }} onChange={(text) => handleServiceTypeChange(text.target.value, key, 'servicePrice')} value={input.servicePrice} />
                                                <div style={{ display: "flex", marginTop: 10 }}>

                                                    <Button onClick={() => addServiceTypeField()}><FiPlus /></Button>
                                                    <Button onClick={() => removeServiceTypeField(key)} style={{ marginLeft: 35 }}><FiMinus /></Button>
                                                </div>
                                                <br></br><br></br>
                                            </>
                                        ))}

                                    {
                                        serviceTypeStateOther.length !== 0 ?
                                            <Button variant='warning' onClick={() => mergeClick()}>Add</Button>
                                            : ""
                                    }
                                </Form.Group>

                            </Col>



                        </Row>

                        {/*----------------- Pathologist Details ---------------------*/}

                        <Row>
                            <h4 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
                                Pathologist Details
                                <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '17%', marginTop: 10 }}>
                                    {' '}
                                </div>
                            </h4>
                            {pathologistDetails.map((input, key) => (
                                <Row>
                                    <h6 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
                                        Pathologist {(counter += 1)}
                                        <div style={{ backgroundColor: '#ffb606 ', height: 1, width: '7%', marginTop: 10 }}>
                                            {' '}
                                        </div>
                                    </h6>
                                    <Col md={4} lg={4} className="mt-5">
                                        <Form.Group>
                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Pathologist Full Name</Form.Label>
                                            <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Pathologist Full Name" onChange={(e) => handleChange(e.target.value, key, 'name')} value={input.name} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} lg={4} className="mt-5">
                                        <Form.Group>
                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Pathologist Qualification</Form.Label>
                                            <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Separeated by comma " onChange={(e) => handleChange(e.target.value, key, 'qualification')} value={input.qualification} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} lg={4} className="mt-5">
                                        <Form.Group>
                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Pathologist Experience</Form.Label>
                                            <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="In Year" onChange={(e) => handleChange(e.target.value, key, 'experience')} value={input.experience} onWheel={(e) => e.target.blur()} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            ))}
                            <Col md={6} lg={4} className="mt-5">
                                <Button onClick={() => addFormFields()} className="m-1" style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>
                                    <AiFillPlusCircle style={{ fontSize: '20px' }} />
                                </Button>
                            </Col>
                        </Row>

                        {
                            loading ?
                                <Button variant="primary" disabled className="mt-5" style={{ fontFamily: 'DM Sans', fontSize: '12px', marginBottom: '100px' }}>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    <span className="visually-hidden">Loading...</span>
                                </Button> :
                                <Button type="submit" className="mt-5" style={{ fontFamily: 'DM Sans', fontSize: '12px', marginBottom: '100px' }}>Add Pathology</Button>
                        }
                    </Form>
                </Container>
            </div>
        </>
    )
}
