import { useMutation, useQuery } from '@apollo/client'
import React from 'react'
import { Button, Container, Spinner, Table } from 'react-bootstrap'
import { GET_USER_SOS } from '../../graphql/queries'
import Moment from 'react-moment'
import { MUTATION_USER_SOS_ACCEPT, MUTATION_USER_SOS_REJECT } from '../../graphql/mutations'
import swal from 'sweetalert';
import * as XLSX from 'xlsx';

export default function Sos() {
    let count = 1;
    const { data: sosData, loading: sosLoading } = useQuery(GET_USER_SOS)
    console.log("sosData", sosData)

    function handleOnExport() {
        const todayDate = new Date()
        const todayDateString = todayDate.toString()
        const finalDate = todayDateString.slice(0, 10);
        console.log("finaldate", finalDate)
        var wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(sosData && sosData.getUserSos);

        XLSX.utils.book_append_sheet(wb, ws, "Sheet");
        XLSX.writeFile(wb, "Sosdetails(" + finalDate + ").xlsx")
    }

    const [acceptSOS] = useMutation(MUTATION_USER_SOS_ACCEPT, {
        refetchQueries: [
            GET_USER_SOS
        ]
    })

    const [rejectSOS] = useMutation(MUTATION_USER_SOS_REJECT, {
        refetchQueries: [
            GET_USER_SOS
        ]
    })

    function handleAccept(id) {
        acceptSOS({
            variables: {
                "sosId": `${id}`
            }
        })
        swal({
            title: "Accepted",
            text: "SOS Accepted Successfully!!",
            icon: "success",
        })

    }


    function handleReject(id) {
        rejectSOS({
            variables: {
                "sosId": `${id}`
            }
        })
        swal({
            title: "Rejected",
            text: "SOS Rejected Successfully!!!!",
            icon: "success",
        })

    }
    return (
        <>
            <Container fluid>
                <h4 style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 25 }}>SOS Details</h4>

                <Table bordered style={{ fontFamily: "Dm sans", fontSize: 12 }}>
                    <tbody>
                        <tr>
                            <td><Button size="sm" onClick={() => handleOnExport()}>Download Excel</Button></td>
                        </tr>
                    </tbody>
                </Table>

                <Table striped bordered hover style={{ fontFamily: "Dm sans", fontSize: 12 }} responsive>
                    <thead>
                        <tr>
                            <th>SR No</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Contact</th>
                            <th>Data/Time</th>
                            <th>Status</th>
                            <th>Accept</th>
                            <th>Reject</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sosLoading ?
                                <>
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </>
                                :
                                sosData && sosData.getUserSos.slice().reverse().map(soslist =>
                                    <tr>
                                        <td>{count++}</td>
                                        <td>{soslist.fName} {soslist.lName}</td>
                                        <td>{soslist.email}</td>
                                        <td>{soslist.contact}</td>
                                        <td><Moment format='DD/MM/YYYY hh:mm a'>{soslist.createDateTime}</Moment></td>
                                        <td>{soslist.status}</td>
                                        <td> <Button variant='success' className='mx-auto d-block' onClick={() => handleAccept(soslist.id)}>Accept</Button></td>
                                        <td> <Button variant='danger' className='mx-auto d-block' onClick={() => handleReject(soslist.id)}>Reject</Button></td>
                                    </tr>
                                )

                        }
                    </tbody>
                </Table>
            </Container>
        </>
    )
}
