import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Button, Table, Modal, Image, Row, Col, Form, Spinner } from "react-bootstrap"
import { QUERY_ALL_USER } from '../../graphql/queries'
import { FaEye, FaPen } from "react-icons/fa";
import { MUTATION_USER, MUTATION_EDIT_WALLET_AMOUNT } from '../../graphql/mutations';
import * as XLSX from 'xlsx';
import Moment from 'react-moment';

export default function User() {

  useEffect(() => {
    setInterval(() => {
      refetch()
    }, 1000);
  }, [])

  const { data, loading, refetch } = useQuery(QUERY_ALL_USER, {
    pollInterval: 300
  })
  console.log("data", data)
  let count = 1;
  const [userId, setUserId] = useState("")
  const [ayushmanImg, setAyushmanImg] = useState("")
  const [ayushmanNo, setAyushmanNo] = useState("")
  const [ayushmanVerification, setAyushmanVerification] = useState("")
  const [bplImg, setBplImg] = useState("")
  const [bplNo, setBplNo] = useState("")
  const [bplVerifation, setBplVerification] = useState("")
  const [nationHealthImg, setNationHealthImg] = useState("")
  const [nationHealthNo, setNationHealthNo] = useState("")
  const [nationHealthVerification, setNationHealthVerification] = useState("")

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  function handleOnExport() {
    const todayDate = new Date()
    const todayDateString = todayDate.toString()
    const finalDate = todayDateString.slice(0, 10);
    console.log("finaldate", finalDate)
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(data && data.getAllUser);

    XLSX.utils.book_append_sheet(wb, ws, "Sheet");
    XLSX.writeFile(wb, "Userdetails(" + finalDate + ").xlsx")
  }

  function ayushManhandle(id, img, number, Verification) {
    handleShow()
    setUserId(id)
    setAyushmanImg(img)
    setAyushmanNo(number)
    setAyushmanVerification(Verification)
  }

  function bplhandle(id, img, number, Verification) {
    handleShow2()
    setUserId(id)
    setBplImg(img)
    setBplNo(number)
    setBplVerification(Verification)
  }

  function nationalHealthCard(id, img, number, Verification) {
    handleShow3()
    setUserId(id)
    setNationHealthImg(img)
    setNationHealthNo(number)
    setNationHealthVerification(Verification)
  }

  const [updateUser] = useMutation(MUTATION_USER, {
    refetchQueries: [
      QUERY_ALL_USER,
      "getAllUser"
    ]
  })
  const [editWalletAmount] = useMutation(MUTATION_EDIT_WALLET_AMOUNT, {
    refetchQueries: [
      QUERY_ALL_USER,
      "getAllUser"
    ]
  })

  function varifyAyushman() {
    updateUser({
      variables: {
        "userUpdateInput": {
          "userId": `${userId}`,
          "ayushmanCardVerification": "verify",
        }
      }
    })
    handleClose()
  }

  function unVerifyAyushman() {
    updateUser({
      variables: {
        "userUpdateInput": {
          "userId": `${userId}`,
          "ayushmanCardVerification": "unverify",
        }
      }
    })
    handleClose()
  }

  function verifyBPl() {
    updateUser({
      variables: {
        "userUpdateInput": {
          "userId": `${userId}`,
          "bplCardVerification": "verify"
        }
      }
    })
    handleClose2()
  }

  function unVerifyBPL() {
    updateUser({
      variables: {
        "userUpdateInput": {
          "userId": `${userId}`,
          "bplCardVerification": "verify"
        }
      }
    })
    handleClose2()
  }

  function verifyNationHealth() {
    updateUser({
      variables: {
        "userUpdateInput": {
          "userId": `${userId}`,
          "nationalHealthCardVerification": "verify",

        }
      }
    })
    handleClose3()
  }

  function unVerifyNationHealth() {
    updateUser({
      variables: {
        "userUpdateInput": {
          "userId": `${userId}`,
          "nationalHealthCardVerification": "unverify",
        }
      }
    })
    handleClose3()
  }

  const [fNameState, setFnameState] = useState('');
  const [userIdState, setUserIdState] = useState('');
  const [walletAmountState, setWalletAmountState] = useState('');
  function editWalletAmountHandel(uId, name, wAmount) {
    setFnameState(uId)
    setUserIdState(name)
    setWalletAmountState(wAmount)
    handleShow4()
  }


  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    }
    else {
      event.preventDefault();
      setValidated(false);
      editWalletAmount({
        variables: {
          "userId": `${userIdState}`,
          "amount": `${walletAmountState}`
        }
      }).then(() => {
        // alert("Updated!!!");
        handleClose4();
      })

    }

  };


  return (
    <>
      <h1 style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 25 }}>User's Details</h1>

      {
        loading ?
          <Spinner animation="border" role="status" className="mx-auto d-block">
            <span className="visually-hidden">Loading...</span>
          </Spinner> :
          <>
            <Table bordered style={{ fontFamily: "Dm sans", fontSize: 12 }}>
              <tbody>
                <tr>
                  <td><Button size="sm" onClick={() => handleOnExport()}>Download Excel</Button></td>
                </tr>
              </tbody>
            </Table>

            <Table striped bordered hover style={{ fontFamily: "Dm sans", fontSize: 12 }}>
              <thead style={{ background: '#34495e', color: '#fff', position: 'sticky', top:0, margin:'0,0,0,0' }}>
                <tr>
                  <th>S.No.</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Contact</th>
                  <th>Date/Time</th>
                  <th>Wallet Amount</th>
                  <th>Edit Wallet</th>
                  <th>Ayushman Card</th>
                  <th>BPL Card</th>
                  <th>National Health Card</th>
                </tr>
              </thead>
              <tbody>
                {

                  data && data.getAllUser.slice().reverse().map(item => {
                    return (
                      <tr>
                        <td>{count++}</td>
                        <td>{item.fName} {item.lName}</td>
                        <td>{item.email}</td>
                        <td>{item.contact}</td>
                        <td><Moment format='DD/MM/YYYY, hh:mm a'>{item.createDateTime}</Moment></td>
                        <td style={{ textAlign: 'center' }}>{item.wallet}</td>
                        <td><center>
                          <Button size="sm" variant='info' onClick={() => editWalletAmountHandel(item.fName, item.id, item.wallet)}><FaPen /></Button>
                        </center></td>
                        {
                          item.status === "tempUser" ?
                            <td style={{ color: "#3498db" }}><center>Profile not activated yet</center></td> :
                            item.ayushmanCardImg === null ?
                              <td style={{ color: '#9b59b6' }}><center>Not uploaded yet</center></td>
                              :
                              item.ayushmanCardVerification === 'pending' ?
                                <td>
                                  <center>
                                    <Button size="sm" variant='warning' onClick={() => ayushManhandle(item.id, item.ayushmanCardImg, item.ayushmanCardNo, item.ayushmanCardVerification)}><FaEye /></Button>
                                    <p style={{ color: '#f1c40f' }}>Pending</p>
                                  </center>
                                </td>
                                :
                                item.ayushmanCardVerification === 'verify' ?
                                  <td>
                                    <center>
                                      <Button size="sm" variant='success' onClick={() => ayushManhandle(item.id, item.ayushmanCardImg, item.ayushmanCardNo, item.ayushmanCardVerification)}><FaEye /></Button>
                                      <p style={{ color: '#2ecc71' }}>Verified</p>
                                    </center>
                                  </td>
                                  :
                                  item.ayushmanCardVerification === 'unverify' ?
                                    <td>
                                      <center>
                                        <Button size="sm" variant='danger' onClick={() => ayushManhandle(item.id, item.ayushmanCardImg, item.ayushmanCardNo, item.ayushmanCardVerification)}><FaEye /></Button>
                                        <p style={{ color: '#e74c3c' }}>Unverified</p>
                                      </center>
                                    </td>
                                    : ''
                        }
                        {
                          item.status === "tempUser" ?
                            <td style={{ color: "#3498db" }}><center>Profile not activated yet</center></td> :
                            item.bplCardImg === null ?
                              <td style={{ color: '#9b59b6' }}><center>Not uploaded yet</center></td>
                              :
                              item.bplCardVerification === 'pending' ?
                                <td>
                                  <center>
                                    <Button size="sm" variant='warning' onClick={() => bplhandle(item.id, item.bplCardImg, item.bplCardNo, item.bplCardVerification)}><FaEye /></Button>
                                    <p style={{ color: '#f1c40f' }}>Pending</p>
                                  </center>
                                </td>
                                :
                                item.bplCardVerification === 'verify' ?
                                  <td>
                                    <center>
                                      <Button size="sm" variant='success' onClick={() => bplhandle(item.id, item.bplCardImg, item.bplCardNo, item.bplCardVerification)}><FaEye /></Button>
                                      <p style={{ color: '#2ecc71' }}>Verified</p>
                                    </center>
                                  </td>
                                  :
                                  item.bplCardVerification === 'unverify' ?
                                    <td>
                                      <center>
                                        <Button size="sm" variant='danger' onClick={() => bplhandle(item.id, item.bplCardImg, item.bplCardNo, item.bplCardVerification)}><FaEye /></Button>
                                        <p style={{ color: '#e74c3c' }}>Unverified</p>
                                      </center>
                                    </td>
                                    : ''
                        }
                        {
                          item.status === "tempUser" ?
                            <td style={{ color: "#3498db" }}><center>Profile not activated yet</center></td> :
                            item.nationalHealthCardImg === null ?
                              <td style={{ color: '#9b59b6' }}><center>Not uploaded yet</center></td>
                              :
                              item.nationalHealthCardVerification === 'pending' ?
                                <td>
                                  <center>
                                    <Button size="sm" variant='warning' onClick={() => nationalHealthCard(item.id, item.nationalHealthCardImg, item.nationalHealthCardNo, item.nationalHealthCardVerification)}><FaEye /></Button>
                                    <p style={{ color: '#f1c40f' }}>Pending</p>
                                  </center>
                                </td>
                                :
                                item.nationalHealthCardVerification === 'verify' ?
                                  <td>
                                    <center>
                                      <Button size="sm" variant='success' onClick={() => nationalHealthCard(item.id, item.nationalHealthCardImg, item.nationalHealthCardNo, item.nationalHealthCardVerification)}><FaEye /></Button>
                                      <p style={{ color: '#2ecc71' }}>Verified</p>
                                    </center>
                                  </td>
                                  :
                                  item.nationalHealthCardVerification === 'unverify' ?
                                    <td>
                                      <center>
                                        <Button size="sm" variant='danger' onClick={() => nationalHealthCard(item.id, item.nationalHealthCardImg, item.nationalHealthCardNo, item.nationalHealthCardVerification)}><FaEye /></Button>
                                        <p style={{ color: '#e74c3c' }}>Unverified</p>
                                      </center>
                                    </td>
                                    : ''
                        }
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          </>
      }



      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Ayushman Card Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body><Row>
          <Col md={6}>
            <Image src={`https://bharp-user-data.s3.ap-south-1.amazonaws.com/${ayushmanImg}`} style={{ width: 350, height: 350 }} className="img-fluid" alt="Responsive image" />
          </Col>
          <Col md={6}>
            <h6 style={{ fontWeight: "bold", fontSize: 12 }}>Ayushman Card No : {ayushmanNo}</h6>
            {
              ayushmanVerification === "unverify" ?
                <h6 style={{ fontWeight: "bold", fontSize: 12, color: 'red' }}>Status : {ayushmanVerification}</h6> :
                <h6 style={{ fontWeight: "bold", fontSize: 12, color: 'green' }}>Status : {ayushmanVerification}</h6>
            }
          </Col>

        </Row></Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => varifyAyushman()} style={{ fontFamily: "Dm sans" }} size="sm">
            Verify
          </Button>
          <Button variant="danger" onClick={() => unVerifyAyushman()} style={{ fontFamily: "Dm sans" }} size="sm">
            Unverify
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={show2} onHide={handleClose2} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>BPL Card Verification</Modal.Title>

        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Image src={`https://bharp-user-data.s3.ap-south-1.amazonaws.com/${bplImg}`} style={{ width: 350, height: 350 }} className="img-fluid" alt="Responsive image" />

            </Col>
            <Col md={6}>
              <h6 style={{ fontWeight: "bold", fontSize: 12 }}>BPL Card No : {bplNo}</h6>
              {
                bplVerifation === "unverify" ?
                  <h6 style={{ fontWeight: "bold", fontSize: 12 }}>Status : {bplVerifation}</h6> :
                  <h6 style={{ fontWeight: "bold", fontSize: 12 }}>Status : {bplVerifation}</h6>
              }
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" size="sm" onClick={() => verifyBPl()} style={{ fontFamily: "Dm sans" }}>
            Verify
          </Button>
          <Button variant="danger" size="sm" onClick={() => unVerifyBPL()} style={{ fontFamily: "Dm sans" }}>
            Unverify
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show3} onHide={handleClose3} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>National Health Card</Modal.Title>
        </Modal.Header>
        <Modal.Body> <Row>
          <Col md={6}>
            <Image src={`https://bharp-user-data.s3.ap-south-1.amazonaws.com/${nationHealthImg}`} style={{ width: 350, height: 350 }} className="img-fluid" alt="Responsive image" />

          </Col>
          <Col md={6}>
            <h6 style={{ fontWeight: "bold", fontSize: 12 }}>National Health Card No : {nationHealthNo}</h6>
            {
              nationHealthVerification === "unverify" ?
                <h6 style={{ fontWeight: "bold", fontSize: 12 }}>Status : {nationHealthVerification}</h6> :
                <h6 style={{ fontWeight: "bold", fontSize: 12 }}>Status : {nationHealthVerification}</h6>
            }
          </Col>
        </Row></Modal.Body>
        <Modal.Footer>
          <Button variant="primary" size="sm" onClick={() => verifyNationHealth()} style={{ fontFamily: "Dm sans" }}>
            Verify
          </Button>
          <Button variant="primary" size="sm" onClick={() => unVerifyNationHealth()} style={{ fontFamily: "Dm sans" }}>
            Unverify
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show4} onHide={handleClose4} size="sm">
        <Modal.Header closeButton>
          <Modal.Title style={{ fontWeight: 'bold', fontSize: 12 }} > Edit {fNameState}'s wallet amount</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>Enter Wallet Amount</Form.Label>
                <Form.Control required type="text" onChange={(e) => setWalletAmountState(e.target.value)} value={walletAmountState} />
              </Form.Group>

            </Row>


            <Button type="submit">Edit</Button>
          </Form>


        </Modal.Body>
      </Modal>
    </>
  )
}
