import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate, Outlet, Link } from 'react-router-dom'
import '../../ComponentCss/SuperAdminDashboard.css'
import { FaHome, FaChevronDown, FaBars, FaRegUser, FaAmbulance, FaChartBar } from "react-icons/fa";
import "../../ComponentCss/AdminDashboard.css"


export default function SubAdminDashboard() {

    const navigate = useNavigate();

    const [sideBar, setSideBar] = useState('sidebar close')
    const [dropDown, setDropDown] = useState('dropdown')
    const [dropDown1, setDropDown1] = useState('dropdown')
    const superAdminToken = localStorage.getItem('superAdminToken')

    // useEffect(() => {
    //     if (!superAdminToken) {
    //         navigate('/superAdminLogin');
    //     }
    // }, [])

    function handleDown() {
        const listItems = document.querySelectorAll(".sidebar-list li");
        listItems.forEach((item) => {
            item.addEventListener("click", () => {
                let isActive = item.classList.contains("active");

                listItems.forEach((el) => {
                    el.classList.remove("active");
                });

                if (isActive) item.classList.remove("active");
                else item.classList.add("active");
            });
        });
    }

    function handleClick() {
        if (sideBar === 'sidebar show') {
            setSideBar('sidebar close')
        }
        else {
            setSideBar('sidebar show')
        }
    }

    function handleClickDropdown1() {
        if (dropDown === 'dropdown') {
            setDropDown('dropdown active')
        }
        else {
            setDropDown('dropdown')
        }
    }

    function handleClickDropdown2() {
        if (dropDown1 === 'dropdown') {
            setDropDown1('dropdown active')
        }
        else {
            setDropDown1('dropdown')
        }
    }

    return (
        <>
            <div className={sideBar}>
                <ul className="sidebar-list">
                    <li>
                        <div className="title">
                            <Link to="/" className="link" style={{ textDecoration: "none" }}>
                                <i className='bx bx-grid-alt'><FaHome /></i>
                                <span className="name">Home</span>
                            </Link>
                        </div>
                        <div className="submenu">
                            <Link to="/" className="link submenu-title" style={{ textDecoration: "none" }}>Home</Link>
                        </div>
                    </li>
                    <li>
                        <div className="title">
                            <Link to="/subAdminDashboard/subDashboardHome" className="link" style={{ textDecoration: "none" }}>
                                <i className='bx bx-grid-alt'><FaChartBar /></i>
                                <span className="name">Dashboard</span>
                            </Link>
                        </div>
                        <div className="submenu">
                            <Link to="/subAdminDashboard/subDashboardHome" className="link submenu-title" style={{ textDecoration: "none" }}>Dashboard</Link>
                        </div>
                    </li>

                    <li className={dropDown}>
                        <div className="title">
                            <Link to="/subAdminDashboard/addAgentSub" className="link" style={{ textDecoration: "none" }}>
                                <i className='bx bx-book-alt'><FaRegUser /></i>
                                <span className="name">Agent</span>
                            </Link>
                            <i className='bx bxs-chevron-down' onClick={() => handleClickDropdown1()}><FaChevronDown /></i>
                        </div>
                        <div className="submenu">
                            <Link to="/subAdminDashboard/addAgentSub" className="link" style={{ textDecoration: "none" }}>Add Agent</Link>
                            <Link to="/subAdminDashboard/allAgentSub" className="link" style={{ textDecoration: "none" }}>All Agents</Link>
                        </div>
                    </li>

                    <li className={dropDown1}>
                        <div className="title">
                            <Link to="/subAdminDashboard/addAmbulanceSub" className="link" style={{ textDecoration: "none" }}>
                                <i className='bx bx-book-alt'><FaAmbulance /></i>
                                <span className="name">Ambulance</span>
                            </Link>
                            <i className='bx bxs-chevron-down' onClick={() => handleClickDropdown2()}><FaChevronDown /></i>
                        </div>
                        <div className="submenu">
                            <Link to="/subAdminDashboard/ambulanceBookingsSub" className="link" style={{ textDecoration: "none" }}>Bookings</Link>
                            <Link to="/subAdminDashboard/addAmbulanceSub" className="link" style={{ textDecoration: "none" }}>Add Ambulance</Link>
                            <Link to="/subAdminDashboard/ambulanceRequestSub" className="link" style={{ textDecoration: "none" }}>Ambulance Request</Link>
                            <Link to="/subAdminDashboard/activeAmbulanceSub" className="link" style={{ textDecoration: "none" }}>Ambulance Active</Link>
                            <Link to="/subAdminDashboard/rejectAmbulanceSub" className="link" style={{ textDecoration: "none" }}>Ambulance Reject</Link>
                        </div>
                    </li>
                </ul>
            </div>
            <section className="home">
                <div className="toggle-sidebar">
                    <FaBars className='bx bx-menu' onClick={() => handleClick()} style={{ width: 30, height: 30, marginLeft: 10 }} />
                </div>
                <Container style={{ padding: '20px' }} fluid>
                    <Row>
                        <Col md={12}><Outlet /></Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

