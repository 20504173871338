import { useMutation, useQuery } from '@apollo/client';
import React, { useState,useEffect } from 'react'
import swal from 'sweetalert';
import { FaBan, FaCheck ,FaArrowLeft} from "react-icons/fa";
import { Container, Row, Col, Form, Button, Card, Spinner, Table } from 'react-bootstrap'
import { QUERY_GET_PATHOLOGY_BY_ID, QUERY_PATHOLOGY_SLOT_BY_PATHOLOGY_ID } from '../../../graphql/queries';
import { MUTATION_DCREASE_SLOT_PATHOLOGY, MUTATION_INCREASE_SLOT_PATHOLOGY } from '../../../graphql/mutations';
import { useNavigate, useParams } from 'react-router-dom';
import Navigation from '../../Navigation';

export default function ViewPathologySlot() {
    const { id } = useParams()
    const [validated, setValidated] = useState(false);
    const [slot, setSlot] = useState("")
    const [time, setTime] = useState("")

    const PathologyId = localStorage.getItem("PathologyId")


  const { data: pathologyData, loading: pathologyLoading } = useQuery(QUERY_GET_PATHOLOGY_BY_ID, {
    variables: {
      "pathologyId": `${id}`
    }
  })
  console.log("pathologyData", pathologyData)




//   const handleSubmit = (event) => {
//     const form = event.currentTarget;
//     if (form.checkValidity() === false) {
//       event.preventDefault();
//       event.stopPropagation();
//     } else {
//       event.preventDefault();
//       createPathologySlot({
//         variables: {
//           "pathologySlotInput": {
//             "pathologyid": `${PathologyId}`,
//             "slot": `${slot}`,
//             "time": `${time}`
//           }
//         }
//       })
//       setSlot("")
//       setTime("")

//       swal({ title: 'Successfull!!!', text: 'Create Successfully', icon: 'success' });
//     }
//     setValidated(true);
//   };


//   const [blockPathologySlot] = useMutation(MUTATION_BLOCK_PATHOLOGY_SLOT, {
//     refetchQueries: [
//       QUERY_PATHOLOGY_SLOT_BY_PATHOLOGY_ID,
//       "getPathologySlotByPathologyId"
//     ]
//   })

//   const [activePathologySlot] = useMutation(M, {
//     refetchQueries: [
//       QUERY_PATHOLOGY_SLOT_BY_PATHOLOGY_ID,
//       "getPathologySlotByPathologyId"
//     ]
//   })



  
    const[increasePathologySlotCapacity] = useMutation(MUTATION_INCREASE_SLOT_PATHOLOGY,{
      refetchQueries:[
        QUERY_GET_PATHOLOGY_BY_ID,
        "getPathologyById"
      ]
    })

   
    const[decreasePathologySlotCapacity] =useMutation(MUTATION_DCREASE_SLOT_PATHOLOGY,{
      refetchQueries:[
        QUERY_GET_PATHOLOGY_BY_ID,
        "getPathologyById"
      ]
    })
   





  function handleIncrea(id,capacity){
    increasePathologySlotCapacity({
      variables:{
        "slotId": `${id}`,
       "pathologyId": `${ pathologyData && pathologyData.getPathologyById.id}`,
       "capacity": parseInt(capacity)
      }
    })
  }


  function handleDecrea(id,capacity){
    decreasePathologySlotCapacity({
      variables:{
        "slotId": `${id}`,
       "pathologyId": `${ pathologyData && pathologyData.getPathologyById.id}`,
       "capacity": parseInt(capacity)
      }
    })
  }

   




  return (
    <div>
    <Navigation/>
    <br></br> <br></br>  <br></br> <br></br>
    <Container>


      <Row>
        <Col>
          <h1 style={{ fontFamily: 'DM Sans', textAlign: 'center', fontSize: 20, fontWeight: 'bold', marginTop:20, marginBottom:20 }}>Pathology Slot</h1>

          <Table responsive style={{ fontFamily: 'DM Sans', fontSize: 12 }}>
            <tbody>
              {
                pathologyData && pathologyData.getPathologyById.slotDetails.map(data =>
                  <tr>
                    <td>{data.slotTimeInterval}</td>
                    <td>{data.capacity}</td>
                    <td>{data.status}</td>
                    <td><Button size="sm" variant="success" onClick={()=>handleIncrea(data.id,data.capacity)}>+</Button></td>
                    <td><Button size="sm" variant="danger"onClick={()=>handleDecrea(data.id,data.capacity)}>-</Button></td>
                   
                  </tr>
                )
              }
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  </div>
  )
}
