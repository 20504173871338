import React, { useEffect, useState } from 'react'
import Navigation from '../Navigation'
import { Tab, Row, Col, Nav, Container, Image, Card, Modal, Button, Form } from "react-bootstrap"
import PathologyProfile from './PathologyProfile'
import sadImg from "../../Images/SadImg.png"
import { useMutation, useQuery } from '@apollo/client';
import AddPathologySlot from './AddPathologySlot'
import { QUERY_AMBULANCE_BY_ID, QUERY_GET_PATHOLOGY_BY_ID } from '../../graphql/queries'
import AllPathologyBooking from './AllPathologyBooking'
import "../../ComponentCss/NewDashboard.css"
import { Bar, Pie } from "react-chartjs-2";
import { FaAlignJustify, FaHome, FaUsers, FaUsersCog, FaHeadset, FaKey, FaSignOutAlt, FaClock,FaBookOpen,FaInfoCircle } from "react-icons/fa";
import { UserData } from '../SuperAdmin/Data';
import { Chart as ChartJS } from "chart.js/auto";
import { LinkContainer } from 'react-router-bootstrap'
import { MUTATION_UPDATE_PATHOLOGY } from '../../graphql/mutations'

export default function PathologyDashboard() {


  const id = localStorage.getItem("PathologyId")

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [agree, setAgree] = useState();

  const handleChange = (event) => {
    const { checked, value } = event.currentTarget;
    setAgree(value)
  };

  

  const { data } = useQuery(QUERY_GET_PATHOLOGY_BY_ID, {
    variables:{
      "pathologyId": `${id}`
  }
  })


  console.log(data)


  useEffect(() => {
    if (data && data.getPathologyById.termCondition === null) {
      handleShow()
    }
  }, [data])

  console.log("data", data)




  const [updatePathology] = useMutation(MUTATION_UPDATE_PATHOLOGY, {
    refetchQueries: [
      QUERY_GET_PATHOLOGY_BY_ID,
      "getPathologyById"
    ]
  })




  function handleMenu() {

    let toggle = document.querySelector(".toggle");
    let navigation = document.querySelector(".navigation");
    let main = document.querySelector(".main");
    navigation.classList.toggle("active");
    main.classList.toggle("active");
  };

  const [userData, setUserData] = useState({
    labels: UserData.map((data) => data.year),
    datasets: [
      {
        label: "Users Gained",
        data: UserData.map((data) => data.userGain),
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#ecf0f1",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 1,
      },
    ],
  });




  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

    } else if (agree === "") {
      alert("Please Select CheckBox Check")
    } else {
      event.preventDefault();
      updatePathology({
        variables: {
          "updatePathologyInput": {
            "pathologyId": `${data && data.getPathologyById.id}`,
            "termCondition": `${agree}`
          }
        }
      })
      handleClose()
    }

    setValidated(true);
  };

  return (
    <>
      {/* <Navigation />
      <br></br>  <br></br>  <br></br>  <br></br>
      {
        data && data.getPathologyById.status === "confirm" ?
          <>
            <h5 style={{ textAlign: "center", background: "#bdc3c7", padding: 10, color: "#000" }}>Pathology Dashboard</h5>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row style={{ marginTop: -10 }}>
                <Col sm={2} style={{ background: "#bdc3c7", height: '100%' }}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first" style={{ marginTop: 10, fontSize: '12px', fontFamily: 'DM Sans', width: '100%', background: '#34495e', borderColor: '#34495e', borderRadius: '0px', padding: 10, color: '#fff', cursor: 'pointer' }}>Profile</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third" style={{ marginTop: 10, fontSize: '12px', fontFamily: 'DM Sans', width: '100%', background: '#34495e', borderColor: '#34495e', borderRadius: '0px', padding: 10, color: '#fff', cursor: 'pointer' }}>Add Pathology Slot</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={10}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <PathologyProfile />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <AllPathologyBooking />
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <AddPathologySlot />
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </>
          :
          <>
            <Container style={{ marginTop: 100 }}>
              <Card>
                <Image src={sadImg} style={{ width: 200, height: 200, marginTop: 20 }} className="mx-auto d-block" />
                <h6 style={{ textAlign: "center", fontFamily: "Dm sans", color: "#3498db" }}>Sorry!!! Not Found Any Confirmation </h6>
                <p style={{ textAlign: "center", fontFamily: "Dm sans", color: "#3498db", fontSize: 12 }}>Please Wait For Update</p>
              </Card>
            </Container>
          </>
      } */}

      <div >
        <div className="navigation">
          <br></br>

          <span className="title" style={{ fontSize: 20, fontFamily: 'DM Sans', fontWeight: 'bold', color: "#fff", textAlign: "center", marginLeft: 20, marginTop: 20 }}>Dashboard</span>
          <ul>
            <br></br><br></br><br></br>

            <li>
              <LinkContainer to="/">
                <a href="#">
                  <span className="icon">

                  </span>
                  <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }} eventKey="first"><FaHome /> Home</span>

                </a>
              </LinkContainer>
            </li>
            <li>
              <LinkContainer to="/PathologyProfile">
                <a href="#">
                  <span className="icon">
                  </span>
                  <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }}><FaUsers /> Profile</span>
                </a>
              </LinkContainer>
            </li>
            <li>
              <LinkContainer to="/AddPathologySlot">
                <a href="#">
                  <span className="icon"> </span>
                  <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }}><FaClock /> All Slot</span>
                </a>
              </LinkContainer>
            </li>

            <li>
                        <LinkContainer to="/Termcondition">
                            <a href="#">
                                <span className="icon"> </span>
                                <span className="title" style={{ fontSize: 12, fontFamily: 'DM Sans' }}><FaInfoCircle /> Terms & Condition</span>
                            </a>
                            </LinkContainer>
                        </li>

          </ul>

        </div>


        <div className="main">
          <div className="topbar">
            <div>
              <FaAlignJustify onClick={() => handleMenu()} className="toggle" />
            </div>
            <div className="search">
              <label>
                <input type="text" placeholder="Search here" />
              </label>
            </div>
            <div className="user">
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/2048px-Circle-icons-profile.svg.png" alt="" />
            </div>
          </div>
          <div className="cardBox">
            <div className="card">
              <div>
                <div className="numbers">1,504</div>
                <div className="cardName">Daily Revenue</div>
              </div>

            </div>
            <div className="card">
              <div>
                <div className="numbers">80</div>
                <div className="cardName">Total Order</div>
              </div>
              <div className="iconBx">
              </div>
            </div>
            <div className="card">
              <div>
                <div className="numbers">284</div>
                <div className="cardName">Total Slot</div>
              </div>
              <div className="iconBx">
              </div>
            </div>
            <div className="card">
              <div>
                <div className="numbers">7,842</div>
                <div className="cardName">Daily Customer</div>
              </div>
              <div className="iconBx">
              </div>
            </div>
          </div>
          <Row>
            <Col md={6}>
              <Bar data={userData} />
            </Col>
            <Col md={6}>
              <Pie data={userData} style={{ width: "280px", height: "280px" }} />
            </Col>
          </Row>

        </div>

      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >

        <h6 style={{ marginTop: 10, marginLeft: 10 }}>Term & Condition</h6>
        <Container>
          <div style={{ overflow: "scroll", height: 400 }}>
            <div>
              <h6 style={{ color: "#000" }}>1. Engagement</h6>
              <p style={{ fontSize: 12, color: "#000" }}>1.1 Subject to the provisions of this Agreement, Vendor shall provide, as an independent professional agency,
                medical services more specifically stated in Annexure - A (hereinafter referred to as the "medical services") and
                Company agrees to avail the medical services from Vendor. Vendor shall provide the above services on the
                Premises on the stipulated timings agreed with Company.</p>

              <p style={{ fontSize: 12, color: "#000" }}>1.2 Subject to earlier termination in accordance with the provisions of this Agreement, the term of the
                Agreement shall be for a period of one year from ________ _ __ to ______ ______.</p>

              <p style={{ fontSize: 12, color: "#000" }}>1.3 In consideration of the medical services provided by Vendor, Vendor agrees to pay the fee ("the charges"), as
                per the rates mutually agreed and appended herein as Annexure 'A'.</p>

              <p style={{ fontSize: 12, color: "#000" }}>1.4 The charges agreed and set forth in Annexure 'A' is exclusive of taxes, duties and levies, except for taxes
                based on the net income of Vendor. The payment shall be subject to Deduction of Tax at Source and such other
                taxes as may be applicable on Vendor.
              </p>
              <p style={{ fontSize: 12, color: "#000" }}>1.5 The charges shall be payable to Vendor/Company on a weekly basis. Vendor shall issue an invoice to
                Company, for the charges payable for the month for the medical services provided in that month and shall
                submit the invoice to Company (it may be auto generated).
              </p>
              <p style={{ fontSize: 12, color: "#000" }}>1.6 Vendor agrees to pay the charges to Company within 7 days from the receipt of the invoice or vice-versa.</p>

            </div>

            <div>
              <h6 style={{ color: "#000" }}>2. Obligations, representations and warranties by Vendor and rights of Company</h6>
              <p style={{ fontSize: 12, color: "#000" }}>2.1 Vendor has all necessary statutory and regulatory permissions, approvals and permits for the running and
                operation of its establishment for the conduct of its business.</p>

              <p style={{ fontSize: 12, color: "#000" }}>2.2 Vendor is in compliance of all laws, regulations and rules in the conduct of its business and the running of its
                business establishment</p>

              <p style={{ fontSize: 12, color: "#000" }}>2.3 Vendor represents and confirms that all the persons engaged by it in providing the medical services for
                Company Clients shall be its employees of Vendor (herein "Vendor personnel") and shall be under the control of
                Vendor. In any case, Vendor shall be solely responsible for (i) making any and all payments and benefits due to
                the Vendor personnel including without limitation, Wages (including minimum wages), Dearness Allowance,
                Bonus, Provident Fund, Gratuity, Employees State Insurance, workmen' compensation, terminal benefits, and
                any and all other benefits that are due to the Vendor Employees under applicable law; and (ii) maintaining
                appropriate registers and records, and filing of appropriate returns with appropriate authorities, as may be
                required under applicable law. Vendor shall ensure that none of the Vendor personnel make any claim against
                Company, or any of the Directors, Managers, officers, employees and representatives of Company, under any
                circumstance.</p>

              <p style={{ fontSize: 12, color: "#000" }}>2.4 Vendor will provide uniformed and trained Nurses/Technician’s/Drivers/Doctor’s, with approved background
                verification, to provide such services at the Premises, in duty shifts and locations as may be requested by
                Company from time to time. The provisions of said medical services, forming part of this Agreement are the
                binding obligation of Vendor for operations and performance of this Agreement. Vendor shall ensure that the
                Vendor personnel provided by it maintain perfect discipline and behaviour and they shall not in any manner
                cause any interference, annoyance or nuisance to Company or its employees, officers and customers.
              </p>
              <p style={{ fontSize: 12, color: "#000" }}>2.5 Vendor shall take and keep in force all necessary insurance cover in respect of Vendor personnel to be
                employed or engaged by it in connection with rendering of the medical services.
              </p>
              <p style={{ fontSize: 12, color: "#000" }}>2.6 It is agreed by and between the parties that this Agreement does not create a relationship of master and
                servant vis-a-vis Company to Vendor.
              </p>
              <p style={{ fontSize: 12, color: "#000" }}>2.7 Vendor represents and warrants to Company that Vendor is a company duly incorporated and has full
                corporate power and authority to enter into and perform its obligations set forth in this Agreement.
              </p>
              <p style={{ fontSize: 12, color: "#000" }}>2.8 Vendor shall provide adequate supervision to ensure correct performance of the said medical services in
                accordance with the prevailing assignment instructions agreed upon between Company and Vendor. Especially
                in the storage of drugs /vaccines to prevent misuse and in disposal of medical waste as per EHS norms.
              </p>
              <p style={{ fontSize: 12, color: "#000" }}>2.9 Vendor reserves the right to change the Nurses with prior intimation to Company, emergencies exempted. In
                such a case, Vendor will ensure that the replacement staff has undergone sufficient on the job training to
                maintain the desired level of service.
              </p>

              <p style={{ fontSize: 12, color: "#000" }}>2.10 The officers designated by Company will have full access to inspect the medical rooms from time to time to
                satisfy them for the services provided by Vendor and bring the same to the notice of Vendor to rectify the
                defects/shortcomings, if any.
              </p>

              <p style={{ fontSize: 12, color: "#000" }}>2.11 Vendor personnel shall behave in utmost professional manner and shall always be courteous and well
                mannered. The uniform and other apparels of the personnel shall be clean and neat.
              </p>

              <p style={{ fontSize: 12, color: "#000" }}>2.12 Each party shall indemnify and keep indemnified other party against all losses, damages, claims, liabilities,
                costs and expenses incurred or suffered by Company on account of breach or default by other as per this
                agreement. Vendor shall indemnify and keep indemnified Company against all losses, damages, claims, liabilities,
                costs and expenses incurred or suffered by Company on account of breach or default by Vendor (and/or its
                personnel) in performance or fulfilment of the obligations, covenants, representations or warranties set forth
                herein or against any claim, loss, damage occurred or caused to Company due to acts or omissions or
                carelessness or negligence of the personnel employed or engaged by Company for provision of the medical
                services.

              </p>

              <p style={{ fontSize: 12, color: "#000" }}>2.13 That it is expressly understood and agreed between the parties to this Agreement that the nurses and other
                persons deployed by the Vendor shall be the employees of the Vendor for all intents and purpose and in no case,
                shall there be a relationship of employer and employee between the said persons and Company.

              </p>
            </div>

            <div>
              <h6 style={{ color: "#000" }}>3. Company's Obligations</h6>
              <p style={{ fontSize: 12, color: "#000" }}>3.1 Company Client shall pay separately for any additional services provided by Vendor that are not covered
                under this Agreement.
              </p>

              <p style={{ fontSize: 12, color: "#000" }}>3.2 The medical services stipulated in this Agreement are for the exclusive use of Company and its Clients
                (via BHARP app) only and cannot be subcontracted or used by a third party without the prior written consent of
                Company. However, Company shall have the right to allow the use of the medical services by its affiliates,
                subsidiaries, holding companies, group companies, officers and employees, and the same shall not constitute
                subcontracting, provided however, the payment of the fees/charges contemplated herein shall remain the
                responsibility of Company</p>

              <p style={{ fontSize: 12, color: "#000" }}>3.3 The company provide consumers via application named as “BHARP”. On the basis of consumer visit or
                services availed at vendors end, the vendor shall be liable to pay the commission to company as per agreed in
                given Annexure ‘A’.
              </p>
            </div>
            <div>
              <h6 style={{ color: "#000" }}>4. Confidentiality</h6>
              <p style={{ fontSize: 12, color: "#000" }}>Vendor and the assigned medical personnel shall exercise utmost diligence in keeping confidential all the
                sensitive or other information of Company (its associates, affiliates and customers including their respective
                Directors, officers, employees and other representatives). This Clause does not apply to information, which is or
                becomes public knowledge, otherwise than through the acts/omissions of Vendor and its staff.
              </p>
            </div>
            <div>
              <h6 style={{ color: "#000" }}>5. Force Majeure</h6>
              <p style={{ fontSize: 12, color: "#000" }}>No party shall be in breach of its obligations under this Agreement to the extent, such failure to perform its
                obligations results solely from storm, lightning, floods and other acts of God not within the control of the
                defaulting party, provided that such failure is not caused or contributed by any negligence of the defaulting party
                or its personnel. If due to such force majeure, medical services are not provided, then the obligation to pay the
                charges shall stand suspended. If such an event of force majeure occurs, the defaulting party shall immediately
                inform the other party of the occurrence of the force majeure and its impact on the performance of the its
                obligations under this Agreement. If such an event of force majeure continues for a period of 30 days both party
                shall have the option to terminate this Agreement immediately and the notice period for the termination, as
                provided in this Agreement, shall not be applicable under such circumstances
              </p>
            </div>

            <div>
              <h6 style={{ color: "#000" }}>6. Variation/Amendment</h6>
              <p style={{ fontSize: 12, color: "#000" }}>No variation, amendment, modification or addition to this Agreement shall be effective or binding on either of
                the parties unless set forth in writing and executed by them through their authorized representatives.
              </p>
            </div>

            <div>
              <h6 style={{ color: "#000" }}>7. Termination</h6>
              <p style={{ fontSize: 12, color: "#000" }}>7.1 As noted hereinabove, this Agreement is valid for a period of one year, unless renewed in writing. It is clearly
                understood that this Agreement is for the Premises of Company as mentioned herein above. The Agreement is
                subject to renewal for a further period of one year by mutual consent between the two parties.
              </p>
              <p style={{ fontSize: 12, color: "#000" }}>7.2 This Agreement is terminable by either party by giving 30 days advance notice, in writing.
              </p>
              <p style={{ fontSize: 12, color: "#000" }}>7.3 Notwithstanding the above, Company shall have a right to cancel or terminate this Agreement, without any
                advance notice or payment in lieu thereof, if there shall have occurred dissolution or liquidation or any order is
                made or resolution, law or regulation passed or other action taken for its dissolution or liquidation or shall
                otherwise enter into liquidation.
              </p>
              <p style={{ fontSize: 12, color: "#000" }}>7.4 Any cancellation or termination of this Agreement shall not constitute a waiver by Company of any obligation
                that by its terms shall survive such cancellation or termination or a waiver of any claim which Company may
                have for actual damages caused by reason of, or relieve Vendor from liability for, any breach of the terms and
                conditions of this Agreement prior to such termination or cancellation.
              </p>
            </div>

            <div>
              <h6 style={{ color: "#000" }}>8. Settlement of Dispute</h6>
              <p style={{ fontSize: 12, color: "#000" }}>Parties shall endeavour to resolve any disputes and differences arising out of or relating to this Agreement
                including interpretation of its terms, through joint discussions. Any dispute, difference or question that is not
                resolved through such joint discussions within a period of 30 days, shall be referred for arbitration to a mutually
                appointed sole arbitrator thereafter, failing which the provisions of the Arbitration and Conciliation Act, 1996
                will apply. The arbitration proceedings shall be held in Buxar, Bihar, in accordance with the provisions of the
                Arbitration & Conciliation Act 1996 and the rules made there-under, as amended from time to time.
              </p>

            </div>

            <div>
              <h6 style={{ color: "#000" }}>9. Jurisdiction</h6>
              <p style={{ fontSize: 12, color: "#000" }}>Subject to the provisions of Clause 8, all actions, proceedings, and suits under this Agreement or arising from this
                Agreement shall be subject to the exclusive jurisdiction of courts in Buxar.
              </p>
            </div>

            <div>
              <h6 style={{ color: "#000" }}>10. Entire Agreement</h6>
              <p style={{ fontSize: 12, color: "#000" }}>This Agreement sets forth the entire agreement and understanding between the parties as to the subject matter
                hereof and shall supersede and override all previous communications, negotiations, commitments, agreements,
                understandings, either oral or written, between the parties with respect to the subject matter of this Agreement.
              </p>
            </div>


            <div>
              <h6 style={{ color: "#000" }}>11. Assignment
              </h6>
              <p style={{ fontSize: 12, color: "#000" }}>The rights and obligations under this Agreement are personal to Vendor and shall not be assigned by it, to any
                third party, without the express prior written consent of Company.
              </p>
            </div>

            <div>
              <h6 style={{ color: "#000" }}>12. Waiver</h6>
              <p style={{ fontSize: 12, color: "#000" }}>The failure, with or without intent, of any party to insist upon the performance (in strict conformity with the
                literal requirements) by the other party of any term or stipulation of this Agreement, shall not be treated or
                deemed to constitute a modification of any terms or stipulations of this Agreement. Nor shall such failure or
                election be deemed to constitute a waiver of the right of such Party at any time whatsoever thereafter to insist
                upon performance by the other strictly in accordance with any terms or provisions hereof.
              </p>
            </div>
            <div>
              <h6 style={{ color: "#000" }}>13. Additional Services and/or Change in Services</h6>
              <p style={{ fontSize: 12, color: "#000" }}>Company may, at any time, request additions and/or changes to the Services. Such additions and/or changes,
                including any fees or fee adjustments related to such additions and/or changes, shall be documented with a
                Schedule or an amended Schedule, where appropriate.
              </p>
            </div>

            <div>
              <h6 style={{ color: "#000" }}>14. Successors and Assigns</h6>
              <p style={{ fontSize: 12, color: "#000" }}>This Agreement shall inure to the benefit of and be binding upon the successors and permitted assigns of
                Company and Vendor. Neither party may assign its rights or obligations hereunder without the prior written
                consent of the other party.
              </p>
            </div>



            <div>
              <h6 style={{ color: "#000" }}>15. Miscellaneous</h6>
              <p style={{ fontSize: 12, color: "#000" }}>15.1 The headings used herein are for convenience only and will not affect the interpretation of this Agreement.
              </p>
              <p style={{ fontSize: 12, color: "#000" }}>15.2 The relationship between the parties is that of independent contractors. Nothing in this Agreement will be
                deemed or construed to create a joint venture, agency, or partnership between the parties for any purpose or
                between the partners, officers, members, or employees of the parties by virtue of either this Agreement or
                actions taken pursuant to this Agreement.
              </p>
              <p style={{ fontSize: 12, color: "#000" }}>15.3 If any provision of this Agreement or portion thereof is declared invalid, the remaining provisions will
                nevertheless remain in full force and effect.
              </p>
            </div>

          </div>
        </Container>







        <div style={{ marginLeft: 20 }}>
          <Form noValidate onSubmit={handleSubmit}>

            <Form.Group className="mb-3">
              <Form.Check
                required
                name="terms"
                value="Agree"
                label="Agree to terms and conditions"
                onChange={handleChange}

                feedbackType="invalid"
                id="validationFormik0"
              />


            </Form.Group>

            <Button type="submit" style={{ border: "none", marginBottom: 10 }}>Submit </Button>
          </Form>
          {
            agree === "" ?
              <p style={{ color: "red" }}>Please Select CheckBox Check</p>
              :
              <></>

          }


        </div>

      </Modal>

    </>
  )
}
