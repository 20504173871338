import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Image, Table, Spinner, Modal, Button, Form, Carousel } from 'react-bootstrap'
import Navigation from "../../Navigation"
import { useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { useJsApiLoader, GoogleMap, MarkerF } from '@react-google-maps/api'
import { FaPen, FaPlusCircle, FaTrash } from 'react-icons/fa'
import { QUERY_AMBULANCE_BY_ID, QUERY_GET_AMBULANCE_BY_ID } from '../../../graphql/queries'
import { MUTATION_UPDATE_AMBULANCE, MUTATION_UPDATE_AMBULANCE_FACILITY, MUTATION_EDIT_AMBULANCE_DETAILS, MUTATION_EDIT_DRIVER_DETAILS, MUTATION_ADD_FACILITY, MUTATION_DELETE_FACILITY, MUTATION_EDIT_DRIVER_IMAGE, MUTATION_EDIT_DRIVER_LICENSE_IMAGE } from '../../../graphql/mutations'
import AWS from 'aws-sdk';
import uniqid from 'uniqid';

const ID = process.env.REACT_APP_AWS_ACCESSS_ID;
const SECRET = process.env.REACT_APP_AWS_ACCESSS_KEY;
const BUCKET_NAME = 'bharp-ambulance';

const s3 = new AWS.S3({
   accessKeyId: ID,
   secretAccessKey: SECRET,
});

const imageKey = []

export default function AmbulanceDetail() {

   const { id } = useParams()
   const { data, loading, refetch } = useQuery(QUERY_GET_AMBULANCE_BY_ID, {
      variables: {
         "ambulanceId": `${id}`
      }
   })

   const [show2, setShow2] = useState(false);
   const handleClose2 = () => setShow2(false);
   const handleShow2 = () => setShow2(true);

   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);

   const [show3, setShow3] = useState(false);
   const handleClose3 = () => setShow3(false);
   const handleShow3 = () => setShow3(true);

   const [show4, setShow4] = useState(false);
   const handleClose4 = () => setShow4(false);
   const handleShow4 = () => setShow4(true);

   const [show5, setShow5] = useState(false);
   const handleClose5 = () => setShow5(false);
   const handleShow5 = () => setShow5(true);

   const [show6, setShow6] = useState(false);
   const handleClose6 = () => setShow6(false);
   const handleShow6 = () => setShow6(true);

   const [show7, setShow7] = useState(false);
   const handleClose7 = () => setShow7(false);
   const handleShow7 = () => setShow7(true);

   const [show8, setShow8] = useState(false);
   const handleClose8 = () => setShow8(false);
   const handleShow8 = () => setShow8(true);

   // Edit vehicle details

   const [validatedVehicleEdit, setValidatedVehicleEdit] = useState(false);
   const [showVehicleDetailEdit, setShowVehicleDetailEdit] = useState(false);
   const handleVehicleDetailEditClose = () => setShowVehicleDetailEdit(false);
   const handleVehicleDetailEditShow = () => setShowVehicleDetailEdit(true);

   const [vId, setVid] = useState('')
   const [vNo, setVno] = useState('')
   const [licNo, setLicNo] = useState('')
   const [vehType, setVehType] = useState('')
   const [ambOwner, setAmbOwner] = useState('')

   function clickAmbulanceDetailHandle(id, vehicleNo, licenceNo, vehicleType, ambulanceOwnerShip) {
      setVid(id)
      setVno(vehicleNo)
      setLicNo(licenceNo)
      setVehType(vehicleType)
      setAmbOwner(ambulanceOwnerShip)
      handleVehicleDetailEditShow()
   }

   const handleVehicleEdit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
         event.preventDefault();
         event.stopPropagation();
         setValidatedVehicleEdit(true);
      }
      else {
         event.preventDefault();
         setValidatedVehicleEdit(false);
         updateAmbulanceDetails({
            variables: {
               "editVehicleDetailInput": {
                  "vehicleId": `${vId}`,
                  "vehicleNo": `${vNo}`,
                  "licenceNo": `${licNo}`,
                  "vehicleType": `${vehType}`,
                  "ambulanceOwnerShip": `${ambOwner}`,
               }
            }
         }).then(() => {
            handleVehicleDetailEditClose()
         })
      }
   };


   const [updateAmbulanceDetails] = useMutation(MUTATION_EDIT_AMBULANCE_DETAILS, {
      refetchQueries: [
         QUERY_GET_AMBULANCE_BY_ID,
         "getAmbulanceById"
      ]
   })



   //Edit driver details

   const [validatedDriverEdit, setValidatedDriverEdit] = useState(false);
   const [showDriverDetailEdit, setShowDriverDetailEdit] = useState(false);
   const handleDriverDetailEditClose = () => setShowDriverDetailEdit(false);
   const handleDriverDetailEditShow = () => setShowDriverDetailEdit(true);

   const [driverFname, setDriverFname] = useState('')
   const [driverLname, setDriverLname] = useState('')
   const [driverEmail, setDriverEmail] = useState('')
   const [driverContact, setDriverContact] = useState('')

   function clickDriverDetailHandle(id, driverFName, driverlName, driverEmail, driverContact) {
      setVid(id)
      setDriverFname(driverFName)
      setDriverLname(driverlName)
      setDriverEmail(driverEmail)
      setDriverContact(driverContact)
      handleDriverDetailEditShow()
   }

   const handleDriverEdit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
         event.preventDefault();
         event.stopPropagation();
         setValidatedDriverEdit(true);
      }
      else {
         event.preventDefault();
         setValidatedDriverEdit(false);
         updateDriverDetails({
            variables: {
               "editDriverDetailInput": {
                  "vehicleId": `${vId}`,
                  "driverFName": `${driverFname}`,
                  "driverlName": `${driverLname}`,
                  "driverContact": `${driverContact}`,
                  "driverEmail": `${driverEmail}`
               }
            }
         }).then(() => {
            handleDriverDetailEditClose()
         })
      }
   };

   const [updateDriverDetails] = useMutation(MUTATION_EDIT_DRIVER_DETAILS, {
      refetchQueries: [
         QUERY_GET_AMBULANCE_BY_ID,
         "getAmbulanceById"
      ]
   })



   const [stop, setStop] = useState(false)
   const [bharpdis, setBhaprDis] = useState(data && data.getAmbulanceById.bharpDiscount)
   const [vendorMar, setVendorMar] = useState(data && data.getAmbulanceById.vendorMargin)
   const [localPriceParKm, setLocalPriceParKm] = useState(data && data.getAmbulanceById.localPriceKm)
   const [localBookingPrice, setLocalBookingPrice] = useState(data && data.getAmbulanceById.localBasePrice)
   const [outlocalBookingPrice, setOutLocalBookingPrice] = useState(data && data.getAmbulanceById.outLocalBasePrice)
   const [outlocalPriceParKm, setOutLocalPriceParKm] = useState(data && data.getAmbulanceById.outLocalPerKmPrice)
   const [localThresholderPriceParKm, setLocalThresholderPriceParKm] = useState(data && data.getAmbulanceById.localThresholderKm)
   const [acPriceParKm, setAcPriceParKm] = useState(data && data.getAmbulanceById.acPerKmPrice)
   const [nightServicePrice, setNightServicePrice] = useState(data && data.getAmbulanceById.nightServicePrice)


   if (data && stop === false) {
      setBhaprDis(data && data.getAmbulanceById.bharpDiscount)
      setVendorMar(data && data.getAmbulanceById.vendorMargin)
      setLocalPriceParKm(data && data.getAmbulanceById.localPriceKm)
      setLocalBookingPrice(data && data.getAmbulanceById.localBasePrice)
      setOutLocalBookingPrice(data && data.getAmbulanceById.outLocalBasePrice)
      setOutLocalPriceParKm(data && data.getAmbulanceById.outLocalBasePrice)
      setLocalThresholderPriceParKm(data && data.getAmbulanceById.localThresholderKm)
      setAcPriceParKm(data && data.getAmbulanceById.acPerKmPrice)
      setNightServicePrice(data && data.getAmbulanceById.nightServicePrice)
      setStop(true)

   }

   const [facilityId, setFacilityId] = useState()
   const [facilityName, setFacilityName] = useState()
   const [facilityPrice, setFacilityPrice] = useState()

   function handEditFacility(data) {
      setFacilityId(data.id)
      setFacilityName(data.facilityName)
      setFacilityPrice(data.facilityPrice)
      handleShow2()
   }

   const [validated, setValidated] = useState(false);

   const [updateAmbulancefacility] = useMutation(MUTATION_UPDATE_AMBULANCE_FACILITY, {
      refetchQueries: [
         QUERY_GET_AMBULANCE_BY_ID,
         "getAmbulanceById"
      ]
   })

   const handleSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
         event.preventDefault();
         event.stopPropagation();
         setValidated(true);
      } else {
         setValidated(false);
         event.preventDefault();
         updateAmbulancefacility({
            variables: {
               "ambulanceId": `${data && data.getAmbulanceById.id}`,
               "facility": `${facilityId}`,
               "facilityPrice": `${facilityPrice}`
            }
         })
         handleClose2()
      }
   };

   const [updateAmbulance] = useMutation(MUTATION_UPDATE_AMBULANCE, {
      refetchQueries: [
         QUERY_GET_AMBULANCE_BY_ID,
         "getAmbulanceById"
      ]
   })

   function handleChange() {
      updateAmbulance({
         variables: {
            "updateAmbulanceInput": {
               "ambulanceId": `${data && data.getAmbulanceById.id}`,
               "vendorMargin": `${vendorMar}`,
               "bharpDiscount": `${bharpdis}`,
               "localThresholderKm": `${localThresholderPriceParKm}`,
               "localBasePrice": `${localBookingPrice}`,
               "localPriceKm": `${localPriceParKm}`,
               "outLocalBasePrice": `${outlocalBookingPrice}`,
               "outLocalPerKmPrice": `${outlocalPriceParKm}`,
               "acPerKmPrice": `${acPriceParKm}`,
               "nightServicePrice": `${nightServicePrice}`
            }
         }
      })
      handleClose()
   }

   setInterval(() => {
      refetch()
   }, 5000)

   useEffect(() => {
      if (data) {
         setLatitude(parseFloat(data && data.getAmbulanceById.latitude))
         setLongtude(parseFloat(data && data.getAmbulanceById.longitude))
      }
   }, [data])

   const [latitude, setLatitude] = useState(parseFloat(data && data.getAmbulanceById.latitude))
   const [longtude, setLongtude] = useState(parseFloat(data && data.getAmbulanceById.longitude))

   const { isLoaded } = useJsApiLoader({
      googleMapsApiKey: 'AIzaSyB2eRRn8bW1VVh4wj4AEaYd3F_RgEP2mE4'
   })

   function openImageViewer(imglist) {
      handleShow3()
   }



   const [validatedFacility, setValidatedFacility] = useState(false);
   const [facilityNameState, setFacilityNameState] = useState('');
   const [facilityPriceState, setFacilityPriceState] = useState('');

   const [addFacility] = useMutation(MUTATION_ADD_FACILITY, {
      refetchQueries: [
         QUERY_GET_AMBULANCE_BY_ID,
         "getAmbulanceById"
      ]
   })
   const [deleteFacilityMutation] = useMutation(MUTATION_DELETE_FACILITY, {
      refetchQueries: [
         QUERY_GET_AMBULANCE_BY_ID,
         "getAmbulanceById"
      ]
   })


   const handleFacilitySubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
         event.preventDefault();
         event.stopPropagation();
         setValidatedFacility(true);
      }
      else {
         event.preventDefault();
         setValidatedFacility(false);
         addFacility({
            variables: {
               "addFacilityInput": {
                  "ambulanceId": `${id}`,
                  "facilityName": `${facilityNameState}`,
                  "facilityPrice": `${facilityPriceState}`,
               }
            }
         }).then(() => {
            setFacilityNameState('')
            setFacilityPriceState('')
            handleClose6()
         })
      }

   };

   function deleteFacilityHandel(fId) {
      deleteFacilityMutation({
         variables: {
            "abmulanceId": `${id}`,
            "facilityId": `${fId}`,
         }
      })
   }

   const [validatedDriverImage, setValidatedDriverImage] = useState(false);
   const [driverImageSelect, setDriverImageSelect] = useState();

   const [editDriverImage] = useMutation(MUTATION_EDIT_DRIVER_IMAGE, {
      refetchQueries: [
         QUERY_GET_AMBULANCE_BY_ID,
         "getAmbulanceById"
      ]
   })


   const handleDriverImageEditSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
         event.preventDefault();
         event.stopPropagation();
         setValidatedDriverImage(true);
      }
      else {
         event.preventDefault();
         setValidatedDriverImage(false);

         try {
            const uniqueId = uniqid();
            var regex = new RegExp('[^.]+$');
            var extension = driverImageSelect.name.match(regex);
            var uniqueFileName = "driverImg" + '-' + uniqueId + '-' + uniqueId + '.' + extension[0];
            const params = {
               Bucket: BUCKET_NAME,
               Key: uniqueFileName,
               Body: driverImageSelect,
            };
            s3.upload(params, function (err, data) {
               if (err) {
                  throw err;
               } else {
                  editDriverImage({
                     variables: {
                        "ambulanceId": `${id}`,
                        "imageKey": `${uniqueFileName}`
                     }
                  }).then(() => {
                     handleClose7()
                  })
               }
            });
         } catch (err) {
            console.log(err);
         }
      }
   }



   const [validatedDriverLicenseImage, setValidatedDriverLicenseImage] = useState(false);
   const [driverLicenseImageSelect, setDriverLicenseImageSelect] = useState();

   const [editDriverLicenseImage] = useMutation(MUTATION_EDIT_DRIVER_LICENSE_IMAGE, {
      refetchQueries: [
         QUERY_GET_AMBULANCE_BY_ID,
         "getAmbulanceById"
      ]
   })


   const handleDriverLicenseImageEditSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
         event.preventDefault();
         event.stopPropagation();
         setValidatedDriverLicenseImage(true);
      }
      else {
         event.preventDefault();
         setValidatedDriverLicenseImage(false);

         try {
            const uniqueId = uniqid();
            var regex = new RegExp('[^.]+$');
            var extension = driverLicenseImageSelect.name.match(regex);
            var uniqueFileName = "driverLicenseImg" + '-' + uniqueId + '-' + uniqueId + '.' + extension[0];
            const params = {
               Bucket: BUCKET_NAME,
               Key: uniqueFileName,
               Body: driverLicenseImageSelect,
            };
            s3.upload(params, function (err, data) {
               if (err) {
                  throw err;
               } else {
                  editDriverLicenseImage({
                     variables: {
                        "ambulanceId": `${id}`,
                        "imageKey": `${uniqueFileName}`
                     }
                  }).then(() => {
                     handleClose8()
                  })
               }
            });
         } catch (err) {
            console.log(err);
         }
      }
   }


   return (
      <div>

         <Navigation />
         {
            loading ?
               <>
                  <div className='mx-auto d-block'>
                     <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                     </Spinner>
                  </div>
               </>
               :
               <>
                  <Container style={{ background: "#fff", marginBottom: 20, borderRadius: 10, marginTop: 130 }}>
                     <Row>
                        <h5 style={{ textAlign: "center", marginTop: "10px", fontFamily: "Dm sans", fontWeight: "800" }}>Ambulance Details</h5>
                        <hr></hr>
                        <Col md={6}>
                           <Table bordered hover>
                              <tbody style={{ fontSize: 12 }}>
                                 <tr>
                                    <th colSpan={2} style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 15 }}>Vehicle Details <FaPen style={{ marginTop: '-4px', color: '#3498db', cursor: 'pointer' }} onClick={() => clickAmbulanceDetailHandle(data && data.getAmbulanceById.id, data && data.getAmbulanceById.vehicleNo, data && data.getAmbulanceById.licenceNo, data && data.getAmbulanceById.vehicleType, data && data.getAmbulanceById.ambulanceOwnerShip)} /></th>
                                 </tr>
                                 <tr>
                                    <th colSpan={2}>
                                       <Carousel style={{ width: '100%', height: '100%' }}>
                                          {
                                             data && data.getAmbulanceById.vehicleImg.map((Image, index) => {
                                                return (
                                                   <Carousel.Item style={{ width: '100%', height: '100%' }}>
                                                      <img
                                                         style={{ width: '100%', height: '400px' }}
                                                         src={`https://bharp-ambulance.s3.ap-south-1.amazonaws.com/${Image}`}
                                                         alt="Ambulance Pictures"
                                                         onClick={() => openImageViewer(Image)}
                                                      />
                                                   </Carousel.Item>
                                                )
                                             })
                                          }
                                       </Carousel>
                                       <h1 style={{ fontFamily: "Dm sans", textAlign: "center", fontWeight: "800", fontSize: '12px', marginTop: '10px' }}>Vehicle's Image <FaPen style={{ marginTop: '-4px', color: '#3498db', cursor: 'pointer' }} onClick={() => alert('Functionality will be available soon...')} /></h1>
                                    </th>
                                 </tr>
                                 <tr>
                                    <td style={{ fontWeight: 'bold' }}>Vehicle No</td>
                                    <td>{data && data.getAmbulanceById.vehicleNo}</td>
                                 </tr>
                                 <tr>
                                    <td style={{ fontWeight: 'bold' }}>License No</td>
                                    <td>{data && data.getAmbulanceById.licenceNo}</td>
                                 </tr>
                                 <tr>
                                    <td style={{ fontWeight: 'bold' }}>Vehicle Type</td>
                                    {
                                       data && data.getAmbulanceById.vehicleTypeOther === "" ?
                                          <td>{data && data.getAmbulanceById.vehicleType}</td> :
                                          <td>{data && data.getAmbulanceById.vehicleTypeOther}</td>
                                    }
                                 </tr>
                                 <tr>
                                    <td style={{ fontWeight: 'bold' }}>Ownership</td>
                                    {
                                       data && data.getAmbulanceById.ambulanceOwnerShipOther === "" ?
                                          <td>{data && data.getAmbulanceById.ambulanceOwnerShip}</td> :
                                          <td>{data && data.getAmbulanceById.ambulanceOwnerShipOther}</td>
                                    }
                                 </tr>
                              </tbody>
                           </Table>
                        </Col>
                        <Col md={6}>
                           <Table bordered hover>
                              <tbody style={{ fontSize: 12 }}>
                                 <tr>
                                    <th colSpan={2} style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 15 }}>Owner Detail <FaPen style={{ marginTop: '-4px', color: '#3498db', cursor: 'pointer' }} onClick={() => clickDriverDetailHandle(data && data.getAmbulanceById.id, data && data.getAmbulanceById.driverFName, data && data.getAmbulanceById.driverlName, data && data.getAmbulanceById.driverEmail, data && data.getAmbulanceById.driverContact)} /></th>
                                 </tr>
                                 <tr>
                                    <th>
                                       <Image onClick={handleShow4} src={`https://bharp-ambulance.s3.ap-south-1.amazonaws.com/${data && data.getAmbulanceById.driverImg}`} style={{ width: '100px', height: 180 }} className="img-fluid mx-auto d-block" alt="Image Not Available" fluid />
                                       <h1 style={{ fontFamily: "Dm sans", textAlign: "center", fontWeight: "800", fontSize: '12px', marginTop: '10px' }}>Driver's Image <FaPen style={{ marginTop: '-4px', color: '#3498db', cursor: 'pointer' }} onClick={() => handleShow7()} /></h1>
                                    </th>
                                    <th>
                                       <Image onClick={handleShow5} src={`https://bharp-ambulance.s3.ap-south-1.amazonaws.com/${data && data.getAmbulanceById.licenceImg}`} style={{ width: '100%', height: 180 }} className="img-fluid mx-auto d-block" alt="Image Not Available" fluid />
                                       <h1 style={{ fontFamily: "Dm sans", textAlign: "center", fontWeight: "800", fontSize: '12px', marginTop: '10px' }}>Driver's License Image <FaPen style={{ marginTop: '-4px', color: '#3498db', cursor: 'pointer' }} onClick={() => handleShow8()} /></h1>
                                    </th>
                                 </tr>
                                 <tr>
                                    <td style={{ fontWeight: 'bold' }}>Name</td>
                                    <td>{data && data.getAmbulanceById.driverFName} {data && data.getAmbulanceById.driverlName}</td>
                                 </tr>
                                 <tr>
                                    <td style={{ fontWeight: 'bold' }}>Email</td>
                                    <td>{data && data.getAmbulanceById.driverEmail}</td>
                                 </tr>
                                 <tr>
                                    <td style={{ fontWeight: 'bold' }}>Contact</td>
                                    <td>{data && data.getAmbulanceById.driverContact}</td>
                                 </tr>
                              </tbody>
                           </Table>
                        </Col>
                     </Row>
                     <hr></hr>

                     <Row>
                        <Col md={6}>
                           <Table bordered hover>
                              <tbody style={{ fontSize: 12 }}>
                                 <tr>
                                    <th colSpan={2} style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 15 }}>Pricing Details <FaPen style={{ marginTop: '-4px', color: '#3498db', cursor: 'pointer' }} onClick={() => handleShow()} /></th>
                                 </tr>
                                 <tr>
                                    <td style={{ fontWeight: 'bold' }}>Local Thresholder Per Km</td>
                                    <td>{data && data.getAmbulanceById.localThresholderKm}</td>
                                 </tr>
                                 <tr>
                                    <td style={{ fontWeight: 'bold' }}>Local Price Per Km</td>
                                    <td>{data && data.getAmbulanceById.localPriceKm}</td>
                                 </tr>
                                 <tr>
                                    <td style={{ fontWeight: 'bold' }}>Local Booking Price</td>
                                    <td>{data && data.getAmbulanceById.localBasePrice}</td>
                                 </tr>
                                 <tr>
                                    <td style={{ fontWeight: 'bold' }}>Out Local Price Per Km</td>
                                    <td>{data && data.getAmbulanceById.outLocalPerKmPrice}</td>
                                 </tr>
                                 <tr>
                                    <td style={{ fontWeight: 'bold' }}>Out Local Booking Price</td>
                                    <td>{data && data.getAmbulanceById.outLocalBasePrice}</td>
                                 </tr>
                                 <tr>
                                    <td style={{ fontWeight: 'bold' }}>Bharp Discount</td>
                                    <td>{data && data.getAmbulanceById.bharpDiscount}%</td>
                                 </tr>
                                 <tr>
                                    <td style={{ fontWeight: 'bold' }}>Vendor Margin</td>
                                    <td>{data && data.getAmbulanceById.vendorMargin}%</td>
                                 </tr>
                                 <tr>
                                    <td style={{ fontWeight: 'bold' }}>AC Available</td>
                                    <td>{data && data.getAmbulanceById.acAvailable}</td>
                                 </tr>
                                 <tr>
                                    <td style={{ fontWeight: 'bold' }}>AC Price Per Km</td>
                                    <td>{data && data.getAmbulanceById.acPerKmPrice}</td>
                                 </tr>
                              </tbody>
                           </Table>
                        </Col>
                        <Col md={6}>
                           <Table bordered hover>
                              <tbody style={{ fontSize: 12 }}>
                                 <tr>
                                    <th colSpan={3} style={{ textAlign: 'center', fontWeight: 'bold' }}>Live Location</th>
                                 </tr>
                                 <tr>
                                    <th>
                                       {
                                          !isLoaded ?
                                             <>
                                                <div className="mx-auto d-block">
                                                   <Spinner animation="border" />
                                                </div>
                                             </>
                                             :
                                             <>
                                                <GoogleMap center={{ lat: latitude, lng: longtude }} zoom={15} mapContainerStyle={{ width: '100%', height: '300px' }}>
                                                   <MarkerF position={{ lat: latitude, lng: longtude }} />
                                                </GoogleMap>
                                             </>
                                       }
                                    </th>
                                 </tr>
                              </tbody>
                           </Table>
                        </Col>
                     </Row>
                     <hr></hr>
                     <Row>
                        <Col md={12}>
                           <Table bordered hover>
                              <tbody style={{ fontSize: 12 }}>
                                 <tr>
                                    <th colSpan={4} style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 15 }}>Facilities <FaPlusCircle style={{ marginTop: '-3px', color: '#2ecc71', cursor: 'pointer' }} onClick={() => handleShow6()} /></th>
                                 </tr>
                                 <tr>
                                    <th>Facility Name</th>
                                    <th>Facility Price</th>
                                    <th>Edit Price</th>
                                    <th>Delete</th>
                                 </tr>
                                 {
                                    data && data.getAmbulanceById.facility.length === 0 ?
                                       <tr>
                                          <td colSpan={4} style={{ fontFamily: "Dm sans", textAlign: "center", fontWeight: "800", fontSize: '12px', marginTop: '10px', color: '#e74c3c' }}>
                                             No Facility Available
                                          </td>
                                       </tr>
                                       :
                                       data && data.getAmbulanceById.facility.map(facilityData =>
                                          <tr>
                                             <td>{facilityData.facilityName}</td>
                                             <td>₹{facilityData.facilityPrice}</td>
                                             <td><Button onClick={() => handEditFacility(facilityData)} size='sm'><FaPen style={{ marginTop: '-4px' }} /></Button></td>
                                             <td><Button onClick={() => deleteFacilityHandel(facilityData.id)} size='sm' variant='danger'><FaTrash style={{ marginTop: '-4px' }} /></Button></td>
                                          </tr>
                                       )
                                 }
                              </tbody>
                           </Table>
                        </Col>
                     </Row>

                  </Container>
               </>
         }

         <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
               <Modal.Title>Add Details</Modal.Title>
            </Modal.Header>
            <div style={{ margin: 20 }}>

               <Row>
                  <Col md={6}>
                     <label className='mx-auto d-block' style={{ marginLeft: 20, fontWeight: "bold", fontSize: 12 }}>Bharp Discount %</label>
                     <input onChange={(e) => setBhaprDis(e.target.value)} value={bharpdis} />
                  </Col>
                  <Col md={6}>
                     <label className='mx-auto d-block' style={{ marginLeft: 20, fontWeight: "bold", fontSize: 12 }}>Vendor Margin %</label>
                     <input onChange={(e) => setVendorMar(e.target.value)} value={vendorMar} />
                  </Col>
               </Row>

               <Row>
                  <Col md={6}>
                     <br></br>
                     <label className='mx-auto d-block' style={{ marginLeft: 20, fontWeight: "bold", fontSize: 12 }}>Local Price (Per KM)</label>
                     <input onChange={(e) => setLocalPriceParKm(e.target.value)} value={localPriceParKm} />
                  </Col>

                  <Col md={6}>
                     <br></br>
                     <label className='mx-auto d-block' style={{ marginLeft: 20, fontWeight: "bold", fontSize: 12 }}>Local Booking/Base Price</label>
                     <input onChange={(e) => setLocalBookingPrice(e.target.value)} value={localBookingPrice} />
                  </Col>
               </Row>

               <Row>
                  <Col md={6}>
                     <br></br>
                     <label className='mx-auto d-block' style={{ marginLeft: 20, fontWeight: "bold", fontSize: 12 }}>Out Local Price (Per KM)</label>
                     <input onChange={(e) => setOutLocalPriceParKm(e.target.value)} value={outlocalPriceParKm} />
                  </Col>

                  <Col md={6}>
                     <br></br>
                     <label className='mx-auto d-block' style={{ marginLeft: 20, fontWeight: "bold", fontSize: 12 }}>Out Local Booking/Base Price</label>
                     <input onChange={(e) => setOutLocalBookingPrice(e.target.value)} value={outlocalBookingPrice} />
                  </Col>
               </Row>

               <Row>
                  <Col md={6}>
                     <br></br>
                     <label className='mx-auto d-block' style={{ marginLeft: 20, fontWeight: "bold", fontSize: 12 }}>Local Thresholder KM</label>
                     <input required onChange={(e) => setLocalThresholderPriceParKm(e.target.value)} value={localThresholderPriceParKm} />
                  </Col>

                  <Col md={6}>
                     <br></br>
                     <label className='mx-auto d-block' style={{ marginLeft: 20, fontWeight: "bold", fontSize: 12 }}>AC Price (Per KM)</label>
                     <input required onChange={(e) => setAcPriceParKm(e.target.value)} value={acPriceParKm} />
                  </Col>

                  {/* <Col md={6}>
                     <br></br>
                     <label className='mx-auto d-block' style={{ marginLeft: 20, fontWeight: "bold", fontSize: 12 }}>Night Service Price </label>
                     <input placeholder='Night Service Price' onChange={(e) => setNightServicePrice(e.target.value)} value={nightServicePrice} />
                  </Col> */}
               </Row>
            </div>
            {
               bharpdis === '' ||
                  vendorMar === '' ||
                  localPriceParKm === '' ||
                  localBookingPrice === '' ||
                  outlocalPriceParKm === '' ||
                  outlocalBookingPrice === '' ||
                  localThresholderPriceParKm === '' ||
                  acPriceParKm === '' ?
                  <Button disabled style={{ border: "none" }} onClick={() => handleChange()}>Change Now</Button>
                  :
                  <Button style={{ border: "none" }} onClick={() => handleChange()}>Change Now</Button>
            }
         </Modal>

         <Modal show={show2} onHide={handleClose2}>
            <Container>
               <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row className="mb-3" style={{ marginTop: 20 }}>
                     <Form.Group as={Col} md="12" controlId="validationCustom01">
                        <Form.Label><p style={{ color: '#000', fontWeight: 'bold' }}>Facility Name : <span style={{ color: 'blue' }}>{facilityName}</span></p>  </Form.Label>
                        <Form.Control required type="text" placeholder="Facilitate Price" onChange={(e) => setFacilityPrice(e.target.value)} value={facilityPrice} />
                     </Form.Group>
                  </Row>
                  <Button type="submit" className='mx-auto d-block' style={{ border: "none", marginBottom: 20 }}>Edit</Button>
               </Form>
            </Container>
         </Modal>

         <Modal show={show3} centered onHide={handleClose3}>

            <Carousel style={{ width: '100%', height: '100%' }}>
               {
                  data && data.getAmbulanceById.vehicleImg.map((Image, index) => {
                     return (
                        <Carousel.Item style={{ width: '100%', height: '100%' }}>
                           <img
                              style={{ width: '100%', height: '400px' }}
                              src={`https://bharp-ambulance.s3.ap-south-1.amazonaws.com/${Image}`}
                              alt="Ambulance Pictures"
                              onClick={() => openImageViewer(Image)}
                           />
                        </Carousel.Item>
                     )
                  })
               }
            </Carousel>

         </Modal>
         <Modal show={show4} centered onHide={handleClose4}>
            <Image src={`https://bharp-ambulance.s3.ap-south-1.amazonaws.com/${data && data.getAmbulanceById.driverImg}`} style={{ height: '100%', width: '100%' }}></Image>
         </Modal>
         <Modal show={show5} centered onHide={handleClose5}>
            <Image src={`https://bharp-ambulance.s3.ap-south-1.amazonaws.com/${data && data.getAmbulanceById.licenceImg}`} style={{ height: '100%', width: '100%' }}></Image>
         </Modal>

         <Modal show={showVehicleDetailEdit} onHide={handleVehicleDetailEditClose}>
            <Container style={{ padding: 10 }}>
               <Form noValidate validated={validatedVehicleEdit} onSubmit={handleVehicleEdit}>
                  <h1 style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 15, marginTop: 20 }}>Edit Vehicle Details</h1>
                  <Row className="mb-3">
                     <Form.Group as={Col} md="6" controlId="validationCustom01">
                        <Form.Label style={{ fontWeight: 'bold', fontSize: 12 }}>Vehicle No</Form.Label>
                        <Form.Control required type="text" style={{ fontSize: 12 }} onChange={(e) => setVno(e.target.value)} value={vNo} />
                     </Form.Group>
                     <Form.Group as={Col} md="6" controlId="validationCustom01">
                        <Form.Label style={{ fontWeight: 'bold', fontSize: 12 }}>License No</Form.Label>
                        <Form.Control required type="text" style={{ fontSize: 12 }} onChange={(e) => setLicNo(e.target.value)} value={licNo} />
                     </Form.Group>
                  </Row>
                  <Row className="mb-3">
                     <Form.Group as={Col} md="6" controlId="validationCustom01">
                        <Form.Label style={{ fontWeight: 'bold', fontSize: 12 }}>Vehicle Type</Form.Label>
                        <Form.Control required type="text" style={{ fontSize: 12 }} onChange={(e) => setVehType(e.target.value)} value={vehType} />
                     </Form.Group>
                     <Form.Group as={Col} md="6" controlId="validationCustom01">
                        <Form.Label style={{ fontWeight: 'bold', fontSize: 12 }}>Ownership</Form.Label>
                        <Form.Control required type="text" style={{ fontSize: 12 }} onChange={(e) => setAmbOwner(e.target.value)} value={ambOwner} />
                     </Form.Group>
                  </Row>
                  <Button type="submit" className='mx-auto d-block'>Edit</Button>
               </Form>
            </Container>
         </Modal>

         <Modal show={showDriverDetailEdit} onHide={handleDriverDetailEditClose}>
            <Container style={{ padding: 10 }}>
               <Form noValidate validated={validatedDriverEdit} onSubmit={handleDriverEdit}>
                  <h1 style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 15, marginTop: 20 }}>Edit Driver Details</h1>
                  <Row className="mb-3">
                     <Form.Group as={Col} md="6" controlId="validationCustom01">
                        <Form.Label style={{ fontWeight: 'bold', fontSize: 12 }}>First Name</Form.Label>
                        <Form.Control required type="text" style={{ fontSize: 12 }} onChange={(e) => setDriverFname(e.target.value)} value={driverFname} />
                     </Form.Group>
                     <Form.Group as={Col} md="6" controlId="validationCustom01">
                        <Form.Label style={{ fontWeight: 'bold', fontSize: 12 }}>Last Name</Form.Label>
                        <Form.Control required type="text" style={{ fontSize: 12 }} onChange={(e) => setDriverLname(e.target.value)} value={driverLname} />
                     </Form.Group>
                  </Row>
                  <Row className="mb-3">
                     <Form.Group as={Col} md="6" controlId="validationCustom01">
                        <Form.Label style={{ fontWeight: 'bold', fontSize: 12 }}>Email</Form.Label>
                        <Form.Control type="email" style={{ fontSize: 12 }} onChange={(e) => setDriverEmail(e.target.value)} value={driverEmail} />
                     </Form.Group>
                     <Form.Group as={Col} md="6" controlId="validationCustom01">
                        <Form.Label style={{ fontWeight: 'bold', fontSize: 12 }}>Contact</Form.Label>
                        <Form.Control required type="number" style={{ fontSize: 12 }} onChange={(e) => setDriverContact(e.target.value)} value={driverContact} />
                     </Form.Group>
                  </Row>
                  <Button type="submit" className='mx-auto d-block'>Edit</Button>
               </Form>
            </Container>
         </Modal>

         <Modal show={show6} onHide={handleClose6}>
            <Modal.Body>
               <Container>
                  <h1 style={{ fontSize: 15, textAlign: 'center', fontWeight: 'bold' }}>Add Facility</h1>
                  <hr></hr>
                  <Form noValidate validated={validatedFacility} onSubmit={handleFacilitySubmit}>
                     <Row className="mb-3">
                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                           <Form.Label style={{ fontSize: 15, fontWeight: 'bold' }}>Facility Price</Form.Label>
                           <Form.Select required style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setFacilityNameState(e.target.value)} value={facilityNameState} >
                              <option value="" selected="selected" disabled="disabled">Select Facility</option>
                              <option value="Night Service">Night Service</option>
                              <option value="Oxygen Service">Oxygen Service</option>
                              <option value="Nursing Staff">Nursing Staff</option>
                              <option value="Dead Body Carry">Dead Body Carry</option>
                              <option value="AC Facility">AC Facility</option>
                           </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                           <Form.Label style={{ fontSize: 15, fontWeight: 'bold' }}>Facility Price</Form.Label>
                           <Form.Control style={{ fontSize: 12 }} required type="number" placeholder="Facility Price" onChange={(e) => setFacilityPriceState(e.target.value)} value={facilityPriceState} />
                        </Form.Group>
                     </Row>
                     <Button type="submit" className="mx-auto d-block">Add</Button>
                  </Form>
               </Container>
            </Modal.Body>
         </Modal>

         <Modal show={show7} onHide={handleClose7} centered>
            <Modal.Body>
               <Container>
                  <h1 style={{ fontSize: 15, textAlign: 'center', fontWeight: 'bold' }}>Driver's Image</h1>
                  <hr></hr>
                  <Form noValidate validated={validatedDriverImage} onSubmit={handleDriverImageEditSubmit}>
                     <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                           <Form.Control required type="file" style={{ fontSize: 12 }} onChange={(event) => { setDriverImageSelect(event.target.files[0]) }} />
                        </Form.Group>
                     </Row>
                     <Button type="submit" size="sm" className="mx-auto d-block">Update</Button>
                  </Form>
               </Container>
            </Modal.Body>
         </Modal>

         <Modal show={show8} onHide={handleClose8} centered>
            <Modal.Body>
               <Container>
                  <h1 style={{ fontSize: 15, textAlign: 'center', fontWeight: 'bold' }}>Driver's License Image</h1>
                  <hr></hr>
                  <Form noValidate validated={validatedDriverLicenseImage} onSubmit={handleDriverLicenseImageEditSubmit}>
                     <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                           <Form.Control required type="file" style={{ fontSize: 12 }} onChange={(event) => { setDriverLicenseImageSelect(event.target.files[0]) }} />
                        </Form.Group>
                     </Row>
                     <Button type="submit" size="sm" className="mx-auto d-block">Update</Button>
                  </Form>
               </Container>
            </Modal.Body>
         </Modal>
      </div>
   )
}
