import React, { useState } from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import PatientAdminForm from './PatientAdminForm';

export default function PatientVerify() {

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };

    return (
        <Container style={{ marginTop: 50 }}>
            {/* <PatientAdminForm /> */}
            <Row>
                <Col></Col>
                <Col>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                <Form.Label>Enter OTP</Form.Label>
                                <Form.Control required type="number" placeholder="Enter OTP" />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Button type="submit">Proceed</Button>
                    </Form>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    )
}
