/* eslint-disable jsx-a11y/heading-has-content */
import React, { useState } from 'react';
import { Table, Spinner, Container } from 'react-bootstrap'
import { useQuery } from '@apollo/client';
import "../../../ComponentCss/AllHospitals.css"
import { GET_ALL_HOSPITALS } from '../../../graphql/queries';
import HospitalRow from './HospitalRow';
import { FaEye, FaEyeSlash } from "react-icons/fa";

export default function AllHospital() {

    const { data, loading, error } = useQuery(GET_ALL_HOSPITALS);
    const [showPass, setShowPass] = useState(false)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [deleteshow, setDeleteShow] = useState(false);

    const deletehandleClose = () => setDeleteShow(false);
    const deletehandleShow = () => setDeleteShow(true);
    return (
        <>
            <div style={{ overflow: 'scroll', height: '1000px', marginTop: '20px', width: '100%' }}>
                <Container>
                    <h1 className="pb-3" style={{ fontFamily: 'DM Sans' }}>All Hospitals
                        <h2 style={{ backgroundColor: '#ffb606 ', height: 2, width: '25%', marginTop: 10 }}></h2>
                    </h1>
                    {
                        loading ?
                            <Spinner animation="border" role="status" className="mx-auto d-block">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner> :
                            <Table bordered responsive style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>
                                <thead>
                                    <tr>
                                        {/* <th>S.No.</th> */}
                                        <th>Reg No</th>
                                        <th>Name</th>
                                        <th>Status</th>
                                        <th>Password {showPass ? <FaEye style={{fontSize:"15px"}} onClick={() => setShowPass(s => !s)} /> : <FaEyeSlash style={{fontSize:"15px"}} onClick={() => setShowPass(s => !s)} />}</th>
                                        <th>View</th>
                                        {/* <th>Edit</th> */}
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.getAllHospital.slice().reverse().map((hospital, index) => (
                                        <HospitalRow key={hospital.id} hospital={hospital} index={index} pass={showPass} />
                                    ))}
                                    <tr>
                                    </tr>
                                </tbody>
                            </Table>
                    }
                </Container>
            </div>
        </>
    )
}