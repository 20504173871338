import React,{useState} from 'react'
import { Container,Row,Col,Button,Form } from 'react-bootstrap'
import "../BloodBank/Blood.css"
import Navigation from '../Navigation'

export default function PathologyVerifyOTP() {

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
  
      setValidated(true);
    };

  return (
     <>
    <Navigation/>
    <br></br><br></br><br></br><br></br><br></br>
     <Container>
       <Row>
        <Col md={3}></Col>
        <Col  md={6} className="boxBB">
            <h5 style={{textAlign:"center",marginTop:20,fontFamily:"Dm sans",fontWeight:"bold"}}>User OTP Verification</h5>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="validationCustom01">
        <br></br>
          <Form.Control
            required
            type="number"
            placeholder="Enter OTP"

        
          />
          
        </Form.Group>
       
      </Row>
     
      <Form.Group className="mb-3">
      
      </Form.Group>
      <Button type="submit" style={{marginBottom:20,border:"none",fontFamily:"Dm sans"}} className="mx-auto d-block">Submit</Button>
    </Form>

        </Col>
        <Col  md={3}></Col>
       </Row>
     </Container>
    
    </>
  )
}
