import React, { useState } from 'react'
import { Container, Card, Row, Col, Form, Button, Image, Spinner } from 'react-bootstrap'
import Navigation from '../Navigation'
import bloodBank from "../../Images/pathologyD.png"
import "../BloodBank/Blood.css"
import { useMutation } from '@apollo/client'
import swal from 'sweetalert';
import { MUTATION_PATHOLOGY_LOGIN } from '../../graphql/mutations'
import { useNavigate } from 'react-router-dom'

export default function PathologyLogin() {
  const [validated, setValidated] = useState(false);
  const [registration, setRegistration] = useState()
  const [password, setPassword] = useState()

  const navigate = useNavigate();

  const [pathologylogin, { data, loading }] = useMutation(MUTATION_PATHOLOGY_LOGIN)

  console.log(data)

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      pathologylogin({
        variables: {
          "registrationNo": `${registration}`,
          "password": `${password}`

        }


      })

    }

    setValidated(true);
  };
  if (data) {

    localStorage.setItem("PathologyToken", data && data.pathologylogin.pathologyToken)
    localStorage.setItem("PathologyId", data && data.pathologylogin.pathologyId)
    // swal({ title: 'Successfull!!!', text: 'Login Successfully', icon: 'success' });
    navigate("/PathologyDashboard")

  }




  return (
    <>
      <Navigation />
      <br></br>  <br></br>  <br></br><br></br><br></br>
      <Container style={{ borderRadius: "20px" }}>
        <Card style={{ borderRadius: "20px" }}>
          <Row >
            <Col className="boxBB" md={6} style={{ borderRadius: "20px", overflow: "hidden" }}>
              <Image src={bloodBank} style={{ width: 350, marginTop: 50 }} className="mx-auto d-block img-fluid" alt="Responsive image" />
              <h6 style={{ fontFamily: "Dm sans", textAlign: "center", fontSize: 20, fontWeight: "bold", }}>Welcome Back</h6>
            </Col>
            <Col md={6} >
              <h3 style={{ fontFamily: "Dm sans", textAlign: "center", marginTop: 70, fontWeight: "bold" }}>Pathology Login</h3>
              <Form noValidate validated={validated} onSubmit={handleSubmit} style={{ marginTop: 50 }} >
                <Row className="mb-3">
                  <Form.Group as={Col} md="9" controlId="validationCustom01" className="mx-auto d-block">
                    <Form.Control required type="text" placeholder="Enter Registration Number" style={{ border: "2px solid #48dbfb", fontFamily: "Dm sans" }} onChange={(e) => setRegistration(e.target.value)} />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="9" controlId="validationCustom01" className="mx-auto d-block">
                    <Form.Control required type="password" placeholder="Enter Password" style={{ border: "2px solid #48dbfb", fontFamily: "Dm sans" }} onChange={(e) => setPassword(e.target.value)} />
                  </Form.Group>
                </Row>
                {
                  loading ?
                    <div className='mx-auto d-block'>
                      <Spinner color='#000' />
                    </div>
                    :
                    <Button type="submit" className="mx-auto d-block boxBB " style={{ border: "none", fontFamily: "Dm sans", marginBottom: 100 }} >Login Now</Button>
                }


              </Form>
            </Col>
          </Row>


        </Card>

      </Container>




    </>
  )
}
