/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Image, Table, Modal } from 'react-bootstrap'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelopeOpen } from 'react-icons/fa'
import { FiGlobe } from "react-icons/fi";
import { GET_HOSPITAL_BY_ID, GET_AGENT_BY_ID } from '../../../graphql/queries'
import { useJsApiLoader, GoogleMap, MarkerF } from '@react-google-maps/api'
import "./HospitalDetail.css"
import Carousel from 'react-bootstrap/Carousel';
import Navigation from "../../Navigation"
import Moment from 'react-moment';
// const google = window.google;

export default function HospitalDetail() {


  let count = 1;
  const { id } = useParams()

  const [index, setIndex] = useState(0);
  const [image, setImage] = useState()

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [center, setCenter] = useState({ lat: 23.8388, lng: 78.7378 })



  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const { data } = useQuery(GET_HOSPITAL_BY_ID, {
    variables: {
      "hospitalId": `${id}`
    }
  })

  useEffect(() => {
    if (data) {
      setCenter({ lat: parseFloat(data.getHospitalById.hospitalLatitude), lng: parseFloat(data.getHospitalById.hospitalLongitude) })
    }
  }, [data]);

  const { data: AgentData } = useQuery(GET_AGENT_BY_ID, {
    variables: {
      "agentId": `${data && data.getHospitalById.agentId}`
    },
    pollInterval: 300
  })

  function handleOne(Img) {
    setImage(Img)
    handleShow()
  }

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyB2eRRn8bW1VVh4wj4AEaYd3F_RgEP2mE4'
  })

  if (!isLoaded) {
    return <h1>Loading</h1>
  }

  console.log(data)
  return (
    <div style={{ background: "#00a8ff" }}>
      <Navigation />
      <br></br> <br></br><br></br> <br></br>
      <Container style={{ background: "#00a8ff", marginTop: 20, position: "relative" }}>
        <Row style={{ background: "#fff", padding: 10, borderRadius: 20 }}>
          <Col md={6} className="mx-auto d-block">
            {
              data && data.getHospitalById.hospitalImages[0] === "" ?
                <h1 style={{ fontFamily: 'Dm Sans', fontSize: '12px', fontWeight: 'bold', color: '#e74c3c', textAlign: 'center' }}>No Hospital Certificate Image Available</h1> :
                <Carousel>
                  {
                    data && data.getHospitalById.hospitalImages.map(Image => {
                      return (
                        <Carousel.Item style={{ width: '200px', height: '300px' }}>
                          <img
                            style={{ borderRadius: 20, width: '200px', height: '300px' }}
                            className="d-block w-100"
                            src={`https://bharp-hospital.s3.ap-south-1.amazonaws.com/${Image}`}
                            alt="Hospital Pictures"
                          />
                        </Carousel.Item>
                      )
                    })
                  }
                </Carousel>
            }
            <br></br>
            <Row>
              <Col md={3}>
                {
                  data && data.getHospitalById.hospitalCertificateImg === "" ?
                    <h1 style={{ fontFamily: 'Dm Sans', fontSize: '12px', fontWeight: 'bold', color: '#e74c3c', textAlign: 'center' }}>No Hospital Certificate Image Available</h1> :
                    <Image src={`https://bharp-hospital.s3.ap-south-1.amazonaws.com/${data && data.getHospitalById.hospitalCertificateImg}`} style={{ border: "2px solid #00a8ff", borderRadius: 20, width: '100%', height: '200px' }} className="img-fluid" alt="Responsive image" onClick={() => handleOne(data && data.getHospitalById.hospitalCertificateImg)} />
                }
                <p style={{ textAlign: "center", fontFamily: "Dm sans" }}>Certificate</p>
              </Col>
              <Col md={3}>
                {
                  data && data.getHospitalById.hospitalGstCertificateImg === "" ?
                    <h1 style={{ fontFamily: 'Dm Sans', fontSize: '12px', fontWeight: 'bold', color: '#e74c3c', textAlign: 'center' }}>No Hospital GST Certificate Image Available</h1> :
                    <Image src={`https://bharp-hospital.s3.ap-south-1.amazonaws.com/${data && data.getHospitalById.hospitalGstCertificateImg}`} style={{ border: "2px solid #00a8ff", borderRadius: 20, width: '100%', height: '200px' }} className="img-fluid" alt="Responsive image" onClick={() => handleOne(data && data.getHospitalById.hospitalGstCertificateImg)} />
                }
                <p style={{ textAlign: "center", fontFamily: "Dm sans" }}>Gst Certificate</p>
              </Col>
              <Col md={3}>
                {
                  data && data.getHospitalById.hospitalLicenseImg === "" ?
                    <h1 style={{ fontFamily: 'Dm Sans', fontSize: '12px', fontWeight: 'bold', color: '#e74c3c', textAlign: 'center', marginBottom: '22px' }}>No Hospital License Image Available</h1> :
                    <Image src={`https://bharp-hospital.s3.ap-south-1.amazonaws.com/${data && data.getHospitalById.hospitalLicenseImg}`} style={{ border: "2px solid #00a8ff", borderRadius: 20, width: '100%', height: '200px' }} className="img-fluid" alt="Responsive image" onClick={() => handleOne(data && data.getHospitalById.hospitalLicenseImg)} />
                }
                <p style={{ textAlign: "center", fontFamily: "Dm sans" }}>License</p>
              </Col>
            </Row>
            {
              data && data.getHospitalById.agentId === null ?
                <h1 style={{ fontFamily: 'Dm Sans', fontSize: '15px', marginTop: '30px', fontWeight: 'bold', color: '#f39c12', textAlign: 'center' }}>Hospital Uploaded By Admin</h1> :
                AgentData && AgentData.getAgentById === null ? <h1 style={{ marginTop: 20, fontFamily: "Dm sans", color: "#000", fontSize: '15px', fontWeight: 'bold' }}>No Agent Found</h1> :
                  <Row style={{ border: "2px solid #00a8ff", marginBottom: 20 }}>
                    <h6 style={{ textAlign: "center", marginTop: 20, fontFamily: "Dm sans", color: "#00a8ff" }}>Registered By Agent Information</h6>
                    <Col>
                      <p style={{ fontFamily: "Dm sans", fontSize: '12px' }}><strong>Username :</strong> {AgentData && AgentData.getAgentById.uniqueId}</p>
                      <p style={{ fontFamily: "Dm sans", fontSize: '12px' }}><strong>Fullname :</strong> {AgentData && AgentData.getAgentById.firstName} {AgentData && AgentData.getAgentById.lastName}</p>
                      <p style={{ fontFamily: "Dm sans", fontSize: '12px' }}><strong>Email Id :</strong> {AgentData && AgentData.getAgentById.email}</p>
                      <p style={{ fontFamily: "Dm sans", fontSize: '12px' }}><strong>Contact :</strong>  {AgentData && AgentData.getAgentById.contact}</p>
                    </Col>
                  </Row>
            }



          </Col>
          <Col md={6}>

            <h4 style={{ textAlign: "center", fontFamily: "Dm sans", marginTop: 20, color: "#00a8ff" }}>{data && data.getHospitalById.hospitalName}</h4>
            <hr></hr>
            <p style={{ fontFamily: "Dm sans", fontSize: '12px' }}><strong>Registration No :</strong> {data && data.getHospitalById.hospitalRegistrationNumber} </p>
            <p style={{ fontFamily: "Dm sans", fontSize: '12px' }}><strong>Date Of Registration :</strong> <Moment format='DD-MM-YYYY'>{data && data.getHospitalById.createdDateTime}</Moment></p>
            <Row style={{ marginBottom: "20px" }}>

              <Col>
                {
                  data && data.getHospitalById.otherHospitalType === "" ?
                    <h6 style={{ padding: 5, fontFamily: "Dm sans", border: "2px solid #00a8ff ", fontSize: '12px' }}><strong>Hospital Type :</strong> {data && data.getHospitalById.hospitalType}</h6>
                    :
                    <h6 style={{ padding: 5, fontFamily: "Dm sans", border: "2px solid #00a8ff ", fontSize: '12px' }}><strong>Hospital Type :</strong> {data && data.getHospitalById.otherHospitalType}</h6>
                }
              </Col>
              <Col>

                {
                  data && data.getHospitalById.otherHealthCareProvider === "" ?
                    <h6 style={{ padding: 5, fontFamily: "Dm sans", border: "2px solid #00a8ff ", fontSize: 12 }}><strong>Health Care Provider :</strong> {data && data.getHospitalById.hospitalHealthCareProviderType}</h6>
                    :
                    <h6 style={{ padding: 5, fontFamily: "Dm sans", border: "2px solid #00a8ff ", fontSize: 12 }}><strong>Health Care Provider :</strong> {data && data.getHospitalById.otherHealthCareProvider}</h6>
                }
              </Col>
            </Row>
            <p style={{ fontFamily: "Dm sans", fontSize: '12px' }}><FaMapMarkerAlt style={{ marginRight: 10, color: "#00a8ff", fontSize: '12px' }} />{data && data.getHospitalById.hospitalAddress} {data && data.getHospitalById.hospitalCity} {data && data.getHospitalById.hospitalDistrict}  {data && data.getHospitalById.hospitalState}</p>
            <p style={{ fontFamily: "Dm sans", fontSize: '12px' }}><FaPhoneAlt style={{ marginRight: 10, color: "#00a8ff", fontSize: '12px' }} />{data && data.getHospitalById.hospitalContact}</p>
            <p style={{ fontFamily: "Dm sans", fontSize: '12px' }}><FaEnvelopeOpen style={{ marginRight: 10, color: "#00a8ff", fontSize: '12px' }} />{data && data.getHospitalById.hospitalEmail}</p>
            <a href={data && data.getHospitalById.hospitalWebsiteLink} style={{ textDecoration: "none", fontFamily: "Dm sans", fontSize: '12px' }} target='_blank'><FiGlobe style={{ marginRight: 10, fontSize: '12px' }} />Go Website Link</a>
            <br></br>
            <br></br>
            <Row>
              <Col>
                <h6>Contact Personal </h6>
                <hr></hr>
                <p style={{ fontFamily: "Dm sans", fontSize: 12 }}><strong>Name :</strong> {data && data.getHospitalById.cPName}</p>
                <p style={{ fontFamily: "Dm sans", fontSize: 12 }}><strong>Contact :</strong> {data && data.getHospitalById.cPContact}</p>
                <p style={{ fontFamily: "Dm sans", fontSize: 12 }}><strong>Email :</strong> {data && data.getHospitalById.cPEmail}</p>
                <p style={{ fontFamily: "Dm sans", fontSize: 12 }}><strong>Designation :</strong> {data && data.getHospitalById.cPDesignation}</p>
              </Col>
              <Col>
                <h6>Hospital Information</h6>
                <hr></hr>
                <p style={{ fontFamily: "Dm sans", fontSize: 12 }}><strong>Block :</strong> {data && data.getHospitalById.hospitalBlock}</p>
                <p style={{ fontFamily: "Dm sans", fontSize: 12 }}><strong>Establishment Year :</strong> {data && data.getHospitalById.hospitalEstablishmentYear}</p>
                <p style={{ fontFamily: "Dm sans", fontSize: 12 }}><strong>Total Doctor :</strong> {data && data.getHospitalById.totalDcoctor}</p>
                {
                  data && data.getHospitalById.status === "reject" ?
                    <p style={{ fontFamily: "Dm sans", fontSize: 12, color: "red", fontWeight: "bold" }}>Status : {data && data.getHospitalById.status}</p>
                    :
                    <p style={{ fontFamily: "Dm sans", fontSize: 12, color: "#2ed573", fontWeight: "bold" }}>Status : {data && data.getHospitalById.status}</p>
                }
              </Col>
            </Row>
            <h1 style={{ fontSize: '30px', textAlign: 'center' }}>Hospital Location</h1>
            <hr />
            <GoogleMap center={center} zoom={15} mapContainerStyle={{ width: '100%', height: '300px' }}>
              <MarkerF position={center} />
            </GoogleMap>
          </Col>
          <Row style={{ marginTop: '20px' }}>
            <hr />
            <Col>
              <h1 style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '17px' }}>Benificial Services</h1>
              {
                data && data.getHospitalById.beneficialServices.map(bData =>
                  <ul>
                    <li style={{ fontFamily: 'Dm Sans', fontSize: '12px' }}>{bData}</li>
                  </ul>
                )
              }
            </Col>
            <Col>
              <h1 style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '17px' }}>Facilities</h1>
              {
                data && data.getHospitalById.addService.map(sData =>
                  <ul>
                    <li style={{ fontFamily: 'Dm Sans', fontSize: '12px' }}>{sData.serviceName} ({sData.servicePrice}/-)</li>
                  </ul>
                )
              }
            </Col>
          </Row>
        </Row>
        <Container style={{ background: "#fff", marginTop: 20, borderRadius: 20, marginBottom: 20 }}>
          <h1 style={{ textAlign: 'center', fontFamily: 'DM Sans', fontSize: '20px', fontWeight: 'bold', paddingTop: '10px' }}>Doctors Details</h1>
          <Table bordered responsive style={{ marginTop: 10, fontSize: '12px', fontFamily: 'DM Sans' }}>
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Doctor Name</th>
                <th>Experience</th>
                <th>Licence No</th>
                <th>Qualification</th>
                <th>Speciality</th>
                <th>Ward</th>
              </tr>
            </thead>
            <tbody>
              {
                data && data.getHospitalById.doctorDetail.map(doctor => {
                  return (
                    <tr>
                      <td>{count++}</td>
                      <td>{doctor.doctorName}</td>
                      <td>{doctor.experience}</td>
                      <td>{doctor.licenceNo}</td>
                      <td>{doctor.qualification}</td>
                      <td>{doctor.speciality}</td>
                      <td>{doctor.ward}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </Container>
      </Container>

      <Modal show={show} onHide={handleClose} size="lg">
        <Image src={`https://bharp-hospital.s3.ap-south-1.amazonaws.com/${image}`} className="img-fluid" alt="Responsive image" />
      </Modal>
    </div>
  )
}
