import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react'
import swal from 'sweetalert';
import { FaBan, FaCheck ,FaEye} from "react-icons/fa";
import { Container, Row, Col, Form, Button, Card, Spinner, Table } from 'react-bootstrap'
import { MUTATION_ACTIVE_PATHOLOGY_SLOT, MUTATION_ACTIVE_RADIOLOGY_SLOT, MUTATION_ACTIVE_RADIOLOGY_SLOT_TIME, MUTATION_ADD_PATHOLOGY_SLOT, MUTATION_ADD_RADIOLOGY_SLOT, MUTATION_BLOCK_PATHOLOGY_SLOT, MUTATION_BLOCK_RADIOLOGY_SLOT, MUTATION_BLOCK_RADIOLOGY_SLOT_TIME, MUTATION_DCREASE_SLOT_RADILOGY, MUTATION_INCREASE_SLOT_PATHOLOGY, MUTATION_INCREASE_SLOT_RADIOLOGY } from '../../graphql/mutations';
import { QUERY_PATHOLOGY_SLOT_BY_PATHOLOGY_ID, QUERY_RADIOLOGY_SLOT_BY_RADIOLOGY_ID, QUERY_GET_RADIOLOGY_BY_ID } from '../../graphql/queries';
import { LakeFormation } from 'aws-sdk';
import { useNavigate } from 'react-router-dom';
import "../../ComponentCss/NewDashboard.css"

import { FaAlignJustify, FaHome, FaUsers, FaUsersCog, FaHeadset, FaKey, FaSignOutAlt,FaClock,FaInfoCircle } from "react-icons/fa";

import { LinkContainer } from 'react-router-bootstrap'


export default function AddRadiologySlot() {
  const [validated, setValidated] = useState(false);
  const [slot, setSlot] = useState("")
  const [time, setTime] = useState("")

  const RadiologyId = localStorage.getItem("RadiologyId")

  const { data: radiologyData, loading: radiologyLoading } = useQuery(QUERY_GET_RADIOLOGY_BY_ID, {
    variables: {
      "radiologyId": `${RadiologyId}`
    },
    pollInterval:1000
    
  })

  console.log("radiologyData", radiologyData)

  const [createRadiologySlot] = useMutation(MUTATION_ADD_RADIOLOGY_SLOT, {
    refetchQueries: [
      QUERY_RADIOLOGY_SLOT_BY_RADIOLOGY_ID,
      "getRadiologySlotByRadiologyId"
    ]
  })

  const { data, loading } = useQuery(QUERY_RADIOLOGY_SLOT_BY_RADIOLOGY_ID, {
    variables: {
      "radiologyId": `${RadiologyId}`
    }
  })

  console.log(data)

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      createRadiologySlot({
        variables: {
          "radiologySlotInput": {
            "radiologyId": `${RadiologyId}`,
            "slot": `${slot}`,
            "time": `${time}`
          }
        }
      })
      setSlot("")
      setTime("")

      swal({ title: 'Successfull!!!', text: 'Create Successfully', icon: 'success' });
    }
    setValidated(true);
  };







   const[increaseRadiologySlotCapacity] =useMutation(MUTATION_INCREASE_SLOT_RADIOLOGY,{
    refetchQueries:[
      QUERY_GET_RADIOLOGY_BY_ID,
      "getRadiologyById"
    ]
   })

  const[decreaseRadiologySlotCapacity] = useMutation(MUTATION_DCREASE_SLOT_RADILOGY,{
    refetchQueries:[
      QUERY_GET_RADIOLOGY_BY_ID,
      "getRadiologyById"
    ]
  })

  function handleIncre(id,capacity){
    increaseRadiologySlotCapacity({
      variables:{
        "radiologyId": `${radiologyData && radiologyData.getRadiologyById.id}`,
        "slotId":`${id}`,
        "capacity":parseInt(capacity)
      }
    })
   
  }

  function handleDcre(id,capacity){
    decreaseRadiologySlotCapacity({
       variables:{
        "radiologyId": `${radiologyData && radiologyData.getRadiologyById.id}`,
        "slotId": `${id}`,
        "capacity":parseInt(capacity)
       }
    })
  }



  const navigate = useNavigate()

  function handleSlot(id){
   navigate("/ViewRadiologySlotBooking/"+id)
  }
 
 

  function handleMenu() {

    let toggle = document.querySelector(".toggle");
let navigation = document.querySelector(".navigation");
let main = document.querySelector(".main");
    navigation.classList.toggle("active");
    main.classList.toggle("active");
};

const[activeRadiologySlotTime] = useMutation(MUTATION_ACTIVE_RADIOLOGY_SLOT_TIME,{
  refetchQueries:[
    QUERY_GET_RADIOLOGY_BY_ID,
    "getRadiologyById"
  ]
})

 const[blockRadiologySlotTime] = useMutation(MUTATION_BLOCK_RADIOLOGY_SLOT_TIME,{
  refetchQueries:[
    QUERY_GET_RADIOLOGY_BY_ID,
    "getRadiologyById"
  ]
 })


function handleActive(id){
  activeRadiologySlotTime({
variables:{
  "slotId": `${id}`,
  "radiologyId": `${radiologyData && radiologyData.getRadiologyById.id}`,

}
})
}

function handleBlock(id){
  blockRadiologySlotTime({
variables:{
  "slotId": `${id}`,
  "radiologyId": `${radiologyData && radiologyData.getRadiologyById.id}`,

}
})
}


  return (
    <div>
      
      <div >
                <div className="navigation">
                    <br></br> 
                  
                <span className="title" style={{ fontSize: 20, fontFamily: 'DM Sans', fontWeight: 'bold',color:"#fff",textAlign:"center",marginLeft:20,marginTop:20 }}>Dashboard</span>
                    <ul>
                        <br></br><br></br><br></br>
                       
                        <li>
                        <LinkContainer to="/">
                            <a href="#">
                                <span className="icon">

                                </span>
                                <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }} eventKey="first"><FaHome /> Home</span>
                              
                            </a>
                            </LinkContainer>
                        </li>
                        <li>
                        <LinkContainer to="/RadiologyProfile">
                            <a href="#">
                                <span className="icon">
                                </span>
                                <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }}><FaUsers /> Profile</span>
                            </a>
                            </LinkContainer>
                        </li>
                        <li>
                        <LinkContainer to="/AddRadiologySlot">
                            <a href="#">
                                <span className="icon"> </span>
                                <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }}><FaClock /> All Slot</span>
                            </a>
                            </LinkContainer>
                        </li>
                        <li>
                        <LinkContainer to="/Termcondition">
                            <a href="#">
                                <span className="icon"> </span>
                                <span className="title" style={{ fontSize: 12, fontFamily: 'DM Sans' }}><FaInfoCircle /> Terms & Condition</span>
                            </a>
                            </LinkContainer>
                        </li>
                      
                       
                    </ul>
                    
                </div>
                

                <div className="main">
                    <div className="topbar">
                        <div>
                            <FaAlignJustify onClick={() => handleMenu()}  className="toggle"/>
                        </div>
                        <div className="search">
                            <label>
                                <input type="text" placeholder="Search here" />
                            </label>
                        </div>
                        <div className="user">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/2048px-Circle-icons-profile.svg.png" alt="" />
                        </div>
                    </div>
                   
                   <Container>
        <Row>
          <Col>
            <h1 style={{ fontFamily: 'DM Sans', textAlign: 'center', fontSize: 20, fontWeight: 'bold', marginTop: 20, marginBottom: 20 }}>Radiology Slot</h1>
            <Table responsive style={{ fontFamily: 'DM Sans', fontSize: 12 }}>
              <tbody>
                {
                  radiologyData && radiologyData.getRadiologyById.slotDetails.map(data =>
                    {

                      return(
                        <tr>
                        <td>{data.slotTimeInterval}</td>
                        <td>{data.status}</td>
                        {
                            data.status ==="active" ?
                            <td><Button size="sm" variant="danger"onClick={()=>handleBlock(data.id)}>Block</Button></td>
                            :
                           <td><Button size="sm" variant="success"onClick={()=>handleActive(data.id)}>Active</Button></td>

                        }
                        <td><Button size="sm" variant="info" onClick={()=>handleSlot(data.id)} ><FaEye color="#fff"/></Button></td>
                      </tr>
                      )



                    }
                 
                  )
                }
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
                </div>
              
            </div>

      
    </div>
  )
}
