import React from 'react'
import Navigation from './Navigation';
import About from './About';
import OurServices from './OurServices';
import ContactUs from './ContactUs';
import Footer from './Footer';
import HeroSection from './HeroSection';

export default function Home() {
    return (
        <>
            <Navigation />
            <HeroSection />
            <About />
            <OurServices />
            {/* <ContactUs /> */}
            <Footer />
        </>
    )
}

