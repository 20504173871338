import { Form, Row, Col, Button, Container, Modal } from 'react-bootstrap';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { ADD_HOSPITAL } from '../../../graphql/mutations';
import { QUERY_PENDING_HOSPITAL } from '../../../graphql/queries';
import Spinner from '../../Spinner';
import { AiFillPlusCircle } from 'react-icons/ai';
import swal from 'sweetalert';
import AWS from 'aws-sdk';
import { v4 } from 'uuid';
import axios from 'axios';
import uniqid from 'uniqid';
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { FiPlus,FiMinus } from "react-icons/fi";

const ID = process.env.REACT_APP_AWS_ACCESSS_ID;
const SECRET = process.env.REACT_APP_AWS_ACCESSS_KEY;
const BUCKET_NAME = 'bharp-hospital';

const s3 = new AWS.S3({
  accessKeyId: ID,
  secretAccessKey: SECRET,
});

let stateArray = ['Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jammu and Kashmir', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttarakhand', 'Uttar Pradesh', 'West Bengal', 'Andaman and Nicobar Islands', 'Chandigarh', 'Dadra and Nagar Haveli', 'Daman and Diu', 'Delhi', 'Lakshadweep', 'Puducherry'];

const andhraPradeshDistrictArray = ["Ananthapur", "Chittoor", "Cuddapah", "East Godavari", "Guntur", "Krishna", "Kurnool", "Nellore", "Prakasam", "Srikakulam", "Visakhapatnam", "Vizianagaram", "West Godavari"];
const andamanAndNicobarIslandsDistrictArray = ["Nicobar", "North And Middle Andaman", "South Andaman"];
const arunachalPradeshDistrictArray = ["Changlang", "Dibang Valley", "East Kameng", "East Siang", "Kurung Kumey", "Lohit", "Lower Dibang Valley", "Lower Subansiri", "Papum Pare", "Tawang", "Tirap", "Upper Siang", "Upper Subansiri", "West Kameng", "West Siang"];
const assamDistrictArray = ["Barpeta", "Bongaigaon", "Cachar", "Darrang", "Dhemaji", "Dhubri", "Dibrugarh", "Goalpara", "Golaghat", "Hailakandi", "Jorhat", "Kamrup", "Karbi Anglong", "Karimganj", "Kokrajhar", "Lakhimpur", "Marigaon", "Nagaon", "Nalbari", "North Cachar Hills", "Sibsagar", "Sonitpur", "Tinsukia"];
const biharDistrictArray = ["Araria", "Arwal", "Aurangabad(BH)", "Banka", "Begusarai", "Bhagalpur", "Bhojpur", "Buxar", "Darbhanga", "East Champaran", "Gaya", "Gopalganj", "Jamui", "Jehanabad", "Kaimur (Bhabua)", "Katihar", "Khagaria", "Kishanganj", "Lakhisarai", "Madhepura", "Madhubani", "Munger", "Muzaffarpur", "Nalanda", "Nawada", "Patna", "Purnia", "Rohtas", "Saharsa", "Samastipur", "Saran", "Sheikhpura", "Sheohar", "Sitamarhi", "Siwan", "Supaul", "Vaishali", "West Champaran"];
const chandigarhDistrictArray = ["Chandigarh"];
const chhattisgarhDistrictArray = ["Bastar", "Bijapur(CGH)", " Bilaspur(CGH)", "Dantewada", "Dhamtari", "Durg", "Gariaband", "Janjgir-champa", "Jashpur", "Kanker", "Kawardha", "Korba", "Koriya", "Mahasamund", "Narayanpur", "Raigarh", "Raipur", "Rajnandgaon", "Surguja"];
const dadarAndNagarHaveliDistrictArray = ["Dadra & Nagar Haveli"];
const damanAndDiuDistrictArray = ["Daman", "Diu"];
const delhiDistrictArray = ["Central Delhi", "East Delhi", "New Delhi", "North Delhi", "North East Delhi", "North West Delhi", " South Delhi", "South West Delhi", "West Delhi"];
const LakshadweepDistrictArray = ["North Goa", "South Goa"];
const puducherryDistrictArray = ["Karaikal", "Mahe", "Pondicherry"];
const goaDistrictArray = ["North Goa", "South Goa"];
const gujaratDistrictArray = ["Ahmedabad", "Amreli", "Anand", "Banaskantha", "Bharuch", "Bhavnagar", "Dahod", "Gandhi Nagar", "Jamnagar", "Junagadh", "Kachchh", "Kheda", "Mahesana", "Narmada", "Navsari", "Panch Mahals", "Patan", "Porbandar", "Rajkot", "Sabarkantha", "Surat", "Surendra Nagar", "Tapi", "The Dangs", "Vadodara", "Valsad"];
const jammuAndKashmirDistrictArray = ["Ananthnag", "Bandipur", "Baramulla", "Budgam", "Doda", "Jammu", "Kargil", "Kathua", "Kulgam", "Kupwara", "Leh", "Poonch", "Pulwama", "Rajauri", "Reasi", "Shopian", "Srinagar", "Udhampur"];
const himachalPradeshDistrictArray = ["Bilaspur(HP)", "Chamba", "Hamirpur(HP)", "Kangra", "Kinnaur", "Kullu", "Lahul & Spiti", "Mandi", "Shimla", "Sirmaur", "Solan", "Una"];
const jharkhandDistrictArray = ["Bokaro", "Chatra", "Deoghar", "Dhanbad", "Dumka", "East Singhbhum", "Garhwa", "Giridh", "Godda", "Gumla", "Hazaribag", "Jamtara", "Khunti", "Koderma", "Latehar", "Lohardaga", "Pakur", "Palamau", "Ramgarh", "Ranchi", "Sahibganj", "Seraikela - kharsawan", "Simdega", "West Singhbhum"];
const karnatakaDistrictArray = ["Bagalkot", "Bangalore", "Rural", "Belgaum", "Bellary", "Bidar", "Bijapur(KAR)", "Chamrajnagar", "Chickmagalur", "Chikkaballapur", "Chitradurga", "Dakshina Kannada", "Davangere", "Dharwad", "Gadag", "Gulbarga", "Hassan", "Haveri", "Kodagu", "Kolar", "Koppal", "Mandya", "Mysore", "Raichur", "Ramanagar", "Shimoga", "Tumkur", "Udupi", "Uttara Kannada", "Yadgir"];
const keralaDistrictArray = ["Alappuzha", "Ernakulam", "Idukki", "Kannur", "Kasargod", "Kollam", "Kottayam", "Kozhikode", "Malappuram", "Palakkad", "Pathanamthitta", "Thiruvananthapuram", "Thrissur", "Wayanad"];
const madhyaPradeshDistrictArray = ["Alirajpur", "Anuppur", "Ashok Nagar", "Balaghat", "Barwani", "Betul", "Bhind", "Bhopal", "Burhanpur", "Chhatarpur", "Damoh", "Datia", "Dewas", "Dhar", "Dindori", "East Nimar", "Guna", "Gwalior", "Harda", "Hoshangabad", "Indore", "Jabalpur", "Jhabua", "Katni", "Khandwa", "Khargone", "Mandla", "Mandsaur", "Morena", "Narsinghpur", "Neemuch", "Panna", "Raisen", "Rajgarh", "Ratlam", "Rewa", "Sagar", "Satna", "Sehore", "Seoni", "Shahdol", "Shajapur", "Sheopur", "Shivpuri", "Sidhi", "Singrauli", "Tikamgarh", "Ujjain", "Umaria", "Vidisha", "West Nimar"];
const maharashtraDistrictArray = ["Ahmed Nagar", "Akola", "Amravati", "Aurangabad", "Beed", "Bhandara", "Buldhana", "Chandrapur", "Dhule", "Gadchiroli", "Gondia", "Hingoli", "Jalgaon", "Jalna", "Kolhapur", "Latur", "Mumbai", "Nagpur", "Nanded", "Nandurbar", "Nashik", "Osmanabad", "Parbhani", "Pune", "Raigarh(MH)", "Ratnagiri", "Sangli", "Satara", "Sindhudurg", "Solapur", "Thane", "Wardha", "Washim", "Yavatmal"];
const manipurDistrictArray = ["Bishnupur", "Chandel", "Churachandpur", " Imphal East", "Imphal West", "Senapati", "Tamenglong", "Thoubal", "Ukhrul"];
const meghalayaDistrictArray = ["East Garo Hills", "East Khasi Hills", "Jaintia Hills", "Ri Bhoi", "South Garo Hills", "West Garo Hills", "West Khasi Hills"];
const mizoramDistrictArray = ["Aizawl", "Champhai", "Kolasib", "Lawngtlai", "Lunglei", "Mammit", "Saiha", "Serchhip"];
const nagalandDistrictArray = ["Dimapur", "Kiphire", "Kohima", "Longleng", "Mokokchung", "Mon", "Peren", "Phek", "Tuensang", "Wokha", "Zunhebotto"];
const odishaDistrictArray = ["Angul", "Balangir", "Baleswar", "Bargarh", "Bhadrak", "Boudh", "Cuttack", "Debagarh", "Dhenkanal", "Gajapati", "Ganjam", "Jagatsinghapur", "Jajapur", "Jharsuguda", "Kalahandi", "Kandhamal", "Kendrapara", "Kendujhar", "Khorda", "Koraput", "Malkangiri", "Mayurbhanj", "Nabarangapur", "Nayagarh", "Nuapada", "Puri", "Rayagada", "Sambalpur", "Sonapur", "Sundergarh"];
const punjabDistrictArray = ["Amritsar", "Barnala", "Bathinda", "Faridkot", "Fatehgarh Sahib", "Fazilka", "Firozpur", "Gurdaspur", "Hoshiarpur", "Jalandhar", "Kapurthala", "Ludhiana", "Mansa", "Moga", "Mohali", "Muktsar", "Nawanshahr", "Pathankot", "Patiala", "Ropar", "Rupnagar", "Sangrur", "Tarn Taran"];
const rajasthanDistrictArray = ["Ajmer", "Alwar", "Banswara", "Baran", "Barmer", "Bharatpur", "Bhilwara", "Bikaner", "Bundi", "Chittorgarh", "Churu", "Dausa", "Dholpur", "Dungarpur", "Ganganagar", "Hanumangarh", "Jaipur", "Jaisalmer", "Jalor", "Jhalawar", "Jhujhunu", "Jodhpur", "Karauli", "Kota", "Nagaur", "Pali", "Rajsamand", "Sikar", "Sawai Madhopur", "Sirohi", "Tonk", "Udaipur"];
const sikkimDistrictArray = ["East Sikkim", "North Sikkim", "South Sikkim", "West Sikkim"];
const tamilNaduDistrictArray = ["Ariyalur", "Chennai", "Coimbatore", "Cuddalore", "Dharmapuri", "Dindigul", "Erode", "Kanchipuram", "Kanyakumari", "Karur", "Krishnagiri", "Madurai", "Nagapattinam", "Namakkal", "Nilgiris", "Perambalur", "Pudukkottai", "Ramanathapuram", "Salem", "Sivaganga", "Thanjavur", "Theni", "Tiruchirappalli", "Tirunelveli", "Tiruvallur", "Tiruvannamalai", "Tiruvarur", "Tuticorin", "Vellore", "Villupuram", "Virudhunagar"];
const telanganaDistrictArray = ["Adilabad", "Hyderabad", "K.V.Rangareddy", "Karim Nagar", "Khammam", "Mahabub Nagar", "Medak", "Nalgonda", "Nizamabad", "Warangal"];
const tripuraDistrictArray = ["Dhalai", "North Tripura", "South Tripura", "West Tripura"];
const uttarPradeshDistrictArray = ["Agra", "Aligarh", "Allahabad", "Ambedkar Nagar", "Auraiya", "Azamgarh", "Bagpat", "Bahraich", "Ballia", "Balrampur", "Banda", "Barabanki", "Bareilly", "Basti", "Bijnor", "Budaun", "Bulandshahr", "Chandauli", "Chitrakoot", "Deoria", "Etah", "Etawah", "Faizabad", "Farrukhabad", "Fatehpur", "Firozabad", "Gautam Buddha Nagar", "Ghaziabad", "Ghazipur", "Gonda", "Gorakhpur", "Hamirpur", "Hardoi", "Hathras", "Jalaun", "Jaunpur", "Jhansi", "Jyotiba Phule Nagar", "Kannauj", "Kanpur Dehat", "Kanpur Nagar", "Kaushambi", "Kheri", "Kushinagar", "Lalitpur", "Lucknow", "Maharajganj", "Mahoba", "Mainpuri", "Mathura", "Mau", "Meerut", "Mirzapur", "Moradabad", "Muzaffarnagar", "Pilibhit", "Pratapgarh", "Raebareli", "Rampur", "Saharanpur", "Sant Kabir Nagar", "Sant Ravidas Nagar", "Shahjahanpur", "Shrawasti", "Siddharthnagar", "Sitapur", "Sonbhadra", "Sultanpur", "Unnao", "Varanasi"];
const uttarakhandDistrictArray = ["Almora", "Bageshwar", "Chamoli", "Champawat", "Dehradun", "Haridwar", "Nainital", "Pauri Garhwal", "Pithoragarh", "Rudraprayag", "Tehri Garhwal", "Udham Singh Nagar", "Uttarkashi"];
const westBengalDistrictArray = ["Bankura", "Bardhaman", "Birbhum", "Cooch Behar", "Darjiling", "East Midnapore", "Hooghly", "Howrah", "Jalpaiguri", "Kolkata", "Malda", "Medinipur", "Murshidabad", "Nadia", "North 24 Parganas", "North Dinajpur", "Puruliya", "South 24 Parganas", "South Dinajpur", "West Midnapore",];

let imageKey = []
export default function AddHospital() {
  let counter = 0;

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const [selectedHopitalImage, setSelectedHospitalImage] = useState(null);
  const [hospitalImgKey, setHospitalImgKey] = useState([]);
  const [selectedHopitalCertificateImage, setSelectedHospitalCertificateImage] = useState(null);
  const [selectedHopitalLicenseImage, setSelectedHopitalLicenseImage] = useState(null);
  const [selectedHopitalGstImage, setSelectedHopitalGstImage] = useState(null);

  const [hospitalUploadLoading, setHospitalUploadLoading] = useState(false);
  const [certificateUploadLoading, setCertificateUploadLoading] = useState(false);
  const [licenseUploadLoading, setLicenseUploadLoading] = useState(false);
  const [gstCertificateUploadLoading, setGstCertificateUploadLoading] = useState(false);

  const [hospitalImgUploadDone, setHospitalImgUploadDone] = useState(false);
  const [certificateUploadDone, setCertificateUploadDone] = useState(false);
  const [licenseUploadDone, setLicenseUploadDone] = useState(false);
  const [gstCertificateUploadDone, setGstCertificateUploadDone] = useState(false);

  const [showImgUpload, setShowImgUpload] = useState(false);

  const handleImgUploadClose = () => setShowImgUpload(false);
  const handleImgUploadShow = () => setShowImgUpload(true);

  const [validated, setValidated] = useState(false);
  const [addHospitalMutation, { loading }] = useMutation(ADD_HOSPITAL, {
    refetchQueries: [
      QUERY_PENDING_HOSPITAL
    ]
  });
  const [facilitiesState, setFacilitiesState] = useState([]);
  const [mFacilitiesState, setMfacilitiesState] = useState([]);
  const [acceptanceState, setAcceptancesState] = useState([]);

  console.log("facilitiestate", facilitiesState)


  const [hospitalName, setHospitalName] = useState('');
  const [hospitalType, setHospitalType] = useState('');
  const [otherHospitalType, setotherHospitalType] = useState('');
  const [otherHealthCareProvider, setotherHealthCareProvider] = useState('');
  const [hospitalHealthCareProviderType, sethospitalHealthCareProviderType] = useState('');
  const [hospitalRegistrationNumber, sethospitalRegistrationNumber] = useState('');
  const [hospitalCity, setHospitalCity] = useState('');
  const [hospitalBlock, sethospitalBlock] = useState('');
  const [hospitalVillage, sethospitalVillage] = useState('');
  const [hospitalAddress, sethospitalAddress] = useState('');
  const [hospitalDistrict, setHospitalDistrict] = useState('');
  const [hospitalState, setHospitalState] = useState('');
  const [hospitalPincode, setHospitalPincode] = useState('');
  const [PersonName, setPersonName] = useState('');
  const [PersonDesignation, setPersonDesignation] = useState('');
  const [PersonContact, setPersonContact] = useState('');
  const [PersonEmail, setPersonEmail] = useState('');
  const [hospitalEmail, sethospitalEmail] = useState('');
  const [hospitalContact, sethospitalContact] = useState('');
  const [hospitalCertificateImg, sethospitalCertificateImg] = useState('');
  const [hospitalLicenseImg, sethospitalLicenseImg] = useState('');
  const [hospitalImages, sethospitalImages] = useState('');
  const [hospitalGstCertificateImg, sethospitalGstCertificateImg] = useState('');
  const [hospitalWebsiteLink, setHospitalWebsiteLink] = useState('');
  const [hospitalEstablishmentYear, sethospitalEstablishmentYear] = useState('');
  const [hospitalLatitude, setHospitalLatitude] = useState('');
  const [hospitalLongitude, setHospitalLongitude] = useState('');
  const [noOfDoctors, setNoOfDoctors] = useState('');
  const [noOfMedicalConsultants, setNoOfMedicalConsultants] = useState('');
  const [noOfNurses, setNoOfNurses] = useState('');
  const [noOfBeds, setNoOfBeds] = useState('');
  const [noOfPrivateBeds, setNoOfPrivateBeds] = useState('');
  const [noOfEmergencyBeds, setNoOfEmergencyBeds] = useState('');


  const [password, setPassword] = useState('');
  const [agentId, setagentId] = useState('');


  //  start service add

  const [checkedOpd, setCheckedOpd] = useState(false);
  const [checkedGeneralSurgical, setCheckedGeneralSurgical] = useState(false);
  const [checkedSpecialitySurgical, setCheckedSpecialitySurgical] = useState(false);
  const [checkedEmergency, setCheckedEmergency] = useState(false);
  const [checkedLaborRoom, setCheckedLaborRoom] = useState(false);
  const [checkedNursingServices, setCheckedNursingServices] = useState(false);
  const [checkedPhysiotherapy, setCheckedPhysiotherapy] = useState(false);
  const [checkedRehabilitation, setCheckedRehabilitation] = useState(false);
  const [checkedIcu, setCheckedIcu] = useState(false);
  const [checkedDiagnosis, setCheckedDiagnosis] = useState(false);
  const [checkedAnesthetics, setCheckedAnesthetics] = useState(false);
  const [checkedBreastScanning, setCheckedBreastScanning] = useState(false);
  const [checkedCardiology, setCheckedCardiology] = useState(false);
  const [checkedEnt, setCheckedEnt] = useState(false);
  const [checkedGastroenterology, setCheckedGastroenterology] = useState(false);
  const [checkedGynecology, setCheckedGynecology] = useState(false);
  const [checkedHematology, setCheckedHematology] = useState(false);
  const [checkedNeonatalUnit, setCheckedNeonatalUnit] = useState(false);
  const [checkedNeurology, setCheckedNeurology] = useState(false);
  const [checkedNutritionAndDietetics, setCheckedNutritionAndDietetics] = useState(false);
  const [checkedObstetrics, setCheckedObstetrics] = useState(false);
  const [checkedOncology, setCheckedOncology] = useState(false);
  const [checkedOphthalmology, setCheckedOphthalmology] = useState(false);
  const [checkedOrthopedics, setCheckedOrthopedics] = useState(false);
  const [checkedRenalUnit, setCheckedRenalUnit] = useState(false);
  const [checkedSexualHealth, setCheckedSexualHealth] = useState(false);
  const [checkedUrology, setCheckedUrology] = useState(false);
  const [serviceState, setServiceState] = useState([false]);
  const [OPDText, setOpdText] = useState()

  const [checkOpdPrice, setCheckOpdPrice] = useState('');
  const [checkGeneralSurgicalPrice, setCheckGeneralSurgicalPrice] = useState('');
  const [checkSpecialitySurgicalPrice, setCheckSpecialitySurgicalPrice] = useState('');
  const [checkEmergencyPrice, setCheckEmergencyPrice] = useState('');
  const [checkLaborRoomPrice, setCheckLaborRoomPrice] = useState('');
  const [checkNursingServicesPrice, setCheckNursingServicesPrice] = useState('');
  const [checkPhysiotherapyPrice, setCheckPhysiotherapyPrice] = useState('');
  const [checkRehabilitationPrice, setCheckRehabilitationPrice] = useState('');
  const [checkIcuPrice, setCheckIcuPrice] = useState('');
  const [checkDiagnosisPrice, setCheckDiagnosisPrice] = useState('');
  const [checkAnestheticsPrice, setCheckAnestheticsPrice] = useState('');
  const [checkBreastScanningPrice, setCheckBreastScanningPrice] = useState('');
  const [checkCardiologyPrice, setCheckCardiologyPrice] = useState('');
  const [checkEntPrice, setCheckEntPrice] = useState('');
  const [checkGastroenterologyPrice, setCheckGastroenterologyPrice] = useState('');
  const [checkGynecologyPrice, setCheckGynecologyPrice] = useState('');
  const [checkHematologyPrice, setCheckHematologyPrice] = useState('');
  const [checkNeonatalUnitPrice, setCheckNeonatalUnitPrice] = useState('');
  const [checkNeurologyPrice, setCheckNeurologyPrice] = useState('');
  const [checkNutritionAndDieteticsPrice, setCheckNutritionAndDieteticsPrice] = useState('');
  const [checkObstetricsPrice, setCheckObstetricsPrice] = useState('');
  const [checkOncologyPrice, setCheckOncologyPrice] = useState('');
  const [checkOphthalmologyPrice, setCheckOphthalmologyPrice] = useState('');
  const [checkOrthopedicsPrice, setCheckOrthopedicsPrice] = useState('');
  const [checkRenalUnitPrice, setCheckRenalUnitPrice] = useState('');
  const [checkSexualHealthPrice, setCheckSexualHealthPrice] = useState('');
  const [checkUrologyPrice, setCheckUrologyPrice] = useState('');

  const [serviceTypeStateOther, setServiceTypeStateOther] = useState([]);



  const handleChangeforFacilities = (event) => {
    const { checked, value } = event.currentTarget;

    if (value === 'OPD') {
      setCheckedOpd(checked);
    } else if (value === 'General Surgical Services') {
      setCheckedGeneralSurgical(checked)
    } else if (value === 'Specialty Surgical Services') {
      setCheckedSpecialitySurgical(checked)
    } else if (value === 'Emergency') {
      setCheckedEmergency(checked)
    } else if (value === 'Labor Room') {
      setCheckedLaborRoom(checked)
    } else if (value === 'Nursing Services') {
      setCheckedNursingServices(checked)
    } else if (value === 'Physiotherapy') {
      setCheckedPhysiotherapy(checked)
    } else if (value === 'Rehabilitation') {
      setCheckedRehabilitation(checked)
    } else if (value === 'ICU') {
      setCheckedIcu(checked)
    } else if (value === 'Diagnosis') {
      setCheckedDiagnosis(checked)
    } else if (value === 'Anesthetics') {
      setCheckedAnesthetics(checked)
    } else if (value === 'Breast Scanning') {
      setCheckedBreastScanning(checked)
    } else if (value === 'Cardiology') {
      setCheckedCardiology(checked)
    } else if (value === 'ENT') {
      setCheckedEnt(checked)
    } else if (value === 'Gastroenterology') {
      setCheckedGastroenterology(checked)
    } else if (value === 'Gynecology') {
      setCheckedGynecology(checked)
    } else if (value === 'Hematology') {
      setCheckedHematology(checked)
    } else if (value === 'Neonatal Unit') {
      setCheckedNeonatalUnit(checked)
    } else if (value === 'Neurology') {
      setCheckedNeurology(checked)
    } else if (value === 'Nutrition & Dietetics') {
      setCheckedNutritionAndDietetics(checked)
    } else if (value === 'Obstetrics') {
      setCheckedObstetrics(checked)
    } else if (value === 'Oncology') {
      setCheckedOncology(checked)
    } else if (value === 'Ophthalmology') {
      setCheckedOphthalmology(checked)
    } else if (value === 'Orthopedics') {
      setCheckedOrthopedics(checked)
    } else if (value === 'Renal Unit') {
      setCheckedRenalUnit(checked)
    } else if (value === 'Sexual Health') {
      setCheckedSexualHealth(checked)
    } else if (value === 'Urology') {
      setCheckedUrology(checked)
    }
    else {
      alert('Somthing error!!!')
    }




    if (checked === true) {
      if (value === 'OPD') {
        setCheckOpdPrice("");
      } else if (value === 'General Surgical Services') {
        setCheckGeneralSurgicalPrice("")
      } else if (value === 'Specialty Surgical Services') {
        setCheckSpecialitySurgicalPrice("")
      }
      else if (value === 'Emergency') {
        setCheckEmergencyPrice("")
      } else if (value === 'Labor Room') {
        setCheckLaborRoomPrice("")
      } else if (value === 'Nursing Services') {
        setCheckNursingServicesPrice("")
      } else if (value === 'Physiotherapy') {
        setCheckPhysiotherapyPrice("")
      } else if (value === 'Rehabilitation') {
        setCheckRehabilitationPrice("")
      } else if (value === 'ICU') {
        setCheckIcuPrice("")
      } else if (value === 'Diagnosis') {
        setCheckDiagnosisPrice("")
      } else if (value === 'Anesthetics') {
        setCheckAnestheticsPrice("")
      } else if (value === 'Breast Scanning') {
        setCheckBreastScanningPrice("")
      } else if (value === 'Cardiology') {
        setCheckCardiologyPrice("")
      } else if (value === 'ENT') {
        setCheckEntPrice("")
      } else if (value === 'Gastroenterology') {
        setCheckGastroenterologyPrice("")
      } else if (value === 'Gynecology') {
        setCheckGynecologyPrice("")
      } else if (value === 'Hematology') {
        setCheckHematologyPrice("")
      } else if (value === 'Neonatal Unit') {
        setCheckNeonatalUnitPrice("")
      } else if (value === 'Neurology') {
        setCheckNeurologyPrice("")
      } else if (value === 'Nutrition & Dietetics') {
        setCheckNutritionAndDieteticsPrice("")
      } else if (value === 'Obstetrics') {
        setCheckObstetricsPrice("")
      } else if (value === 'Oncology') {
        setCheckOncologyPrice("")
      } else if (value === 'Ophthalmology') {
        setCheckOphthalmologyPrice("")
      } else if (value === 'Orthopedics') {
        setCheckOrthopedicsPrice("")
      } else if (value === 'Renal Unit') {
        setCheckRenalUnitPrice("")
      } else if (value === 'Sexual Health') {
        setCheckedSexualHealth(checked)
      } else if (value === 'Urology') {
        setCheckUrologyPrice("")
      }
      else {
        alert('Somthing error!!!')
      }

      setFacilitiesState(current => [...current, { serviceName: value, servicePrice: "" }]);
    }
    if (checked === false) {
      setFacilitiesState(current =>
        current.filter(element => {
          return element.serviceName !== value;
        }),
      );
    }
  };


  const handleChangeforFacilitiesPrice = (price, name) => {
    facilitiesState.filter(elempricet => {
      if (name === 'OPD') {
        setCheckOpdPrice(price)
      } else if (name === 'General Surgical Services') {
        setCheckGeneralSurgicalPrice(price)
      } else if (name === 'Specialty Surgical Services') {
        setCheckSpecialitySurgicalPrice(price)
      }
      else if (name === 'Emergency') {
        setCheckEmergencyPrice(price)
      } else if (name === 'Labor Room') {
        setCheckLaborRoomPrice(price)
      } else if (name === 'Nursing Services') {
        setCheckNursingServicesPrice(price)
      } else if (name === 'Physiotherapy') {
        setCheckPhysiotherapyPrice(price)
      } else if (name === 'Rehabilitation') {
        setCheckRehabilitationPrice(price)
      } else if (name === 'ICU') {
        setCheckIcuPrice(price)
      } else if (name === 'Diagnosis') {
        setCheckDiagnosisPrice(price)
      } else if (name === 'Anesthetics') {
        setCheckAnestheticsPrice(price)
      } else if (name === 'Breast Scanning') {
        setCheckBreastScanningPrice(price)
      } else if (name === 'Cardiology') {
        setCheckCardiologyPrice(price)
      } else if (name === 'ENT') {
        setCheckEntPrice(price)
      } else if (name === 'Gastroenterology') {
        setCheckGastroenterologyPrice(price)
      } else if (name === 'Gynecology') {
        setCheckGynecologyPrice(price)
      } else if (name === 'Hematology') {
        setCheckHematologyPrice(price)
      } else if (name === 'Neonatal Unit') {
        setCheckNeonatalUnitPrice(price)
      } else if (name === 'Neurology') {
        setCheckNeurologyPrice(price)
      } else if (name === 'Nutrition & Dietetics') {
        setCheckNutritionAndDieteticsPrice(price)
      } else if (name === 'Obstetrics') {
        setCheckObstetricsPrice(price)
      } else if (name === 'Oncology') {
        setCheckOncologyPrice(price)
      } else if (name === 'Ophthalmology') {
        setCheckOphthalmologyPrice(price)
      } else if (name === 'Orthopedics') {
        setCheckOrthopedicsPrice(price)
      } else if (name === 'Renal Unit') {
        setCheckRenalUnitPrice(price)
      } else if (name === 'Sexual Health') {
        setCheckedSexualHealth(price)
      } else if (name === 'Urology') {
        setCheckUrologyPrice(price)
      }
      else {
        alert('Somthing error!!!')
      }
      const newState = facilitiesState.map(obj => {
        console.log("obj", obj)
        if (obj.serviceName === name) {
          return { ...obj, servicePrice: price };
        }
        return obj;
      });
      setFacilitiesState(newState);
    });
  };


  let addServiceTypeField = () => {
    setServiceTypeStateOther([...serviceTypeStateOther, { serviceName: "", servicePrice: "" }]);
  };

  let removeServiceTypeField = (i) => {
    let newServiceFormValues = [...serviceTypeStateOther];
    newServiceFormValues.splice(i, 1);
    setServiceTypeStateOther(newServiceFormValues);
  };

  let handleServiceTypeChange = (e, i, nameValue) => {
    console.log("e", e)
    let newServiceFormValues = [...serviceTypeStateOther];
    newServiceFormValues[i][nameValue] = e;
    setServiceTypeStateOther(newServiceFormValues);
  };

  function mergeClick() {
    setFacilitiesState(prev => [...prev, ...serviceTypeStateOther])
    setServiceTypeStateOther([])
    alert('Service Added Successfully...')
  }


  const [formValues3, setFormValues3] = useState([
    {
      doctorName: '',
      qualification: '',
      experience: '',
      speciality: '',
      licenceNo: '',
      ward: '',
    },
  ]);

  let addFormFields = () => {
    setFormValues3([
      ...formValues3,
      {
        doctorName: '',
        qualification: '',
        experience: '',
        speciality: '',
        licenceNo: '',
        ward: '',
      },
    ]);
  };

  let handleChange = (e, i, nameValue) => {
    let newFormValues = [...formValues3];
    newFormValues[i][nameValue] = e;
    setFormValues3(newFormValues);
  };

  const handleChangeforMFacilities = (event) => {
    const { checked, value } = event.currentTarget;
    setMfacilitiesState((prev) =>
      checked ? [...prev, value] : prev.filter((val) => val !== value)
    );
  };


  const handleChangeforAcceptance = (event) => {
    const { checked, value } = event.currentTarget;
    setAcceptancesState((prev) =>
      checked ? [...prev, value] : prev.filter((val) => val !== value)
    );
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
    }
    else {
      e.preventDefault();
      addHospitalMutation({
        variables: {
          hospitalInput: {
            hospitalName: `${hospitalName}`,
            hospitalType: `${hospitalType}`,
            otherHospitalType: `${otherHospitalType}`,
            hospitalHealthCareProviderType: `${hospitalHealthCareProviderType}`,
            otherHealthCareProvider: `${otherHealthCareProvider}`,
            hospitalRegistrationNumber: `${hospitalRegistrationNumber}`,
            hospitalState: `${hospitalState}`,
            hospitalCity: `${hospitalCity}`,
            hospitalBlock: `${hospitalBlock}`,
            hospitalVillage: `${hospitalVillage}`,
            hospitalDistrict: `${hospitalDistrict}`,
            hospitalAddress: `${hospitalAddress}`,
            hospitalPincode: `${hospitalPincode}`,
            hospitalEmail: `${hospitalEmail}`,
            hospitalContact: `${hospitalContact}`,
            hospitalWebsiteLink: `${hospitalWebsiteLink}`,
            hospitalEstablishmentYear: `${hospitalEstablishmentYear}`,
            cPName: `${PersonName}`,
            cPDesignation: `${PersonDesignation}`,
            cPEmail: `${PersonEmail}`,
            cPContact: `${hospitalName}`,
            hospitalCertificateImg: `${hospitalCertificateImg}`,
            hospitalLicenseImg: `${hospitalLicenseImg}`,
            hospitalImages: imageKey,
            hospitalGstCertificateImg: `${hospitalGstCertificateImg}`,
            hospitalLatitude: `${hospitalLatitude}`,
            hospitalLongitude: `${hospitalLongitude}`,
            totalDcoctor: `${noOfDoctors}`,
            noOfMedicalConsultants: `${noOfMedicalConsultants}`,
            noOfNurses: `${noOfNurses}`,
            noOfBeds: parseInt(noOfBeds),
            noOfPrivateBeds: parseInt(noOfPrivateBeds),
            noOfEmergencyBeds: parseInt(noOfEmergencyBeds),
            beneficialServices: acceptanceState,
            addService: facilitiesState,
            doctorDetail: formValues3,
            agentId: null,
            "rejectStatus": "false",
            "rejectComment": "",
          },
        },
      });
      swal({ title: 'Successfull!!!', text: 'Hospital Add Successfully', icon: 'success' });
      setValidated(false);
      imageKey = []
      setHospitalName('');
      setHospitalType('');
      setotherHospitalType('');
      sethospitalHealthCareProviderType('');
      sethospitalHealthCareProviderType('');
      sethospitalRegistrationNumber('');
      setHospitalCity('');
      sethospitalBlock('');
      sethospitalVillage('');
      sethospitalAddress('');
      setHospitalDistrict('');
      setHospitalState('');
      setHospitalPincode('');
      setPersonName('');
      setPersonDesignation('');
      setPersonContact('');
      setPersonEmail('');
      sethospitalContact('');
      sethospitalCertificateImg('');
      sethospitalLicenseImg('');
      sethospitalImages('');
      sethospitalGstCertificateImg('');
      setHospitalWebsiteLink('');
      sethospitalEstablishmentYear('');
      setHospitalLatitude('');
      setHospitalLongitude('');
      setNoOfDoctors('');
      setNoOfMedicalConsultants('');
      setNoOfNurses('');
      setNoOfBeds('');
      setNoOfPrivateBeds('');
      setNoOfEmergencyBeds('');
      hospitalUploadLoading(false);
      certificateUploadLoading(false);
      licenseUploadLoading(false);
      gstCertificateUploadLoading(false);
      hospitalImgUploadDone(false);
      certificateUploadDone(false);
      licenseUploadDone(false);
      gstCertificateUploadDone(false);
      setSelectedHospitalImage(null);
      setHospitalImgKey([]);
      setSelectedHospitalCertificateImage(null);
      setSelectedHopitalLicenseImage(null);
      setSelectedHopitalGstImage(null);
      setFormValues3([
        {
          doctorName: '',
          qualification: '',
          experience: '',
          speciality: '',
          licenceNo: '',
          ward: '',
        },
      ]);
      setFacilitiesState([]);
      setMfacilitiesState([]);
      setAcceptancesState([]);
    }
  };

  const handleHospitalImgSubmit = (e) => {

    try {
      setHospitalUploadLoading(true)
      selectedHopitalImage.map(async (fileData) => {
        const uniqueId = uniqid();
        var regex = new RegExp('[^.]+$');
        var extension = fileData.name.match(regex);
        var uniqueFileName = "hospitalImg" + '-' + uniqueId + '-' + uniqueId + '.' + extension[0];
        imageKey.push(uniqueFileName)
        const params = {
          Bucket: BUCKET_NAME,
          Key: uniqueFileName,
          Body: fileData,
        };
        s3.upload(params, function (err, data) {
          if (err) {
            throw err;
          } else {
            sethospitalCertificateImg(uniqueFileName)
            swal({
              title: 'Successfull!!!',
              text: 'Hospital Images Uploaded Successfully',
              icon: 'success',
            });
            setHospitalImgKey([])
            setSelectedHospitalImage('')
            setHospitalUploadLoading(false)
            setHospitalImgUploadDone(true)
            e.reset()
          }
        });
      })
    } catch (err) {
      swal({
        title: 'Error!!!',
        text: 'Error please try again',
        icon: 'error',
      });
      console.log(err);
      setHospitalUploadLoading(false)
    }
  };




  const handleHospitalCertificateImage = (e) => {
    try {
      setCertificateUploadLoading(true)
      const uniqueId = uniqid();
      var regex = new RegExp('[^.]+$');
      var extension = selectedHopitalCertificateImage.name.match(regex);
      var uniqueFileName = "hoscertificate" + '-' + uniqueId + '-' + uniqueId + '.' + extension[0];
      const params = {
        Bucket: BUCKET_NAME,
        Key: uniqueFileName,
        Body: selectedHopitalCertificateImage,
      };
      s3.upload(params, function (err, data) {
        if (err) {
          throw err;
        } else {
          sethospitalCertificateImg(uniqueFileName)
          swal({
            title: 'Successfull!!!',
            text: 'Hospital Certificate Uploaded Successfully',
            icon: 'success',
          });
          setSelectedHospitalCertificateImage('')
          setCertificateUploadLoading(false)
          setCertificateUploadDone(true)
        }
      });
    } catch (err) {
      swal({
        title: 'Error!!!',
        text: 'Error please try again',
        icon: 'error',
      });
      console.log(err);
      certificateUploadLoading(false)
    }

  };

  const handleHospitalLicenseImage = (e) => {
    setLicenseUploadLoading(true)
    try {
      const uniqueId = uniqid();
      var regex = new RegExp('[^.]+$');
      var extension = selectedHopitalLicenseImage.name.match(regex);

      var uniqueFileName = "hoslicense" + '-' + uniqueId + '-' + uniqueId + '.' + extension[0];
      const params = {
        Bucket: BUCKET_NAME,
        Key: uniqueFileName,
        Body: selectedHopitalLicenseImage,
      };
      s3.upload(params, function (err, data) {
        if (err) {
          throw err;
        } else {
          sethospitalLicenseImg(uniqueFileName)
          swal({
            title: 'Successfull!!!',
            text: 'Hospital License Uploaded Successfully',
            icon: 'success',
          });
          setSelectedHopitalLicenseImage('')
          setLicenseUploadLoading(false)
          setLicenseUploadDone(true)
        }
      });
    } catch (err) {
      swal({
        title: 'Error!!!',
        text: 'Error please try again',
        icon: 'error',
      });
      console.log(err);
      setLicenseUploadLoading(false)
    }

  };

  const handleHospitalGSTCertificateImage = (e) => {
    setGstCertificateUploadLoading(true)
    try {
      const uniqueId = uniqid();
      var regex = new RegExp('[^.]+$');
      var extension = selectedHopitalGstImage.name.match(regex);

      var uniqueFileName = "hosgstcertificate" + '-' + uniqueId + '-' + uniqueId + '.' + extension[0];

      const params = {
        Bucket: BUCKET_NAME,
        Key: uniqueFileName,
        Body: selectedHopitalGstImage,
      };
      s3.upload(params, function (err, data) {
        if (err) {
          throw err;
        } else {
          sethospitalGstCertificateImg(uniqueFileName)
          swal({
            title: 'Successfull!!!',
            text: 'Hospital GST Certificate Uploaded Successfully',
            icon: 'success',
          });
          setSelectedHopitalGstImage('')
          setGstCertificateUploadLoading(false)
          setGstCertificateUploadDone(true)
        }
      });
    } catch (err) {
      swal({
        title: 'Error!!!',
        text: 'Error please try again',
        icon: 'error',
      });
      console.log(err);
      setGstCertificateUploadLoading(false)
    }
  };

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const onChangePhoto = async (e) => {
    const data = Array.from(e.target.files);
    const urls = await Promise.all(
      data.map(() => {
        const params = {
          Bucket: 'myfirstawsbucketsoftseekers',
          Key: v4(),
          Expires: 60,
        };
        return s3.getSignedUrlPromise('putObject', params);
      })
    );
    urls.map((url, i) => {
      return axios.put(url, data[i], config);
    });
  };

  const getUploadParams = ({ meta }) => {
    return { url: 'https://httpbin.org/post' }
  }

  const handleChangeStatus = ({ meta, file }, status) => {
    console.log(status, meta, file)
  }


  function multipleImg(e) {
    console.log(e)
    setSelectedHospitalImage([...e.target.files])
  }
  console.log("serviceTypeStateOther", serviceTypeStateOther)
  return (
    <>
      <div style={{ marginTop: '30px' }}>
        <Container>
          <h1 style={{ fontFamily: 'DM Sans', fontWeight: 'bold' }}>
            Add a Hospital
          </h1>
          <hr />
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            {/*----------------- Hospital Details ---------------------*/}
            <Row>
              <h4 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
                Hospital Details
                <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '19%', marginTop: 10 }}>
                  {' '}
                </div>
              </h4>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group>
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Hospital Name</Form.Label>
                  <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalName" onChange={(e) => setHospitalName(e.target.value)} value={hospitalName} placeholder="Hospital Name" />
                </Form.Group>
              </Col>

              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalType">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Hospital Type</Form.Label>
                  <Form.Select required name="hospitalType" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalType(e.target.value)} value={hospitalType}>
                    <option value="" selected="selected" disabled="disabled">Select hospital Type</option>
                    <option value="Government Center">Government Center</option>
                    <option value="Private Center">Private Center</option>
                    <option value="Semi-Government">Semi-Government</option>
                    <option value="hospitalTypeOther">Other</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              {
                hospitalType === 'hospitalTypeOther' ?
                  <Col md={6} lg={4} className="mt-5">
                    <Form.Group controlId="hospitalType">
                      <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Hospital Other Type</Form.Label>
                      <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setotherHospitalType(e.target.value)} value={otherHospitalType} placeholder="Enter Hospital Other Type" />
                    </Form.Group>
                  </Col> : ''
              }

              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalHealthCareProviderType">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Health Care Provider Type</Form.Label>
                  <Form.Select required name="hospitalHealthCareProviderType" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => sethospitalHealthCareProviderType(e.target.value)} value={hospitalHealthCareProviderType}>
                    <option value="" selected="selected" disabled="disabled">Select Health Care Provider Type</option>
                    <option value="Hospital Dispensary">Hospital Dispensary</option>
                    <option value="Nursing Home">Nursing Home</option>
                    <option value="Medical College">Medical College</option>
                    <option value="Primary Health Center">Primary Health Center</option>
                    <option value="Clinic">Clinic</option>
                    <option value="healthCareOther">Other</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              {
                hospitalHealthCareProviderType === 'healthCareOther' ?
                  <Col md={6} lg={4} className="mt-5">
                    <Form.Group controlId="hospitalType">
                      <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Hospital Care Other Provider</Form.Label>
                      <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setotherHealthCareProvider(e.target.value)} value={otherHealthCareProvider} placeholder="Enter Hospital Care Other Provider" />
                    </Form.Group>
                  </Col> : ''
              }


              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalRegistrationNumber">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Registration Number</Form.Label>
                  <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalRegistrationNumber" onChange={(e) => sethospitalRegistrationNumber(e.target.value)} value={hospitalRegistrationNumber} placeholder="Registration Number" />
                </Form.Group>
              </Col>

              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalState">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>State</Form.Label>
                  <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalState(e.target.value)} value={hospitalState}>
                    <option value="" selected="selected" disabled="disabled">Select State</option>
                    {stateArray.map((d) => (
                      <option value={d}>{d}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>

              {
                hospitalState === '' ?
                  <Col md={6} lg={4} className="mt-5">
                    <Form.Group controlId="hospitalState">
                      <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                      <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                        <option value="" selected="selected" disabled="disabled">Select State To Display District</option>
                      </Form.Select>
                    </Form.Group>
                  </Col> :
                  hospitalState === 'Andhra Pradesh' ?
                    <Col md={6} lg={4} className="mt-5">
                      <Form.Group controlId="hospitalState">
                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                          <option value="" selected="selected" disabled="disabled">Select District</option>
                          {andhraPradeshDistrictArray.map((d) => (
                            <option value={d}>{d}</option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col> :
                    hospitalState === 'Andaman and Nicobar Islands' ?
                      <Col md={6} lg={4} className="mt-5">
                        <Form.Group controlId="hospitalState">
                          <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                          <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                            <option value="" selected="selected" disabled="disabled">Select District</option>
                            {andamanAndNicobarIslandsDistrictArray.map((d) => (
                              <option value={d}>{d}</option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col> :
                      hospitalState === 'Arunachal Pradesh' ?
                        <Col md={6} lg={4} className="mt-5">
                          <Form.Group controlId="hospitalState">
                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                              <option value="" selected="selected" disabled="disabled">Select District</option>
                              {arunachalPradeshDistrictArray.map((d) => (
                                <option value={d}>{d}</option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Col> :
                        hospitalState === 'Assam' ?
                          <Col md={6} lg={4} className="mt-5">
                            <Form.Group controlId="hospitalState">
                              <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                              <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                <option value="" selected="selected" disabled="disabled">Select District</option>
                                {assamDistrictArray.map((d) => (
                                  <option value={d}>{d}</option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </Col> :
                          hospitalState === 'Bihar' ?
                            <Col md={6} lg={4} className="mt-5">
                              <Form.Group controlId="hospitalState">
                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                  <option value="" selected="selected" disabled="disabled">Select District</option>
                                  {biharDistrictArray.map((d) => (
                                    <option value={d}>{d}</option>
                                  ))}
                                </Form.Select>
                              </Form.Group>
                            </Col> :
                            hospitalState === 'Chandigarh' ?
                              <Col md={6} lg={4} className="mt-5">
                                <Form.Group controlId="hospitalState">
                                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                  <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                    {chandigarhDistrictArray.map((d) => (
                                      <option value={d}>{d}</option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>
                              </Col> :
                              hospitalState === 'Chhattisgarh' ?
                                <Col md={6} lg={4} className="mt-5">
                                  <Form.Group controlId="hospitalState">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                      <option value="" selected="selected" disabled="disabled">Select District</option>
                                      {chhattisgarhDistrictArray.map((d) => (
                                        <option value={d}>{d}</option>
                                      ))}
                                    </Form.Select>
                                  </Form.Group>
                                </Col> :
                                hospitalState === 'Dadar and Nagar Haveli' ?
                                  <Col md={6} lg={4} className="mt-5">
                                    <Form.Group controlId="hospitalState">
                                      <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                      <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                        {dadarAndNagarHaveliDistrictArray.map((d) => (
                                          <option value={d}>{d}</option>
                                        ))}
                                      </Form.Select>
                                    </Form.Group>
                                  </Col> :
                                  hospitalState === 'Daman and Diu' ?
                                    <Col md={6} lg={4} className="mt-5">
                                      <Form.Group controlId="hospitalState">
                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                          <option value="" selected="selected" disabled="disabled">Select District</option>
                                          {damanAndDiuDistrictArray.map((d) => (
                                            <option value={d}>{d}</option>
                                          ))}
                                        </Form.Select>
                                      </Form.Group>
                                    </Col> :
                                    hospitalState === 'Delhi' ?
                                      <Col md={6} lg={4} className="mt-5">
                                        <Form.Group controlId="hospitalState">
                                          <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                          <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                            {delhiDistrictArray.map((d) => (
                                              <option value={d}>{d}</option>
                                            ))}
                                          </Form.Select>
                                        </Form.Group>
                                      </Col> :
                                      hospitalState === 'Lakshadweep' ?
                                        <Col md={6} lg={4} className="mt-5">
                                          <Form.Group controlId="hospitalState">
                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                              <option value="" selected="selected" disabled="disabled">Select District</option>
                                              {LakshadweepDistrictArray.map((d) => (
                                                <option value={d}>{d}</option>
                                              ))}
                                            </Form.Select>
                                          </Form.Group>
                                        </Col> :
                                        hospitalState === 'Puducherry' ?
                                          <Col md={6} lg={4} className="mt-5">
                                            <Form.Group controlId="hospitalState">
                                              <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                              <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                                <option value="" selected="selected" disabled="disabled">Select District</option>
                                                {puducherryDistrictArray.map((d) => (
                                                  <option value={d}>{d}</option>
                                                ))}
                                              </Form.Select>
                                            </Form.Group>
                                          </Col> :
                                          hospitalState === 'Goa' ?
                                            <Col md={6} lg={4} className="mt-5">
                                              <Form.Group controlId="hospitalState">
                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                                  <option value="" selected="selected" disabled="disabled">Select District</option>
                                                  {goaDistrictArray.map((d) => (
                                                    <option value={d}>{d}</option>
                                                  ))}
                                                </Form.Select>
                                              </Form.Group>
                                            </Col> :
                                            hospitalState === 'Gujarat' ?
                                              <Col md={6} lg={4} className="mt-5">
                                                <Form.Group controlId="hospitalState">
                                                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                  <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                    {gujaratDistrictArray.map((d) => (
                                                      <option value={d}>{d}</option>
                                                    ))}
                                                  </Form.Select>
                                                </Form.Group>
                                              </Col> :
                                              hospitalState === 'Jammu and Kashmir' ?
                                                <Col md={6} lg={4} className="mt-5">
                                                  <Form.Group controlId="hospitalState">
                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                                      <option value="" selected="selected" disabled="disabled">Select District</option>
                                                      {jammuAndKashmirDistrictArray.map((d) => (
                                                        <option value={d}>{d}</option>
                                                      ))}
                                                    </Form.Select>
                                                  </Form.Group>
                                                </Col> :
                                                hospitalState === 'Himachal Pradesh' ?
                                                  <Col md={6} lg={4} className="mt-5">
                                                    <Form.Group controlId="hospitalState">
                                                      <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                      <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                        {himachalPradeshDistrictArray.map((d) => (
                                                          <option value={d}>{d}</option>
                                                        ))}
                                                      </Form.Select>
                                                    </Form.Group>
                                                  </Col> :
                                                  hospitalState === 'Jharkhand' ?
                                                    <Col md={6} lg={4} className="mt-5">
                                                      <Form.Group controlId="hospitalState">
                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                                          <option value="" selected="selected" disabled="disabled">Select District</option>
                                                          {jharkhandDistrictArray.map((d) => (
                                                            <option value={d}>{d}</option>
                                                          ))}
                                                        </Form.Select>
                                                      </Form.Group>
                                                    </Col> :
                                                    hospitalState === 'Karnataka' ?
                                                      <Col md={6} lg={4} className="mt-5">
                                                        <Form.Group controlId="hospitalState">
                                                          <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                          <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                            {karnatakaDistrictArray.map((d) => (
                                                              <option value={d}>{d}</option>
                                                            ))}
                                                          </Form.Select>
                                                        </Form.Group>
                                                      </Col> :
                                                      hospitalState === 'Kerala' ?
                                                        <Col md={6} lg={4} className="mt-5">
                                                          <Form.Group controlId="hospitalState">
                                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                                              <option value="" selected="selected" disabled="disabled">Select District</option>
                                                              {keralaDistrictArray.map((d) => (
                                                                <option value={d}>{d}</option>
                                                              ))}
                                                            </Form.Select>
                                                          </Form.Group>
                                                        </Col> :
                                                        hospitalState === 'Madhya Pradesh' ?
                                                          <Col md={6} lg={4} className="mt-5">
                                                            <Form.Group controlId="hospitalState">
                                                              <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                              <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                                                <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                {madhyaPradeshDistrictArray.map((d) => (
                                                                  <option value={d}>{d}</option>
                                                                ))}
                                                              </Form.Select>
                                                            </Form.Group>
                                                          </Col> :
                                                          hospitalState === 'Maharashtra' ?
                                                            <Col md={6} lg={4} className="mt-5">
                                                              <Form.Group controlId="hospitalState">
                                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                                                  <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                  {maharashtraDistrictArray.map((d) => (
                                                                    <option value={d}>{d}</option>
                                                                  ))}
                                                                </Form.Select>
                                                              </Form.Group>
                                                            </Col> :
                                                            hospitalState === 'Manipur' ?
                                                              <Col md={6} lg={4} className="mt-5">
                                                                <Form.Group controlId="hospitalState">
                                                                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                  <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                    {manipurDistrictArray.map((d) => (
                                                                      <option value={d}>{d}</option>
                                                                    ))}
                                                                  </Form.Select>
                                                                </Form.Group>
                                                              </Col> :
                                                              hospitalState === 'Meghalaya' ?
                                                                <Col md={6} lg={4} className="mt-5">
                                                                  <Form.Group controlId="hospitalState">
                                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                                                      <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                      {meghalayaDistrictArray.map((d) => (
                                                                        <option value={d}>{d}</option>
                                                                      ))}
                                                                    </Form.Select>
                                                                  </Form.Group>
                                                                </Col> :
                                                                hospitalState === 'Mizoram' ?
                                                                  <Col md={6} lg={4} className="mt-5">
                                                                    <Form.Group controlId="hospitalState">
                                                                      <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                      <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                        {mizoramDistrictArray.map((d) => (
                                                                          <option value={d}>{d}</option>
                                                                        ))}
                                                                      </Form.Select>
                                                                    </Form.Group>
                                                                  </Col> :
                                                                  hospitalState === 'Nagaland' ?
                                                                    <Col md={6} lg={4} className="mt-5">
                                                                      <Form.Group controlId="hospitalState">
                                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                                                          <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                          {nagalandDistrictArray.map((d) => (
                                                                            <option value={d}>{d}</option>
                                                                          ))}
                                                                        </Form.Select>
                                                                      </Form.Group>
                                                                    </Col> :
                                                                    hospitalState === 'Odisha' ?
                                                                      <Col md={6} lg={4} className="mt-5">
                                                                        <Form.Group controlId="hospitalState">
                                                                          <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                          <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                            {odishaDistrictArray.map((d) => (
                                                                              <option value={d}>{d}</option>
                                                                            ))}
                                                                          </Form.Select>
                                                                        </Form.Group>
                                                                      </Col> :
                                                                      hospitalState === 'Punjab' ?
                                                                        <Col md={6} lg={4} className="mt-5">
                                                                          <Form.Group controlId="hospitalState">
                                                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                                                              <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                              {punjabDistrictArray.map((d) => (
                                                                                <option value={d}>{d}</option>
                                                                              ))}
                                                                            </Form.Select>
                                                                          </Form.Group>
                                                                        </Col> :
                                                                        hospitalState === 'Rajasthan' ?
                                                                          <Col md={6} lg={4} className="mt-5">
                                                                            <Form.Group controlId="hospitalState">
                                                                              <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                              <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                                                                <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                {rajasthanDistrictArray.map((d) => (
                                                                                  <option value={d}>{d}</option>
                                                                                ))}
                                                                              </Form.Select>
                                                                            </Form.Group>
                                                                          </Col> :
                                                                          hospitalState === 'Sikkim' ?
                                                                            <Col md={6} lg={4} className="mt-5">
                                                                              <Form.Group controlId="hospitalState">
                                                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                                                                  <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                  {sikkimDistrictArray.map((d) => (
                                                                                    <option value={d}>{d}</option>
                                                                                  ))}
                                                                                </Form.Select>
                                                                              </Form.Group>
                                                                            </Col> :
                                                                            hospitalState === 'Tamil Nadu' ?
                                                                              <Col md={6} lg={4} className="mt-5">
                                                                                <Form.Group controlId="hospitalState">
                                                                                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                  <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                    {tamilNaduDistrictArray.map((d) => (
                                                                                      <option value={d}>{d}</option>
                                                                                    ))}
                                                                                  </Form.Select>
                                                                                </Form.Group>
                                                                              </Col> :
                                                                              hospitalState === 'Telangana' ?
                                                                                <Col md={6} lg={4} className="mt-5">
                                                                                  <Form.Group controlId="hospitalState">
                                                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                                                                      <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                      {telanganaDistrictArray.map((d) => (
                                                                                        <option value={d}>{d}</option>
                                                                                      ))}
                                                                                    </Form.Select>
                                                                                  </Form.Group>
                                                                                </Col> :
                                                                                hospitalState === 'Tripura' ?
                                                                                  <Col md={6} lg={4} className="mt-5">
                                                                                    <Form.Group controlId="hospitalState">
                                                                                      <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                      <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                        {tripuraDistrictArray.map((d) => (
                                                                                          <option value={d}>{d}</option>
                                                                                        ))}
                                                                                      </Form.Select>
                                                                                    </Form.Group>
                                                                                  </Col> :
                                                                                  hospitalState === 'Uttar Pradesh' ?
                                                                                    <Col md={6} lg={4} className="mt-5">
                                                                                      <Form.Group controlId="hospitalState">
                                                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                                                                          <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                          {uttarPradeshDistrictArray.map((d) => (
                                                                                            <option value={d}>{d}</option>
                                                                                          ))}
                                                                                        </Form.Select>
                                                                                      </Form.Group>
                                                                                    </Col> :
                                                                                    hospitalState === 'Uttarakhand' ?
                                                                                      <Col md={6} lg={4} className="mt-5">
                                                                                        <Form.Group controlId="hospitalState">
                                                                                          <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                          <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                            {uttarakhandDistrictArray.map((d) => (
                                                                                              <option value={d}>{d}</option>
                                                                                            ))}
                                                                                          </Form.Select>
                                                                                        </Form.Group>
                                                                                      </Col> :
                                                                                      hospitalState === 'West Bengal' ?
                                                                                        <Col md={6} lg={4} className="mt-5">
                                                                                          <Form.Group controlId="hospitalState">
                                                                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict}>
                                                                                              <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                              {westBengalDistrictArray.map((d) => (
                                                                                                <option value={d}>{d}</option>
                                                                                              ))}
                                                                                            </Form.Select>
                                                                                          </Form.Group>
                                                                                        </Col> : ""
              }

              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalCity">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>City</Form.Label>
                  <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalCity" onChange={(e) => setHospitalCity(e.target.value)} value={hospitalCity} placeholder="Hospital City" />
                </Form.Group>
              </Col>

              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalBlock">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Block</Form.Label>
                  <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalBlock" onChange={(e) => sethospitalBlock(e.target.value)} value={hospitalBlock} placeholder="Hospital Block" />
                </Form.Group>
              </Col>

              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalVillage">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Village</Form.Label>
                  <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalVillage" onChange={(e) => sethospitalVillage(e.target.value)} value={hospitalVillage} placeholder="Hospital Village" />
                </Form.Group>
              </Col>

              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalAddress">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Address</Form.Label>
                  <Form.Control required rows="1" as="textarea" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalAddress" onChange={(e) => sethospitalAddress(e.target.value)} value={hospitalAddress} placeholder="Hospital Address" />
                </Form.Group>
              </Col>

              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalPincode">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Pincode</Form.Label>
                  <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalPincode" onChange={(e) => setHospitalPincode(e.target.value)} value={hospitalPincode} placeholder="Pin Code" onWheel={(e) => e.target.blur()} />
                </Form.Group>
              </Col>

              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalContact">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Hospital Contact</Form.Label>
                  <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="number" onChange={(e) => sethospitalContact(e.target.value)} value={hospitalContact} placeholder="Hospital Contact" onWheel={(e) => e.target.blur()} />
                </Form.Group>
              </Col>

              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalEmail">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Hospital Email</Form.Label>
                  <Form.Control required type="email" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="Email" onChange={(e) => sethospitalEmail(e.target.value)} value={hospitalEmail} placeholder="Hospital Email" />
                </Form.Group>
              </Col>

              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalWebsiteLink">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Hospital Website Link</Form.Label>
                  <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalWebsiteLink" onChange={(e) => setHospitalWebsiteLink(e.target.value)} value={hospitalWebsiteLink} placeholder="Hospital Website Link" />
                </Form.Group>
              </Col>

              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalEstablishmentYear">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Hospital Establishment Year</Form.Label>
                  <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalEstablishmentYear" onChange={(e) => sethospitalEstablishmentYear(e.target.value)} value={hospitalEstablishmentYear} placeholder="(YYYY)" onWheel={(e) => e.target.blur()} />
                </Form.Group>
              </Col>

              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalEstablishmentYear">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Hospital Latitude</Form.Label>
                  <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalLatitude" onChange={(e) => setHospitalLatitude(e.target.value)} value={hospitalLatitude} placeholder="Hospital Latitude" onWheel={(e) => e.target.blur()} />
                </Form.Group>
              </Col>

              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalEstablishmentYear">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Hospital Longitude</Form.Label>
                  <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalLongitude" onChange={(e) => setHospitalLongitude(e.target.value)} value={hospitalLongitude} placeholder="Hospital Longitude" onWheel={(e) => e.target.blur()} />
                </Form.Group>
              </Col>
            </Row>

            {/*----------------- Nodel person Details ---------------------*/}

            <Row>
              <h4 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
                Person Details
                <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '17%', marginTop: 10 }}>
                  {' '}
                </div>
              </h4>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="contactPersonName">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Contact Person Name</Form.Label>
                  <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="contactPersonName" placeholder="Contact Person Name" onChange={(e) => setPersonName(e.target.value)} value={PersonName} />
                </Form.Group>
              </Col>

              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="contactPersonDesignation">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Contact Person Designation</Form.Label>
                  <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="contactPersonDesignation" placeholder="Contact Person Designation" onChange={(e) => setPersonDesignation(e.target.value)} value={PersonDesignation} />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="contactPersonEmail">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Contact Person Email</Form.Label>
                  <Form.Control required type="email" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="contactPersonEmail" onChange={(e) => setPersonEmail(e.target.value)} value={PersonEmail} placeholder="Contact Person Email" />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="contactPersonContact">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Contact Person Contact</Form.Label>
                  <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="contactPersonContact" onChange={(e) => setPersonContact(e.target.value)} value={PersonContact} placeholder="Contact Person Contact" onWheel={(e) => e.target.blur()} />
                </Form.Group>
              </Col>
            </Row>

            {/*----------------- Additional Deails ---------------------*/}

            <Row>
              <h4 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
                Additional Details
                <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '18%', marginTop: 10 }}>
                  {' '}
                </div>
              </h4>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="noOfDoctors">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>No of Doctors</Form.Label>
                  <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setNoOfDoctors(e.target.value)} value={noOfDoctors} placeholder="No of Doctors" onWheel={(e) => e.target.blur()} />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="noOfDoctors">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>No of Medical Consultants</Form.Label>
                  <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setNoOfMedicalConsultants(e.target.value)} value={noOfMedicalConsultants} placeholder="No of Medical Consultants" onWheel={(e) => e.target.blur()} />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="noOfDoctors">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>No of Nurses</Form.Label>
                  <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setNoOfNurses(e.target.value)} value={noOfNurses} placeholder="No of Nurses" onWheel={(e) => e.target.blur()} />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="noOfDoctors">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>No of Beds</Form.Label>
                  <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setNoOfBeds(e.target.value)} value={noOfBeds} placeholder="No of Beds" onWheel={(e) => e.target.blur()} />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="noOfDoctors">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>No of Private Beds</Form.Label>
                  <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setNoOfPrivateBeds(e.target.value)} value={noOfPrivateBeds} placeholder="No of Private Beds" onWheel={(e) => e.target.blur()} />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="noOfDoctors">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>No of Emergency Beds</Form.Label>
                  <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setNoOfEmergencyBeds(e.target.value)} value={noOfEmergencyBeds} placeholder="No of Emergency Beds" onWheel={(e) => e.target.blur()} />
                </Form.Group>
              </Col>
            </Row>

            {/*----------------- Services ---------------------*/}

            <Row>
              <h4 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
                Services
                <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '62%', marginTop: 10 }}>
                  {' '}
                </div>
              </h4>
              <Col md={4} lg={4} className="mt-5">
                <Form.Group controlId="hospitalServices">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}> Acceptance of beneficial services for consumers</Form.Label>
                  <br />
                  <input type="checkbox" name="BPL" value="BPL" checked={acceptanceState.some(val => val === "BPL")} onChange={handleChangeforAcceptance} />
                  <label style={{ marginLeft: '10px' }}>BPL</label><br></br>

                  <input type="checkbox" name="Ayushman Card" value="Ayushman Card" checked={acceptanceState.some(val => val === "Ayushman Card")} onChange={handleChangeforAcceptance} />
                  <label style={{ marginLeft: '10px' }}>Ayushman Card</label><br></br>

                  <input type="checkbox" name="Health Card" value="Health Card" checked={acceptanceState.some(val => val === "Health Card")} onChange={handleChangeforAcceptance} />
                  <label style={{ marginLeft: '10px' }}>Health Card</label><br></br>

                  <input type="checkbox" name="Health Insurance" value="Health Insurance" checked={acceptanceState.some(val => val === "Health Insurance")} onChange={handleChangeforAcceptance} />
                  <label style={{ marginLeft: '10px' }}>Health Insurance</label><br></br>

                </Form.Group>
              </Col>

              <Col md={4} lg={4} className="mt-5">
                <Form.Group controlId="hospitalServices">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Facilities</Form.Label>
                  <br />
                  <input type="checkbox" name="OPD" value="OPD" onChange={handleChangeforFacilities} />
                  <label style={{ marginLeft: '10px' }}>OPD</label><br></br>
                  {
                    checkedOpd ?
                      <>
                        <label style={{ marginLeft: '10px' }}>OPD Price</label><br></br>
                        <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'OPD')} value={checkOpdPrice} />
                        <br></br>
                      </>
                      : ""
                  }
                  <input type="checkbox" name="General Surgical Services" value="General Surgical Services" onChange={handleChangeforFacilities} />
                  <label style={{ marginLeft: '10px' }}>General Surgical Services</label><br></br>
                  {
                    checkedGeneralSurgical ?
                      <>
                        <label style={{ marginLeft: '10px' }}>General Surgical Services</label><br></br>
                        <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'General Surgical Services')} value={checkGeneralSurgicalPrice} />
                        <br></br>
                      </>
                      : ""
                  }

                  <input type="checkbox" name="Specialty Surgical Services" value="Specialty Surgical Services" onChange={handleChangeforFacilities} />
                  <label style={{ marginLeft: '10px' }}>Specialty Surgical Services</label><br></br>
                  {
                    checkedSpecialitySurgical ?
                      <>
                        <label style={{ marginLeft: '10px' }}>Specialty Surgical Services Price</label><br></br>
                        <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Specialty Surgical Services')} value={checkSpecialitySurgicalPrice} />
                        <br></br>
                      </>
                      : ""
                  }

                  <input type="checkbox" name="Emergency" value="Emergency" onChange={handleChangeforFacilities} />
                  <label style={{ marginLeft: '10px' }}>Emergency</label><br></br>
                  {
                    checkedEmergency ?
                      <>
                        <label style={{ marginLeft: '10px' }}>Emergency Price</label><br></br>
                        <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Emergency')} value={checkEmergencyPrice} />
                        <br></br>
                      </>
                      : ""
                  }

                  <input type="checkbox" name="Labor Room" value="Labor Room" onChange={handleChangeforFacilities} />
                  <label style={{ marginLeft: '10px' }}>Labor Room</label><br></br>
                  {
                    checkedLaborRoom ?
                      <>
                        <label style={{ marginLeft: '10px' }}>Labor Room Price</label><br></br>
                        <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Labor Room')} value={checkLaborRoomPrice} />
                        <br></br>
                      </>
                      : ""
                  }

                  <input type="checkbox" name="Nursing Services" value="Nursing Services" onChange={handleChangeforFacilities} />
                  <label style={{ marginLeft: '10px' }}>Nursing Services</label><br></br>
                  {
                    checkedNursingServices ?
                      <>
                        <label style={{ marginLeft: '10px' }}>Nursing Services Price</label><br></br>
                        <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Nursing Services')} value={checkNursingServicesPrice} />
                        <br></br>
                      </>
                      : ""
                  }

                  <input type="checkbox" name="Physiotherapy" value="Physiotherapy" onChange={handleChangeforFacilities} />
                  <label style={{ marginLeft: '10px' }}>Physiotherapy</label><br></br>
                  {
                    checkedPhysiotherapy ?
                      <>
                        <label style={{ marginLeft: '10px' }}>Physiotherapy Price</label><br></br>
                        <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Physiotherapy')} value={checkPhysiotherapyPrice} />
                        <br></br>
                      </>
                      : ""
                  }

                  <input type="checkbox" name="Rehabilitation" value="Rehabilitation" onChange={handleChangeforFacilities} />
                  <label style={{ marginLeft: '10px' }}>Rehabilitation</label><br></br>
                  {
                    checkedRehabilitation ?
                      <>
                        <label style={{ marginLeft: '10px' }}>Rehabilitation Price</label><br></br>
                        <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Rehabilitation')} value={checkRehabilitationPrice} />
                        <br></br>
                      </>
                      : ""
                  }

                  <input type="checkbox" name="ICU" value="ICU" onChange={handleChangeforFacilities} />
                  <label style={{ marginLeft: '10px' }}>ICU</label><br></br>
                  {
                    checkedIcu ?
                      <>
                        <label style={{ marginLeft: '10px' }}>ICU Price</label><br></br>
                        <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'ICU')} value={checkIcuPrice} />
                        <br></br>
                      </>
                      : ""
                  }

                  <input type="checkbox" name="Diagnosis" value="Diagnosis" onChange={handleChangeforFacilities} />
                  <label style={{ marginLeft: '10px' }}>Diagnosis</label><br></br>
                  {
                    checkedDiagnosis ?
                      <>
                        <label style={{ marginLeft: '10px' }}>Diagnosis Price</label><br></br>
                        <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Diagnosis')} value={checkDiagnosisPrice} />
                        <br></br>
                      </>
                      : ""
                  }

                  <input type="checkbox" name="Anesthetics" value="Anesthetics" onChange={handleChangeforFacilities} />
                  <label style={{ marginLeft: '10px' }}>Anesthetics</label><br></br>
                  {
                    checkedAnesthetics ?
                      <>
                        <label style={{ marginLeft: '10px' }}>Anesthetics Price</label><br></br>
                        <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Anesthetics')} value={checkAnestheticsPrice} />
                        <br></br>
                      </>
                      : ""
                  }

                  <input type="checkbox" name="Breast Scanning" value="Breast Scanning" onChange={handleChangeforFacilities} />
                  <label style={{ marginLeft: '10px' }}>Breast Scanning</label><br></br>
                  {
                    checkedBreastScanning ?
                      <>
                        <label style={{ marginLeft: '10px' }}>Breast Scanning Price</label><br></br>
                        <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Breast Scanning')} value={checkBreastScanningPrice} />
                        <br></br>
                      </>
                      : ""
                  }

                  <input type="checkbox" name="Cardiology" value="Cardiology" onChange={handleChangeforFacilities} />
                  <label style={{ marginLeft: '10px' }}>Cardiology</label><br></br>
                  {
                    checkedCardiology ?
                      <>
                        <label style={{ marginLeft: '10px' }}>Cardiology Price</label><br></br>
                        <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Cardiology')} value={checkCardiologyPrice} />
                        <br></br>
                      </>
                      : ""
                  }

                  <input type="checkbox" name="ENT" value="ENT" onChange={handleChangeforFacilities} />
                  <label style={{ marginLeft: '10px' }}>ENT</label><br></br>
                  {
                    checkedEnt ?
                      <>
                        <label style={{ marginLeft: '10px' }}>ENT Price</label><br></br>
                        <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'ENT')} value={checkEntPrice} />
                        <br></br>
                      </>
                      : ""
                  }

                </Form.Group>
              </Col>

              <Col md={4} lg={4} className="mt-5">
                <Form.Group controlId="hospitalServices">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Facilities</Form.Label>
                  <br />
                  <input type="checkbox" name="Gastroenterology" value="Gastroenterology" onChange={handleChangeforFacilities} />
                  <label style={{ marginLeft: '10px' }}>Gastroenterology</label><br></br>
                  {
                    checkedGastroenterology ?
                      <>
                        <label style={{ marginLeft: '10px' }}>Gastroenterology Price</label><br></br>
                        <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Gastroenterology')} value={checkGastroenterologyPrice} />
                        <br></br>
                      </>
                      : ""
                  }

                  <input type="checkbox" name="Gynecology" value="Gynecology" onChange={handleChangeforFacilities} />
                  <label style={{ marginLeft: '10px' }}>Gynecology</label><br></br>
                  {
                    checkedGynecology ?
                      <>
                        <label style={{ marginLeft: '10px' }}>Gynecology Price</label><br></br>
                        <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Gynecology')} value={checkGynecologyPrice} />
                        <br></br>
                      </>
                      : ""
                  }

                  <input type="checkbox" name="Hematology" value="Hematology" onChange={handleChangeforFacilities} />
                  <label style={{ marginLeft: '10px' }}>Hematology</label><br></br>
                  {
                    checkedHematology ?
                      <>
                        <label style={{ marginLeft: '10px' }}>Hematology Price</label><br></br>
                        <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Hematology')} value={checkHematologyPrice} />
                        <br></br>
                      </>
                      : ""
                  }

                  <input type="checkbox" name="Neonatal Unit" value="Neonatal Unit" onChange={handleChangeforFacilities} />
                  <label style={{ marginLeft: '10px' }}>Neonatal Unit</label><br></br>
                  {
                    checkedNeonatalUnit ?
                      <>
                        <label style={{ marginLeft: '10px' }}>Neonatal Unit Price</label><br></br>
                        <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Neonatal Unit')} value={checkNeonatalUnitPrice} />
                        <br></br>
                      </>
                      : ""
                  }

                  <input type="checkbox" name="Neurology" value="Neurology" onChange={handleChangeforFacilities} />
                  <label style={{ marginLeft: '10px' }}>Neurology</label><br></br>
                  {
                    checkedNeurology ?
                      <>
                        <label style={{ marginLeft: '10px' }}>Neurology Price</label><br></br>
                        <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Neurology')} value={checkNeurologyPrice} />
                        <br></br>
                      </>
                      : ""
                  }

                  <input type="checkbox" name="Nutrition & Dietetics" value="Nutrition & Dietetics" onChange={handleChangeforFacilities} />
                  <label style={{ marginLeft: '10px' }}>Nutrition & Dietetics</label><br></br>
                  {
                    checkedNutritionAndDietetics ?
                      <>
                        <label style={{ marginLeft: '10px' }}>Nutrition & Dietetics Price</label><br></br>
                        <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Nutrition & Dietetics')} value={checkNutritionAndDieteticsPrice} />
                        <br></br>
                      </>
                      : ""
                  }

                  <input type="checkbox" name="Obstetrics" value="Obstetrics" onChange={handleChangeforFacilities} />
                  <label style={{ marginLeft: '10px' }}>Obstetrics</label><br></br>
                  {
                    checkedObstetrics ?
                      <>
                        <label style={{ marginLeft: '10px' }}>Obstetrics Price</label><br></br>
                        <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Obstetrics')} value={checkObstetricsPrice} />
                        <br></br>
                      </>
                      : ""
                  }

                  <input type="checkbox" name="Oncology" value="Oncology" onChange={handleChangeforFacilities} />
                  <label style={{ marginLeft: '10px' }}>Oncology</label><br></br>
                  {
                    checkedOncology ?
                      <>
                        <label style={{ marginLeft: '10px' }}>Oncology Price</label><br></br>
                        <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Oncology')} value={checkOncologyPrice} />
                        <br></br>
                      </>
                      : ""
                  }

                  <input type="checkbox" name="Ophthalmology" value="Ophthalmology" onChange={handleChangeforFacilities} />
                  <label style={{ marginLeft: '10px' }}>Ophthalmology</label><br></br>
                  {
                    checkedOphthalmology ?
                      <>
                        <label style={{ marginLeft: '10px' }}>Ophthalmology Price</label><br></br>
                        <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Ophthalmology')} value={checkOphthalmologyPrice} />
                        <br></br>
                      </>
                      : ""
                  }

                  <input type="checkbox" name="Orthopedics" value="Orthopedics" onChange={handleChangeforFacilities} />
                  <label style={{ marginLeft: '10px' }}>Orthopedics</label><br></br>
                  {
                    checkedOrthopedics ?
                      <>
                        <label style={{ marginLeft: '10px' }}>Orthopedics Price</label><br></br>
                        <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Orthopedics')} value={checkOrthopedicsPrice} />
                        <br></br>
                      </>
                      : ""
                  }

                  <input type="checkbox" name="Renal Unit" value="Renal Unit" onChange={handleChangeforFacilities} />
                  <label style={{ marginLeft: '10px' }}>Renal Unit</label><br></br>
                  {
                    checkedRenalUnit ?
                      <>
                        <label style={{ marginLeft: '10px' }}>Renal Unit Price</label><br></br>
                        <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Renal Unit')} value={checkRenalUnitPrice} />
                        <br></br>
                      </>
                      : ""
                  }

                  <input type="checkbox" name="Sexual Health" value="Sexual Health" onChange={handleChangeforFacilities} />
                  <label style={{ marginLeft: '10px' }}>Sexual Health</label><br></br>
                  {
                    checkedSexualHealth ?
                      <>
                        <label style={{ marginLeft: '10px' }}>Sexual Health Price</label><br></br>
                        <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Sexual Health')} value={checkSexualHealthPrice} />
                        <br></br>
                      </>
                      : ""
                  }

                  <input type="checkbox" name="Urology" value="Urology" onChange={handleChangeforFacilities} />
                  <label style={{ marginLeft: '10px' }}>Urology</label><br></br>
                  {
                    checkedUrology ?
                      <>
                        <label style={{ marginLeft: '10px' }}>Urology Price</label><br></br>
                        <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Urology')} value={checkUrologyPrice} />
                        <br></br>
                      </>
                      : ""
                  }

                  {
                    serviceTypeStateOther.length === 0 ?
                      <Button onClick={() => addServiceTypeField()}>Add Other Services</Button> : ""  
                  }

                  {
                    serviceTypeStateOther.map((input, key) => (
                      <>
                    <br></br>
                        <input placeholder="Service Name" type="text" style={{ color: "#000", borderColor: '#000', borderWidth: 1, fontSize: 10, height: 40 ,width:200}} onChange={(text) => handleServiceTypeChange(text.target.value, key, 'serviceName')} value={input.serviceName} />
                         <br></br><br></br>
                        <input placeholder="Service Price" type="number" style={{ color: "#000", borderColor: '#000', borderWidth: 1, fontSize: 10, height: 40,}} onChange={(text) => handleServiceTypeChange(text.target.value, key, 'servicePrice')} value={input.servicePrice} />
                        <div style={{display:"flex",marginTop:10}}>
                          
                        <Button onClick={() => addServiceTypeField()}><FiPlus/></Button>
                        <Button onClick={() => removeServiceTypeField(key)} style={{marginLeft:35}}><FiMinus/></Button>
                        </div>
                          <br></br><br></br>
                      </>
                    ))}

                  {
                    serviceTypeStateOther.length !== 0 ?
                      <Button variant='warning' onClick={() => mergeClick()}>Add</Button>
                      : ""
                  }


                </Form.Group>
              </Col>
            </Row>

            {/*----------------- Documents Details ---------------------*/}

            <Row>
              <h4 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
                Hospital Document
                <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '23%', marginTop: 10 }}>
                  {' '}
                </div>
              </h4>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group className="position-relative mb-3" controlId="contactPersonName">
                  <Form.Label style={{ fontSize: '12px', fontWeight: 'bold' }}>Upload Hospital Certificate</Form.Label>
                  <Form.Control style={{ fontSize: '12px' }} type="file" name="file" onChange={(event) => { setSelectedHospitalCertificateImage(event.target.files[0]) }} accept="image/*" />
                  {
                    certificateUploadDone ?
                      <h1 style={{ fontSize: '12px', fontFamily: 'DM Sans', color: 'green' }}>Certificate Uploaded Successfully</h1> : ""
                  }
                  {
                    certificateUploadLoading ?
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner> :
                      selectedHopitalCertificateImage === null ?
                        <Button size="sm" style={{ fontFamily: 'DM Sans', fontSize: '10px', marginTop: '10px' }} disabled>Upload</Button> :
                        <Button size="sm" style={{ fontFamily: 'DM Sans', fontSize: '10px', marginTop: '10px' }} onClick={() => handleHospitalCertificateImage()}>Upload</Button>
                  }
                </Form.Group>
              </Col>

              <Col md={6} lg={4} className="mt-5">
                <Form.Group className="position-relative mb-3" controlId="contactPersonName">
                  <Form.Label style={{ fontSize: '12px', fontWeight: 'bold' }}>Upload Hospital License</Form.Label>
                  <Form.Control style={{ fontSize: '12px' }} type="file" name="file" onChange={(event) => { setSelectedHopitalLicenseImage(event.target.files[0]) }} accept="image/*" />
                  {
                    licenseUploadDone ?
                      <h1 style={{ fontSize: '12px', fontFamily: 'DM Sans', color: 'green' }}>License Uploaded Successfully</h1> : ""
                  }
                  {
                    licenseUploadLoading ?
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner> :
                      selectedHopitalLicenseImage === null ?
                        <Button size="sm" style={{ fontFamily: 'DM Sans', fontSize: '10px', marginTop: '10px' }} disabled>Upload</Button> :
                        <Button size="sm" style={{ fontFamily: 'DM Sans', fontSize: '10px', marginTop: '10px' }} onClick={() => handleHospitalLicenseImage()}>Upload</Button>
                  }
                </Form.Group>
              </Col>

              <Col md={6} lg={4} className="mt-5">
                <Form.Group className="position-relative mb-3" controlId="contactPersonName">
                  <Form.Label style={{ fontSize: '12px', fontWeight: 'bold' }}>Upload Hospital GST Certificate</Form.Label>
                  <Form.Control style={{ fontSize: '12px' }} type="file" name="file" onChange={(event) => { setSelectedHopitalGstImage(event.target.files[0]) }} accept="image/*" />
                  {
                    gstCertificateUploadDone ?
                      <h1 style={{ fontSize: '12px', fontFamily: 'DM Sans', color: 'green' }}>GST Certificate Uploaded Successfully</h1> : ""
                  }
                  {
                    gstCertificateUploadLoading ?
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner> :
                      selectedHopitalGstImage === null ?
                        <Button size="sm" style={{ fontFamily: 'DM Sans', fontSize: '10px', marginTop: '10px' }} disabled>Upload</Button> :
                        <Button size="sm" style={{ fontFamily: 'DM Sans', fontSize: '10px', marginTop: '10px' }} onClick={() => handleHospitalGSTCertificateImage()}>Upload</Button>
                  }
                </Form.Group>
              </Col>
            </Row>

            <Col md={6} lg={4} className="mt-5">
              <Form.Group className="position-relative mb-3" controlId="contactPersonName">
                <Form.Label style={{ fontSize: '12px', fontWeight: 'bold' }}>Upload Hospital Pictures</Form.Label>
                <Form.Control style={{ fontSize: '12px' }} type="file" name="file" onChange={multipleImg} multiple accept="image/*" />
                {
                  hospitalImgUploadDone ?
                    <h1 style={{ fontSize: '12px', fontFamily: 'DM Sans', color: 'green' }}>Hopital Images Uploaded Successfully</h1> : ""
                }
                {
                  hospitalUploadLoading ?
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner> :
                    selectedHopitalImage === null ?
                      <Button size="sm" style={{ fontFamily: 'DM Sans', fontSize: '10px', marginTop: '10px' }} disabled>Upload</Button> :
                      <Button size="sm" style={{ fontFamily: 'DM Sans', fontSize: '10px', marginTop: '10px' }} onClick={() => handleHospitalImgSubmit()}>Upload</Button>
                }
              </Form.Group>
            </Col>
            {/* <Col md={6} lg={4} className="mt-5">
              <Form.Group className="position-relative mb-3" controlId="contactPersonName">
                <Button style={{ fontFamily: 'DM Sans', fontSize: '12px', marginTop: '10px' }} onClick={() => handleImgUploadShow()}>Upload Hospital Multiple Images</Button>
              </Form.Group>
            </Col> */}

            {/*----------------- Add Doctors ---------------------*/}
            <h4 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
              Add Doctor
              <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '14%', marginTop: 10 }}>
                {' '}
              </div>
            </h4>
            <Row>
              {formValues3.map((input, key) => (
                <Row>
                  <h6 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
                    Doctor {(counter += 1)}
                    <div style={{ backgroundColor: '#ffb606 ', height: 1, width: '7%', marginTop: 10 }}>
                      {' '}
                    </div>
                  </h6>
                  <Col md={6} lg={4} className="mt-5">
                    <Form.Group controlId="DoctorName">
                      <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Doctor Name</Form.Label>
                      <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="DoctorName" placeholder="Doctor Name" onChange={(e) => handleChange(e.target.value, key, 'doctorName')} value={input.doctorName} />
                    </Form.Group>
                  </Col>

                  <Col md={6} lg={4} className="mt-5">
                    <Form.Group controlId="qualification">
                      <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Doctor Qualification</Form.Label>
                      <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="qualification" onChange={(e) => handleChange(e.target.value, key, 'qualification')} value={input.qualification} placeholder="Doctor Qualification" />
                    </Form.Group>
                  </Col>

                  <Col md={6} lg={4} className="mt-5">
                    <Form.Group controlId="experience">
                      <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Doctor Experience</Form.Label>
                      <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="experience" onChange={(e) => handleChange(e.target.value, key, 'experience')} value={input.experience} placeholder="Doctor Experience (In Year)" />
                    </Form.Group>
                  </Col>

                  <Col md={6} lg={4} className="mt-5">
                    <Form.Group controlId="speciality">
                      <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Doctor Speciality</Form.Label>
                      <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="speciality" onChange={(e) => handleChange(e.target.value, key, 'speciality')} value={input.speciality} placeholder="Doctor Speciality (Seperate by comma)" />
                    </Form.Group>
                  </Col>

                  <Col md={6} lg={4} className="mt-5">
                    <Form.Group controlId="licenseNo">
                      <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Doctor License Number</Form.Label>
                      <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="licenseNo" onChange={(e) => handleChange(e.target.value, key, 'licenceNo')} value={input.licenceNo} placeholder="License No" />
                    </Form.Group>
                  </Col>

                  <Col md={6} lg={4} className="mt-5">
                    <Form.Group controlId="ward">
                      <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Ward Name</Form.Label>
                      <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="ward" onChange={(e) => handleChange(e.target.value, key, 'ward')} value={input.ward} placeholder="Ward" />
                    </Form.Group>
                  </Col>
                </Row>
              ))}
              <Col md={6} lg={4} className="mt-5">
                <Button onClick={() => addFormFields()} className="m-1" style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>
                  <AiFillPlusCircle style={{ fontSize: '20px' }} />
                </Button>
              </Col>
            </Row>
            {
              loading ?
                <Button variant="primary" disabled className="mt-5" style={{ fontFamily: 'DM Sans', fontSize: '12px', marginBottom: '100px' }}>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  <span className="visually-hidden">Loading...</span>
                </Button> :
                <Button type="submit" className="mt-5" style={{ fontFamily: 'DM Sans', fontSize: '12px', marginBottom: '100px' }}>Add Hospital</Button>
            }
          </Form>
        </Container>
      </div>
    </>
  );
}
