/* eslint-disable jsx-a11y/heading-has-content */
import { useState } from 'react';
import { Table, Container, Spinner, Button, Form, Col, Row, Modal, Dropdown } from 'react-bootstrap';
import { FaPen, FaExchangeAlt, FaTrash } from "react-icons/fa";
import { BiReset } from "react-icons/bi";
import { useQuery, useMutation } from '@apollo/client';
import '../../../ComponentCss/AllHospitals.css';
import { GET_ALL_SUB_ADMIN, GET_USER_SETTING } from '../../../graphql/queries';
import {
  EDIT_SUB_ADMIN,
  RESAT_ADMIN_PASSWORD,
  CHANGE_STATUS,
} from '../../../graphql/mutations';

import * as XLSX from 'xlsx';

export default function AllSubadmin() {
  const [validated, setValidated] = useState(false);

  const [test] = useMutation(EDIT_SUB_ADMIN, {
    refetchQueries: [{ GET_ALL_SUB_ADMIN }, 'getSubAdmin'],
  });
  const [resat] = useMutation(RESAT_ADMIN_PASSWORD, {
    refetchQueries: [{ GET_ALL_SUB_ADMIN }, 'getSubAdmin'],
  });
  const [satatus] = useMutation(CHANGE_STATUS, {
    refetchQueries: [{ GET_ALL_SUB_ADMIN }, 'getSubAdmin'],
  });

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [contact, setContact] = useState();
  const [editfirstName, seteditFirstName] = useState();
  const [editlastName, seteditLastName] = useState();
  const [editemail, seteditEmail] = useState();
  const [editcontact, seteditContact] = useState();
  const [selectedData, setSelectedData] = useState();
  const [currentpassword, setCurrentpassword] = useState();
  const [status, setStatus] = useState("Select Status");

  let count = 1;
  const { data, loading } = useQuery(GET_ALL_SUB_ADMIN);
  console.log('line 2o', data);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);
  const handleClose3 = () => setShow3(false);
  const handleEdit = (d) => {
    setSelectedData(d);
    seteditFirstName(d.fName);
    seteditLastName(d.lName);
    seteditEmail(d.email);
    seteditContact(d.contact);
    setCurrentpassword(d.password);
    setStatus(d.status);
    handleShow();
  };

  const handleUpdatePassword = (t) => {
    setSelectedData(t)
    setCurrentpassword(t.password);
    handleShow2();
  };

  const handleChangestatus = (t) => {
    setStatus("select status")
    handleShow3();
  };

  const handleShow = () => setShow(true);
  const handleShow2 = () => setShow2(true);
  const handleShow3 = () => setShow3(true);
  function handleOnExport() {
    const todayDate = new Date();

    const todayDateString = todayDate.toString();
    const finalDate = todayDateString.slice(0, 10);
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(data && data.getSubAdmin);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet');
    XLSX.writeFile(wb, 'Admindetails(' + finalDate + ').xlsx');
  }

  const handleSubmit = (e) => {
    console.log(firstName);

    // if (form.checkValidity() === false) {
    //   setValidated(true);
    //   e.preventDefault();
    //   e.stopPropagation();
    // } else {
    // e.preventDefault();
    test({
      variables: {
        subadminInput: {
          id: `${selectedData.id}`,
          fName: `${editfirstName}`,
          lName: `${editlastName}`,
          email: `${editemail}`,
          contact: `${editcontact}`,
        },
      },

      onCompleted: () => {
        setValidated(false);
        setFirstName();
        setLastName();
        setEmail();
        setContact();
      },
    })
      .then((d) => console.log(d))
      .catch((e) => console.log(e));

    handleClose();
  };

  const handleSubmit2 = () => {
    console.log(currentpassword);
    resat({
      variables: {
        subadminId: `${selectedData.id}`,
        subadminPassword: `${currentpassword}`,
      },

      onCompleted: () => {
        setValidated(false);
        setCurrentpassword();
      },
    })
      .then((d) => console.log(d))
      .catch((e) => console.log(e));

    handleClose2()
  };

  const handleSubmit3 = () => {
    // console.log(currentpassword);
    satatus({
      variables: {
        subadminId: `${selectedData.id}`,
        subadminStatus: `${status}`,
      },

      onCompleted: () => {
        setValidated(false);
        setStatus();
      },
    })
      .then((d) => console.log(d))
      .catch((e) => console.log(e));

    handleClose3()
  };

  return (
    <>
      <Container>
        <h1 className="pb-3" style={{ fontFamily: 'DM Sans' }}>
          All Sub Admin
          <h2 style={{ backgroundColor: '#ffb606 ', height: 2, width: '20%', marginTop: 10 }}></h2>
        </h1>
        {
          loading ?
            <Spinner animation="border" role="status" className="mx-auto d-block">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            :
            <Table bordered style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>
              <thead
                style={{
                  background: '#34495e',
                  color: '#fff',
                  position: 'sticky',
                  top: 0,
                  margin: '0,0,0,0',
                }}
              >
                <tr>
                  <th>S.No.</th>
                  <th>Full Name</th>
                  <th>Contact</th>
                  <th>Email</th>
                  <th>Password</th>
                  <th>Status</th>
                  <th>Edit</th>
                  <th>Reset</th>
                  <th>Status</th>
                  <th>Delete</th>
                  {/* <th>Status</th> */}
                  {/* <th>Update Password</th>
                 <th>Delete Password</th> */}
                </tr>
              </thead>
              <tbody>
                {
                  data && data.getSubAdmin.slice().reverse().map((sData) =>
                    <tr key={sData.id}>
                      <td>{count++}</td>
                      <td>{sData.fName} {sData.lName}</td>
                      <td>{sData.contact}</td>
                      <td> {sData.email}</td>
                      <td>{sData.password}</td>
                      <td>{sData.status}</td>
                      <td>
                        <center>
                          <Button size="sm" variant='primary' onClick={() => handleEdit(sData)}>
                            <FaPen />
                          </Button>
                        </center>
                      </td>
                      <td>
                        <center>
                          <Button size="sm" variant='info' onClick={() => handleUpdatePassword(sData)}>
                            <BiReset style={{ color: '#fff' }} />
                          </Button>
                        </center>
                      </td>
                      <td>
                        <center>
                          <Button size="sm" variant='warning' onClick={() => handleChangestatus(sData)}>
                            <FaExchangeAlt style={{ color: '#fff' }} />
                          </Button>
                        </center>
                      </td>
                      <td>
                        <center>
                          <Button size="sm" variant='danger' onClick={() => handleChangestatus(sData)}>
                            <FaTrash style={{ color: '#fff' }} />
                          </Button>
                        </center>
                      </td>
                    </tr>
                  )}
              </tbody>
            </Table>
        }
      </Container>

      {/* Edit */}
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>EDIT ADMIN</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
              <Col md={6} lg={4}>
                <Form.Group controlId="firstName">
                  <Form.Label style={{ fontSize: 12 }}>First Name</Form.Label>
                  <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} value={editfirstName} onChange={(e) => seteditFirstName(e.target.value)} required />
                </Form.Group>
              </Col>
              <Col md={6} lg={4}>
                <Form.Group controlId="lastName">
                  <Form.Label style={{ fontSize: 12 }}>Last Name</Form.Label>
                  <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} value={editlastName} onChange={(e) => seteditLastName(e.target.value)} required />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} lg={4}>
                <Form.Group controlId="email">
                  <Form.Label style={{ fontSize: 12 }}>Email</Form.Label>
                  <Form.Control type="email" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} value={editemail} onChange={(e) => seteditEmail(e.target.value)} required />
                </Form.Group>
              </Col>
              <Col md={6} lg={4}>
                <Form.Group controlId="contact">
                  <Form.Label style={{ fontSize: 12 }}>Contact</Form.Label>
                  <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="contact" value={editcontact} onChange={(e) => seteditContact(e.target.value)} placeholder="Contact Number" required />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleSubmit()}>
            Edit Admin
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>RESAT PASSWORD</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Row>
              <Col md={6} lg={4} className="mt-1">
                <Form.Group controlId="firstName">
                  <Form.Control
                    type="text"
                    style={{ fontFamily: 'DM Sans', fontSize: '14px' }}
                    name="firstName"
                    value={currentpassword}
                    onChange={(e) => setCurrentpassword(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleSubmit2()}>
            UpdatePassword
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title>CHANGE STATUS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">{status}</Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={(e) => setStatus("Active")}>Active</Dropdown.Item>
              <Dropdown.Item onClick={(e) => setStatus("Pending")}>Pending</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit3}>Update</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
