/* eslint-disable jsx-a11y/heading-has-content */
import { useState } from 'react';
import { Table, Container, Spinner, Button, Modal, Form, Col, Row } from 'react-bootstrap'
import { useQuery } from '@apollo/client';
import "../../../ComponentCss/AllHospitals.css"
import { GET_ALL_AGENTS } from '../../../graphql/queries';
import AgentRow from './AgentRowSub';
import * as XLSX from 'xlsx';


export default function AllAgentsSub() {
    const { data, loading } = useQuery(GET_ALL_AGENTS);

    function handleOnExport() {
        const todayDate = new Date()
        const todayDateString = todayDate.toString()
        const finalDate = todayDateString.slice(0, 10);
        var wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(data && data.getAllAgent);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet");
        XLSX.writeFile(wb, "Agentdetails(" + finalDate + ").xlsx")
    }



    return (
        <>
            <Container>
                <h1 className="pb-3" style={{ fontFamily: 'DM Sans' }}>All Agents
                    <h2 style={{ backgroundColor: '#ffb606 ', height: 2, width: '20%', marginTop: 10 }}></h2>
                </h1>
                <Table bordered style={{ fontFamily: "Dm sans", fontSize: 12 }}>
                    <tbody>
                        <tr>
                            <td><Button size="sm" onClick={() => handleOnExport()}>Download Excel</Button></td>
                        </tr>
                    </tbody>
                </Table>
                {
                    loading ?
                        <Spinner animation="border" role="status" className="mx-auto d-block">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner> :
                        <Table striped bordered hover style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>
                            <thead style={{ background: '#34495e', color: '#fff', position: 'sticky', top: 0, margin: '0,0,0,0' }}>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Name</th>
                                    <th>Unique ID</th>
                                    <th>Password</th>
                                    <th>Contact</th>
                                    <th>View</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                    <th>Password Reset</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.getAllAgent.slice().reverse().map((agent, index) => (
                                    <AgentRow key={agent.id} agent={agent} index={index} />
                                ))}
                                <tr>
                                </tr>
                            </tbody>
                        </Table>
                }
            </Container>
        </>
    )
}