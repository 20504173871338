import SpinnerBs from 'react-bootstrap/Spinner';

function Spinner() {
  return (
    <div className="h-100 w-100 d-flex justify-content-center align-items-center">
      <SpinnerBs animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </SpinnerBs>
    </div>
  );
}
export default Spinner;
