import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react'
import swal from 'sweetalert';
import { FaBan, FaCheck } from "react-icons/fa";
import { Container, Row, Col, Form, Button, Card, Spinner, Table } from 'react-bootstrap'
import { LakeFormation } from 'aws-sdk';
import { MUTATION_DCREASE_SLOT_RADILOGY, MUTATION_INCREASE_SLOT_RADIOLOGY } from '../../../graphql/mutations';
import { QUERY_GET_RADIOLOGY_BY_ID, QUERY_RADIOLOGY_BY_ID } from '../../../graphql/queries';
import Navigation from '../../Navigation';


export default function ViewRadiologySlot() {
    const [validated, setValidated] = useState(false);
    const [slot, setSlot] = useState("")
    const [time, setTime] = useState("")
  
    const RadiologyId = localStorage.getItem("RadiologyId")
  
    const { data: radiologyData, loading: radiologyLoading } = useQuery(QUERY_GET_RADIOLOGY_BY_ID, {
      variables: {
        "radiologyId": `${RadiologyId}`
      },
      pollInterval:1000
      
    })
  
    console.log("radiologyData", radiologyData)
  
    // const [createRadiologySlot] = useMutation(MUTATION_ADD_RADIOLOGY_SLOT, {
    //   refetchQueries: [
    //     QUERY_RADIOLOGY_SLOT_BY_RADIOLOGY_ID,
    //     "getRadiologySlotByRadiologyId"
    //   ]
    // })
  
    // const { data, loading } = useQuery(QUERY_RADIOLOGY_SLOT_BY_RADIOLOGY_ID, {
    //   variables: {
    //     "radiologyId": `${RadiologyId}`
    //   }
    // })
  
    // console.log(data)
  
    // const handleSubmit = (event) => {
    //   const form = event.currentTarget;
    //   if (form.checkValidity() === false) {
    //     event.preventDefault();
    //     event.stopPropagation();
    //   } else {
    //     event.preventDefault();
    //     createRadiologySlot({
    //       variables: {
    //         "radiologySlotInput": {
    //           "radiologyId": `${RadiologyId}`,
    //           "slot": `${slot}`,
    //           "time": `${time}`
    //         }
    //       }
    //     })
    //     setSlot("")
    //     setTime("")
  
    //     swal({ title: 'Successfull!!!', text: 'Create Successfully', icon: 'success' });
    //   }
    //   setValidated(true);
    // };
  
  
    // const [blockRadiologySlot] = useMutation(MUTATION_BLOCK_RADIOLOGY_SLOT, {
    //   refetchQueries: [
    //     QUERY_RADIOLOGY_SLOT_BY_RADIOLOGY_ID,
    //     "getRadiologySlotByRadiologyId"
    //   ]
    // })
  
    // const [activeRadiologySlot] = useMutation(MUTATION_ACTIVE_RADIOLOGY_SLOT, {
    //   refetchQueries: [
    //     QUERY_RADIOLOGY_SLOT_BY_RADIOLOGY_ID,
    //     "getRadiologySlotByRadiologyId"
    //   ]
    // })
  
  
  
  
    // function handleBlock(id) {
    //   blockRadiologySlot({
    //     variables: {
    //       "blockRadiologySlotInput": {
    //         "radiologySlotId": `${id}`,
  
    //       }
    //     }
    //   })
    // }
  
    // function handleActive(id) {
    //   activeRadiologySlot({
    //     variables: {
    //       "activeRadiologySlotInput": {
    //         "radiologySlotId": `${id}`
    //       }
    //     }
    //   })
  
    // }

     const[increaseRadiologySlotCapacity] =useMutation(MUTATION_INCREASE_SLOT_RADIOLOGY,{
      refetchQueries:[
        QUERY_GET_RADIOLOGY_BY_ID,
        "getRadiologyById"
      ]
     })
  
    const[decreaseRadiologySlotCapacity] = useMutation(MUTATION_DCREASE_SLOT_RADILOGY,{
      refetchQueries:[
        QUERY_GET_RADIOLOGY_BY_ID,
        "getRadiologyById"
      ]
    })
  
    function handleIncre(id,capacity){
      increaseRadiologySlotCapacity({
        variables:{
          "radiologyId": `${radiologyData && radiologyData.getRadiologyById.id}`,
          "slotId":`${id}`,
          "capacity":parseInt(capacity)
        }
      })
     
    }
  
    function handleDcre(id,capacity){
      decreaseRadiologySlotCapacity({
         variables:{
          "radiologyId": `${radiologyData && radiologyData.getRadiologyById.id}`,
          "slotId": `${id}`,
          "capacity":parseInt(capacity)
         }
      })
    }
  
  
  
  return (
    <div>
        <Navigation/>
        <br></br> <br></br> <br></br><br></br>
    <Container>
      <Row>
        <Col>
          <h1 style={{ fontFamily: 'DM Sans', textAlign: 'center', fontSize: 20, fontWeight: 'bold', marginTop: 20, marginBottom: 20 }}>Radiology Slot</h1>
          <Table responsive style={{ fontFamily: 'DM Sans', fontSize: 12 }}>
            <tbody>
              {
                radiologyData && radiologyData.getRadiologyById.slotDetails.map(data =>
                  {

                    return(
                      <tr>
                      <td>{data.slotTimeInterval}</td>
                      <td>{data.capacity}</td>
                      <td>{data.status}</td>
                      <td><Button size="sm" variant="success" onClick={()=>handleIncre(data.id,data.capacity)}>+</Button></td>
                      <td><Button size="sm" variant="danger" onClick={()=>handleDcre(data.id,data.capacity)}>-</Button></td>
                    </tr>
                    )
                  }
               
                )
              }
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  </div>
  )
}
