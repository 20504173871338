import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Card, Row, Col, Form, Button, Image, Spinner } from 'react-bootstrap'
import Navigation from '../Navigation'
import swal from 'sweetalert';
import "../BloodBank/Blood.css"
import bg from "../../Images/ambulancebg.png"
import { useMutation } from '@apollo/client';
import { MUTATION_AMBULANCE_LOGIN } from '../../graphql/mutations';

export default function AmbulanceLogin() {

  const [userName, setUserName] = useState()
  const [password, setPassword] = useState()


  const [ambulancelogin, { data, loading }] = useMutation(MUTATION_AMBULANCE_LOGIN)
  console.log("data", data)

  const navigate = useNavigate();

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      ambulancelogin({
        variables: {
          "userName": `${userName}`,
          "password": `${password}`

        }
      })
    }

    setValidated(true);
  };


  if (data) {

    localStorage.setItem("ambulanceToken", data && data.ambulancelogin.ambulanceToken)
    localStorage.setItem("ambulanceId", data && data.ambulancelogin.ambulanceId)
    // swal({ title: 'Successfull!!!', text: 'Login Successfully', icon: 'success' });
    navigate("/AmbulanceDashboard")
  }




  return (
    <>
      <Navigation />
      <br></br>  <br></br>  <br></br><br></br><br></br>
      <Container >
        <Card style={{ borderRadius: "20px" }}>
          <Row>
            <Col className="boxBB" md={6} style={{ borderRadius: "20px", overflow: "hidden" }}>
              <Image src={bg} style={{ width: 350, marginTop: 50 }} className="mx-auto d-block img-fluid" alt="Responsive image" />
              <h6 style={{ fontFamily: "Dm sans", textAlign: "center", fontSize: 20, fontWeight: "bold", }}>Welcome Back</h6>
            </Col>
            <Col md={6} >
              <h3 style={{ fontFamily: "Dm sans", textAlign: "center", marginTop: 70, fontWeight: "bold" }}>Ambulance Login</h3>
              <Form noValidate validated={validated} onSubmit={handleSubmit} style={{ marginTop: 50 }} >
                <Row className="mb-3">
                  <Form.Group as={Col} md="9" controlId="validationCustom01" className="mx-auto d-block">
                    <Form.Control required type="text" placeholder="Enter username" style={{ border: "2px solid #48dbfb", fontFamily: "Dm sans" }} onChange={(e) => setUserName(e.target.value)} />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="9" controlId="validationCustom01" className="mx-auto d-block">
                    <Form.Control required type="password" placeholder="Enter Password" style={{ border: "2px solid #48dbfb", fontFamily: "Dm sans" }} onChange={(e) => setPassword(e.target.value)} />
                  </Form.Group>
                </Row>
                {
                  loading ?
                    <div className='mx-auto d-block'>
                      <Spinner animation="border" className='mx-auto d-block' />
                    </div>
                    :
                    <>
                      <Button type="submit" className="mx-auto d-block boxBB " style={{ border: "none", fontFamily: "Dm sans", marginBottom: 100 }} >Login Now</Button>
                    </>


                }

              </Form>
            </Col>
          </Row>


        </Card>

      </Container>
    </>
  )
}
