import React from 'react'
import { useSubscription, useMutation, gql } from '@apollo/client';
import { MUTATION_CREATE_TEST_USER } from '../graphql/mutations';
import { Button } from 'react-bootstrap'
export default function Subscription() {

    const SUBSCRIPTION_USER = gql`
        subscription Subscription {
            newUserSos {
                id
                userId
                fName
                lName
                email
                contact
                createDateTime
                status
            }
        }`

    const [createTest] = useMutation(MUTATION_CREATE_TEST_USER)

    const { data, loading } = useSubscription(SUBSCRIPTION_USER, {
        onSubscriptionData: (data) => {
            console.log(data.subscriptionData.data)
            alert('SOS Alert!!!')
        }
    });

    console.log("data", data)

    const clickHandel = () => {
        createTest({
            variables: {
                "testUserInput": {
                    "name": "222",
                    "age": "333"
                }
            }
        })
    }

    return (
        <div>
            <Button onClick={() => clickHandel()}>Click</Button>
        </div>
    )
}
