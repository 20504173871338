import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react'
import swal from 'sweetalert';
import { FaBan, FaCheck ,FaEye} from "react-icons/fa";
import { Container, Row, Col, Form, Button, Card, Spinner, Table } from 'react-bootstrap'
import { MUTATION_ACTIVE_PATHOLOGY_SLOT_TIME,   MUTATION_BLOCK_PATHOLOGY_SLOT_TIME, MUTATION_DCREASE_SLOT_PATHOLOGY, MUTATION_DCREASE_SLOT_RADIOLOGY, MUTATION_INCREASE_SLOT_PATHOLOGY } from '../../graphql/mutations';
import { QUERY_PATHOLOGY_SLOT_BY_PATHOLOGY_ID, QUERY_GET_PATHOLOGY_BY_ID } from '../../graphql/queries';
import { useNavigate } from 'react-router-dom';
import "../../ComponentCss/NewDashboard.css"
import { Bar, Pie } from "react-chartjs-2";
import { FaAlignJustify, FaHome, FaUsers, FaUsersCog, FaHeadset, FaKey, FaSignOutAlt ,FaClock,FaInfoCircle} from "react-icons/fa";
import { UserData } from '../SuperAdmin/Data';
import { Chart as ChartJS } from "chart.js/auto";
import { LinkContainer } from 'react-router-bootstrap'
 

export default function AddPathologySlot() {
  const [validated, setValidated] = useState(false);
  const [slot, setSlot] = useState("")
  const [time, setTime] = useState("")

  const PathologyId = localStorage.getItem("PathologyId")


  const { data: pathologyData, loading: pathologyLoading } = useQuery(QUERY_GET_PATHOLOGY_BY_ID, {
    variables: {
      "pathologyId": `${PathologyId}`
    }
  })
  console.log("pathologyData", pathologyData)

 

  const { data, loading } = useQuery(QUERY_PATHOLOGY_SLOT_BY_PATHOLOGY_ID, {
    variables: {
      "pathologyId": `${PathologyId}`
    }
  })

  console.log(data)







  

  
    const[increasePathologySlotCapacity] = useMutation(MUTATION_INCREASE_SLOT_PATHOLOGY,{
      refetchQueries:[
        QUERY_GET_PATHOLOGY_BY_ID,
        "getPathologyById"
      ]
    })

   
    const[decreasePathologySlotCapacity] =useMutation(MUTATION_DCREASE_SLOT_PATHOLOGY,{
      refetchQueries:[
        QUERY_GET_PATHOLOGY_BY_ID,
        "getPathologyById"
      ]
    })
   





  function handleIncrea(id,capacity){
    increasePathologySlotCapacity({
      variables:{
        "slotId": `${id}`,
       "pathologyId": `${ pathologyData && pathologyData.getPathologyById.id}`,
       "capacity": parseInt(capacity)
      }
    })
  }


  function handleDecrea(id,capacity){
    decreasePathologySlotCapacity({
      variables:{
        "slotId": `${id}`,
       "pathologyId": `${ pathologyData && pathologyData.getPathologyById.id}`,
       "capacity": parseInt(capacity)
      }
    })
  }


   const navigate = useNavigate()

 function handleSlot(id){
  navigate("/ViewSlotBooking/"+id)
 }

 function handleMenu() {

  let toggle = document.querySelector(".toggle");
let navigation = document.querySelector(".navigation");
let main = document.querySelector(".main");
  navigation.classList.toggle("active");
  main.classList.toggle("active");
};

  const[activePathologySlotTime] = useMutation(MUTATION_ACTIVE_PATHOLOGY_SLOT_TIME,{
    refetchQueries:[
      QUERY_GET_PATHOLOGY_BY_ID,
      "getPathologyById"
    ]
  })

   const[blockPathologySlotTime] = useMutation(MUTATION_BLOCK_PATHOLOGY_SLOT_TIME,{
    refetchQueries:[
      QUERY_GET_PATHOLOGY_BY_ID,
      "getPathologyById"
    ]
   })

function handleActive(id){
  activePathologySlotTime({
  variables:{
    "slotId": `${id}`,
    "pathologyId": `${pathologyData && pathologyData.getPathologyById.id}`,

  }
  })
}

function handleBlock(id){
  blockPathologySlotTime({
  variables:{
    "slotId": `${id}`,
    "pathologyId": `${pathologyData && pathologyData.getPathologyById.id}`,

  }
  })
}






  return (
    <div >
    <div className="navigation">
        <br></br> 
      
    <span className="title" style={{ fontSize: 20, fontFamily: 'DM Sans', fontWeight: 'bold',color:"#fff",textAlign:"center",marginLeft:20,marginTop:20 }}>Dashboard</span>
        <ul>
            <br></br><br></br><br></br>
           
            <li>
            <LinkContainer to="/">
                <a href="#">
                    <span className="icon">

                    </span>
                    <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }} eventKey="first"><FaHome /> Home</span>
                  
                </a>
                </LinkContainer>
            </li>
            <li>
            <LinkContainer to="/PathologyProfile">
                <a href="#">
                    <span className="icon">
                    </span>
                    <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }}><FaUsers /> Profile</span>
                </a>
                </LinkContainer>
            </li>
            <li>
            <LinkContainer to="/AddPathologySlot">
                <a href="#">
                    <span className="icon"> </span>
                    <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }}><FaClock /> All Slot</span>
                </a>
                </LinkContainer>
            </li>
            <li>
                        <LinkContainer to="/Termcondition">
                            <a href="#">
                                <span className="icon"> </span>
                                <span className="title" style={{ fontSize: 12, fontFamily: 'DM Sans' }}><FaInfoCircle /> Terms & Condition</span>
                            </a>
                            </LinkContainer>
                        </li>
          
           
           
        </ul>
        
    </div>
    

    <div className="main">
        <div className="topbar">
            <div>
                <FaAlignJustify onClick={() => handleMenu()}  className="toggle"/>
            </div>
            <div className="search">
                <label>
                    <input type="text" placeholder="Search here" />
                </label>
            </div>
            <div className="user">
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/2048px-Circle-icons-profile.svg.png" alt="" />
            </div>
        </div>
        <Container>
           <Row>
             <Col>
               <h1 style={{ fontFamily: 'DM Sans', textAlign: 'center', fontSize: 20, fontWeight: 'bold', marginTop:20, marginBottom:20 }}>Pathology Slot</h1>
               <Table responsive style={{ fontFamily: 'DM Sans', fontSize: 12 }}>
                 <tbody>
                   {
                     pathologyData && pathologyData.getPathologyById.slotDetails.map(data =>
                       <tr>
                         <td>{data.slotTimeInterval}</td>
                   
                         <td>{data.status}</td>
                  
                        {
                            data.status ==="active" ?
                            <td><Button size="sm" variant="danger"onClick={()=>handleBlock(data.id)}>Block</Button></td>
                            :
                           <td><Button size="sm" variant="success"onClick={()=>handleActive(data.id)}>Active</Button></td>

                        }
                 
                         


                         <td><Button size="sm" variant="info" onClick={()=>handleSlot(data.id)} ><FaEye color="#fff"/></Button></td>
                       </tr>
                     )
                   }
                 </tbody>
               </Table>
             </Col>
           </Row>
         </Container>
     
       
    </div>
  
</div>


    
    // <div>
    //   <Container>
    //     <Row>
    //       <Col>
    //         <h1 style={{ fontFamily: 'DM Sans', textAlign: 'center', fontSize: 20, fontWeight: 'bold', marginTop:20, marginBottom:20 }}>Pathology Slot</h1>
    //         <Table responsive style={{ fontFamily: 'DM Sans', fontSize: 12 }}>
    //           <tbody>
    //             {
    //               pathologyData && pathologyData.getPathologyById.slotDetails.map(data =>
    //                 <tr>
    //                   <td>{data.slotTimeInterval}</td>
                     
    //                   <td>{data.status}</td>
                  
    //                   <td><Button size="sm" variant="danger"onClick={()=>handleDecrea(data.id)}>Active</Button></td>
    //                   <td><Button size="sm" variant="info" onClick={()=>handleSlot(data.id)} ><FaEye color="#fff"/></Button></td>
    //                 </tr>
    //               )
    //             }
    //           </tbody>
    //         </Table>
    //       </Col>
    //     </Row>
    //   </Container>
    // </div>
  )
}
