import { Form, Row, Col, Button, Container, Modal } from 'react-bootstrap';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_SUBAMBULANCE } from '../../../graphql/mutations';
import { QUERY_GET_PENDING_AMBULANCE } from '../../../graphql/queries';
import Spinner from '../../Spinner';
import swal from 'sweetalert';
import AWS from 'aws-sdk';
import uniqid from 'uniqid';
import { AiFillPlusCircle } from 'react-icons/ai';

const ID = process.env.REACT_APP_AWS_ACCESSS_ID;
const SECRET = process.env.REACT_APP_AWS_ACCESSS_KEY;
const BUCKET_NAME = 'bharp-ambulance';

const s3 = new AWS.S3({
    accessKeyId: ID,
    secretAccessKey: SECRET,
});

const imageKey = []

export default function AddAmbulanceSub() {
    const subAdminId = localStorage.getItem('subAdminId')
    let counter = 0;
    const [validated, setValidated] = useState(false);
    const [addAmbulanceMutation, { loading }] = useMutation(CREATE_SUBAMBULANCE, {
        refetchQueries: [
            QUERY_GET_PENDING_AMBULANCE
        ]
    });

    const [ownerFirstName, setOwnerFirstName] = useState('');
    const [ownerLastName, setOwnerLastName] = useState('');
    const [ownerContact, setOwnerContact] = useState('');
    const [ownerEmail, setOwnerEmail] = useState('');
    const [vehicleNo, setVehicleNo] = useState('');
    const [licenseNo, setLicenseNo] = useState('');
    const [driverFirstName, setDriverFirstName] = useState('');
    const [driverLastName, setDriverLastName] = useState('');
    const [driverContact, setDriverContact] = useState('');
    const [driverEmail, setDriverEmail] = useState('');
    const [emergencyContact, setEmergencyContact] = useState('');
    const [vehicleType, setVehicleType] = useState('');
    const [vehicleTypeOther, setVehicleTypeOther] = useState('');
    const [ownership, setOwnership] = useState('');
    const [ownershipOther, setOwnershipOther] = useState('');
    const [priceKm, setPriceKm] = useState('');
    const [basicPrice, setBasicPrice] = useState('');
    const [bharpdiscount, setBharpDiscount] = useState('');
    const [vendorMargin, setVendorMargin] = useState('');
    const [latitude, setLatitude] = useState("")
    const [longitude, setLongitude] = useState("")
    const [selectedAmbulanceImgImg, setSelectedAmbulanceImgImg] = useState(null);
    const [ambulanceImgImgLoading, setAmbulanceImgImgLoading] = useState(false);
    const [ambulanceImgImgDone, setAmbulanceImgImgDone] = useState(false);
    const [ambulanceImgImgKey, setAmbulanceImgImgKey] = useState('');

    const [selectedDriverImgImg, setSelectedDriverImgImg] = useState(null);
    const [driverImgImgLoading, setDriverImgImgLoading] = useState(false);
    const [driverImgImgDone, setDriverImgImgDone] = useState(false);
    const [driverImgImgKey, setDriverImgImgKey] = useState('');

    const [selectedDrivingLicenseImg, setSelectedDrivingLicenseImg] = useState(null);
    const [drivingLicenseImgLoading, setDrivingLicenseImgLoading] = useState(false);
    const [drivingLicenseImgDone, setDrivingLicenseImgDone] = useState(false);
    const [drivingLicenseImgKey, setDrivingLicenseImgKey] = useState('');
    const [localThreholder, setLocalThreholder] = useState("")
    const [localBasePrice, setLocalBasePrice] = useState("")
    const [localPricePerkm, setLocalPricePerKm] = useState("")
    const [outLocalBasePrice, setOutLocalBasePrice] = useState("")
    const [outLocalPricePerKm, setOutLocalPricePerKm] = useState("")
    const [acAvailable, setAcAvailable] = useState("")
    const [acPrice, setAcPrice] = useState("")
    const [nightService, setNightService] = useState("")
    const [nightServiceStartTime, setNightServiceStartTime] = useState("")
    const [nightServiceEndTime, setNightServiceEndTime] = useState("")
    const [nightServicePrice, setNightServicePrice] = useState("")



    const [facilitiesState, setFacilitiesState] = useState([{ facilityName: '', facilityPrice: '' }]);


    let addFormFields = () => {
        setFacilitiesState([...facilitiesState, { facilityName: '', facilityPrice: '', }]);
    };

    let handleChange = (e, i, nameValue) => {
        let newFormValues = [...facilitiesState];
        newFormValues[i][nameValue] = e;
        setFacilitiesState(newFormValues);
    };


    const [selectImage, setSelectImage] = useState()
    const [imageBox, setImageBox] = useState()
    const [uploadDone, setUploadDone] = useState(false)
    const [uploadLoading, setUploadLoading] = useState(false)
    const [multipleImgKey, setMultipleImgKey] = useState([])

    function multipleImg(e) {
        setSelectImage([...e.target.files])
    }

    console.log(imageKey)


    const handleSubmit = (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            e.preventDefault();
            e.stopPropagation();
        }
        else {
            e.preventDefault();
            addAmbulanceMutation({
                variables: {
                    "ambulanceInput": {
                        "ownerfName": `${ownerFirstName}`,
                        "ownerlName": `${ownerLastName}`,
                        "ownerContact": `${ownerContact}`,
                        "ownerEmail": `${ownerEmail}`,
                        "vehicleNo": `${vehicleNo}`,
                        "licenceNo": `${licenseNo}`,
                        "licenceImg": `${drivingLicenseImgKey}`,
                        "vehicleType": `${vehicleType}`,
                        "vehicleImg": imageKey,
                        "bharpDiscount": `${bharpdiscount}`,
                        "vendorMargin": `${vendorMargin}`,
                        "driverFName": `${driverFirstName}`,
                        "driverlName": `${driverLastName}`,
                        "driverContact": `${driverContact}`,
                        "driverEmail": `${driverEmail}`,
                        "driverImg": `${driverImgImgKey}`,
                        "pricePerKms": `${priceKm}`,
                        "basePrice": `${basicPrice}`,
                        "emengencyContact": `${emergencyContact}`,
                        "ambulanceOwnerShip": `${ownership}`,
                        "longitude": `${longitude}`,
                        "latitude": `${latitude}`,
                        "header": null,
                        "agentId": null,
                        "localThresholderKm": `${localThreholder}`,
                        "localBasePrice": `${localBasePrice}`,
                        "localPriceKm": `${localPricePerkm}`,
                        "outLocalBasePrice": `${outLocalBasePrice}`,
                        "outLocalPerKmPrice": `${outLocalPricePerKm}`,
                        "acAvailable": `${acAvailable}`,
                        "acPerKmPrice": `${acPrice}`,
                        "vehicleTypeOther": `${vehicleTypeOther}`,
                        "ambulanceOwnerShipOther": `${ownershipOther}`,
                        "facility": facilitiesState,
                        "rejectStatus": "false",
                        "rejectComment": "",
                        "subAdminId": `${subAdminId}`
                    }
                },


            });
            setValidated(false);
            swal({ title: 'Successfull!!!', text: 'Ambulance Add Successfully', icon: 'success' });
            setOwnerFirstName('');
            setOwnerLastName('');
            setOwnerContact('');
            setOwnerEmail('');
            setVehicleNo('');
            setLicenseNo('');
            setDriverFirstName('');
            setDriverLastName('');
            setDriverContact('');
            setDriverEmail('');
            setEmergencyContact('');
            setVehicleType('');
            setOwnership('');
            setSelectedAmbulanceImgImg(null)
            setAmbulanceImgImgLoading(false)
            setAmbulanceImgImgDone(false)
            setAmbulanceImgImgKey('')
            setVehicleTypeOther('')
            setOwnershipOther('')

            setSelectedDriverImgImg(null)
            setDriverImgImgLoading(false)
            setDriverImgImgDone(false)
            setDriverImgImgKey('')

            setSelectedDrivingLicenseImg(null)
            setDrivingLicenseImgLoading(false)
            setDrivingLicenseImgDone(false)
            setDrivingLicenseImgKey('')
        }
    };


    const handleAmbulanceImage = (e) => {
        try {
            selectImage.map(async (fileData) => {
                const uniqueId = uniqid();
                var regex = new RegExp('[^.]+$');
                var extension = fileData.name.match(regex);
                var uniqueFileName = "Bharp" + '-' + uniqueId + '-' + uniqueId + '.' + extension[0];
                imageKey.push(uniqueFileName)
                const params = {
                    Bucket: BUCKET_NAME,
                    Key: uniqueFileName,
                    Body: fileData,
                };
                s3.upload(params, function (err, data) {
                    if (err) {
                        throw err;
                    } else {
                        setImageBox(uniqueFileName)
                        swal({
                            title: 'Successfull!!!',
                            text: ' Uploaded Successfully',
                            icon: 'success',
                        });
                        setMultipleImgKey([])
                        setSelectImage('')
                        setUploadLoading(false)
                        setUploadDone(true)
                    }
                });
            })
        } catch (err) {
            swal({
                title: 'Error!!!',
                text: 'Error please try again',
                icon: 'error',
            });
            console.log(err);
            setUploadLoading(false)
        }

    };

    const handleDriverImage = (e) => {
        try {
            setDriverImgImgLoading(true)
            const uniqueId = uniqid();
            var regex = new RegExp('[^.]+$');
            var extension = selectedDriverImgImg.name.match(regex);
            var uniqueFileName = "driverImg" + '-' + uniqueId + '-' + uniqueId + '.' + extension[0];
            const params = {
                Bucket: BUCKET_NAME,
                Key: uniqueFileName,
                Body: selectedDriverImgImg,
            };
            s3.upload(params, function (err, data) {
                if (err) {
                    throw err;
                } else {
                    setDriverImgImgKey(uniqueFileName)
                    swal({
                        title: 'Successfull!!!',
                        text: 'Driver Image Uploaded Successfully',
                        icon: 'success',
                    });
                    setSelectedDriverImgImg(null)
                    setDriverImgImgLoading(false)
                    setDriverImgImgDone(true)
                }
            });
        } catch (err) {
            swal({
                title: 'Error!!!',
                text: 'Error please try again',
                icon: 'error',
            });
            console.log(err);
            setDriverImgImgLoading(false)
        }

    };

    const handleDriverLicenseImage = (e) => {
        try {
            setDrivingLicenseImgLoading(true)
            const uniqueId = uniqid();
            var regex = new RegExp('[^.]+$');
            var extension = selectedDrivingLicenseImg.name.match(regex);
            var uniqueFileName = "driverLicenseImg" + '-' + uniqueId + '-' + uniqueId + '.' + extension[0];
            const params = {
                Bucket: BUCKET_NAME,
                Key: uniqueFileName,
                Body: selectedDrivingLicenseImg,
            };
            s3.upload(params, function (err, data) {
                if (err) {
                    throw err;
                } else {
                    setDrivingLicenseImgKey(uniqueFileName)
                    swal({
                        title: 'Successfull!!!',
                        text: 'Driver License Image Uploaded Successfully',
                        icon: 'success',
                    });
                    setSelectedDrivingLicenseImg(null)
                    setDrivingLicenseImgLoading(false)
                    setDrivingLicenseImgDone(true)
                }
            });
        } catch (err) {
            swal({
                title: 'Error!!!',
                text: 'Error please try again',
                icon: 'error',
            });
            console.log(err);
            setDrivingLicenseImgLoading(false)
        }

    };


    return (
        <>
            <div style={{ marginTop: '30px' }}>
                <Container>
                    <h1 style={{ fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                        Add Ambulance
                    </h1>
                    <hr />
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>

                        {/*----------------- Owner Details ---------------------*/}
                        <Row>
                            <h4 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
                                Owner Details
                                <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '19%', marginTop: 10 }}>
                                    {' '}
                                </div>
                            </h4>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Owner First Name</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setOwnerFirstName(e.target.value)} value={ownerFirstName} placeholder="Owner First Name" />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Owner Last Name</Form.Label>
                                    <Form.Control required as="textarea" rows={1} style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setOwnerLastName(e.target.value)} value={ownerLastName} placeholder="Owner Last Name" />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Owner Contact</Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setOwnerContact(e.target.value)} value={ownerContact} placeholder="Owner Contact" onWheel={(e) => e.target.blur()} />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Owner Email</Form.Label>
                                    <Form.Control required type="email" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setOwnerEmail(e.target.value)} value={ownerEmail} placeholder="Owner Email" />
                                </Form.Group>
                            </Col>
                        </Row>

                        {/*----------------- Driver Details ---------------------*/}

                        <Row>
                            <h4 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
                                Driver Details
                                <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '17%', marginTop: 10 }}>
                                    {' '}
                                </div>
                            </h4>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Driver First Name</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Driver First Name" onChange={(e) => setDriverFirstName(e.target.value)} value={driverFirstName} />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Driver Last Name</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Driver Last Name" onChange={(e) => setDriverLastName(e.target.value)} value={driverLastName} />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Driver Contact</Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDriverContact(e.target.value)} value={driverContact} placeholder="Driver Contact" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Driver Email</Form.Label>
                                    <Form.Control required type="email" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDriverEmail(e.target.value)} value={driverEmail} placeholder="Driver Email" />
                                </Form.Group>
                            </Col>
                        </Row>

                        {/*----------------- Vehicle Details ---------------------*/}

                        <Row>
                            <h4 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
                                Vehicle Details
                                <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '17%', marginTop: 10 }}>
                                    {' '}
                                </div>
                            </h4>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Vehicle No</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Vehicle No" onChange={(e) => setVehicleNo(e.target.value)} value={vehicleNo} />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>License No</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="License No" onChange={(e) => setLicenseNo(e.target.value)} value={licenseNo} />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Emergency Contact No</Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Emergency Contact No" onChange={(e) => setEmergencyContact(e.target.value)} value={emergencyContact} onWheel={(e) => e.target.blur()} />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Select Vehicle Type</Form.Label>
                                    <Form.Select required style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setVehicleType(e.target.value)} value={vehicleType}>
                                        <option value="" selected="selected" disabled="disabled">Select Vehicle Type</option>
                                        <option value="Government">Government</option>
                                        <option value="Private">Private</option>
                                        <option value="vehicleTypeOther">Other</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>



                            {
                                vehicleType === 'vehicleTypeOther' ?
                                    <Col md={6} lg={4} className="mt-5">
                                        <Form.Group>
                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Select Vehicle Other Type</Form.Label>
                                            <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Select Vehicle Other Type" onChange={(e) => setVehicleTypeOther(e.target.value)} value={vehicleTypeOther} onWheel={(e) => e.target.blur()} />
                                        </Form.Group>
                                    </Col> : ''
                            }

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Select Ambulance Ownership</Form.Label>
                                    <Form.Select required style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setOwnership(e.target.value)} value={ownership}>
                                        <option value="" selected="selected" disabled="disabled">Select Ambulance Ownership</option>
                                        <option value="Rented">Rented</option>
                                        <option value="Own">Own</option>
                                        <option value="vehicleOwnershipOther">Other</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            {
                                ownership === 'vehicleOwnershipOther' ?
                                    <Col md={6} lg={4} className="mt-5">
                                        <Form.Group>
                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Select Ambulance Other Ownership</Form.Label>
                                            <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Select Ambulance Other Ownership" onChange={(e) => setOwnershipOther(e.target.value)} value={ownershipOther} onWheel={(e) => e.target.blur()} />
                                        </Form.Group>
                                    </Col> : ''
                            }



                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Bharp Discount</Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Enter Bharp Discount" onChange={(e) => setBharpDiscount(e.target.value)} value={bharpdiscount} onWheel={(e) => e.target.blur()} />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Vendor Margin</Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Enter Vendor Margin" onChange={(e) => setVendorMargin(e.target.value)} value={vendorMargin} onWheel={(e) => e.target.blur()} />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Local Thresholder Km
                                    </Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Enter Local Thresholder Km" onChange={(e) => setLocalThreholder(e.target.value)} value={localThreholder} onWheel={(e) => e.target.blur()} />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Local Base Price</Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Enter Local Base Price" onChange={(e) => setLocalBasePrice(e.target.value)} value={localBasePrice} onWheel={(e) => e.target.blur()} />
                                </Form.Group>
                            </Col>


                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Local  Price Per Km</Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Enter Local Base Price" onChange={(e) => setLocalPricePerKm(e.target.value)} value={localPricePerkm} onWheel={(e) => e.target.blur()} />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Out Local Base Price</Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Enter Local Base Price" onChange={(e) => setOutLocalBasePrice(e.target.value)} value={outLocalBasePrice} onWheel={(e) => e.target.blur()} />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Out Local  Price Per Km</Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Enter Local Base Price" onChange={(e) => setOutLocalPricePerKm(e.target.value)} value={outLocalPricePerKm} onWheel={(e) => e.target.blur()} />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>AC Available</Form.Label>
                                    <Form.Select required style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setAcAvailable(e.target.value)} value={acAvailable}>
                                        <option value="" selected="selected" disabled="disabled">Select AC Available</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>

                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            {

                                acAvailable === "Yes" ?
                                    <Col md={6} lg={4} className="mt-5">
                                        <Form.Group>
                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}> AC Price Per Km</Form.Label>
                                            <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Enter AC Price Per Km Price" onChange={(e) => setAcPrice(e.target.value)} value={acPrice} onWheel={(e) => e.target.blur()} />
                                        </Form.Group>
                                    </Col>
                                    :
                                    <></>
                            }
                            {/* <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Night Service</Form.Label>
                                    <Form.Select required style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setNightService(e.target.value)} value={nightService}>
                                        <option value="" selected="selected" disabled="disabled">Select Night Service</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </Form.Select>
                                </Form.Group>
                                </Col>
                                {
                                       nightService === "Yes" ?
                                       <>
                                       <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}> Night Service Start Time</Form.Label>
                                    <Form.Control required type="time" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Enter Night Service Start Time" onChange={(e) => setNightServiceStartTime(e.target.value)} value={nightServiceStartTime} onWheel={(e) => e.target.blur()} />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}> Night Service End Time</Form.Label>
                                    <Form.Control required type="time" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Enter Night Service End Time" onChange={(e) => setNightServiceEndTime(e.target.value)} value={nightServiceEndTime} onWheel={(e) => e.target.blur()} />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}> Night Service Price</Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Enter Night Service Price" onChange={(e) => setNightServicePrice(e.target.value)} value={nightServicePrice} onWheel={(e) => e.target.blur()} />
                                </Form.Group>
                            </Col>
                                       
                                       </>

                                       :
                                       <>
                                       </>


                                }

                                
                          */}
                        </Row>


                        <Row>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Latitude</Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Enter Latitude" onChange={(e) => setLatitude(e.target.value)} value={latitude} onWheel={(e) => e.target.blur()} />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Longitude</Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Enter Longitude" onChange={(e) => setLongitude(e.target.value)} value={longitude} onWheel={(e) => e.target.blur()} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <h4 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
                                Add Facilitate
                                <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '17%', marginTop: 10 }}>
                                    {' '}
                                </div>
                            </h4>
                            {facilitiesState.map((input, key) => (
                                <Row>
                                    <h6 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
                                        Facilities {(counter += 1)}
                                        <div style={{ backgroundColor: '#ffb606 ', height: 1, width: '7%', marginTop: 10 }}>
                                            {' '}
                                        </div>
                                    </h6>
                                    <Col md={4} lg={4} className="mt-5">
                                        <Form.Group>
                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Facilitate  Name</Form.Label>
                                            <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Facilitate Name" onChange={(e) => handleChange(e.target.value, key, 'facilityName')} value={input.facilityName} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} lg={4} className="mt-5">
                                        <Form.Group>
                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Facilitate Price</Form.Label>
                                            <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Facilitate Price " onChange={(e) => handleChange(e.target.value, key, 'facilityPrice')} value={input.facilityPrice} />
                                        </Form.Group>
                                    </Col>

                                </Row>
                            ))}
                            <Col md={6} lg={4} className="mt-5">
                                <Button onClick={() => addFormFields()} className="m-1" style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>
                                    <AiFillPlusCircle style={{ fontSize: '20px' }} />
                                </Button>
                            </Col>
                        </Row>

                        {/*----------------- Upload Details ---------------------*/}

                        <Row>
                            <h4 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
                                Uploads
                                <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '17%', marginTop: 10 }}>
                                    {' '}
                                </div>
                            </h4>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group className="position-relative mb-3" controlId="contactPersonName">
                                    <Form.Label style={{ fontSize: '12px', fontWeight: 'bold' }}>Upload Ambulance Image</Form.Label>
                                    <Form.Control style={{ fontSize: '12px' }} type="file" name="file" onChange={multipleImg} multiple accept="image/*" />
                                    {
                                        uploadDone ?
                                            <h1 style={{ fontSize: '12px', fontFamily: 'DM Sans', color: 'green', marginTop: '10px' }}>Ambulance Image Uploaded Successfully</h1> : ""
                                    }
                                    {
                                        uploadLoading ?
                                            <Spinner animation="border" role="status" size="small" color="#000" style={{ marginTop: '10px' }}>
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner> :
                                            imageKey === 0 ?
                                                <Button size="sm" style={{ fontFamily: 'DM Sans', fontSize: '10px', marginTop: '10px' }} disabled>Upload</Button> :
                                                <Button size="sm" style={{ fontFamily: 'DM Sans', fontSize: '10px', marginTop: '10px' }} onClick={() => handleAmbulanceImage()}>Upload</Button>
                                    }
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group className="position-relative mb-3" controlId="contactPersonName">
                                    <Form.Label style={{ fontSize: '12px', fontWeight: 'bold' }}>Upload Driver Image</Form.Label>
                                    <Form.Control style={{ fontSize: '12px' }} type="file" name="file" onChange={(event) => { setSelectedDriverImgImg(event.target.files[0]) }} accept="image/*" />
                                    {
                                        driverImgImgDone ?
                                            <h1 style={{ fontSize: '12px', fontFamily: 'DM Sans', color: 'green', marginTop: '10px' }}>Driver Image Uploaded Successfully</h1> : ""
                                    }
                                    {
                                        driverImgImgLoading ?
                                            <Spinner animation="border" role="status" size="small" color="#000" style={{ marginTop: '10px' }}>
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner> :
                                            selectedDriverImgImg === null ?
                                                <Button size="sm" style={{ fontFamily: 'DM Sans', fontSize: '10px', marginTop: '10px' }} disabled>Upload</Button> :
                                                <Button size="sm" style={{ fontFamily: 'DM Sans', fontSize: '10px', marginTop: '10px' }} onClick={() => handleDriverImage()}>Upload</Button>
                                    }
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group className="position-relative mb-3" controlId="contactPersonName">
                                    <Form.Label style={{ fontSize: '12px', fontWeight: 'bold' }}>Upload Driver License Image</Form.Label>
                                    <Form.Control style={{ fontSize: '12px' }} type="file" name="file" onChange={(event) => { setSelectedDrivingLicenseImg(event.target.files[0]) }} accept="image/*" />
                                    {
                                        drivingLicenseImgDone ?
                                            <h1 style={{ fontSize: '12px', fontFamily: 'DM Sans', color: 'green', marginTop: '10px' }}>Driver License Image Uploaded Successfully</h1> : ""
                                    }
                                    {
                                        drivingLicenseImgLoading ?
                                            <Spinner animation="border" role="status" size="small" color="#000" style={{ marginTop: '10px' }}>
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner> :
                                            selectedDrivingLicenseImg === null ?
                                                <Button size="sm" style={{ fontFamily: 'DM Sans', fontSize: '10px', marginTop: '10px' }} disabled>Upload</Button> :
                                                <Button size="sm" style={{ fontFamily: 'DM Sans', fontSize: '10px', marginTop: '10px' }} onClick={() => handleDriverLicenseImage()}>Upload</Button>
                                    }
                                </Form.Group>
                            </Col>
                        </Row>

                        {
                            loading ?
                                <Button variant="primary" disabled className="mt-5" style={{ fontFamily: 'DM Sans', fontSize: '12px', marginBottom: '100px' }}>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    <span className="visually-hidden">Loading...</span>
                                </Button> :
                                <Button type="submit" className="mt-5" style={{ fontFamily: 'DM Sans', fontSize: '12px', marginBottom: '100px' }}>Add Ambulance</Button>
                        }
                    </Form>
                </Container>
            </div>
        </>
    )
}
