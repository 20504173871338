import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Card, Row, Col, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { SIGNIN_SUPER_ADMIN } from '../../graphql/mutations';
import Spinner from '../../Components/Spinner';
import swal from 'sweetalert';
import "../BloodBank/Blood.css"
import Img from "../../Images/smd.jpg"
import Navigation from '../Navigation';

export default function SuperAdminLogin() {

    const navigate = useNavigate();
    const [registrationNumber, setRegistrationNumber] = useState('');
    const [password, setPassword] = useState('');
    const [validated, setValidated] = useState(false);

    const [signinSuperAdmin, { data, loading }] = useMutation(SIGNIN_SUPER_ADMIN, {
        variables: { signinSuperAdminInput: { registrationNumber, password } },
        onCompleted: ({ signinSuperAdmin }) => {
            localStorage.setItem('superAdminToken', signinSuperAdmin.adminToken);
            localStorage.setItem('superAdminId', signinSuperAdmin.adminId);
            navigate('/superAdminDashboard/homeDashboard');
        },
        onError: (e) => {
            swal({ title: "Error!!!", text: "Username & Password Not Match", icon: "error" });
        },
    });
    console.log(data)
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            setValidated(false);
            signinSuperAdmin();
        }
    };

    useEffect(() => {
        if (localStorage.getItem('superAdminToken')) {
            navigate('/superAdminDashboard/homeDashboard');
        }
    }, [navigate]);


    return (
        <>
            <Navigation />
            <Container style={{ marginTop: '150px', background: "#fff", padding: 5, }}>
                <Card style={{ borderRadius: "20px", border: "2px solid #0097e6" }}>
                    <Row>
                        <Col md={6} style={{ borderRadius: 20 }}>
                            <center>
                                <Image src={Img} style={{ width: 350, height: 350, marginLeft: 10 }} clasName="img-fluid" alt="Responsive image" />
                            </center>
                        </Col>
                        <Col md="6" className='mx-auto d-block'>

                            {loading ? (
                                <Spinner />
                            ) : (
                                <>
                                    <br></br>
                                    <h2 className="mb-5" style={{ fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: "center" }}>Super Admin Login</h2>
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '15px' }}>Username</Form.Label>
                                            <Form.Control required style={{ fontFamily: 'DM Sans', fontSize: '15px', }} type="text" placeholder="Username" onChange={(e) => setRegistrationNumber(e.target.value)} value={registrationNumber} />
                                        </Form.Group>
                                        <Form.Group className="mb-4" controlId="formBasicPassword">
                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '15px' }}>Password</Form.Label>
                                            <Form.Control required style={{ fontFamily: 'DM Sans', fontSize: '15px' }} type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} value={password} />
                                        </Form.Group>
                                        <Button type="submit" style={{ fontFamily: 'DM Sans', fontSize: '15px', marginBottom: 50 }}>Login</Button>
                                    </Form>
                                </>
                            )}

                        </Col>
                    </Row>
                </Card>
            </Container>
        </>
    )
}
