import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { useNavigate, Outlet, Link } from 'react-router-dom'
import '../../ComponentCss/SuperAdminDashboard.css'
import { FaHome, FaChevronDown, FaBars, FaVirus, FaExclamationCircle, FaRegUser, FaAmbulance, FaHandHoldingWater, FaUserAlt, FaChartBar, FaClinicMedical, FaExclamationTriangle } from "react-icons/fa";
import { AiFillSetting } from "react-icons/ai";
import { RiAdminFill } from "react-icons/ri";
import "../../ComponentCss/AdminDashboard.css"
import { UserData } from './Data';
import { useSubscription, useMutation, gql, useQuery } from '@apollo/client'
import ringer from '../../Images/ring.mp3'
import { MUTATION_USER_SOS_ACCEPT, MUTATION_USER_SOS_REJECT } from '../../graphql/mutations';
import { MUTATION_UPDATE_ADMIN_PASSWORD } from '../../graphql/mutations';
const SUBSCRIPTION_USER = gql`
        subscription Subscription {
            newUserSos {
                id
                userId
                fName
                lName
                email
                contact
                createDateTime
                status
            }
        }`

export default function SuperAdminDashboard() {

    const navigate = useNavigate();

    const audio = new Audio(ringer);
    audio.loop = true;

    var x = document.getElementById("myAudio");

    function playAudio() {
        x.play();
        x.loop = true
    }

    function pauseAudio() {
        x.pause();
        x.loop = false
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [alertData, setAlertData] = useState()
    console.log("alertData", alertData)
    const { data: subData } = useSubscription(SUBSCRIPTION_USER, {
        onSubscriptionData: (data) => {
            console.log(data.subscriptionData.data)
            setAlertData(data.subscriptionData.data);
            playAudio()
            handleShow()
        }
    });

    console.log("subData", subData)

    const superAdminToken = localStorage.getItem('superAdminToken')

    const [hospitalOpen, setHospitalOpen] = useState(false)
    const [agentOpen, setAgentOpen] = useState(false)
    const [pathologyOpen, setPathologyOpen] = useState(false)
    const [radiologyOpen, setRadiologyOpen] = useState(false)
    const [ambulanceOpen, setAmbulanceOpen] = useState(false)

    useEffect(() => {
        if (!superAdminToken) {
            navigate('/superAdminLogin');
        }
    }, [])

    function handleMenu() {

        let toggle = document.querySelector(".toggle");
        let navigation = document.querySelector(".navigation");
        let main = document.querySelector(".main");
        navigation.classList.toggle("active");
        main.classList.toggle("active");
    };


    const [userData, setUserData] = useState({
        labels: UserData.map((data) => data.year),
        datasets: [
            {
                label: "Users Gained",
                data: UserData.map((data) => data.userGain),
                backgroundColor: [
                    "rgba(75,192,192,1)",
                    "#ecf0f1",
                    "#50AF95",
                    "#f3ba2f",
                    "#2a71d0",
                ],
                borderColor: "black",
                borderWidth: 1,
            },
        ],
    });

    function handleDown() {
        const listItems = document.querySelectorAll(".sidebar-list li");

        listItems.forEach((item) => {
            item.addEventListener("click", () => {
                let isActive = item.classList.contains("active");

                listItems.forEach((el) => {
                    el.classList.remove("active");
                });

                if (isActive) item.classList.remove("active");
                else item.classList.add("active");
            });
        });

    }






    function handleClick() {
        const toggleSidebar = document.querySelector(".toggle-sidebar");
        const logo = document.querySelector(".logo-box");
        const sidebar = document.querySelector(".sidebar");
        toggleSidebar.addEventListener("click", () => {
            sidebar.classList.toggle("close");
        });

        logo.addEventListener("click", () => {
            sidebar.classList.toggle("close");
        });
    }

    function handleAudio() {
        playAudio()
    }

    const [acceptSOS] = useMutation(MUTATION_USER_SOS_ACCEPT)

    const [rejectSOS] = useMutation(MUTATION_USER_SOS_REJECT)

    function handleAccept(id) {
        audio.pause()
        acceptSOS({
            variables: {
                "sosId": `${id}`
            }
        })
        handleClose()
        pauseAudio()

    }


    function handleReject(id) {
        audio.pause()
        rejectSOS({
            variables: {
                "sosId": `${id}`
            }
        })
        handleClose()
        pauseAudio()

    }




    return (
        <>
            <div className="sidebar close" >
                <audio id="myAudio" controls style={{ display: "none" }}>
                    <source src={ringer} type="audio/mpeg" />
                </audio>
                {/*               
                <Button onClick={() => handleAudio()}>Click</Button> */}
                <Link to="/" className="logo-box" style={{ textDecoration: "none" }}>
                    <i className='bx bxl-xing'></i>
                    <div className="logo-name" ><h6> Admin Dashboard</h6></div>
                </Link>

                <ul className="sidebar-list">
                    <li>
                        <div className="title">
                            <Link to="/" className="link" style={{ textDecoration: "none" }}>
                                <i className='bx bx-grid-alt'><FaHome /></i>
                                <span className="name">Home</span>
                            </Link>
                        </div>
                        <div className="submenu">
                            <Link to="/" className="link submenu-title" style={{ textDecoration: "none" }}>Home</Link>
                        </div>
                    </li>
                    <li>
                        <div className="title">
                            <Link to="/superAdminDashboard/homeDashboard" className="link" style={{ textDecoration: "none" }}>
                                <i className='bx bx-grid-alt'><FaChartBar /></i>
                                <span className="name">Dashboard</span>
                            </Link>
                        </div>
                        <div className="submenu">
                            <Link to="/superAdminDashboard/homeDashboard" className="link submenu-title" style={{ textDecoration: "none" }}>Dashboard</Link>
                        </div>
                    </li>
                    <li>
                        <div className="title">
                            <Link to="/superAdminDashboard/User" className="link" style={{ textDecoration: "none" }}>
                                <i className='bx bx-grid-alt'><FaUserAlt /></i>
                                <span className="name">All Users</span>
                            </Link>
                        </div>
                        <div className="submenu">
                            <Link to="/superAdminDashboard/User" className="link submenu-title" style={{ textDecoration: "none" }}>All Users</Link>
                        </div>
                    </li>

                    <li>
                        {/* <div className="title">
                            <Link to="/superAdminDashboard/addHospital" className="link" style={{ textDecoration: "none" }}>
                                <i className='bx bx-grid-alt'><FaClinicMedical /></i>
                                <span className="name">Add Hospital</span>
                            </Link>
                        </div> */}
                        <div className="submenu">
                            <Link to="/superAdminDashboard/addHospital" className="link submenu-title" style={{ textDecoration: "none" }}>Add Hospital</Link>
                        </div>
                    </li>

                    <li className="dropdown">
                        <div className="title">
                            <Link to="/superAdminDashboard/addAgent" className="link" style={{ textDecoration: "none" }}>
                                <i className='bx bx-book-alt'><FaRegUser /></i>
                                <span className="name">Agent</span>
                            </Link>
                            <i className='bx bxs-chevron-down' onClick={() => handleDown()}><FaChevronDown /></i>
                        </div>
                        <div className="submenu">
                            <Link to="/superAdminDashboard/addAgent" className="link" style={{ textDecoration: "none" }}>Add Agent</Link>
                            <Link to="/superAdminDashboard/allAgent" className="link" style={{ textDecoration: "none" }}>All Agents</Link>

                        </div>
                    </li>

                    {/* <li className="dropdown">
                        <div className="title">
                            <Link to="/superAdminDashboard/addPathology" className="link" style={{ textDecoration: "none" }}>
                                <i className='bx bx-book-alt'><FaVirus /></i>
                                <span className="name">Pathology</span>
                            </Link>
                            <i className='bx bxs-chevron-down' onClick={() => handleDown()}><FaChevronDown /></i>
                        </div>
                        <div className="submenu">
                            <Link to="/superAdminDashboard/addPathology" className="link" style={{ textDecoration: "none" }}>Add Pathology</Link>
                            <Link to="/superAdminDashboard/pathologyRequest" className="link" style={{ textDecoration: "none" }}>Pathology Request</Link>
                            <Link to="/superAdminDashboard/activePathology" className="link" style={{ textDecoration: "none" }}>Pathology Active</Link>
                            <Link to="/superAdminDashboard/rejectPathology" className="link" style={{ textDecoration: "none" }}>Pathology Reject</Link>
                        </div>
                    </li> */}



                    {/* <li className="dropdown">
                        <div className="title">
                            <Link to="/superAdminDashboard/addRadiology" className="link" style={{ textDecoration: "none" }}>
                                <i className='bx bx-book-alt'><FaXRay /></i>
                                <span className="name">Radiology</span>
                            </Link>
                            <i className='bx bxs-chevron-down' onClick={() => handleDown()}><FaChevronDown /></i>
                        </div>
                        <div className="submenu">
                            <Link to="/superAdminDashboard/addRadiology" className="link" style={{ textDecoration: "none" }}>Add Radiology</Link>
                            <Link to="/superAdminDashboard/radiologyRequest" className="link" style={{ textDecoration: "none" }}>Radiology Request</Link>
                            <Link to="/superAdminDashboard/activeRadiology" className="link" style={{ textDecoration: "none" }}>Radiology Active</Link>
                            <Link to="/superAdminDashboard/rejectRadiology" className="link" style={{ textDecoration: "none" }}>Radiology Reject</Link>
                        </div>
                    </li> */}

                    <li className="dropdown">
                        <div className="title">
                            <Link to="/superAdminDashboard/addAmbulance" className="link" style={{ textDecoration: "none" }}>
                                <i className='bx bx-book-alt'><FaAmbulance /></i>
                                <span className="name">Ambulance</span>
                            </Link>
                            <i className='bx bxs-chevron-down' onClick={() => handleDown()}><FaChevronDown /></i>
                        </div>
                        <div className="submenu">
                            <Link to="/superAdminDashboard/ambulanceBooking" className="link" style={{ textDecoration: "none" }}>Bookings</Link>
                            <Link to="/superAdminDashboard/addAmbulance" className="link" style={{ textDecoration: "none" }}>Add Ambulance</Link>
                            <Link to="/superAdminDashboard/ambulanceRequest" className="link" style={{ textDecoration: "none" }}>Ambulance Request</Link>
                            <Link to="/superAdminDashboard/activeAmbulance" className="link" style={{ textDecoration: "none" }}>Ambulance Active</Link>
                            <Link to="/superAdminDashboard/rejectAmbulance" className="link" style={{ textDecoration: "none" }}>Ambulance Reject</Link>
                        </div>
                    </li>

                    <li className="dropdown">
                        <div className="title">
                            <Link to="/superAdminDashboard/addBloodBank" className="link" style={{ textDecoration: "none" }}>
                                <i className='bx bx-book-alt'><FaHandHoldingWater /></i>
                                <span className="name">Blood Bank</span>
                            </Link>
                            <i className='bx bxs-chevron-down' onClick={() => handleDown()}><FaChevronDown /></i>
                        </div>
                        <div className="submenu">
                            <Link to="/superAdminDashboard/addBloodBank" className="link" style={{ textDecoration: "none" }}>Add Blood Bank</Link>
                            <Link to="/superAdminDashboard/bloodBankRequest" className="link" style={{ textDecoration: "none" }}>Blood Bank Request</Link>
                            <Link to="/superAdminDashboard/activeBloodBank" className="link" style={{ textDecoration: "none" }}>Blood Bank Active</Link>
                            <Link to="/superAdminDashboard/rejectBloodBank" className="link" style={{ textDecoration: "none" }}>Blood Bank Reject</Link>
                        </div>
                    </li>
                    <li>
                        <div className="title">
                            <Link to="/superAdminDashboard/sosDetatilPage" className="link" style={{ textDecoration: "none" }}>
                                <i className='bx bx-grid-alt'><FaExclamationTriangle /></i>
                                <span className="name">SOS</span>
                            </Link>
                        </div>
                        <div className="submenu">
                            <Link to="/superAdminDashboard/sosDetatilPage" className="link submenu-title" style={{ textDecoration: "none" }}>SOS</Link>
                        </div>
                    </li>

                    <li className="dropdown">
                        <div className="title">
                            <Link to="/superAdminDashboard/AddSubadmin" className="link" style={{ textDecoration: "none" }}>
                                <i className='bx bx-book-alt'><RiAdminFill /></i>
                                <span className="name">Subadmin</span>
                            </Link>
                            <i className='bx bxs-chevron-down' onClick={() => handleDown()}><FaChevronDown /></i>
                        </div>
                        <div className="submenu">
                            <Link to="/superAdminDashboard/AddSubadmin" className="link" style={{ textDecoration: "none" }}>Add Sub Admin</Link>
                            <Link to="/superAdminDashboard/AllSubadmin" className="link" style={{ textDecoration: "none" }}>All Sub Admin</Link>

                        </div>
                    </li>

                    <li>
                        <div className="title">
                            <Link to="/superAdminDashboard/satingDetatilpage" className="link" style={{ textDecoration: "none" }}>
                                <i className='bx bx-grid-alt'><AiFillSetting /></i>
                                <span className="name">Settings</span>
                            </Link>
                        </div>
                        <div className="submenu">
                            <Link to="/superAdminDashboard/satingDetatilpage" className="link submenu-title" style={{ textDecoration: "none" }}>Settings</Link>
                        </div>
                    </li>
                </ul>
            </div>
            <section className="home">
                <div className="toggle-sidebar">
                    <FaBars className='bx bx-menu' onClick={() => handleClick()} style={{ width: 30, height: 30, marginLeft: 10 }} />
                </div>
                <Container style={{ padding: '20px' }} fluid>
                    <Row>
                        <Col md={12}><Outlet /></Col>
                    </Row>
                </Container>
            </section>

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
                <Modal.Body>
                    <FaExclamationCircle className='mx-auto d-block' size={50} style={{ color: '#e74c3c' }} />
                    <p style={{ fontWeight: 'bold', textAlign: 'center' }}>Name: <span style={{ fontWeight: 'normal' }}>{alertData && alertData.newUserSos.fName} {alertData && alertData.newUserSos.lName}</span></p>
                    <p style={{ fontWeight: 'bold', textAlign: 'center' }}>Contact: <span style={{ fontWeight: 'normal' }}>{alertData && alertData.newUserSos.contact}</span></p>
                    <p style={{ fontWeight: 'bold', textAlign: 'center' }}>Email: <span style={{ fontWeight: 'normal' }}>{alertData && alertData.newUserSos.email}</span></p>

                    <Row>
                        <Col>
                            <Button variant='success' className='mx-auto d-block' onClick={() => handleAccept(alertData && alertData.newUserSos.id)}>Accept</Button>
                        </Col>
                        <Col>
                            <Button variant='danger' className='mx-auto d-block' onClick={() => handleReject(alertData && alertData.newUserSos.id)}>Reject</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

