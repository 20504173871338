import '../../../ComponentCss/AllHospitals.css';
import { useState } from 'react';
import EditAgent from './EditAgent';
import { DELETE_AGENT } from '../../../graphql/mutations';
import { GET_ALL_AGENTS } from '../../../graphql/queries';
import { useMutation } from '@apollo/client';
import { FaEye, FaPen, FaTrash } from "react-icons/fa";
import { MdOutlineResetTv } from "react-icons/md";
import ViewAgent from './ViewAgent';
import { Table, Container, Spinner, Button, Modal, Form, Col, Row } from 'react-bootstrap'
import { MUTATION_EDIT_AGENT_PASSWORD } from '../../../graphql/mutations'


function HospitalRow({ agent, index }) {
  const [deleteshow, setDeleteShow] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [viewshow, setViewShow] = useState(false);
  const handleViewClose = () => setViewShow(false);
  const handleViewShow = () => setViewShow(true);

  const deletehandleClose = () => {
    setDeleteShow(false);
  };
  const deletehandleShow = () => setDeleteShow(true);
  const [deleteAgent] = useMutation(DELETE_AGENT, {
    variables: { agentId: agent.id },
    refetchQueries: [{ query: GET_ALL_AGENTS }, 'GetAllAgent'],
  });

  const [resetAgentPassword] = useMutation(MUTATION_EDIT_AGENT_PASSWORD, {
    refetchQueries: [{ query: GET_ALL_AGENTS }, 'GetAllAgent'],
  });


  const [validated, setValidated] = useState(false);
  const [agentId, setAgentId] = useState(false);
  const [agentPassword, setAgentPassword] = useState('')

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  function openResetHandel(aId) {
    setAgentId(aId)
    handleShow1()
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    }
    else {
      event.preventDefault();
      setValidated(false);
      resetAgentPassword({
        variables: {
          "agentId": `${agentId}`,
          "agentPassword": `${agentPassword}`
        }
      }).then(() => {
        handleClose1()
      })
    }

  };

  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td>{agent.firstName + ' ' + agent.lastName}</td>
        <td>{agent.uniqueId}</td>
        <td>{agent.password}</td>
        <td>{agent.contact}</td>
        <td>
          <Button size="sm" onClick={handleViewShow}><FaEye style={{ marginTop: '-3px' }} /></Button>
        </td>
        <td>
          <Button size="sm" variant='info' onClick={handleShow}><FaPen style={{ marginTop: '-4px', color: '#fff' }} /></Button>
        </td>
        <td>
          <Button size="sm" variant='danger' onClick={deletehandleShow}><FaTrash style={{ marginTop: '-4px' }} /></Button>
        </td>
        <td>
          <Button size="sm" variant='warning' onClick={() => openResetHandel(agent.id)}><MdOutlineResetTv style={{ marginTop: '-4px', color: '#fff' }} /></Button>
        </td>
      </tr>
      {/*  */}
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Agent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditAgent agent={agent} />
        </Modal.Body>
      </Modal>

      {/*  */}
      <Modal show={deleteshow} onHide={deletehandleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>Delete Agent?</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 15, textAlign: 'center' }}>
          <p>Are you sure to delete this agend?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => { deleteAgent(); deletehandleClose(); }} className="mx-auto d-block">Yes</Button>
          <Button variant="primary" onClick={deletehandleClose} className="mx-auto d-block">No</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={viewshow} onHide={handleViewClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Agent: <small>{agent.firstName}</small></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ViewAgent agent={agent} />
        </Modal.Body>
      </Modal>


      <Modal show={show1} onHide={handleClose1} centered>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label style={{ fontSize: 15, fontWeight: 'bold' }}>New Password</Form.Label>
                <Form.Control required type="text" placeholder="Enter New Password" onChange={(e) => setAgentPassword(e.target.value)} value={agentPassword} style={{ fontSize: 12 }} />
              </Form.Group>
            </Row>
            <Button type="submit" className='mx-auto d-block'>Reset</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default HospitalRow;