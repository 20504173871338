import { useQuery } from '@apollo/client'
import React from 'react'
import { Container,Row,Col,Image } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { QUERY_GET_RADIOLOGY_BOOKING_BY_ID } from '../../graphql/queries'
import "../BloodBank/Blood.css"
import Navigation from '../Navigation'
import Moment from 'react-moment';
import { FaUser,FaPhoneAlt,FaEnvelope,FaHospital } from "react-icons/fa";

export default function RadiologyDetailBooking() {


    const { id } = useParams();
    
    const{data} = useQuery(QUERY_GET_RADIOLOGY_BOOKING_BY_ID,{
        variables:{
            "radiologyId": `${id}` 
        }
    })

    console.log(data)

  return (
    <div style={{height:"100%",background:"#3498db"}} >
    <Navigation/>
    <br></br> <br></br> <br></br><br></br><br></br>
       <Container>
        <Row>
            <Col md={3}></Col>
        <Col  style={{background:"#fff"}} md={6}>
           <h5  style={{textAlign:"center",marginTop:10,fontFamily:"Dm sans",fontWeight:"bold"}}>Booking Detail</h5>
           <hr></hr>
           
           <h6 style={{fontFamily:"Dm sans",fontSize:13,color:"#000"}}><FaUser  style={{marginTop:-5,color:"#2980b9"}}/> {data && data.getRadiologyBookingById.userName}</h6>
           <h6 style={{fontFamily:"Dm sans",fontSize:13,color:"#000"}}><FaPhoneAlt  style={{marginTop:-5,color:"#2980b9"}}/> {data && data.getRadiologyBookingById.userContact}</h6>
           <h6 style={{fontFamily:"Dm sans",fontSize:13,color:"#000"}}><FaEnvelope  style={{marginTop:-5,color:"#2980b9"}}/> {data && data.getRadiologyBookingById.userEmail}</h6>

           <hr></hr>
           <h6 style={{fontFamily:"Dm sans",fontSize:13,color:"#000"}}>Radiology Name : {data && data.getRadiologyBookingById.readologyName}</h6>
           <h6 style={{fontFamily:"Dm sans",fontSize:13,color:"#000"}}>Service Name : {data && data.getRadiologyBookingById.serviceName}</h6>
           <h6 style={{fontFamily:"Dm sans",fontSize:13,color:"#000"}}>Appointment Date : <Moment format="DD/MM/YYYY"><p>{data && data.getRadiologyBookingById.appointmentDate}</p></Moment> </h6>
           <div style={{display:"flex"}}>
           <h6 style={{fontFamily:"Dm sans",fontSize:13,color:"#000"}}>Start Time : {data && data.getRadiologyBookingById.startTime}</h6>
           <h6 style={{fontFamily:"Dm sans",fontSize:13,marginLeft:10,color:"#000"}}>End Time : {data && data.getRadiologyBookingById.endTime}</h6>
           </div>
           <h6 style={{fontFamily:"Dm sans",fontSize:13,color:"#000"}}>Booking Date :  <Moment format="DD/MM/YYYY ,HH:MM"><p>{data && data.getRadiologyBookingById.createDateTime}</p></Moment></h6>
             
             <div style={{border:"2px solid #000 ",borderStyle:"dashed",marginBottom:20,marginTop:20}}>
               <h6 style={{textAlign:"center",marginTop:10}}>Payment Status</h6>
               {
                data && data.getRadiologyBookingById.paymentType === "online Pay" ?
                <Image src="https://funtura.in/lko/wp-content/themes/funtura/assets/images/success.svg" className='mx-auto d-block' style={{width:100}}/>
                :
                <>
                      <Image src="https://img.icons8.com/bubbles/2x/cash-in-hand.png" className='mx-auto d-block' style={{width:100}}/>
                </>

               }
              
               <br></br>
                <Row>
                    <Col md={6}>
                    <h6 style={{fontFamily:"Dm sans",fontSize:13,marginLeft:10,color:"#000"}}>Payment Type : {data && data.getRadiologyBookingById.paymentType}</h6>
                    </Col>
                    <Col md={6}>
                    <h6 style={{fontFamily:"Dm sans",fontSize:13,marginLeft:10,color:"#000"}}>Price : {data && data.getRadiologyBookingById.servicePrice} /-</h6>
                    </Col>
                </Row>
             
              



             </div>


        </Col>
        <Col md={3}></Col>
        </Row>
       </Container>
    </div>
  )
}
