import { Form, Row, Col, Button, Container, Modal } from 'react-bootstrap';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_BLOODBANK } from '../../../graphql/mutations';
import { QUERY_GET_PENDING_BLOODBANK } from '../../../graphql/queries';
import Spinner from '../../Spinner';
import { AiFillPlusCircle } from 'react-icons/ai';
import swal from 'sweetalert';
import AWS from 'aws-sdk';
import { v4 } from 'uuid';
import axios from 'axios';
import uniqid from 'uniqid';

const ID = process.env.REACT_APP_AWS_ACCESSS_ID;
const SECRET = process.env.REACT_APP_AWS_ACCESSS_KEY;
const BUCKET_NAME = 'bharp-hospital';

const s3 = new AWS.S3({
    accessKeyId: ID,
    secretAccessKey: SECRET,
});

let stateArray = ['Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jammu and Kashmir', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttarakhand', 'Uttar Pradesh', 'West Bengal', 'Andaman and Nicobar Islands', 'Chandigarh', 'Dadra and Nagar Haveli', 'Daman and Diu', 'Delhi', 'Lakshadweep', 'Puducherry'];
const imageKey = []

const andhraPradeshDistrictArray = ["Ananthapur", "Chittoor", "Cuddapah", "East Godavari", "Guntur", "Krishna", "Kurnool", "Nellore", "Prakasam", "Srikakulam", "Visakhapatnam", "Vizianagaram", "West Godavari"];
const andamanAndNicobarIslandsDistrictArray = ["Nicobar", "North And Middle Andaman", "South Andaman"];
const arunachalPradeshDistrictArray = ["Changlang", "Dibang Valley", "East Kameng", "East Siang", "Kurung Kumey", "Lohit", "Lower Dibang Valley", "Lower Subansiri", "Papum Pare", "Tawang", "Tirap", "Upper Siang", "Upper Subansiri", "West Kameng", "West Siang"];
const assamDistrictArray = ["Barpeta", "Bongaigaon", "Cachar", "Darrang", "Dhemaji", "Dhubri", "Dibrugarh", "Goalpara", "Golaghat", "Hailakandi", "Jorhat", "Kamrup", "Karbi Anglong", "Karimganj", "Kokrajhar", "Lakhimpur", "Marigaon", "Nagaon", "Nalbari", "North Cachar Hills", "Sibsagar", "Sonitpur", "Tinsukia"];
const biharDistrictArray = ["Araria", "Arwal", "Aurangabad(BH)", "Banka", "Begusarai", "Bhagalpur", "Bhojpur", "Buxar", "Darbhanga", "East Champaran", "Gaya", "Gopalganj", "Jamui", "Jehanabad", "Kaimur (Bhabua)", "Katihar", "Khagaria", "Kishanganj", "Lakhisarai", "Madhepura", "Madhubani", "Munger", "Muzaffarpur", "Nalanda", "Nawada", "Patna", "Purnia", "Rohtas", "Saharsa", "Samastipur", "Saran", "Sheikhpura", "Sheohar", "Sitamarhi", "Siwan", "Supaul", "Vaishali", "West Champaran"];
const chandigarhDistrictArray = ["Chandigarh"];
const chhattisgarhDistrictArray = ["Bastar", "Bijapur(CGH)", " Bilaspur(CGH)", "Dantewada", "Dhamtari", "Durg", "Gariaband", "Janjgir-champa", "Jashpur", "Kanker", "Kawardha", "Korba", "Koriya", "Mahasamund", "Narayanpur", "Raigarh", "Raipur", "Rajnandgaon", "Surguja"];
const dadarAndNagarHaveliDistrictArray = ["Dadra & Nagar Haveli"];
const damanAndDiuDistrictArray = ["Daman", "Diu"];
const delhiDistrictArray = ["Central Delhi", "East Delhi", "New Delhi", "North Delhi", "North East Delhi", "North West Delhi", " South Delhi", "South West Delhi", "West Delhi"];
const LakshadweepDistrictArray = ["North Goa", "South Goa"];
const puducherryDistrictArray = ["Karaikal", "Mahe", "Pondicherry"];
const goaDistrictArray = ["North Goa", "South Goa"];
const gujaratDistrictArray = ["Ahmedabad", "Amreli", "Anand", "Banaskantha", "Bharuch", "Bhavnagar", "Dahod", "Gandhi Nagar", "Jamnagar", "Junagadh", "Kachchh", "Kheda", "Mahesana", "Narmada", "Navsari", "Panch Mahals", "Patan", "Porbandar", "Rajkot", "Sabarkantha", "Surat", "Surendra Nagar", "Tapi", "The Dangs", "Vadodara", "Valsad"];
const jammuAndKashmirDistrictArray = ["Ananthnag", "Bandipur", "Baramulla", "Budgam", "Doda", "Jammu", "Kargil", "Kathua", "Kulgam", "Kupwara", "Leh", "Poonch", "Pulwama", "Rajauri", "Reasi", "Shopian", "Srinagar", "Udhampur"];
const himachalPradeshDistrictArray = ["Bilaspur(HP)", "Chamba", "Hamirpur(HP)", "Kangra", "Kinnaur", "Kullu", "Lahul & Spiti", "Mandi", "Shimla", "Sirmaur", "Solan", "Una"];
const jharkhandDistrictArray = ["Bokaro", "Chatra", "Deoghar", "Dhanbad", "Dumka", "East Singhbhum", "Garhwa", "Giridh", "Godda", "Gumla", "Hazaribag", "Jamtara", "Khunti", "Koderma", "Latehar", "Lohardaga", "Pakur", "Palamau", "Ramgarh", "Ranchi", "Sahibganj", "Seraikela - kharsawan", "Simdega", "West Singhbhum"];
const karnatakaDistrictArray = ["Bagalkot", "Bangalore", "Rural", "Belgaum", "Bellary", "Bidar", "Bijapur(KAR)", "Chamrajnagar", "Chickmagalur", "Chikkaballapur", "Chitradurga", "Dakshina Kannada", "Davangere", "Dharwad", "Gadag", "Gulbarga", "Hassan", "Haveri", "Kodagu", "Kolar", "Koppal", "Mandya", "Mysore", "Raichur", "Ramanagar", "Shimoga", "Tumkur", "Udupi", "Uttara Kannada", "Yadgir"];
const keralaDistrictArray = ["Alappuzha", "Ernakulam", "Idukki", "Kannur", "Kasargod", "Kollam", "Kottayam", "Kozhikode", "Malappuram", "Palakkad", "Pathanamthitta", "Thiruvananthapuram", "Thrissur", "Wayanad"];
const madhyaPradeshDistrictArray = ["Alirajpur", "Anuppur", "Ashok Nagar", "Balaghat", "Barwani", "Betul", "Bhind", "Bhopal", "Burhanpur", "Chhatarpur", "Damoh", "Datia", "Dewas", "Dhar", "Dindori", "East Nimar", "Guna", "Gwalior", "Harda", "Hoshangabad", "Indore", "Jabalpur", "Jhabua", "Katni", "Khandwa", "Khargone", "Mandla", "Mandsaur", "Morena", "Narsinghpur", "Neemuch", "Panna", "Raisen", "Rajgarh", "Ratlam", "Rewa", "Sagar", "Satna", "Sehore", "Seoni", "Shahdol", "Shajapur", "Sheopur", "Shivpuri", "Sidhi", "Singrauli", "Tikamgarh", "Ujjain", "Umaria", "Vidisha", "West Nimar"];
const maharashtraDistrictArray = ["Ahmed Nagar", "Akola", "Amravati", "Aurangabad", "Beed", "Bhandara", "Buldhana", "Chandrapur", "Dhule", "Gadchiroli", "Gondia", "Hingoli", "Jalgaon", "Jalna", "Kolhapur", "Latur", "Mumbai", "Nagpur", "Nanded", "Nandurbar", "Nashik", "Osmanabad", "Parbhani", "Pune", "Raigarh(MH)", "Ratnagiri", "Sangli", "Satara", "Sindhudurg", "Solapur", "Thane", "Wardha", "Washim", "Yavatmal"];
const manipurDistrictArray = ["Bishnupur", "Chandel", "Churachandpur", " Imphal East", "Imphal West", "Senapati", "Tamenglong", "Thoubal", "Ukhrul"];
const meghalayaDistrictArray = ["East Garo Hills", "East Khasi Hills", "Jaintia Hills", "Ri Bhoi", "South Garo Hills", "West Garo Hills", "West Khasi Hills"];
const mizoramDistrictArray = ["Aizawl", "Champhai", "Kolasib", "Lawngtlai", "Lunglei", "Mammit", "Saiha", "Serchhip"];
const nagalandDistrictArray = ["Dimapur", "Kiphire", "Kohima", "Longleng", "Mokokchung", "Mon", "Peren", "Phek", "Tuensang", "Wokha", "Zunhebotto"];
const odishaDistrictArray = ["Angul", "Balangir", "Baleswar", "Bargarh", "Bhadrak", "Boudh", "Cuttack", "Debagarh", "Dhenkanal", "Gajapati", "Ganjam", "Jagatsinghapur", "Jajapur", "Jharsuguda", "Kalahandi", "Kandhamal", "Kendrapara", "Kendujhar", "Khorda", "Koraput", "Malkangiri", "Mayurbhanj", "Nabarangapur", "Nayagarh", "Nuapada", "Puri", "Rayagada", "Sambalpur", "Sonapur", "Sundergarh"];
const punjabDistrictArray = ["Amritsar", "Barnala", "Bathinda", "Faridkot", "Fatehgarh Sahib", "Fazilka", "Firozpur", "Gurdaspur", "Hoshiarpur", "Jalandhar", "Kapurthala", "Ludhiana", "Mansa", "Moga", "Mohali", "Muktsar", "Nawanshahr", "Pathankot", "Patiala", "Ropar", "Rupnagar", "Sangrur", "Tarn Taran"];
const rajasthanDistrictArray = ["Ajmer", "Alwar", "Banswara", "Baran", "Barmer", "Bharatpur", "Bhilwara", "Bikaner", "Bundi", "Chittorgarh", "Churu", "Dausa", "Dholpur", "Dungarpur", "Ganganagar", "Hanumangarh", "Jaipur", "Jaisalmer", "Jalor", "Jhalawar", "Jhujhunu", "Jodhpur", "Karauli", "Kota", "Nagaur", "Pali", "Rajsamand", "Sikar", "Sawai Madhopur", "Sirohi", "Tonk", "Udaipur"];
const sikkimDistrictArray = ["East Sikkim", "North Sikkim", "South Sikkim", "West Sikkim"];
const tamilNaduDistrictArray = ["Ariyalur", "Chennai", "Coimbatore", "Cuddalore", "Dharmapuri", "Dindigul", "Erode", "Kanchipuram", "Kanyakumari", "Karur", "Krishnagiri", "Madurai", "Nagapattinam", "Namakkal", "Nilgiris", "Perambalur", "Pudukkottai", "Ramanathapuram", "Salem", "Sivaganga", "Thanjavur", "Theni", "Tiruchirappalli", "Tirunelveli", "Tiruvallur", "Tiruvannamalai", "Tiruvarur", "Tuticorin", "Vellore", "Villupuram", "Virudhunagar"];
const telanganaDistrictArray = ["Adilabad", "Hyderabad", "K.V.Rangareddy", "Karim Nagar", "Khammam", "Mahabub Nagar", "Medak", "Nalgonda", "Nizamabad", "Warangal"];
const tripuraDistrictArray = ["Dhalai", "North Tripura", "South Tripura", "West Tripura"];
const uttarPradeshDistrictArray = ["Agra", "Aligarh", "Allahabad", "Ambedkar Nagar", "Auraiya", "Azamgarh", "Bagpat", "Bahraich", "Ballia", "Balrampur", "Banda", "Barabanki", "Bareilly", "Basti", "Bijnor", "Budaun", "Bulandshahr", "Chandauli", "Chitrakoot", "Deoria", "Etah", "Etawah", "Faizabad", "Farrukhabad", "Fatehpur", "Firozabad", "Gautam Buddha Nagar", "Ghaziabad", "Ghazipur", "Gonda", "Gorakhpur", "Hamirpur", "Hardoi", "Hathras", "Jalaun", "Jaunpur", "Jhansi", "Jyotiba Phule Nagar", "Kannauj", "Kanpur Dehat", "Kanpur Nagar", "Kaushambi", "Kheri", "Kushinagar", "Lalitpur", "Lucknow", "Maharajganj", "Mahoba", "Mainpuri", "Mathura", "Mau", "Meerut", "Mirzapur", "Moradabad", "Muzaffarnagar", "Pilibhit", "Pratapgarh", "Raebareli", "Rampur", "Saharanpur", "Sant Kabir Nagar", "Sant Ravidas Nagar", "Shahjahanpur", "Shrawasti", "Siddharthnagar", "Sitapur", "Sonbhadra", "Sultanpur", "Unnao", "Varanasi"];
const uttarakhandDistrictArray = ["Almora", "Bageshwar", "Chamoli", "Champawat", "Dehradun", "Haridwar", "Nainital", "Pauri Garhwal", "Pithoragarh", "Rudraprayag", "Tehri Garhwal", "Udham Singh Nagar", "Uttarkashi"];
const westBengalDistrictArray = ["Bankura", "Bardhaman", "Birbhum", "Cooch Behar", "Darjiling", "East Midnapore", "Hooghly", "Howrah", "Jalpaiguri", "Kolkata", "Malda", "Medinipur", "Murshidabad", "Nadia", "North 24 Parganas", "North Dinajpur", "Puruliya", "South 24 Parganas", "South Dinajpur", "West Midnapore",];

export default function AddBloodBank() {

    const [validated, setValidated] = useState(false);
    const [addBloodBankMutation, { loading }] = useMutation(CREATE_BLOODBANK, {
        refetchQueries: [
            QUERY_GET_PENDING_BLOODBANK
        ]
    });

    const [centerName, setCenterName] = useState('');
    const [centerAddress, setCenterAddress] = useState('');
    const [state, setState] = useState('');
    const [district, setDistrict] = useState('');
    const [pincode, setPincode] = useState('');
    const [contact, setContact] = useState('');
    const [email, setEmail] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [city, setCity] = useState('');
    const [nodalPersonFname, setNodalPersonFname] = useState('');
    const [nodalPersonLname, setNodalPersonLname] = useState('');
    const [nodalPersonContact, setNodalPersonContact] = useState('');
    const [nodalPersonEmail, setNodalPersonEmail] = useState('');
    const [emergencyContact, setEmergencyContact] = useState('');
    const [bloodBankType, setBloodBankType] = useState('');
    const [bloodBankTypeOther, setBloodBankTypeOther] = useState('');
    const [bloodBankOwnerShip, setBloodBankOwnerShip] = useState('');
    const [bloodBankOwnerShipOther, setBloodBankOwnerShipOther] = useState('');
    const [registrationNo, setRegistrationNo] = useState('');

    const [selectedBloodBankImg, setSelectedBloodBankImg] = useState(null);
    const [bloodBankImgLoading, setBloodBankImgLoading] = useState(false);
    const [bloodBankImgDone, setBloodBankImgDone] = useState(false);
    const [bloodBankImgKey, setBloodBankImgKey] = useState('');

    const [aPosQ, setAPosQ] = useState('');
    const [aPosA, setAPosA] = useState('');

    const [aNegQ, setANegQ] = useState('');
    const [aNegA, setANegA] = useState('');

    const [bPosQ, setBPosQ] = useState('');
    const [bPosA, setBPosA] = useState('');

    const [bNegQ, setBNegQ] = useState('');
    const [bNegA, setBNegA] = useState('');

    const [oPosQ, setOPosQ] = useState('');
    const [oPosA, setOPosA] = useState('');

    const [oNegQ, setONegQ] = useState('');
    const [oNegA, setONegA] = useState('');

    const [abPosQ, setAbPosQ] = useState('');
    const [abPosA, setAbPosA] = useState('');

    const [abNegQ, setAbNegQ] = useState('');
    const [abNegA, setAbNegA] = useState('');

    const handleSubmit = (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            e.preventDefault();
            e.stopPropagation();
        }
        else {
            e.preventDefault();
            addBloodBankMutation({
                variables: {
                    "bloodBankInput": {
                        "centerName": `${centerName}`,
                        "centerContact": `${contact}`,
                        "centerEmail": `${email}`,
                        "address": `${centerAddress}`,
                        "city": `${city}`,
                        "district": `${district}`,
                        "state": `${state}`,
                        "pincode": `${pincode}`,
                        "picture": `${bloodBankImgKey}`,
                        "nodalPersonFName": `${nodalPersonFname}`,
                        "nodalPersonLName": `${nodalPersonLname}`,
                        "nodalPersonContact": `${nodalPersonContact}`,
                        "nodalPersonEamil": `${nodalPersonEmail}`,
                        "emergencyContact": `${emergencyContact}`,
                        "longitude": `${latitude}`,
                        "latitude": `${longitude}`,
                        "bloodBankType": `${bloodBankType}`,
                        "bloodBankOwnerShip": `${bloodBankOwnerShip}`,
                        "bloodBankAPos": [{ "quantity": `${aPosQ}`, "available": `${aPosA}` }],
                        "bloodBankANeg": [{ "quantity": `${aNegQ}`, "available": `${aNegA}` }],
                        "bloodBankBPos": [{ "quantity": `${bPosQ}`, "available": `${bPosA}` }],
                        "bloodBankBNeg": [{ "quantity": `${bNegQ}`, "available": `${bNegA}` }],
                        "bloodBankOPos": [{ "quantity": `${oPosQ}`, "available": `${oPosA}` }],
                        "bloodBankONeg": [{ "quantity": `${oNegQ}`, "available": `${oNegA}` }],
                        "bloodBankABPos": [{ "quantity": `${abPosQ}`, "available": `${abPosA}` }],
                        "bloodBankABNeg": [{ "quantity": `${abNegQ}`, "available": `${abNegA}` }],
                        "agentId": null,
                        "bloodBankTypeOther": `${bloodBankTypeOther}`,
                        "bloodBankOwnerShipOther": `${bloodBankOwnerShipOther}`,
                        "registrationNo": `${registrationNo}`,
                        "rejectStatus": "false",
                        "rejectComment": "",
                    }
                },

            });
            setValidated(false);
            swal({ title: 'Successfull!!!', text: 'Blood Bank Add Successfully', icon: 'success' });
            setCenterName('');
            setCenterAddress('');
            setState('');
            setDistrict('');
            setPincode('');
            setContact('');
            setEmail('');
            setLatitude('');
            setLongitude('');
            setCity('');
            setNodalPersonFname('');
            setNodalPersonLname('');
            setNodalPersonContact('');
            setNodalPersonEmail('');
            setEmergencyContact('');
            setBloodBankType('');
            setBloodBankTypeOther('');
            setBloodBankOwnerShip('');
            setBloodBankOwnerShipOther('');
            setSelectedBloodBankImg(null);
            setBloodBankImgLoading(false);
            setBloodBankImgDone(false);
            setBloodBankImgKey('');

            setAPosQ('');
            setAPosA('');
            setANegQ('');
            setANegA('');
            setBPosQ('');
            setBPosA('');
            setBNegQ('');
            setBNegA('');
            setOPosQ('');
            setOPosA('');
            setONegQ('');
            setONegA('');
            setAbPosQ('');
            setAbPosA('');
            setAbNegQ('');
            setAbNegA('');
        }
    };



    const handleBloodBankImage = (e) => {
        try {
            setBloodBankImgLoading(true)
            const uniqueId = uniqid();
            var regex = new RegExp('[^.]+$');
            var extension = selectedBloodBankImg.name.match(regex);
            var uniqueFileName = "bloodBankImg" + '-' + uniqueId + '-' + uniqueId + '.' + extension[0];
            const params = {
                Bucket: BUCKET_NAME,
                Key: uniqueFileName,
                Body: selectedBloodBankImg,
            };
            s3.upload(params, function (err, data) {
                if (err) {
                    throw err;
                } else {
                    setBloodBankImgKey(uniqueFileName)
                    swal({
                        title: 'Successfull!!!',
                        text: 'Blood Bank Image Uploaded Successfully',
                        icon: 'success',
                    });
                    setSelectedBloodBankImg(null)
                    setBloodBankImgLoading(false)
                    setBloodBankImgDone(true)
                }
            });
        } catch (err) {
            swal({
                title: 'Error!!!',
                text: 'Error please try again',
                icon: 'error',
            });
            console.log(err);
            setBloodBankImgLoading(false)
        }

    };

    return (
        <>
            <div style={{ marginTop: '30px' }}>
                <Container>
                    <h1 style={{ fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                        Add Blood Bank
                    </h1>
                    <hr />
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>

                        {/*----------------- Blood Bank Details ---------------------*/}
                        <Row>
                            <h4 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
                                Center Details
                                <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '19%', marginTop: 10 }}>
                                    {' '}
                                </div>
                            </h4>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Center Name</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setCenterName(e.target.value)} value={centerName} placeholder="Center Name" />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Center Address</Form.Label>
                                    <Form.Control required as="textarea" rows={1} style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setCenterAddress(e.target.value)} value={centerAddress} placeholder="Center Address" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Contact</Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setContact(e.target.value)} value={contact} placeholder="Contact" onWheel={(e) => e.target.blur()} />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Email</Form.Label>
                                    <Form.Control required type="email" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setEmail(e.target.value)} value={email} placeholder="Email" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>City</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setCity(e.target.value)} value={city} placeholder="City" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>State</Form.Label>
                                    <Form.Select required style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setState(e.target.value)} value={state}>
                                        <option value="" selected="selected" disabled="disabled">Select State</option>
                                        {stateArray.map((d) => (
                                            <option value={d}>{d}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            {
                                state === '' ?
                                    <Col md={6} lg={4} className="mt-5">
                                        <Form.Group controlId="hospitalState">
                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                <option value="" selected="selected" disabled="disabled">Select State To Display District</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col> :
                                    state === 'Andhra Pradesh' ?
                                        <Col md={6} lg={4} className="mt-5">
                                            <Form.Group controlId="hospitalState">
                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                    {andhraPradeshDistrictArray.map((d) => (
                                                        <option value={d}>{d}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col> :
                                        state === 'Andaman and Nicobar Islands' ?
                                            <Col md={6} lg={4} className="mt-5">
                                                <Form.Group controlId="hospitalState">
                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                        {andamanAndNicobarIslandsDistrictArray.map((d) => (
                                                            <option value={d}>{d}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col> :
                                            state === 'Arunachal Pradesh' ?
                                                <Col md={6} lg={4} className="mt-5">
                                                    <Form.Group controlId="hospitalState">
                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                            {arunachalPradeshDistrictArray.map((d) => (
                                                                <option value={d}>{d}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col> :
                                                state === 'Assam' ?
                                                    <Col md={6} lg={4} className="mt-5">
                                                        <Form.Group controlId="hospitalState">
                                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                {assamDistrictArray.map((d) => (
                                                                    <option value={d}>{d}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col> :
                                                    state === 'Bihar' ?
                                                        <Col md={6} lg={4} className="mt-5">
                                                            <Form.Group controlId="hospitalState">
                                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                    {biharDistrictArray.map((d) => (
                                                                        <option value={d}>{d}</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Col> :
                                                        state === 'Chandigarh' ?
                                                            <Col md={6} lg={4} className="mt-5">
                                                                <Form.Group controlId="hospitalState">
                                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                        {chandigarhDistrictArray.map((d) => (
                                                                            <option value={d}>{d}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </Form.Group>
                                                            </Col> :
                                                            state === 'Chhattisgarh' ?
                                                                <Col md={6} lg={4} className="mt-5">
                                                                    <Form.Group controlId="hospitalState">
                                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                            {chhattisgarhDistrictArray.map((d) => (
                                                                                <option value={d}>{d}</option>
                                                                            ))}
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                </Col> :
                                                                state === 'Dadar and Nagar Haveli' ?
                                                                    <Col md={6} lg={4} className="mt-5">
                                                                        <Form.Group controlId="hospitalState">
                                                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                {dadarAndNagarHaveliDistrictArray.map((d) => (
                                                                                    <option value={d}>{d}</option>
                                                                                ))}
                                                                            </Form.Select>
                                                                        </Form.Group>
                                                                    </Col> :
                                                                    state === 'Daman and Diu' ?
                                                                        <Col md={6} lg={4} className="mt-5">
                                                                            <Form.Group controlId="hospitalState">
                                                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                    {damanAndDiuDistrictArray.map((d) => (
                                                                                        <option value={d}>{d}</option>
                                                                                    ))}
                                                                                </Form.Select>
                                                                            </Form.Group>
                                                                        </Col> :
                                                                        state === 'Delhi' ?
                                                                            <Col md={6} lg={4} className="mt-5">
                                                                                <Form.Group controlId="hospitalState">
                                                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                        {delhiDistrictArray.map((d) => (
                                                                                            <option value={d}>{d}</option>
                                                                                        ))}
                                                                                    </Form.Select>
                                                                                </Form.Group>
                                                                            </Col> :
                                                                            state === 'Lakshadweep' ?
                                                                                <Col md={6} lg={4} className="mt-5">
                                                                                    <Form.Group controlId="hospitalState">
                                                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                            {LakshadweepDistrictArray.map((d) => (
                                                                                                <option value={d}>{d}</option>
                                                                                            ))}
                                                                                        </Form.Select>
                                                                                    </Form.Group>
                                                                                </Col> :
                                                                                state === 'Puducherry' ?
                                                                                    <Col md={6} lg={4} className="mt-5">
                                                                                        <Form.Group controlId="hospitalState">
                                                                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                {puducherryDistrictArray.map((d) => (
                                                                                                    <option value={d}>{d}</option>
                                                                                                ))}
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col> :
                                                                                    state === 'Goa' ?
                                                                                        <Col md={6} lg={4} className="mt-5">
                                                                                            <Form.Group controlId="hospitalState">
                                                                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                    {goaDistrictArray.map((d) => (
                                                                                                        <option value={d}>{d}</option>
                                                                                                    ))}
                                                                                                </Form.Select>
                                                                                            </Form.Group>
                                                                                        </Col> :
                                                                                        state === 'Gujarat' ?
                                                                                            <Col md={6} lg={4} className="mt-5">
                                                                                                <Form.Group controlId="hospitalState">
                                                                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                        {gujaratDistrictArray.map((d) => (
                                                                                                            <option value={d}>{d}</option>
                                                                                                        ))}
                                                                                                    </Form.Select>
                                                                                                </Form.Group>
                                                                                            </Col> :
                                                                                            state === 'Jammu and Kashmir' ?
                                                                                                <Col md={6} lg={4} className="mt-5">
                                                                                                    <Form.Group controlId="hospitalState">
                                                                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                            {jammuAndKashmirDistrictArray.map((d) => (
                                                                                                                <option value={d}>{d}</option>
                                                                                                            ))}
                                                                                                        </Form.Select>
                                                                                                    </Form.Group>
                                                                                                </Col> :
                                                                                                state === 'Himachal Pradesh' ?
                                                                                                    <Col md={6} lg={4} className="mt-5">
                                                                                                        <Form.Group controlId="hospitalState">
                                                                                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                {himachalPradeshDistrictArray.map((d) => (
                                                                                                                    <option value={d}>{d}</option>
                                                                                                                ))}
                                                                                                            </Form.Select>
                                                                                                        </Form.Group>
                                                                                                    </Col> :
                                                                                                    state === 'Jharkhand' ?
                                                                                                        <Col md={6} lg={4} className="mt-5">
                                                                                                            <Form.Group controlId="hospitalState">
                                                                                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                    {jharkhandDistrictArray.map((d) => (
                                                                                                                        <option value={d}>{d}</option>
                                                                                                                    ))}
                                                                                                                </Form.Select>
                                                                                                            </Form.Group>
                                                                                                        </Col> :
                                                                                                        state === 'Karnataka' ?
                                                                                                            <Col md={6} lg={4} className="mt-5">
                                                                                                                <Form.Group controlId="hospitalState">
                                                                                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                        {karnatakaDistrictArray.map((d) => (
                                                                                                                            <option value={d}>{d}</option>
                                                                                                                        ))}
                                                                                                                    </Form.Select>
                                                                                                                </Form.Group>
                                                                                                            </Col> :
                                                                                                            state === 'Kerala' ?
                                                                                                                <Col md={6} lg={4} className="mt-5">
                                                                                                                    <Form.Group controlId="hospitalState">
                                                                                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                            {keralaDistrictArray.map((d) => (
                                                                                                                                <option value={d}>{d}</option>
                                                                                                                            ))}
                                                                                                                        </Form.Select>
                                                                                                                    </Form.Group>
                                                                                                                </Col> :
                                                                                                                state === 'Madhya Pradesh' ?
                                                                                                                    <Col md={6} lg={4} className="mt-5">
                                                                                                                        <Form.Group controlId="hospitalState">
                                                                                                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                {madhyaPradeshDistrictArray.map((d) => (
                                                                                                                                    <option value={d}>{d}</option>
                                                                                                                                ))}
                                                                                                                            </Form.Select>
                                                                                                                        </Form.Group>
                                                                                                                    </Col> :
                                                                                                                    state === 'Maharashtra' ?
                                                                                                                        <Col md={6} lg={4} className="mt-5">
                                                                                                                            <Form.Group controlId="hospitalState">
                                                                                                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                    {maharashtraDistrictArray.map((d) => (
                                                                                                                                        <option value={d}>{d}</option>
                                                                                                                                    ))}
                                                                                                                                </Form.Select>
                                                                                                                            </Form.Group>
                                                                                                                        </Col> :
                                                                                                                        state === 'Manipur' ?
                                                                                                                            <Col md={6} lg={4} className="mt-5">
                                                                                                                                <Form.Group controlId="hospitalState">
                                                                                                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                        {manipurDistrictArray.map((d) => (
                                                                                                                                            <option value={d}>{d}</option>
                                                                                                                                        ))}
                                                                                                                                    </Form.Select>
                                                                                                                                </Form.Group>
                                                                                                                            </Col> :
                                                                                                                            state === 'Meghalaya' ?
                                                                                                                                <Col md={6} lg={4} className="mt-5">
                                                                                                                                    <Form.Group controlId="hospitalState">
                                                                                                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                            {meghalayaDistrictArray.map((d) => (
                                                                                                                                                <option value={d}>{d}</option>
                                                                                                                                            ))}
                                                                                                                                        </Form.Select>
                                                                                                                                    </Form.Group>
                                                                                                                                </Col> :
                                                                                                                                state === 'Mizoram' ?
                                                                                                                                    <Col md={6} lg={4} className="mt-5">
                                                                                                                                        <Form.Group controlId="hospitalState">
                                                                                                                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                {mizoramDistrictArray.map((d) => (
                                                                                                                                                    <option value={d}>{d}</option>
                                                                                                                                                ))}
                                                                                                                                            </Form.Select>
                                                                                                                                        </Form.Group>
                                                                                                                                    </Col> :
                                                                                                                                    state === 'Nagaland' ?
                                                                                                                                        <Col md={6} lg={4} className="mt-5">
                                                                                                                                            <Form.Group controlId="hospitalState">
                                                                                                                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                    {nagalandDistrictArray.map((d) => (
                                                                                                                                                        <option value={d}>{d}</option>
                                                                                                                                                    ))}
                                                                                                                                                </Form.Select>
                                                                                                                                            </Form.Group>
                                                                                                                                        </Col> :
                                                                                                                                        state === 'Odisha' ?
                                                                                                                                            <Col md={6} lg={4} className="mt-5">
                                                                                                                                                <Form.Group controlId="hospitalState">
                                                                                                                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                        {odishaDistrictArray.map((d) => (
                                                                                                                                                            <option value={d}>{d}</option>
                                                                                                                                                        ))}
                                                                                                                                                    </Form.Select>
                                                                                                                                                </Form.Group>
                                                                                                                                            </Col> :
                                                                                                                                            state === 'Punjab' ?
                                                                                                                                                <Col md={6} lg={4} className="mt-5">
                                                                                                                                                    <Form.Group controlId="hospitalState">
                                                                                                                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                            {punjabDistrictArray.map((d) => (
                                                                                                                                                                <option value={d}>{d}</option>
                                                                                                                                                            ))}
                                                                                                                                                        </Form.Select>
                                                                                                                                                    </Form.Group>
                                                                                                                                                </Col> :
                                                                                                                                                state === 'Rajasthan' ?
                                                                                                                                                    <Col md={6} lg={4} className="mt-5">
                                                                                                                                                        <Form.Group controlId="hospitalState">
                                                                                                                                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                                <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                                {rajasthanDistrictArray.map((d) => (
                                                                                                                                                                    <option value={d}>{d}</option>
                                                                                                                                                                ))}
                                                                                                                                                            </Form.Select>
                                                                                                                                                        </Form.Group>
                                                                                                                                                    </Col> :
                                                                                                                                                    state === 'Sikkim' ?
                                                                                                                                                        <Col md={6} lg={4} className="mt-5">
                                                                                                                                                            <Form.Group controlId="hospitalState">
                                                                                                                                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                                    {sikkimDistrictArray.map((d) => (
                                                                                                                                                                        <option value={d}>{d}</option>
                                                                                                                                                                    ))}
                                                                                                                                                                </Form.Select>
                                                                                                                                                            </Form.Group>
                                                                                                                                                        </Col> :
                                                                                                                                                        state === 'Tamil Nadu' ?
                                                                                                                                                            <Col md={6} lg={4} className="mt-5">
                                                                                                                                                                <Form.Group controlId="hospitalState">
                                                                                                                                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                                        {tamilNaduDistrictArray.map((d) => (
                                                                                                                                                                            <option value={d}>{d}</option>
                                                                                                                                                                        ))}
                                                                                                                                                                    </Form.Select>
                                                                                                                                                                </Form.Group>
                                                                                                                                                            </Col> :
                                                                                                                                                            state === 'Telangana' ?
                                                                                                                                                                <Col md={6} lg={4} className="mt-5">
                                                                                                                                                                    <Form.Group controlId="hospitalState">
                                                                                                                                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                                            {telanganaDistrictArray.map((d) => (
                                                                                                                                                                                <option value={d}>{d}</option>
                                                                                                                                                                            ))}
                                                                                                                                                                        </Form.Select>
                                                                                                                                                                    </Form.Group>
                                                                                                                                                                </Col> :
                                                                                                                                                                state === 'Tripura' ?
                                                                                                                                                                    <Col md={6} lg={4} className="mt-5">
                                                                                                                                                                        <Form.Group controlId="hospitalState">
                                                                                                                                                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                                                <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                                                {tripuraDistrictArray.map((d) => (
                                                                                                                                                                                    <option value={d}>{d}</option>
                                                                                                                                                                                ))}
                                                                                                                                                                            </Form.Select>
                                                                                                                                                                        </Form.Group>
                                                                                                                                                                    </Col> :
                                                                                                                                                                    state === 'Uttar Pradesh' ?
                                                                                                                                                                        <Col md={6} lg={4} className="mt-5">
                                                                                                                                                                            <Form.Group controlId="hospitalState">
                                                                                                                                                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                                                    {uttarPradeshDistrictArray.map((d) => (
                                                                                                                                                                                        <option value={d}>{d}</option>
                                                                                                                                                                                    ))}
                                                                                                                                                                                </Form.Select>
                                                                                                                                                                            </Form.Group>
                                                                                                                                                                        </Col> :
                                                                                                                                                                        state === 'Uttarakhand' ?
                                                                                                                                                                            <Col md={6} lg={4} className="mt-5">
                                                                                                                                                                                <Form.Group controlId="hospitalState">
                                                                                                                                                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                                                        {uttarakhandDistrictArray.map((d) => (
                                                                                                                                                                                            <option value={d}>{d}</option>
                                                                                                                                                                                        ))}
                                                                                                                                                                                    </Form.Select>
                                                                                                                                                                                </Form.Group>
                                                                                                                                                                            </Col> :
                                                                                                                                                                            state === 'West Bengal' ?
                                                                                                                                                                                <Col md={6} lg={4} className="mt-5">
                                                                                                                                                                                    <Form.Group controlId="hospitalState">
                                                                                                                                                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                                                            {westBengalDistrictArray.map((d) => (
                                                                                                                                                                                                <option value={d}>{d}</option>
                                                                                                                                                                                            ))}
                                                                                                                                                                                        </Form.Select>
                                                                                                                                                                                    </Form.Group>
                                                                                                                                                                                </Col> : ""
                            }



                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Pincode</Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setPincode(e.target.value)} value={pincode} placeholder="Pin Code" onWheel={(e) => e.target.blur()} />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Latitude</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setLatitude(e.target.value)} value={latitude} placeholder="Latitude" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Longitude</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setLongitude(e.target.value)} value={longitude} placeholder="Longitude" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Select Blood Bank Type</Form.Label>
                                    <Form.Select required style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setBloodBankType(e.target.value)} value={bloodBankType}>
                                        <option value="" selected="selected" disabled="disabled">Select Blood Bank Type</option>
                                        <option value="Government">Government</option>
                                        <option value="Private">Private</option>
                                        <option value="bloodBankTypeOther">Other</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            {
                                bloodBankType === 'bloodBankTypeOther' ?
                                    <Col md={6} lg={4} className="mt-5">
                                        <Form.Group>
                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Blood Bank Other Type</Form.Label>
                                            <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setBloodBankTypeOther(e.target.value)} value={bloodBankTypeOther} placeholder="Blood Bank Other Type" />
                                        </Form.Group>
                                    </Col> : ''
                            }

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Select Blood Bank Ownership</Form.Label>
                                    <Form.Select required style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setBloodBankOwnerShip(e.target.value)} value={bloodBankOwnerShip}>
                                        <option value="" selected="selected" disabled="disabled">Select Blood Bank Ownership</option>
                                        <option value="Rented">Rented</option>
                                        <option value="Own">Own</option>
                                        <option value="bloodBankOwnerShipOther">Other</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            {
                                bloodBankOwnerShip === 'bloodBankOwnerShipOther' ?
                                    <Col md={6} lg={4} className="mt-5">
                                        <Form.Group>
                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Blood Bank Other Ownership</Form.Label>
                                            <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setBloodBankOwnerShipOther(e.target.value)} value={bloodBankOwnerShipOther} placeholder="Blood Bank Other Ownership" />
                                        </Form.Group>
                                    </Col> : ''
                            }

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Emergency Contact No</Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setEmergencyContact(e.target.value)} value={emergencyContact} placeholder="Emergency Contact No" onWheel={(e) => e.target.blur()} />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Registration No</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setRegistrationNo(e.target.value)} value={registrationNo} placeholder="Registration No" onWheel={(e) => e.target.blur()} />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group className="position-relative mb-3" controlId="contactPersonName">
                                    <Form.Label style={{ fontSize: '12px', fontWeight: 'bold' }}>Upload Blood Bank Image</Form.Label>
                                    <Form.Control style={{ fontSize: '12px' }} type="file" name="file" onChange={(event) => { setSelectedBloodBankImg(event.target.files[0]) }} accept="image/*" />
                                    {
                                        bloodBankImgDone ?
                                            <h1 style={{ fontSize: '12px', fontFamily: 'DM Sans', color: 'green', marginTop: '10px' }}>Blood Bank Image Uploaded Successfully</h1> : ""
                                    }
                                    {
                                        bloodBankImgLoading ?
                                            <Spinner animation="border" role="status" size="small" color="#000" style={{ marginTop: '10px' }}>
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner> :
                                            selectedBloodBankImg === null ?
                                                <Button size="sm" style={{ fontFamily: 'DM Sans', fontSize: '10px', marginTop: '10px' }} disabled>Upload</Button> :
                                                <Button size="sm" style={{ fontFamily: 'DM Sans', fontSize: '10px', marginTop: '10px' }} onClick={() => handleBloodBankImage()}>Upload</Button>
                                    }
                                </Form.Group>
                            </Col>
                        </Row>

                        {/*----------------- Nodal Person Details ---------------------*/}

                        <Row>
                            <h4 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
                                Nodal Person Details
                                <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '17%', marginTop: 10 }}>
                                    {' '}
                                </div>
                            </h4>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Nodal Person First Name</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Nodal Person First Name" onChange={(e) => setNodalPersonFname(e.target.value)} value={nodalPersonFname} />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Nodal Person Last Name</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Nodal Person Last Name Contact" onChange={(e) => setNodalPersonLname(e.target.value)} value={nodalPersonLname} />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Nodal Person Contact</Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setNodalPersonContact(e.target.value)} value={nodalPersonContact} placeholder="Nodal Person Contact" onWheel={(e) => e.target.blur()} />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Nodal Person Email</Form.Label>
                                    <Form.Control required type="email" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setNodalPersonEmail(e.target.value)} value={nodalPersonEmail} placeholder="Nodal Person Email" />
                                </Form.Group>
                            </Col>
                        </Row>

                        {/*----------------- Blood Details ---------------------*/}

                        <Row>
                            <h4 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
                                Blood Details
                                <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '17%', marginTop: 10 }}>
                                    {' '}
                                </div>
                            </h4>
                            <Col md={4} lg={3} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>A Positive</Form.Label>
                                    <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setAPosQ(e.target.value)} value={aPosQ} placeholder="Quantity" />
                                    <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px', marginTop: '10px' }} onChange={(e) => setAPosA(e.target.value)} value={aPosA} placeholder="Available" />
                                </Form.Group>
                            </Col>
                            <Col md={4} lg={3} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>A Negative</Form.Label>
                                    <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setANegQ(e.target.value)} value={aNegQ} placeholder="Quantity" />
                                    <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px', marginTop: '10px' }} onChange={(e) => setANegA(e.target.value)} value={aNegA} placeholder="Available" />
                                </Form.Group>
                            </Col>
                            <Col md={4} lg={3} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>B Positive</Form.Label>
                                    <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setBPosQ(e.target.value)} value={bPosQ} placeholder="Quantity" />
                                    <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px', marginTop: '10px' }} onChange={(e) => setBPosA(e.target.value)} value={bPosA} placeholder="Available" />
                                </Form.Group>
                            </Col>
                            <Col md={4} lg={3} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>B Negative</Form.Label>
                                    <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setBNegQ(e.target.value)} value={bNegQ} placeholder="Quantity" />
                                    <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px', marginTop: '10px' }} onChange={(e) => setBNegA(e.target.value)} value={bNegA} placeholder="Available" />
                                </Form.Group>
                            </Col>
                            <Col md={4} lg={3} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>O Positive</Form.Label>
                                    <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setOPosQ(e.target.value)} value={oPosQ} placeholder="Quantity" />
                                    <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px', marginTop: '10px' }} onChange={(e) => setOPosA(e.target.value)} value={oPosA} placeholder="Available" />
                                </Form.Group>
                            </Col>
                            <Col md={4} lg={3} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>O Negative</Form.Label>
                                    <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setONegQ(e.target.value)} value={oNegQ} placeholder="Quantity" />
                                    <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px', marginTop: '10px' }} onChange={(e) => setONegA(e.target.value)} value={oNegA} placeholder="Available" />
                                </Form.Group>
                            </Col>
                            <Col md={4} lg={3} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>AB Positive</Form.Label>
                                    <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setAbPosQ(e.target.value)} value={abPosQ} placeholder="Quantity" />
                                    <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px', marginTop: '10px' }} onChange={(e) => setAbPosA(e.target.value)} value={abPosA} placeholder="Available" />
                                </Form.Group>
                            </Col>
                            <Col md={4} lg={3} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>AB Negative</Form.Label>
                                    <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setAbNegQ(e.target.value)} value={abNegQ} placeholder="Quantity" />
                                    <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px', marginTop: '10px' }} onChange={(e) => setAbNegA(e.target.value)} value={abNegA} placeholder="Available" />
                                </Form.Group>
                            </Col>
                        </Row>

                        {
                            loading ?
                                <Button variant="primary" disabled className="mt-5" style={{ fontFamily: 'DM Sans', fontSize: '12px', marginBottom: '100px' }}>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    <span className="visually-hidden">Loading...</span>
                                </Button> :
                                <Button type="submit" className="mt-5" style={{ fontFamily: 'DM Sans', fontSize: '12px', marginBottom: '100px' }}>Add Blood Bank</Button>
                        }
                    </Form>
                </Container>
            </div>
        </>
    )
}
