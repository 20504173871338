import { useMutation, useQuery } from '@apollo/client';
import React ,{useState}from 'react'
import {Row ,Col,Image,Modal,Button,Form,Container,Table}from "react-bootstrap"
import { FaPenSquare, FaPen, FaPhoneAlt, FaEnvelopeOpen, FaMapMarkerAlt, FaHistory,FaEdit } from "react-icons/fa";
import { MUTATION_UPDATE_PATHOLOGY, MUTATION_UPDATE_PATHOLOGY_SERVICE } from '../../graphql/mutations';
import swal from 'sweetalert';
import { QUERY_PATHOLOGY_BY_ID } from '../../graphql/queries';
import "../../ComponentCss/NewDashboard.css"
import { Bar, Pie } from "react-chartjs-2";
import { FaAlignJustify, FaHome, FaUsers, FaUsersCog, FaHeadset, FaKey, FaSignOutAlt ,FaClock,FaInfoCircle} from "react-icons/fa";
import { UserData } from '../SuperAdmin/Data';
import { Chart as ChartJS } from "chart.js/auto";
import { LinkContainer } from 'react-router-bootstrap'

export default function PathologyProfile() {

   let count = 1;
    const id =localStorage.getItem("PathologyId")


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);
  const [servicePrice,setServicePrice] =useState("")
  const [serviceId,setServiceId] =useState("")

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
   

  function handleService(id,servicePrice){
    setServicePrice(servicePrice)
    setServiceId(id)
    handleShow2()

    
   }


const{data} =  useQuery(QUERY_PATHOLOGY_BY_ID,{
    variables:{
        "pathologyId": `${id}`
    }
})


console.log(data)

function handleClick(){
    handleShow()
    
   }

     const[updatePathology]   = useMutation(MUTATION_UPDATE_PATHOLOGY,{
        refetchQueries:[
            QUERY_PATHOLOGY_BY_ID,
            "getPathologyById"
        ]
     })





   const[stop,setStop] =useState(false)
   const[idData,setIdData] =useState(data && data.getPathologyById.id)
   const[centerName,setCenterName] =useState(data && data.getPathologyById.nameOfCenter)
   const [RegistNo,setRegistNo] =useState(data && data.getPathologyById.registrationNo)
   const [centerAdd,setCenterAdd] =useState(data && data.getPathologyById.centerAddress)
   const [centerPincode,setCenterPincode] =useState(data && data.getPathologyById.pincode)
    const [centerState,setCenterState] =useState(data && data.getPathologyById.state)
   const [centerContact,setCenterContact] =useState(data && data.getPathologyById.contact)
   
   const [centerEmail,setCenterEmail] =useState(data && data.getPathologyById.email)
   const [ownName,setOwnName] =useState(data && data.getPathologyById.ownerName)
   const [ownContact,setOwnContact] =useState(data && data.getPathologyById.ownerContact)
   const [ownEmail,setOwnEmail] =useState(data && data.getPathologyById.ownerEmail)
   const [privateSector,setPrivateSector] =useState(data && data.getPathologyById.privateSectorCentralGov)
   const [publicSector,setPublicSector] =useState(data && data.getPathologyById.privateSectorIndividual)

  if(data && stop === false){

    setIdData(data && data.getPathologyById.id)
    setCenterName(data && data.getPathologyById.nameOfCenter)
    setRegistNo(data && data.getPathologyById.registrationNo)
    setCenterAdd(data && data.getPathologyById.centerAddress)
    setCenterPincode(data && data.getPathologyById.pincode)
    setCenterState(data && data.getPathologyById.state)
    setCenterContact(data && data.getPathologyById.contact)
    setCenterEmail(data && data.getPathologyById.email)
    setOwnName(data && data.getPathologyById.ownerName)
    setOwnContact(data && data.getPathologyById.ownerContact)
    setOwnEmail(data && data.getPathologyById.ownerEmail)
    setPrivateSector(data && data.getPathologyById.privateSectorCentralGov)
    setPublicSector(data && data.getPathologyById.privateSectorIndividual)
    setStop(true)

  }





const [validated, setValidated] = useState(false);

const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }else{
        event.preventDefault();
        updatePathology({
         variables:{
            "updatePathologyInput": {
                "pathologyId": `${idData}`,
                "nameOfCenter": `${centerName}`,
                "centerAddress": `${centerAdd}`,
                "state": `${centerState}`,
                "pincode": `${centerPincode}`,
                "contact": `${centerContact}`,
                "email": `${centerEmail}`,
                "ownerName": `${ownName}`,
                "ownerContact": `${ownContact}`,
                "privateSectorCentralGov": `${privateSector}`,
                "ownerEmail": `${ownEmail}`,
                "privateSectorIndividual": `${publicSector}`,
                "registrationNo": `${RegistNo}`,
                
              }
         }
        })

        handleClose();
        swal({ title: 'Successfull!!!', text: 'Update Successfully', icon: 'success' });
    }

    setValidated(true);
  };


    const[updatePathologyService,{loading}] = useMutation(MUTATION_UPDATE_PATHOLOGY_SERVICE,{
      refetchQueries:[
        QUERY_PATHOLOGY_BY_ID,
        "getPathologyById"
      ]
    })

  function handleEditService(){
    updatePathologyService({
      variables:{
   "pathology": `${data && data.getPathologyById.id}`,
   "serviceId": `${serviceId}`,
   "servicePrice": `${servicePrice}`
      }
    })
    handleClose2()

  }


function handleMenu() {
let toggle = document.querySelector(".toggle");
let navigation = document.querySelector(".navigation");
let main = document.querySelector(".main");
    navigation.classList.toggle("active");
    main.classList.toggle("active");
};




  return (
    <>

<div >
                <div className="navigation">
                    <br></br> 
                  
                <span className="title" style={{ fontSize: 20, fontFamily: 'DM Sans', fontWeight: 'bold',color:"#fff",textAlign:"center",marginLeft:20,marginTop:20 }}>Dashboard</span>
                    <ul>
                        <br></br><br></br><br></br>
                       
                        <li>
                        <LinkContainer to="/">
                            <a href="#">
                                <span className="icon">

                                </span>
                                <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }} eventKey="first"><FaHome /> Home</span>
                              
                            </a>
                            </LinkContainer>
                        </li>
                        <li>
                        <LinkContainer to="/PathologyProfile">
                            <a href="#">
                                <span className="icon">
                                </span>
                                <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }}><FaUsers /> Profile</span>
                            </a>
                            </LinkContainer>
                        </li>
                        <li>
                        <LinkContainer to="/AddPathologySlot">
                            <a href="#">
                                <span className="icon"> </span>
                                <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }}><FaClock /> All Slot</span>
                            </a>
                            </LinkContainer>
                        </li>
                        <li>
                        <LinkContainer to="/Termcondition">
                            <a href="#">
                                <span className="icon"> </span>
                                <span className="title" style={{ fontSize: 12, fontFamily: 'DM Sans' }}><FaInfoCircle /> Terms & Condition</span>
                            </a>
                            </LinkContainer>
                        </li>
                       
                       
                    </ul>
                    
                </div>
                

                <div className="main">
                    <div className="topbar">
                        <div>
                            <FaAlignJustify onClick={() => handleMenu()}  className="toggle"/>
                        </div>
                        <div className="search">
                            <label>
                                <input type="text" placeholder="Search here" />
                            </label>
                        </div>
                        <div className="user">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/2048px-Circle-icons-profile.svg.png" alt="" />
                        </div>
                    </div>
                 
                    <h3 style={{textAlign:"center",marginTop:20,fontFamily:"Dm sans",fontWeight:"bold"}}>{data && data.getPathologyById.nameOfCenter}</h3>
    <hr></hr>
    <Row>
     <Col md={6}>
        <Image src="https://play-lh.googleusercontent.com/NuJSG_bIoce6kvvtJnULAf34_Rsa1j-HDEt4MWTOrL_3XA51QH9qOQR5UmAYxPI96jA=w600-h300-pc0xffffff-pd"  className='mx-auto d-block img-fluid' alt="Responsive image"/>
     </Col>
    <Col md={6}>

    <Row>
        <Col md={6} style={{border:"1px solid #00a8ff",textAlign:"center"}}>
            <h6 style={{marginTop:10,fontWeight:"bold",color:"#00a8ff",fontFamily:"Dm sans",fontSize:12}}>Private Sector Central Goverment</h6>
            <h6 style={{marginTop:10,fontFamily:"Dm sans"   ,fontSize:12}}>{data && data.getPathologyById.privateSectorCentralGov}</h6>
        </Col>
        <Col md={6} style={{border:"1px solid #00a8ff",textAlign:"center",}}>
        <h6 style={{marginTop:10,fontWeight:"bold",color:"#00a8ff",fontFamily:"Dm sans",fontSize:12}}>Private Sector Individual</h6>
        <h6 style={{marginTop:10,fontFamily:"Dm sans",fontSize:12}}>{data && data.getPathologyById.privateSectorIndividual}</h6>

        </Col>
    </Row>
     <Row>
      <Col md={6}>
      <h6 style={{marginTop:30,fontFamily:"Dm sans",fontSize:12 ,fontWeight:"bold"}}>Registartion No : {data && data.getPathologyById.registrationNo}</h6>
  <h6 style={{marginTop:20 ,fontSize:12  }}><FaPhoneAlt style={{marginRight:20}}/>{data && data.getPathologyById.contact}  </h6>

  <h6 style={{marginTop:5  ,fontSize:12 }}><FaEnvelopeOpen style={{marginRight:20}}/>{data && data.getPathologyById.email} </h6>
  <h6 style={{marginTop:5 ,fontSize:12  }}><FaMapMarkerAlt style={{marginRight:20}}/>{data && data.getPathologyById.centerAddress} {data && data.getPathologyById.pincode} {data && data.getPathologyById.district} {data && data.getPathologyById.state}</h6>


  <h6 style={{marginTop:20 ,fontSize:12 }}>Owner Name    : {data && data.getPathologyById.ownerName} </h6>
  <h6 style={{marginTop:5  ,fontSize:12}}>Owner Contact    : {data && data.getPathologyById.ownerContact} </h6>
  <h6 style={{marginTop:5 ,fontSize:12 }}>Owner Email    : {data && data.getPathologyById.ownerEmail} </h6>

  <h6 style={{marginTop:5  }}  onClick={()=>handleClick()}>Edit Profile </h6>
      </Col>


      <Col md={6}>
      <h6 style={{marginTop:30,fontSize:12}}>Lisence By</h6>
   <ul>
    {
        data && data.getPathologyById.lisenceBy.map(lis=>{
            return(
           <li style={{fontSize:12}}>{lis}</li>
            )
        })


    }

   </ul>
      </Col>
     </Row>

    
    </Col>

    </Row>
 <br></br>
 <Row>
      <h5 style={{textAlign:"center",fontFamily:"Dm sans",fontWeight:"bold"}}>All service</h5>

      {
            data && data.getPathologyById.services.map(service=>{
               return (

                <Col md={3} style={{background:"#0abde3",border:"2px solid #fff"}}>
                <h6 style={{marginTop:10,color:"#fff"}}>{count++}</h6>
                 <h5  style={{fontFamily:"Dm sans",marginTop:5,fontSize:14,fontWeight:"bold",fontSize:12}}>Service Name : {service.serviceName}</h5>
                 <h6  style={{fontFamily:"Dm sans",marginTop:5,fontSize:14,fontWeight:"bold",fontSize:12}}>Service Price : {service.servicePrice} /-</h6>
                
                 <div   style={{display:"flex",justifyContent:"flex-end"}} onClick={()=>handleService(service.id,service.servicePrice)}>
                 <FaEdit style={{marginBottom:10,color:"#fff"}} size={15} />
                  <h6 style={{marginLeft:5,color:"#fff",fontSize:12,marginTop:1}}>Edit Service</h6>
                 </div>

              </Col>

               )


            })



      }
     
      
    </Row>



    <h6 style={{margin:10,color:"#3498db"}}>Pathologist Detail</h6>
    <hr></hr>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>SR No.</th>
          <th>Name</th>
          <th>Qualification</th>
          <th>Experience</th>
        </tr>
      </thead>
      <tbody>
        {
              data && data.getPathologyById.pathologistDetail.map(detail=>{
                return(

                    <tr>
                    <td>{count++}</td>
                    <td>{detail.name}</td>
                    <td>{detail.qualification}</td>
                    <td>{detail.experience}</td>
                    
                  </tr>
                )
              })

        }
       
       
      </tbody>
    </Table>





    
    <Modal show={show} onHide={handleClose}   size="lg">
    <Container>
        <h6 style={{textAlign:"center",marginTop:20,color:"#000",marginBottom:10}}>Update Profile</h6>
   <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Center name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Center Name"
            value={centerName}
            style={{fontSize:12}}
            onChange={(e)=>setCenterName(e.target.value)}

          />
         
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label  style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Registarion No</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Registarion No"
            value={RegistNo}
            style={{fontSize:12}}
            onChange={(e)=>setRegistNo(e.target.value)}
          />
         
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Center Address</Form.Label>
          <Form.Control
            required
            type="text"
            value={centerAdd}
            placeholder="Center Address"
             style={{fontSize:12}}
             onChange={(e)=>setCenterAdd(e.target.value)}
          />
        
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom03">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Center Pincode</Form.Label>
          <Form.Control type="text" placeholder="Center Pincode" required   style={{fontSize:12}}
          value={centerPincode}
          onChange={(e)=>setCenterPincode(e.target.value)}
          />
       
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom04">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Center State</Form.Label>
          <Form.Control type="text" placeholder="Center State" required    style={{fontSize:12}}
          value={centerState}
          onChange={(e)=>setCenterState(e.target.value)}
          />
        
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom05">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Center Contact</Form.Label>
          <Form.Control type="text" placeholder="Center Contact" required   style={{fontSize:12}} 
           value={centerContact}
           onChange={(e)=>setCenterContact(e.target.value)}
          
          />
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom03">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Center Email</Form.Label>
          <Form.Control type="text" placeholder="Center Pincode" required   style={{fontSize:12}}
            value={centerEmail}
            onChange={(e)=>setCenterEmail(e.target.value)}
          />
       
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom04">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Owner Name</Form.Label>
          <Form.Control type="text" placeholder="Owner Name" required   style={{fontSize:12}}
          value={ownName}
          onChange={(e)=>setOwnName(e.target.value)}
          />
        
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom05">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Owner Contact</Form.Label>
          <Form.Control type="text" placeholder="Owner Contact" required  style={{fontSize:12}} 
          value={ownContact}
          onChange={(e)=>setOwnContact(e.target.value)}
          />
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom03">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Owner Email</Form.Label>
          <Form.Control type="text" placeholder="Owner Email" required   style={{fontSize:12}}
           value={ownEmail}
           onChange={(e)=>setOwnEmail(e.target.value)}
          />
       
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom04">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Select Private Sector</Form.Label>
          <Form.Select aria-label="Select Private Sector"   style={{fontSize:12}} onChange={(e)=>setPrivateSector(e.target.value)}  value={privateSector}>
           <option>Open this select menu</option>
           <option value="Individual Proprietorship">Individual Proprietorship</option>
           <option value="Registered Partnership">Registered Partnership</option>
            <option value="Registered Company">Registered Company</option>
            <option value="Co-operation Society">Co-operation Society</option>
            <option value="Trust and Charitable">Trust and Charitable</option>
            <option value="privateSectorOtherr">Other</option>
           </Form.Select>
        
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom05">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Select Public Sector</Form.Label>
          <Form.Select aria-label="Select Private Sector"   style={{fontSize:12}}  onChange={(e)=>setPublicSector(e.target.value)} value={publicSector}>
          <option>Open this select menu</option>
           <option value="Central Government">Central Government</option>
           <option value="State Government">State Government</option>
            <option value="Local Government">Registered Company</option>
            <option value="Public Sector Undertaken">Co-operation Society</option>
            <option value="Trust and Charitable">Trust and Charitable</option>
            <option value="publicSectorOther">Other</option>
           </Form.Select>
        </Form.Group>
      </Row>
      <Form.Group className="mb-3">
       
      </Form.Group>
      <Button type="submit" style={{fontFamily:"Dm sans",marginBottom:20}} className="mx-auto d-block">Update Profile</Button>
    </Form>
    </Container>
      </Modal>
     
      <Modal show={show2} onHide={handleClose2}>
        <h5 style={{textAlign:"center",marginTop:20,fontFamily:"Dm sans"}}>Edit Service Price </h5>
      <Row style={{marginTop:10}}>
        <Col md={3}></Col>
        <Col md={6}>
          <input placeholder='Service Price'  className='mx-auto d-block' style={{padding:3,fontFamily:"Dm sans",borderRadius:5,fontSize:14}} onChange={(e)=>setServicePrice(e.target.value)}  value={servicePrice}/>

          <Button  style={{marginTop:20,border:"none",marginBottom:10,fontFamily:"Dm sans",borderRadius:20,fontSize:12}} className="mx-auto d-block" onClick={()=>handleEditService()}>Change Now</Button>

        </Col>
        <Col md={3}></Col>
      </Row>
      </Modal>
                </div>
              
            </div>


    
    
    </>
  )
}
