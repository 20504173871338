import React from 'react'

export default function Test() {

    const getUploadParams = () => {
        return { url: 'https://httpbin.org/post' }
    }

    const handleChangeStatus = ({ meta }, status) => {
        console.log(status, meta)
    }

    const handleSubmit = (files, allFiles) => {
        console.log(files.map(f => f.meta))
        allFiles.forEach(f => f.remove())
    }

    return (
        <>
            
        </>
    )
}
