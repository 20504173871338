import React from 'react';
import { Col, Container, Row, Image } from 'react-bootstrap';
import { ImLocation2, ImMail } from 'react-icons/im';
import { BsFillTelephoneFill, BsFacebook, BsTwitter, BsLinkedin, BsInstagram } from 'react-icons/bs';
import { FaYoutube} from 'react-icons/fa';
import '../ComponentCss/Footer.css';
import Aro from '../Images/aro.png';
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>
            <Container style={{ backgroundColor: '#222831', color: '#fff', paddingTop: '30px' }} fluid id="contactId">
                <Row>
                    <Col md={4}>
                        <center>
                            <p className='heading' style={{ fontWeight: 'bold', fontFamily: 'poppins' }}>B+Harp</p>
                            <p>
                                <Link to="/privacyPolicy" style={{ color: '#fff', textDecoration: 'none' }} target="_blank">Privacy Policy</Link>
                            </p>
                            <p >
                                <Link to="/terms" style={{ color: '#fff', textDecoration: 'none' }} target="_blank">Terms & Conditions</Link>
                            </p>
                            <p >
                                <Link to="/refund" style={{ color: '#fff', textDecoration: 'none' }} target="_blank"> Cancellation/Refund Policy</Link>
                            </p>
                        </center>
                    </Col>
                    <Col md={4}>
                        <center>
                            <p className='heading' style={{ fontWeight: 'bold', fontFamily: 'poppins' }}>Contact Us</p>
                            <p>
                                <ImLocation2 />&nbsp; Vishwamitra Colony, Golambar, Distt. Buxar, State, Bihar
                            </p>
                            <p style={{ marginTop: '-5px' }}>
                                <BsFillTelephoneFill />&nbsp; Call: +91-9110188852
                            </p>
                            <p style={{ marginTop: '-5px' }}>
                                <ImMail />&nbsp; contactbharp@gmail.com
                            </p>
                        </center>
                    </Col>
                    <Col md={4}>
                        <center>
                            <p className='heading' style={{ fontFamily: 'poppins', fontWeight: 'bold' }}>Social</p>
                            <p style={{ fontFamily: 'poppins' }}>We have an enduring commitment to a healthier INDIA, and partner with organizations that share our vision.</p>
                            <h4>
                                <a href="https://www.facebook.com/arogyatrident" target='_blank' style={{ color: "#fff" }}><BsFacebook style={{ marginLeft: 10 }} /></a>
                                <a href="https://www.linkedin.com/company/arogyatrident/" target='_blank' style={{ color: "#fff" }}><BsLinkedin style={{ marginLeft: 10 }} /></a>
                                <a href="https://twitter.com/arogyatrident" target='_blank' style={{ color: "#fff" }}><BsTwitter style={{ marginLeft: 10 }} /></a>
                                <a href="https://www.youtube.com/@arogyatrident" target='_blank' style={{ color: "#fff" }}><FaYoutube style={{ marginLeft: 10 }} /></a>
                            </h4>
                        </center>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Image src={Aro} style={{ widht: 150, height: 150 }} className='mx-auto d-block' />
                        <p style={{ fontSize: '12px', textAlign: 'center' }}>© 2023 All Rights Reserved By Arogya Trident Healthcare Private Limited</p>
                        <p style={{ fontSize: '12px', marginTop: '-10px', textAlign: 'center' }}>Developed and managed by <a href="https://softseekersinfotech.com/" style={{ textDecoration: 'none', color: '#fff' }} target="_blank">Softseekers Infotech Pvt. Ltd.</a></p>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Footer