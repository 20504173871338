import { useMutation, useQuery } from '@apollo/client';
import React,{useState} from 'react'
import {Row ,Col,Image,Table,Modal,Button,Form,InputGroup, Container}from "react-bootstrap"
import { FaPenSquare, FaPen, FaPhoneAlt, FaEnvelopeOpen, FaMapMarkerAlt, FaHistory ,FaEdit} from "react-icons/fa";
import { MUTATION_UPDATE_RADIOLOGY, MUTATION_UPDATE_RADIOLOGY_SERVICE } from '../../graphql/mutations';
import swal from 'sweetalert';
import { QUERY_RADIOLOGY_BY_ID } from '../../graphql/queries';
import "../../ComponentCss/NewDashboard.css"
import { Bar, Pie } from "react-chartjs-2";
import { FaAlignJustify, FaHome, FaUsers, FaUsersCog, FaHeadset, FaKey, FaSignOutAlt,FaClock ,FaInfoCircle} from "react-icons/fa";
import { UserData } from '../SuperAdmin/Data';
import { Chart as ChartJS } from "chart.js/auto";
import { LinkContainer } from 'react-router-bootstrap'

export default function RadiologyProfile() {

    const id =localStorage.getItem("RadiologyId")

    const{data} = useQuery(QUERY_RADIOLOGY_BY_ID,{
      variables:{
          "radiologyId": `${id}`
      }
    })

    console.log(data)
   
   const[updateRadiology] = useMutation(MUTATION_UPDATE_RADIOLOGY,{
    refetchQueries:[
        QUERY_RADIOLOGY_BY_ID,
        "getRadiologyById"
    ]
   })

   function handleMenu() {

    let toggle = document.querySelector(".toggle");
let navigation = document.querySelector(".navigation");
let main = document.querySelector(".main");
    navigation.classList.toggle("active");
    main.classList.toggle("active");
};

const [userData, setUserData] = useState({
  labels: UserData.map((data) => data.year),
  datasets: [
      {
          label: "Users Gained",
          data: UserData.map((data) => data.userGain),
          backgroundColor: [
              "rgba(75,192,192,1)",
              "#ecf0f1",
              "#50AF95",
              "#f3ba2f",
              "#2a71d0",
          ],
          borderColor: "black",
          borderWidth: 1,
      },
  ],
});

   const[idData,setIdData] =useState(data && data.getRadiologyById.id)
   const[centerName,setCenterName] =useState(data && data.getRadiologyById.nameOfCenter)
   const [RegistNo,setRegistNo] =useState(data && data.getRadiologyById.registrationNo)
   const [centerAdd,setCenterAdd] =useState(data && data.getRadiologyById.centerAddress)
   const [centerPincode,setCenterPincode] =useState(data && data.getRadiologyById.pincode)
    const [centerState,setCenterState] =useState(data && data.getRadiologyById.state)
   const [centerContact,setCenterContact] =useState(data && data.getRadiologyById.contact)
   
   const [centerEmail,setCenterEmail] =useState(data && data.getRadiologyById.email)
   const [ownName,setOwnName] =useState(data && data.getRadiologyById.ownerName)
   const [ownContact,setOwnContact] =useState(data && data.getRadiologyById.ownerContact)
   const [ownEmail,setOwnEmail] =useState(data && data.getRadiologyById.ownerEmail)
   const [privateSector,setPrivateSector] =useState(data && data.getRadiologyById.privateSectorCentralGov)
   const [publicSector,setPublicSector] =useState(data && data.getRadiologyById.privateSectorIndividual)

  let count = 1;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  const [show2, setShow2] = useState(false);
  const [servicePrice,setServicePrice] =useState("")
  const [serviceId,setServiceId] =useState("")

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);


  const[getdata,setGetData] =useState()

   

  


   function handleClick(){
    handleShow()
    
   }

   function handleService(id,servicePrice){
    setServicePrice(servicePrice)
    setServiceId(id)
    handleShow2()

    
   }





   const [validated, setValidated] = useState(false);

   const handleSubmit = (event) => {
     const form = event.currentTarget;
     if (form.checkValidity() === false) {
       event.preventDefault();
       event.stopPropagation();
     }else{
        event.preventDefault();
     updateRadiology({
     variables:{
        "updateRadiologyInput": {
            "radiologyId": `${idData}`,
            "nameOfCenter": `${centerName}`,
            "centerAddress": `${centerAdd}`,
            "state": `${centerState}`,
            "contact":`${centerContact}`,
            "email": `${centerEmail}`,
            "ownerName": `${ownName}`,
            "pincode": `${centerPincode}`,
            "ownerContact": `${ownContact}`,
            "ownerEmail": `${ownEmail}`,
            "privateSectorCentralGov": `${privateSector}`,
            "privateSectorIndividual": `${publicSector}`,
            "registrationNo": `${RegistNo}`
          }
    }
     })
     handleClose();
     swal({ title: 'Successfull!!!', text: 'Update Successfully', icon: 'success' });

     }
 
     setValidated(true);
   };
   
     const[updateRadiologyService,{loading}] =  useMutation(MUTATION_UPDATE_RADIOLOGY_SERVICE,{
      refetchQueries:[
        QUERY_RADIOLOGY_BY_ID,
        "getRadiologyById"
      ]
     })


      function handleEditService(){
        updateRadiologyService({
         variables:{
          "serviceId": `${serviceId}`,
          "servicePrice": `${servicePrice}`,
          "radiologyId": `${data && data.getRadiologyById.id}`
         }
        })
        handleClose2()
      }






  return (
    <>

<div >
 
 <div className="navigation">
     <br></br> 
   
 <span className="title" style={{ fontSize: 20, fontFamily: 'DM Sans', fontWeight: 'bold',color:"#fff",textAlign:"center",marginLeft:20,marginTop:20 }}>Dashboard</span>
     <ul>
         <br></br><br></br><br></br>
        
         <li>
         <LinkContainer to="/RadiologyDashboard">
             <a href="#">
                 <span className="icon">

                 </span>
                 <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }} eventKey="first"><FaHome /> Home</span>
               
             </a>
             </LinkContainer>
         </li>
         <li>
         <LinkContainer to="/RadiologyProfile">
             <a href="/RadiologyProfile">
                 <span className="icon">
                 </span>
                 <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }}><FaUsers />Profile</span>
             </a>
             </LinkContainer>
         </li>
         <li>
         <LinkContainer to="/AddRadiologySlot">
             <a href="#">
                 <span className="icon"> </span>
                 <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }}><FaClock /> All Slot</span>
             </a>
             </LinkContainer>
         </li>
         <li>
                        <LinkContainer to="/Termcondition">
                            <a href="#">
                                <span className="icon"> </span>
                                <span className="title" style={{ fontSize: 12, fontFamily: 'DM Sans' }}><FaInfoCircle /> Terms & Condition</span>
                            </a>
                            </LinkContainer>
                        </li>
      
       
     </ul>
     
 </div>
 

 <div className="main">
     <div className="topbar">
         <div>
             <FaAlignJustify onClick={() => handleMenu()}  className="toggle"/>
         </div>
         <div className="search">
             <label>
                 <input type="text" placeholder="Search here" />
             </label>
         </div>
         <div className="user">
             <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/2048px-Circle-icons-profile.svg.png" alt="" />
         </div>
     </div>

     <h3 style={{textAlign:"center",marginTop:20,fontFamily:"Dm sans",fontWeight:"bold"}}>{data && data.getRadiologyById.nameOfCenter}</h3>
    <hr></hr>
    <Row>
     <Col md={6}>
        <Image src="https://play-lh.googleusercontent.com/NuJSG_bIoce6kvvtJnULAf34_Rsa1j-HDEt4MWTOrL_3XA51QH9qOQR5UmAYxPI96jA=w600-h300-pc0xffffff-pd"  className='mx-auto d-block img-fluid' alt="Responsive image"/>
     </Col>
    <Col md={6}>
    <Row>
        <Col md={6} style={{border:"1px solid #00a8ff",textAlign:"center"}}>
            <h6 style={{marginTop:10,fontWeight:"bold",color:"#00a8ff",fontFamily:"Dm sans",fontSize:12 }}>Private Sector Central Goverment</h6>
            <h6 style={{marginTop:10,fontFamily:"Dm sans" ,fontSize:12  }}>{data && data.getRadiologyById.privateSectorCentralGov}</h6>
        </Col>
        <Col md={6} style={{border:"1px solid #00a8ff",textAlign:"center",}}>
        <h6 style={{marginTop:10,fontWeight:"bold",color:"#00a8ff",fontFamily:"Dm sans",fontSize:12}}>Private Sector Individual</h6>
        <h6 style={{marginTop:10,fontFamily:"Dm sans",fontSize:12}}>{data && data.getRadiologyById.privateSectorIndividual}</h6>

        </Col>
    </Row>
    <Row>
      <Col md={6}>
      <h6 style={{marginTop:30,fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Registartion No : {data && data.getRadiologyById.registrationNo}</h6>
  <h6 style={{marginTop:20,fontSize:12  }}><FaPhoneAlt style={{marginRight:20,fontSize:12}}/>{data && data.getRadiologyById.contact}  </h6>

  <h6 style={{marginTop:5 ,fontSize:12  }}><FaEnvelopeOpen style={{marginRight:20,fontSize:12 }}/>{data && data.getRadiologyById.email} </h6>
  <h6 style={{marginTop:5 ,fontSize:12  }}><FaMapMarkerAlt style={{marginRight:20,fontSize:12 }}/>{data && data.getRadiologyById.centerAddress} {data && data.getRadiologyById.pincode} </h6>


  <h6 style={{marginTop:20,fontFamily:"Dm sans" ,fontSize:12 }}>Owner Name    : {data && data.getRadiologyById.ownerName} </h6>
  <h6 style={{marginTop:5 ,fontFamily:"Dm sans" ,fontSize:12}}>Owner Contact    : {data && data.getRadiologyById.ownerContact} </h6>
  <h6 style={{marginTop:5  ,fontFamily:"Dm sans",fontSize:12}}>Owner Email    : {data && data.getRadiologyById.ownerEmail} </h6>
  <h6 style={{marginTop:10 ,fontFamily:"Dm sans" ,fontSize:12,background:"#0abde3",color:"#fff",padding:10,borderRadius:5}} onClick={()=>handleClick()}>   Edit Profile </h6>

      </Col>

      <Col md={6}>
      <h6 style={{fontFamily:"Dm sans",marginTop:30,fontSize:14,fontWeight:"bold"}}>Lisence By</h6>
   <ul>
    {

        data && data.getRadiologyById.lisenceBy.map(lis=>{
            return(
                <li  style={{fontSize:12}}>{lis}</li>
            )
        })


    }
  
   </ul>
      </Col>
    </Row>

    
    </Col>

    </Row>

   <hr></hr>
    <Row>
      <h5 style={{textAlign:"center",fontFamily:"Dm sans",fontWeight:"bold"}}>All service</h5>

      {
            data && data.getRadiologyById.services.map(service=>{
               return (

                <Col md={3} style={{background:"#0abde3",border:"2px solid #fff",borderRadius:10}}>
                <h6 style={{marginTop:10,fontSize:12,color:"#fff"}}>{count++}</h6>
                 <h5  style={{fontFamily:"Dm sans",marginTop:5,fontSize:12,fontWeight:"bold"}}>Service Name : {service.serviceName}</h5>
                 <h6  style={{fontFamily:"Dm sans",marginTop:5,fontSize:12,fontWeight:"bold"}}>Service Price : {service.servicePrice} /-</h6>

                 <div   style={{display:"flex",justifyContent:"flex-end"}} onClick={()=>handleService(service.id,service.servicePrice)}>
                 <FaEdit style={{marginBottom:10,color:"#fff"}} size={15} />
                  <h6 style={{marginLeft:5,color:"#fff",fontSize:12,marginTop:1}}>Edit Service</h6>
                 </div>

              </Col>

               )


            })



      }
     
      
    </Row>

    


   
    <h6 style={{margin:10,color:"#3498db"}}>Radiologist Detail</h6>
    <hr></hr>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>SR No.</th>
          <th>Name</th>
          <th>Qualification</th>
          <th>Experience</th>
        </tr>
      </thead>
      <tbody>
        {
              data && data.getRadiologyById.radiologistDetail.map(detail=>{
                return(

                    <tr>
                    <td>{count++}</td>
                    <td>{detail.name}</td>
                    <td>{detail.qualification}</td>
                    <td>{detail.experience}</td>
                    
                  </tr>
                )
              })

        }
       
       
      </tbody>
    </Table>
   <Modal show={show} onHide={handleClose}   size="lg">
    <Container>
        <h6 style={{textAlign:"center",marginTop:20,color:"#000",marginBottom:10}}>Update Profile</h6>
   <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Center name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Center Name"
            value={centerName}
            style={{fontSize:12}}
            onChange={(e)=>setCenterName(e.target.value)}

          />
         
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label  style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Registarion No</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Registarion No"
            value={RegistNo}
            style={{fontSize:12}}
            onChange={(e)=>setRegistNo(e.target.value)}
          />
         
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Center Address</Form.Label>
          <Form.Control
            required
            type="text"
            value={centerAdd}
            placeholder="Center Address"
             style={{fontSize:12}}
             onChange={(e)=>setCenterAdd(e.target.value)}
          />
        
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom03">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Center Pincode</Form.Label>
          <Form.Control type="text" placeholder="Center Pincode" required   style={{fontSize:12}}
          value={centerPincode}
          onChange={(e)=>setCenterPincode(e.target.value)}
          />
       
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom04">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Center State</Form.Label>
          <Form.Control type="text" placeholder="Center State" required    style={{fontSize:12}}
          value={centerState}
          onChange={(e)=>setCenterState(e.target.value)}
          />
        
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom05">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Center Contact</Form.Label>
          <Form.Control type="text" placeholder="Center Contact" required   style={{fontSize:12}} 
           value={centerContact}
           onChange={(e)=>setCenterContact(e.target.value)}
          
          />
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom03">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Center Email</Form.Label>
          <Form.Control type="text" placeholder="Center Pincode" required   style={{fontSize:12}}
            value={centerEmail}
            onChange={(e)=>setCenterEmail(e.target.value)}
          />
       
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom04">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Owner Name</Form.Label>
          <Form.Control type="text" placeholder="Owner Name" required   style={{fontSize:12}}
          value={ownName}
          onChange={(e)=>setOwnName(e.target.value)}
          />
        
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom05">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Owner Contact</Form.Label>
          <Form.Control type="text" placeholder="Owner Contact" required  style={{fontSize:12}} 
          value={ownContact}
          onChange={(e)=>setOwnContact(e.target.value)}
          />
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom03">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Owner Email</Form.Label>
          <Form.Control type="text" placeholder="Owner Email" required   style={{fontSize:12}}
           value={ownEmail}
           onChange={(e)=>setOwnEmail(e.target.value)}
          />
       
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom04">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Select Private Sector</Form.Label>
          <Form.Select aria-label="Select Private Sector"   style={{fontSize:12}} onChange={(e)=>setPrivateSector(e.target.value)}  value={privateSector}>
           <option>Open this select menu</option>
           <option value="Individual Proprietorship">Individual Proprietorship</option>
           <option value="Registered Partnership">Registered Partnership</option>
            <option value="Registered Company">Registered Company</option>
            <option value="Co-operation Society">Co-operation Society</option>
            <option value="Trust and Charitable">Trust and Charitable</option>
            <option value="privateSectorOtherr">Other</option>
           </Form.Select>
        
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom05">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Select Public Sector</Form.Label>
          <Form.Select aria-label="Select Private Sector"   style={{fontSize:12}}  onChange={(e)=>setPublicSector(e.target.value)} value={publicSector}>
          <option>Open this select menu</option>
           <option value="Central Government">Central Government</option>
           <option value="State Government">State Government</option>
            <option value="Local Government">Registered Company</option>
            <option value="Public Sector Undertaken">Co-operation Society</option>
            <option value="Trust and Charitable">Trust and Charitable</option>
            <option value="publicSectorOther">Other</option>
           </Form.Select>
        </Form.Group>
      </Row>
      <Form.Group className="mb-3">
       
      </Form.Group>
      <Button type="submit" style={{fontFamily:"Dm sans",marginBottom:20}} className="mx-auto d-block">Update Profile</Button>
    </Form>
    </Container>
      </Modal>


      <Modal show={show2} onHide={handleClose2}>
        <h5 style={{textAlign:"center",marginTop:20,fontFamily:"Dm sans"}}>Edit Service Price </h5>
      <Row style={{marginTop:10}}>
        <Col md={3}></Col>
        <Col md={6}>
          <input placeholder='Service Price'  className='mx-auto d-block' style={{padding:3,fontFamily:"Dm sans",borderRadius:5,fontSize:14}} onChange={(e)=>setServicePrice(e.target.value)}  value={servicePrice}/>

          <Button  style={{marginTop:20,border:"none",marginBottom:10,fontFamily:"Dm sans",borderRadius:20,fontSize:12}} className="mx-auto d-block" onClick={()=>handleEditService()}>Change Now</Button>

        </Col>
        <Col md={3}></Col>
      </Row>
      </Modal>
    
    
 </div>

</div>






    
    </>
  )
}
