import React,{useState} from 'react'
import { QUERY_BLOOD_BANK_BY_ID } from '../../graphql/queries';
import { useMutation, useQuery } from '@apollo/client';
import {Container,Col,Row,Form,Button,Modal, Card} from "react-bootstrap"
import "../../ComponentCss/NewDashboard.css"
import { Bar, Pie } from "react-chartjs-2";
import { FaAlignJustify, FaHome, FaUsers, FaUsersCog, FaHeadset, FaKey, FaSignOutAlt ,FaClock,FaPenSquare,FaInfoCircle} from "react-icons/fa";
import { UserData } from '../SuperAdmin/Data';
import { Chart as ChartJS } from "chart.js/auto";
import { LinkContainer } from 'react-router-bootstrap'
import { MUTATION_UPDATE_AB_NEG, MUTATION_UPDATE_AB_POS, MUTATION_UPDATE_A_NEG, MUTATION_UPDATE_A_POS, MUTATION_UPDATE_B_NEG, MUTATION_UPDATE_B_POS, MUTATION_UPDATE_O_NEG, MUTATION_UPDATE_O_POS } from '../../graphql/mutations';

export default function EditBloodBank() {

    const [showBPos, setBPosShow] = useState(false);

    
  function handleMenu() {

    let toggle = document.querySelector(".toggle");
    let navigation = document.querySelector(".navigation");
    let main = document.querySelector(".main");
    navigation.classList.toggle("active");
    main.classList.toggle("active");
  };

    const handleBPosClose = () => setBPosShow(false);
    const handleBPosShow = () => setBPosShow(true);

    const [showBNeg, setBNegShow] = useState(false);

    const handleBNegClose = () => setBNegShow(false);
    const handleBNegShow = () => setBNegShow(true);

    const [showANeg, setANegShow] = useState(false);

    const handleANegClose = () => setANegShow(false);
    const handleANegShow = () => setANegShow(true);

    const [showAPos, setAPosShow] = useState(false);

    const handleAPosClose = () => setAPosShow(false);
    const handleAPosShow = () => setAPosShow(true);

    const [shoONeg, setONegShow] = useState(false);

    const handleONegClose = () => setONegShow(false);
    const handleONegShow = () => setONegShow(true);

    const [showOPos, setOPosShow] = useState(false);

    const handleOPosClose = () => setOPosShow(false);
    const handleOPosShow = () => setOPosShow(true);

    const [shoABNeg, setABNegShow] = useState(false);

    const handleABNegClose = () => setABNegShow(false);
    const handleABNegShow = () => setABNegShow(true);


    const [showABPos, setABPosShow] = useState(false);

    const handleABPosClose = () => setABPosShow(false);
    const handleABPosShow = () => setABPosShow(true);

  
    const id = localStorage.getItem("bloodBankId")

    const{data} = useQuery(QUERY_BLOOD_BANK_BY_ID,{
      variables:{
        "bloodBankId":`${id}`
      }
    })




    console.log("data",data)

     const[stop,setStop] =useState(false)
    const[bPosQuanity,setBPosQuanity] =useState(data && data.getBloodBankById.bloodBankBPos[0].quantity)
    const[bPosAvailable,setBPosAvailable] =useState(data && data.getBloodBankById.bloodBankBPos[0].available)
    const[bNegQuanity,setBNegQuanity] =useState(data && data.getBloodBankById.bloodBankBNeg[0].quantity)
    const[bNegAvailable,setBNegAvailable] =useState(data && data.getBloodBankById.bloodBankBNeg[0].available)
    const[ANegQuanity,setANegQuanity] =useState(data && data.getBloodBankById.bloodBankANeg[0].quantity)
    const[ANegAvailable,setANegAvailable] =useState(data && data.getBloodBankById.bloodBankANeg[0].available)
    const[APosQuanity,setAPosQuanity] =useState(data && data.getBloodBankById.bloodBankAPos[0].quantity)
    const[APosAvailable,setAPosAvailable] =useState(data && data.getBloodBankById.bloodBankAPos[0].available)
    const[OPosQuanity,setOPosQuanity] =useState(data && data.getBloodBankById.bloodBankOPos[0].quantity)
    const[OPosAvailable,setOPosAvailable] =useState(data && data.getBloodBankById.bloodBankOPos[0].available)
    const[ONegQuanity,setONegQuanity] =useState(data && data.getBloodBankById.bloodBankONeg[0].quantity)
    const[ONegAvailable,setONegAvailable] =useState(data && data.getBloodBankById.bloodBankONeg[0].available)
    const[ABPosQuanity,setABPosQuanity] =useState(data && data.getBloodBankById.bloodBankABPos[0].quantity)
    const[ABPosAvailable,setABPosAvailable] =useState(data && data.getBloodBankById.bloodBankABPos[0].available)
    const[ABNegQuanity,setABNegQuanity] =useState(data && data.getBloodBankById.bloodBankABNeg[0].quantity)
    const[ABNegAvailable,setABNegAvailable] =useState(data && data.getBloodBankById.bloodBankABNeg[0].available)
  
    if(data && stop === false){
setBPosAvailable(data && data.getBloodBankById.bloodBankBPos[0].available)
setBNegAvailable(data && data.getBloodBankById.bloodBankBNeg[0].available)
setANegAvailable(data && data.getBloodBankById.bloodBankANeg[0].available)
setAPosAvailable(data && data.getBloodBankById.bloodBankAPos[0].available)
setOPosAvailable(data && data.getBloodBankById.bloodBankOPos[0].available)
setONegAvailable(data && data.getBloodBankById.bloodBankONeg[0].available)
setABPosAvailable(data && data.getBloodBankById.bloodBankABPos[0].available)
setABNegAvailable(data && data.getBloodBankById.bloodBankABNeg[0].available)
setStop(true)
    }


  const[updateBloodBankOPos] =  useMutation(MUTATION_UPDATE_O_POS,{
    refetchQueries:[
      QUERY_BLOOD_BANK_BY_ID,
      "getBloodBankById" 
    ]
  })

  const[updateBloodBankONeg] =  useMutation(MUTATION_UPDATE_O_NEG,{
    refetchQueries:[
      QUERY_BLOOD_BANK_BY_ID,
      "getBloodBankById" 
    ]
  })
  
  const[updateBloodBankAPos] =  useMutation(MUTATION_UPDATE_A_POS,{
    refetchQueries:[
      QUERY_BLOOD_BANK_BY_ID,
      "getBloodBankById" 
    ]
  })

  const[updateBloodBankANeg] =  useMutation(MUTATION_UPDATE_A_NEG,{
    refetchQueries:[
      QUERY_BLOOD_BANK_BY_ID,
      "getBloodBankById" 
    ]
  })

  const[updateBloodBankBPos] =  useMutation(MUTATION_UPDATE_B_POS,{
    refetchQueries:[
      QUERY_BLOOD_BANK_BY_ID,
      "getBloodBankById" 
    ]
  })

  const[updateBloodBankBNeg] =  useMutation(MUTATION_UPDATE_B_NEG,{
    refetchQueries:[
      QUERY_BLOOD_BANK_BY_ID,
      "getBloodBankById" 
    ]
  })

  const[updateBloodBankABNeg] =  useMutation(MUTATION_UPDATE_AB_NEG,{
    refetchQueries:[
      QUERY_BLOOD_BANK_BY_ID,
      "getBloodBankById" 
    ]
  })

  const[updateBloodBankABPos] =  useMutation(MUTATION_UPDATE_AB_POS,{
    refetchQueries:[
      QUERY_BLOOD_BANK_BY_ID,
      "getBloodBankById" 
    ]
  })


  console.log("Blood Bank Id",data && data.getBloodBankById.id)
  console.log("Blood  Id",data && data.getBloodBankById.bloodBankBNeg[0].id)

    function handleBNeg(){
      updateBloodBankBNeg({
        variables:{
          "bloodBankId": `${data && data.getBloodBankById.id}`,
          "bloodId": `${data && data.getBloodBankById.bloodBankBNeg[0].id}`,
          "available": `${bNegAvailable}`
        }
      })
      handleBNegClose()

    }

    function handleBPos(){
      updateBloodBankBPos({
        variables:{
          "bloodBankId": `${data && data.getBloodBankById.id}`,
          "bloodId": `${data && data.getBloodBankById.bloodBankBPos[0].id}`,
          "available": `${bPosAvailable}`
        }
      })
      handleBPosClose()
    }

    function handleANeg(){
      updateBloodBankANeg({
        variables:{
          "bloodBankId": `${data && data.getBloodBankById.id}`,
          "bloodId": `${data && data.getBloodBankById.bloodBankANeg[0].id}`,
          "available": `${ANegAvailable}`
        }
      })
      handleANegClose()
    }

    function handleAPos(){
      updateBloodBankAPos({
        variables:{
          "bloodBankId": `${data && data.getBloodBankById.id}`,
          "bloodId":`${data && data.getBloodBankById.bloodBankAPos[0].id}`,
          "available": `${APosAvailable}`
        }
      })
      handleAPosClose()
    }

    console.log("io",data && data.getBloodBankById.bloodBankONeg[0].id)

    function handleONeg(){
      updateBloodBankONeg({
        variables:{
          "bloodBankId": `${data && data.getBloodBankById.id}`,
          "bloodId": `${data && data.getBloodBankById.bloodBankONeg[0].id}`,
         "available": `${ONegAvailable}`
        }
      
      })
      handleONegClose()
    }

    function handleOPos(){
      updateBloodBankOPos({
        variables:{
          "bloodBankId": `${data && data.getBloodBankById.id}`,
          "bloodId": `${data && data.getBloodBankById.bloodBankOPos[0].id}`,
          "available": `${OPosAvailable}`
        }
      })
      handleOPosClose()
    }

    function handleABPos(){
      updateBloodBankABPos({
        variables:{
          "bloodBankId": `${data && data.getBloodBankById.id}`,
          "bloodId": `${data && data.getBloodBankById.bloodBankABPos[0].id}`,
          "available": `${ABPosAvailable}`
        }
      })
      handleABPosClose()
    }

    function handleABNeg(){
      updateBloodBankABNeg({
        variables:{
          "bloodBankId": `${data && data.getBloodBankById.id}`,
          "bloodId": `${data && data.getBloodBankById.bloodBankABNeg[0].id}`,
          "available": `${ABNegAvailable}`
        }
      })
      handleABNegClose()
    }


    

  return (
    <>
     <div >
                <div className="navigation">
                    <br></br> 
                  
                <span className="title" style={{ fontSize: 20, fontFamily: 'DM Sans', fontWeight: 'bold',color:"#fff",textAlign:"center",marginLeft:20,marginTop:20 }}>Dashboard</span>
                    <ul>
                        <br></br><br></br><br></br>
                       
                        <li>
                        <LinkContainer to="/">
                            <a href="#">
                                <span className="icon">

                                </span>
                                <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }} eventKey="first"><FaHome /> Home</span>
                              
                            </a>
                            </LinkContainer>
                        </li>
                        <li>
                        <LinkContainer to="/BloodBankProfile">
                            <a href="#">
                                <span className="icon">
                                </span>
                                <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }}><FaUsers /> Profile</span>
                            </a>
                            </LinkContainer>
                        </li>
                        <li>
                        <LinkContainer to="/EditBloodBank">
                            <a href="#">
                                <span className="icon"> </span>
                                <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }}><FaPenSquare /> Edit Blood Bank</span>
                            </a>
                            </LinkContainer>
                        </li>
                        <li>
                        <LinkContainer to="/Termcondition">
                            <a href="#">
                                <span className="icon"> </span>
                                <span className="title" style={{ fontSize: 12, fontFamily: 'DM Sans' }}><FaInfoCircle /> Terms & Condition</span>
                            </a>
                            </LinkContainer>
                        </li>
                       
                       
                    </ul>
                    
                </div>
                

                <div className="main">
                    <div className="topbar">
                        <div>
                            <FaAlignJustify onClick={() => handleMenu()}  className="toggle"/>
                        </div>
                        <div className="search">
                            <label>
                                <input type="text" placeholder="Search here" />
                            </label>
                        </div>
                        <div className="user">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/2048px-Circle-icons-profile.svg.png" alt="" />
                        </div>
                    </div>
                    
                    <Container>
        <Row style={{marginTop:50}}>
           <Col md={3}>
           <Card>
            <h6 style={{textAlign:"center",marginTop:10}}> B Positve</h6>
            <p style={{textAlign:"center"}}>Available :{data && data.getBloodBankById.bloodBankBPos[0].available}</p>
             <Button onClick={handleBPosShow}> Update</Button>
           </Card>
           </Col>
           <Col md={3}>
           <Card>
            <h6 style={{textAlign:"center",marginTop:10}}> B Negative</h6>
            <p style={{textAlign:"center"}}>Available :{data && data.getBloodBankById.bloodBankBNeg[0].available}</p>
             <Button   onClick={handleBNegShow}> update</Button>
           </Card>
           </Col>
           <Col md={3}>
           <Card>
            <h6 style={{textAlign:"center",marginTop:10}}> A Positve</h6>
            <p style={{textAlign:"center"}}>Available :{data && data.getBloodBankById.bloodBankAPos[0].available}</p>
             <Button onClick={handleAPosShow}> Update</Button>
           </Card>
           </Col>

           <Col md={3}>
           <Card>
            <h6 style={{textAlign:"center",marginTop:10}}> A Negative</h6>
            <p style={{textAlign:"center"}}>Available :{data && data.getBloodBankById.bloodBankANeg[0].available}</p>
             <Button onClick={handleANegShow}> Update</Button>
           </Card>
           
           </Col>

           <Col md={3}>
           <Card>
            <h6 style={{textAlign:"center",marginTop:10}}> O Positve</h6>
            <p style={{textAlign:"center"}}>Available :{data && data.getBloodBankById.bloodBankOPos[0].available}</p>
             <Button onClick={handleOPosShow}> Update</Button>
           </Card>
           
           </Col>

           <Col md={3}>
           <Card>
            <h6 style={{textAlign:"center",marginTop:10}}>O Negative</h6>
            <p style={{textAlign:"center"}}>Available :{data && data.getBloodBankById.bloodBankONeg[0].available}</p>
             <Button onClick={handleONegShow}> Update</Button>
           </Card>
           </Col>

           <Col md={3}>
           <Card>
            <h6 style={{textAlign:"center",marginTop:10}}>AB Positve</h6>
            <p style={{textAlign:"center"}}>Available :{data && data.getBloodBankById.bloodBankABPos[0].available}</p>
             <Button  onClick={handleABPosShow}> Update</Button>
           </Card>
           </Col>

           <Col md={3}>
           <Card>
            <h6 style={{textAlign:"center",marginTop:10}}>AB Negative</h6>
            <p style={{textAlign:"center"}}>Available :{data && data.getBloodBankById.bloodBankABNeg[0].available}</p>
             <Button  onClick={handleABNegShow}> Update</Button>
           </Card>
           </Col>

        </Row>
    </Container>
    <Modal
        show={showBPos}
        onHide={handleBPosClose}
        keyboard={false}
      >
        <div  style={{width:"200px"}} className ="mx-auto d-block">
        <h6 style={{textAlign:"center"}}>B Positive</h6>
       



        <input placeholder='Enter Available'style={{marginTop:10}} onChange={(e)=>setBPosAvailable(e.target.value)} value={bPosAvailable} />
        

        <Button className ="mx-auto d-block" style={{marginTop:10,marginBottom:10}}  onClick={()=>handleBPos()}>Update Now</Button>
        </div>
       
      </Modal>

      <Modal
        show={showBNeg}
        onHide={handleBNegClose}
        keyboard={false}
      >
         <div  style={{width:"200px"}} className ="mx-auto d-block">
        <h6 style={{textAlign:"center"}}>B Negative</h6>
      
       

        <input placeholder='Enter Available' style={{marginTop:10}}  onChange={(e)=>setBNegAvailable(e.target.value)} value={bNegAvailable}/>

        <Button   className ="mx-auto d-block" style={{marginTop:10,marginBottom:10}} onClick={()=>handleBNeg()}>Update Now</Button>
       
        </div>
      </Modal>

      <Modal
        show={showANeg}
        onHide={handleANegClose}
        keyboard={false}
      >
         <div  style={{width:"200px"}} className ="mx-auto d-block">
        <h6 style={{textAlign:"center"}}>A Negative</h6>
       

       

        <input placeholder='Enter Available'style={{marginTop:10}}   onChange={(e)=>setANegAvailable(e.target.value)} value={ANegAvailable}/>

        <Button  className ="mx-auto d-block" style={{marginTop:10,marginBottom:10}} onClick={()=>handleANeg()}>Update Now</Button>
        </div>
       
      </Modal>

      <Modal
        show={showAPos}
        onHide={handleAPosClose}
        keyboard={false}
      >
       <div  style={{width:"200px"}} className ="mx-auto d-block">
        <h6 style={{textAlign:"center"}}>A Positive</h6>
       

     

        <input placeholder='Enter Available'style={{marginTop:10}}  onChange={(e)=>setAPosAvailable(e.target.value)} value={APosAvailable} />

        <Button  className ="mx-auto d-block" style={{marginTop:10,marginBottom:10}} onClick={()=>handleAPos()}>Update Now</Button>
        </div>
       
      </Modal>

      <Modal
        show={shoONeg}
        onHide={handleONegClose}
        keyboard={false}
      >
        <div  style={{width:"200px"}} className ="mx-auto d-block">
        <h6 style={{textAlign:"center"}}>O Negative</h6>
       

       

        <input placeholder='Enter Available'style={{marginTop:10}}  onChange={(e)=>setONegAvailable(e.target.value)} value={ONegAvailable}/>

        <Button  className ="mx-auto d-block" style={{marginTop:10,marginBottom:10}} onClick={()=>handleONeg()}>Update Now</Button>
        </div>
       
      </Modal>

      <Modal
        show={showOPos}
        onHide={handleOPosClose}
        keyboard={false}
      >
        <div  style={{width:"200px"}} className ="mx-auto d-block">
        <h6 style={{textAlign:"center"}}>O Positve</h6>
       

       

        <input placeholder='Enter Available'style={{marginTop:10}}   onChange={(e)=>setOPosAvailable(e.target.value)} value={OPosAvailable}/>

        <Button  className ="mx-auto d-block" style={{marginTop:10,marginBottom:10}}  onClick={()=>handleOPos()}>Update Now</Button>
        </div>
       
      </Modal>

      <Modal
        show={showABPos}
        onHide={handleABPosClose}
        keyboard={false}
      >
         <div  style={{width:"200px"}} className ="mx-auto d-block">
        <h6 style={{textAlign:"center"}}>AB Positve</h6>
       

      

        <input placeholder='Enter Available'style={{marginTop:10}} onChange={(e)=>setABPosAvailable(e.target.value)} value={ABPosAvailable}/>

        <Button  className ="mx-auto d-block" style={{marginTop:10,marginBottom:10}} onClick={()=>handleABPos()}>Update Now</Button>
        </div>
       
      </Modal>

      <Modal
        show={shoABNeg}
        onHide={handleABNegClose}
        keyboard={false}
      >
         <div  style={{width:"200px"}} className ="mx-auto d-block">
        <h6 style={{textAlign:"center"}}>AB Negative</h6>
       

        <input placeholder='Enter Available' style={{marginTop:10}} onChange={(e)=>setABNegAvailable(e.target.value)} value={ABNegAvailable}/>

        <Button  className ="mx-auto d-block" style={{marginTop:10,marginBottom:10}} onClick={()=>handleABNeg()}>Update Now</Button>
        </div>
       
      </Modal>
                   
                </div>
              
            </div>
   
    
    </>
  )
}