import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { Table, Row, Col, InputGroup, Form, Button, Modal, Container, Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { FaRetweet, FaEye } from "react-icons/fa";
import Moment from 'react-moment';

import swal from 'sweetalert';

import { QUERY_SLOT_BOOKING_BY_VENDOR_ID } from '../../../graphql/queries';
import Navigation from '../../Navigation';


export default function RadiologyAllBooking() {


  const { id } = useParams();


  const [validated, setValidated] = useState(false);

  const [getOtp, setGetOTP] = useState()
  const [getId, setGetId] = useState()


  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);



  const [contact, setContact] = useState("")
  const [genOtp, setGenotp] = useState("")

  // send otp


  let count = 1;

  const [show, setShow] = useState(false);
  const [getItem, setGetItem] = useState()

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);






  const { data, loading } = useQuery(QUERY_SLOT_BOOKING_BY_VENDOR_ID, {
    variables: {
      "vendorId": `${id}`
    },
    pollInterval: 500,


  })










  return (
    <>
      <Navigation />
      <br></br>  <br></br>  <br></br><br></br>
      <h1 style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}>Slot Bookings</h1>
      <Row>
        <Col md={4}></Col>
        <Col md={4}></Col>
        <Col md={4}>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Serach..."
              aria-label="Recipient's username"
              style={{ fontFamily: "Dm sans" }}
            />
            <InputGroup.Text id="basic-addon2" style={{ fontFamily: "Dm sans" }}>Search</InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
      {
        loading ?
          <div className='mx-auto d-block'>
            <Spinner animation="border" role="status"></Spinner>
          </div>

          :
          <>
            <Table striped bordered hover style={{ fontFamily: "Dm sans", fontSize: 12 }} responsive>
              <thead>
                <tr>
                  <th>SR No.</th>
                  <th>Center Name</th>
                  <th>Booking Date</th>
                  <th>Slot Time</th>
                  <th>User Name</th>
                  <th>User Contact</th>
                  <th>User discount </th>
                  <th>Vendor discount </th>
                  <th>User Price </th>
                  <th>Vendor Price </th>
                  <th>Payment Type</th>
                  <th>Payment Type</th>
                  <th>Total Amount</th>
                  <th>Status</th>
                  <th>Refund Status</th>
                  <th>Refund</th>
                </tr>
              </thead>
              <tbody>
                {
                  data && data.getAllSlotBookingByVendorId.map(item => {

                    return (
                      <tr>
                        <td>{count++}</td>
                        <td>{item.venderName}</td>
                        <td><Moment format='DD/MM/YYYY'>{item.bookingDate}</Moment></td>
                        <td>{item.slotTime}</td>
                        <td>{item.userName}</td>
                        <td>{item.userContact}</td>
                        <td>{item.userDiscount}%</td>
                        <td>{item.vendorDiscount}%</td>
                        <td>₹{item.userPrice}</td>
                        <td>₹{item.vendorPrice}</td>
                        <td>{item.paymentType}</td>
                        <td>₹{item.totalAmount}</td>
                        <td>{item.status}</td>
                        {
                          item.status === 'complete' ?
                            <td style={{ color: 'red' }}>Date Pass</td>
                            :
                            <td style={{ color: 'green' }}>Refund Available</td>
                        }
                        {
                          item.status === 'complete' ?
                            <td><Button variant='warning' size="sm" disabled><FaRetweet /></Button></td>
                            :
                            <td><Button variant='warning' size="sm"><FaRetweet /></Button></td>
                        }
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          </>
      }


      <Modal show={show} onHide={handleClose} size="lg">
        <Container>
          <h6 style={{ textAlign: "center" }}>{getItem && getItem.venderName}</h6>

          <Row>
            <Col md={6}>
              <h4 style={{ textAlign: "center" }}>User Information</h4>
              <hr></hr>
              <h6 style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 15 }}>Name : {getItem && getItem.userName}</h6>
              <h6 style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 15 }}>Contact : {getItem && getItem.userContact}</h6>
              <h6 style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 15 }}>Email : {getItem && getItem.userEmail}</h6>
              <h6 style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 15 }}> Discount : {getItem && getItem.userDiscount}</h6>
              <h6 style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 15 }}> Payment Type : {getItem && getItem.paymentType}</h6>
              <h6 style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 15 }}> Price : {getItem && getItem.totalAmount}</h6>
              <h6 style={{ fontFamily: "Dm sans", fontWeight: "bold" }}>Service</h6>
              <ul>
                {
                  getItem && getItem.service.map(list => {

                    return (
                      <li style={{ fontFamily: "Dm sans", fontSize: 13 }}>Service : {list.serviceName}</li>

                    )
                  })

                }
              </ul>
            </Col>
            <Col md={6}>
              <h4 style={{ textAlign: "center" }}>Booking Information</h4>
              <hr></hr>
              <h6 style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 15 }}>Booking Date : {getItem && getItem.bookingDate}</h6>
              <h6 style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 15 }}>Slot Time : {getItem && getItem.slotTime}</h6>

            </Col>

          </Row>
        </Container>

      </Modal>

    </>
  )
}
