import React from 'react';
import { Row, Col } from 'react-bootstrap';

const ViewHospital = ({ hospital }) => {
  return (
    <div>
      <h4 className="mt-1" style={{ fontFamily: 'DM Sans' }}>
        Hospital Details
        <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '15%', marginTop: 10 }}>
          {' '}
        </div>
      </h4>
      <br />
      <Row>
        <Col md={6}>
          <p>Name: <small>{hospital.hospitalName}</small>{' '}</p>
        </Col>
        <Col md={6}>
          <p>
            Type: <small>{hospital.hospitalType}</small>{' '}
          </p>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <p>
            Accreditation: <small>{hospital.hospitalAccreditation}</small>{' '}
          </p>{' '}
        </Col>
        <Col md={6}>
          <p>
            Health Care Provider Type:{' '}
            <small>{hospital.hospitalHealthCareProviderType}</small>{' '}
          </p>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <p>
            Registration Number:{' '}
            <small>{hospital.hospitalRegistrationNumber}</small>{' '}
          </p>{' '}
        </Col>
        <Col md={6}>
          <p>
            Address: <small>{hospital.hospitalAddress}</small>{' '}
          </p>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <p>
            City: <small>{hospital.hospitalCity}</small>{' '}
          </p>{' '}
        </Col>
        <Col md={6}>
          <p>
            District: <small>{hospital.hospitalDistrict}</small>{' '}
          </p>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <p>
            State: <small>{hospital.hospitalState}</small>{' '}
          </p>{' '}
        </Col>
        <Col md={6}>
          <p>
            Pincode: <small>{hospital.hospitalPincode}</small>{' '}
          </p>
        </Col>
      </Row>

      <h3 className="mt-3" style={{ fontFamily: 'DM Sans' }}>
        Nodal Person Details
        <div
          style={{
            backgroundColor: '#ffb606 ',
            height: 2,
            width: '15%',
            marginTop: 10,
          }}
        >
          {' '}
        </div>
      </h3>

      <Row>
        <Col md={6}>
          <p>
            Person Name: <small>{hospital.nodalPersonName}</small>{' '}
          </p>{' '}
        </Col>
        <Col md={6}>
          <p>
            Person Designation: <small>{hospital.nodalPersonDesignation}</small>{' '}
          </p>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <p>
            Person Contact: <small>{hospital.nodalPersonContact}</small>{' '}
          </p>{' '}
        </Col>
        <Col md={6}>
          <p>
            Person Email: <small>{hospital.nodalPersonEmail}</small>{' '}
          </p>
        </Col>
      </Row>


      <h3 className="mt-3" style={{ fontFamily: 'DM Sans' }}>
        Hospital Additional Details
        <div
          style={{
            backgroundColor: '#ffb606 ',
            height: 2,
            width: '15%',
            marginTop: 10,
          }}
        >
          {' '}
        </div>
      </h3>

      <Row>
        <Col md={6}>
          <p>
            Help desk Contact: <small>{hospital.hospitalHelpdeskContact}</small>{' '}
          </p>{' '}
        </Col>
        <Col md={6}>
          <p>
            Emergency Contact: <small>{hospital.hospitalEmergencyContact}</small>{' '}
          </p>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <p>
            Ambulance Contact: <small>{hospital.hospitalAmbulanceContact}</small>{' '}
          </p>{' '}
        </Col>
        <Col md={6}>
          <p>
            Fax Number: <small>{hospital.hospitalFaxNumber}</small>{' '}
          </p>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <p>
            Blood Bank Contact: <small>{hospital.hospitalBloodBankContact}</small>{' '}
          </p>{' '}
        </Col>
        <Col md={6}>
          <p>
            Hospital Email: <small>{hospital.hospitalEmail}</small>{' '}
          </p>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <p>
            Website: <small>{hospital.hospitalWebsiteLink}</small>{' '}
          </p>{' '}
        </Col>
        <Col md={6}>
          <p>
            Established Date: <small>{hospital.hospitalEstablishedDate}</small>{' '}
          </p>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <p>
            Latitude: <small>{hospital.hospitalLatitude}</small>{' '}
          </p>{' '}
        </Col>
        <Col md={6}>
          <p>
            Longitude: <small>{hospital.hospitalLongitude}</small>{' '}
          </p>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <p>
            No Of Doctors: <small>{hospital.noOfDoctors}</small>{' '}
          </p>{' '}
        </Col>
        <Col md={6}>
          <p>
            No Of Medical Consultants: <small>{hospital.noOfMedicalConsultants}</small>{' '}
          </p>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <p>
            No Of Nurses: <small>{hospital.noOfNurses}</small>{' '}
          </p>{' '}
        </Col>
        <Col md={6}>
          <p>
            No Of Beds: <small>{hospital.noOfBeds}</small>{' '}
          </p>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <p>
            No Of Private Beds: <small>{hospital.noOfPrivateBeds}</small>{' '}
          </p>{' '}
        </Col>
        <Col md={6}>
          <p>
            No Of Emergency Beds: <small>{hospital.noOfEmergencyBeds}</small>{' '}
          </p>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <p>
            is Emergency Service Available: <small>{hospital.isEmergencyServiceAvailable}</small>{' '}
          </p>{' '}
        </Col>
        <Col md={6}>
          <p>
            is Ambulance Service Available: <small>{hospital.isAmbulanceServiceAvailable}</small>{' '}
          </p>
        </Col>
      </Row>


    </div>
  );
};

export default ViewHospital;
