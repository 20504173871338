import React, { useState } from 'react';
import '../../ComponentCss/Setting.css' // Import the CSS file
import Navigation from '../Navigation';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {UPDATE_ADMIN_PASSWORD} from '../../graphql/mutations';
import{GET_USER_SETTING} from '../../graphql/queries';
const Setting = () => {
  const adminID = localStorage.getItem("superAdminId")

  const [ adminId, setadminId] = useState('');
  const [ adminPassword, setadminPassword] = useState('');
  
  const [updatePasswordMutation] = useMutation(UPDATE_ADMIN_PASSWORD);

  const handleCurrentPasswordChange = (e) => {
    setadminId(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setadminPassword(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
  
  try {
      const { data } = await updatePasswordMutation({
        variables: {
          adminId:`${adminID}`,
          adminPassword:`${adminPassword}`,
        },
      });

      console.log(data);
    } catch (error) {
     
      console.error(error);
    }
  
    
    setadminId('');
    setadminPassword('');

  };
  

  return (
    <div className="update-password-container">
      <h2>Update Password</h2>

      <form onSubmit={handleFormSubmit}>
        {/* <div className="form-group">
          <label htmlFor="current-password">Current Password:</label>
          <input
            type="password"
            id="current-password"
            value={adminId}
            onChange={(e)=>handleCurrentPasswordChange(e)}
          />
        </div> */}
        <div className="form-group">
          <label htmlFor="new-password">New Password:</label>
          <input
            type="password"
            id="new-password"
            value={adminPassword}
            onChange={handleNewPasswordChange}
          />
        </div>
        {/* <div className="form-group">
          <label htmlFor="confirm-password">Confirm Password:</label>
          <input
            type="password"
            id="confirm-password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
          />
        </div> */}
        <button type="submit">Update Password</button>
      </form>
    </div>
  );
};

export default  Setting;
