import React, { useState, useEffect } from 'react'
import Navigation from '../Navigation'
import { Container, Form, Button, Card, Row, Col, Spinner,Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { SIGNIN_HOSPITAL } from '../../graphql/mutations';
import swal from 'sweetalert'
import { BiBorderAll } from 'react-icons/bi';

export default function HospitalLogin() {

    const navigate = useNavigate();
    const [registrationNumber, setRegistrationNumber] = useState('');
    const [password, setPassword] = useState('');
    const [validated, setValidated] = useState(false);

    const [signinHospitalAdmin, { loading }] = useMutation(SIGNIN_HOSPITAL, {
        variables: { signinHospitalAdminInput: { registrationNumber, password } },
        onCompleted: ({ signinHospitalAdmin }) => {
            localStorage.setItem('hospitalId', signinHospitalAdmin.hospitalId);
            localStorage.setItem('hospitalToken', signinHospitalAdmin.hospitalToken);
            navigate('/hospitalDashboard');
        },
        onError: (e) => {
            swal({ title: "Error!!!", text: "Username & Password Not Match", icon: "error" });
        },
    });

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            setValidated(false);
            signinHospitalAdmin();
        }
    };

    useEffect(() => {
        if (localStorage.getItem('hospitalToken')) {
            navigate('/hospitalDashboard');
        }
    }, [navigate]);

    return (
        <>
            <Navigation />
            <Container style={{ marginTop: '150px' }}>
            <Card className="p-5" style={{ boxShadow: '5px 5px 5px 5px #ecf0f1' ,border:"2px solid #0097e6" }}>
                <Row>
                   <Col md={6}>
                    
                    <Image src="https://img.freepik.com/free-vector/people-walking-sitting-hospital-building-city-clinic-glass-exterior-flat-vector-illustration-medical-help-emergency-architecture-healthcare-concept_74855-10130.jpg?w=2000" style={{width:430,height:300}} className="img-fluid" alt="Responsive image" />
                   
                   </Col>
                    <Col md="6" className='mx-auto d-block'>
                        
                            <h1 className="mb-5" style={{ fontFamily: 'DM Sans', fontWeight: 'bold' }}>Hospital Login</h1>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="registrationNumber">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '15px' }}>Registration Number</Form.Label>
                                    <Form.Control style={{ fontFamily: 'DM Sans', fontSize: '15px' }} required type="text" placeholder="Registration Number" onChange={(e) => setRegistrationNumber(e.target.value)} value={registrationNumber} />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="password">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '15px' }}>Password</Form.Label>
                                    <Form.Control style={{ fontFamily: 'DM Sans', fontSize: '15px' }} required type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} value={password} />
                                </Form.Group>
                                {
                                    loading ?
                                        <Button variant="primary" style={{ fontFamily: 'DM Sans', fontSize: '15px' }} disabled>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span className="visually-hidden">Loading...</span>
                                        </Button>
                                        :
                                        <Button type="submit" style={{ fontFamily: 'DM Sans', fontSize: '15px' }}>Login</Button>
                                }
                            </Form>
                       
                    </Col>
                </Row>
                </Card>

            </Container>
        </>
    )
}
