import React,{useState,useEffect} from 'react'
import { Container, Row, Col, Image, Table, Button,Carousel,Form,Modal } from 'react-bootstrap'
import Navigation from "../../Navigation"
import patholoy from "../../../Images/pathology.png"
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelopeOpen, FaUserAlt } from 'react-icons/fa'
import { QUERY_GET_PATHOLOGY_BY_ID } from '../../../graphql/queries'
import { useJsApiLoader, GoogleMap, MarkerF } from '@react-google-maps/api'
import { MUTATION_UPDATE_PATHOLOGY } from '../../../graphql/mutations'

export default function PathologyDetail() {

   const [index, setIndex] = useState(0);

   const [show, setShow] = useState(false);

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   

   const handleSelect = (selectedIndex, e) => {
     setIndex(selectedIndex);
   };
   const navigate = useNavigate();
   let count = 1

   const [center, setCenter] = useState({ lat: 23.8388, lng: 78.7378 })
   const { id } = useParams()

   const { data } = useQuery(QUERY_GET_PATHOLOGY_BY_ID, {
      variables: {
         "pathologyId": `${id}`
      }
   })

   const[bharpdis,setBhaprDis] =useState(data && data.getPathologyById.bharpDiscount)
   const[vendorMar,setVendorMar] =useState(data && data.getPathologyById.vendorMargin)
  
   const[updatePathology] =useMutation(MUTATION_UPDATE_PATHOLOGY,{
      refetchQueries:[
         QUERY_GET_PATHOLOGY_BY_ID,
         "getPathologyById"
     ]
    })

   function handleChange(){
      updatePathology({
       variables:{
         "updatePathologyInput": {
            "pathologyId": `${data && data.getPathologyById.id}`,
            "bharpDiscount": `${bharpdis}`,
            "vendorMargin": `${vendorMar}`
          } 
       }
      })
      handleClose()
   }

    
    useEffect(() => {
      if (data) {
        setCenter({ lat: parseFloat(data.getPathologyById.latitude), lng: parseFloat(data.getPathologyById.longitude) })
      }
    }, [data]);

    const { isLoaded } = useJsApiLoader({
      googleMapsApiKey: 'AIzaSyB2eRRn8bW1VVh4wj4AEaYd3F_RgEP2mE4'
    })
  
    if (!isLoaded) {
      return <h1>Loading</h1>
    }



    


   console.log("data", data)

    


   function handleClick(id){
      navigate('/ViewPathologySlot/' + id)
   }

  



   
   return (
      <div style={{ background: "#00a8ff" }}>
         <Navigation />
         <br></br> <br></br><br></br> <br></br><br></br>
         <Container style={{ background: "#fff", marginBottom: 20, borderRadius: 10 }}>
            <Row>
               <h5 style={{ textAlign: "center", marginTop: "10px", fontFamily: "Dm sans", fontWeight: "800" }}>Pathology Detail</h5>
               <hr></hr>

               <Col md={6}>    
               <Carousel activeIndex={index} onSelect={handleSelect}>
                  {
                      data && data.getPathologyById.picture.map(item =>{
                     return(
                        <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={`https://bharp-hospital.s3.ap-south-1.amazonaws.com/${data && data.getPathologyById.item}`}
                          alt="First slide"
                        />
                       
                      </Carousel.Item>
                     )
                      }

                      )                    

                  }
                 </Carousel>


                
               </Col>
               <Col md={6}>
                  <h6 style={{ textAlign: "center", fontFamily: "Dm sans", fontWeight: "600" }}>{data && data.getPathologyById.nameOfCenter}</h6>
                  <h6 style={{ textAlign: "center", fontFamily: "Dm sans", fontWeight: "600", color: "#3498db" }}>Registration No : {data && data.getPathologyById.registrationNo}</h6>
                  <br></br>

                  <Row style={{ margin: 10 }}>
                     <Col md={6} style={{ border: "2px solid #3498db " }}>
                        <p style={{ textAlign: "center", color: "#000", fontSize: 12, fontFamily: "Dm sans", fontWeight: 600, marginTop: 10 }}>Public Sector</p>
                        {
                           data && data.getPathologyById.publicSectorOther === "" ?
                              <h6 style={{ textAlign: "center", color: "#3498db", }}>{data && data.getPathologyById.privateSectorCentralGov}</h6> :
                              <h6 style={{ textAlign: "center", color: "#3498db", }}>{data && data.getPathologyById.publicSectorOther}</h6>
                        }
                     </Col>
                     <Col md={6} style={{ border: "2px solid #3498db " }}>
                        <p style={{ textAlign: "center", color: "#000", fontSize: 12, fontFamily: "Dm sans", fontWeight: 600, marginTop: 10 }}>Private Sector Individual Proprietorship</p>
                        {
                           data && data.getPathologyById.privateSectorOther === "" ?
                              <h6 style={{ textAlign: "center", color: "#3498db", }}>{data && data.getPathologyById.privateSectorIndividual}</h6> :
                              <h6 style={{ textAlign: "center", color: "#3498db", }}>{data && data.getPathologyById.privateSectorOther}</h6>
                        }
                     </Col>
                  </Row>

                  <p styl={{ fontFamily: "Dm sans" }}><FaEnvelopeOpen style={{ color: "#3498db", marginRight: 10 }} />{data && data.getPathologyById.email}</p>
                  <p style={{ fontFamily: "Dm sans" }}><FaPhoneAlt style={{ color: "#3498db", marginRight: 10 }} />{data && data.getPathologyById.contact}</p>
                  <p style={{ fontFamily: "Dm sans" }}><FaMapMarkerAlt style={{ color: "#3498db", marginRight: 10 }} />{data && data.getPathologyById.centerAddress} {data && data.getPathologyById.villageName} {data && data.getPathologyById.district
                  }
                     {data && data.getPathologyById.pincode} {data && data.getPathologyById.state}
                  </p>
                  <a href={data && data.getPathologyById.websiteLink} style={{ fontSize: 12, textDecoration: "none" }}  >Website Line Here</a>               </Col>
            </Row>

            <hr></hr>
            <Row>
               <Col>
                  <h6 style={{ fontFamily: "Dm sans", textAlign: "center", fontWeight: "800" }}>Owner Detail</h6>
                  <p style={{ fontFamily: "Dm sans" }}><FaUserAlt style={{ color: "#3498db", marginRight: 10 }} />{data && data.getPathologyById.ownerName}</p>
                  <p styl={{ fontFamily: "Dm sans" }}><FaEnvelopeOpen style={{ color: "#3498db", marginRight: 10 }} />{data && data.getPathologyById.ownerEmail}</p>
                  <p style={{ fontFamily: "Dm sans" }}><FaPhoneAlt style={{ color: "#3498db", marginRight: 10 }} />{data && data.getPathologyById.ownerContact}</p>
                  <Button style={{fontFamily:"Dm sans",border:"none"}} onClick={()=>handleClick(data && data.getPathologyById.id)}>View Slot </Button>

                    <br></br><br></br>
                  <p style={{ fontFamily: "Dm sans" }}> Bharp Discount : {data && data.getPathologyById.bharpDiscount} %</p>
                  <p styl={{ fontFamily: "Dm sans" }}> Vendor Margin : {data && data.getPathologyById.vendorMargin} %</p>
                  <Button style={{fontFamily:"Dm sans",border:"none"}} onClick={()=>handleShow()}>Change </Button>


               </Col>
               <Col>
                  <Row>
                     <Col md={6}>
                        <h6 style={{ fontFamily: "Dm sans", textAlign: "center", fontWeight: "800" }}>Licence By</h6>
                        {
                           data && data.getPathologyById.lisenceBy.map(lisence =>
                              <ul>
                                 <li style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>{lisence}</li>
                              </ul>
                           )
                        }
                     </Col>
                     <Col md={6}>
                        <h6 style={{ fontFamily: "Dm sans", textAlign: "center", fontWeight: "800" }}>Services</h6>
                        {
                           data && data.getPathologyById.services.map(service =>
                              <ul>
                                 <li style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>{service.serviceName} ({service.servicePrice}/-)</li>
                              </ul>
                           )
                        }
                     </Col>

                  </Row>

               </Col>
            </Row>
            <h1 style={{ marginTop: '25px', fontFamily: 'DM Sans', fontSize: '20px', fontWeight: 'bold', textAlign: 'center' }}>Pathologist Details</h1>
            <hr />
            <Table responsive >
               <thead style={{ fontFamily: "Dm sans", fontSize: 12 }}>
                  <tr>
                     <th>SR No</th>
                     <th> Name</th>
                     <th>Qualification</th>
                     <th>Experience</th>
                     <th>Picture</th>
                  </tr>
               </thead>
               <tbody style={{ fontFamily: "Dm sans", fontSize: 12 }}>

                  {
                     data && data.getPathologyById.pathologistDetail.map(detail => {
                        return (
                           <tr>
                              <td>{count++}</td>
                              <td>{detail.name}</td>
                              <td>{detail.qualification}</td>
                              <td>{detail.experience}</td>
                              <td><Image src={detail.picture} style={{ width: 200 }} /></td>
                           </tr>

                        )

                     })



                  }



               </tbody>
            </Table>
               <Row>
                  <Col md={12}>
                  <GoogleMap center={center} zoom={15} mapContainerStyle={{ width: '100%', height: '200px' }}>
              <MarkerF position={center} />
            </GoogleMap>
                  </Col>



               </Row>

         </Container>

         <Modal show={show} onHide={handleClose}>
            <div style={{margin:20}}>
               <label style={{fontWeight:"bold"}}>Bharp Discount</label>
        <input  placeholder='Bharp Discount' className='mx-auto d-block' onChange={(e)=>setBhaprDis(e.target.value)}  value={bharpdis}/>
        <br></br>
        <label  style={{fontWeight:"bold"}}>Vendor Margin</label>
        <input placeholder='Vendor Margin'className='mx-auto d-block' onChange={(e)=>setVendorMar(e.target.value)}  value={vendorMar}/>
        </div>
        <Button style={{border:"none"}} onClick={()=>handleChange()}>Change Now</Button>
      </Modal>
      </div>
   )
}
