import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import Home from './Components/Home';

import SuperAdminLogin from './Components/SuperAdmin/SuperAdminLogin';
import SuperAdminDashboard from './Components/SuperAdmin/SuperAdminDashboard';
import HospitalDetail from './Components/SuperAdmin/hospital/HospitalDetail';
import SuperAdminAddHospital from './Components/SuperAdmin/hospital/AddHospital';
import SuperAdminAllHospital from './Components/SuperAdmin/hospital/AllHospital';
import SuperAdminAddAgent from './Components/SuperAdmin/agent/AddAgent';
import SuperAdminAllAgent from './Components/SuperAdmin/agent/AllAgents';
import SuperAdminHospitalRequest from './Components/SuperAdmin/hospital/HospitalRequest';
import SuperAdminActiveHospital from './Components/SuperAdmin/hospital/HospitalActive';
import SuperAdminRejectHospital from './Components/SuperAdmin/hospital/HospitalReject';
import SuperAdminAddPathology from './Components/SuperAdmin/pathology/AddPathology';
import SuperAdminPathologyRequest from './Components/SuperAdmin/pathology/PathologyRequest';
import SuperAdminActivePathology from './Components/SuperAdmin/pathology/ActivePathology';
import SuperAdminRejectPathology from './Components/SuperAdmin/pathology/RejectPathology';
import SuperAdminAddRadiology from './Components/SuperAdmin/radiology/AddRadiology';
import SuperAdminRadiologyRequest from './Components/SuperAdmin/radiology/RadiologyRequest';
import SuperAdminActiveRadiology from './Components/SuperAdmin/radiology/ActiveRadiology';
import SuperAdminRejectRadiology from './Components/SuperAdmin/radiology/RejectRadiology';
import PathologyDetail from './Components/SuperAdmin/pathology/PathologyDetail';
import RadiologyDetail from './Components/SuperAdmin/radiology/RadiologyDetail';
import AmbulanceDetail from './Components/SuperAdmin/ambulance/AmbulanceDetail';
import BloodBankDetail from './Components/SuperAdmin/bloodBank/BloodBankDetail';
import SuperAdminAddAmbulance from './Components/SuperAdmin/ambulance/AddAmbulance';
import SuperAdminAmbulanceRequest from './Components/SuperAdmin/ambulance/AmbulanceRequest';
import SuperAdminActiveAmbulance from './Components/SuperAdmin/ambulance/ActiveAmbulance';
import SuperAdminRejectAmbulance from './Components/SuperAdmin/ambulance/RejectAmbulance';
import SuperAdminAddBloodBank from './Components/SuperAdmin/bloodBank/AddBloodBank';
import SuperAdminBloodBankRequest from './Components/SuperAdmin/bloodBank/BloodBankRequest';
import SuperAdminActiveBloodBank from './Components/SuperAdmin/bloodBank/ActiveBloodBank';
import SuperAdminRejectBloodBank from './Components/SuperAdmin/bloodBank/RejectBloodBank';
import ViewPathologySlot from './Components/SuperAdmin/pathology/ViewPathologySlot';
import ViewRadiologySlot from './Components/SuperAdmin/radiology/ViewRadiologySlot';
import PathologyAllBooking from './Components/SuperAdmin/pathology/PathologyAllBooking';
import RadiologyAllBooking from './Components/SuperAdmin/radiology/RadiologyAllBooking';
import NewDashboard from './Components/SuperAdmin/NewDashboard';
import Test from './Components/SuperAdmin/Test';
import SuperAdminAmbulanceBooking from './Components/SuperAdmin/ambulance/AmbulanceBookings';

import HospitalLogin from './Components/Hospital/HospitalLogin';
import HospitalAddDoctor from './Components/Hospital/AddDoctorForm';
import HospitalAllDoctor from './Components/Hospital/AllDoctors';
import HospitalProfile from './Components/Hospital/HospitalProfile';
import AllHospitalBooking from './Components/Hospital/AllHospitalBooking';
import PatientVerify from './Components/Hospital/PatientVerify';
import HospitalDashboard from './Components/Hospital/HospitalDashboard';

import RadiologyLogin from './Components/Radiology/RadiologyLogin';
import RadiologyDashboard from './Components/Radiology/RadiologyDashboard';
import RadiologyVerifyOTP from './Components/Radiology/RadiologyVerifyOTP';
import RadiologyDetailBooking from './Components/Radiology/RadiologyDetailBooking';
import ViewRadiologySlotBooking from './Components/Radiology/ViewRadiologySlotBooking';
import RadiologyProfile from './Components/Radiology/RadiologyProfile';
import AddRadiologySlot from './Components/Radiology/AddRadiologySlot';

import PathologyLogin from './Components/Pathology/PathologyLogin';
import PathologyDashboard from './Components/Pathology/PathologyDashboard';
import PathologyVerifyOTP from './Components/Pathology/PathologyVerifyOTP';
import PathologyDetailBooking from './Components/Pathology/PathologyDetailBooking';
import ViewSlotBooking from './Components/Pathology/ViewSlotBooking';
import PathologyProfile from './Components/Pathology/PathologyProfile';
import AddPathologySlot from './Components/Pathology/AddPathologySlot';

import BloodBankLogin from './Components/BloodBank/BloodBankLogin';
import BloodBankDeshboard from './Components/BloodBank/BloodBankDeshboard';
import BloodBankProfile from './Components/BloodBank/BloodBankProfile';
import EditBloodBank from './Components/BloodBank/EditBloodBank';
import Termcondition from './Components/Termcondition';

import AmbulanceLogin from './Components/Ambulance/AmbulanceLogin';
import AmbulanceDashboard from './Components/Ambulance/AmbulanceDashboard';
import AmbulanceProfile from './Components/Ambulance/AmbulanceProfile';
import AllAmbulanceBooking from './Components/Ambulance/AllAmbulanceBooking';
import User from './Components/SuperAdmin/User';

import Subscription from './Components/Subscription';
import PrivacyPolicy from './Components/PrivacyPolicy';
import Terms from './Components/Terms';
import Refund from './Components/Refund';
import DashboardHome from './Components/SuperAdmin/DashboardHome';
import Sos from './Components/SuperAdmin/Sos';
import Setting from './Components/SuperAdmin/Setting';
import AddSubadmin from './Components/SuperAdmin/Subadmin/AddSubadmin';
import AllSubadmin from './Components/SuperAdmin/Subadmin/AllSubadmin';

import SubAdminLogin from './Components/SubAdmin/SubAdminLogin';
import SubAdminDashboard from './Components/SubAdmin/SubAdminDashboard';
import SubDashboardHome from './Components/SubAdmin/SubDashboardHome';
import AddAmbulanceSub from './Components/SubAdmin/ambulance/AddAmbulanceSub'
import ActiveAmbulanceSub from './Components/SubAdmin/ambulance/ActiveAmbulanceSub'
import AmbulanceBookingsSub from './Components/SubAdmin/ambulance/AmbulanceBookingsSub'
import AmbulanceDetailSub from './Components/SubAdmin/ambulance/AmbulanceDetailSub'
import AmbulanceRequestSub from './Components/SubAdmin/ambulance/AmbulanceRequestSub'
import RejectAmbulanceSub from './Components/SubAdmin/ambulance/RejectAmbulanceSub'

import AddAgentSub from './Components/SubAdmin/agent/AddAgentSub'
import AllAgentSub from './Components/SubAdmin/agent/AllAgentsSub'

import PaymentCheckout from './Components/PaymentCheckout'

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Pagenotfound />} />
          <Route path="/hospitalLogin" element={<HospitalLogin />} />
          <Route path="/superAdminLogin" element={<SuperAdminLogin />} />
          <Route path="/subAdminLogin" element={<SubAdminLogin />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/refund" element={<Refund />} />
          <Route path="/paymentCheckout" element={<PaymentCheckout />} />
          <Route path="/superAdminDashboard" element={<SuperAdminDashboard />}>
            <Route path="AddSubadmin" element={<AddSubadmin />} />
            <Route path="addHospital" element={<SuperAdminAddHospital />} />
            <Route path="allHospital" element={<SuperAdminAllHospital />} />
            <Route path="addAgent" element={<SuperAdminAddAgent />} />
            <Route path="allAgent" element={<SuperAdminAllAgent />} />
            <Route path="hospitalRequest" element={<SuperAdminHospitalRequest />} />
            <Route path="activeHospital" element={<SuperAdminActiveHospital />} />
            <Route path="rejectHospital" element={<SuperAdminRejectHospital />} />
            <Route path="pathologyRequest" element={<SuperAdminPathologyRequest />} />
            <Route path="activePathology" element={<SuperAdminActivePathology />} />
            <Route path="rejectPathology" element={<SuperAdminRejectPathology />} />
            <Route path="radiologyRequest" element={<SuperAdminRadiologyRequest />} />
            <Route path="activeRadiology" element={<SuperAdminActiveRadiology />} />
            <Route path="rejectRadiology" element={<SuperAdminRejectRadiology />} />
            <Route path="ambulanceRequest" element={<SuperAdminAmbulanceRequest />} />
            <Route path="activeAmbulance" element={<SuperAdminActiveAmbulance />} />
            <Route path="rejectAmbulance" element={<SuperAdminRejectAmbulance />} />
            <Route path="addBloodBank" element={<SuperAdminAddBloodBank />} />
            <Route path="bloodBankRequest" element={<SuperAdminBloodBankRequest />} />
            <Route path="activeBloodBank" element={<SuperAdminActiveBloodBank />} />
            <Route path="rejectBloodBank" element={<SuperAdminRejectBloodBank />} />
            <Route path="addPathology" element={<SuperAdminAddPathology />} />
            <Route path="addRadiology" element={<SuperAdminAddRadiology />} />
            <Route path="addAmbulance" element={<SuperAdminAddAmbulance />} />
            <Route path="ViewPathologySlot/:id" element={<ViewPathologySlot />} />
            <Route path="ViewRadiologySlot/:id" element={<ViewRadiologySlot />} />
            <Route path="NewDashboard" element={<NewDashboard />} />
            <Route path="AddSubadmin" element={<AddSubadmin />} />
            <Route path="AllSubadmin" element={<AllSubadmin />} />
            <Route path="homeDashboard" element={<DashboardHome />} />
            <Route path="ambulanceBooking" element={<SuperAdminAmbulanceBooking />} />
            <Route path="sosDetatilPage" element={<Sos />} />
            <Route path="satingDetatilpage" element={<Setting />} />
            <Route path="User" element={<User />} />
          </Route>
          <Route path="/subAdminDashboard" element={<SubAdminDashboard />}>
            <Route path="subDashboardHome" element={<SubDashboardHome />} />
            <Route path="addAmbulanceSub" element={<AddAmbulanceSub />} />
            <Route path="ambulanceBookingsSub" element={<AmbulanceBookingsSub />} />
            <Route path="ambulanceRequestSub" element={<AmbulanceRequestSub />} />
            <Route path="activeAmbulanceSub" element={<ActiveAmbulanceSub />} />
            <Route path="rejectAmbulanceSub" element={<RejectAmbulanceSub />} />
            <Route path="addAgentSub" element={<AddAgentSub />} />
            <Route path="allAgentSub" element={<AllAgentSub />} />
          </Route>
          <Route path="/AmbulanceDetailSub/:id" element={<AmbulanceDetailSub />} />
          <Route path="/hospitalDashboard" element={<HospitalDashboard />}>
            <Route path="addDoctors" element={<SubDashboardHome />} />
            <Route path="allDoctors" element={<HospitalAllDoctor />} />
            <Route path="hospitalProfile" element={<HospitalProfile />} />
            <Route path="AllHospitalBooking" element={<AllHospitalBooking />} />
            <Route path="PatientVerify" element={<PatientVerify />} />
          </Route>
          <Route path="/hospitalDetail/:id" element={<HospitalDetail />} />
          <Route path="/PathologyDetail/:id" element={<PathologyDetail />} />
          <Route path="/RadiologyDetail/:id" element={<RadiologyDetail />} />
          <Route path="/AmbulanceDetail/:id" element={<AmbulanceDetail />} />
          <Route path="/BloodBankDetail/:id" element={<BloodBankDetail />} />
          <Route path="/RadiologyLogin" element={<RadiologyLogin />} />
          <Route path="/PathologyLogin" element={<PathologyLogin />} />
          <Route path="/BloodBankLogin" element={<BloodBankLogin />} />
          <Route path="/AmbulanceLogin" element={<AmbulanceLogin />} />
          <Route path="/BloodBankDeshboard" element={<BloodBankDeshboard />} />
          <Route path="/RadiologyDashboard" element={<RadiologyDashboard />} />
          <Route path="/PathologyDashboard" element={<PathologyDashboard />} />
          <Route path="/AmbulanceDashboard" element={<AmbulanceDashboard />} />
          <Route path="/RadiologyVerifyOTP/:id" element={<RadiologyVerifyOTP />} />
          <Route path="/RadiologyDetailBooking/:id" element={<RadiologyDetailBooking />} />
          <Route path="/PathologyVerifyOTP/:id" element={<PathologyVerifyOTP />} />
          <Route path="/PathologyDetailBooking/:id" element={<PathologyDetailBooking />} />
          <Route path="ViewPathologySlot/:id" element={<ViewPathologySlot />} />
          <Route path="ViewRadiologySlot/:id" element={<ViewRadiologySlot />} />
          <Route path="ViewSlotBooking/:id" element={<ViewSlotBooking />} />
          <Route path="ViewRadiologySlotBooking/:id" element={<ViewRadiologySlotBooking />} />
          <Route path="PathologyAllBooking/:id" element={<PathologyAllBooking />} />
          <Route path="RadiologyAllBooking/:id" element={<RadiologyAllBooking />} />
          <Route path="NewDashboard" element={<NewDashboard />} />
          <Route path="RadiologyProfile" element={<RadiologyProfile />} />
          <Route path="AddRadiologySlot" element={<AddRadiologySlot />} />
          <Route path="PathologyProfile" element={<PathologyProfile />} />
          <Route path="AddPathologySlot" element={<AddPathologySlot />} />
          <Route path="AmbulanceProfile" element={<AmbulanceProfile />} />
          <Route path="AllAmbulanceBooking" element={<AllAmbulanceBooking />} />
          <Route path="BloodBankProfile" element={<BloodBankProfile />} />
          <Route path="EditBloodBank" element={<EditBloodBank />} />
          <Route path="Termcondition" element={<Termcondition />} />
          <Route path="User" element={<User />} />
          <Route path="/test" element={<Test />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

function Pagenotfound() {
  return (
    <div>
      <h1> 404 page not found</h1>
      <h5>
        <Link to="/">Home</Link>
      </h5>
    </div>
  );
}

export default App;
