import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { Table, Row, Col, InputGroup, Form, Button, Modal, Container, Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { FaCheck, FaEye } from "react-icons/fa";
import { QUERY_GET_SLOT_VENDOR_ID_SLOT_ID, QUERY_SEND_OTP } from '../../graphql/queries'
import Navigation from '../Navigation'
import swal from 'sweetalert';
import { MUTATION_COMPLETE_SLOT_BOOKING } from '../../graphql/mutations';
import "../../ComponentCss/NewDashboard.css"
import { FaAlignJustify, FaHome, FaUsers, FaUsersCog, FaHeadset, FaKey, FaSignOutAlt ,FaClock,FaInfoCircle} from "react-icons/fa";
import { Chart as ChartJS } from "chart.js/auto";
import { LinkContainer } from 'react-router-bootstrap'


export default function ViewSlotBooking() {

  const PathologyId = localStorage.getItem("PathologyId")

  const { id } = useParams();


  const [validated, setValidated] = useState(false);

  const [getOtp, setGetOTP] = useState()
  const [getId, setGetId] = useState()


  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [sendOtp, { loading: otpLoading }] = useLazyQuery(QUERY_SEND_OTP)

  const [contact, setContact] = useState("")
  const [genOtp, setGenotp] = useState("")

  // send otp

  function handleSend() {
    const otp = Math.floor(1000 + Math.random() * 9000);
    sendOtp({
      variables: {
        "contact": `${contact}`,
        "otp": `${otp}`
      }
    }).then(() => {
      console.log(otp)
      setGenotp(otp)

    })

  }

  let count = 1;

  const [show, setShow] = useState(false);
  const [getItem, setGetItem] = useState()

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);




  console.log("PathologyId", PathologyId)
  console.log("id", id)





  const { data, loading } = useQuery(QUERY_GET_SLOT_VENDOR_ID_SLOT_ID, {
    variables: {
      "slotId": `${id}`,
      "vendorId": `${PathologyId}`
    },
    pollInterval: 500,


  })

  console.log("data", data)


  function handleClick(item) {
    setGetItem(item)
    handleShow()
  }


  // mode 2

  function handleOpenModel(contact, id) {
    setGetId(id)
    setContact(contact)
    handleShow2()
  }



  console.log(getItem)




  const [completeBooking, { loading: loadingComplete }] = useMutation(MUTATION_COMPLETE_SLOT_BOOKING, {
    refetchQueries: [
      QUERY_GET_SLOT_VENDOR_ID_SLOT_ID,
      "getSlotBookingByVendorIdSlotId"
    ]
  })

  console.log("getId", getId)

  function handleComplete() {
    if (parseInt(getOtp) === parseInt(genOtp)) {
      completeBooking({
        variables: {
          "slotId": `${getId}`
        }
      })
      swal({ title: "Successfull!!!", text: "Block Successfully", icon: "success" });
      handleClose2();
    } else {
      swal({
        title: 'Error!!!',
        text: 'Error OTP not match',
        icon: 'error',
      });
    }
  }

  function handleMenu() {
    let toggle = document.querySelector(".toggle");
    let navigation = document.querySelector(".navigation");
    let main = document.querySelector(".main");
        navigation.classList.toggle("active");
        main.classList.toggle("active");
    };



  return (
    <>
     <div >
                <div className="navigation">
                    <br></br> 
    
                <span className="title" style={{ fontSize: 20, fontFamily: 'DM Sans', fontWeight: 'bold',color:"#fff",textAlign:"center",marginLeft:20,marginTop:20 }}>Dashboard</span>
                    <ul>
                        <br></br><br></br><br></br>
                       
                        <li>
                        <LinkContainer to="/">
                            <a href="#">
                                <span className="icon">

                                </span>
                                <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }} eventKey="first"><FaHome /> Home</span>
                              
                            </a>
                            </LinkContainer>
                        </li>
                        <li>
                        <LinkContainer to="/RadiologyProfile">
                            <a href="#">
                                <span className="icon">
                                </span>
                                <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }}><FaUsers /> Profile</span>
                            </a>
                            </LinkContainer>
                        </li>
                        <li>
                        <LinkContainer to="/AddRadiologySlot">
                            <a href="#">
                                <span className="icon"> </span>
                                <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }}><FaClock /> All Slot</span>
                            </a>
                            </LinkContainer>
                        </li>
                        <li>
                        <LinkContainer to="/Termcondition">
                            <a href="#">
                                <span className="icon"> </span>
                                <span className="title" style={{ fontSize: 12, fontFamily: 'DM Sans' }}><FaInfoCircle /> Terms & Condition</span>
                            </a>
                            </LinkContainer>
                        </li>
                       
                       
                    </ul>
                    
                </div>
                

                <div className="main">
                    <div className="topbar">
                        <div>
                            <FaAlignJustify onClick={() => handleMenu()}  className="toggle"/>
                        </div>
                        <div className="search">
                            <label>
                                <input type="text" placeholder="Search here" />
                            </label>
                        </div>
                        <div className="user">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/2048px-Circle-icons-profile.svg.png" alt="" />
                        </div>
                    </div>
                    <Row>
        <Col md={4}></Col>
        <Col md={4}></Col>
        <Col md={4}>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Search..."
              aria-label="Recipient's username"
              style={{ fontFamily: "Dm sans" }}
            />
            <InputGroup.Text id="basic-addon2" style={{ fontFamily: "Dm sans" }}>Search</InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
      {
        loading ?
          <div className='mx-auto d-block'>
            <Spinner animation="border" role="status" className='mx-auto d-block'></Spinner>
          </div>

          :
          <>
            <Table striped bordered hover style={{ fontFamily: "Dm sans", fontSize: 12 }}>
              <thead>
                <tr>
                  <th>SR No.</th>
                  <th>Center Name</th>
                  <th>Booking Date</th>
                  <th>Slot Time</th>
                  <th>User Name</th>
                  <th>User Contact</th>
                  <th> Total Amount </th>

                  <th>Payment Type</th>
                  <th>View Detail</th>
                  <th>Status</th>
                  <th>Complete</th>
                </tr>
              </thead>
              <tbody>
                {
                  data && data.getSlotBookingByVendorIdSlotId.map(item => {

                    return (
                      <tr>
                        <td>{count++}</td>
                        <td>{item.venderName}</td>
                        <td>{item.bookingDate}</td>
                        <td>{item.slotTime}</td>
                        <td>{item.userName}</td>
                        <td>{item.userContact}</td>
                        <td><center>{item.totalAmount}/-</center></td>
                        <td><center>{item.paymentType}</center></td>
                        <td><center><Button size="sm" onClick={() => handleClick(item)}><FaEye /></Button></center></td>
                        <td>{item.status}</td>
                        {
                          item.status === 'complete' ?
                            <td><center><Button size="sm" variant="success" disabled><FaCheck /></Button></center></td> :
                            <td><center><Button size="sm" variant="success" onClick={() => handleOpenModel(item.userContact, item.id)}><FaCheck /></Button></center></td>
                        }
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          </>
      }


      <Modal show={show} onHide={handleClose} size="lg">
        <Container>
          <h6 style={{ textAlign: "center" }}>{getItem && getItem.venderName}</h6>

          <Row>
            <Col md={6}>
              <h4 style={{ textAlign: "center" }}>User Information</h4>
              <hr></hr>
              <h6 style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 15 }}>Name : {getItem && getItem.userName}</h6>
              <h6 style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 15 }}>Contact : {getItem && getItem.userContact}</h6>
              <h6 style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 15 }}>Email : {getItem && getItem.userEmail}</h6>
              <h6 style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 15 }}> Discount : {getItem && getItem.userDiscount}</h6>
              <h6 style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 15 }}> Payment Type : {getItem && getItem.paymentType}</h6>
              <h6 style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 15 }}> Price : {getItem && getItem.totalAmount}</h6>
              <h6 style={{ fontFamily: "Dm sans", fontWeight: "bold" }}>Service</h6>
              <ul>
                {
                  getItem && getItem.service.map(list => {

                    return (
                      <li style={{ fontFamily: "Dm sans", fontSize: 13 }}>Service : {list.serviceName}</li>

                    )

                  })

                }



              </ul>

            </Col>

            <Col md={6}>
              <h4 style={{ textAlign: "center" }}>Booking Information</h4>
              <hr></hr>
              <h6 style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 15 }}>Booking Date : {getItem && getItem.bookingDate}</h6>
              <h6 style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 15 }}>Slot Time : {getItem && getItem.slotTime}</h6>

            </Col>

          </Row>
        </Container>

      </Modal>
      <Modal show={show2} onHide={handleClose2}>
        <Container>


          {
            genOtp === "" ?
              <Button className='mx-auto d-block' style={{ marginTop: 20, marginBottom: 10, border: "none", fontFamily: "Dm sans", letterSpacing: 1 }} onClick={() => handleSend()}>send</Button>
              :
              <>
                <input type="number" placeholder='Enter OTP' className='mx-auto d-block' style={{ marginTop: 20, fontFamily: "Dm sans", padding: 5 }} onChange={(e) => setGetOTP(e.target.value)} />
                <Button className='mx-auto d-block' style={{ marginTop: 20, marginBottom: 10, border: "none", fontFamily: "Dm sans", letterSpacing: 1 }} onClick={() => handleComplete()}>complete</Button>
              </>
          }



        </Container>
      </Modal>

                  
                </div>
              
            </div>
    



    </>
  )
}
