import { Form, Row, Col, Button, Container, Modal } from 'react-bootstrap';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_RADIOLOGY } from '../../../graphql/mutations';
import { QUERY_PENDING_RADIOLOGY } from '../../../graphql/queries';
import Spinner from '../../Spinner';
import swal from 'sweetalert';
import AWS from 'aws-sdk';
import { v4 } from 'uuid';
import axios from 'axios';
import uniqid from 'uniqid';
import { AiFillPlusCircle } from 'react-icons/ai';
import { FiPlus,FiMinus } from "react-icons/fi";

const ID = process.env.REACT_APP_AWS_ACCESSS_ID;
const SECRET = process.env.REACT_APP_AWS_ACCESSS_KEY;
const BUCKET_NAME = 'bharp-hospital';

const s3 = new AWS.S3({
    accessKeyId: ID,
    secretAccessKey: SECRET,
});

let stateArray = ['Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jammu and Kashmir', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttarakhand', 'Uttar Pradesh', 'West Bengal', 'Andaman and Nicobar Islands', 'Chandigarh', 'Dadra and Nagar Haveli', 'Daman and Diu', 'Delhi', 'Lakshadweep', 'Puducherry'];
const imageKey = []

const andhraPradeshDistrictArray = ["Ananthapur", "Chittoor", "Cuddapah", "East Godavari", "Guntur", "Krishna", "Kurnool", "Nellore", "Prakasam", "Srikakulam", "Visakhapatnam", "Vizianagaram", "West Godavari"];
const andamanAndNicobarIslandsDistrictArray = ["Nicobar", "North And Middle Andaman", "South Andaman"];
const arunachalPradeshDistrictArray = ["Changlang", "Dibang Valley", "East Kameng", "East Siang", "Kurung Kumey", "Lohit", "Lower Dibang Valley", "Lower Subansiri", "Papum Pare", "Tawang", "Tirap", "Upper Siang", "Upper Subansiri", "West Kameng", "West Siang"];
const assamDistrictArray = ["Barpeta", "Bongaigaon", "Cachar", "Darrang", "Dhemaji", "Dhubri", "Dibrugarh", "Goalpara", "Golaghat", "Hailakandi", "Jorhat", "Kamrup", "Karbi Anglong", "Karimganj", "Kokrajhar", "Lakhimpur", "Marigaon", "Nagaon", "Nalbari", "North Cachar Hills", "Sibsagar", "Sonitpur", "Tinsukia"];
const biharDistrictArray = ["Araria", "Arwal", "Aurangabad(BH)", "Banka", "Begusarai", "Bhagalpur", "Bhojpur", "Buxar", "Darbhanga", "East Champaran", "Gaya", "Gopalganj", "Jamui", "Jehanabad", "Kaimur (Bhabua)", "Katihar", "Khagaria", "Kishanganj", "Lakhisarai", "Madhepura", "Madhubani", "Munger", "Muzaffarpur", "Nalanda", "Nawada", "Patna", "Purnia", "Rohtas", "Saharsa", "Samastipur", "Saran", "Sheikhpura", "Sheohar", "Sitamarhi", "Siwan", "Supaul", "Vaishali", "West Champaran"];
const chandigarhDistrictArray = ["Chandigarh"];
const chhattisgarhDistrictArray = ["Bastar", "Bijapur(CGH)", " Bilaspur(CGH)", "Dantewada", "Dhamtari", "Durg", "Gariaband", "Janjgir-champa", "Jashpur", "Kanker", "Kawardha", "Korba", "Koriya", "Mahasamund", "Narayanpur", "Raigarh", "Raipur", "Rajnandgaon", "Surguja"];
const dadarAndNagarHaveliDistrictArray = ["Dadra & Nagar Haveli"];
const damanAndDiuDistrictArray = ["Daman", "Diu"];
const delhiDistrictArray = ["Central Delhi", "East Delhi", "New Delhi", "North Delhi", "North East Delhi", "North West Delhi", " South Delhi", "South West Delhi", "West Delhi"];
const LakshadweepDistrictArray = ["North Goa", "South Goa"];
const puducherryDistrictArray = ["Karaikal", "Mahe", "Pondicherry"];
const goaDistrictArray = ["North Goa", "South Goa"];
const gujaratDistrictArray = ["Ahmedabad", "Amreli", "Anand", "Banaskantha", "Bharuch", "Bhavnagar", "Dahod", "Gandhi Nagar", "Jamnagar", "Junagadh", "Kachchh", "Kheda", "Mahesana", "Narmada", "Navsari", "Panch Mahals", "Patan", "Porbandar", "Rajkot", "Sabarkantha", "Surat", "Surendra Nagar", "Tapi", "The Dangs", "Vadodara", "Valsad"];
const jammuAndKashmirDistrictArray = ["Ananthnag", "Bandipur", "Baramulla", "Budgam", "Doda", "Jammu", "Kargil", "Kathua", "Kulgam", "Kupwara", "Leh", "Poonch", "Pulwama", "Rajauri", "Reasi", "Shopian", "Srinagar", "Udhampur"];
const himachalPradeshDistrictArray = ["Bilaspur(HP)", "Chamba", "Hamirpur(HP)", "Kangra", "Kinnaur", "Kullu", "Lahul & Spiti", "Mandi", "Shimla", "Sirmaur", "Solan", "Una"];
const jharkhandDistrictArray = ["Bokaro", "Chatra", "Deoghar", "Dhanbad", "Dumka", "East Singhbhum", "Garhwa", "Giridh", "Godda", "Gumla", "Hazaribag", "Jamtara", "Khunti", "Koderma", "Latehar", "Lohardaga", "Pakur", "Palamau", "Ramgarh", "Ranchi", "Sahibganj", "Seraikela - kharsawan", "Simdega", "West Singhbhum"];
const karnatakaDistrictArray = ["Bagalkot", "Bangalore", "Rural", "Belgaum", "Bellary", "Bidar", "Bijapur(KAR)", "Chamrajnagar", "Chickmagalur", "Chikkaballapur", "Chitradurga", "Dakshina Kannada", "Davangere", "Dharwad", "Gadag", "Gulbarga", "Hassan", "Haveri", "Kodagu", "Kolar", "Koppal", "Mandya", "Mysore", "Raichur", "Ramanagar", "Shimoga", "Tumkur", "Udupi", "Uttara Kannada", "Yadgir"];
const keralaDistrictArray = ["Alappuzha", "Ernakulam", "Idukki", "Kannur", "Kasargod", "Kollam", "Kottayam", "Kozhikode", "Malappuram", "Palakkad", "Pathanamthitta", "Thiruvananthapuram", "Thrissur", "Wayanad"];
const madhyaPradeshDistrictArray = ["Alirajpur", "Anuppur", "Ashok Nagar", "Balaghat", "Barwani", "Betul", "Bhind", "Bhopal", "Burhanpur", "Chhatarpur", "Damoh", "Datia", "Dewas", "Dhar", "Dindori", "East Nimar", "Guna", "Gwalior", "Harda", "Hoshangabad", "Indore", "Jabalpur", "Jhabua", "Katni", "Khandwa", "Khargone", "Mandla", "Mandsaur", "Morena", "Narsinghpur", "Neemuch", "Panna", "Raisen", "Rajgarh", "Ratlam", "Rewa", "Sagar", "Satna", "Sehore", "Seoni", "Shahdol", "Shajapur", "Sheopur", "Shivpuri", "Sidhi", "Singrauli", "Tikamgarh", "Ujjain", "Umaria", "Vidisha", "West Nimar"];
const maharashtraDistrictArray = ["Ahmed Nagar", "Akola", "Amravati", "Aurangabad", "Beed", "Bhandara", "Buldhana", "Chandrapur", "Dhule", "Gadchiroli", "Gondia", "Hingoli", "Jalgaon", "Jalna", "Kolhapur", "Latur", "Mumbai", "Nagpur", "Nanded", "Nandurbar", "Nashik", "Osmanabad", "Parbhani", "Pune", "Raigarh(MH)", "Ratnagiri", "Sangli", "Satara", "Sindhudurg", "Solapur", "Thane", "Wardha", "Washim", "Yavatmal"];
const manipurDistrictArray = ["Bishnupur", "Chandel", "Churachandpur", " Imphal East", "Imphal West", "Senapati", "Tamenglong", "Thoubal", "Ukhrul"];
const meghalayaDistrictArray = ["East Garo Hills", "East Khasi Hills", "Jaintia Hills", "Ri Bhoi", "South Garo Hills", "West Garo Hills", "West Khasi Hills"];
const mizoramDistrictArray = ["Aizawl", "Champhai", "Kolasib", "Lawngtlai", "Lunglei", "Mammit", "Saiha", "Serchhip"];
const nagalandDistrictArray = ["Dimapur", "Kiphire", "Kohima", "Longleng", "Mokokchung", "Mon", "Peren", "Phek", "Tuensang", "Wokha", "Zunhebotto"];
const odishaDistrictArray = ["Angul", "Balangir", "Baleswar", "Bargarh", "Bhadrak", "Boudh", "Cuttack", "Debagarh", "Dhenkanal", "Gajapati", "Ganjam", "Jagatsinghapur", "Jajapur", "Jharsuguda", "Kalahandi", "Kandhamal", "Kendrapara", "Kendujhar", "Khorda", "Koraput", "Malkangiri", "Mayurbhanj", "Nabarangapur", "Nayagarh", "Nuapada", "Puri", "Rayagada", "Sambalpur", "Sonapur", "Sundergarh"];
const punjabDistrictArray = ["Amritsar", "Barnala", "Bathinda", "Faridkot", "Fatehgarh Sahib", "Fazilka", "Firozpur", "Gurdaspur", "Hoshiarpur", "Jalandhar", "Kapurthala", "Ludhiana", "Mansa", "Moga", "Mohali", "Muktsar", "Nawanshahr", "Pathankot", "Patiala", "Ropar", "Rupnagar", "Sangrur", "Tarn Taran"];
const rajasthanDistrictArray = ["Ajmer", "Alwar", "Banswara", "Baran", "Barmer", "Bharatpur", "Bhilwara", "Bikaner", "Bundi", "Chittorgarh", "Churu", "Dausa", "Dholpur", "Dungarpur", "Ganganagar", "Hanumangarh", "Jaipur", "Jaisalmer", "Jalor", "Jhalawar", "Jhujhunu", "Jodhpur", "Karauli", "Kota", "Nagaur", "Pali", "Rajsamand", "Sikar", "Sawai Madhopur", "Sirohi", "Tonk", "Udaipur"];
const sikkimDistrictArray = ["East Sikkim", "North Sikkim", "South Sikkim", "West Sikkim"];
const tamilNaduDistrictArray = ["Ariyalur", "Chennai", "Coimbatore", "Cuddalore", "Dharmapuri", "Dindigul", "Erode", "Kanchipuram", "Kanyakumari", "Karur", "Krishnagiri", "Madurai", "Nagapattinam", "Namakkal", "Nilgiris", "Perambalur", "Pudukkottai", "Ramanathapuram", "Salem", "Sivaganga", "Thanjavur", "Theni", "Tiruchirappalli", "Tirunelveli", "Tiruvallur", "Tiruvannamalai", "Tiruvarur", "Tuticorin", "Vellore", "Villupuram", "Virudhunagar"];
const telanganaDistrictArray = ["Adilabad", "Hyderabad", "K.V.Rangareddy", "Karim Nagar", "Khammam", "Mahabub Nagar", "Medak", "Nalgonda", "Nizamabad", "Warangal"];
const tripuraDistrictArray = ["Dhalai", "North Tripura", "South Tripura", "West Tripura"];
const uttarPradeshDistrictArray = ["Agra", "Aligarh", "Allahabad", "Ambedkar Nagar", "Auraiya", "Azamgarh", "Bagpat", "Bahraich", "Ballia", "Balrampur", "Banda", "Barabanki", "Bareilly", "Basti", "Bijnor", "Budaun", "Bulandshahr", "Chandauli", "Chitrakoot", "Deoria", "Etah", "Etawah", "Faizabad", "Farrukhabad", "Fatehpur", "Firozabad", "Gautam Buddha Nagar", "Ghaziabad", "Ghazipur", "Gonda", "Gorakhpur", "Hamirpur", "Hardoi", "Hathras", "Jalaun", "Jaunpur", "Jhansi", "Jyotiba Phule Nagar", "Kannauj", "Kanpur Dehat", "Kanpur Nagar", "Kaushambi", "Kheri", "Kushinagar", "Lalitpur", "Lucknow", "Maharajganj", "Mahoba", "Mainpuri", "Mathura", "Mau", "Meerut", "Mirzapur", "Moradabad", "Muzaffarnagar", "Pilibhit", "Pratapgarh", "Raebareli", "Rampur", "Saharanpur", "Sant Kabir Nagar", "Sant Ravidas Nagar", "Shahjahanpur", "Shrawasti", "Siddharthnagar", "Sitapur", "Sonbhadra", "Sultanpur", "Unnao", "Varanasi"];
const uttarakhandDistrictArray = ["Almora", "Bageshwar", "Chamoli", "Champawat", "Dehradun", "Haridwar", "Nainital", "Pauri Garhwal", "Pithoragarh", "Rudraprayag", "Tehri Garhwal", "Udham Singh Nagar", "Uttarkashi"];
const westBengalDistrictArray = ["Bankura", "Bardhaman", "Birbhum", "Cooch Behar", "Darjiling", "East Midnapore", "Hooghly", "Howrah", "Jalpaiguri", "Kolkata", "Malda", "Medinipur", "Murshidabad", "Nadia", "North 24 Parganas", "North Dinajpur", "Puruliya", "South 24 Parganas", "South Dinajpur", "West Midnapore",];

export default function AddRadiology() {
    let counter = 0;
    const [validated, setValidated] = useState(false);
    const [addRadiologyMutation, { loading }] = useMutation(CREATE_RADIOLOGY, {
        refetchQueries: [
            QUERY_PENDING_RADIOLOGY
        ]
    });

    const [centerName, setCenterName] = useState('');
    const [centerAddress, setCenterAddress] = useState('');
    const [villageName, setVillageName] = useState('');
    const [state, setState] = useState('');
    const [district, setDistrict] = useState('');
    const [pincode, setPincode] = useState('');
    const [contact, setContact] = useState('');
    const [email, setEmail] = useState('');
    const [websiteLink, setWebsiteLink] = useState('');
    const [publicSector, setPublicSector] = useState('');
    const [publicSectorOther, setPublicSectorOther] = useState('');
    const [privateSector, setPrivateSector] = useState('');
    const [privateSectorOther, setPrivateSectorOther] = useState('');
    const [registrationNo, setRegistrationNo] = useState('');
    const [ownerName, setOwnerName] = useState('');
    const [ownerContact, setOwnerContact] = useState('');
    const [ownerEmail, setOwnerEmail] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [licenseBy, setLicenseBy] = useState([]);
    const [services, setServices] = useState([]);
    const [radiologistDetails, setRadiologistDetails] = useState([{ name: '', qualification: '', experience: '', rpId: '' }]);
 const [bharpDiscount, setBharpDiscount] = useState('');
 const [vendorMargin, setVendorMargin] = useState('');
    const [selectedRadiologyImg, setSelectedRadiologyImg] = useState(null);
    const [radiologyImgLoading, setRadiologyImgLoading] = useState(false);
    const [radiologyImgDone, setRadiologyImgDone] = useState(false);
    const [radiologyImgKey, setRadiologyImgKey] = useState('');


    const [checkedComputedTomography, setCheckedComputedTomography] = useState(false);
    const [checkedFluoroscopy, setCheckedFluoroscopy] = useState(false);
    const [checkedMagneticResonanceImaging, setCheckedMagneticResonanceImaging] = useState(false);
    const [checkedMammography, setCheckedMammography] = useState(false);
    const [checkedMagneticResonanceAngiography, setCheckedMagneticResonanceAngiography] = useState(false);
    const [checkedXray, setCheckedXray] = useState(false);
    const [checkedPositronEmissionTomography, setCheckedPositronEmissionTomography] = useState(false);
    const [checkedUltrasound, setCheckedUltrasound] = useState(false);
    const [checkedAngiography, setCheckedAngiography] = useState(false);
    const [checkedDiagnosis, setCheckedDiagnosis] = useState(false);
    const [checkedCancerTreatment, setCheckedCancerTreatment] = useState(false);
    const [checkedTumorAblation, setCheckedTumorAblation] = useState(false);
    const [checkedNiddleBiopsy, setCheckedNiddleBiopsy] = useState(false);
    const [checkedBreastBiopsy, setCheckedBreastBiopsy] = useState(false);
    const [checkedUterineArteryEmbolization, setCheckedUterineArteryEmbolization] = useState(false);
    const [checkedBrainScanning, setCheckedBrainScanning] = useState(false);
    const [checkedEchocardiography, setCheckedEchocardiography] = useState(false);
    const [checkedUrography, setCheckedUrography] = useState(false);

    const [facilitiesState, setFacilitiesState] = useState([]);
    const [mFacilitiesState, setMfacilitiesState] = useState([]);
    const [acceptanceState, setAcceptancesState] = useState([]);

    const [serviceTypeState, setServiceTypeState] = useState([]);
    const [serviceTypeStateOther, setServiceTypeStateOther] = useState([]);
  
    const [ComputedTomographyPrice, setComputedTomographyPrice] = useState('');
    const [FluoroscopyPrice, setFluoroscopyPrice] = useState('');
    const [MagneticResonanceImagingPrice, setMagneticResonanceImagingPrice] = useState('');
    const [MammographyPrice, setMammographyPrice] = useState('');
    const [MagneticResonanceAngiographyPrice, setMagneticResonanceAngiographyPrice] = useState('');
    const [XrayPrice, setXrayPrice] = useState('');
    const [PositronEmissionTomographyPrice, setPositronEmissionTomographyPrice] = useState('');
    const [UltrasoundPrice, setUltrasoundPrice] = useState('');
    const [AngiographyPrice, setAngiographyPrice] = useState('');
    const [DiagnosisPrice, setDiagnosisPrice] = useState('');
    const [CancerTreatmentPrice, setCancerTreatmentPrice] = useState('');
    const [TumorAblationPrice, setTumorAblationPrice] = useState('');
    const [NiddleBiopsyPrice, setNiddleBiopsyPrice] = useState('');
    const [BreastBiopsyPrice, setBreastBiopsyPrice] = useState('');
    const [UterineArteryEmbolizationPrice, setUterineArteryEmbolizationPrice] = useState('');
    const [BrainScanningPrice, setBrainScanningPrice] = useState('');
    const [EchocardiographyPrice, setEchocardiographyPrice] = useState('');
    const [UrographyPrice, setUrographyPrice] = useState('');
    const [selectImage, setSelectImage] = useState()
    const [imageBox, setImageBox] = useState()
    const [uploadDone, setUploadDone] = useState(false)
    const [uploadLoading, setUploadLoading] = useState(false)
    const [multipleImgKey, setMultipleImgKey] = useState([])

    function multipleImg(e) {
        setSelectImage([...e.target.files])
      }
    
  

    console.log("facilitiestate", facilitiesState)

    const handleChangeforFacilities = (event) => {
        const { checked, value } = event.currentTarget;
    
        if (value === 'Computed tomography (C.T Scan)') {
            setCheckedComputedTomography(checked)
        } else if (value === 'Fluoroscopy') {
            setCheckedFluoroscopy(checked)
        } else if (value === 'Magnetic resonance imaging (M.R.I)') {
            setCheckedMagneticResonanceImaging(checked)
        } else if (value === 'Mammography') {
            setCheckedMammography(checked)
        } else if (value === 'Magnetic resonance angiography (M.R.A)') {
            setCheckedMagneticResonanceAngiography(checked)
        }  else if (value === 'X-ray') {
            setCheckedXray(checked)
        } else if (value === 'Positron emission tomography (PET-Scan)') {
            setCheckedPositronEmissionTomography(checked)
        } else if (value === 'Ultrasound') {
            setCheckedUltrasound(checked)
        }else if (value === 'Angiography') {
            setCheckedAngiography(checked)
        } else if (value === 'Diagnosis') {
            setCheckedDiagnosis(checked)
        } else if (value === 'Cancer treatment') {
            setCheckedCancerTreatment(checked)
        } else if (value === 'Tumor ablation') {
            setCheckedTumorAblation(checked)
        } else if (value === 'Needle biopsy') {
            setCheckedNiddleBiopsy(checked)
        } else if (value === 'Breast biopsy') {
            setCheckedBreastBiopsy(checked)
        } else if (value === 'Uterine artery embolization') {
            setCheckedUterineArteryEmbolization(checked)
        } else if (value === 'Brain scanning') {
            setCheckedBrainScanning(checked)
        } else if (value === 'Echocardiography') {
            setCheckedEchocardiography(checked)
        } else if (value === 'Urography') {
            setCheckedUrography(checked)
        } 
       
        else {
          alert('Somthing error!!!')
        }
    
    
    
    
        if (checked === true) {
            if (value === 'Computed tomography (C.T Scan)') {
                setComputedTomographyPrice("")
            } else if (value === 'Fluoroscopy') {
                setFluoroscopyPrice("")
            } else if (value === 'Magnetic resonance imaging (M.R.I)') {
                setMagneticResonanceImagingPrice("")
            } else if (value === 'Mammography') {
                setMammographyPrice("")
            } else if (value === 'Magnetic resonance angiography (M.R.A)') {
                setMagneticResonanceAngiographyPrice("")
            }  else if (value === 'X-ray') {
                setXrayPrice("")
            } else if (value === 'Positron emission tomography (PET-Scan)') {
                setPositronEmissionTomographyPrice("")
            } else if (value === 'Ultrasound') {
                setUltrasoundPrice("")
            }
            else if (value === 'Angiography') {
                setAngiographyPrice("")
            } else if (value === 'Diagnosis') {
                setDiagnosisPrice("")
            } else if (value === 'Cancer treatment') {
                setCancerTreatmentPrice("")
            } else if (value === 'Tumor ablation') {
                setTumorAblationPrice("")
            } else if (value === 'Needle biopsy') {
                setNiddleBiopsyPrice("")
            } else if (value === 'Breast biopsy') {
                setBreastBiopsyPrice("")
            } else if (value === 'Uterine artery embolization') {
                setUterineArteryEmbolizationPrice("")
            } else if (value === 'Brain scanning') {
                setBrainScanningPrice("")
            } else if (value === 'Echocardiography') {
                setEchocardiographyPrice("")
            } else if (value === 'Urography') {
                setUrographyPrice("")
            } 
        
          else {
            alert('Somthing error!!!')
          }
    
          setFacilitiesState(current => [...current, { serviceName: value, servicePrice: "" }]);
        }
        if (checked === false) {
          setFacilitiesState(current =>
            current.filter(element => {
              return element.serviceName !== value;
            }),
          );
        }
      };

      const handleChangeforFacilitiesPrice = (price, name) => {
        facilitiesState.filter(elempricet => {
        if (name === 'Computed tomography (C.T Scan)') {
            setComputedTomographyPrice(price)
        } else if (name === 'Fluoroscopy') {
            setFluoroscopyPrice(price)
        } else if (name === 'Magnetic resonance imaging (M.R.I)') {
            setMagneticResonanceImagingPrice(price)
        } else if (name === 'Mammography') {
            setMammographyPrice(price)
        } else if (name === 'Magnetic resonance angiography (M.R.A)') {
            setMagneticResonanceAngiographyPrice(price)
        }  else if (name === 'X-ray') {
            setXrayPrice(price)
        } else if (name === 'Positron emission tomography (PET-Scan)') {
            setPositronEmissionTomographyPrice(price)
        } else if (name === 'Ultrasound') {
            setUltrasoundPrice(price)
        }else if (name === 'Angiography') {
            setAngiographyPrice(price)
        } else if (name === 'Diagnosis') {
            setDiagnosisPrice(price)
        } else if (name === 'Cancer treatment') {
            setCancerTreatmentPrice(price)
        } else if (name === 'Tumor ablation') {
            setTumorAblationPrice(price)
        } else if (name === 'Needle biopsy') {
            setNiddleBiopsyPrice(price)
        } else if (name === 'Breast biopsy') {
            setBreastBiopsyPrice(price)
        } else if (name === 'Uterine artery embolization') {
            setUterineArteryEmbolizationPrice(price)
        } else if (name === 'Brain scanning') {
            setBrainScanningPrice(price)
        } else if (name === 'Echocardiography') {
            setEchocardiographyPrice(price)
        } else if (name === 'Urography') {
            setUrographyPrice(price)
        } 
           
        
          else {
            alert('Somthing error!!!')
          }
          const newState = facilitiesState.map(obj => {
            console.log("obj", obj)
            if (obj.serviceName === name) {
              return { ...obj, servicePrice: price };
            }
            return obj;
          });
          setFacilitiesState(newState);
        });
      };

      let addServiceTypeField = () => {
        setServiceTypeStateOther([...serviceTypeStateOther, { serviceName: "", servicePrice: "" }]);
      };
    
      let removeServiceTypeField = (i) => {
        let newServiceFormValues = [...serviceTypeStateOther];
        newServiceFormValues.splice(i, 1);
        setServiceTypeStateOther(newServiceFormValues);
      };
    
      let handleServiceTypeChange = (e, i, nameValue) => {
        console.log("e", e)
        let newServiceFormValues = [...serviceTypeStateOther];
        newServiceFormValues[i][nameValue] = e;
        setServiceTypeStateOther(newServiceFormValues);
      };
    
      function mergeClick() {
        setFacilitiesState(prev => [...prev, ...serviceTypeStateOther])
        setServiceTypeStateOther([])
        alert('Service Added Successfully...')
      }



    let addFormFields = () => {
        setRadiologistDetails([...radiologistDetails, { name: '', qualification: '', experience: '', rpId: '' }]);
    };

    let handleChange = (e, i, nameValue) => {
        let newFormValues = [...radiologistDetails];
        newFormValues[i][nameValue] = e;
        setRadiologistDetails(newFormValues);
    };

    const handleSubmit = (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            e.preventDefault();
            e.stopPropagation();
        }
        
        else 
        {
        e.preventDefault();
         setValidated(false);
       
            addRadiologyMutation({
                variables: {
                    "radiologyInput": {
                        "nameOfCenter": `${centerName}`,
                        "centerAddress": `${centerAddress}`,
                        "villageName": `${villageName}`,
                        "state": `${state}`,
                        "district": `${district}`,
                        "pincode": `${pincode}`,
                        "contact": `${contact}`,
                        "email": `${email}`,
                        "websiteLink": `${websiteLink}`,
                        "ownerName": `${ownerName}`,
                        "ownerContact": `${ownerContact}`,
                        "ownerEmail": `${ownerEmail}`,
                        "picture": imageKey,
                        "radiologistDetail": radiologistDetails,
                        "privateSectorCentralGov": `${privateSector}`,
                        "bharpDiscount":`${bharpDiscount}`,
                        "vendorMargin": `${vendorMargin}`,
                        "publicSectorOther": `${publicSectorOther}`,
                        "privateSectorIndividual": `${publicSector}`,
                        "privateSectorOther": `${privateSectorOther}`,
                        "registrationNo": `${registrationNo}`,
                        "lisenceBy": licenseBy,
                        "services": facilitiesState,
                        "latitude": `${latitude}`,
                        "longitude": `${longitude}`,
                        "agentId": null,
                        "rejectStatus": "false",
                        "rejectComment": "",
                    }
                },
            });

            setValidated(false);
            swal({ title: 'Successfull!!!', text: 'Radiology Add Successfully', icon: 'success' });
            setCenterName('');
            setCenterAddress('');
            setVillageName('');
            setState('');
            setDistrict('');
            setPincode('');
            setContact('');
            setEmail('');
            setWebsiteLink('');
            setPublicSector('');
            setPrivateSector('');
            setRegistrationNo('');
            setOwnerName('');
            setOwnerContact('');
            setOwnerEmail('');
            setLatitude('');
            setLongitude('');
            setBharpDiscount("")
            setVendorMargin("")
            setLicenseBy([]);
            setServices([]);
            setRadiologistDetails([{ name: '', qualification: '', experience: '', rpId: '' }]);
            setSelectedRadiologyImg(null)
            setRadiologyImgLoading(false)
            setRadiologyImgDone(false)
            setRadiologyImgKey('')
            setPublicSectorOther('')
            setPrivateSectorOther('')
            setMultipleImgKey([''])
        }
    };


    const handleChangeLicenseChange = (event) => {
        const { checked, value } = event.currentTarget;
        setLicenseBy((prev) =>
            checked ? [...prev, value] : prev.filter((val) => val !== value)
        );
    };
    const handleChangeServicesChange = (event) => {
        const { checked, value } = event.currentTarget;
        setServices((prev) =>
            checked ? [...prev, value] : prev.filter((val) => val !== value)
        );
    };

    const handleRadiologyImage = (e) => {
         try {
            selectImage.map(async (fileData) => {
              const uniqueId = uniqid();
              var regex = new RegExp('[^.]+$');
              var extension = fileData.name.match(regex);
              var uniqueFileName = "Bharp" + '-' + uniqueId + '-' + uniqueId + '.' + extension[0];
              imageKey.push(uniqueFileName)
              const params = {
                Bucket: BUCKET_NAME,
                Key: uniqueFileName,
                Body: fileData,
              };
              s3.upload(params, function (err, data) {
                if (err) {
                  throw err;
                } else {
                  setImageBox(uniqueFileName)
                  swal({
                    title: 'Successfull!!!',
                    text: ' Uploaded Successfully',
                    icon: 'success',
                  });
                  setMultipleImgKey([])
                  setSelectImage('')
                  setUploadLoading(false)
                  setUploadDone(true)
                }
              });
            })
          } catch (err) {
            swal({
              title: 'Error!!!',
              text: 'Error please try again',
              icon: 'error',
            });
            console.log(err);
            setUploadLoading(false)
          }
    }

    return (
        <>
            <div style={{ marginTop: '30px' }}>
                <Container>
                    <h1 style={{ fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                        Add Radiology
                    </h1>
                    <hr />
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>

                        {/*----------------- Radiology Details ---------------------*/}
                        <Row>
                            <h4 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
                                Center Details
                                <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '19%', marginTop: 10 }}>
                                    {' '}
                                </div>
                            </h4>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Center Name</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setCenterName(e.target.value)} value={centerName} placeholder="Center Name" />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Center Address</Form.Label>
                                    <Form.Control required as="textarea" rows={1} style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setCenterAddress(e.target.value)} value={centerAddress} placeholder="Center Address" />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Village Name</Form.Label>
                                    <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setVillageName(e.target.value)} value={villageName} placeholder="Village Name" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>State</Form.Label>
                                    <Form.Select required style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setState(e.target.value)} value={state}>
                                        <option value="" selected="selected" disabled="disabled">Select State</option>
                                        {stateArray.map((d) => (
                                            <option value={d}>{d}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            {
                                state === '' ?
                                    <Col md={6} lg={4} className="mt-5">
                                        <Form.Group controlId="hospitalState">
                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                <option value="" selected="selected" disabled="disabled">Select State To Display District</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col> :
                                    state === 'Andhra Pradesh' ?
                                        <Col md={6} lg={4} className="mt-5">
                                            <Form.Group controlId="hospitalState">
                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                    {andhraPradeshDistrictArray.map((d) => (
                                                        <option value={d}>{d}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col> :
                                        state === 'Andaman and Nicobar Islands' ?
                                            <Col md={6} lg={4} className="mt-5">
                                                <Form.Group controlId="hospitalState">
                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                        {andamanAndNicobarIslandsDistrictArray.map((d) => (
                                                            <option value={d}>{d}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col> :
                                            state === 'Arunachal Pradesh' ?
                                                <Col md={6} lg={4} className="mt-5">
                                                    <Form.Group controlId="hospitalState">
                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                            {arunachalPradeshDistrictArray.map((d) => (
                                                                <option value={d}>{d}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col> :
                                                state === 'Assam' ?
                                                    <Col md={6} lg={4} className="mt-5">
                                                        <Form.Group controlId="hospitalState">
                                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                {assamDistrictArray.map((d) => (
                                                                    <option value={d}>{d}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col> :
                                                    state === 'Bihar' ?
                                                        <Col md={6} lg={4} className="mt-5">
                                                            <Form.Group controlId="hospitalState">
                                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                    {biharDistrictArray.map((d) => (
                                                                        <option value={d}>{d}</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Col> :
                                                        state === 'Chandigarh' ?
                                                            <Col md={6} lg={4} className="mt-5">
                                                                <Form.Group controlId="hospitalState">
                                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                        {chandigarhDistrictArray.map((d) => (
                                                                            <option value={d}>{d}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </Form.Group>
                                                            </Col> :
                                                            state === 'Chhattisgarh' ?
                                                                <Col md={6} lg={4} className="mt-5">
                                                                    <Form.Group controlId="hospitalState">
                                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                            {chhattisgarhDistrictArray.map((d) => (
                                                                                <option value={d}>{d}</option>
                                                                            ))}
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                </Col> :
                                                                state === 'Dadar and Nagar Haveli' ?
                                                                    <Col md={6} lg={4} className="mt-5">
                                                                        <Form.Group controlId="hospitalState">
                                                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                {dadarAndNagarHaveliDistrictArray.map((d) => (
                                                                                    <option value={d}>{d}</option>
                                                                                ))}
                                                                            </Form.Select>
                                                                        </Form.Group>
                                                                    </Col> :
                                                                    state === 'Daman and Diu' ?
                                                                        <Col md={6} lg={4} className="mt-5">
                                                                            <Form.Group controlId="hospitalState">
                                                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                    {damanAndDiuDistrictArray.map((d) => (
                                                                                        <option value={d}>{d}</option>
                                                                                    ))}
                                                                                </Form.Select>
                                                                            </Form.Group>
                                                                        </Col> :
                                                                        state === 'Delhi' ?
                                                                            <Col md={6} lg={4} className="mt-5">
                                                                                <Form.Group controlId="hospitalState">
                                                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                        {delhiDistrictArray.map((d) => (
                                                                                            <option value={d}>{d}</option>
                                                                                        ))}
                                                                                    </Form.Select>
                                                                                </Form.Group>
                                                                            </Col> :
                                                                            state === 'Lakshadweep' ?
                                                                                <Col md={6} lg={4} className="mt-5">
                                                                                    <Form.Group controlId="hospitalState">
                                                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                            {LakshadweepDistrictArray.map((d) => (
                                                                                                <option value={d}>{d}</option>
                                                                                            ))}
                                                                                        </Form.Select>
                                                                                    </Form.Group>
                                                                                </Col> :
                                                                                state === 'Puducherry' ?
                                                                                    <Col md={6} lg={4} className="mt-5">
                                                                                        <Form.Group controlId="hospitalState">
                                                                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                {puducherryDistrictArray.map((d) => (
                                                                                                    <option value={d}>{d}</option>
                                                                                                ))}
                                                                                            </Form.Select>
                                                                                        </Form.Group>
                                                                                    </Col> :
                                                                                    state === 'Goa' ?
                                                                                        <Col md={6} lg={4} className="mt-5">
                                                                                            <Form.Group controlId="hospitalState">
                                                                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                    {goaDistrictArray.map((d) => (
                                                                                                        <option value={d}>{d}</option>
                                                                                                    ))}
                                                                                                </Form.Select>
                                                                                            </Form.Group>
                                                                                        </Col> :
                                                                                        state === 'Gujarat' ?
                                                                                            <Col md={6} lg={4} className="mt-5">
                                                                                                <Form.Group controlId="hospitalState">
                                                                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                        {gujaratDistrictArray.map((d) => (
                                                                                                            <option value={d}>{d}</option>
                                                                                                        ))}
                                                                                                    </Form.Select>
                                                                                                </Form.Group>
                                                                                            </Col> :
                                                                                            state === 'Jammu and Kashmir' ?
                                                                                                <Col md={6} lg={4} className="mt-5">
                                                                                                    <Form.Group controlId="hospitalState">
                                                                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                            {jammuAndKashmirDistrictArray.map((d) => (
                                                                                                                <option value={d}>{d}</option>
                                                                                                            ))}
                                                                                                        </Form.Select>
                                                                                                    </Form.Group>
                                                                                                </Col> :
                                                                                                state === 'Himachal Pradesh' ?
                                                                                                    <Col md={6} lg={4} className="mt-5">
                                                                                                        <Form.Group controlId="hospitalState">
                                                                                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                {himachalPradeshDistrictArray.map((d) => (
                                                                                                                    <option value={d}>{d}</option>
                                                                                                                ))}
                                                                                                            </Form.Select>
                                                                                                        </Form.Group>
                                                                                                    </Col> :
                                                                                                    state === 'Jharkhand' ?
                                                                                                        <Col md={6} lg={4} className="mt-5">
                                                                                                            <Form.Group controlId="hospitalState">
                                                                                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                    {jharkhandDistrictArray.map((d) => (
                                                                                                                        <option value={d}>{d}</option>
                                                                                                                    ))}
                                                                                                                </Form.Select>
                                                                                                            </Form.Group>
                                                                                                        </Col> :
                                                                                                        state === 'Karnataka' ?
                                                                                                            <Col md={6} lg={4} className="mt-5">
                                                                                                                <Form.Group controlId="hospitalState">
                                                                                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                        {karnatakaDistrictArray.map((d) => (
                                                                                                                            <option value={d}>{d}</option>
                                                                                                                        ))}
                                                                                                                    </Form.Select>
                                                                                                                </Form.Group>
                                                                                                            </Col> :
                                                                                                            state === 'Kerala' ?
                                                                                                                <Col md={6} lg={4} className="mt-5">
                                                                                                                    <Form.Group controlId="hospitalState">
                                                                                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                            {keralaDistrictArray.map((d) => (
                                                                                                                                <option value={d}>{d}</option>
                                                                                                                            ))}
                                                                                                                        </Form.Select>
                                                                                                                    </Form.Group>
                                                                                                                </Col> :
                                                                                                                state === 'Madhya Pradesh' ?
                                                                                                                    <Col md={6} lg={4} className="mt-5">
                                                                                                                        <Form.Group controlId="hospitalState">
                                                                                                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                {madhyaPradeshDistrictArray.map((d) => (
                                                                                                                                    <option value={d}>{d}</option>
                                                                                                                                ))}
                                                                                                                            </Form.Select>
                                                                                                                        </Form.Group>
                                                                                                                    </Col> :
                                                                                                                    state === 'Maharashtra' ?
                                                                                                                        <Col md={6} lg={4} className="mt-5">
                                                                                                                            <Form.Group controlId="hospitalState">
                                                                                                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                    {maharashtraDistrictArray.map((d) => (
                                                                                                                                        <option value={d}>{d}</option>
                                                                                                                                    ))}
                                                                                                                                </Form.Select>
                                                                                                                            </Form.Group>
                                                                                                                        </Col> :
                                                                                                                        state === 'Manipur' ?
                                                                                                                            <Col md={6} lg={4} className="mt-5">
                                                                                                                                <Form.Group controlId="hospitalState">
                                                                                                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                        {manipurDistrictArray.map((d) => (
                                                                                                                                            <option value={d}>{d}</option>
                                                                                                                                        ))}
                                                                                                                                    </Form.Select>
                                                                                                                                </Form.Group>
                                                                                                                            </Col> :
                                                                                                                            state === 'Meghalaya' ?
                                                                                                                                <Col md={6} lg={4} className="mt-5">
                                                                                                                                    <Form.Group controlId="hospitalState">
                                                                                                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                            {meghalayaDistrictArray.map((d) => (
                                                                                                                                                <option value={d}>{d}</option>
                                                                                                                                            ))}
                                                                                                                                        </Form.Select>
                                                                                                                                    </Form.Group>
                                                                                                                                </Col> :
                                                                                                                                state === 'Mizoram' ?
                                                                                                                                    <Col md={6} lg={4} className="mt-5">
                                                                                                                                        <Form.Group controlId="hospitalState">
                                                                                                                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                {mizoramDistrictArray.map((d) => (
                                                                                                                                                    <option value={d}>{d}</option>
                                                                                                                                                ))}
                                                                                                                                            </Form.Select>
                                                                                                                                        </Form.Group>
                                                                                                                                    </Col> :
                                                                                                                                    state === 'Nagaland' ?
                                                                                                                                        <Col md={6} lg={4} className="mt-5">
                                                                                                                                            <Form.Group controlId="hospitalState">
                                                                                                                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                    {nagalandDistrictArray.map((d) => (
                                                                                                                                                        <option value={d}>{d}</option>
                                                                                                                                                    ))}
                                                                                                                                                </Form.Select>
                                                                                                                                            </Form.Group>
                                                                                                                                        </Col> :
                                                                                                                                        state === 'Odisha' ?
                                                                                                                                            <Col md={6} lg={4} className="mt-5">
                                                                                                                                                <Form.Group controlId="hospitalState">
                                                                                                                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                        {odishaDistrictArray.map((d) => (
                                                                                                                                                            <option value={d}>{d}</option>
                                                                                                                                                        ))}
                                                                                                                                                    </Form.Select>
                                                                                                                                                </Form.Group>
                                                                                                                                            </Col> :
                                                                                                                                            state === 'Punjab' ?
                                                                                                                                                <Col md={6} lg={4} className="mt-5">
                                                                                                                                                    <Form.Group controlId="hospitalState">
                                                                                                                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                            {punjabDistrictArray.map((d) => (
                                                                                                                                                                <option value={d}>{d}</option>
                                                                                                                                                            ))}
                                                                                                                                                        </Form.Select>
                                                                                                                                                    </Form.Group>
                                                                                                                                                </Col> :
                                                                                                                                                state === 'Rajasthan' ?
                                                                                                                                                    <Col md={6} lg={4} className="mt-5">
                                                                                                                                                        <Form.Group controlId="hospitalState">
                                                                                                                                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                                <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                                {rajasthanDistrictArray.map((d) => (
                                                                                                                                                                    <option value={d}>{d}</option>
                                                                                                                                                                ))}
                                                                                                                                                            </Form.Select>
                                                                                                                                                        </Form.Group>
                                                                                                                                                    </Col> :
                                                                                                                                                    state === 'Sikkim' ?
                                                                                                                                                        <Col md={6} lg={4} className="mt-5">
                                                                                                                                                            <Form.Group controlId="hospitalState">
                                                                                                                                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                                    {sikkimDistrictArray.map((d) => (
                                                                                                                                                                        <option value={d}>{d}</option>
                                                                                                                                                                    ))}
                                                                                                                                                                </Form.Select>
                                                                                                                                                            </Form.Group>
                                                                                                                                                        </Col> :
                                                                                                                                                        state === 'Tamil Nadu' ?
                                                                                                                                                            <Col md={6} lg={4} className="mt-5">
                                                                                                                                                                <Form.Group controlId="hospitalState">
                                                                                                                                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                                        {tamilNaduDistrictArray.map((d) => (
                                                                                                                                                                            <option value={d}>{d}</option>
                                                                                                                                                                        ))}
                                                                                                                                                                    </Form.Select>
                                                                                                                                                                </Form.Group>
                                                                                                                                                            </Col> :
                                                                                                                                                            state === 'Telangana' ?
                                                                                                                                                                <Col md={6} lg={4} className="mt-5">
                                                                                                                                                                    <Form.Group controlId="hospitalState">
                                                                                                                                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                                            {telanganaDistrictArray.map((d) => (
                                                                                                                                                                                <option value={d}>{d}</option>
                                                                                                                                                                            ))}
                                                                                                                                                                        </Form.Select>
                                                                                                                                                                    </Form.Group>
                                                                                                                                                                </Col> :
                                                                                                                                                                state === 'Tripura' ?
                                                                                                                                                                    <Col md={6} lg={4} className="mt-5">
                                                                                                                                                                        <Form.Group controlId="hospitalState">
                                                                                                                                                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                                            <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                                                <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                                                {tripuraDistrictArray.map((d) => (
                                                                                                                                                                                    <option value={d}>{d}</option>
                                                                                                                                                                                ))}
                                                                                                                                                                            </Form.Select>
                                                                                                                                                                        </Form.Group>
                                                                                                                                                                    </Col> :
                                                                                                                                                                    state === 'Uttar Pradesh' ?
                                                                                                                                                                        <Col md={6} lg={4} className="mt-5">
                                                                                                                                                                            <Form.Group controlId="hospitalState">
                                                                                                                                                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                                                <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                                                    <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                                                    {uttarPradeshDistrictArray.map((d) => (
                                                                                                                                                                                        <option value={d}>{d}</option>
                                                                                                                                                                                    ))}
                                                                                                                                                                                </Form.Select>
                                                                                                                                                                            </Form.Group>
                                                                                                                                                                        </Col> :
                                                                                                                                                                        state === 'Uttarakhand' ?
                                                                                                                                                                            <Col md={6} lg={4} className="mt-5">
                                                                                                                                                                                <Form.Group controlId="hospitalState">
                                                                                                                                                                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                                                        <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                                                        {uttarakhandDistrictArray.map((d) => (
                                                                                                                                                                                            <option value={d}>{d}</option>
                                                                                                                                                                                        ))}
                                                                                                                                                                                    </Form.Select>
                                                                                                                                                                                </Form.Group>
                                                                                                                                                                            </Col> :
                                                                                                                                                                            state === 'West Bengal' ?
                                                                                                                                                                                <Col md={6} lg={4} className="mt-5">
                                                                                                                                                                                    <Form.Group controlId="hospitalState">
                                                                                                                                                                                        <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                                                                                                                                                                        <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setDistrict(e.target.value)} value={district}>
                                                                                                                                                                                            <option value="" selected="selected" disabled="disabled">Select District</option>
                                                                                                                                                                                            {westBengalDistrictArray.map((d) => (
                                                                                                                                                                                                <option value={d}>{d}</option>
                                                                                                                                                                                            ))}
                                                                                                                                                                                        </Form.Select>
                                                                                                                                                                                    </Form.Group>
                                                                                                                                                                                </Col> : ""
                            }

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Pincode</Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setPincode(e.target.value)} value={pincode} placeholder="Pin Code" onWheel={(e) => e.target.blur()} />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Contact</Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setContact(e.target.value)} value={contact} placeholder="Contact" onWheel={(e) => e.target.blur()} />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Email</Form.Label>
                                    <Form.Control required type="email" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setEmail(e.target.value)} value={email} placeholder="Email" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Radiology Website Link</Form.Label>
                                    <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setWebsiteLink(e.target.value)} value={websiteLink} placeholder="Radiology Website Link" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Select Public Sector</Form.Label>
                                    <Form.Select required style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setPublicSector(e.target.value)} value={publicSector}>
                                        <option value="" selected="selected" disabled="disabled">Select Public Sector</option>
                                        <option value="Central Government">Central Government</option>
                                        <option value="State Government">State Government</option>
                                        <option value="Local Government">Local Government</option>
                                        <option value="Public Sector Undertaken">Public Sector Undertaken</option>
                                        <option value="publicSectorOther">Other</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            {
                                publicSector === 'publicSectorOther' ?
                                    <Col md={6} lg={4} className="mt-5">
                                        <Form.Group>
                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Public Other Sector</Form.Label>
                                            <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setPublicSectorOther(e.target.value)} value={publicSectorOther} placeholder="Public Other Sector" />
                                        </Form.Group>
                                    </Col> : ''
                            }

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Select Private Sector</Form.Label>
                                    <Form.Select required style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setPrivateSector(e.target.value)} value={privateSector}>
                                        <option value="" selected="selected" disabled="disabled">Select Private Sector</option>
                                        <option value="Individual Proprietorship">Individual Proprietorship</option>
                                        <option value="Registered Partnership">Registered Partnership</option>
                                        <option value="Registered Company">Registered Company</option>
                                        <option value="Co-operation Society">Co-operation Society</option>
                                        <option value="Trust and Charitable">Trust and Charitable</option>
                                        <option value="privateSectorOther">Other</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            {
                                privateSector === 'privateSectorOther' ?
                                    <Col md={6} lg={4} className="mt-5">
                                        <Form.Group>
                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Private Other Sector</Form.Label>
                                            <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setPrivateSectorOther(e.target.value)} value={privateSectorOther} placeholder="Private Other Sector" />
                                        </Form.Group>
                                    </Col> : ''
                            }

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Registration No</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setRegistrationNo(e.target.value)} value={registrationNo} placeholder="Registration No" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Latitude</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setLatitude(e.target.value)} value={latitude} placeholder="Latitude" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Longitude</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setLongitude(e.target.value)} value={longitude} placeholder="Longitude" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group className="position-relative mb-3" controlId="contactPersonName">
                                    <Form.Label style={{ fontSize: '12px', fontWeight: 'bold' }}>Upload Radiology Image</Form.Label>
                                    <Form.Control style={{ fontSize: '12px' }} type="file" name="file" onChange={multipleImg} multiple accept="image/*"  />
                                    {
                                        uploadDone ?
                                            <h1 style={{ fontSize: '12px', fontFamily: 'DM Sans', color: 'green', marginTop: '10px' }}>Radiology Image Uploaded Successfully</h1> : ""
                                    }
                                    {
                                        uploadLoading ?
                                            <Spinner animation="border" role="status" size="small" color="#000" style={{ marginTop: '10px' }}>
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner> :
                                            imageKey === 0 ?
                                                <Button size="sm" style={{ fontFamily: 'DM Sans', fontSize: '10px', marginTop: '10px' }} disabled>Upload</Button> :
                                                <Button size="sm" style={{ fontFamily: 'DM Sans', fontSize: '10px', marginTop: '10px' }} onClick={() => handleRadiologyImage()}>Upload</Button>
                                    }
                                </Form.Group>
                            </Col>
                        </Row>

                        {/*----------------- Owner Details ---------------------*/}

                        <Row>
                            <h4 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
                                Owner Details
                                <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '17%', marginTop: 10 }}>
                                    {' '}
                                </div>
                            </h4>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Owner Name</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Owner Name" onChange={(e) => setOwnerName(e.target.value)} value={ownerName} />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Owner Contact</Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Owner Contact" onChange={(e) => setOwnerContact(e.target.value)} value={ownerContact} onWheel={(e) => e.target.blur()} />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Owner Email</Form.Label>
                                    <Form.Control required type="email" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setOwnerEmail(e.target.value)} value={ownerEmail} placeholder="Owner Email" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Bharp Discount</Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setBharpDiscount(e.target.value)} value={bharpDiscount} placeholder="Bharp Discount" />
                                </Form.Group>
                            </Col>

                             <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Vendor Margin</Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setVendorMargin(e.target.value)} value={vendorMargin} placeholder="Vendor Margin" />
                                </Form.Group>
                            </Col>
                        </Row>

                        {/*----------------- License By ---------------------*/}

                        <Row>
                            <h4 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
                                License By
                                <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '17%', marginTop: 10 }}>
                                    {' '}
                                </div>
                            </h4>

                            <Col md={6} lg={4} className="mt-2">
                                <Form.Group controlId="hospitalServices">
                                    <input type="checkbox" value="NABL" checked={licenseBy.some(val => val === "NABL")} onChange={handleChangeLicenseChange} />
                                    <label style={{ marginLeft: '10px' }}>NABL</label><br></br>

                                    <input type="checkbox" value="AERB" checked={licenseBy.some(val => val === "AERB")} onChange={handleChangeLicenseChange} />
                                    <label style={{ marginLeft: '10px' }}>AERB</label><br></br>

                                    <input type="checkbox" value="RSO" checked={licenseBy.some(val => val === "RSO")} onChange={handleChangeLicenseChange} />
                                    <label style={{ marginLeft: '10px' }}>RSO</label><br></br>

                                </Form.Group>
                            </Col>
                        </Row>

                        {/*----------------- Services Details ---------------------*/}

                        <Row>
                            <h4 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
                                Services
                                <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '17%', marginTop: 10 }}>
                                    {' '}
                                </div>
                            </h4>

                            <Col md={6} lg={6} className="mt-2">
                                <Form.Group controlId="hospitalServices">
                                    <input type="checkbox" value="Computed tomography (C.T Scan)" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Computed tomography (C.T Scan)</label><br></br>

                                    {
                                        checkedComputedTomography ?
                                        <>
                                    <label style={{ marginLeft: '10px' }}>Computed tomography (C.T Scan)</label><br></br>
                                      <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Computed tomography (C.T Scan)')} value={ComputedTomographyPrice} />
                                       <br></br>
                                        </>

                                        :""
                                    }

                                    <input type="checkbox" value="Fluoroscopy" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Fluoroscopy</label><br></br>
                                    {
                                        checkedFluoroscopy ?
                                        <>
                                    <label style={{ marginLeft: '10px' }}>Fluoroscopy</label><br></br>
                                      <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Fluoroscopy')} value={FluoroscopyPrice} />
                                       <br></br>
                                        </>

                                        :""
                                    }

                                    <input type="checkbox" value="Magnetic resonance imaging (M.R.I)" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Magnetic resonance imaging (M.R.I)</label><br></br>
                                    {
                                        checkedMagneticResonanceImaging ?
                                        <>
                                    <label style={{ marginLeft: '10px' }}>Magnetic resonance imaging (M.R.I)</label><br></br>
                                      <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Magnetic resonance imaging (M.R.I)')} value={MagneticResonanceImagingPrice} />
                                       <br></br>
                                        </>

                                        :""
                                    }

                                    <input type="checkbox" value="Mammography" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Mammography</label><br></br>
                                    {
                                        checkedMammography ?
                                        <>
                                    <label style={{ marginLeft: '10px' }}>Mammography</label><br></br>
                                      <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Mammography')} value={MammographyPrice} />
                                       <br></br>
                                        </>

                                        :""
                                    }

                                    <input type="checkbox" value="Magnetic resonance angiography (M.R.A)" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Magnetic resonance angiography (M.R.A)</label><br></br>
                                    {
                                        checkedMagneticResonanceAngiography ?
                                        <>
                                    <label style={{ marginLeft: '10px' }}>Magnetic resonance angiography (M.R.A)</label><br></br>
                                      <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Magnetic resonance angiography (M.R.A)')} value={MagneticResonanceAngiographyPrice} />
                                       <br></br>
                                        </>

                                        :""
                                    }

                                    <input type="checkbox" value="X-ray" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>X-ray</label><br></br>
                                    {
                                        checkedXray ?
                                        <>
                                    <label style={{ marginLeft: '10px' }}>X-ray</label><br></br>
                                      <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'X-ray')} value={XrayPrice} />
                                       <br></br>
                                        </>

                                        :""
                                    }

                                    <input type="checkbox" value="Positron emission tomography (PET-Scan)" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Positron emission tomography (PET-Scan)</label><br></br>
                                    {
                                        checkedPositronEmissionTomography ?
                                        <>
                                    <label style={{ marginLeft: '10px' }}>Positron emission tomography (PET-Scan)</label><br></br>
                                      <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Positron emission tomography (PET-Scan)')} value={PositronEmissionTomographyPrice} />
                                       <br></br>
                                        </>

                                        :""
                                    }

                                    <input type="checkbox" value="Ultrasound" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Ultrasound</label><br></br>

                                    {
                                        checkedUltrasound ?
                                        <>
                                    <label style={{ marginLeft: '10px' }}>Ultrasound</label><br></br>
                                      <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Ultrasound')} value={UltrasoundPrice} />
                                       <br></br>
                                        </>

                                        :""
                                    }
                                    <input type="checkbox" value="Angiography" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Angiography</label><br></br>
                                    {
                                        checkedAngiography ?
                                        <>
                                    <label style={{ marginLeft: '10px' }}>Angiography</label><br></br>
                                      <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Angiography')} value={AngiographyPrice} />
                                       <br></br>
                                        </>

                                        :""
                                    }
                                    <input type="checkbox" value="Diagnosis" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Diagnosis</label><br></br>
                                    {
                                        checkedDiagnosis ?
                                        <>
                                    <label style={{ marginLeft: '10px' }}>Diagnosis</label><br></br>
                                      <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Diagnosis')} value={DiagnosisPrice} />
                                       <br></br>
                                        </>

                                        :""
                                    }
                                    <input type="checkbox" value="Cancer treatment" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Cancer treatment</label><br></br>
                                    {
                                        checkedCancerTreatment ?
                                        <>
                                    <label style={{ marginLeft: '10px' }}>Cancer treatment</label><br></br>
                                      <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Cancer treatment')} value={CancerTreatmentPrice} />
                                       <br></br>
                                        </>

                                        :""
                                    }
                                    <input type="checkbox" value="Tumor ablation" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Tumor ablation</label><br></br>
                                    {
                                        checkedTumorAblation ?
                                        <>
                                    <label style={{ marginLeft: '10px' }}>Tumor ablation</label><br></br>
                                      <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Tumor ablation')} value={TumorAblationPrice} />
                                       <br></br>
                                        </>

                                        :""
                                    }

                                    <input type="checkbox" value="Needle biopsy" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Needle biopsy</label><br></br>
                                    {
                                        checkedNiddleBiopsy ?
                                        <>
                                    <label style={{ marginLeft: '10px' }}>Needle biopsy</label><br></br>
                                      <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Needle biopsy')} value={NiddleBiopsyPrice} />
                                       <br></br>
                                        </>

                                        :""
                                    }

                                    <input type="checkbox" value="Breast biopsy" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Breast biopsy</label><br></br>
                                  {
                                        checkedBreastBiopsy ?
                                        <>
                                    <label style={{ marginLeft: '10px' }}>Breast biopsy</label><br></br>
                                      <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Breast biopsy')} value={BreastBiopsyPrice} />
                                       <br></br>
                                        </>

                                        :""
                                    }
                                    <input type="checkbox" value="Uterine artery embolization" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Uterine artery embolization</label><br></br>
                                    {
                                        checkedUterineArteryEmbolization ?
                                        <>
                                    <label style={{ marginLeft: '10px' }}>Uterine artery embolization</label><br></br>
                                      <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Uterine artery embolization')} value={UterineArteryEmbolizationPrice} />
                                       <br></br>
                                        </>

                                        :""
                                    }

                                    <input type="checkbox" value="Brain scanning" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Brain scanning</label><br></br>
                                    {
                                        checkedBrainScanning?
                                        <>
                                    <label style={{ marginLeft: '10px' }}>Brain scanning</label><br></br>
                                      <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Brain scanning')} value={BrainScanningPrice} />
                                       <br></br>
                                        </>

                                        :""
                                    }

                                    <input type="checkbox" value="Echocardiography" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Echocardiography</label><br></br>
 {
                                        checkedEchocardiography ?
                                        <>
                                    <label style={{ marginLeft: '10px' }}>Echocardiography</label><br></br>
                                      <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Echocardiography')} value={EchocardiographyPrice} />
                                       <br></br>
                                        </>

                                        :""
                                    }

                                    <input type="checkbox" value="Urography" onChange={handleChangeforFacilities} />
                                    <label style={{ marginLeft: '10px' }}>Urography</label><br></br>
 {
                                        checkedUrography ?
                                        <>
                                    <label style={{ marginLeft: '10px' }}>Urography</label><br></br>
                                      <input type="number" onChange={(e) => handleChangeforFacilitiesPrice(e.target.value, 'Urography')} value={UrographyPrice} />
                                       <br></br>
                                        </>

                                        :""
                                    }

{
                    serviceTypeStateOther.length === 0 ?
                      <Button onClick={() => addServiceTypeField()}>Add Other Services</Button> : ""  
                  }
                                    {
                    serviceTypeStateOther.map((input, key) => (
                      <>
                    <br></br>
                        <input placeholder="Service Name" type="text" style={{ color: "#000", borderColor: '#000', borderWidth: 1, fontSize: 10, height: 40 ,width:200}} onChange={(text) => handleServiceTypeChange(text.target.value, key, 'serviceName')} value={input.serviceName} />
                         <br></br><br></br>
                        <input placeholder="Service Price" type="number" style={{ color: "#000", borderColor: '#000', borderWidth: 1, fontSize: 10, height: 40,width:200}} onChange={(text) => handleServiceTypeChange(text.target.value, key, 'servicePrice')} value={input.servicePrice} />
                        <div style={{display:"flex",marginTop:10}}>
                          
                        <Button onClick={() => addServiceTypeField()}><FiPlus/></Button>
                        <Button onClick={() => removeServiceTypeField(key)} style={{marginLeft:35}}><FiMinus/></Button>
                        </div>
                          <br></br><br></br>
                      </>
                    ))}

                  {
                    serviceTypeStateOther.length !== 0 ?
                      <Button variant='warning' onClick={() => mergeClick()}>Add</Button>
                      : ""
                  }
                                </Form.Group>
                            </Col>
                        </Row>

                        {/*----------------- Radiologist Details ---------------------*/}

                        <Row>
                            <h4 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
                                Pathologist Details
                                <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '17%', marginTop: 10 }}>
                                    {' '}
                                </div>
                            </h4>


                            {radiologistDetails.map((input, key) => (
                                <Row>
                                    <h6 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
                                        Radiologist {(counter += 1)}
                                        <div style={{ backgroundColor: '#ffb606 ', height: 1, width: '7%', marginTop: 10 }}>
                                            {' '}
                                        </div>
                                    </h6>
                                    <Col md={4} lg={4} className="mt-5">
                                        <Form.Group>
                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Radiologist Full Name</Form.Label>
                                            <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Radiologist Full Name" onChange={(e) => handleChange(e.target.value, key, 'name')} value={input.name} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} lg={4} className="mt-5">
                                        <Form.Group>
                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Radiologist Qualification</Form.Label>
                                            <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Separeated by comma " onChange={(e) => handleChange(e.target.value, key, 'qualification')} value={input.qualification} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} lg={4} className="mt-5">
                                        <Form.Group>
                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Radiologist Experience</Form.Label>
                                            <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="In Year" onChange={(e) => handleChange(e.target.value, key, 'experience')} value={input.experience} onWheel={(e) => e.target.blur()} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} lg={4} className="mt-5">
                                        <Form.Group>
                                            <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Radiologist RP ID</Form.Label>
                                            <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Radiologist RP ID" onChange={(e) => handleChange(e.target.value, key, 'rpId')} value={input.rpId} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            ))}
                            <Col md={6} lg={4} className="mt-5">
                                <Button onClick={() => addFormFields()} className="m-1" style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>
                                    <AiFillPlusCircle style={{ fontSize: '20px' }} />
                                </Button>
                            </Col>
                        </Row>

                        {
                            loading ?
                                <Button variant="primary" disabled className="mt-5" style={{ fontFamily: 'DM Sans', fontSize: '12px', marginBottom: '100px' }}>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    <span className="visually-hidden">Loading...</span>
                                </Button> :
                                <Button type="submit" className="mt-5" style={{ fontFamily: 'DM Sans', fontSize: '12px', marginBottom: '100px' }}>Add Radiology</Button>
                        }
                    </Form>
                </Container>
            </div>
        </>
    )
}
