import '../../../ComponentCss/AllHospitals.css';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import EditHospital from './EditHospital';
import { DELETE_HOSPITAL, UPDATE_HOSPITAL_STATUS } from '../../../graphql/mutations';
import { GET_ALL_HOSPITALS } from '../../../graphql/queries';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { FaEye, FaEyeSlash, FaTrash } from "react-icons/fa";
import ViewHospital from './ViewHospital';

function HospitalRow({ hospital, index, pass }) {
  let count = 1
  const navigate = useNavigate()

  const [showPass, setShowPass] = useState(false)
  const [show, setShow] = useState(false);
  const [deleteshow, setDeleteShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [viewshow, setViewShow] = useState(false);
  const handleViewClose = () => setViewShow(false);
  const handleViewShow = () => setViewShow(true);

  const deletehandleClose = () => {
    setDeleteShow(false);
  };
  const deletehandleShow = () => setDeleteShow(true);
  const [deleteHospital] = useMutation(DELETE_HOSPITAL, {
    variables: { hospitalId: hospital.id },
    refetchQueries: [{ query: GET_ALL_HOSPITALS }, 'GetAllHospital'],
  });

  const [updateHospitalStatus] = useMutation(UPDATE_HOSPITAL_STATUS)

  const block = () => {
    updateHospitalStatus({
      variables: { updateStatusInput: { id: hospital.id, status: 'inactive' } },
      refetchQueries: [{ query: GET_ALL_HOSPITALS }, 'GetAllHospital'],
    });
  };

  const unblock = () => {
    updateHospitalStatus({
      variables: { updateStatusInput: { id: hospital.id, status: 'active' } },
      refetchQueries: [{ query: GET_ALL_HOSPITALS }, 'GetAllHospital'],
    });
  };

  function handleDetail(id) {
    navigate('/hospitalDetail/' + id)
  }

  return (
    <>
      <tr>
        {/* <td>{count++}</td> */}
        <td>{hospital?.hospitalRegistrationNumber}</td>
        <td>{hospital?.hospitalName}</td>
        {
          hospital?.status === 'confirm' ?
            <td style={{ color: 'green' }}>Confirm</td> :
            hospital?.status === 'pending' ?
              <td style={{ color: 'orange' }}>Pending</td> :
              hospital?.status === 'reject' ?
                <td style={{ color: 'red' }}>Reject</td> : ""
        }
        {
          pass ?
            <td>{hospital?.password}</td> :
            <td>*******</td>
        }
        <td>
          <Button onClick={() => handleDetail(hospital.id)} size="sm"><FaEye style={{ marginTop: '-4px' }} /></Button>
        </td>
        {/* <td>
          <Button variant='info' onClick={handleShow} size="sm"><FaPen style={{ marginTop: '-4px', color: '#fff' }} /></Button>
        </td> */}
        <td>
          <Button variant='danger' onClick={deletehandleShow} size="sm"><FaTrash style={{ marginTop: '-4px' }} /></Button>
        </td>
      </tr>
      {/*  */}
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <EditHospital hospital={hospital} />
        </Modal.Body>
      </Modal>

      {/*  */}
      <Modal show={deleteshow} onHide={deletehandleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Hospital?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please confirm deletion.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" size="sm" onClick={() => { deleteHospital(); deletehandleClose(); }}>Yes</Button>
          <Button variant="primary" size="sm" onClick={deletehandleClose}>No</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={viewshow} onHide={handleViewClose} size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <ViewHospital hospital={hospital} />
        </Modal.Body>
      </Modal>
    </>
  );
}
export default HospitalRow;