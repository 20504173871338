import { Form, Row, Col, Button, Container, Spinner } from 'react-bootstrap';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_HOSPITAL } from '../../../graphql/mutations';
import { GET_ALL_HOSPITALS } from '../../../graphql/queries';
import swal from 'sweetalert'

let stateArray = ["Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jammu and Kashmir", "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttarakhand", "Uttar Pradesh", "West Bengal", "Andaman and Nicobar Islands", "Chandigarh", "Dadra and Nagar Haveli", "Daman and Diu", "Delhi", "Lakshadweep", "Puducherry"];

const EditHospital = ({ hospital }) => {
  console.log("hospital", hospital)
  const [validated, setValidated] = useState(false);
  const [updateHospital, { loading }] = useMutation(UPDATE_HOSPITAL);
  const [facilitiesState, setFacilitiesState] = useState(hospital.hospitalServices);
  const [mFacilitiesState, setMfacilitiesState] = useState(hospital.hospitalMiscellaneousFacilities);
  const [acceptanceState, setAcceptancesState] = useState(hospital.hospitalAcceptanceOfBeneficialServices);


  const [hospitalName, setHospitalName] = useState(hospital.hospitalName);
  const [hospitalType, setHospitalType] = useState(hospital.hospitalType);
  const [hospitalAccreditation, setHospitalAccreditation] = useState(hospital.hospitalAccreditation);
  const [hospitalHealthCareProviderType, setHospitalHealthCareProviderType] = useState(hospital.hospitalHealthCareProviderType);
  const [hospitalRegistrationNumber, setHospitalRegistrationNumber] = useState(hospital.hospitalRegistrationNumber);
  const [hospitalAddress, setHospitalAddress] = useState(hospital.hospitalAddress);
  const [hospitalCity, setHospitalCity] = useState(hospital.hospitalCity);
  const [hospitalDistrict, setHospitalDistrict] = useState(hospital.hospitalDistrict);
  const [hospitalState, setHospitalState] = useState(hospital.hospitalState);
  const [hospitalPincode, setHospitalPincode] = useState(hospital.hospitalPincode);
  const [nodalPersonName, setNodalPersonName] = useState(hospital.nodalPersonName);
  const [nodalPersonDesignation, setNodalPersonDesignation] = useState(hospital.nodalPersonDesignation);
  const [nodalPersonContact, setNodalPersonContact] = useState(hospital.nodalPersonContact);
  const [nodalPersonEmail, setNodalPersonEmail] = useState(hospital.nodalPersonEmail);
  const [hospitalHelpdeskContact, setHospitalHelpdeskContact] = useState(hospital.hospitalHelpdeskContact);
  const [hospitalEmergencyContact, setHospitalEmergencyContact] = useState(hospital.hospitalEmergencyContact);
  const [hospitalAmbulanceContact, setHospitalAmbulanceContact] = useState(hospital.hospitalAmbulanceContact);
  const [hospitalFaxNumber, setHospitalFaxNumber] = useState(hospital.hospitalFaxNumber);
  const [hospitalBloodBankContact, setHospitalBloodBankContact] = useState(hospital.hospitalBloodBankContact);
  const [hospitalEmail, setHospitalEmail] = useState(hospital.hospitalEmail);
  const [hospitalWebsiteLink, setHospitalWebsiteLink] = useState(hospital.hospitalWebsiteLink);
  const [hospitalEstablishedDate, setHospitalEstablishedDate] = useState(hospital.hospitalEstablishedDate);
  const [hospitalLatitude, setHospitalLatitude] = useState(hospital.hospitalLatitude);
  const [hospitalLongitude, setHospitalLongitude] = useState(hospital.hospitalLongitude);
  const [noOfDoctors, setNoOfDoctors] = useState(hospital.noOfDoctors);
  const [noOfMedicalConsultants, setNoOfMedicalConsultants] = useState(hospital.noOfMedicalConsultants);
  const [noOfNurses, setNoOfNurses] = useState(hospital.noOfNurses);
  const [noOfBeds, setNoOfBeds] = useState(hospital.noOfBeds);
  const [noOfPrivateBeds, setNoOfPrivateBeds] = useState(hospital.noOfPrivateBeds);
  const [noOfEmergencyBeds, setNoOfEmergencyBeds] = useState(hospital.noOfEmergencyBeds);
  const [isEmergencyServiceAvailable, setIsEmergencyServiceAvailable] = useState(hospital.isEmergencyServiceAvailable);
  const [isAmbulanceServiceAvailable, setIsAmbulanceServiceAvailable] = useState(hospital.isAmbulanceServiceAvailable);


  const handleChangeforMFacilities = event => {
    const { checked, value } = event.currentTarget;
    setMfacilitiesState(
      prev => checked
        ? [...prev, value]
        : prev.filter(val => val !== value)
    );
  };

  const handleChangeforFacilities = event => {
    const { checked, value } = event.currentTarget;
    setFacilitiesState(
      prev => checked
        ? [...prev, value]
        : prev.filter(val => val !== value)
    );
  };

  const handleChangeforAcceptance = event => {
    const { checked, value } = event.currentTarget;
    setAcceptancesState(
      prev => checked
        ? [...prev, value]
        : prev.filter(val => val !== value)
    );
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
    }
    else {
      e.preventDefault();
      updateHospital({
        variables: {
          "updateHospitalInput": {
            "id": `${hospital.id}`,
            "hospitalName": `${hospitalName}`,
            "hospitalType": `${hospitalType}`,
            "hospitalAccreditation": `${hospitalAccreditation}`,
            "hospitalAddress": `${hospitalAddress}`,
            "hospitalCity": `${hospitalCity}`,
            "hospitalDistrict": `${hospitalDistrict}`,
            "hospitalState": `${hospitalState}`,
            "hospitalPincode": `${hospitalPincode}`,
            "nodalPersonName": `${nodalPersonName}`,
            "nodalPersonDesignation": `${nodalPersonDesignation}`,
            "nodalPersonContact": `${nodalPersonContact}`,
            "nodalPersonEmail": `${nodalPersonEmail}`,
            "hospitalHelpdeskContact": `${hospitalHelpdeskContact}`,
            "hospitalEmergencyContact": `${hospitalEmergencyContact}`,
            "hospitalAmbulanceContact": `${hospitalAmbulanceContact}`,
            "hospitalFaxNumber": `${hospitalFaxNumber}`,
            "hospitalBloodBankContact": `${hospitalBloodBankContact}`,
            "hospitalEmail": `${hospitalEmail}`,
            "hospitalWebsiteLink": `${hospitalWebsiteLink}`,
            "hospitalEstablishedDate": `${hospitalEstablishedDate}`,
            "hospitalLatitude": `${hospitalEstablishedDate}`,
            "hospitalLongitude": `${hospitalEstablishedDate}`,
            "hospitalServices": facilitiesState,
            "hospitalMiscellaneousFacilities": mFacilitiesState,
            "hospitalAcceptanceOfBeneficialServices": acceptanceState,
            "noOfDoctors": `${noOfDoctors}`,
            "noOfMedicalConsultants": `${noOfMedicalConsultants}`,
            "noOfNurses": `${noOfNurses}`,
            "noOfBeds": parseInt(noOfBeds),
            "noOfPrivateBeds": parseInt(noOfPrivateBeds),
            "noOfEmergencyBeds": parseInt(noOfEmergencyBeds),
            "isEmergencyServiceAvailable": `${isEmergencyServiceAvailable}`,
            "isAmbulanceServiceAvailable": `${isAmbulanceServiceAvailable}`
          }
        },
        refetchQueries: [{ query: GET_ALL_HOSPITALS }, 'GetAllHospital'],
        onCompleted: () => {
          setValidated(false);
          setHospitalName();
          setHospitalType();
          setHospitalAccreditation();
          setHospitalHealthCareProviderType();
          setHospitalRegistrationNumber();
          setHospitalAddress();
          setHospitalCity();
          setHospitalDistrict();
          setHospitalState()
          setHospitalPincode()
          setNodalPersonName()
          setNodalPersonDesignation()
          setNodalPersonContact()
          setNodalPersonEmail()
          setHospitalHelpdeskContact()
          setHospitalEmergencyContact()
          setHospitalAmbulanceContact()
          setHospitalFaxNumber()
          setHospitalBloodBankContact()
          setHospitalEmail()
          setHospitalWebsiteLink()
          setHospitalEstablishedDate()
          setHospitalLatitude()
          setHospitalLongitude()
          setNoOfDoctors()
          setNoOfMedicalConsultants()
          setNoOfNurses()
          setNoOfBeds()
          setNoOfPrivateBeds()
          setNoOfEmergencyBeds()
          setIsEmergencyServiceAvailable()
          setIsAmbulanceServiceAvailable()
          setFacilitiesState([])
          setMfacilitiesState([])
          setAcceptancesState([])
          swal({ title: "Successfull!!!", text: "Hospital Update Successfully", icon: "success" });
        },
      });
    }
  };

  if (loading) {
    return (
      <Spinner animation="border" role="status" className="mx-auto d-block">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    )
  }


  return (
    <>
      <div style={{ overflow: 'scroll', height: '1000px', marginTop: '20px' }}>
        <Container>
          <h1 style={{ fontFamily: 'DM Sans', fontWeight: 'bold' }}>Add a Hospital</h1>
          <hr />
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
              <h4 className="mt-5" style={{ fontFamily: 'DM Sans' }}>Hospital Details
                <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '15%', marginTop: 10 }}>
                  {' '}
                </div></h4>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalName">
                  <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalName" onChange={e => setHospitalName(e.target.value)} value={hospitalName} placeholder="Hospital Name" />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalType">
                  <Form.Select name="hospitalType" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={e => setHospitalType(e.target.value)} value={hospitalType}>
                    <option value="Government Center">Government Center</option>
                    <option value="Private Center">Private Center</option>
                    <option value="Semi-Government">Semi-Government</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalAccreditation">
                  <Form.Select name="hospitalAccreditation" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={e => setHospitalAccreditation(e.target.value)} value={hospitalAccreditation}>
                    <option value="NABH">NABH</option>
                    <option value="NABL">NABL</option>
                    <option value="JCI">JCI</option>
                    <option value="ISO">ISO</option>
                    <option value="Others">Others</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalHealthCareProviderType">
                  <Form.Select name="hospitalHealthCareProviderType" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={e => setHospitalHealthCareProviderType(e.target.value)} value={hospitalHealthCareProviderType}>
                    <option value="Hospital Dispensary">Hospital Dispensary</option>
                    <option value="Nursing Home">Nursing Home</option>
                    <option value="Medical College">Medical College</option>
                    <option value="Primary Health Center">Primary Health Center</option>
                    <option value="Clinic">Clinic</option>
                    <option value="Others">Others</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalRegistrationNumber">
                  <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalRegistrationNumber" onChange={e => setHospitalRegistrationNumber(e.target.value)} value={hospitalRegistrationNumber} placeholder="Registration Number" />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalAddress">
                  <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalAddress" onChange={e => setHospitalAddress(e.target.value)} value={hospitalAddress} placeholder="Address" />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalCity">
                  <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalCity" onChange={e => setHospitalCity(e.target.value)} value={hospitalCity} placeholder="City" />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalDistrict">
                  <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalDistrict" onChange={e => setHospitalDistrict(e.target.value)} value={hospitalDistrict} placeholder="District" />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalState">
                  <Form.Select name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={e => setHospitalState(e.target.value)} value={hospitalState}>
                    {
                      stateArray.map(d =>
                        <option value={d}>{d}</option>
                      )
                    }
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalPincode">
                  <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalPincode" onChange={e => setHospitalPincode(e.target.value)} value={hospitalPincode} placeholder="Pin Code" />
                </Form.Group>
              </Col>
            </Row>
            <h3 className="mt-5" style={{ fontFamily: 'DM Sans' }}>Nodal Person Details
              <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '15%', marginTop: 10 }}>
                {' '}
              </div></h3>
            <Row>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="nodalPersonName">
                  <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="nodalPersonName" onChange={e => setNodalPersonName(e.target.value)} value={nodalPersonName} placeholder="Name" />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="nodalPersonDesignation">
                  <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="nodalPersonDesignation" onChange={e => setNodalPersonDesignation(e.target.value)} value={nodalPersonDesignation} placeholder="Designation" />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="nodalPersonContact">
                  <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="nodalPersonContact" onChange={e => setNodalPersonContact(e.target.value)} value={nodalPersonContact} placeholder="Contact Number" />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="nodalPersonEmail">
                  <Form.Control type="email" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="nodalPersonEmail" onChange={e => setNodalPersonEmail(e.target.value)} value={nodalPersonEmail} placeholder="Email" />
                </Form.Group>
              </Col>
            </Row>
            <h3 className="mt-5" style={{ fontFamily: 'DM Sans' }}>Hospital Additional Details
              <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '15%', marginTop: 10 }}>
                {' '}
              </div></h3>
            <Row>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalHelpdeskContact">
                  <Form.Control type="number" name="hospitalHelpdeskContact" onChange={e => setHospitalHelpdeskContact(e.target.value)} value={hospitalHelpdeskContact} placeholder="Helpdesk Contact" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalEmergencyContact">
                  <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalEmergencyContact" onChange={e => setHospitalEmergencyContact(e.target.value)} value={hospitalEmergencyContact} placeholder="Emergency Contact" />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalAmbulanceContact">
                  <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalAmbulanceContact" onChange={e => setHospitalAmbulanceContact(e.target.value)} value={hospitalAmbulanceContact} placeholder="Ambulance Contact" />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalFaxNumber">
                  <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalFaxNumber" onChange={e => setHospitalFaxNumber(e.target.value)} value={hospitalFaxNumber} placeholder="Fax Number" />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalBloodBankContact">
                  <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalBloodBankContact" onChange={e => setHospitalBloodBankContact(e.target.value)} value={hospitalBloodBankContact} placeholder="Blood Bank Contact" />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalEmail">
                  <Form.Control type="email" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalEmail" onChange={e => setHospitalEmail(e.target.value)} value={hospitalEmail} placeholder="Email" />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalWebsiteLink">
                  <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalWebsiteLink" onChange={e => setHospitalWebsiteLink(e.target.value)} value={hospitalWebsiteLink} placeholder="Website Link" />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-3">
                <Form.Group controlId="hospitalEstablishedDate">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>Established On</Form.Label>
                  <Form.Control type="date" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalEstablishedDate" onChange={e => setHospitalEstablishedDate(e.target.value)} value={hospitalEstablishedDate} />
                </Form.Group>
              </Col>
            </Row>
            <h4 className="mt-5" style={{ fontFamily: 'DM Sans' }}>Hospital Google Coordinates
              <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '15%', marginTop: 10 }}>
                {' '}
              </div></h4>
            <Row>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalLatitude">
                  <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalLatitude" onChange={e => setHospitalLatitude(e.target.value)} value={hospitalLatitude} placeholder="Latitude" />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalLongitude">
                  <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalLongitude" onChange={e => setHospitalLongitude(e.target.value)} value={hospitalLongitude} placeholder="Longitude" />
                </Form.Group>
              </Col>
            </Row>
            <h4 className="mt-5" style={{ fontFamily: 'DM Sans' }}>Hospital Services
              <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '15%', marginTop: 10 }}>
                {' '}
              </div></h4>
            <Row>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="hospitalServices">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}> Acceptance of beneficial services for consumers</Form.Label>
                  <br />
                  <input type="checkbox" name="BPL" value="BPL" checked={acceptanceState.some(val => val === "BPL")} onChange={handleChangeforAcceptance} />
                  <label> BPL</label><br></br>

                  <input type="checkbox" name="Ayushman Card" value="Ayushman Card" checked={acceptanceState.some(val => val === "Ayushman Card")} onChange={handleChangeforAcceptance} />
                  <label>Ayushman Card</label><br></br>

                  <input type="checkbox" name="Health Card" value="Health Card" checked={acceptanceState.some(val => val === "Health Card")} onChange={handleChangeforAcceptance} />
                  <label>Health Card</label><br></br>

                  <input type="checkbox" name="Health Insurance" value="Health Insurance" checked={acceptanceState.some(val => val === "Health Insurance")} onChange={handleChangeforAcceptance} />
                  <label>Health Insurance</label><br></br>

                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-3">
                <Form.Group controlId="hospitalServices">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>Facilities</Form.Label>
                  <br />
                  <input type="checkbox" name="OPD" value="OPD" checked={facilitiesState.some(val => val === "OPD")} onChange={handleChangeforFacilities} />
                  <label>OPD</label><br></br>

                  <input type="checkbox" name="General Surgical Services" value="General Surgical Services" checked={facilitiesState.some(val => val === "General Surgical Services")} onChange={handleChangeforFacilities} />
                  <label>General Surgical Services</label><br></br>

                  <input type="checkbox" name="Specialty Surgical Services" value="Specialty Surgical Services" checked={facilitiesState.some(val => val === "Specialty Surgical Services")} onChange={handleChangeforFacilities} />
                  <label>Specialty Surgical Services</label><br></br>

                  <input type="checkbox" name="Emergency" value="Emergency" checked={facilitiesState.some(val => val === "Emergency")} onChange={handleChangeforFacilities} />
                  <label>Emergency</label><br></br>

                  <input type="checkbox" name="Labor Room" value="Labor Room" checked={facilitiesState.some(val => val === "Labor Room")} onChange={handleChangeforFacilities} />
                  <label>Labor Room</label><br></br>

                  <input type="checkbox" name="Nursing Services" value="Nursing Services" checked={facilitiesState.some(val => val === "Nursing Services")} onChange={handleChangeforFacilities} />
                  <label>Nursing Services</label><br></br>

                  <input type="checkbox" name="Physiotherapy" value="Physiotherapy" checked={facilitiesState.some(val => val === "Physiotherapy")} onChange={handleChangeforFacilities} />
                  <label>Physiotherapy</label><br></br>

                  <input type="checkbox" name="Rehabilitation" value="Rehabilitation" checked={facilitiesState.some(val => val === "Rehabilitation")} onChange={handleChangeforFacilities} />
                  <label>Rehabilitation</label><br></br>

                  <input type="checkbox" name="ICU" value="ICU" checked={facilitiesState.some(val => val === "ICU")} onChange={handleChangeforFacilities} />
                  <label>ICU</label><br></br>

                  <input type="checkbox" name="Diagnosis" value="Diagnosis" checked={facilitiesState.some(val => val === "Diagnosis")} onChange={handleChangeforFacilities} />
                  <label>Diagnosis</label><br></br>

                  <input type="checkbox" name="Anesthetics" value="Anesthetics" checked={facilitiesState.some(val => val === "Anesthetics")} onChange={handleChangeforFacilities} />
                  <label>Anesthetics</label><br></br>

                  <input type="checkbox" name="Breast Scanning" value="Breast Scanning" checked={facilitiesState.some(val => val === "Breast Scanning")} onChange={handleChangeforFacilities} />
                  <label>Breast Scanning</label><br></br>

                  <input type="checkbox" name="Cardiology" value="Cardiology" checked={facilitiesState.some(val => val === "Cardiology")} onChange={handleChangeforFacilities} />
                  <label>Cardiology</label><br></br>

                  <input type="checkbox" name="ENT" value="ENT" checked={facilitiesState.some(val => val === "ENT")} onChange={handleChangeforFacilities} />
                  <label>ENT</label><br></br>

                  <input type="checkbox" name="Gastroenterology" value="Gastroenterology" checked={facilitiesState.some(val => val === "Gastroenterology")} onChange={handleChangeforFacilities} />
                  <label>Gastroenterology</label><br></br>

                  <input type="checkbox" name="Gynecology" value="Gynecology" checked={facilitiesState.some(val => val === "Gynecology")} onChange={handleChangeforFacilities} />
                  <label>Gynecology</label><br></br>

                  <input type="checkbox" name="Hematology" value="Hematology" checked={facilitiesState.some(val => val === "Hematology")} onChange={handleChangeforFacilities} />
                  <label>Hematology</label><br></br>

                  <input type="checkbox" name="Neonatal Unit" value="Neonatal Unit" checked={facilitiesState.some(val => val === "Neonatal Unit")} onChange={handleChangeforFacilities} />
                  <label>Neonatal Unit</label><br></br>

                  <input type="checkbox" name="Neurology" value="Neurology" checked={facilitiesState.some(val => val === "Neurology")} onChange={handleChangeforFacilities} />
                  <label>Neurology</label><br></br>

                  <input type="checkbox" name="Nutrition & Dietetics" value="Nutrition & Dietetics" checked={facilitiesState.some(val => val === "Nutrition & Dietetics")} onChange={handleChangeforFacilities} />
                  <label>Nutrition & Dietetics</label><br></br>

                  <input type="checkbox" name="Obstetrics" value="Obstetrics" checked={facilitiesState.some(val => val === "Obstetrics")} onChange={handleChangeforFacilities} />
                  <label>Obstetrics</label><br></br>

                  <input type="checkbox" name="Oncology" value="Oncology" checked={facilitiesState.some(val => val === "Oncology")} onChange={handleChangeforFacilities} />
                  <label>Oncology</label><br></br>

                  <input type="checkbox" name="Ophthalmology" value="Ophthalmology" checked={facilitiesState.some(val => val === "Ophthalmology")} onChange={handleChangeforFacilities} />
                  <label>Ophthalmology</label><br></br>

                  <input type="checkbox" name="Orthopedics" value="Orthopedics" checked={facilitiesState.some(val => val === "Orthopedics")} onChange={handleChangeforFacilities} />
                  <label>Orthopedics</label><br></br>

                  <input type="checkbox" name="Renal Unit" value="Renal Unit" checked={facilitiesState.some(val => val === "Renal Unit")} onChange={handleChangeforFacilities} />
                  <label>Renal Unit</label><br></br>

                  <input type="checkbox" name="Sexual Health" value="Sexual Health" checked={facilitiesState.some(val => val === "Sexual Health")} onChange={handleChangeforFacilities} />
                  <label>Sexual Health</label><br></br>

                  <input type="checkbox" name="Urology" value="Urology" checked={facilitiesState.some(val => val === "Urology")} onChange={handleChangeforFacilities} />
                  <label>Urology</label><br></br>

                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-3">
                <Form.Group controlId="hospitalServices">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}> Miscellaneous Facilities</Form.Label>
                  <br />
                  <input type="checkbox" name="Animal Bite Care" value="Animal Bite Care" checked={mFacilitiesState.some(val => val === "Animal Bite Care")} onChange={handleChangeforMFacilities} />
                  <label> Animal Bite Care</label><br></br>

                  <input type="checkbox" name="Poisoning Care" value="Poisoning Care" checked={mFacilitiesState.some(val => val === "Poisoning Care")} onChange={handleChangeforMFacilities} />
                  <label>Poisoning Care</label><br></br>

                  <input type="checkbox" name="Rabies Care" value="Rabies Care" checked={mFacilitiesState.some(val => val === "Rabies Care")} onChange={handleChangeforMFacilities} />
                  <label>Rabies Care</label><br></br>

                  <input type="checkbox" name="Drug Deaddiction" value="Drug Deaddiction" checked={mFacilitiesState.some(val => val === "Drug Deaddiction")} onChange={handleChangeforMFacilities} />
                  <label>Drug Deaddiction</label><br></br>

                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="noOfDoctors">
                  <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="noOfDoctors" onChange={e => setNoOfDoctors(e.target.value)} value={noOfDoctors} placeholder="No. of Doctors" />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="noOfMedicalConsultants">
                  <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="noOfMedicalConsultants" onChange={e => setNoOfMedicalConsultants(e.target.value)} value={noOfMedicalConsultants} placeholder="No. of Medical Consultants" />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="noOfNurses">
                  <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="noOfNurses" onChange={e => setNoOfNurses(e.target.value)} value={noOfNurses} placeholder="No. of Nurses" />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="noOfBeds">
                  <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="noOfBeds" onChange={e => setNoOfBeds(e.target.value)} value={noOfBeds} placeholder="No. of Beds" />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="noOfPrivateBeds">
                  <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="noOfPrivateBeds" onChange={e => setNoOfPrivateBeds(e.target.value)} value={noOfPrivateBeds} placeholder="No. of Private Beds" />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="noOfEmergencyBeds">
                  <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="noOfEmergencyBeds" onChange={e => setNoOfEmergencyBeds(e.target.value)} value={noOfEmergencyBeds} placeholder="No. of Emergency Beds" />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-3">
                <Form.Group controlId="isEmergencyServiceAvailable">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>Emergency Service Available?</Form.Label>
                  <Form.Select name="isEmergencyServiceAvailable" onChange={e => setIsEmergencyServiceAvailable(e.target.value)} style={{ fontFamily: 'DM Sans', fontSize: '12px' }} value={isEmergencyServiceAvailable}>
                    <option value="Yes" style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>Yes</option>
                    <option value="No" style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>No</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-3">
                <Form.Group controlId="isAmbulanceServiceAvailable">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>Ambulance Service Available?</Form.Label>
                  <Form.Select name="isAmbulanceServiceAvailable" onChange={e => setIsAmbulanceServiceAvailable(e.target.value)} style={{ fontFamily: 'DM Sans', fontSize: '12px' }} value={isAmbulanceServiceAvailable}>
                    <option value="Yes" style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>Yes</option>
                    <option value="No" style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>No</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Button type="submit" className="mt-5" style={{ fontFamily: 'DM Sans', fontSize: '12px', marginBottom: '30px' }}>
              Update
            </Button>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default EditHospital;
