import React, { useEffect } from 'react'
import { Container, Image, Card, Row, Col, Nav, Tab, Dropdown } from 'react-bootstrap';
import Navigation from '../Navigation'
import AddDoctors from './AddDoctorForm';
import AllDoctors from './AllDoctors';
import { useNavigate, Outlet, Link } from 'react-router-dom'
import { useQuery } from '@apollo/client';
import HospitalProfile from './HospitalProfile';
import { QUERY_GET_HOPITAL_BY_ID } from '../../graphql/queries';
import sadImg from "../../Images/SadImg.png"
import { FaPlusSquare, FaXRay, FaVirus, FaRegHospital, FaRegUser, FaPeopleArrows, FaHourglassEnd, FaRegCheckCircle, FaTimesCircle } from "react-icons/fa";

export default function HospitalDashboard() {

  const navigate = useNavigate();

  const hospitalToken = localStorage.getItem('hospitalToken')
  const hospitalId = localStorage.getItem('hospitalId')

  useEffect(() => {
    if (!hospitalToken) {
      navigate('/hospitalLogin');
    }
  }, [])

  const { data } = useQuery(QUERY_GET_HOPITAL_BY_ID, {
    variables: {
      "hospitalId": `${hospitalId}`
    }
  })


  console.log(data)


  return (
    <>
      {
        data && data.getHospitalById.status === "confirm" ?
          <>
            <Navigation />
            <Container style={{ marginTop: '100px' }} fluid>
              <Row>
                <Col md={12} style={{ background: '#3498db', padding: '50px', }}>
                  <h1 style={{ fontFamily: 'DM Sans', fontSize: '25px', textAlign: 'center', fontWeight: 'bold', color: '#fff' }}>Hospital Dashboard</h1>
                </Col>
              </Row>
              <Row style={{ marginTop: '-20px', marginLeft: '0px' }}>
                <Col md={12} className="  text-dark pt-3 px-0">
                  <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                      <Col sm={2} style={{ background: '#3498db', height: '1000px' }}>
                        <Nav variant="pills" className="flex-column">
                          <Dropdown style={{ marginTop: '15px' }}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ fontSize: '12px', fontFamily: 'DM Sans', width: '100%' }}>
                              <FaPlusSquare style={{ marginTop: '-3px' }} /> Patient
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                <Nav.Item>
                                  <Nav.Link as={Link} to={'/hospitalDashboard/PatientVerify'} style={{ padding: '10px', fontSize: '12px', fontFamily: 'DM Sans', color: '#000', fontWeight: 'bold' }}>
                                    <FaPlusSquare style={{ marginTop: '-3px' }} /> Patient Verification
                                  </Nav.Link>
                                </Nav.Item>
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                <Nav.Item>
                                  <Nav.Link as={Link} to={'/'} style={{ padding: '10px', fontSize: '12px', fontFamily: 'DM Sans', color: '#000', fontWeight: 'bold' }}>
                                    <FaRegHospital style={{ marginTop: '-3px' }} /> Admited Patient
                                  </Nav.Link>
                                </Nav.Item>
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <Nav.Item>
                                  <Nav.Link as={Link} to={'/'} style={{ padding: '10px', fontSize: '12px', fontFamily: 'DM Sans', color: '#000', fontWeight: 'bold' }}>
                                    <FaHourglassEnd style={{ marginTop: '-3px' }} /> Discharge Patient
                                  </Nav.Link>
                                </Nav.Item>
                              </Dropdown.Item>

                            </Dropdown.Menu>
                          </Dropdown>

                          <Dropdown style={{ marginTop: '15px' }}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ fontSize: '12px', fontFamily: 'DM Sans', width: '100%' }}>
                              <FaRegUser style={{ marginTop: '-3px' }} /> Doctor
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                <Nav.Item>
                                  <Nav.Link as={Link} to={'/hospitalDashboard/addDoctors'} style={{ padding: '10px', fontSize: '12px', fontFamily: 'DM Sans', color: '#000', fontWeight: 'bold' }}>
                                    <FaRegUser style={{ marginTop: '-3px' }} /> Add Doctor
                                  </Nav.Link>
                                </Nav.Item>
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                <Nav.Item>
                                  <Nav.Link as={Link} to={'/hospitalDashboard/allDoctors'} style={{ padding: '10px', fontSize: '12px', fontFamily: 'DM Sans', color: '#000', fontWeight: 'bold' }}>
                                    <FaPeopleArrows style={{ marginTop: '-3px' }} /> All Doctor
                                  </Nav.Link>
                                </Nav.Item>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>

                          <Dropdown style={{ marginTop: '15px' }}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ fontSize: '12px', fontFamily: 'DM Sans', width: '100%' }}>
                              <FaVirus style={{ marginTop: '-3px' }} /> Hospital
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-3">
                                <Nav.Item>
                                  <Nav.Link as={Link} to={'/hospitalDashboard/hospitalProfile'} style={{ padding: '10px', fontSize: '12px', fontFamily: 'DM Sans', color: '#000', fontWeight: 'bold' }}>
                                    <FaHourglassEnd style={{ marginTop: '-3px' }} /> My Profile
                                  </Nav.Link>
                                </Nav.Item>
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-4">
                                <Nav.Item>
                                  <Nav.Link as={Link} to={'/'} style={{ padding: '10px', fontSize: '12px', fontFamily: 'DM Sans', color: '#000', fontWeight: 'bold' }}>
                                    <FaRegCheckCircle style={{ marginTop: '-3px' }} /> Our Services
                                  </Nav.Link>
                                </Nav.Item>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>

                          <Dropdown style={{ marginTop: '15px' }}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ fontSize: '12px', fontFamily: 'DM Sans', width: '100%' }}>
                              <FaVirus style={{ marginTop: '-3px' }} /> Booking
                            </Dropdown.Toggle>
                            <Dropdown.Menu>

                              <Dropdown.Item href="#/action-4">
                                <Nav.Item>
                                  <Nav.Link as={Link} to={'/hospitalDashboard/AllHospitalBooking'} style={{ padding: '10px', fontSize: '12px', fontFamily: 'DM Sans', color: '#000', fontWeight: 'bold' }}>
                                    <FaHourglassEnd style={{ marginTop: '-3px' }} /> Service Booking
                                  </Nav.Link>
                                </Nav.Item>


                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-4">
                                <Nav.Item>
                                  <Nav.Link as={Link} to={'/'} style={{ padding: '10px', fontSize: '12px', fontFamily: 'DM Sans', color: '#000', fontWeight: 'bold' }}>
                                    <FaRegCheckCircle style={{ marginTop: '-3px' }} /> Doctor Booking
                                  </Nav.Link>
                                </Nav.Item>
                              </Dropdown.Item>

                              <Dropdown.Item href="#/action-4">
                                <Nav.Item>
                                  <Nav.Link as={Link} to={'/'} style={{ padding: '10px', fontSize: '12px', fontFamily: 'DM Sans', color: '#000', fontWeight: 'bold' }}>
                                    <FaRegCheckCircle style={{ marginTop: '-3px' }} /> Bed Booking
                                  </Nav.Link>
                                </Nav.Item>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Nav>

                      </Col>
                      <Col md={9}>
                        <Outlet />
                      </Col>
                    </Row>
                  </Tab.Container>
                </Col>
              </Row>
            </Container>
          </>
          :
          <Container style={{ marginTop: 100 }}>
            <Card>
              <Image src={sadImg} style={{ width: 200, height: 200, marginTop: 20 }} className="mx-auto d-block" />
              <h6 style={{ textAlign: "center", fontFamily: "Dm sans", color: "#3498db" }}>Sorry!!! Your Hospital is Rejected</h6>
              <p style={{ textAlign: "center", fontFamily: "Dm sans", color: "#3498db", fontSize: 12 }}>Please Wait For Update</p>
            </Card>
          </Container>

      }
    </>
  )
}
