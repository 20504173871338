import React from 'react'
import { Container, Row, Col, Button, Image } from 'react-bootstrap'
import '../ComponentCss/HeroSection.css'
import img2 from '../Images/banner_img.png'
import googleApp from '../Images/googleapp.png'

export default function HeroSection() {
    return (
        <div className='banner_partmain'>
            <Container style={{ marginTop: 130 }}>
                <Row>
                    <Col md={5} style={{ textAlign: 'left' }}>
                        <h5 className='h5Heading'>We are here for your care</h5>
                        <h1 className='h1Heading'>Better Health & Better Lives</h1>
                        <p style={{ fontFamily: 'poppins' }}>We have an enduring commitment to a healthier INDIA, and partner with organizations that share our vision. </p>
                        <a href="https://play.google.com/store/apps/details?id=com.bharpuser" target="_blank">
                        <Image src={googleApp} style={{ width: 180, height: 80, cursor: 'pointer' }} />
                        </a>
                    </Col>
                    <Col md={7} >
                        <Image src={img2} className="d-none d-lg-block img-fluid" style={{ marginTop: 10 }} />
                    </Col>
                </Row>
            </Container>

        </div>
    )
}
