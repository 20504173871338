import React, { useState, useEffect } from 'react'
import { Table, Button, Modal, Image, Spinner } from "react-bootstrap"
import { FaCheck, FaRegEye } from 'react-icons/fa'
import { useQuery, useMutation } from '@apollo/client'
import swal from 'sweetalert'

import { QUERY_REJECT_HOSPITAL, QUERY_CONFIRM_HOSPITAL, GET_AGENT_BY_ID } from '../../../graphql/queries'
import { BLOCK_HOSPITAL, CONFIRM_HOSPITAL } from '../../../graphql/mutations'
import { useNavigate } from 'react-router-dom';



export default function HospitalReject() {

  let count = 1

  useEffect(() => {
    setInterval(() => {
      refetch()
    }, 1000);
  }, [])


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { data, loading, refetch } = useQuery(QUERY_REJECT_HOSPITAL)


  const [agentId, setAgentId] = useState()


  const navigate = useNavigate()

  function handleDetail(id) {
    navigate('/hospitalDetail/' + id)
  }




  const [hospitalConfirm] = useMutation(CONFIRM_HOSPITAL, {
    refetchQueries: [
      QUERY_REJECT_HOSPITAL,
      "getRejecteHospital",
      QUERY_CONFIRM_HOSPITAL,
      "getActiveHospital"

    ]
  })

  function handleConfirm(id) {
    hospitalConfirm({
      variables: {
        "hospitalConfirmInput": {
          "id": `${id}`
        }

      }
    })
    swal({ title: "Successfull!!!", text: "Confirm Successfully", icon: "success" });
  }

  const { data: AgentData } = useQuery(GET_AGENT_BY_ID, {
    variables: {
      "agentId": `${agentId}`
    }
  })

  function handleDetailAgent(Id) {
    setAgentId(Id)
    handleShow()
  }

  return (
    <>
      <h3 style={{ padding: 10, borderBottom: "2px solid #fbc531", fontFamily: "Dm sans", fontWeight: "bold", color: "#ff4757", marginTop: '20px' }}>Hospital Reject</h3>
      {
        loading ?
          <Spinner animation="border" role="status" className="mx-auto d-block">
            <span className="visually-hidden">Loading...</span>
          </Spinner> :
          <Table striped bordered hover style={{ fontSize: "12px", fontFamily: "Dm sans" }} responsive >
            <thead>
              <tr>
                <th>SR No</th>
                <th>Registration No</th>
                <th>Hospital Name</th>
                <th>View Hospital Detail</th>
                <th>View Agent Detail</th>
                <th>Status</th>
                <th>Confirm</th>
                {/* <th>Reject</th> */}
              </tr>
            </thead>
            <tbody>
              {
                data && data.getRejecteHospital.slice().reverse().map(hospital => {
                  return (
                    <tr>
                      <td>{count++}</td>
                      <td>{hospital.hospitalRegistrationNumber}</td>
                      <td>{hospital.hospitalName}</td>
                      <td><center><Button size="sm" onClick={() => handleDetail(hospital.id)}><FaRegEye /></Button></center></td>
                      <td><center><Button size="sm" onClick={() => handleDetailAgent(hospital.agentId)}><FaRegEye /></Button></center></td>
                      <td>{hospital.status}</td>
                      <td><center><Button size="sm" style={{ background: "#7bed9f", border: "2px solid #7bed9f" }} onClick={() => handleConfirm(hospital.id)}><FaCheck /></Button></center></td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
      }

      <Modal show={show} onHide={handleClose}>
        <center>
          <Image src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" style={{ width: 100, height: 100, borderRadius: 50, marginTop: 10, marginBottom: 10 }} />
        </center>
        {
          AgentData && AgentData.getAgentById === null ? <h1 style={{ fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: 'center', fontSize: '15px' }}>No Agent Found</h1> :
            <>
              <h6 style={{ textAlign: "center" }}>{AgentData && AgentData.getAgentById.uniqueId} </h6>
              <h6 style={{ textAlign: "center" }}>{AgentData && AgentData.getAgentById.firstName} {AgentData && AgentData.getAgentById.lastName}</h6>
              <h6 style={{ textAlign: "center" }}>{AgentData && AgentData.getAgentById.email} </h6>
              <h6 style={{ textAlign: "center", marginBottom: 10 }}>{AgentData && AgentData.getAgentById.contact} </h6>
            </>
        }
      </Modal>
    </>
  )
}
