export const UserData = [
    {
      id: 1,
      name:"Active Ambulance",
      userGain: 500
    },
    {
      id: 2,
      name:"Pending Ambulance",
      userGain: 100
    },
    {
      id: 3,
      name:"Total Bookings",
      userGain: 50
    },
    {
      id: 4,
      name:"Cancelled Bookings",
      userGain: 30
    }
  ];