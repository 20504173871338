import React, { useState } from 'react';
import axios from 'axios';
import CryptoJS from 'crypto-js';

export default function PaymentCheckout() {

    const [orderId, setOrderId] = useState('8765432');
    const [amount, setAmount] = useState('1000');
    const [accessCode] = useState('AVMZ17KJ53AC61ZMCA');
    const [merchantId] = useState('2899301');
    const [encryptionKey] = useState('af98f39500de01a2a8062ca5edc9ffe29777664ac200974394a01c16650d86df256a0f7aa5221e567fb9d2eef8e20398aaec0c15dbbc0b9e954c71e932747e19009323293047b5abfc24800499c9a8394ee342d0571023d8c328be8cd6bf9fa6e257bfbabbad2846e15201b567e1c92ed5e85e8af67b616b18e7d645853f607becb5fa4456dead0f34ed9d9519d67deed9da849d0ceeedce7e1b92aa9635655cc408d7472be66842f51e35a6b223904b610e1387a28c08443cecb7b0003c037a3206a7f52534d37ff1bc6a4ba9f1654f617fe489a23b886c84be8974c28175bb87d2b31452b4b2d1140a9b73c5dabe48675689c65ef185ab6d6a3e0cd4e8103f730fd316857b1170023918090679e7c1692dc98dd5e1bac0ed0010221664ba960162be5fa1a5551fecc01ad79b37079b');
    const redirectUrl = 'https://bharp.in/';

    const handlePayment = () => {
        // Construct the request parameters
        const params = {
            order_id: orderId,
            amount: amount,
            merchant_id: merchantId,
            redirect_url: redirectUrl,
        };

        // Create the data string to be encrypted
        const data = Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join('&');

        // Encrypt the data using the encryption key
        const encryptedData = CryptoJS.AES.encrypt(data, encryptionKey).toString();

console.log(encryptedData)
        // Send a POST request to CCAvenue
        axios
            .post('https://secure.ccavenue.com/transaction/transaction.do', {
                command: 'initiateTransaction',
                access_code: accessCode,
                encRequest: encryptedData,
            })
            .then((response) => {
                // Redirect the user to the payment page
                window.location.href = response.data;
            })
            .catch((error) => {
                console.error('Payment initiation failed', error);
            });
    }


    return (
        <div>
            <button onClick={handlePayment}>Proceed to Payment</button>
        </div>
    )
}
