import React,{useState} from 'react'
import Navigation from '../Navigation'
import{Tab ,Row,Col,Nav}  from "react-bootstrap"
import BloodBankProfile from './BloodBankProfile'
import AllBloodBankBooking from './AllBloodBankBooking'
import EditBloodBank from "./EditBloodBank"
import "../../ComponentCss/NewDashboard.css"
import { Bar, Pie } from "react-chartjs-2";
import { FaAlignJustify, FaHome, FaUsers, FaUsersCog, FaHeadset, FaKey, FaSignOutAlt ,FaClock,FaPenSquare} from "react-icons/fa";
import { UserData } from '../SuperAdmin/Data';
import { Chart as ChartJS } from "chart.js/auto";
import { LinkContainer } from 'react-router-bootstrap'

function BloodBankDeshboard() {


  function handleMenu() {

    let toggle = document.querySelector(".toggle");
    let navigation = document.querySelector(".navigation");
    let main = document.querySelector(".main");
    navigation.classList.toggle("active");
    main.classList.toggle("active");
  };


  const [userData, setUserData] = useState({
    labels: UserData.map((data) => data.year),
    datasets: [
      {
        label: "Users Gained",
        data: UserData.map((data) => data.userGain),
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#ecf0f1",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 1,
      },
    ],
  });

  
   

  return (
    <>
    {/* <Navigation/>
    <br></br>  <br></br>  <br></br>  <br></br>
    <h5 style={{textAlign:"center",background:"#000",padding:10,color:"#fff"}}>Blood Bank Dashboard</h5>
    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Row style={{marginTop:-10}}>
        <Col sm={3} style={{background:"#000", height:'100%'}}>
          <Nav variant="pills" className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey="first">Profile</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">All Booking</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="third">Edit BookBank</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            <Tab.Pane eventKey="first">
             <BloodBankProfile/>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
          <AllBloodBankBooking/>
            </Tab.Pane>
               <Tab.Pane eventKey="third">
          <EditBloodBank/>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container> */}
        <div >
                <div className="navigation">
                    <br></br> 
                  
                <span className="title" style={{ fontSize: 20, fontFamily: 'DM Sans', fontWeight: 'bold',color:"#fff",textAlign:"center",marginLeft:20,marginTop:20 }}>Dashboard</span>
                    <ul>
                        <br></br><br></br><br></br>
                       
                        <li>
                        <LinkContainer to="/">
                            <a href="#">
                                <span className="icon">

                                </span>
                                <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }} eventKey="first"><FaHome /> Home</span>
                              
                            </a>
                            </LinkContainer>
                        </li>
                        <li>
                        <LinkContainer to="/BloodBankProfile">
                            <a href="#">
                                <span className="icon">
                                </span>
                                <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }}><FaUsers /> Profile</span>
                            </a>
                            </LinkContainer>
                        </li>
                        <li>
                        <LinkContainer to="/EditBloodBank">
                            <a href="#">
                                <span className="icon"> </span>
                                <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }}><FaPenSquare /> Edit Blood Bank</span>
                            </a>
                            </LinkContainer>
                        </li>
                      
                       
                       
                    </ul>
                    
                </div>
                

                <div className="main">
                    <div className="topbar">
                        <div>
                            <FaAlignJustify onClick={() => handleMenu()}  className="toggle"/>
                        </div>
                        <div className="search">
                            <label>
                                <input type="text" placeholder="Search here" />
                            </label>
                        </div>
                        <div className="user">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/2048px-Circle-icons-profile.svg.png" alt="" />
                        </div>
                    </div>
                    <div className="cardBox">
                        <div className="card">
                            <div>
                                <div className="numbers">1,504</div>
                                <div className="cardName">Daily Revenue</div>
                            </div>
                            
                        </div>
                        <div className="card">
                            <div>
                                <div className="numbers">2</div>
                                <div className="cardName">Total Order</div>
                            </div>
                            <div className="iconBx">
                              
                            </div>
                        </div>
                        <div className="card">
                            <div>
                                <div className="numbers">284</div>
                                <div className="cardName">Total Slot</div>
                            </div>
                            <div className="iconBx">
                            </div>
                        </div>
                        <div className="card">
                            <div>
                                <div className="numbers">7,842</div>
                                <div className="cardName">Detail Customer</div>
                            </div>
                            <div className="iconBx">
                            </div>
                        </div>
                    </div>
                    <Row>
                        <Col md={6}>
                            <Bar data={userData} />
                        </Col>
                        <Col md={6}>
                            <Pie data={userData} style={{ width: "280px", height: "280px" }} />
                        </Col>
                    </Row>
                   
                </div>
              
            </div>
    </>
  )
}

export default BloodBankDeshboard