import React from 'react';
import { Row, Col } from 'react-bootstrap';

const ViewAgentSub = ({ agent }) => {
  return (
    <div>
      <Row>
        <Col md={6}>
          <p>
            First Name: <small>{agent.firstName}</small>{' '}
          </p>
        </Col>
        <Col md={6}>
          <p>
            Last Name: <small>{agent.lastName}</small>{' '}
          </p>
        </Col>
        <Col md={6}>
          <p>
            Contact: <small>{agent.contact}</small>{' '}
          </p>
        </Col>
        <Col md={6}>
          <p>
            Email: <small>{agent.email}</small>{' '}
          </p>{' '}
        </Col>
        <Col md={6}>
          <p>
            Unique ID:{' '}
            <small>{agent.uniqueId}</small>{' '}
          </p>
        </Col>
        <Col md={6}>
          <p>
            Status:{' '}
            <small>{agent.status}</small>{' '}
          </p>
        </Col>
      </Row>

    </div>
  );
};

export default ViewAgentSub;
