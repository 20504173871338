import React, { useState } from 'react'
import { Table, Button, Col, Row, Image, Container, Form, InputGroup } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import { AiOutlineDelete } from 'react-icons/ai'
import { GrView } from 'react-icons/gr'
import { BiEdit } from 'react-icons/bi'
import EditDoctor from './EditDoctor';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ALL_DOCTORS } from '../../graphql/queries'
import { DELETE_DOCTOR, UPDATE_DOCTOR, UPDATE_DOCTOR_STATUS } from '../../graphql/mutations'
import Spinner from '../Spinner';
import { FaEye, FaPen, FaTrash } from "react-icons/fa";
import swal from 'sweetalert';
import ViewDoctor from './ViewDoctor';

const AllDoctors = () => {
  const [doctorID, setDoctorID] = useState('')
  const [doctor, setDoctor] = useState('')
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [viewshow, setVIewShow] = useState(false);

  const { data, loading } = useQuery(GET_ALL_DOCTORS)
  const hospitalId = localStorage.getItem('HospitalId')

  const [id, setid] = useState('')
  const [fName, setFName] = useState('')
  const [LName, setLName] = useState('')
  const [email, setEmail] = useState('')
  const [contact, setContact] = useState('')
  const [ward, setWard] = useState('')
  const [license, setLicense] = useState('')
  const [RegNumber, setRegNumber] = useState('')

  const [deleteDoctor] = useMutation(DELETE_DOCTOR, {
    refetchQueries: [
      GET_ALL_DOCTORS,
      'getAllDoctor',
    ],
  })
  const [updateDoctorStatus] = useMutation(UPDATE_DOCTOR_STATUS)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const viewHandleClose = () => setVIewShow(false);
  const viewHandleShow = (doctor) => {
    setVIewShow(true);
    setDoctor(doctor)
  }

  const [deleteshow, setDeleteShow] = useState(false);
  const deletehandleClose = () => setDeleteShow(false);
  const deletehandleShow = (doctor) => {
    setDeleteShow(true);
    setDoctorID(doctor.id)
  }


  const [blockshow, setBlockShow] = useState(false);
  const blockhandleClose = () => setBlockShow(false);
  const blockhandleShow = () => setBlockShow(true);

  const [unblockshow, setUnblockShow] = useState(false);
  const unblockhandleClose = () => setUnblockShow(false);
  const unblockhandleShow = () => setUnblockShow(true);

  const [isActivate, setActivate] = useState(false);

  const handleActivate = (e) => {
    !isActivate ? setActivate(true) : setActivate(false)
    setBlockShow(true);
  }

  const onDelete = (id) => {
    deleteDoctor({
      variables: { doctorId: id }
    }).then(() => {
      swal({ title: "Delete Successfully!", text: "Doctor Delete successfully!", icon: "success" });
      deletehandleClose()
    })
  }

  const [updateDoctor] = useMutation(UPDATE_DOCTOR, {
    refetchQueries: [
      GET_ALL_DOCTORS,
      'getAllDoctor',
    ],
  })

  const clickEditHandle = (doctor) => {
    setid(doctor.id);
    setFName(doctor.fName);
    setLName(doctor.lName);
    setEmail(doctor.email);
    setContact(doctor.contact);
    setWard(doctor.hospitalWard);
    setLicense(doctor.licenseNumber);
    setRegNumber(doctor.registrationNumber);
    handleShow()
  }
  
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      setValidated(false);
      updateDoctor({
        variables: {
          "updateDoctorInput": {
            "id": `${id}`,
            "hospitalId": `${hospitalId}`,
            "fName": `${fName}`,
            "lName": `${LName}`,
            "hospitalWard": `${ward}`,
            "contact": `${contact}`,
            "email": `${email}`,
            "registrationNumber": `${RegNumber}`,
            "licenseNumber": `${license}`
          }
        }
      })
      handleClose();
    }
  };

  return (
    <>
      <Container style={{ marginTop: '20px' }}>
        <div>
          <h1 style={{ fontFamily: 'DM Sans' }}>All Doctors
            <h2 style={{ backgroundColor: '#ffb606 ', height: 2, width: '15%', marginTop: 10 }}></h2>
          </h1>
          {
            loading ? <Spinner /> :
              <Table bordered style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>
                <thead>
                  <tr>
                    <th>Doctor Name</th>
                    <th>Ward</th>
                    <th>View</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data?.getAllDoctor.map((doctor) => (
                      <tr key={doctor.id}>
                        <td>{doctor.fName}</td>
                        <td>{doctor.hospitalWard}</td>
                        <td><Button size="sm" onClick={() => viewHandleShow(doctor)}><FaEye style={{ marginTop: '-4px' }} /></Button></td>
                        <td><Button size="sm" variant='warning' onClick={() => clickEditHandle(doctor)}><FaPen style={{ marginTop: '-4px', color: '#fff' }} /></Button></td>
                        <td><Button size="sm" variant='danger' onClick={() => deletehandleShow(doctor)}><FaTrash style={{ marginTop: '-4px' }} /></Button></td>
                        {/* <td><center>
                        {isActivate === true ?
                          <Button variant="primary" onClick={handleActivate} className='mx-auto d-block'>
                            UnBlock
                          </Button>
                          : false}
                        {!isActivate ?
                          <Button variant="primary" onClick={handleActivate} className='mx-auto d-block'>
                            Block
                          </Button>
                          : false}
                      </center></td> */}
                      </tr>
                    ))}
                </tbody>
              </Table>
          }
          <Modal show={deleteshow} onHide={deletehandleClose}>
            <Modal.Body style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '20px', textAlign: 'center' }}>Please Confirm </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={() => onDelete(doctorID)}>
                Yes
              </Button>
              <Button variant="primary" onClick={deletehandleClose}>
                No
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={show} onHide={handleClose} size="lg">
            <Container>
              <h4 style={{ textAlign: "center", marginTop: 20 }}>Edit Profile</h4>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Form.Group as={Col} md="4">
                    <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}>First name</Form.Label>
                    <Form.Control required type="text" placeholder="First name" style={{ fontSize: 13 }} value={fName} onChange={(e) => setFName(e.target.value)} />
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}>Last name</Form.Label>
                    <Form.Control required type="text" placeholder="Last name" style={{ fontSize: 13 }} value={LName} onChange={(e) => setLName(e.target.value)} />
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}>Email</Form.Label>
                    <InputGroup hasValidation>
                      <InputGroup.Text>@</InputGroup.Text>
                      <Form.Control type="email" placeholder="Email Id" aria-describedby="inputGroupPrepend" required style={{ fontSize: 13 }} value={email} onChange={(e) => setEmail(e.target.value)} />
                    </InputGroup>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="4" controlId="validationCustom05">
                    <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}>Contact
                    </Form.Label>
                    <Form.Control type="text" placeholder="Contact" required style={{ fontSize: 13 }} value={contact} onChange={(e) => setContact(e.target.value)} />
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="validationCustom03">
                    <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}>Hospital Ward</Form.Label>
                    <Form.Control type="text" placeholder="Hospital Ward" required style={{ fontSize: 13 }} value={ward} onChange={(e) => setWard(e.target.value)} />
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="validationCustom04">
                    <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}>License Number</Form.Label>
                    <Form.Control type="text" placeholder="License Number" required style={{ fontSize: 13 }} value={license} onChange={(e) => setLicense(e.target.value)} />
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="validationCustom05">
                    <Form.Label style={{ fontSize: 13, fontWeight: "bold" }}>Registration Number
                    </Form.Label>
                    <Form.Control type="text" placeholder="Registration Number" required style={{ fontSize: 13 }} value={RegNumber} onChange={(e) => setRegNumber(e.target.value)} />
                  </Form.Group>
                </Row>
                <Form.Group className="mb-3">
                </Form.Group>
                <Button type="submit" style={{ marginBottom: 20, font: "Dm sans" }} className="mx-auto d-block">Update</Button>
              </Form>
            </Container>
          </Modal>

          <Modal show={viewshow} onHide={viewHandleClose} >
            <Image src="https://img.freepik.com/free-vector/doctor-clinic-illustration_1270-69.jpg?w=740&t=st=1662206526~exp=1662207126~hmac=599980b40bdbb5134b98caead1e34ecc0fb20cebedac433e3d7e130157ae84a0" style={{ width: 100, borderRadius: 100, marginTop: 10, marginBottom: 10 }} className="mx-auto d-block" />
            <Container>
              <Row>
                <Col>
                  <p style={{ fontSize: "13px", fontWeight: "bold", textAlign: "center", color: "#3498db" }}>Registartion : {doctor.registrationNumber} </p>
                  <p style={{ fontSize: "13px", fontWeight: "bold", textAlign: "center" }}>Name : {doctor.fName} {doctor.lName}</p>
                  <p style={{ fontSize: "13px", fontWeight: "bold" }}>Contact : {doctor.contact} </p>
                  <p style={{ fontSize: "13px", fontWeight: "bold" }}>Email : {doctor.email}</p>
                  <p style={{ fontSize: "13px", fontWeight: "bold" }}>License No : {doctor.licenseNumber}</p>
                  <p style={{ fontSize: "13px", fontWeight: "bold" }}>Hospital Ward : {doctor.hospitalWard}</p>
                  <p style={{ fontSize: "13px", fontWeight: "bold" }}>specialization : {doctor.specialization}</p>
                </Col>
              </Row>
            </Container>

          </Modal>

          <Modal show={blockshow} onHide={blockhandleClose}>
            <Modal.Header closeButton>
              <Modal.Title> delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>you want to block ? please confirm</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={blockhandleClose}>
                Cancle
              </Button>
              <Button variant="primary" onClick={blockhandleClose}>
                Block
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={unblockshow} onHide={unblockhandleClose} size='lg'>
            <Modal.Header closeButton>
              <Modal.Title>unblock heading</Modal.Title>
            </Modal.Header>
            <Modal.Body>do you want unblock</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={unblockhandleClose}>
                Cancle
              </Button>
              <Button variant="primary" onClick={unblockhandleClose}>
                Block
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Container>
    </>
  )
}

export default AllDoctors