
import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { Container, Carousel, Button, Row, Col, Image, Modal, Form } from 'react-bootstrap'
import { QUERY_GET_HOPITAL_BY_ID } from '../../graphql/queries'
import { FaPenSquare, FaPen, FaPhoneAlt, FaEnvelopeOpen, FaMapMarkerAlt, FaHistory } from "react-icons/fa";
import Multiselect from 'multiselect-react-dropdown';
import { UPDATE_HOSPITAL } from '../../graphql/mutations';

export default function HospitalProfile() {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let stateArray = ['Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jammu and Kashmir', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttarakhand', 'Uttar Pradesh', 'West Bengal', 'Andaman and Nicobar Islands', 'Chandigarh', 'Dadra and Nagar Haveli', 'Daman and Diu', 'Delhi', 'Lakshadweep', 'Puducherry'];

    const hospitalId = localStorage.getItem('hospitalId')

    const { data } = useQuery(QUERY_GET_HOPITAL_BY_ID, {
        variables: {
            "hospitalId": `${hospitalId}`
        }
    })


    console.log("data", data)

    const [updateHospital] = useMutation(UPDATE_HOSPITAL)


    const [hospitalid, setHospitalId] = useState(data && data.getHospitalById.id)
    const [hospitalName, setHospitalName] = useState(data && data.getHospitalById.hospitalName)
    const [hospitalType, setHospitalType] = useState(data && data.getHospitalById.hospitalType)
    const [hospitalHealthCareProviderType, sethospitalHealthCareProviderType] = useState(data && data.getHospitalById.hospitalHealthCareProviderTyp)
    const [hospitalRegistrationNumber, sethospitalRegistrationNumber] = useState(data && data.getHospitalById.hospitalRegistrationNumber)
    const [hospitalState, setHospitalState] = useState(data && data.getHospitalById.hospitalState)
    const [hospitalDistrict, setHospitalDistrict] = useState(data && data.getHospitalById.hospitalDistrict)
    const [hospitalCity, setHospitalCity] = useState(data && data.getHospitalById.hospitalCity)
    const [hospitalBlock, sethospitalBlock] = useState(data && data.getHospitalById.hospitalBlock)
    const [hospitalVillage, sethospitalVillage] = useState(data && data.getHospitalById.hospitalVillage)
    const [hospitalAddress, sethospitalAddress] = useState(data && data.getHospitalById.hospitalAddress)
    const [hospitalPincode, setHospitalPincode] = useState(data && data.getHospitalById.hospitalPincode)
    const [hospitalContact, sethospitalContact] = useState(data && data.getHospitalById.hospitalContact)
    const [hospitalEmail, sethospitalEmail] = useState(data && data.getHospitalById.hospitalEmail)
    const [hospitalWebsiteLink, setHospitalWebsiteLink] = useState(data && data.getHospitalById.hospitalWebsiteLink)
    const [hospitalEstablishmentYear, sethospitalEstablishmentYear] = useState(data && data.getHospitalById.hospitalEstablishmentYear)
    const [noOfMedicalConsultants, setNoOfMedicalConsultants] = useState(data && data.getHospitalById.noOfMedicalConsultants)
    const [noOfNurses, setNoOfNurses] = useState(data && data.getHospitalById.noOfNurses)
    const [noOfBeds, setNoOfBeds] = useState(data && data.getHospitalById.noOfBeds)
    const [noOfPrivateBeds, setNoOfPrivateBeds] = useState(data && data.getHospitalById.noOfPrivateBeds)
    const [noOfEmergencyBeds, setNoOfEmergencyBeds] = useState(data && data.getHospitalById.noOfEmergencyBeds)
    const [isAmbulanceServiceAvailable, setIsAmbulanceServiceAvailable] = useState(data && data.getHospitalById.isAmbulanceServiceAvailable)
    const [isEmergencyServiceAvailable, setIsEmergencyServiceAvailable] = useState(data && data.getHospitalById.isEmergencyServiceAvailable)
    const [PersonName, setPersonName] = useState(data && data.getHospitalById.cPName)
    const [PersonDesignation, setPersonDesignation] = useState(data && data.getHospitalById.cPDesignation)
    const [PersonEmail, setPersonEmail] = useState(data && data.getHospitalById.cPEmail)
    const [PersonContact, setPersonContact] = useState(data && data.getHospitalById.cPContact)
    const [addService, setAddService] = useState('')
    const [options, setOptions] = useState(['Option1', 'Option2'])



    console.log(PersonContact)
    console.log(data)

    function handleClick() {
        handleShow()
    }



    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            updateHospital({
                variables: {
                    "updateHospitalInput": {
                        id: `${hospitalid}`,
                        hospitalName: `${hospitalName}`,
                        hospitalType: `${hospitalType}`,
                        hospitalHealthCareProviderType: `${hospitalHealthCareProviderType}`,
                        hospitalRegistrationNumber: `${hospitalRegistrationNumber}`,
                        hospitalState: `${hospitalState}`,
                        hospitalCity: `${hospitalCity}`,
                        hospitalBlock: `${hospitalBlock}`,
                        hospitalVillage: `${hospitalVillage}`,
                        hospitalDistrict: `${hospitalDistrict}`,
                        hospitalAddress: `${hospitalAddress}`,
                        hospitalPincode: `${hospitalPincode}`,
                        hospitalEmail: `${hospitalEmail}`,
                        hospitalContact: `${hospitalContact}`,
                        hospitalWebsiteLink: `${hospitalWebsiteLink}`,
                        hospitalEstablishmentYear: `${hospitalEstablishmentYear}`,
                        cPName: `${PersonName}`,
                        cPDesignation: `${PersonDesignation}`,
                        cPEmail: `${PersonEmail}`,
                        noOfMedicalConsultants: `${noOfMedicalConsultants}`,
                        noOfNurses: `${noOfNurses}`,
                        noOfBeds: parseInt(`${noOfBeds}`),
                        noOfPrivateBeds: parseInt(`${noOfPrivateBeds}`),
                        noOfEmergencyBeds: parseInt(`${noOfEmergencyBeds}`),
                        isEmergencyServiceAvailable: `${isEmergencyServiceAvailable}`,
                        isAmbulanceServiceAvailable: `${isAmbulanceServiceAvailable}`,
                        cPContact: `${hospitalName}`,
                    }

                }




            })



        }

        setValidated(true);
    };



    return (
        <>
            <h4 style={{ marginTop: 10, textAlign: "center", color: "#3498db" }}>{data && data.getHospitalById.hospitalName}</h4>
            <hr></hr>

            <Container>
                <Row>
                    <Col md={3}>
                        <Carousel style={{ width: '100%', height: 150 }}>
                            {
                                data && data.getHospitalById.hospitalImages.map(Img => {
                                    return (
                                        <Carousel.Item>
                                            <img
                                                className="d-block w-100 h-30 img-fluid"
                                                src={`https://bharp-hospital.s3.ap-south-1.amazonaws.com/${Img}`}
                                                alt="First slide ,Responsive image"
                                                style={{ width: '100%', height: 200 }}
                                            />
                                        </Carousel.Item>
                                    )
                                })
                            }
                        </Carousel>
                    </Col>
                    <Col md={3}>
                        <Image src={`https://bharp-hospital.s3.ap-south-1.amazonaws.com/${data && data.getHospitalById.hospitalCertificateImg}`} style={{ width: "100%", height: 200 }} />
                        <div style={{ display: "flex", justifyContent: "flex-end", marginRight: 10, marginTop: 10 }}>
                            <Button variant="light" style={{ fontFamily: "Dm sans", fontSize: 12 }} ><FaPen /> Edit Image</Button>
                        </div>
                    </Col>
                    <Col md={3}>
                        <Image src={`https://bharp-hospital.s3.ap-south-1.amazonaws.com/${data && data.getHospitalById.hospitalGstCertificateImg}`} style={{ width: "100%", height: 200 }} />
                        <div style={{ display: "flex", justifyContent: "flex-end", marginRight: 10, marginTop: 10 }}>
                            <Button variant="light" style={{ fontFamily: "Dm sans", fontSize: 12 }} ><FaPen /> Edit Image</Button>
                        </div>
                    </Col>
                    <Col md={3}>
                        <Image src={`https://bharp-hospital.s3.ap-south-1.amazonaws.com/${data && data.getHospitalById.hospitalLicenseImg}`} style={{ width: "100%", height: 200 }} />
                        <div style={{ display: "flex", justifyContent: "flex-end", marginRight: 10, marginTop: 10 }}>
                            <Button variant="light" style={{ fontFamily: "Dm sans", fontSize: 12 }} ><FaPen /> Edit Image</Button>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container>
                <hr></hr>
                <Row>
                    <Col md={6}>
                        <div style={{ border: "2px solid #3498db", marginTop: 5 }}>
                            <h6 style={{ textAlign: "center", fontFamily: "Dm sans", marginTop: 5, fontWeight: "bold", fontSize: 12 }}>Hospital Type</h6>
                            <h6 style={{ textAlign: "center", fontFamily: "Dm sans", color: "#3498db", fontWeight: "bold", fontSize: 12 }}>{data && data.getHospitalById.hospitalType}</h6>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div style={{ border: "2px solid #3498db", marginTop: 5 }}>
                            <h6 style={{ textAlign: "center", fontFamily: "Dm sans", marginTop: 5, fontWeight: "bold", fontSize: 12 }}>Hospital HealthCare Provider Type</h6>
                            <h6 style={{ textAlign: "center", fontFamily: "Dm sans", color: "#3498db", fontWeight: "bold", fontSize: 12 }}>{data && data.getHospitalById.hospitalHealthCareProviderType
                            }</h6>
                        </div>
                    </Col>
                </Row>
            </Container>
            <h6 style={{ textAlign: "center", marginTop: 20, fontFamily: "Dm sans", fontWeight: "bold", color: "#3498db" }}>Registartion : 82744252</h6>
            <hr></hr>
            <Container style={{ marginTop: 20 }}>
                <Row>
                    <Col md={6}>
                        <p style={{ fontFamily: "Dm sans", fontSize: 13, fontWeight: "bold" }}><FaPhoneAlt style={{ color: "#3498db", marginRight: 10 }} /> {data && data.getHospitalById.hospitalContact}</p>
                        <p style={{ fontFamily: "Dm sans", fontSize: 13, fontWeight: "bold" }}><FaEnvelopeOpen style={{ color: "#3498db", marginRight: 10 }} /> {data && data.getHospitalById.hospitalEmail}</p>
                        <p style={{ fontFamily: "Dm sans", fontSize: 13, fontWeight: "bold" }}><FaMapMarkerAlt style={{ color: "#3498db", marginRight: 10 }} /> {data && data.getHospitalById.hospitalAddress} {data && data.getHospitalById.hospitalVillage
                        }  {data && data.getHospitalById.hospitalCity} {data && data.getHospitalById.hospitalPincode}
                        </p>
                        <p style={{ fontFamily: "Dm sans", fontSize: 13, fontWeight: "bold" }}><FaMapMarkerAlt style={{ color: "#3498db", marginRight: 10 }} />{data && data.getHospitalById.hospitalDistrict} {data && data.getHospitalById.hospitalState} </p>
                        <p style={{ fontFamily: "Dm sans", fontSize: 13, fontWeight: "bold" }}><FaHistory style={{ color: "#3498db", marginRight: 10 }} />
                            {data && data.getHospitalById.hospitalEstablishmentYear
                            }  </p>
                    </Col>
                    <Col md={6}>
                        <h6 style={{ textAlign: "center", fontFamily: "Dm sans", fontWeight: "bold", color: "#3498db" }}>Hospital Detail</h6>
                        <Row>
                            <Col md={6}>
                                <p style={{ fontFamily: "Dm sans", fontSize: 13, fontWeight: "bold" }}>Hospital Block :
                                    {data && data.getHospitalById.hospitalBlock}</p>

                                <p style={{ fontFamily: "Dm sans", fontSize: 13, fontWeight: "bold" }}>Ambulance  Service Avl :
                                    {data && data.getHospitalById.isAmbulanceServiceAvailable}</p>

                                <p style={{ fontFamily: "Dm sans", fontSize: 13, fontWeight: "bold" }}> Emergency Service Avl :
                                    {data && data.getHospitalById.isEmergencyServiceAvailable} </p>

                                <p style={{ fontFamily: "Dm sans", fontSize: 13, fontWeight: "bold" }}> Number Of Beds :
                                    {data && data.getHospitalById.noOfBeds} </p>

                            </Col>
                            <Col md={6}>
                                <p style={{ fontFamily: "Dm sans", fontSize: 13, fontWeight: "bold" }}> Number Of Emergency Beds :
                                    {data && data.getHospitalById.noOfEmergencyBeds}</p>

                                <p style={{ fontFamily: "Dm sans", fontSize: 13, fontWeight: "bold" }}> Number Of Medical Consultants :
                                    {data && data.getHospitalById.noOfMedicalConsultants}</p>

                                <p style={{ fontFamily: "Dm sans", fontSize: 13, fontWeight: "bold" }}> Number Of Nursing :{""}
                                    {data && data.getHospitalById.noOfNurses}</p>

                                <p style={{ fontFamily: "Dm sans", fontSize: 13, fontWeight: "bold" }}> Number Of Private Beds :
                                    {data && data.getHospitalById.noOfPrivateBeds}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <h6 style={{ color: "#3498db", fontWeight: "bold" }}>Contact Person</h6>
                <hr></hr>

                <Row>
                    <Col>
                        <p style={{ fontFamily: "Dm sans", fontSize: 13, fontWeight: "bold" }}> Name : {data && data.getHospitalById.cPName}</p>
                        <p style={{ fontFamily: "Dm sans", fontSize: 13, fontWeight: "bold" }}> Email : {data && data.getHospitalById.cPEmail}</p>
                        <p style={{ fontFamily: "Dm sans", fontSize: 13, fontWeight: "bold" }}> Contact : {data && data.getHospitalById.cPContact}</p>
                        <p style={{ fontFamily: "Dm sans", fontSize: 13, fontWeight: "bold" }}> Designation : {data && data.getHospitalById.cPDesignation}</p>
                    </Col>


                </Row>

                <div style={{ display: "flex", justifyContent: "flex-end", marginRight: 10, marginTop: 10 }}>
                    <Button variant="light" style={{ fontFamily: "Dm sans", marginBottom: 20 }} onClick={() => handleClick()} ><FaPen /> Edit Image</Button>
                </div>
            </Container>

            <Modal show={show} onHide={handleClose} size="lg">
                <Container>

                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        {/*----------------- Hospital Details ---------------------*/}
                        <Row>
                            <h4 className="mt-5" style={{ fontFamily: 'DM Sans', textAlign: "center" }}>
                                Edit Hospital Detail

                            </h4>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group>
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Hospital Name</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalName" onChange={(e) => setHospitalName(e.target.value)} value={hospitalName} placeholder="Hospital Name" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group controlId="hospitalType">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Hospital Type</Form.Label>
                                    <Form.Select required name="hospitalType" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalType(e.target.value)} value={hospitalType}>
                                        <option value="" selected="selected" disabled="disabled">Select hospital Type</option>
                                        <option value="Government Center">Government Center</option>
                                        <option value="Private Center">Private Center</option>
                                        <option value="Semi-Government">Semi-Government</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group controlId="hospitalHealthCareProviderType">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Health Care Provider Type</Form.Label>
                                    <Form.Select required name="hospitalHealthCareProviderType" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => sethospitalHealthCareProviderType(e.target.value)} value={hospitalHealthCareProviderType}>
                                        <option value="" selected="selected" disabled="disabled">Select Health Care Provider Type</option>
                                        <option value="Hospital Dispensary">Hospital Dispensary</option>
                                        <option value="Nursing Home">Nursing Home</option>
                                        <option value="Medical College">Medical College</option>
                                        <option value="Primary Health Center">Primary Health Center</option>
                                        <option value="Clinic">Clinic</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group controlId="hospitalRegistrationNumber">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Registration Number</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalRegistrationNumber" onChange={(e) => sethospitalRegistrationNumber(e.target.value)} value={hospitalRegistrationNumber} placeholder="Registration Number" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group controlId="hospitalState">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>State</Form.Label>
                                    <Form.Select required name="hospitalState" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setHospitalState(e.target.value)} value={hospitalState}>
                                        <option value="" selected="selected" disabled="disabled">Select State</option>
                                        {stateArray.map((d) => (
                                            <option value={d}>{d}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group controlId="hospitalDistrict">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>District</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalDistrict" onChange={(e) => setHospitalDistrict(e.target.value)} value={hospitalDistrict} placeholder="Hospital District" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group controlId="hospitalCity">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>City</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalCity" onChange={(e) => setHospitalCity(e.target.value)} value={hospitalCity} placeholder="Hospital City" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group controlId="hospitalBlock">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Block</Form.Label>
                                    <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalBlock" onChange={(e) => sethospitalBlock(e.target.value)} value={hospitalBlock} placeholder="Hospital Block" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group controlId="hospitalVillage">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Village</Form.Label>
                                    <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalVillage" onChange={(e) => sethospitalVillage(e.target.value)} value={hospitalVillage} placeholder="Hospital Village" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group controlId="hospitalAddress">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Address</Form.Label>
                                    <Form.Control required rows="1" as="textarea" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalAddress" onChange={(e) => sethospitalAddress(e.target.value)} value={hospitalAddress} placeholder="Hospital Address" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group controlId="hospitalPincode">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Pincode</Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalPincode" onChange={(e) => setHospitalPincode(e.target.value)} value={hospitalPincode} placeholder="Pin Code" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group controlId="hospitalContact">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Hospital Contact</Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="number" onChange={(e) => sethospitalContact(e.target.value)} value={hospitalContact} placeholder="Hospital Contact" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group controlId="hospitalEmail">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Hospital Email</Form.Label>
                                    <Form.Control type="email" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="Email" onChange={(e) => sethospitalEmail(e.target.value)} value={hospitalEmail} placeholder="Hospital Email" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group controlId="hospitalWebsiteLink">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Hospital Website Link</Form.Label>
                                    <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalWebsiteLink" onChange={(e) => setHospitalWebsiteLink(e.target.value)} value={hospitalWebsiteLink} placeholder="Hospital Website Link" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group controlId="hospitalEstablishmentYear">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Hospital Establishment Year</Form.Label>
                                    <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="hospitalEstablishmentYear" onChange={(e) => sethospitalEstablishmentYear(e.target.value)} value={hospitalEstablishmentYear} placeholder="(YYYY)" />
                                </Form.Group>
                            </Col>



                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group controlId="hospitalAddress">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>No of Medical Consultants</Form.Label>
                                    <Form.Control required rows="1" as="textarea" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setNoOfMedicalConsultants(e.target.value)} value={noOfMedicalConsultants} placeholder="Medical Consultants" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group controlId="hospitalPincode">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>No of Nursing</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setNoOfNurses(e.target.value)} value={noOfNurses} placeholder="No of Nursing" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group controlId="hospitalContact">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>No of Beds</Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="number" onChange={(e) => setNoOfBeds(e.target.value)} value={noOfBeds} placeholder="No of Beds" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group controlId="hospitalEmail">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>No of Private Beds</Form.Label>
                                    <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setNoOfPrivateBeds(e.target.value)} value={noOfPrivateBeds} placeholder="No of Private" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group controlId="hospitalEmail">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>No of Emergency Beds</Form.Label>
                                    <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setNoOfEmergencyBeds(e.target.value)} value={noOfEmergencyBeds} placeholder="No of Emergency Beds" />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group controlId="hospitalEmail">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>No of Ambulences</Form.Label>
                                    <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setIsAmbulanceServiceAvailable(e.target.value)} value={isAmbulanceServiceAvailable} placeholder="Ambulences" />
                                </Form.Group>
                            </Col>


                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group controlId="hospitalEmail">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}> Emergency Service Available</Form.Label>
                                    <Form.Control type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} onChange={(e) => setIsEmergencyServiceAvailable(e.target.value)} value={isEmergencyServiceAvailable} placeholder=" Emergency Service Available" />
                                </Form.Group>
                            </Col>


                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group controlId="hospitalEmail">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Add Service</Form.Label>
                                    <Multiselect
                                        isObject={false}
                                        options={options}
                                        onRemove={(event) => setAddService(event)}
                                        onSelect={(event) => setAddService(event)}
                                        showCheckbox
                                        style={{ fontFamily: "Dm sans", fontSize: 12 }} />
                                </Form.Group>
                            </Col>

                        </Row>
                        {/*----------------- Nodel person Details ---------------------*/}

                        <Row>
                            <h4 className="mt-5" style={{ fontFamily: 'DM Sans' }}>
                                Person Details
                                <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '17%', marginTop: 10 }}>
                                    {' '}
                                </div>
                            </h4>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group controlId="contactPersonName">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Contact Person Name</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="contactPersonName" placeholder="Contact Person Name" onChange={(e) => setPersonName(e.target.value)} value={PersonName} />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group controlId="contactPersonDesignation">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Contact Person Designation</Form.Label>
                                    <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="contactPersonDesignation" placeholder="Contact Person Designation" onChange={(e) => setPersonDesignation(e.target.value)} value={PersonDesignation} />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group controlId="contactPersonEmail">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Contact Person Email</Form.Label>
                                    <Form.Control required type="email" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="contactPersonEmail" onChange={(e) => setPersonEmail(e.target.value)} value={PersonEmail} placeholder="Contact Person Email" />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={4} className="mt-5">
                                <Form.Group controlId="contactPersonContact">
                                    <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 'bold' }}>Contact Person Contact</Form.Label>
                                    <Form.Control required type="number" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="contactPersonContact" onChange={(e) => setPersonContact(e.target.value)} value={PersonContact} placeholder="Contact Person Contact" />
                                </Form.Group>
                            </Col>
                        </Row>

                        {/*----------------- No of doctors ---------------------*/}












                        {/* <Col md={6} lg={4} className="mt-5">
              <Form.Group className="position-relative mb-3" controlId="contactPersonName">
                <Form.Label style={{ fontSize: '12px', fontWeight: 'bold' }}>Upload Hospital Pictures</Form.Label>
                <Form.Control style={{ fontSize: '12px' }} type="file" name="file" onChange={(event) => { setSelectedHospitalImage(event.target.files[0]) }} multiple />
                <Button size="sm" style={{ fontFamily: 'DM Sans', fontSize: '10px', marginTop: '10px' }}>Upload</Button>
              </Form.Group>
            </Col> */}
                        {/* <Col md={6} lg={4} className="mt-5">
              <Form.Group className="position-relative mb-3" controlId="contactPersonName">
                <Button style={{ fontFamily: 'DM Sans', fontSize: '12px', marginTop: '10px' }} onClick={() => handleImgUploadShow()}>Upload Hospital Multiple Images</Button>
              </Form.Group>
            </Col> */}

                        <Button type="submit" className="mt-5 mx-auto d-block" style={{ fontFamily: 'DM Sans', fontSize: '12px', marginBottom: '100px' }} >Add Hospital</Button>

                    </Form>
                </Container>
            </Modal>
        </>
    )
}
