import React, { useState, useRef, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Chart as ChartJS, Bar, Pie } from 'react-chartjs-2'
// import { UserData } from './Data';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { QUERY_CANCEL_AMBULANCEBOOKING, QUERY_GET_ACTIVE_AMBULANCE, QUERY_GET_AMBULANCE_ALL_BOOKINGS, QUERY_GET_PENDING_AMBULANCE } from '../../graphql/queries';

export default function DashboardHome() {

    const { data: activeData } = useQuery(QUERY_GET_ACTIVE_AMBULANCE)
    const { data: PendingData } = useQuery(QUERY_GET_PENDING_AMBULANCE)
    const { data: allBooking } = useQuery(QUERY_GET_AMBULANCE_ALL_BOOKINGS)
    const { data: RejectBooking } = useQuery(QUERY_CANCEL_AMBULANCEBOOKING)

    const UserData = [
        {
            id: 1,
            name: "Active Ambulance",
            userGain: 100
        },
        {
            id: 2,
            name: "Pending Ambulance",
            userGain: 200
        },
        {
            id: 3,
            name: "Total Bookings",
            userGain: allBooking && allBooking.getAmbulanceAllBookings.length
        },
        {
            id: 4,
            name: "Cancelled Bookings",
            userGain: RejectBooking && RejectBooking.getCancelAmbulanceBooking.length
        }
    ];

    const [userDataa, setUserData] = useState({
        labels: UserData.map((data) => data.name),
        datasets: [
            {
                label: "Users Gained",
                data: [
                    activeData && activeData.getActiveAmbulance.length,
                    PendingData && PendingData.getPendingAmbulance.length,
                    allBooking && allBooking.getAmbulanceAllBookings.length,
                    RejectBooking && RejectBooking.getCancelAmbulanceBooking.length
                ],
                backgroundColor: [
                    "#2ecc71",
                    "#3498db",
                    "#e67e22",
                    "#9b59b6",
                ],
                borderColor: "black",
                borderWidth: 0,
            },
        ],
    });


    useEffect(() => {
        setUserData(
            {
                labels: UserData.map((data) => data.name),
                datasets: [
                    {
                        label: "Users Gained",
                        data: [
                            activeData && activeData.getActiveAmbulance.length,
                            PendingData && PendingData.getPendingAmbulance.length,
                            allBooking && allBooking.getAmbulanceAllBookings.length,
                            RejectBooking && RejectBooking.getCancelAmbulanceBooking.length
                        ],
                        backgroundColor: [
                            "#2ecc71",
                            "#3498db",
                            "#e67e22",
                            "#9b59b6",
                        ],
                        borderColor: "black",
                        borderWidth: 0,
                    },
                ],
            }
        )
    }, [activeData, PendingData, allBooking, RejectBooking]);




    const [options, setOptions] = useState({
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'All Details',
            },
            style: {
                width: 100,
                height: 100
            }
        },
    });




    return (
        <>
            <Container fluid>
                <div className="cardBox">
                    <Link to='/superAdminDashboard/activeAmbulance' style={{ textDecoration: 'none' }}>
                        <div className="card">
                            <div>
                                <div className="numbers">{activeData && activeData.getActiveAmbulance.length}</div>
                                <div className="cardName">Active Ambulances</div>
                            </div>
                            <div className="iconBx">
                            </div>
                        </div>
                    </Link>
                    <Link to='/superAdminDashboard/ambulanceRequest' style={{ textDecoration: 'none' }}>
                        <div className="card">
                            <div>
                                <div className="numbers">{PendingData && PendingData.getPendingAmbulance.length}</div>
                                <div className="cardName">Pending Ambulances</div>
                            </div>
                            <div className="iconBx">
                            </div>
                        </div>
                    </Link>
                    <Link to='/superAdminDashboard/ambulanceBooking' style={{ textDecoration: 'none' }}>
                        <div className="card">
                            <div>
                                <div className="numbers">{allBooking && allBooking.getAmbulanceAllBookings.length}</div>
                                <div className="cardName">Total Bookings</div>
                            </div>
                            <div className="iconBx">
                            </div>
                        </div>
                    </Link>
                    <Link to='/superAdminDashboard/ambulanceBooking' style={{ textDecoration: 'none' }}>
                        <div className="card">
                            <div>
                                <div className="numbers">{RejectBooking && RejectBooking.getCancelAmbulanceBooking.length}</div>
                                <div className="cardName">Cancel Bookings</div>
                            </div>
                            <div className="iconBx">
                            </div>

                        </div>
                    </Link>
                </div>
                <Row>
                    <Col md={6}>
                        <Bar data={userDataa} options={options} redraw={true} />
                    </Col>
                    <Col md={6}>
                        <Pie data={userDataa} options={options} />
                    </Col>
                </Row>
            </Container>
        </>
    )
}
