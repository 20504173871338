import { Navbar, Container, Nav, Button, NavDropdown } from 'react-bootstrap'
import '../ComponentCss/Navigation.css'
import { Link } from "react-scroll";
import { Link as Link1 } from 'react-router-dom'
import Logo from '../Images/logo.png'

export default function Navigation() {
    const superAdminToken = localStorage.getItem('superAdminToken')
    const hospitalToken = localStorage.getItem('hospitalToken')
    const bloodBankToken = localStorage.getItem("bloodBankToken")
    const pathologyToken = localStorage.getItem("PathologyToken")
    const radiologyToken = localStorage.getItem("RadiologyToken")
    const ambulanceToken = localStorage.getItem("ambulanceToken")
    const subAdminToken = localStorage.getItem("subAdminToken")

    const logoutHandle = () => {
        localStorage.removeItem('superAdminToken')
        localStorage.removeItem('hospitalId')
        localStorage.removeItem('superAdminId')
        localStorage.removeItem('hospitalToken')
        localStorage.removeItem('bloodBankToken')
        localStorage.removeItem('PathologyToken')
        localStorage.removeItem('RadiologyToken')
        localStorage.removeItem('ambulanceToken')
        localStorage.removeItem('subAdminId')
        localStorage.removeItem('subAdminToken')
        window.reload()
    }

    return (
        <div>
            <Navbar collapseOnSelect expand="lg" variant="light" fixed="top" bg="white">
                <Container >
                    <Navbar.Brand href="#"><h3 style={{ fontWeight: 'bold' }}>
                        <Link1 to="/">
                            <img src={Logo} width="150" height="60" className="d-inline-block align-top" alt="React Bootstrap logo" />
                        </Link1>
                    </h3></Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll " className="justify-content-end">
                        <Nav>
                            <Link1 to="/" className="nav-link headerLink" style={{ fontFamily: 'DM Sans', cursor: 'pointer', fontWeight: 'bold', color: '#201e1e' }}>HOME</Link1>
                            <Link to="aboutId" spy={true} offset={-70} duration={500} className="nav-link" style={{ fontFamily: 'DM Sans', cursor: 'pointer', fontWeight: 'bold', color: '#201e1e' }}>ABOUT</Link>
                            <Link to="serviceId" spy={true} offset={-70} duration={500} className="nav-link" style={{ fontFamily: 'DM Sans', cursor: 'pointer', fontWeight: 'bold', color: '#201e1e' }}>SERVICES</Link>
                            <Link to="contactId" spy={true} offset={-70} duration={500} className="nav-link" style={{ fontFamily: 'DM Sans', cursor: 'pointer', fontWeight: 'bold', color: '#201e1e' }}>CONTACT</Link>
                            {
                                superAdminToken ?
                                    <>
                                        <Link1 to="/superAdminDashboard/homeDashboard" className="nav-link headerLink">Dashboard</Link1>
                                        <Link1 to="/" className="nav-link headerLink" onClick={() => logoutHandle()}>Logout</Link1>
                                    </>
                                    :
                                    hospitalToken ?
                                        <>
                                            <Link1 to="/hospitalDashboard" className="nav-link headerLink">Dashboard</Link1>
                                            <Link1 to="/" className="nav-link headerLink" onClick={() => logoutHandle()}>Logout</Link1>
                                        </>
                                        : bloodBankToken ?
                                            <>
                                                <Link1 to="/BloodBankDeshboard" className="nav-link headerLink">Dashboard</Link1>
                                                <Link1 to="/" className="nav-link headerLink" onClick={() => logoutHandle()}>Logout</Link1>
                                            </>

                                            : pathologyToken ?
                                                <>
                                                    <Link1 to="/PathologyDashboard" className="nav-link headerLink">Dashboard</Link1>
                                                    <Link1 to="/" className="nav-link headerLink" onClick={() => logoutHandle()}>Logout</Link1>
                                                </>
                                                : radiologyToken ?
                                                    <>
                                                        <Link1 to="/RadiologyDashboard" className="nav-link headerLink">Dashboard</Link1>
                                                        <Link1 to="/" className="nav-link headerLink" onClick={() => logoutHandle()}>Logout</Link1>
                                                    </>
                                                    : ambulanceToken ?
                                                        <>
                                                            <Link1 to="/AmbulanceDashboard" className="nav-link headerLink">Dashboard</Link1>
                                                            <Link1 to="/" className="nav-link headerLink" onClick={() => logoutHandle()}>Logout</Link1>
                                                        </>

                                                        : subAdminToken ?
                                                            <>
                                                                <Link1 to="/subAdminDashboard/subDashboardHome" className="nav-link headerLink">Dashboard</Link1>
                                                                <Link1 to="/" className="nav-link headerLink" onClick={() => logoutHandle()}>Logout</Link1>
                                                            </>
                                                            :
                                                            <>
                                                                <NavDropdown title="LOGIN" id="collasible-nav-dropdown" style={{ fontFamily: "Dm sans", fontWeight: "800", marginTop: '-1px', marginLeft: '10px', marginRight: '10px' }}>
                                                                    <Link1 to="/superAdminLogin" className="nav-link headerLink" style={{ fontSize: '12px' }}>Super Admin Login</Link1>
                                                                    <Link1 to="/subAdminLogin" className="nav-link headerLink" style={{ fontSize: '12px' }}>Sub Admin Login</Link1>
                                                                    {/* <Link1 to="/hospitalLogin" className="nav-link headerLink" style={{fontSize:'12px'}}>Hospital Login</Link> */}
                                                                    {/* <Link1 to="/PathologyLogin" className="nav-link headerLink" style={{ fontSize: '12px' }}>Pathology Login</Link1>
                                                                <Link1 to="/RadiologyLogin" className="nav-link headerLink" style={{ fontSize: '12px' }}>Radiology Login</Link1> */}
                                                                    <Link1 to="/AmbulanceLogin" className="nav-link headerLink" style={{ fontSize: '12px' }}>Ambulance Login</Link1>
                                                                    <Link1 to="/BloodBankLogin" className="nav-link headerLink" style={{ fontSize: '12px' }}>Blood Bank Login</Link1>
                                                                </NavDropdown>
                                                            </>

                            }
                            <Button className="mx-auto d-block NavigationButton" style={{ fontSize: '12px' }}> <a href="tel:9110188852" style={{ color: "#fff", textDecoration: "none" }}>HOTLINE - 9110188852</a></Button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

