import { useSubscription, useMutation, gql, useQuery } from '@apollo/client'
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { Container, Row, Col, Image, Spinner, Form, Button, Modal } from 'react-bootstrap'
import { QUERY_AMBULANCE_BY_ID } from '../../graphql/queries'
import { useJsApiLoader, GoogleMap, MarkerF } from '@react-google-maps/api'
import Moment from 'react-moment';
import haversine from 'haversine-distance'
import dbImg from "../../Images/mapLocation.png"
import "../../ComponentCss/NewDashboard.css"
import { FaAlignJustify, FaHome, FaUsers, FaUsersCog, FaHeadset, FaKey, FaSignOutAlt, FaExternalLinkAlt, FaInfoCircle, FaRegEdit } from "react-icons/fa";
import { Chart as ChartJS } from "chart.js/auto";
import { LinkContainer } from 'react-router-bootstrap'
import { MUTATION_UPDATE_AMBULANCE, MUTATION_UPDATE_AMBULANCE_FACILITY } from '../../graphql/mutations'

const mapRef = React.createRef();

const SUBSCRIPTION_USER = gql`
        subscription Subscription {
            newUserSos {
                id
                userId
                fName
                lName
                email
                contact
                createDateTime
                status
            }
        }`

export default function AmbulanceProfile() {


  const ambulanceId = localStorage.getItem("ambulanceId")

  const { data, loading, refetch } = useQuery(QUERY_AMBULANCE_BY_ID, {
    variables: {
      "ambulanceId": `${ambulanceId}`
    },

  })

  console.log("data", data)

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [stop, setStop] = useState(false)
  const [bharpdis, setBhaprDis] = useState(data && data.getAmbulanceById.bharpDiscount)
  const [vendorMar, setVendorMar] = useState(data && data.getAmbulanceById.vendorMargin)
  const [localPriceParKm, setLocalPriceParKm] = useState(data && data.getAmbulanceById.localPriceKm)
  const [localBookingPrice, setLocalBookingPrice] = useState(data && data.getAmbulanceById.localBasePrice)
  const [outlocalBookingPrice, setOutLocalBookingPrice] = useState(data && data.getAmbulanceById.outLocalBasePrice)
  const [outlocalPriceParKm, setOutLocalPriceParKm] = useState(data && data.getAmbulanceById.outLocalPerKmPrice)
  const [localThresholderPriceParKm, setLocalThresholderPriceParKm] = useState(data && data.getAmbulanceById.localThresholderKm)
  const [acPriceParKm, setAcPriceParKm] = useState(data && data.getAmbulanceById.acPerKmPrice)
  const [nightServicePrice, setNightServicePrice] = useState(data && data.getAmbulanceById.nightServicePrice)


  if (data && stop === false) {
    setBhaprDis(data && data.getAmbulanceById.bharpDiscount)
    setVendorMar(data && data.getAmbulanceById.vendorMargin)
    setLocalPriceParKm(data && data.getAmbulanceById.localPriceKm)
    setLocalBookingPrice(data && data.getAmbulanceById.localBasePrice)
    setOutLocalBookingPrice(data && data.getAmbulanceById.outLocalBasePrice)
    setOutLocalPriceParKm(data && data.getAmbulanceById.outLocalBasePrice)
    setLocalThresholderPriceParKm(data && data.getAmbulanceById.localThresholderKm)
    setAcPriceParKm(data && data.getAmbulanceById.acPerKmPrice)
    setNightServicePrice(data && data.getAmbulanceById.nightServicePrice)
    setStop(true)

  }

  const [facilityId, setFacilityId] = useState()
  const [facilityName, setFacilityName] = useState()
  const [facilityPrice, setFacilityPrice] = useState()

  function handEditFacility(id, Name, Price) {
    setFacilityId(id)
    setFacilityName(Name)
    setFacilityPrice(Price)
    handleShow2()

  }



  const [validated, setValidated] = useState(false);

  const [updateAmbulancefacility] = useMutation(MUTATION_UPDATE_AMBULANCE_FACILITY, {
    refetchQueries: [
      QUERY_AMBULANCE_BY_ID,
      "getAmbulanceById"
    ]
  })

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      updateAmbulancefacility({
        variables: {
          "ambulanceId": `${data && data.getAmbulanceById.id}`,
          "facility": `${facilityId}`,
          "facilityPrice": `${facilityPrice}`
        }
      })
      handleClose2()
    }

    setValidated(true);
  };


  const [updateAmbulance] = useMutation(MUTATION_UPDATE_AMBULANCE, {
    refetchQueries: [
      QUERY_AMBULANCE_BY_ID,
      "getAmbulanceById"
    ]
  })
  function handleChange() {
    updateAmbulance({
      variables: {
        "updateAmbulanceInput": {
          "ambulanceId": `${data && data.getAmbulanceById.id}`,
          "vendorMargin": `${vendorMar}`,
          "bharpDiscount": `${bharpdis}`,
          "localThresholderKm": `${localThresholderPriceParKm}`,
          "localBasePrice": `${localBookingPrice}`,
          "localPriceKm": `${localPriceParKm}`,
          "outLocalBasePrice": `${outlocalBookingPrice}`,
          "outLocalPerKmPrice": `${outlocalPriceParKm}`,
          "acPerKmPrice": `${acPriceParKm}`,
          "nightServicePrice": `${nightServicePrice}`

        }
      }
    })
    handleClose()
  }







  function handleMenu() {

    let toggle = document.querySelector(".toggle");
    let navigation = document.querySelector(".navigation");
    let main = document.querySelector(".main");
    navigation.classList.toggle("active");
    main.classList.toggle("active");
  };








  setInterval(() => {
    refetch()
  }, 5000)

  useEffect(() => {
    if (data) {
      setLatitude(parseFloat(data && data.getAmbulanceById.latitude))
      setLongtude(parseFloat(data && data.getAmbulanceById.longitude))
    }
  }, [data])

  const [latitude, setLatitude] = useState(parseFloat(data && data.getAmbulanceById.latitude))
  const [longtude, setLongtude] = useState(parseFloat(data && data.getAmbulanceById.longitude))
  console.log("lat", latitude)
  console.log("lon", longtude)

  const [center, setCenter] = useState({ lat: latitude, lng: longtude })


  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyB2eRRn8bW1VVh4wj4AEaYd3F_RgEP2mE4'
  })


  console.log("data", data)

  return (
    <>
      <div >
        <div className="navigation">
          <br></br>

          <span className="title" style={{ fontSize: 20, fontFamily: 'DM Sans', fontWeight: 'bold', color: "#fff", textAlign: "center", marginLeft: 20, marginTop: 20 }}>Dashboard</span>
          <ul>
            <br></br><br></br><br></br>

            <li>
              <LinkContainer to="/">
                <a href="#">
                  <span className="icon">

                  </span>
                  <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }} eventKey="first"><FaHome /> Home</span>

                </a>
              </LinkContainer>
            </li>
            <li>
              <LinkContainer to="/AmbulanceProfile">
                <a href="#">
                  <span className="icon">
                  </span>
                  <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }}><FaUsers /> Profile</span>
                </a>
              </LinkContainer>
            </li>
            <li>
              <LinkContainer to="/AllAmbulanceBooking">
                <a href="#">
                  <span className="icon"> </span>
                  <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }}><FaExternalLinkAlt /> All Booking</span>
                </a>
              </LinkContainer>
            </li>

            <li>
              <LinkContainer to="/Termcondition">
                <a href="#">
                  <span className="icon"> </span>
                  <span className="title" style={{ fontSize: 12, fontFamily: 'DM Sans' }}><FaInfoCircle /> Terms & Condition</span>
                </a>
              </LinkContainer>
            </li>

          </ul>

        </div>


        <div className="main">
          <div className="topbar">
            <div>
              <FaAlignJustify onClick={() => handleMenu()} className="toggle" />
            </div>
            <div className="search">
              <label>
                <input type="text" placeholder="Search here" />
              </label>
            </div>
            <div className="user">
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/2048px-Circle-icons-profile.svg.png" alt="" />
            </div>
          </div>

          {
            loading ?
              <>
                <div className="mx-auto d-block">
                  <Spinner animation="border" />
                </div>
              </>

              :
              <>
                <Container>
                  <Row>
                    <Col md={4}>
                      {/* <Image src="https://www.karexpert.com/wp-content/uploads/2022/04/ambulance-hero.webp" style={{ marginTop: 20 }} className="img-fluid" alt="Responsive image" /> */}

                      {
                        data && data.getAmbulanceById.vehicleImg === "" ?
                          <Image src="https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png" style={{ marginTop: 20, borderRadius: 10, width: 150, height: 150 }} className="img-fluid mx-auto d-block" alt="Responsive image" />
                          :
                          <Image src={`https://bharp-hospital.s3.ap-south-1.amazonaws.com/${data && data.getAmbulanceById.vehicleImg}`} style={{ marginTop: 20, borderRadius: 10, width: 150, height: 150 }} className="img-fluid mx-auto d-block" alt="Responsive image" />
                      }
                    </Col>
                    <Col md={4}>
                      {
                        data && data.getAmbulanceById.ambulanceOwnerShipOther === "" ?
                          <h6 style={{ padding: 5, border: "2px solid #3498db", marginTop: 18, borderRadius: 10, fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}>Ambulance Ownership : <span style={{ fontWeight: 'normal' }}>{data && data.getAmbulanceById.ambulanceOwnerShip}</span></h6>
                          :
                          <h6 style={{ padding: 5, border: "2px solid #3498db", marginTop: 18, borderRadius: 10, fontSize: 12, fontWeight: 'bold', fontFamily: 'DM Sans' }}>Ambulance Ownership: <span style={{ fontWeight: 'normal' }}>{data && data.getAmbulanceById.ambulanceOwnerShipOther}</span></h6>
                      }
                      <h6 style={{ fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}> Owner Name : <span style={{ fontWeight: 'normal' }}>{data && data.getAmbulanceById.ownerfName} {data && data.getAmbulanceById.ownerlName}</span></h6>
                      <h6 style={{ fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}> Owner Contact : <span style={{ fontWeight: 'normal' }}>{data && data.getAmbulanceById.ownerContact}</span></h6>
                      <h6 style={{ fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}> Owner Email : <span style={{ fontWeight: 'normal' }}>{data && data.getAmbulanceById.ownerEmail}</span></h6>
                    </Col>
                    <Col md={4}>
                      {
                        data && data.getAmbulanceById.vehicleTypeOther === "" ?
                          <h6 style={{ padding: 5, border: "2px solid #3498db", marginTop: 18, borderRadius: 10, fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}>Vehicle Type : <span style={{ fontWeight: 'normal' }}>{data && data.getAmbulanceById.vehicleType}</span></h6>
                          :
                          <h6 style={{ padding: 5, border: "2px solid #3498db", marginTop: 18, borderRadius: 10, fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}>Vehicle Type Other : <span style={{ fontWeight: 'normal' }}>{data && data.getAmbulanceById.vehicleTypeOther}</span></h6>
                      }
                      <h6 style={{ fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}> Driver Name : <span style={{ fontWeight: 'normal' }}>{data && data.getAmbulanceById.driverFName} {data && data.getAmbulanceById.driverlName}</span></h6>
                      <h6 style={{ fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}> Driver Contact : <span style={{ fontWeight: 'normal' }}>{data && data.getAmbulanceById.driverContact}</span></h6>
                      <h6 style={{ fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}> Driver Email : <span style={{ fontWeight: 'normal' }}>{data && data.getAmbulanceById.driverEmail}</span></h6>
                      <h6 style={{ fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}>Vehicle Number : <span style={{ fontWeight: 'normal' }}>{data && data.getAmbulanceById.vehicleNo}</span></h6>
                      <h6 style={{ fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}>Price Per km : <span style={{ fontWeight: 'normal' }}>{data && data.getAmbulanceById.pricePerKms}</span></h6>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: 50 }}>
                    <Col md={6}>
                      {
                        !isLoaded ?
                          <>
                            <div className="mx-auto d-block">
                              <Spinner animation="border" />
                            </div>
                          </>
                          :
                          <>
                            <GoogleMap center={{ lat: latitude, lng: longtude }} zoom={15} mapContainerStyle={{ width: '100%', height: '300px' }}>
                              <MarkerF position={{ lat: latitude, lng: longtude }} />
                            </GoogleMap>
                          </>



                      }


                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md={6}>
                          <p style={{ fontFamily: "Dm sans", fontSize: 12 }}>Local Thresholder Par Km  : {data && data.getAmbulanceById.localThresholderKm} /-</p>
                          <p style={{ fontFamily: "Dm sans", fontSize: 12 }}>Local Price Par Km  : {data && data.getAmbulanceById.localPriceKm} /-</p>
                          <p style={{ fontFamily: "Dm sans", fontSize: 12 }}> Local Booking Price  : {data && data.getAmbulanceById.localBasePrice} /-</p>
                          <p style={{ fontFamily: "Dm sans", fontSize: 12 }}>Out Local Price Par Km  : {data && data.getAmbulanceById.outLocalPerKmPrice} /-</p>
                          <p style={{ fontFamily: "Dm sans", fontSize: 12 }}>Out Local Booking Price  : {data && data.getAmbulanceById.outLocalBasePrice} /-</p>
                          <p style={{ fontFamily: "Dm sans", fontSize: 12 }}> Bharp Discount : {data && data.getAmbulanceById.bharpDiscount} %</p>
                          <p style={{ fontFamily: "Dm sans", fontSize: 12 }}> Vendor Margin : {data && data.getAmbulanceById.vendorMargin} %</p>
                        </Col>
                        <Col md={6}>

                          <p style={{ fontFamily: "Dm sans", fontSize: 12 }}>AC Available  : {data && data.getAmbulanceById.acAvailable} </p>
                          <p style={{ fontFamily: "Dm sans", fontSize: 12 }}> AC Price Par Km  : {data && data.getAmbulanceById.acPerKmPrice} /-</p>
                          <p style={{ fontFamily: "Dm sans", fontSize: 12 }}>Night Service Available : {data && data.getAmbulanceById.nightServiceAvailable}</p>
                          <p style={{ fontFamily: "Dm sans", fontSize: 12 }}>Night Service Start  : {data && data.getAmbulanceById.nightServiceStartTime} </p>
                          <p style={{ fontFamily: "Dm sans", fontSize: 12 }}>Night Service End : {data && data.getAmbulanceById.nightServiceEndTime} </p>
                          <p style={{ fontFamily: "Dm sans", fontSize: 12 }}>Night Service Price : {data && data.getAmbulanceById.nightServicePrice} /-</p>
                        </Col>


                      </Row>


                      <Button style={{ fontFamily: "Dm sans", border: "none" }} onClick={() => handleShow()}>Change </Button>


                    </Col>
                  </Row>

                  <Row style={{ margin: 10 }}>
                    {
                      data && data.getAmbulanceById.facility.map(item => {
                        return (
                          <Col style={{ background: "#00a8ff", margin: 5 }} md={3}>
                            <h6 style={{ color: "#fff", marginTop: 10 }}>Facilitate Name : {item.facilityName} </h6>
                            <h6 style={{ color: "#fff" }}>Facilitate Price : {item.facilityPrice} /-</h6>
                            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 10 }}>
                              <Button style={{ border: "none", background: "#fff" }} onClick={() => handEditFacility(item.id, item.facilityName, item.facilityPrice)}>
                                <FaRegEdit color="#000" />
                              </Button>
                            </div>
                          </Col>

                        )
                      })}
                  </Row>
                </Container>


              </>


          }

        </div>

        <Modal show={show} onHide={handleClose}>
          <div style={{ margin: 20 }}>

            <Row>
              <Col md={6}>
                <label className='mx-auto d-block' style={{ marginLeft: 20, fontWeight: "bold", fontSize: 12 }}>Bharp Discount</label>
                <input placeholder='Bharp Discount' onChange={(e) => setBhaprDis(e.target.value)} value={bharpdis} />
              </Col>
              <Col md={6}>
                <label className='mx-auto d-block' style={{ marginLeft: 20, fontWeight: "bold", fontSize: 12 }}>Vendor Margin</label>
                <input placeholder='Vendor Margin' onChange={(e) => setVendorMar(e.target.value)} value={vendorMar} />

              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <br></br>
                <label className='mx-auto d-block' style={{ marginLeft: 20, fontWeight: "bold", fontSize: 12 }}>Local Price Par Km</label>
                <input placeholder='Local Price Par Km' onChange={(e) => setLocalPriceParKm(e.target.value)} value={localPriceParKm} />
              </Col>

              <Col md={6}>
                <br></br>
                <label className='mx-auto d-block' style={{ marginLeft: 20, fontWeight: "bold", fontSize: 12 }}>Local Booking Price</label>
                <input placeholder='Local Booking Price' onChange={(e) => setLocalBookingPrice(e.target.value)} value={localBookingPrice} />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <br></br>
                <label className='mx-auto d-block' style={{ marginLeft: 20, fontWeight: "bold", fontSize: 12 }}> Out Local Price Par Km</label>
                <input placeholder='Out Local Price Par Km' onChange={(e) => setOutLocalPriceParKm(e.target.value)} value={outlocalPriceParKm} />
              </Col>

              <Col md={6}>
                <br></br>
                <label className='mx-auto d-block' style={{ marginLeft: 20, fontWeight: "bold", fontSize: 12 }}>Out Local Booking Price</label>
                <input placeholder='Out Local Booking Price' onChange={(e) => setOutLocalBookingPrice(e.target.value)} value={outlocalBookingPrice} />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <br></br>
                <label className='mx-auto d-block' style={{ marginLeft: 20, fontWeight: "bold", fontSize: 12 }}> Local Thresholder Price Par Km</label>
                <input placeholder='Local Thresholder Price Par Km' onChange={(e) => setLocalThresholderPriceParKm(e.target.value)} value={localThresholderPriceParKm} />
              </Col>

              <Col md={6}>
                <br></br>
                <label className='mx-auto d-block' style={{ marginLeft: 20, fontWeight: "bold", fontSize: 12 }}>AC Price Par Km</label>
                <input placeholder='AC Price Par Km' onChange={(e) => setAcPriceParKm(e.target.value)} value={acPriceParKm} />
              </Col>

              <Col md={6}>
                <br></br>
                <label className='mx-auto d-block' style={{ marginLeft: 20, fontWeight: "bold", fontSize: 12 }}>Night Service Price </label>
                <input placeholder='Night Service Price' onChange={(e) => setNightServicePrice(e.target.value)} value={nightServicePrice} />
              </Col>
            </Row>





          </div>
          <Button style={{ border: "none" }} onClick={() => handleChange()}>Change Now</Button>
        </Modal>

        <Modal show={show2} onHide={handleClose2}>
          <Container>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row className="mb-3" style={{ marginTop: 20 }}>
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Label>Facilitate {facilityName} </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Facilitate Price"
                    onChange={(e) => setFacilityPrice(e.target.value)}
                    value={facilityPrice}
                  />



                </Form.Group>
              </Row>


              <Button type="submit" className='mx-auto d-block' style={{ border: "none", marginBottom: 20 }}>Change</Button>
            </Form>
          </Container>
        </Modal>

      </div>















    </>
  )
}
