import { useMutation, useQuery } from '@apollo/client';
import React,{useState} from 'react'
import swal from 'sweetalert';
import {Row ,Col,Image,Form,Button,Modal,Container}from "react-bootstrap"
import { FaPenSquare, FaPen, FaPhoneAlt, FaEnvelopeOpen, FaMapMarkerAlt, FaHistory } from "react-icons/fa";
import { MUTATION_UPDATE_BLOOD_BANK } from '../../graphql/mutations';
import { QUERY_BLOOD_BANK_BY_ID } from '../../graphql/queries';
import "../../ComponentCss/NewDashboard.css"
import { Bar, Pie } from "react-chartjs-2";
import { FaAlignJustify, FaHome, FaUsers, FaUsersCog, FaHeadset, FaKey, FaSignOutAlt ,FaClock,FaInfoCircle} from "react-icons/fa";
import { UserData } from '../SuperAdmin/Data';
import { Chart as ChartJS } from "chart.js/auto";
import { LinkContainer } from 'react-router-bootstrap'


export default function BloodBankProfile() {


  const id = localStorage.getItem("bloodBankId")

    const{data} = useQuery(QUERY_BLOOD_BANK_BY_ID,{
      variables:{
        "bloodBankId":`${id}`
      }
    })

    function handleMenu() {

      let toggle = document.querySelector(".toggle");
      let navigation = document.querySelector(".navigation");
      let main = document.querySelector(".main");
      navigation.classList.toggle("active");
      main.classList.toggle("active");
    };

    console.log(data)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
   
   function handleClick(){

    handleShow()
   }


   const[stop,setStop] =useState(false)
    const[idData,setIdData] =useState(data && data.getBloodBankById.id)
   const[centerName,setCenterName] =useState(data && data.getBloodBankById.centerName)
   const [RegistNo,setRegistNo] =useState(data && data.getBloodBankById.registrationNo)
   const [centerAdd,setCenterAdd] =useState(data && data.getBloodBankById.address)
   const [centerPincode,setCenterPincode] =useState(data && data.getBloodBankById.pincode)
    const [centerState,setCenterState] =useState(data && data.getBloodBankById.state)
   const [centerContact,setCenterContact] =useState(data && data.getBloodBankById.centerContact)
   
   const [centerEmail,setCenterEmail] =useState(data && data.getBloodBankById.centerEmail)
   const [CPFName,setCPFName] =useState(data && data.getBloodBankById.nodalPersonFName)
   const [CPLName,setCPLName] =useState(data && data.getBloodBankById.nodalPersonLName)
   const [CPContact,setCPContact] =useState(data && data.getBloodBankById.nodalPersonContact)
   const [CPEmail,setCPEmail] =useState(data && data.getBloodBankById.nodalPersonEamil)
   const [BBType,setBBType] =useState(data && data.getBloodBankById.bloodBankType)
   const [BBOwnerShip,setBBOwnerShip] =useState(data && data.getBloodBankById.bloodBankOwnerShip)



   if(data && stop === false){
    setIdData(data && data.getBloodBankById.id)
    setCenterName(data && data.getBloodBankById.centerName)
    setRegistNo(data && data.getBloodBankById.registrationNo)
    setCenterAdd(data && data.getBloodBankById.address)
    setCenterPincode(data && data.getBloodBankById.pincode)
    setCenterState(data && data.getBloodBankById.state)
    setCenterContact(data && data.getBloodBankById.centerContact)
    setCenterEmail(data && data.getBloodBankById.centerEmail)
    setCPFName(data && data.getBloodBankById.nodalPersonFName)
    setCPLName(data && data.getBloodBankById.nodalPersonLName)
    setCPContact(data && data.getBloodBankById.nodalPersonContact)
    setCPEmail(data && data.getBloodBankById.nodalPersonEamil)
    setBBType(data && data.getBloodBankById.bloodBankType)
    setBBOwnerShip(data && data.getBloodBankById.bloodBankOwnerShip)
    setStop(true)

   }


   const [validated, setValidated] = useState(false);
   
   console.log(idData)


     const[updatebloodbank] =useMutation(MUTATION_UPDATE_BLOOD_BANK,{
      refetchQueries:[
        QUERY_BLOOD_BANK_BY_ID,
        "getBloodBankById"
      ]
     })
    
   const handleSubmit = (event) => {
       const form = event.currentTarget;
       if (form.checkValidity() === false) {
         event.preventDefault();
         event.stopPropagation();
       }else{
        event.preventDefault();
        updatebloodbank({
          variables:{
          "updatebloodbankInput": {
            "bloodBankId": `${idData}`,
            "centerName": `${centerName}`,
            "centerContact": `${centerContact}`,
            "centerEmail": `${centerEmail}`,
            "address": `${centerAdd}`,
            "pincode":`${centerPincode}`,
            "state": `${centerState}`,
            "nodalPersonFName": `${CPFName}`,
            "nodalPersonLName": `${CPLName}`,
            "nodalPersonContact": `${CPContact}`,
            "nodalPersonEamil": `${CPEmail}`,
            "bloodBankTypeOther": `${BBType}`,
            "bloodBankOwnerShipOther":`${BBOwnerShip}`,
            "registrationNo": `${RegistNo}`
          }
        }
        })
         handleClose();
         swal({ title: 'Successfull!!!', text: 'Update Successfully', icon: 'success' });


       }

       setValidated(true);
      };
    
  return (
    <>
      <div >
                <div className="navigation">
                    <br></br> 
                  
                <span className="title" style={{ fontSize: 20, fontFamily: 'DM Sans', fontWeight: 'bold',color:"#fff",textAlign:"center",marginLeft:20,marginTop:20 }}>Dashboard</span>
                    <ul>
                        <br></br><br></br><br></br>
                       
                        <li>
                        <LinkContainer to="/">
                            <a href="#">
                                <span className="icon">

                                </span>
                                <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }} eventKey="first"><FaHome /> Home</span>
                              
                            </a>
                            </LinkContainer>
                        </li>
                        <li>
                        <LinkContainer to="/BloodBankProfile">
                            <a href="#">
                                <span className="icon">
                                </span>
                                <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }}><FaUsers /> Profile</span>
                            </a>
                            </LinkContainer>
                        </li>
                        <li>
                        <LinkContainer to="/EditBloodBank">
                            <a href="#">
                                <span className="icon"> </span>
                                <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }}><FaPenSquare /> Edit Blood Bank</span>
                            </a>
                            </LinkContainer>
                        </li>
                      
                        <li>
                        <LinkContainer to="/Termcondition">
                            <a href="#">
                                <span className="icon"> </span>
                                <span className="title" style={{ fontSize: 12, fontFamily: 'DM Sans' }}><FaInfoCircle /> Terms & Condition</span>
                            </a>
                            </LinkContainer>
                        </li>
                       
                    </ul>
                    
                </div>
                

                <div className="main">
                    <div className="topbar">
                        <div>
                            <FaAlignJustify onClick={() => handleMenu()}  className="toggle"/>
                        </div>
                        <div className="search">
                            <label>
                                <input type="text" placeholder="Search here" />
                            </label>
                        </div>
                        <div className="user">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/2048px-Circle-icons-profile.svg.png" alt="" />
                        </div>
                    </div>
                   
                    <h3 style={{textAlign:"center",marginTop:20,fontFamily:"Dm sans",fontWeight:"bold"}}>{data && data.getBloodBankById.centerName}</h3>
    <hr></hr>
    <Row>
     <Col md={6}>
        <Image src="https://play-lh.googleusercontent.com/NuJSG_bIoce6kvvtJnULAf34_Rsa1j-HDEt4MWTOrL_3XA51QH9qOQR5UmAYxPI96jA=w600-h300-pc0xffffff-pd"  className='mx-auto d-block img-fluid' alt="Responsive image"/>
     </Col>
    <Col md={6}>
    <Row>
        <Col md={6} style={{border:"1px solid #00a8ff",textAlign:"center"}}>
            <h6 style={{marginTop:10,fontWeight:"bold",color:"#00a8ff",fontFamily:"Dm sans"}}>Blood Bank Type</h6>
            <h6 style={{marginTop:10,fontFamily:"Dm sans"   }}>{data && data.getBloodBankById.bloodBankType}</h6>
          
        </Col>
        <Col md={6} style={{border:"1px solid #00a8ff",textAlign:"center",}}>
        <h6 style={{marginTop:10,fontWeight:"bold",color:"#00a8ff",fontFamily:"Dm sans"}}>Blood Bank OwnerShip</h6>
        <h6 style={{marginTop:10,fontFamily:"Dm sans"}}>{data && data.getBloodBankById.bloodBankOwnerShip}</h6>

        </Col>
    </Row>
    <h6 style={{marginTop:30,fontFamily:"Dm sans"}}>Registartion No : {data && data.getBloodBankById.registrationNo}</h6>
  <h6 style={{marginTop:20  }}><FaPhoneAlt style={{marginRight:20}}/>{data && data.getBloodBankById.centerContact}</h6>

  <h6 style={{marginTop:5  }}><FaEnvelopeOpen style={{marginRight:20}}/>{data && data.getBloodBankById.centerContact} </h6>
  <h6 style={{marginTop:5  }}><FaMapMarkerAlt style={{marginRight:20}}/>{data && data.getBloodBankById.address}, {data && data.getBloodBankById.pincode}, {data && data.getBloodBankById.city} {data && data.getBloodBankById.district}  {data && data.getBloodBankById.state}</h6>


  <h6 style={{marginTop:20  }}>Nodal Person Name    : {data && data.getBloodBankById.nodalPersonFName} {data && data.getBloodBankById.nodalPersonLName}</h6>
  <h6 style={{marginTop:5  }}>Nodal Person Contact    : {data && data.getBloodBankById.nodalPersonContact} </h6>
  <h6 style={{marginTop:5  }}>Nodal Person Email    : {data && data.getBloodBankById.nodalPersonEamil} </h6>
  <h6 style={{marginTop:5,marginBottom:20  }}>Emergency Contact   : {data && data.getBloodBankById.emergencyContact}</h6>
  <h6 style={{marginTop:5,marginBottom:20  }}  onClick={()=>handleClick()}>Edit Profile</h6>
    </Col>

    </Row>

    <Row>
        <Col md={3} style={{border:"1px solid #00a8ff",textAlign:"center"}}>
            <h6 style={{fontWeight:"bold",fontFamily:"Dm sans",marginTop:10,color:"#00a8ff"}}>Blood Group A+</h6>
            {
             data && data.getBloodBankById.bloodBankAPos.map(lis=>{
              return(
                <>
               
                <h6 style={{fontWeight:"bold",fontFamily:"Dm sans",marginTop:10,color:"#000"}}>Available : {lis.available}</h6>
                </>
              )
             })

            }
          
            </Col>
        <Col md={3} style={{border:"1px solid #00a8ff",textAlign:"center"}}>

        <h6 style={{fontWeight:"bold",fontFamily:"Dm sans",marginTop:10,color:"#00a8ff"}}>Blood Group A-</h6>
        {
             data && data.getBloodBankById.bloodBankANeg.map(lis=>{
              return(
                <>
               
                <h6 style={{fontWeight:"bold",fontFamily:"Dm sans",marginTop:10,color:"#000"}}>Available : {lis.available}</h6>
                </>
              )
             })

            }
        </Col>
        <Col md={3} style={{border:"1px solid #00a8ff",textAlign:"center"}}>
        <h6 style={{fontWeight:"bold",fontFamily:"Dm sans",marginTop:10,color:"#00a8ff"}}>Blood Group B+</h6>
        {
             data && data.getBloodBankById.bloodBankBPos.map(lis=>{
              return(
                <>
                
                <h6 style={{fontWeight:"bold",fontFamily:"Dm sans",marginTop:10,color:"#000"}}>Available : {lis.available}</h6>
                </>
              )
             })

            }
        </Col>
        <Col md={3} style={{border:"1px solid #00a8ff",textAlign:"center"}}>
        <h6 style={{fontWeight:"bold",fontFamily:"Dm sans",marginTop:10,color:"#00a8ff"}}>Blood Group B-</h6>
        {
             data && data.getBloodBankById.bloodBankBNeg.map(lis=>{
              return(
                <>
               
                <h6 style={{fontWeight:"bold",fontFamily:"Dm sans",marginTop:10,color:"#000"}}>Available : {lis.available}</h6>
                </>
              )
             })

            }
        </Col>
    </Row>
    
    <Row>
        <Col md={3} style={{border:"1px solid #00a8ff",textAlign:"center"}}>
            <h6 style={{fontWeight:"bold",fontFamily:"Dm sans",marginTop:10,color:"#00a8ff"}}>Blood Group AB+</h6>
            {
             data && data.getBloodBankById.bloodBankABPos.map(lis=>{
              return(
                <>
              
                <h6 style={{fontWeight:"bold",fontFamily:"Dm sans",marginTop:10,color:"#000"}}>Available : {lis.available}</h6>
                </>
              )
             })

            }
            </Col>
        <Col md={3} style={{border:"1px solid #00a8ff",textAlign:"center"}}>

        <h6 style={{fontWeight:"bold",fontFamily:"Dm sans",marginTop:10,color:"#00a8ff"}}>Blood Group AB-</h6>
        {
             data && data.getBloodBankById.bloodBankABNeg.map(lis=>{
              return(
                <>
               
                <h6 style={{fontWeight:"bold",fontFamily:"Dm sans",marginTop:10,color:"#000"}}>Available : {lis.available}</h6>
                </>
              )
             })

            }
        </Col>
        <Col md={3} style={{border:"1px solid #00a8ff",textAlign:"center"}}>
        <h6 style={{fontWeight:"bold",fontFamily:"Dm sans",marginTop:10,color:"#00a8ff"}}>Blood Group O+</h6>
       
        {
             data && data.getBloodBankById.bloodBankBPos.map(lis=>{
              return(
                <>
              
                <h6 style={{fontWeight:"bold",fontFamily:"Dm sans",marginTop:10,color:"#000"}}>Available : {lis.available}</h6>
                </>
              )
             })

            }
        </Col>
        <Col md={3} style={{border:"1px solid #00a8ff",textAlign:"center"}}>
        <h6 style={{fontWeight:"bold",fontFamily:"Dm sans",marginTop:10,color:"#00a8ff"}}>Blood Group O-</h6>
            {
             data && data.getBloodBankById.bloodBankONeg.map(lis=>{
              return(
                <>
              
                <h6 style={{fontWeight:"bold",fontFamily:"Dm sans",marginTop:10,color:"#000"}}>Available : {lis.available}</h6>
                </>
              )
             })

            }
       
        </Col>
    </Row>
    <Modal show={show} onHide={handleClose}   size="lg">
    <Container>
        <h6 style={{textAlign:"center",marginTop:20,color:"#000",marginBottom:10}}>Update Profile</h6>
   <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Center name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Center Name"
            value={centerName}
            style={{fontSize:12}}
            onChange={(e)=>setCenterName(e.target.value)}

          />
         
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label  style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Registarion No</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Registarion No"
            value={RegistNo}
            style={{fontSize:12}}
            onChange={(e)=>setRegistNo(e.target.value)}
          />
         
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Center Address</Form.Label>
          <Form.Control
            required
            type="text"
            value={centerAdd}
            placeholder="Center Address"
             style={{fontSize:12}}
             onChange={(e)=>setCenterAdd(e.target.value)}
          />
        
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom03">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Center Pincode</Form.Label>
          <Form.Control type="text" placeholder="Center Pincode" required   style={{fontSize:12}}
          value={centerPincode}
          onChange={(e)=>setCenterPincode(e.target.value)}
          />
       
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom04">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Center State</Form.Label>
          <Form.Control type="text" placeholder="Center State" required    style={{fontSize:12}}
          value={centerState}
          onChange={(e)=>setCenterState(e.target.value)}
          />
        
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom05">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Center Contact</Form.Label>
          <Form.Control type="text" placeholder="Center Contact" required   style={{fontSize:12}} 
           value={centerContact}
           onChange={(e)=>setCenterContact(e.target.value)}
          
          />
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} md="3" controlId="validationCustom03">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Center Email</Form.Label>
          <Form.Control type="text" placeholder="Center Pincode" required   style={{fontSize:12}}
            value={centerEmail}
            onChange={(e)=>setCenterEmail(e.target.value)}
          />
       
        </Form.Group>
        <Form.Group as={Col} md="3" controlId="validationCustom04">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Nodal Person First Name</Form.Label>
          <Form.Control type="text" placeholder="Owner Name" required   style={{fontSize:12}}
          value={CPFName}
          onChange={(e)=>setCPFName(e.target.value)}
          />
        
        </Form.Group>
        <Form.Group as={Col} md="3" controlId="validationCustom05">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Nodal Person Last Name</Form.Label>
          <Form.Control type="text" placeholder="Owner Contact" required  style={{fontSize:12}} 
          value={CPLName}
          onChange={(e)=>setCPLName(e.target.value)}
          />
        </Form.Group>

        <Form.Group as={Col} md="3" controlId="validationCustom05">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Nodal Person Contact</Form.Label>
          <Form.Control type="text" placeholder="Owner Contact" required  style={{fontSize:12}} 
          value={CPContact}
          onChange={(e)=>setCPContact(e.target.value)}
          />
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom03">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Nodal Person  Email</Form.Label>
          <Form.Control type="text" placeholder="Owner Email" required   style={{fontSize:12}}
           value={CPEmail}
           onChange={(e)=>setCPEmail(e.target.value)}
          />
       
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom04">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Select Blood Bank OwnerShip</Form.Label>
          <Form.Select aria-label="Select Blood Bank OwnerShip"   style={{fontSize:12}} onChange={(e)=>setBBOwnerShip(e.target.value)}  value={BBOwnerShip}>
           <option>Select Blood Bank OwnerShip</option>
           <option value="Rented">Rented</option>
           <option value="Local Government">Own</option>
            <option value="Other">Registered Company</option>
           
           </Form.Select>
        
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom05">
          <Form.Label style={{fontFamily:"Dm sans",fontSize:12,fontWeight:"bold"}}>Select Blood Bank Type</Form.Label>
          <Form.Select aria-label="Select Blood Bank Type"   style={{fontSize:12}}  onChange={(e)=>setBBType(e.target.value)} value={BBType}>
          <option>Select Blood Bank Type</option>
           <option value="Goverment">  Government</option>
           <option value="Private"> Private</option>  
            <option value="bloodBankTypeOther">Other</option>
          
           </Form.Select>
        </Form.Group>
      </Row>
      <Form.Group className="mb-3">
       
      </Form.Group>
      <Button type="submit" style={{fontFamily:"Dm sans",marginBottom:20}} className="mx-auto d-block">Update Profile</Button>
    </Form>
    </Container>
      </Modal>
                </div>
              
            </div>
    
     
    </>
  )
}
