import { gql } from '@apollo/client';

export const SIGNIN_SUPER_ADMIN = gql`
  mutation Mutation($signinSuperAdminInput: signinSuperAdminInput) {
    signinSuperAdmin(SigninSuperAdminInput: $signinSuperAdminInput) {
      adminId
      adminToken
    }
  }
`;

export const ADD_HOSPITAL = gql`
  mutation Mutation($hospitalInput: hospitalInput) {
    createHospital(HospitalInput: $hospitalInput) {
      id
    }
  }
`;

export const SIGNIN_HOSPITAL = gql`
  mutation Mutation($signinHospitalAdminInput: signinHospitalAdminInput) {
    signinHospitalAdmin(SigninHospitalAdminInput: $signinHospitalAdminInput) {
      hospitalId
      hospitalToken
    }
  }
`;

export const UPDATE_HOSPITAL = gql`
  mutation UpdateHospital($updateHospitalInput: updatehospitalInput) {
    updateHospital(UpdateHospitalInput: $updateHospitalInput) {
      id
    }
  }
`;

export const DELETE_HOSPITAL = gql`
  mutation DeleteHospital($hospitalId: ID) {
    deleteHospital(HospitalID: $hospitalId) {
      id
    }
  }
`;

export const UPDATE_HOSPITAL_STATUS = gql`
  mutation UpdateHospitalStatus($updateStatusInput: updateStatusInput) {
    updateHospitalStatus(UpdateStatusInput: $updateStatusInput) {
      id
      status
    }
  }
`;

export const ADD_DOCTOR = gql`
  mutation CreateDoctor($doctorInput: doctorInput) {
    createDoctor(DoctorInput: $doctorInput) {
      id
    }
  }
`;

export const UPDATE_DOCTOR = gql`
  mutation UpdateDoctor($updateDoctorInput: updateDoctorInput) {
    updateDoctor(UpdateDoctorInput: $updateDoctorInput) {
      id
    }
  }
`;

export const DELETE_DOCTOR = gql`
  mutation DeleteDoctor($doctorId: ID) {
    deleteDoctor(DoctorID: $doctorId) {
      id
    }
  }
`;

export const UPDATE_DOCTOR_STATUS = gql`
  mutation UpdateDoctorStatus(
    $updateDoctorStatusInput: updateDoctorStatusInput
  ) {
    updateDoctorStatus(UpdateDoctorStatusInput: $updateDoctorStatusInput) {
      id
    }
  }
`;

export const ADD_AGENT = gql`
  mutation AddAgent($agentInput: agentInput) {
    addAgent(AgentInput: $agentInput) {
      id
    }
  }
`;

export const UPDATE_AGENT = gql`
  mutation UpdateAgent($updateAgentInput: updateAgentInput) {
    updateAgent(UpdateAgentInput: $updateAgentInput) {
      id
    }
  }
`;

export const DELETE_AGENT = gql`
  mutation DeleteAgent($agentId: ID) {
    deleteAgent(AgentID: $agentId) {
      id
    }
  }
`;

export const CONFIRM_HOSPITAL = gql`
  mutation Mutation($hospitalConfirmInput: hospitalConfirmInput) {
    hospitalConfirm(HospitalConfirmInput: $hospitalConfirmInput) {
      id
    }
  }
`;
export const BLOCK_HOSPITAL = gql`
  mutation Mutation($hospitalBlockInput: hospitalBlockInput) {
    hospitalBlock(HospitalBlockInput: $hospitalBlockInput) {
      id
    }
  }
`;
export const BLOCK_PATHOLOGY = gql`
  mutation Mutation($pathologyBlockInput: pathologyBlockInput) {
    pathologyBlock(PathologyBlockInput: $pathologyBlockInput) {
      id
    }
  }
`;
export const CONFIRM_PATHOLOGY = gql`
  mutation Mutation($pathologyConfirmInput: pathologyConfirmInput) {
    pathologyConfirm(PathologyConfirmInput: $pathologyConfirmInput) {
      id
    }
  }
`;
export const CONFIRM_RADIOLOGY = gql`
  mutation Mutation($radiologyConfirmInput: radiologyConfirmInput) {
    radiologyConfirm(RadiologyConfirmInput: $radiologyConfirmInput) {
      id
    }
  }
`;
export const BLOCK_RADIOLOGY = gql`
  mutation Mutation($radiologyBlockInput: radiologyBlockInput) {
    radiologyBlock(RadiologyBlockInput: $radiologyBlockInput) {
      id
    }
  }
`;

export const AMBULANCE_CONFIRM = gql`
  mutation AmbulanceConfirm($ambulanceId: ID) {
    ambulanceConfirm(ambulanceId: $ambulanceId) {
      id
    }
  }
`;

export const AMBULANCE_REJECT = gql`
  mutation Mutation($ambulanceId: ID, $rejectComment: String) {
    ambulanceReject(ambulanceId: $ambulanceId, rejectComment: $rejectComment) {
      id
    }
  }
`;

export const BLOODBANK_CONFIRM = gql`
  mutation BloodBankConfirm($bloodBankId: ID) {
    bloodBankConfirm(bloodBankId: $bloodBankId) {
      id
    }
  }
`;

export const BLOODBANK_REJECT = gql`
  mutation BloodBankReject($bloodBankId: ID, $rejectComment: String) {
    bloodBankReject(bloodBankId: $bloodBankId, rejectComment: $rejectComment) {
      id
    }
  }
`;

export const CREATE_PATHOLOGY = gql`
  mutation Mutation($pathologyInput: pathologyInput) {
    createPathology(PathologyInput: $pathologyInput) {
      id
    }
  }
`;

export const CREATE_RADIOLOGY = gql`
  mutation Mutation($radiologyInput: radiologyInput) {
    createRadiology(RadiologyInput: $radiologyInput) {
      id
    }
  }
`;

export const CREATE_AMBULANCE = gql`
  mutation CreateAmbulance($ambulanceInput: ambulanceInput) {
    createAmbulance(AmbulanceInput: $ambulanceInput) {
      id
    }
  }
`;

export const CREATE_BLOODBANK = gql`
  mutation CreateAmbulance($bloodBankInput: bloodBankInput) {
    creatBloodBank(BloodBankInput: $bloodBankInput) {
      id
    }
  }
`;

export const MUTATION_RADIOLOGY_LOGIN = gql`
  mutation Radiologylogin($registrationNo: String, $password: String) {
    radiologylogin(registrationNo: $registrationNo, password: $password) {
      radiologyToken
      radiologyId
    }
  }
`;

export const MUTATION_BLOOD_BANK_LOGIN = gql`
  mutation Mutation($registrationNo: String, $password: String) {
    bloodbanklogin(registrationNo: $registrationNo, password: $password) {
      bloodBankToken
      bloodBankId
    }
  }
`;

export const MUTATION_PATHOLOGY_LOGIN = gql`
  mutation Pathologylogin($registrationNo: String, $password: String) {
    pathologylogin(registrationNo: $registrationNo, password: $password) {
      pathologyToken
      pathologyId
    }
  }
`;

export const MUTATION_UPDATE_RADIOLOGY = gql`
  mutation Mutation($updateRadiologyInput: updateRadiologyInput) {
    updateRadiology(UpdateRadiologyInput: $updateRadiologyInput) {
      id
    }
  }
`;

export const MUTATION_UPDATE_PATHOLOGY = gql`
  mutation UpdatePathology($updatePathologyInput: updatePathologyInput) {
    updatePathology(UpdatePathologyInput: $updatePathologyInput) {
      id
    }
  }
`;

export const MUTATION_UPDATE_BLOOD_BANK = gql`
  mutation Mutation($updatebloodbankInput: updatebloodbankInput) {
    updatebloodbank(UpdatebloodbankInput: $updatebloodbankInput) {
      id
    }
  }
`;

export const MUTATION_CONFIRM_RADIOLOGY_BOOKING = gql`
  mutation Mutation(
    $confirmRadiologyBookingInput: confirmRadiologyBookingInput
  ) {
    confirmRadiologyBooking(
      ConfirmRadiologyBookingInput: $confirmRadiologyBookingInput
    ) {
      id
    }
  }
`;

export const MUTATION_CONFIRM_PATHOLOGY_BOOKING = gql`
  mutation Mutation(
    $confirmPathologyBookingInput: confirmPathologyBookingInput
  ) {
    confirmPathologyBooking(
      ConfirmPathologyBookingInput: $confirmPathologyBookingInput
    ) {
      id
    }
  }
`;

export const MUTATION_CONFIRM_HOSPITAL_BOOKING = gql`
  mutation Mutation($confirmHospitalBookingInput: confirmHospitalBookingInput) {
    confirmHospitalBooking(
      ConfirmHospitalBookingInput: $confirmHospitalBookingInput
    ) {
      id
    }
  }
`;

export const MUTATION_CONFIRM_BLOOD_BANK_BOOKING = gql`
  mutation Mutation(
    $confirmBloodBankBookingInput: confirmbloodBankBookingInput
  ) {
    confirmBloodBankBooking(
      ConfirmBloodBankBookingInput: $confirmBloodBankBookingInput
    ) {
      id
    }
  }
`;

export const MUTATION_ADD_PATHOLOGY_SLOT = gql`
  mutation CreatePathologySlot($pathologySlotInput: pathologySlotInput) {
    createPathologySlot(PathologySlotInput: $pathologySlotInput) {
      id
    }
  }
`;

export const MUTATION_BLOCK_PATHOLOGY_SLOT = gql`
  mutation Mutation($blockPathologySlotInput: blockPathologySlotInput) {
    blockPathologySlot(BlockPathologySlotInput: $blockPathologySlotInput) {
      id
    }
  }
`;

export const MUTATION_ACTIVE_PATHOLOGY_SLOT = gql`
  mutation ActivePathologySlot(
    $activePathologySlotInput: activePathologySlotInput
  ) {
    activePathologySlot(ActivePathologySlotInput: $activePathologySlotInput) {
      id
    }
  }
`;

export const MUTATION_ADD_RADIOLOGY_SLOT = gql`
  mutation Mutation($radiologySlotInput: radiologySlotInput) {
    createRadiologySlot(RadiologySlotInput: $radiologySlotInput) {
      id
    }
  }
`;

export const MUTATION_BLOCK_RADIOLOGY_SLOT = gql`
  mutation Mutation($blockRadiologySlotInput: blockRadiologySlotInput) {
    blockRadiologySlot(BlockRadiologySlotInput: $blockRadiologySlotInput) {
      id
    }
  }
`;

export const MUTATION_ACTIVE_RADIOLOGY_SLOT = gql`
  mutation Mutation($activeRadiologySlotInput: activeRadiologySlotInput) {
    activeRadiologySlot(ActiveRadiologySlotInput: $activeRadiologySlotInput) {
      id
    }
  }
`;

export const MUTATION_AMBULANCE_LOGIN = gql`
  mutation Mutation($userName: String, $password: String) {
    ambulancelogin(userName: $userName, password: $password) {
      ambulanceToken
      ambulanceId
    }
  }
`;

export const MUTATION_INCREASE_SLOT_PATHOLOGY = gql`
  mutation IncreasePathologySlotCapacity(
    $slotId: ID
    $pathologyId: ID
    $capacity: Int
  ) {
    increasePathologySlotCapacity(
      slotId: $slotId
      pathologyId: $pathologyId
      capacity: $capacity
    ) {
      id
    }
  }
`;

export const MUTATION_DCREASE_SLOT_PATHOLOGY = gql`
  mutation Mutation($capacity: Int, $slotId: ID, $pathologyId: ID) {
    decreasePathologySlotCapacity(
      capacity: $capacity
      slotId: $slotId
      pathologyId: $pathologyId
    ) {
      id
    }
  }
`;

export const MUTATION_INCREASE_SLOT_RADIOLOGY = gql`
  mutation Mutation($radiologyId: ID, $slotId: ID, $capacity: Int) {
    increaseRadiologySlotCapacity(
      radiologyId: $radiologyId
      slotId: $slotId
      capacity: $capacity
    ) {
      id
    }
  }
`;

export const MUTATION_DCREASE_SLOT_RADILOGY = gql`
  mutation DecreaseRadiologySlotCapacity(
    $radiologyId: ID
    $slotId: ID
    $capacity: Int
  ) {
    decreaseRadiologySlotCapacity(
      radiologyId: $radiologyId
      slotId: $slotId
      capacity: $capacity
    ) {
      id
    }
  }
`;

export const MUTATION_UPDATE_PATHOLOGY_SERVICE = gql`
  mutation UpdatePathologyService(
    $pathology: ID
    $serviceId: ID
    $serviceName: String
    $servicePrice: String
  ) {
    updatePathologyService(
      pathology: $pathology
      serviceId: $serviceId
      serviceName: $serviceName
      servicePrice: $servicePrice
    ) {
      id
    }
  }
`;

export const MUTATION_UPDATE_RADIOLOGY_SERVICE = gql`
  mutation UpdateRadiologyService(
    $radiologyId: ID
    $serviceId: ID
    $serviceName: String
    $servicePrice: String
  ) {
    updateRadiologyService(
      radiologyId: $radiologyId
      serviceId: $serviceId
      serviceName: $serviceName
      servicePrice: $servicePrice
    ) {
      id
    }
  }
`;
export const MUTATION_COMPLETE_SLOT_BOOKING = gql`
  mutation Mutation($slotId: ID) {
    completeBooking(slotId: $slotId) {
      id
    }
  }
`;

export const MUTATION_BLOCK_PATHOLOGY_SLOT_TIME = gql`
  mutation Mutation($slotId: ID, $pathologyId: ID) {
    blockPathologySlotTime(slotId: $slotId, pathologyId: $pathologyId) {
      id
    }
  }
`;

export const MUTATION_ACTIVE_PATHOLOGY_SLOT_TIME = gql`
  mutation ActivePathologySlotTime($pathologyId: ID, $slotId: ID) {
    activePathologySlotTime(pathologyId: $pathologyId, slotId: $slotId) {
      id
    }
  }
`;

export const MUTATION_BLOCK_RADIOLOGY_SLOT_TIME = gql`
  mutation BlockRadiologySlotTime($radiologyId: ID, $slotId: ID) {
    blockRadiologySlotTime(radiologyId: $radiologyId, slotId: $slotId) {
      id
    }
  }
`;

export const MUTATION_ACTIVE_RADIOLOGY_SLOT_TIME = gql`
  mutation ActiveRadiologySlotTime($radiologyId: ID, $slotId: ID) {
    activeRadiologySlotTime(radiologyId: $radiologyId, slotId: $slotId) {
      id
    }
  }
`;

export const MUTATION_UPDATE_AMBULANCE = gql`
  mutation Mutation($updateAmbulanceInput: updateAmbulanceInput) {
    updateAmbulance(UpdateAmbulanceInput: $updateAmbulanceInput) {
      id
    }
  }
`;

export const MUTATION_UPDATE_A_POS = gql`
  mutation Mutation(
    $bloodBankId: ID
    $bloodId: ID
    $quantity: String
    $available: String
  ) {
    updateBloodBankAPos(
      bloodBankId: $bloodBankId
      bloodId: $bloodId
      quantity: $quantity
      available: $available
    ) {
      id
    }
  }
`;

export const MUTATION_UPDATE_A_NEG = gql`
  mutation Mutation(
    $bloodBankId: ID
    $bloodId: ID
    $quantity: String
    $available: String
  ) {
    updateBloodBankANeg(
      bloodBankId: $bloodBankId
      bloodId: $bloodId
      quantity: $quantity
      available: $available
    ) {
      id
    }
  }
`;

export const MUTATION_UPDATE_B_POS = gql`
  mutation UpdateBloodBankBPos(
    $bloodBankId: ID
    $bloodId: ID
    $quantity: String
    $available: String
  ) {
    updateBloodBankBPos(
      bloodBankId: $bloodBankId
      bloodId: $bloodId
      quantity: $quantity
      available: $available
    ) {
      id
    }
  }
`;

export const MUTATION_UPDATE_B_NEG = gql`
  mutation UpdateBloodBankBNeg(
    $bloodBankId: ID
    $bloodId: ID
    $quantity: String
    $available: String
  ) {
    updateBloodBankBNeg(
      bloodBankId: $bloodBankId
      bloodId: $bloodId
      quantity: $quantity
      available: $available
    ) {
      id
    }
  }
`;

export const MUTATION_UPDATE_O_POS = gql`
  mutation Mutation(
    $bloodBankId: ID
    $bloodId: ID
    $quantity: String
    $available: String
  ) {
    updateBloodBankOPos(
      bloodBankId: $bloodBankId
      bloodId: $bloodId
      quantity: $quantity
      available: $available
    ) {
      id
    }
  }
`;

export const MUTATION_UPDATE_O_NEG = gql`
  mutation Mutation(
    $bloodBankId: ID
    $available: String
    $quantity: String
    $bloodId: ID
  ) {
    updateBloodBankONeg(
      bloodBankId: $bloodBankId
      available: $available
      quantity: $quantity
      bloodId: $bloodId
    ) {
      id
    }
  }
`;

export const MUTATION_UPDATE_AB_POS = gql`
  mutation UpdateBloodBankABPos(
    $bloodBankId: ID
    $bloodId: ID
    $available: String
    $quantity: String
  ) {
    updateBloodBankABPos(
      bloodBankId: $bloodBankId
      bloodId: $bloodId
      available: $available
      quantity: $quantity
    ) {
      id
    }
  }
`;

export const MUTATION_UPDATE_AB_NEG = gql`
  mutation UpdateBloodBankABNeg(
    $bloodBankId: ID
    $bloodId: ID
    $quantity: String
    $available: String
  ) {
    updateBloodBankABNeg(
      bloodBankId: $bloodBankId
      bloodId: $bloodId
      quantity: $quantity
      available: $available
    ) {
      id
    }
  }
`;

export const MUTATION_DELETE_FAVOURATE_BY_FAVOURATE_ID = gql`
  mutation DeleteFavourateId($favourateId: ID) {
    deleteFavourateId(favourateId: $favourateId) {
      id
    }
  }
`;

export const MUTATION_DELETE_FAVOURATE_BY_PRODUCT_ID = gql`
  mutation DeleteFavourate($productId: ID) {
    deleteFavourate(productId: $productId) {
      id
    }
  }
`;

export const MUTATION_CREATE_FAVOURATE = gql`
  mutation CreateFavourate($favourateInput: favourateInput) {
    createFavourate(FavourateInput: $favourateInput) {
      id
    }
  }
`;

export const MUTATION_DELETE_CART_BY_PRODUCT_ID = gql`
  mutation CreateFavourate($favourateInput: favourateInput) {
    createFavourate(FavourateInput: $favourateInput) {
      id
    }
  }
`;

export const MUTATION_UPDATE_AMBULANCE_FACILITY = gql`
  mutation UpdateAmbulancefacility(
    $ambulanceId: ID
    $facility: ID
    $facilityName: String
    $facilityPrice: String
  ) {
    updateAmbulancefacility(
      ambulanceId: $ambulanceId
      facility: $facility
      facilityName: $facilityName
      facilityPrice: $facilityPrice
    ) {
      id
    }
  }
`;

export const MUTATION_USER = gql`
  mutation Mutation($userUpdateInput: userUpdateInput) {
    updateUser(UserUpdateInput: $userUpdateInput) {
      id
    }
  }
`;
export const MUTATION_CREATE_TEST_USER = gql`
  mutation Mutation($testUserInput: testUserInput) {
    createTestUser(TestUserInput: $testUserInput) {
      id
    }
  }
`;
export const MUTATION_AMBULANCE_REFUND_TO_USER = gql`
  mutation AddRefundWalletToUser($userId: ID, $walletPrice: String) {
    addRefundWalletToUser(userId: $userId, walletPrice: $walletPrice) {
      id
    }
  }
`;
export const MUTATION_AMBULANCE_BOOKING_REFUND = gql`
  mutation AmbulanceBookingRefund($ambulanceRefundInput: ambulanceRefundInput) {
    ambulanceBookingRefund(AmbulanceRefundInput: $ambulanceRefundInput) {
      id
    }
  }
`;
export const MUTATION_USER_SOS_ACCEPT = gql`
  mutation Mutation($sosId: ID) {
    acceptSOS(sosId: $sosId) {
      id
    }
  }
`;

export const MUTATION_USER_SOS_REJECT = gql`
  mutation RejectSOS($sosId: ID) {
    rejectSOS(sosId: $sosId) {
      id
    }
  }
`;
export const MUTATION_EDIT_WALLET_AMOUNT = gql`
  mutation EditWalletAmount($userId: ID, $amount: String) {
    editWalletAmount(userId: $userId, amount: $amount) {
      id
    }
  }
`;

export const MUTATION_EDIT_AMBULANCE_DETAILS = gql`
  mutation EditVehicleDetail($editVehicleDetailInput: editVehicleDetailInput) {
    editVehicleDetail(EditVehicleDetailInput: $editVehicleDetailInput) {
      id
    }
  }
`;
export const MUTATION_EDIT_DRIVER_DETAILS = gql`
  mutation Mutation($editDriverDetailInput: editDriverDetailInput) {
    editDriverDetail(EditDriverDetailInput: $editDriverDetailInput) {
      id
    }
  }
`;
export const MUTATION_ADD_FACILITY = gql`
  mutation Mutation($addFacilityInput: addFacilityInput) {
    createFacilityToAmbulance(AddFacilityInput: $addFacilityInput) {
      id
    }
  }
`;
export const MUTATION_DELETE_FACILITY = gql`
  mutation DeleteFacilityToAmbulance($abmulanceId: ID, $facilityId: ID) {
    deleteFacilityToAmbulance(
      abmulanceId: $abmulanceId
      facilityId: $facilityId
    ) {
      id
    }
  }
`;
export const MUTATION_EDIT_DRIVER_IMAGE = gql`
  mutation Mutation($ambulanceId: ID, $imageKey: String) {
    updateAmbulanceDriverImage(ambulanceId: $ambulanceId, imageKey: $imageKey) {
      id
    }
  }
`;
export const MUTATION_EDIT_DRIVER_LICENSE_IMAGE = gql`
  mutation UpdateAmbulanceDriverLicenseImage(
    $ambulanceId: ID
    $imageKey: String
  ) {
    updateAmbulanceDriverLicenseImage(
      ambulanceId: $ambulanceId
      imageKey: $imageKey
    ) {
      id
    }
  }
`;
export const MUTATION_EDIT_AGENT_PASSWORD = gql`
  mutation ResetAgentPassword($agentId: ID, $agentPassword: String) {
    resetAgentPassword(agentId: $agentId, agentPassword: $agentPassword) {
      id
    }
  }
`;

export const UPDATE_ADMIN_PASSWORD = gql`
  mutation UpdateAdminPassword($adminId: ID!, $adminPassword: String!) {
    updateAdminPassword(adminId: $adminId, adminPassword: $adminPassword) {
      id
      password
    }
  }
`;

export const ADD_SUB_ADMIN = gql`
  mutation CreateSubAdmin($subadminInput: subadminInput) {
    createSubAdmin(SubadminInput: $subadminInput) {
      id
    }
  }
`;

export const EDIT_SUB_ADMIN = gql`
  mutation UpdateSubAdmin($subadminInput: subadminInput) {
    updateSubAdmin(SubadminInput: $subadminInput) {
      id
      fName
      lName
      contact
      email
      password
      deviceToken
      createdDateTime
      status
    }
  }
`;

export const RESAT_ADMIN_PASSWORD = gql`
  mutation ResatAdminPassword($subadminId: ID, $subadminPassword: String) {
    resatAdminPassword(
      subadminId: $subadminId
      subadminPassword: $subadminPassword
    ) {
      id
      fName
      lName
      contact
      email
      password
      deviceToken
      createdDateTime
      status
    }
  }
`;

export const CHANGE_STATUS = gql`
mutation ChangeStatus($subadminId: ID, $subadminStatus: String) {
  changeStatus(subadminId: $subadminId, subadminStatus: $subadminStatus) {
    id
    fName
    lName
    contact
    email
    password
    deviceToken
    createdDateTime
    status
  }
}
`;

export const SUB_ADMIN_LOGIN = gql`
mutation Mutation($signinSubAdminInput: signinSubAdminInput) {
  signinSubAdmin(SigninSubAdminInput: $signinSubAdminInput) {
    subAdminId
    subAdminToken
  }
}
`;
export const CREATE_SUBAMBULANCE = gql`
  mutation CreateSubAmbulance($ambulanceInput: ambulanceInput) {
    createSubAmbulance(AmbulanceInput: $ambulanceInput) {
      id
    }
  }
`;
