import React, { useState } from 'react'
import { Form, Col, Row, Button, Container } from 'react-bootstrap';
import { ADD_DOCTOR } from '../../graphql/mutations';
import { useMutation } from '@apollo/client';
import swal from 'sweetalert'

const AddDoctorForm = () => {
  const hospitalId = localStorage.getItem('hospitalId')
  const [validated, setValidated] = useState(false);
  const [createDoctor] = useMutation(ADD_DOCTOR)

  const [fName, setFname] = useState();
  const [lName, setLname] = useState();
  const [ward, setWard] = useState();
  const [contact, setContact] = useState();
  const [email, setEmail] = useState();
  const [registrationNo, setRegistrationNo] = useState();
  const [licenseNo, setLicenseNo] = useState();
  const [specialization, setSpecialization] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    }
    else {
      setValidated(false);
      event.preventDefault();

      const arr = specialization.split(',');

      createDoctor({
        variables: {
          "doctorInput": {
            "hospitalId": `${hospitalId}`,
            "hospitalWard": `${ward}`,
            "fName": `${fName}`,
            "lName": `${lName}`,
            "contact": `${contact}`,
            "email": `${email}`,
            "registrationNumber": `${registrationNo}`,
            "licenseNumber": `${licenseNo}`,
            "specialization": arr,
            "password": `${password}`,
          }
        },
        onCompleted: () => {
          setFname('');
          setLname('');
          setWard('');
          setContact('');
          setEmail('');
          setRegistrationNo('');
          setLicenseNo('');
          setSpecialization('');
          setPassword('');
          swal({ title: "Successfully", text: "Doctor Add Successfully", icon: "success" });
        }
      })
    }
  };

  return (
    <>
      <Container style={{ marginTop: '20px' }}>
        <div>
          <h1 style={{ fontFamily: 'DM Sans' }}>Add a Doctor
            <div style={{ backgroundColor: '#ffb606 ', height: 2, width: '15%', marginTop: 10 }}>
              {' '}
            </div>
          </h1>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col md={4} >
                <Form.Group controlId="validationCustom01">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>First Name</Form.Label>
                  <Form.Control required type="text" placeholder="First Name" name="fName" onChange={e => setFname(e.target.value)} value={fName} style={{ fontFamily: 'DM Sans', fontSize: '12px' }} />
                </Form.Group>
              </Col>
              <Col md={4} >
                <Form.Group controlId="validationCustom01">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>Last Name</Form.Label>
                  <Form.Control required type="text" placeholder="Last Name" name="lName" onChange={e => setLname(e.target.value)} value={lName} style={{ fontFamily: 'DM Sans', fontSize: '12px' }} />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4} >
                <Form.Group controlId="validationCustom01">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>Hospital ward</Form.Label>
                  <Form.Control required type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} placeholder="Hospital Ward" name="hospitalWard" onChange={e => setWard(e.target.value)} value={ward} />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={4} >
                <Form.Group style={{ marginTop: '1px', fontSize: '12px' }} controlId="validationCustom01">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>Contact</Form.Label>
                  <Form.Control required type="number" placeholder="Contact" name="contact" onChange={e => setContact(e.target.value)} value={contact} style={{ fontFamily: 'DM Sans', fontSize: '12px' }} />
                </Form.Group>
              </Col>
              <Col md={4} >
                <Form.Group style={{ marginTop: '1px', fontSize: '12px' }} controlId="validationCustom01">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>Email</Form.Label>
                  <Form.Control required type="email" placeholder="Email" name="email" onChange={e => setEmail(e.target.value)} value={email} style={{ fontFamily: 'DM Sans', fontSize: '12px' }} />
                </Form.Group>
              </Col>
              <Col md={4} >
                <Form.Group style={{ marginTop: '1px', fontSize: '12px' }} controlId="validationCustom01">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>Registration Number</Form.Label>
                  <Form.Control required type="text" placeholder="Registration Number" name="registrationNumber" onChange={e => setRegistrationNo(e.target.value)} value={registrationNo} style={{ fontFamily: 'DM Sans', fontSize: '12px' }} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4} >
                <Form.Group style={{ marginTop: '1px', fontSize: '12px' }} controlId="validationCustom01">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>License Number</Form.Label>
                  <Form.Control required type="text" placeholder="License Number" name="licenseNumber" onChange={e => setLicenseNo(e.target.value)} value={licenseNo} style={{ fontFamily: 'DM Sans', fontSize: '12px' }} />
                </Form.Group>
              </Col>
              <Col md={4} >
                <Form.Group style={{ marginTop: '1px', fontSize: '12px' }} controlId="validationCustom01">
                  <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>Specialization</Form.Label>
                  <Form.Control required type="text" placeholder="Specialization" name="specialization" onChange={e => setSpecialization(e.target.value)} value={specialization} style={{ fontFamily: 'DM Sans', fontSize: '12px' }} />
                </Form.Group>
              </Col>
            </Row>
            <Button type="submit" style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>Add doctor</Button>
          </Form>
        </div>
      </Container>
    </>
  );
}

export default AddDoctorForm