import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Table, Button, Modal, Form, Row, Col, Container, Image, Spinner } from "react-bootstrap"
import { FaEye, FaRegCheckCircle, FaCheck, FaCheckCircle } from 'react-icons/fa';
import { MUTATION_CONFIRM_HOSPITAL_BOOKING } from '../../graphql/mutations';
import Moment from 'react-moment';
import { QUERY_GET_BOOKING_BY_HOSPTIAL_ID } from '../../graphql/queries'
import { useNavigate } from 'react-router-dom';
import { FaUser, FaPhoneAlt, FaEnvelope } from "react-icons/fa";


export default function AllHospitalBooking() {

  let count = 1;

  const HospitalId = localStorage.getItem("hospitalId")


  useEffect(() => {
    setInterval(() => {
      refetch()
    }, 1000);
  }, [])

  const { data, loading, refetch } = useQuery(QUERY_GET_BOOKING_BY_HOSPTIAL_ID, {
    variables: {
      "hospitalId": `${HospitalId}`
    }
  })


  const [show, setShow] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  const [showVerify, setShowVerify] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const hanadleDetailClose = () => setShowDetail(false)
  const hanadleDetailShow = () => setShowDetail(true)

  const handleCloseVerify = () => setShowVerify(false);
  const handleShowVerify = () => setShowVerify(true)

  const [getdata, setGetData] = useState()

  const [aDate, setADate] = useState()
  const [sTime, setSTime] = useState()
  const [eTime, setETime] = useState()


  function handleConfirm(data) {
    handleShow()
    setGetData(data)

  }

  const [confirmHospitalBooking] = useMutation(MUTATION_CONFIRM_HOSPITAL_BOOKING, {
    refetchQueries: [
      QUERY_GET_BOOKING_BY_HOSPTIAL_ID,
    ]
  })

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      confirmHospitalBooking({
        variables: {
          "confirmHospitalBookingInput": {
            "hopsitalId": `${getdata && getdata.id}`,
            "startTime": `${sTime}`,
            "endTime": `${eTime}`,
           

          }
        }
      })
      handleClose();
    }

    setValidated(true);
  };

  function handleDetail(data) {
    hanadleDetailShow()
    setGetData(data)
  }


  return (
    <>
      <br></br>
      {
        loading ?
          <Spinner animation="border" />
          :
          <>
            <Table striped bordered hover style={{ fontFamily: "Dm sans", fontSize: 12 }}>
              <thead>
                <tr>
                  <th>SR .</th>
                  <th>Username</th>
                  <th>Contact</th>
                  <th>Service</th>
                  <th>Fees</th>
                  <th>Payment Type</th>
                  <th>Detail</th>
                  <th>Confirm</th>
                  <th>Verify</th>
                  <th>Complete</th> 
                  
                </tr>
              </thead>
              <tbody>

                {
                  data && data.getHospitalBookingByHospitalId.map(radiology => {

                    return (

                      <tr>
                        <td>{count++}</td>
                        <td>{radiology.userName}</td>
                        <td>{radiology.userContact}</td>
                        <td>{radiology.serviceName}</td>
                        <td>{radiology.servicePrice}</td>
                        <td>{radiology.paymentType}</td>
                        <td><center><Button style={{ border: "none" }} onClick={() => handleDetail(radiology)}><FaEye /></Button></center></td>
                        <td>
                          {
                            radiology.appointmentDate === null ?
                              <center><Button style={{ border: "none", background: "#2ecc71" }} onClick={() => handleConfirm(radiology)}><FaRegCheckCircle /></Button></center>
                              :
                              <>
                                <Moment format="DD/MM/YYYY"><p>{radiology.appointmentDate}</p></Moment>
                                <div style={{ display: "flex" }}>
                                  <p>{radiology.startTime}</p>
                                  <p style={{ marginRight: 5, marginLeft: 5 }}>From</p>
                                  <p>{radiology.endTime}</p>
                                </div>
                              </>



                          }


                        </td>
                        <td><center><Button style={{ border: "none" }} onClick={() => handleShowVerify()}><FaCheck /></Button></center></td>
                        <td><center><Button style={{ border: "none" }}><FaCheckCircle /></Button></center></td>
                      </tr>

                    )


                  })




                }
                <tr>

                </tr>

              </tbody>
            </Table>



          </>



      }

      <Modal show={show} onHide={handleClose}>
        <Container>
          <h6 style={{ textAlign: "center", marginTop: 10, marginBottom: 10, fontFamily: "Dm sans", color: "#0abde3" }}> Select Appointment Date & Time</h6>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>

            
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">

                <Form.Control
                  required
                  type="time"
                  placeholder="Start Time"
                  onChange={(e) => setSTime(e.target.value)}
                  style={{ fontFamily: "Dm sans", fontSize: 12 }}

                />

              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">

                <Form.Control
                  required
                  type="time"
                  placeholder="End Time"
                  onChange={(e) => setETime(e.target.value)}
                  style={{ fontFamily: "Dm sans", fontSize: 12 }}

                />

              </Form.Group>

            </Row>

            <Form.Group className="mb-3">

            </Form.Group>
            <Button type="submit" style={{ marginBottom: 20, border: "none", fontFamily: "Dm sans", background: "#0abde3" }} className="mx-auto d-block">Confirm Now</Button>
          </Form>
        </Container>
      </Modal>

      <Modal show={showVerify} onHide={handleCloseVerify}>


        <h5 style={{ textAlign: "center", marginTop: 20, fontFamily: "Dm sans", fontWeight: "bold" }}>User OTP Verification</h5>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="validationCustom01">
              <br></br>
              <Form.Control
                required
                type="number"
                placeholder="Enter OTP" />
            </Form.Group>

          </Row>

          <Form.Group className="mb-3">

          </Form.Group>
          <Button type="submit" style={{ marginBottom: 20, border: "none", fontFamily: "Dm sans" }} className="mx-auto d-block">Submit</Button>
        </Form>

      </Modal>

      <Modal show={showDetail} onHide={hanadleDetailClose}>
        <Container>
          <h5 style={{ textAlign: "center", marginTop: 10, fontFamily: "Dm sans", fontWeight: "bold" }}>Booking Detail</h5>
          <hr></hr>

          <h6 style={{ fontFamily: "Dm sans", fontSize: 13, color: "#000" }}><FaUser style={{ marginTop: -5, color: "#2980b9" }} /> {getdata && getdata.userName}</h6>
          <h6 style={{ fontFamily: "Dm sans", fontSize: 13, color: "#000" }}><FaPhoneAlt style={{ marginTop: -5, color: "#2980b9" }} /> {getdata && getdata.userContact}</h6>
          <h6 style={{ fontFamily: "Dm sans", fontSize: 13, color: "#000" }}><FaEnvelope style={{ marginTop: -5, color: "#2980b9" }} /> {getdata && getdata.userEmail}</h6>

          <hr></hr>
          <h6 style={{ fontFamily: "Dm sans", fontSize: 13, color: "#000" }}>Radiology Name : {getdata && getdata.hopsitalName}</h6>
          <h6 style={{ fontFamily: "Dm sans", fontSize: 13, color: "#000" }}>Service Name : {getdata && getdata.serviceName}</h6>
          <h6 style={{ fontFamily: "Dm sans", fontSize: 13, color: "#000" }}>Appointment Date : <Moment format="DD/MM/YYYY"><p>{getdata && getdata.appointmentDate}</p></Moment> </h6>
          <div style={{ display: "flex" }}>
            <h6 style={{ fontFamily: "Dm sans", fontSize: 13, color: "#000" }}>Start Time : {getdata && getdata.startTime}</h6>
            <h6 style={{ fontFamily: "Dm sans", fontSize: 13, marginLeft: 10, color: "#000" }}>End Time : {getdata && getdata.endTime}</h6>
          </div>
          <h6 style={{ fontFamily: "Dm sans", fontSize: 13, color: "#000" }}>Booking Date :  <Moment format="DD/MM/YYYY ,HH:MM"><p>{getdata && getdata.createDateTime}</p></Moment></h6>

          <div style={{ border: "2px solid #000 ", borderStyle: "dashed", marginBottom: 20, marginTop: 20 }}>
            <h6 style={{ textAlign: "center", marginTop: 10 }}>Payment Status</h6>
            {
              getdata && getdata.paymentType === "online Pay" ?
                <Image src="https://funtura.in/lko/wp-content/themes/funtura/assets/images/success.svg" className='mx-auto d-block' style={{ width: 100 }} />
                :
                <>
                  <Image src="https://img.icons8.com/bubbles/2x/cash-in-hand.png" className='mx-auto d-block' style={{ width: 100 }} />
                </>

            }

            <br></br>
            <Row>
              <Col md={6}>
                <h6 style={{ fontFamily: "Dm sans", fontSize: 13, marginLeft: 10, color: "#000" }}>Payment Type : {getdata && getdata.paymentType}</h6>
              </Col>
              <Col md={6}>
                <h6 style={{ fontFamily: "Dm sans", fontSize: 13, marginLeft: 10, color: "#000" }}>Price : {getdata && getdata.servicePrice} /-</h6>
              </Col>
            </Row>





          </div>
        </Container>
      </Modal>

    </>
  )
}
