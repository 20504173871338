import React, { useState } from 'react'
import { Button, Offcanvas, Row, Col } from "react-bootstrap"
import "../../ComponentCss/NewDashboard.css"
import { Bar, Pie } from "react-chartjs-2";
import { FaAlignJustify, FaHome, FaUsers, FaUsersCog, FaHeadset, FaKey, FaSignOutAlt } from "react-icons/fa";
import { UserData } from './Data';


export default function NewDashboard() {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    function handleMenu() {

        let toggle = document.querySelector(".toggle");
        let navigation = document.querySelector(".navigation");
        let main = document.querySelector(".main");
        navigation.classList.toggle("active");
        main.classList.toggle("active");
    };




    const [userData, setUserData] = useState({
        labels: UserData.map((data) => data.year),
        datasets: [
            {
                label: "Users Gained",
                data: UserData.map((data) => data.userGain),
                backgroundColor: [
                    "rgba(75,192,192,1)",
                    "#ecf0f1",
                    "#50AF95",
                    "#f3ba2f",
                    "#2a71d0",
                ],
                borderColor: "black",
                borderWidth: 1,
            },
        ],
    });

    const style = { width: 500, height: 400 };
    const data = [11, 12, 5, 3, 6, 9, 8, 7, 5, 2, 1, undefined, 4, undefined, 6, 5, 3, 2, 5]


    return (
        <>
            <div>
                <div className="navigation">
                    <br></br>
                    <span className="title" style={{ fontSize: 20, fontFamily: 'DM Sans', fontWeight: 'bold', color: "#fff", textAlign: "center", marginLeft: 20, marginTop: 20 }}>Dashboard</span>
                    <ul>
                        <br></br><br></br><br></br>

                        <li>
                            <a href="#">
                                <span className="icon">

                                </span>
                                <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }}><FaHome /> Home</span>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <span className="icon">
                                </span>
                                <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }}><FaUsers /> Customers</span>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <span className="icon"> </span>
                                <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }}><FaHeadset /> Help</span>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <span className="icon">
                                </span>
                                <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }}><FaUsersCog /> Settings</span>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <span className="icon">
                                </span>
                                <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }}><FaKey /> Password</span>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <span className="icon">
                                </span>
                                <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }}><FaSignOutAlt /> Sign Out</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="main">
                <div className="topbar">
                    <div>
                        <FaAlignJustify onClick={() => handleMenu()} className="toggle" />
                    </div>
                    <div className="search">
                        <label>
                            <input type="text" placeholder="Search here" />
                        </label>
                    </div>
                    <div className="user">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/2048px-Circle-icons-profile.svg.png" alt="" />
                    </div>
                </div>
                <div className="cardBox">
                    <div className="card">
                        <div>
                            <div className="numbers">1,504</div>
                            <div className="cardName">Daily Views</div>
                        </div>
                        <div className="iconBx">
                        </div>
                    </div>
                    <div className="card">
                        <div>
                            <div className="numbers">80</div>
                            <div className="cardName">Total Order</div>
                        </div>
                        <div className="iconBx">
                        </div>
                    </div>
                    <div className="card">
                        <div>
                            <div className="numbers">284</div>
                            <div className="cardName">Total Slot</div>
                        </div>
                        <div className="iconBx">
                        </div>
                    </div>
                    <div className="card">
                        <div>
                            <div className="numbers">7,842</div>
                            <div className="cardName">Customers Detail</div>
                        </div>
                        <div className="iconBx">
                        </div>
                    </div>
                </div>
                <Row>
                    <Col md={6}>
                        <Bar data={userData} />
                    </Col>
                    <Col md={6}>
                        <Pie data={userData} style={{ width: "280px", height: "280px" }} />
                    </Col>
                </Row>
            </div>
        </>
    )
}
