import React, { useState, useEffect } from 'react'
import { Button, Table, Modal, Image, Spinner, Form, Col, Row } from "react-bootstrap"
import { useQuery, useMutation } from '@apollo/client'
import { FaCheck, FaBan, FaRegEye } from 'react-icons/fa'
import swal from 'sweetalert'
import { BLOCK_HOSPITAL, CONFIRM_HOSPITAL, } from '../../../graphql/mutations'
import { useNavigate } from 'react-router-dom';
import { QUERY_REJECT_HOSPITAL, QUERY_CONFIRM_HOSPITAL, QUERY_PENDING_HOSPITAL, GET_AGENT_BY_ID } from '../../../graphql/queries'

export default function HospitalRequest() {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [clickId, setClickId] = useState('')
  const [validated, setValidated] = useState(false);
  const [rejectCommentState, setRejectCommentState] = useState('')

  const [showCommentBox, setShowCommentBox] = useState(false);
  const handleCommentBoxClose = () => setShowCommentBox(false);
  const handleCommentBoxShow = () => setShowCommentBox(true);

  const [agentId, setAgentId] = useState()

  const navigate = useNavigate()

  let count = 1

  useEffect(() => {
    setInterval(() => {
      refetch()
    }, 1000);
  }, [])


  const { data, loading, refetch } = useQuery(QUERY_PENDING_HOSPITAL)

  const [hospitalConfirm] = useMutation(CONFIRM_HOSPITAL, {
    refetchQueries: [
      QUERY_CONFIRM_HOSPITAL,
      "getActiveHospital",
      QUERY_REJECT_HOSPITAL,
      "getRejecteHospital",
      QUERY_PENDING_HOSPITAL

    ]
  })

  const [hospitalBlock] = useMutation(BLOCK_HOSPITAL, {
    refetchQueries: [
      QUERY_CONFIRM_HOSPITAL,
      "getActiveHospital",
      QUERY_REJECT_HOSPITAL,
      "getRejecteHospital",
      QUERY_PENDING_HOSPITAL
    ]
  })


  function handleConfirm(id) {
    hospitalConfirm({
      variables: {
        "hospitalConfirmInput": {
          "id": `${id}`
        }

      }
    })
    swal({ title: "Successfull!!!", text: "Confirm Successfully", icon: "success" });
  }

  const { data: AgentData } = useQuery(GET_AGENT_BY_ID, {
    variables: {
      "agentId": `${agentId}`
    }
  })

  function handleDetail(id) {
    navigate('/hospitalDetail/' + id)
  }

  function handleDetailAgent(Id) {
    setAgentId(Id)
    handleShow()
  }

  function rejectCommentBoxShowHandle(id) {
    setValidated(false);
    setClickId(id)
    handleCommentBoxShow()
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    }
    else {
      setValidated(false);
      event.preventDefault();

      hospitalBlock({
        variables: {
          "hospitalBlockInput": {
            "id": `${clickId}`,
            "rejectComment": `${rejectCommentState}`
          }
        }
      }).then(d => {
        swal({ title: "Successfull!!!", text: "Block Successfully", icon: "success" })
        handleCommentBoxClose()
      }
      )
    }
  };


  return (
    <>
      <h3 style={{ padding: 10, borderBottom: "2px solid #fbc531", fontFamily: "Dm sans", fontWeight: "bold", marginTop: '20px' }}>Hospital Request</h3>
      {
        loading ?
          <Spinner animation="border" role="status" className="mx-auto d-block">
            <span className="visually-hidden">Loading...</span>
          </Spinner> :
          <Table style={{ fontSize: "12px", fontFamily: "Dm sans" }} responsive bordered>
            <thead>
              <tr>
                <th>SR No</th>
                <th>Registration No</th>
                <th>Hospital Name</th>
                <th>View Hospital Detail</th>
                <th>View Agent Detail</th>
                <th>Status</th>
                <th>Confirm</th>
                <th>Reject</th>
              </tr>
            </thead>
            <tbody>
              {
                data && data.getPendingHospital.slice().reverse().map(hospital => {
                  return (
                    <tr>
                      <td>{count++}</td>
                      <td>{hospital.hospitalRegistrationNumber}</td>
                      <td>{hospital.hospitalName}</td>
                      <td><center><Button size="sm" onClick={() => handleDetail(hospital.id)}><FaRegEye /></Button></center></td>
                      <td><center><Button size="sm" onClick={() => handleDetailAgent(hospital.agentId)} ><FaRegEye /></Button></center></td>
                      {
                        hospital.status === 'confirm' ?
                          <td style={{ color: 'green', fontSize: '12px', fontWeight: 'bold' }}>Confirm</td> :
                          hospital.status === 'pending' ?
                            <td style={{ color: 'orange', fontSize: '12px', fontWeight: 'bold' }}>Pending</td> :
                            hospital.status === 'reject' ?
                              <td style={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>Reject</td> : ''
                      }
                      <td><center><Button size="sm" style={{ background: "#7bed9f", border: "2px solid #7bed9f" }} onClick={() => handleConfirm(hospital.id)}><FaCheck /></Button></center></td>
                      <td><Button size="sm" style={{ background: "#ff4757", border: "2px solid #ff4757" }} onClick={() => rejectCommentBoxShowHandle(hospital.id)}><FaBan /></Button></td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
      }

      <Modal show={show} onHide={handleClose}>
        <center>
          <Image src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" style={{ width: 100, height: 100, borderRadius: 50, marginTop: 10, marginBottom: 10 }} />
        </center>
        {
          AgentData && AgentData.getAgentById === null ? <h1 style={{ fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: 'center', fontSize: '15px' }}>No Agent Found</h1> :
            <>
              <h6 style={{ textAlign: "center" }}>{AgentData && AgentData.getAgentById.uniqueId} </h6>
              <h6 style={{ textAlign: "center" }}>{AgentData && AgentData.getAgentById.firstName} {AgentData && AgentData.getAgentById.lastName}</h6>
              <h6 style={{ textAlign: "center" }}>{AgentData && AgentData.getAgentById.email} </h6>
              <h6 style={{ textAlign: "center", marginBottom: 10 }}>{AgentData && AgentData.getAgentById.contact} </h6>
            </>
        }

      </Modal>


      <Modal show={showCommentBox} onHide={handleCommentBoxClose} centered>
        <Modal.Body>
          <h1 style={{ fontSize: '20px', fontFamily: 'DM Sans', textAlign: 'center', fontWeight: 'bold' }}>Enter comment for rejection</h1>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Control required type="text" placeholder="Comment" style={{ fontSize: '12px', fontFamily: 'DM Sans' }} onChange={(e) => setRejectCommentState(e.target.value)} value={rejectCommentState} />
              </Form.Group>
            </Row>
            <Button type="submit" variant='danger' style={{ fontSize: '12px', fontFamily: 'DM Sans' }} className="mx-auto d-block">Reject</Button>
          </Form>
        </Modal.Body>
      </Modal>

    </>
  )
}
