import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Card, Row, Col, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { SUB_ADMIN_LOGIN } from '../../graphql/mutations';
import Spinner from '../Spinner';
import swal from 'sweetalert';
import "../BloodBank/Blood.css"
import Img from "../../Images/smd.jpg"
import Navigation from '../Navigation';

export default function SubAdminLogin() {

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [validated, setValidated] = useState(false);

    const [signinSubAdmin, { data, loading }] = useMutation(SUB_ADMIN_LOGIN, {
        variables: { signinSubAdminInput: { email, password } },
        onCompleted: ({ signinSubAdmin }) => {
            localStorage.setItem('subAdminId', signinSubAdmin.subAdminId);
            localStorage.setItem('subAdminToken', signinSubAdmin.subAdminToken);
            navigate('/subAdminDashboard/subDashboardHome');
        },
        onError: (e) => {
            swal({ title: "Error!!!", text: "Email & Password Not Match", icon: "error" });
        },
    });

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setValidated(false);
            event.preventDefault();
            signinSubAdmin();
        }
    };

    useEffect(() => {
        if (localStorage.getItem('superAdminToken')) {
            navigate('/subAdminDashboard/subDashboardHome');
        }
    }, [navigate]);

    console.log("email", email)
    console.log("password", password)

    return (
        <>
            <Navigation />
            <Container style={{ marginTop: '150px', background: "#fff", padding: 5, }}>
                <Card style={{ borderRadius: "20px", border: "2px solid #0097e6" }}>
                    <Row>
                        <Col md={6} style={{ borderRadius: 20 }}>
                            <center>
                                <Image src={Img} style={{ width: 350, height: 350, marginLeft: 10 }} clasName="img-fluid" alt="Responsive image" />
                            </center>
                        </Col>
                        <Col md="6" className='mx-auto d-block'>

                            {
                                loading ?
                                    <Spinner />
                                    :
                                    <>
                                        <br></br>
                                        <h2 className="mb-5" style={{ fontFamily: 'DM Sans', fontWeight: 'bold', textAlign: "center" }}>Sub Admin Login</h2>
                                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '15px' }}>Email</Form.Label>
                                                <Form.Control required style={{ fontFamily: 'DM Sans', fontSize: '15px', }} type="text" placeholder="Email" onChange={(e) => setEmail(e.target.value)} value={email} />
                                            </Form.Group>
                                            <Form.Group className="mb-4" controlId="formBasicPassword">
                                                <Form.Label style={{ fontFamily: 'DM Sans', fontSize: '15px' }}>Password</Form.Label>
                                                <Form.Control required style={{ fontFamily: 'DM Sans', fontSize: '15px' }} type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} value={password} />
                                            </Form.Group>
                                            <Button type="submit" style={{ fontFamily: 'DM Sans', fontSize: '15px', marginBottom: 50 }}>Login</Button>
                                        </Form>
                                    </>
                            }
                        </Col>
                    </Row>
                </Card>
            </Container>
        </>
    )
}
