import React,{useEffect,useState} from 'react'
import { Container, Row, Col, Image, Table } from 'react-bootstrap'
import Navigation from "../../Navigation"
import blood from "../../../Images/blood.jpg"
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelopeOpen, FaUserAlt } from 'react-icons/fa'
import { QUERY_GET_BLOODBANK_BY_ID } from '../../../graphql/queries'
import { useJsApiLoader, GoogleMap, MarkerF } from '@react-google-maps/api'

export default function BloodBankDetail() {

   const [center, setCenter] = useState({ lat: 23.8388, lng: 78.7378 })

   const { id } = useParams()
   const { data } = useQuery(QUERY_GET_BLOODBANK_BY_ID, {
      variables: {
         "bloodBankId": `${id}`
      }
   })

   
   useEffect(() => {
      if (data) {
        setCenter({ lat: parseFloat(data.getBloodBankById.latitude), lng: parseFloat(data.getBloodBankById.longitude) })
      }
    }, [data]);

    const { isLoaded } = useJsApiLoader({
      googleMapsApiKey: 'AIzaSyB2eRRn8bW1VVh4wj4AEaYd3F_RgEP2mE4'
    })
  
    if (!isLoaded) {
      return <h1>Loading</h1>
    }


  

   console.log("bloodbank", data)

   return (
      <div style={{ background: "#00a8ff" }}>
         <Navigation />
         <br></br> <br></br><br></br> <br></br><br></br>
         <Container style={{ background: "#fff", marginBottom: 20, borderRadius: 10 }}>
            <Row>
               <h5 style={{ textAlign: "center", marginTop: "10px", fontFamily: "Dm sans", fontWeight: "800" }}>Blood Bank Detail</h5>
               <hr></hr>
               <Col md={6}>
                  <Image src={`https://bharp-hospital.s3.ap-south-1.amazonaws.com/${data && data.getBloodBankById.picture}`} style={{ width: 300 }} className="img-fluid mx-auto d-block" alt="Image Not Available" />
               </Col>
               <Col md={6}>
                  <h6 style={{ textAlign: "center", fontFamily: "Dm sans", fontWeight: "600", color: "#3498db" }}>Center Name : {data && data.getBloodBankById.registrationNo}</h6>
                  <h6 style={{ textAlign: "center", fontFamily: "Dm sans", fontWeight: "600", color: "#3498db" }}>Emergency Contact : {data && data.getBloodBankById.emergencyContact}</h6>
                  {
                     data && data.getBloodBankById.bloodBankTypeOther === "" ?
                        <h6 style={{ textAlign: "center", fontFamily: "Dm sans", fontWeight: "600", color: "#3498db" }}>Blood Bank Type : {data && data.getBloodBankById.bloodBankType}</h6> :
                        <h6 style={{ textAlign: "center", fontFamily: "Dm sans", fontWeight: "600", color: "#3498db" }}>Blood Bank Type : {data && data.getBloodBankById.bloodBankTypeOther}</h6>
                  }

                  {
                     data && data.getBloodBankById.bloodBankOwnerShipOther === "" ?
                        <h6 style={{ textAlign: "center", fontFamily: "Dm sans", fontWeight: "600", color: "#3498db" }}>blood Bank OwnerShip : {data && data.getBloodBankById.bloodBankOwnerShip}</h6> :
                        <h6 style={{ textAlign: "center", fontFamily: "Dm sans", fontWeight: "600", color: "#3498db" }}>blood Bank OwnerShip : {data && data.getBloodBankById.bloodBankOwnerShipOther}</h6>
                  }

                  <p styl={{ fontFamily: "Dm sans" }}><FaEnvelopeOpen style={{ color: "#3498db", marginTop: '-4px' }} /> {data && data.getBloodBankById.centerEmail}</p>
                  <p style={{ fontFamily: "Dm sans" }}><FaPhoneAlt style={{ color: "#3498db", marginTop: '-4px' }} /> {data && data.getBloodBankById.centerContact}</p>
                  <p style={{ fontFamily: "Dm sans" }}><FaMapMarkerAlt style={{ color: "#3498db", marginTop: '-4px' }} /> {data && data.getBloodBankById.address} {data && data.getBloodBankById.city} {data && data.getBloodBankById.district} {data && data.getBloodBankById.state} {data && data.getBloodBankById.pincode}</p>
               </Col>
            </Row>

            <hr></hr>
            <Row>
               <Col>
                  <h6 style={{ fontFamily: "Dm sans", textAlign: "center", fontWeight: "800" }}>Nodal Person Detail</h6>
                  <p style={{ fontFamily: "Dm sans" }}><FaUserAlt style={{ color: "#3498db", marginTop: '-4px' }} /> {data && data.getBloodBankById.nodalPersonFName} {data && data.getBloodBankById.nodalPersonLName}  </p>
                  <p styl={{ fontFamily: "Dm sans" }}><FaEnvelopeOpen style={{ color: "#3498db", marginTop: '-4px' }} /> {data && data.getBloodBankById.nodalPersonEamil}</p>
                  <p style={{ fontFamily: "Dm sans" }}><FaPhoneAlt style={{ color: "#3498db", marginTop: '-4px' }} /> {data && data.getBloodBankById.nodalPersonContact}</p>
               </Col>
            </Row>
            <hr></hr>
            <h1 style={{ fontFamily: "Dm sans", fontSize: '15px', textAlign: 'center', fontWeight: 'bold' }}>Blood Details</h1>
            <Table bordered responsive >
               <thead style={{ fontFamily: "Dm sans", fontSize: 12 }}>
                  <tr>
                     <th colSpan={2} style={{ textAlign: 'center' }}>A+</th>
                     <th colSpan={2} style={{ textAlign: 'center' }}>A-</th>
                     <th colSpan={2} style={{ textAlign: 'center' }}>B+</th>
                     <th colSpan={2} style={{ textAlign: 'center' }}>B-</th>
                     <th colSpan={2} style={{ textAlign: 'center' }}>O+</th>
                     <th colSpan={2} style={{ textAlign: 'center' }}>O-</th>
                     <th colSpan={2} style={{ textAlign: 'center' }}>AB+</th>
                     <th colSpan={2} style={{ textAlign: 'center' }}>AB-</th>
                  </tr>
                  <tr>
                     <th>Quantity</th>
                     <th>Available</th>
                     <th>Quantity</th>
                     <th>Available</th>
                     <th>Quantity</th>
                     <th>Available</th>
                     <th>Quantity</th>
                     <th>Available</th>
                     <th>Quantity</th>
                     <th>Available</th>
                     <th>Quantity</th>
                     <th>Available</th>
                     <th>Quantity</th>
                     <th>Available</th>
                     <th>Quantity</th>
                     <th>Available</th>

                  </tr>
               </thead>
               <tbody style={{ fontFamily: "Dm sans", fontSize: 12 }}>
                  <tr>
                     <td>{data && data.getBloodBankById.bloodBankAPos[0].quantity}</td>
                     <td>{data && data.getBloodBankById.bloodBankAPos[0].available}</td>
                     <td>{data && data.getBloodBankById.bloodBankANeg[0].quantity}</td>
                     <td>{data && data.getBloodBankById.bloodBankANeg[0].available}</td>
                     <td>{data && data.getBloodBankById.bloodBankBPos[0].quantity}</td>
                     <td>{data && data.getBloodBankById.bloodBankBPos[0].available}</td>
                     <td>{data && data.getBloodBankById.bloodBankBNeg[0].quantity}</td>
                     <td>{data && data.getBloodBankById.bloodBankBNeg[0].available}</td>
                     <td>{data && data.getBloodBankById.bloodBankOPos[0].quantity}</td>
                     <td>{data && data.getBloodBankById.bloodBankOPos[0].available}</td>
                     <td>{data && data.getBloodBankById.bloodBankONeg[0].quantity}</td>
                     <td>{data && data.getBloodBankById.bloodBankONeg[0].available}</td>
                     <td>{data && data.getBloodBankById.bloodBankABPos[0].quantity}</td>
                     <td>{data && data.getBloodBankById.bloodBankABPos[0].available}</td>
                     <td>{data && data.getBloodBankById.bloodBankABNeg[0].quantity}</td>
                     <td>{data && data.getBloodBankById.bloodBankABNeg[0].available}</td>
                  </tr>
               </tbody>
            </Table>
                 <Row>
                  <Col md={12}>
                  <GoogleMap center={center} zoom={15} mapContainerStyle={{ width: '100%', height: '200px' }}>
              <MarkerF position={center} />
            </GoogleMap>
                  </Col>

                  </Row>

         </Container>


      </div>
   )
}
