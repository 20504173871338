import { Form, Row, Col, Button, Container } from 'react-bootstrap';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { ADD_SUB_ADMIN } from '../../../graphql/mutations';
import { GET_ALL_SUB_ADMIN } from '../../../graphql/queries';
import Spinner from '../../Spinner';

import swal from 'sweetalert';

export default function AddSubadmin() {
  const [validated, setValidated] = useState(false);
  const [test, { loading }] = useMutation(ADD_SUB_ADMIN, {
    refetchQueries: [GET_ALL_SUB_ADMIN],
  });

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [contact, setContact] = useState();
   const [password, setPassword] = useState('');
  console.log(firstName, lastName, email, contact);
  const handleSubmit = (e) => {
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      console.log('false');
    } else {
      e.preventDefault();
      console.log('true');
      test({
        variables: {
          subadminInput: {
            fName:`${firstName}`,
            lName: `${lastName}`,
            contact: `${contact}`,
            email:`${email}` ,
          },
        },
        onCompleted: () => {
          setValidated(false);
          setFirstName();
          setLastName();
          setEmail();
          setContact();
           setPassword()
          swal({
            title: 'Successfull!!!',
            text: ' Add Sub Admin Successfully',
            icon: 'success',
          });
        },
      });
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <div style={{ marginTop: '30px' }}>
        <Container>
          <h1 style={{ fontFamily: 'DM Sans', fontWeight: 'bold' }}>
            Add Sub Admin
          </h1>
          <hr />
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="firstName">
                  <Form.Control
                    type="text"
                    style={{ fontFamily: 'DM Sans', fontSize: '12px' }}
                    name="firstName"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                    placeholder="First Name"
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="lastName">
                  <Form.Control
                    type="text"
                    style={{ fontFamily: 'DM Sans', fontSize: '12px' }}
                    name="lastName"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    placeholder="Last Name"
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="email">
                  <Form.Control
                    type="email"
                    style={{ fontFamily: 'DM Sans', fontSize: '12px' }}
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder="Email"
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="contact">
                  <Form.Control
                    type="number"
                    style={{ fontFamily: 'DM Sans', fontSize: '12px' }}
                    name="contact"
                    onChange={(e) => setContact(e.target.value)}
                    value={contact}
                    placeholder="Contact Number"
                    required
                  />
                </Form.Group>
              </Col>
              {/* <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="password">
                  <Form.Control type="password" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="password" onChange={e => setPassword(e.target.value)} value={password} placeholder="Password" required />
                </Form.Group>
              </Col> */}
            </Row>
            <Button
              type="submit"
              className="mt-5 mx-auto d-block"
              style={{
                fontFamily: 'DM Sans',
                fontSize: '12px',
                marginBottom: '100px',
              }}
            >
              Add Sub admin
            </Button>
          </Form>
        </Container>
      </div>
    </>
  );
}
