import React from 'react'
import { Container, Row, Col, Image, Button } from 'react-bootstrap'
import '../ComponentCss/About.css'
import AboutImg from '../Images/top_service.png'

export default function About() {
    return (
        <div className='banner_part2' id="aboutId">
            <Container style={{ marginTop: 70 }}>
                <Row>
                    <Col md={6} style={{ textAlign: 'left' }}>
                        <Image src={AboutImg} className="d-none d-lg-block img-fluid" style={{ width: '100%', height: '100%' }} />
                    </Col>
                    <Col md={6} style={{ textAlign: 'left', padding: 70 }}>
                        <h2 className='AboutH2'>About US</h2>
                        <p style={{ marginTop: 30, fontFamily: 'poppins' }}>B+Harp  is an integrated health care system of hospitals, neighborhood health centers, long-term care facilities and home care – the public safety net health care system of INDIA. B+Harp is committed to the health and well-being of all indians and we offer a wide range of high quality and affordable health care services to keep our patients healthy and to address the needs of diverse populations. Our mission is to deliver high quality health services with compassion, dignity, and respect to all, regardless of income, gender identity, or immigration status.</p>
                        {/* <Row style={{ marginTop: 30 }}>
                            
                            <Col md={3} style={{ marginTop: 10 }}>
                                <Image className='mx-auto d-block' src={b1} style={{ width: 50, height: 50 }} />
                            </Col>
                            <Col md={1}>
                                <div className="vLine"></div>
                            </Col>
                            <Col md={3} style={{ marginTop: 10 }}>
                                <Image className='mx-auto d-block' src={b2} style={{ width: 50, height: 50 }} />
                            </Col>
                            <Col md={1}>
                                <div className="vLine"></div>
                            </Col>
                            <Col md={3} style={{ marginTop: 10 }}>
                                <Image className='mx-auto d-block' src={b3} style={{ width: 50, height: 50 }} />
                            </Col>
                        </Row> */}
                    </Col>
                </Row>
            </Container>

        </div>
    )
}
