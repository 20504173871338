import { Form, Row, Col, Button, Container, Spinner } from 'react-bootstrap';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_AGENT } from '../../../graphql/mutations';
import { GET_ALL_AGENTS } from '../../../graphql/queries';
import swal from 'sweetalert'

const EditHospital = ({ agent }) => {
  const [validated, setValidated] = useState(false);
  const [updateAgent, { loading }] = useMutation(UPDATE_AGENT);

  const [firstName, setFirstName] = useState(agent.firstName);
  const [lastName, setLastName] = useState(agent.lastName);
  const [email, setEmail] = useState(agent.email);
  const [contact, setContact] = useState(agent.contact);

  const handleSubmit = (e) => {
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
    }
    else {
      e.preventDefault();
      updateAgent({
        variables: {
          "updateAgentInput": {
            "id": `${agent.id}`,
            "firstName": `${firstName}`,
            "lastName": `${lastName}`,
            "email": `${email}`,
            "contact": `${contact}`,
          }
        },
        refetchQueries: [{ query: GET_ALL_AGENTS }, 'GetAllAgent'],
        onCompleted: () => {
          setValidated(false);
          setFirstName()
          setLastName()
          setEmail()
          setContact()
          swal({ title: "Successfull!!!", text: "Agent Update Successfully", icon: "success" });
        },
      });
    }
  };

  if (loading) {
    return (
      <Spinner animation="border" role="status" className="mx-auto d-block">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    )
  }

  return (
    <>
      <div>
        <Container>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="firstName">
                  <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="firstName" onChange={e => setFirstName(e.target.value)} value={firstName} placeholder="First Name" required />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="lastName">
                  <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="lastName" onChange={e => setLastName(e.target.value)} value={lastName} placeholder="Last Name" required />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="email">
                  <Form.Control type="email" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="email" onChange={e => setEmail(e.target.value)} value={email} placeholder="Email" required />
                </Form.Group>
              </Col>
              <Col md={6} lg={4} className="mt-5">
                <Form.Group controlId="contact">
                  <Form.Control type="text" style={{ fontFamily: 'DM Sans', fontSize: '12px' }} name="contact" onChange={e => setContact(e.target.value)} value={contact} placeholder="Contact Number" required />
                </Form.Group>
              </Col>
            </Row>
            <Button type="submit" className="mt-5" style={{ fontFamily: 'DM Sans', fontSize: '12px', marginBottom: '30px' }}>
              Update Agent
            </Button>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default EditHospital;
