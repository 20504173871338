import React, { useState, useEffect } from 'react';
import { Button, Table, Modal, Spinner, Container, Form, Row, Col, Carousel, Image } from "react-bootstrap";
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { FaRetweet, FaRegEye } from 'react-icons/fa';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import { QUERY_GET_AMBULANCE_ALL_BOOKINGS, QUERY_USER_BY_ID, QUERY_GET_AMBULANCE_BY_ID } from '../../../graphql/queries';
import { MUTATION_AMBULANCE_REFUND_TO_USER, MUTATION_AMBULANCE_BOOKING_REFUND } from '../../../graphql/mutations';
import Moment from 'react-moment';
import * as XLSX from 'xlsx';
import '../../../ComponentCss/AmbulanceBooking.css';
import { FaPhoneAlt, FaEnvelopeOpen, FaUserAlt } from 'react-icons/fa'
import { AiOutlineFolderView } from 'react-icons/ai'

export default function AmbulanceBookingsSub() {

    let count = 1
    const navigate = useNavigate()

    const [bookingData, setBookingData] = useState();
    const [search, setSearch] = useState();
    const [ambulanceId, setAmbulanceId] = useState();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function handleOnExport() {
        const todayDate = new Date()
        const todayDateString = todayDate.toString()
        const finalDate = todayDateString.slice(0, 10);
        console.log("finaldate", finalDate)
        var wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(ambulanceBookingData && ambulanceBookingData.getAmbulanceAllBookings);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet");
        XLSX.writeFile(wb, "Ambulancebooking(" + finalDate + ").xlsx")
    }

    useEffect(() => {
        setInterval(() => {
            refetch()
        }, 1000);
    }, []);

    const { data: ambulanceBookingData, loading: ambulanceBookingLoading, refetch } = useQuery(QUERY_GET_AMBULANCE_ALL_BOOKINGS)
    const [getUserData, { data: userData }] = useLazyQuery(QUERY_USER_BY_ID)

    const { data: ambulanceData, loading: ambulanceDataLoading } = useQuery(QUERY_GET_AMBULANCE_BY_ID, {
        variables: {
            "ambulanceId": `${ambulanceId}`
        }
    })

    const [refundToUser] = useMutation(MUTATION_AMBULANCE_REFUND_TO_USER, {
        refetchQueries: [
            QUERY_GET_AMBULANCE_ALL_BOOKINGS
        ]
    })
    const [refundStatusChange] = useMutation(MUTATION_AMBULANCE_BOOKING_REFUND, {
        refetchQueries: [
            QUERY_GET_AMBULANCE_ALL_BOOKINGS
        ]
    })

    useEffect(() => {
        if (ambulanceBookingData) {
            setFoundValue(ambulanceBookingData.getAmbulanceAllBookings)
        }
    }, [ambulanceBookingData]);

    function viewUserClick(uId) {
        getUserData({
            variables: {
                "userId": `${uId}`
            }
        })
        handleShow()
    }

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);

    const [show5, setShow5] = useState(false);
    const handleClose5 = () => setShow5(false);
    const handleShow5 = () => setShow5(true);

    function openAmbulanceDetails(aId) {
        setAmbulanceId(aId)
        handleShow4()
    }

    const [getService, setGetService] = useState([])
    const [foundValue, setFoundValue] = useState();



    function handleService(service) {
        handleShow3()
        setGetService(service)
    }

    function refundHandle(bookingId, userId, walletPrice) {
        console.log("bookingId", bookingId)
        console.log("userId", userId)
        console.log("walletPrice", walletPrice)

        refundToUser({
            variables: {
                "userId": `${userId}`,
                "walletPrice": `${walletPrice}`
            }
        }).then(() => {
            refundStatusChange({
                variables: {
                    "ambulanceRefundInput": {
                        "bookingId": `${bookingId}`
                    }
                }
            }).then(() => {
                swal({ title: 'Refunded!!!', text: 'Refund Successfully', icon: 'success' });
            })
        })
    }


    const filterBookingNumber = (e) => {
        setSearch("")
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = ambulanceBookingData.getAmbulanceAllBookings.filter((data) => {
                return data.ambulanceBookingId.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setFoundValue(results);
        }
        else {
            setFoundValue(ambulanceBookingData.getAmbulanceAllBookings);
        }
        setSearch(keyword);
    };

    function handleViewBookingDetail(bData) {
        setBookingData(bData)
        handleShow5()
    }
    console.log("bookingData", bookingData)
    return (
        <>
            <Row>
                <Col md={4}><h3 style={{ padding: 10, fontFamily: "Dm sans", fontWeight: "bold", marginTop: '20px' }}>Ambulance Bookings </h3></Col>
                <Col md={4}></Col>
                <Col md={4}>
                    <Form.Control type="text" value={search} placeholder="Search Booking Number" style={{ marginTop: 25, fontFamily: "Dm sans" }} onChange={filterBookingNumber} />
                </Col>

            </Row>

            <Table bordered style={{ fontFamily: "Dm sans", fontSize: 12 }}>
                <tbody>
                    <tr>
                        <td><Button size="sm" onClick={() => handleOnExport()}>Download Excel</Button></td>
                    </tr>
                </tbody>
            </Table>
            <hr style={{ background: "#fbc531", height: 3 }}></hr>
            <h6 style={{ textAlign: 'center' }}>(Press Shift+Scroll for move horizontally)</h6>
            {
                ambulanceBookingLoading ?
                    <Spinner animation="border" role="status" className="mx-auto d-block">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner> :
                    <Table bordered style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>
                        <thead style={{ background: '#34495e', color: '#fff', position: 'sticky', top: 0, margin: '0,0,0,0' }}>
                            <tr>
                                <th>S.No.</th>
                                <th>Booking Id</th>
                                <th>User Name</th>
                                <th>User Contact</th>
                                <th>Booking Date/Time</th>
                                <th>Payment Type</th>
                                <th>Local Type</th>
                                <th>Status</th>
                                <th>Refund Status</th>
                                <th>Refund</th>
                                <th>View Details</th>
                                <th>All Service</th>
                                <th>User Details</th>
                                <th>Ambulance Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                foundValue && foundValue.slice().reverse().map(aData => {
                                    return (
                                        <tr>
                                            <td>{count++}</td>
                                            <td>{aData.ambulanceBookingId}</td>
                                            <td>{aData.userName}</td>
                                            <td>{aData.userContact}</td>
                                            <td><Moment format="DD-MM-YYYY, hh:mm a">{aData.createDateTime}</Moment></td>
                                            <td>{aData.paymentType}</td>
                                            <td><center>{aData.localType}</center></td>
                                            <td>
                                                {
                                                    aData.status === 'confirm' ?
                                                        <td style={{ color: 'green', fontSize: '12px', fontWeight: 'bold' }}>Confirm</td> :
                                                        aData.status === 'pending' ?
                                                            <td style={{ color: 'orange', fontSize: '12px', fontWeight: 'bold' }}>Pending</td> :
                                                            aData.status === 'reject' ?
                                                                <td style={{ color: 'yellow', fontSize: '12px', fontWeight: 'bold' }}>Reject</td> :
                                                                aData.status === 'cancel' ?
                                                                    <td style={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>Reject</td> : ''
                                                }
                                            </td>
                                            {
                                                aData.refundStatus === null ?
                                                    <td style={{ color: '#3498db', fontWeight: 'bold' }}>No Refund Initiated</td> :
                                                    aData.refundStatus === "false" ?
                                                        <td style={{ color: '#9b59b6', fontWeight: 'bold' }}>Refund Initiated</td> :
                                                        aData.refundStatus === "true" ?
                                                            <td style={{ color: '#2ecc71', fontWeight: 'bold' }}>Refund Successfully</td> :
                                                            <td style={{ color: '#e74c3c', fontWeight: 'bold' }}>Error</td>
                                            }

                                            {
                                                aData.cancelDateTime === null || aData.refundStatus === null || aData.refundStatus === null || aData.refundPercentage === null ?
                                                    <td><center><Button variant='warning' size="sm" disabled><FaRetweet /></Button></center></td> :
                                                    aData.refundStatus === null || aData.refundStatus === "true" ?
                                                        <td><center><Button variant='warning' size="sm" disabled><FaRetweet /></Button></center></td>
                                                        :
                                                        <td><center><Button variant='warning' size="sm" onClick={() => refundHandle(aData.id, aData.userId, aData.refundAmount)}><FaRetweet /></Button></center></td>
                                            }
                                            <td><center><Button size="sm" onClick={() => handleViewBookingDetail(aData)} style={{ background: '#e67e22', borderColor: '#e67e22' }}><AiOutlineFolderView /></Button></center></td>
                                            <td><center><Button size="sm" onClick={() => handleService(aData.allservice)}><FaRegEye /></Button></center></td>
                                            <td><center><Button size="sm" variant='info' onClick={() => viewUserClick(aData.userId)}><FaRegEye /></Button></center></td>
                                            <td><center><Button size="sm" onClick={() => openAmbulanceDetails(aData.ambulanceId)}><FaRegEye /></Button></center></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
            }

            <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Body>
                    <h1 style={{ fontSize: "15px", fontFamily: "Dm sans", textAlign: 'center', fontWeight: 'bold' }}>User Details</h1>
                    <Table bordered style={{ fontSize: "12px", fontFamily: "Dm sans" }} responsive>
                        <tbody>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Full Name</td>
                                <td>{userData && userData.getUserById.fName} {userData && userData.getUserById.fName}</td>
                                <td style={{ fontWeight: 'bold' }}>Email</td>
                                <td>{userData && userData.getUserById.email}</td>
                                <td style={{ fontWeight: 'bold' }}>Contact</td>
                                <td>{userData && userData.getUserById.contact}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Address</td>
                                <td colSpan={5}>{userData && userData.getUserById.address}, {userData && userData.getUserById.city}, {userData && userData.getUserById.district}, {userData && userData.getUserById.state}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>

            <Modal show={show3} onHide={handleClose3}>
                <Container>
                    <h1 style={{ fontSize: "15px", fontFamily: "Dm sans", textAlign: 'center', fontWeight: 'bold', marginTop: 20 }}>User Selected Facility</h1>
                    <Table bordered style={{ fontSize: "12px", fontFamily: "Dm sans" }} responsive>
                        <tbody>
                            {
                                getService && getService.map(list => {
                                    return (
                                        <>
                                            <tr>
                                                <td style={{ fontWeight: 'bold' }}>Facility Name</td>
                                                <td colSpan={5}>{list.facilityName}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontWeight: 'bold' }}>Facility Price</td>
                                                <td colSpan={5}>{list.facilityPrice}</td>
                                            </tr>
                                        </>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </Container>
            </Modal>

            <Modal show={show4} onHide={handleClose4} size="lg">
                {
                    ambulanceDataLoading ?
                        <>
                            <div className='mx-auto d-block'>
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        </>
                        :
                        <>
                            <Container style={{ background: "#fff", marginBottom: 20, borderRadius: 10, marginTop: 20 }}>
                                <Row>
                                    <h5 style={{ textAlign: "center", marginTop: "10px", fontFamily: "Dm sans", fontWeight: "800" }}>Ambulance Detail</h5>
                                    <hr></hr>

                                    <Col md={3}>
                                        <Carousel style={{ width: '100%', height: '100%' }}>
                                            {
                                                ambulanceData && ambulanceData.getAmbulanceById.vehicleImg.map((Image, index) => {
                                                    return (
                                                        <Carousel.Item style={{ width: '100%', height: '100%' }}>
                                                            <img
                                                                style={{ width: '100%', height: '400px' }}
                                                                src={`https://bharp-ambulance.s3.ap-south-1.amazonaws.com/${Image}`}
                                                                alt="Ambulance Pictures"
                                                            />
                                                        </Carousel.Item>
                                                    )
                                                })
                                            }
                                        </Carousel>

                                    </Col>
                                    <Col md={3}>
                                        <h6 style={{ fontFamily: "Dm sans", textAlign: "center", fontWeight: "bolds" }}>Vechile Detail</h6>
                                        <h6 style={{ textAlign: "center", fontFamily: "Dm sans", fontWeight: "600", color: "#3498db", fontSize: 12 }}>Vehicle No : {ambulanceData && ambulanceData.getAmbulanceById.vehicleNo}</h6>
                                        <h6 style={{ textAlign: "center", fontFamily: "Dm sans", fontWeight: "600", color: "#3498db", fontSize: 12 }}>License No : {ambulanceData && ambulanceData.getAmbulanceById.licenceNo}</h6>
                                        {
                                            ambulanceData && ambulanceData.getAmbulanceById.vehicleTypeOther === "" ?
                                                <h6 style={{ textAlign: "center", fontFamily: "Dm sans", fontWeight: "600", color: "#3498db", fontSize: 12 }}>Vehicle Type : {ambulanceData && ambulanceData.getAmbulanceById.vehicleType}</h6> :
                                                <h6 style={{ textAlign: "center", fontFamily: "Dm sans", fontWeight: "600", color: "#3498db", fontSize: 12 }}>Vehicle Type : {ambulanceData && ambulanceData.getAmbulanceById.vehicleTypeOther}</h6>
                                        }
                                        {
                                            ambulanceData && ambulanceData.getAmbulanceById.ambulanceOwnerShipOther === "" ?
                                                <h6 style={{ textAlign: "center", fontFamily: "Dm sans", fontWeight: "600", color: "#3498db", fontSize: 12 }}>Ownership : {ambulanceData && ambulanceData.getAmbulanceById.ambulanceOwnerShip}</h6> :
                                                <h6 style={{ textAlign: "center", fontFamily: "Dm sans", fontWeight: "600", color: "#3498db", fontSize: 12 }}>Ownership : {ambulanceData && ambulanceData.getAmbulanceById.ambulanceOwnerShipOther}</h6>
                                        }
                                        <h6 style={{ textAlign: "center", fontFamily: "Dm sans", fontWeight: "600", color: "#3498db", fontSize: 12 }}>Emergency Contact No : {ambulanceData && ambulanceData.getAmbulanceById.emengencyContact}</h6>
                                    </Col>
                                    <Col md={3} style={{ textAlign: 'center' }}>
                                        <h6 style={{ fontFamily: "Dm sans", fontWeight: "bold" }}>Owner Detail</h6>
                                        <p style={{ fontFamily: "Dm sans", fontSize: 12 }}><FaUserAlt style={{ color: "#3498db" }} /> {ambulanceData && ambulanceData.getAmbulanceById.ownerfName} {ambulanceData && ambulanceData.getAmbulanceById.ownerlName}</p>
                                        <p style={{ fontFamily: "Dm sans", fontSize: 12 }}><FaEnvelopeOpen style={{ color: "#3498db" }} /> {ambulanceData && ambulanceData.getAmbulanceById.ownerEmail}</p>
                                        <p style={{ fontFamily: "Dm sans", fontSize: 12 }}><FaPhoneAlt style={{ color: "#3498db" }} /> {ambulanceData && ambulanceData.getAmbulanceById.ownerContact}</p>


                                    </Col>
                                    <Col md={3}>
                                        <h6 style={{ fontFamily: "Dm sans", textAlign: "center", fontWeight: "800" }}>Driver Detail</h6>
                                        <Row>
                                            <Col md={6}>
                                                <Image src={`https://bharp-ambulance.s3.ap-south-1.amazonaws.com/${ambulanceData && ambulanceData.getAmbulanceById.driverImg}`} style={{ width: '100px', height: '180px' }} className="img-fluid mx-auto d-block" alt="Image Not Available" fluid />
                                                <h1 style={{ fontFamily: "Dm sans", textAlign: "center", fontWeight: "800", fontSize: '12px', marginTop: '10px' }}>Driver Image</h1>
                                            </Col>
                                            <Col md={6}>
                                                <Image src={`https://bharp-ambulance.s3.ap-south-1.amazonaws.com/${ambulanceData && ambulanceData.getAmbulanceById.licenceImg}`} style={{ width: '100%', height: 180 }} className="img-fluid mx-auto d-block" alt="Image Not Available" fluid />
                                                <h1 style={{ fontFamily: "Dm sans", textAlign: "center", fontWeight: "800", fontSize: '12px', marginTop: '10px' }}>Driver License Image</h1>
                                            </Col>
                                        </Row>
                                        <p style={{ fontFamily: "Dm sans", fontSize: 12 }}><FaUserAlt style={{ color: "#3498db" }} /> {ambulanceData && ambulanceData.getAmbulanceById.driverFName} {ambulanceData && ambulanceData.getAmbulanceById.driverlName}</p>
                                        <p style={{ fontFamily: "Dm sans", fontSize: 12 }}><FaEnvelopeOpen style={{ color: "#3498db" }} /> {ambulanceData && ambulanceData.getAmbulanceById.driverEmail}</p>
                                        <p style={{ fontFamily: "Dm sans", fontSize: 12 }}><FaPhoneAlt style={{ color: "#3498db" }} /> {ambulanceData && ambulanceData.getAmbulanceById.driverContact}</p>

                                    </Col>
                                </Row>
                                <hr></hr>
                                <Row>

                                    <Col md={3}>
                                        <p style={{ fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}>Local Thresholder Per Km: <span style={{ fontWeight: 'normal' }}>{ambulanceData && ambulanceData.getAmbulanceById.localThresholderKm}</span></p>
                                        <p style={{ fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}>Local Price Per Km: <span style={{ fontWeight: 'normal' }}>{ambulanceData && ambulanceData.getAmbulanceById.localPriceKm}</span></p>
                                        <p style={{ fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}>Local Booking Price: <span style={{ fontWeight: 'normal' }}>{ambulanceData && ambulanceData.getAmbulanceById.localBasePrice}</span></p>
                                        <p style={{ fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}>Out Local Price Per Km: <span style={{ fontWeight: 'normal' }}>{ambulanceData && ambulanceData.getAmbulanceById.outLocalPerKmPrice}</span></p>
                                        <p style={{ fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}>Out Local Booking Price: <span style={{ fontWeight: 'normal' }}>{ambulanceData && ambulanceData.getAmbulanceById.outLocalBasePrice}</span></p>
                                        <p style={{ fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}>Bharp Discount: <span style={{ fontWeight: 'normal' }}>{ambulanceData && ambulanceData.getAmbulanceById.bharpDiscount}%</span></p>
                                        <p style={{ fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}>Vendor Margin: <span style={{ fontWeight: 'normal' }}>{ambulanceData && ambulanceData.getAmbulanceById.vendorMargin}%</span></p>
                                    </Col>
                                    <Col md={3}>
                                        <p style={{ fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}>AC Available: <span style={{ fontWeight: 'normal' }}>{ambulanceData && ambulanceData.getAmbulanceById.acAvailable}</span></p>
                                        <p style={{ fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}>AC Price Per Km: <span style={{ fontWeight: 'normal' }}>{ambulanceData && ambulanceData.getAmbulanceById.acPerKmPrice}</span></p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <div style={{ marginTop: 10 }}>
                                            <h4 style={{ fontFamily: "Dm sans", fontWeight: "bold", textAlign: 'center', fontSize: 15 }}>Facilities</h4>
                                            <Table striped bordered hover style={{ fontSize: 12 }}>
                                                <thead>
                                                    <tr>
                                                        <th>Facility Name</th>
                                                        <th>Facility Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        ambulanceData && ambulanceData.getAmbulanceById.facility.map(facilityData =>
                                                            <tr>
                                                                <td>{facilityData.facilityName}</td>
                                                                <td>{facilityData.facilityPrice}</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>

                                    </Col>
                                </Row>
                            </Container>
                        </>
                }
            </Modal>

            <Modal show={show5} onHide={handleClose5} size="lg">
                <Container>
                    <h1 style={{ fontSize: "30px", fontFamily: "Dm sans", textAlign: 'center', fontWeight: 'bold', marginTop: 10 }}>Booking Details</h1>
                    <hr />
                    <Table bordered style={{ fontFamily: 'DM Sans', fontSize: '12px' }}>
                        <tbody>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Booking Id</td>
                                <td>{bookingData && bookingData.ambulanceBookingId}</td>
                                <td style={{ fontWeight: 'bold' }}>User Name</td>
                                <td>{bookingData && bookingData.userName}</td>
                                <td style={{ fontWeight: 'bold' }}>User Contact</td>
                                <td>{bookingData && bookingData.userContact}</td>
                                <td style={{ fontWeight: 'bold' }}>Boooking Date/Time</td>
                                <td><Moment format="DD-MM-YYYY, hh:mm a">{bookingData && bookingData.createDateTime}</Moment></td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Payment Type</td>
                                <td>{bookingData && bookingData.paymentType}</td>
                                <td style={{ fontWeight: 'bold' }}>Local Type</td>
                                <td>{bookingData && bookingData.localType}</td>
                                <td style={{ fontWeight: 'bold' }}>Status</td>
                                <td>{bookingData && bookingData.status}</td>
                                <td style={{ fontWeight: 'bold' }}>Refund Status</td>
                                <td>{bookingData && bookingData.refundStatus}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Pickup Address</td>
                                <td colSpan={3}>{bookingData && bookingData.pickFullAddress} {bookingData && bookingData.pickUpAddress}</td>
                                <td style={{ fontWeight: 'bold' }}>Drop Address</td>
                                <td colSpan={3}>{bookingData && bookingData.dropFullAddress} {bookingData && bookingData.dropAddress}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>User Discount</td>
                                <td>{bookingData && bookingData.userDiscount}</td>
                                <td style={{ fontWeight: 'bold' }}>User Amount</td>
                                <td>₹{bookingData && bookingData.userPrice}</td>
                                <td style={{ fontWeight: 'bold' }}>Vendor Margin</td>
                                <td>{bookingData && bookingData.vendorDiscount}</td>
                                <td style={{ fontWeight: 'bold' }}>Vendor Amount</td>
                                <td>₹{bookingData && bookingData.vendorPrice}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Cancel Date/Time</td>
                                <td><Moment format="DD-MM-YYYY, hh:mm a">{bookingData && bookingData.cancelDateTime}</Moment></td>
                                <td style={{ fontWeight: 'bold' }}>Refund Amount</td>
                                <td>₹{bookingData && bookingData.refundAmount}</td>
                                <td style={{ fontWeight: 'bold' }}>Refund Percentage</td>
                                <td>{bookingData && bookingData.refundPercentage}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Container>
            </Modal>
        </>
    )
}
