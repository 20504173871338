import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import services from '../Images/service.png'
import '../ComponentCss/OurServices.css'
import hospital from '../Images/icons/hospital.png'
import radiology from '../Images/icons/radiology.png'
import pathology from '../Images/icons/pathology.png'
import ambulance from '../Images/icons/ambulance.png'
import bloodbank from '../Images/icons/bloodbank.png'

export default function OurServices() {
    return (
        <Container style={{ marginTop: 40 }}  id="serviceId">
            <Row>
                <Col md={12} style={{ textAlign: 'center', marginTop: 30 }}>
                    <h2 className='servicesH2'>Our Services</h2>
                </Col>
            </Row>
            <Row>
                <Col md={4} style={{ textAlign: 'left' }}>
                    <div style={{ marginTop: 30 }}>
                        <h3 className='h3Heading'><Image src={hospital} style={{ width: 30, height: 30, marginTop: '-14px' }} /> Hospital Services</h3>
                        <p style={{ fontWeight: 'bold' }}>B+ Harp offers comprehensive programs and services to meet your healthcare needs and help you maintain a healthy lifestyle.</p>
                    </div>
                    <div className='servicesdiv'>
                        <h3 className='h3Heading'><Image src={ambulance} style={{ width: 30, height: 30, marginTop: '-7px' }} /> Ambulance Services</h3>
                        <p style={{ fontWeight: 'bold' }}>The mobile app connects users to emergency facilities by providing timely ambulance service when required. With just one click or a call, a patient can have our associated ambulances at the location of their choice.</p>
                    </div>
                </Col>
                <Col md={4}>
                    <Image src={services} className="img-fluid mx-auto d-block d-none d-lg-block" style={{ width: 250, height: 250, marginTop: 80 }} />
                </Col>
                <Col md={4} style={{ textAlign: 'left' }}>
                    <div style={{ marginTop: 30 }}>
                        <h3 className='h3Heading'><Image src={radiology} style={{ width: 30, height: 30, marginTop: '-14px' }} /> Radiology Services</h3>
                        <p style={{ fontWeight: 'bold' }}>B + Harp provides the information about all nearest Radiology centers and services. Users can check the details and book their slot for services.</p>
                    </div>
                    <div className='servicesdiv'>
                        <h3 className='h3Heading'><Image src={pathology} style={{ width: 30, height: 30, marginTop: '-14px' }} /> Pathology Services</h3>
                        <p style={{ fontWeight: 'bold' }}>B+Harp Pathology and Laboratory Medicine engages in many aspects of patient care to enable accurate diagnoses in all areas of anatomic and clinical pathology.</p>
                    </div>
                </Col>
            </Row>
            <Row style={{ marginTop: -100 }}>
                <Col md={4}></Col>
                <Col md={4} style={{ textAlign: 'left' }}>
                    <div className='servicesdivBlood'>
                        <h3 className='h3Heading'><Image src={bloodbank} style={{ width: 30, height: 30, marginTop: '-14px' }} /> Blood Bank</h3>
                        <p style={{ fontWeight: 'bold' }}>B + Harp provides the information about all the nearest blood bank from your location. You can check the availability of blood in blood bank and can book the blood according to your need.</p>
                    </div>
                </Col>
                <Col md={4}></Col>
            </Row>
        </Container>
    )
}
