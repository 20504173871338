import { gql } from '@apollo/client';

export const GET_ALL_HOSPITALS = gql`
  query GetAllHospital {
    getAllHospital {
      id
      hospitalName
      hospitalType
      otherHospitalType
      otherHealthCareProvider
      hospitalHealthCareProviderType
      hospitalRegistrationNumber
      hospitalState
      hospitalCity
      hospitalBlock
      hospitalVillage
      hospitalDistrict
      hospitalAddress
      hospitalPincode
      hospitalEmail
      hospitalContact
      hospitalWebsiteLink
      hospitalEstablishmentYear
      password
      cPName
      cPDesignation
      cPEmail
      cPContact
      hospitalCertificateImg
      hospitalLicenseImg
      hospitalImages
      hospitalGstCertificateImg
      hospitalLatitude
      hospitalLongitude
      totalDcoctor
      doctorDetail {
        doctorName
        qualification
        experience
        speciality
        licenceNo
        ward
      }
      agentId
      createdDateTime
      status
    }
  }
`;

export const GET_ALL_DOCTORS = gql`
  query GetAllDoctor {
    getAllDoctor {
      id
      hospitalId
      hospitalWard
      fName
      lName
      contact
      email
      registrationNumber
      licenseNumber
      specialization
      password
      doctorImage
      deviceToken
      createdDateTime
      status
    }
  }
`;
export const GET_HOSPITAL_BY_ID = gql`
  query GetHospitalById($hospitalId: ID) {
    getHospitalById(hospitalId: $hospitalId) {
      id
      hospitalName
      hospitalType
      otherHospitalType
      otherHealthCareProvider
      hospitalHealthCareProviderType
      hospitalRegistrationNumber
      hospitalState
      hospitalCity
      hospitalBlock
      hospitalVillage
      hospitalDistrict
      hospitalAddress
      hospitalPincode
      hospitalEmail
      hospitalContact
      hospitalWebsiteLink
      hospitalEstablishmentYear
      password
      cPName
      cPDesignation
      cPEmail
      cPContact
      noOfMedicalConsultants
      noOfNurses
      noOfBeds
      noOfPrivateBeds
      noOfEmergencyBeds
      isEmergencyServiceAvailable
      isAmbulanceServiceAvailable
      hospitalCertificateImg
      hospitalLicenseImg
      beneficialServices
      hospitalImages
      hospitalGstCertificateImg
      hospitalLatitude
      hospitalLongitude
      totalDcoctor
      agentId
      createdDateTime
      status
      rejectStatus
      rejectComment
      addService {
        id
        serviceName
        servicePrice
      }
      doctorDetail {
        doctorName
        qualification
        experience
        speciality
        licenceNo
        ward
      }
    }
  }
`;

export const GET_ALL_AGENTS = gql`
  query GetAllAgent {
    getAllAgent {
      id
      firstName
      lastName
      contact
      email
      uniqueId
      password
      status
    }
  }
`;
export const GET_AGENT_BY_ID = gql`
  query GetHospitalById($agentId: ID) {
    getAgentById(agentId: $agentId) {
      id
      firstName
      lastName
      contact
      email
      uniqueId
      password
      createdDateTime
      status
    }
  }
`;

export const QUERY_REJECT_HOSPITAL = gql`
  query GetRejecteHospital {
    getRejecteHospital {
      id
      hospitalName
      hospitalType
      otherHospitalType
      otherHealthCareProvider
      hospitalHealthCareProviderType
      hospitalRegistrationNumber
      hospitalState
      hospitalCity
      hospitalBlock
      hospitalVillage
      hospitalDistrict
      hospitalAddress
      hospitalPincode
      hospitalEmail
      hospitalContact
      hospitalWebsiteLink
      hospitalEstablishmentYear
      password
      cPName
      cPDesignation
      cPEmail
      cPContact
      hospitalCertificateImg
      hospitalLicenseImg
      hospitalImages
      hospitalGstCertificateImg
      hospitalLatitude
      hospitalLongitude
      totalDcoctor
      agentId
      createdDateTime
      status
      doctorDetail {
        doctorName
        qualification
        experience
        speciality
        licenceNo
        ward
      }
    }
  }
`;
export const QUERY_CONFIRM_HOSPITAL = gql`
  query GetActiveHospital {
    getActiveHospital {
      id
      hospitalName
      hospitalType
      otherHospitalType
      otherHealthCareProvider
      hospitalHealthCareProviderType
      hospitalRegistrationNumber
      hospitalState
      hospitalCity
      hospitalBlock
      hospitalVillage
      hospitalDistrict
      hospitalAddress
      hospitalPincode
      hospitalEmail
      hospitalContact
      hospitalWebsiteLink
      hospitalEstablishmentYear
      password
      cPName
      cPDesignation
      cPEmail
      cPContact
      hospitalCertificateImg
      hospitalLicenseImg
      hospitalImages
      hospitalGstCertificateImg
      hospitalLatitude
      hospitalLongitude
      totalDcoctor
      agentId
      createdDateTime
      status
      doctorDetail {
        doctorName
        qualification
        experience
        speciality
        licenceNo
        ward
      }
    }
  }
`;
export const QUERY_PENDING_HOSPITAL = gql`
  query GetPendingHospital {
    getPendingHospital {
      id
      hospitalName
      hospitalType
      otherHospitalType
      otherHealthCareProvider
      hospitalHealthCareProviderType
      hospitalRegistrationNumber
      hospitalState
      hospitalCity
      hospitalBlock
      hospitalVillage
      hospitalDistrict
      hospitalAddress
      hospitalPincode
      hospitalEmail
      hospitalContact
      hospitalWebsiteLink
      hospitalEstablishmentYear
      password
      cPName
      cPDesignation
      cPEmail
      cPContact
      hospitalCertificateImg
      hospitalLicenseImg
      hospitalImages
      hospitalGstCertificateImg
      hospitalLatitude
      hospitalLongitude
      totalDcoctor
      agentId
      createdDateTime
      status
      doctorDetail {
        doctorName
        qualification
        experience
        speciality
        licenceNo
        ward
      }
    }
  }
`;
export const QUERY_PENDING_PATHOLOGY = gql`
  query GetPendingPathology {
    getPendingPathology {
      id
      nameOfCenter
      centerAddress
      villageName
      state
      district
      pincode
      contact
      email
      websiteLink
      ownerName
      ownerContact
      ownerEmail
      pathologistDetail {
        name
        qualification
        experience
      }
      privateSectorCentralGov
      publicSectorOther
      privateSectorIndividual
      privateSectorOther
      registrationNo
      picture
      lisenceBy
      services {
        id
        serviceName
        servicePrice
      }
      createdDateTime
      password
      latitude
      longitude
      status
      agentId
      rejectStatus
      rejectComment
    }
  }
`;
export const QUERY_ACTIVE_PATHOLOGY = gql`
  query GetActivePathology {
    getActivePathology {
      id
      nameOfCenter
      centerAddress
      villageName
      state
      district
      pincode
      contact
      email
      websiteLink
      ownerName
      ownerContact
      ownerEmail
      pathologistDetail {
        name
        qualification
        experience
      }
      privateSectorCentralGov
      privateSectorIndividual
      registrationNo
      lisenceBy
      services {
        id
        serviceName
        servicePrice
      }
      createdDateTime
      password
      latitude
      longitude
      status
      agentId
    }
  }
`;
export const QUERY_REJECT_PATHOLOGY = gql`
  query GetRejectPathology {
    getRejectPathology {
      id
      nameOfCenter
      centerAddress
      villageName
      state
      district
      pincode
      contact
      email
      websiteLink
      ownerName
      ownerContact
      ownerEmail
      pathologistDetail {
        name
        qualification
        experience
      }
      privateSectorCentralGov
      privateSectorIndividual
      registrationNo
      lisenceBy
      services {
        id
        serviceName
        servicePrice
      }
      createdDateTime
      password
      latitude
      longitude
      status
      agentId
    }
  }
`;

export const QUERY_PENDING_RADIOLOGY = gql`
  query GetPendingRadiology {
    getPendingRadiology {
      id
      nameOfCenter
      centerAddress
      villageName
      state
      district
      pincode
      contact
      email
      websiteLink
      picture
      ownerName
      ownerContact
      ownerEmail
      radiologistDetail {
        name
        qualification
        experience
        rpId
      }
      privateSectorCentralGov
      privateSectorIndividual
      registrationNo
      lisenceBy
      services {
        id
        serviceName
        servicePrice
      }
      createdDateTime
      password
      latitude
      longitude
      status
      agentId
    }
  }
`;
export const QUERY_ACTIVE_RADIOLOGY = gql`
  query GetActiveRadiology {
    getActiveRadiology {
      id
      nameOfCenter
      centerAddress
      villageName
      state
      district
      pincode
      bharpDiscount
      vendorMargin
      contact
      email
      websiteLink
      ownerName
      ownerContact
      ownerEmail
      radiologistDetail {
        name
        qualification
        experience
        rpId
      }
      privateSectorCentralGov
      privateSectorIndividual
      registrationNo
      lisenceBy
      services {
        id
        serviceName
        servicePrice
      }
      createdDateTime
      password
      latitude
      longitude
      status
      agentId
    }
  }
`;
export const QUERY_REJECT_RADIOLOGY = gql`
  query GetRejectRadiology {
    getRejectRadiology {
      id
      nameOfCenter
      centerAddress
      villageName
      state
      district
      pincode
      contact
      email
      websiteLink
      ownerName
      ownerContact
      ownerEmail
      radiologistDetail {
        name
        qualification
        experience
        rpId
      }
      privateSectorCentralGov
      privateSectorIndividual
      registrationNo
      lisenceBy
      services {
        id
        serviceName
        servicePrice
      }
      createdDateTime
      password
      latitude
      longitude
      status
      agentId
    }
  }
`;

export const QUERY_GET_PATHOLOGY_BY_ID = gql`
  query GetPathologyById($pathologyId: ID) {
    getPathologyById(pathologyId: $pathologyId) {
      id
      nameOfCenter
      centerAddress
      villageName
      state
      district
      pincode
      contact
      email
      websiteLink
      ownerName
      bharpDiscount
      vendorMargin
      ownerContact
      ownerEmail
      pathologistDetail {
        name
        qualification
        experience
      }
      privateSectorCentralGov
      publicSectorOther
      privateSectorIndividual
      privateSectorOther
      registrationNo
      openingtime
      closingtime
      picture
      lisenceBy
      services {
        id
        serviceName
        servicePrice
      }
      createdDateTime
      password
      latitude
      longitude
      status
      agentId
      rejectStatus
      rejectComment
      termCondition
      slotDetails {
        id
        slotTimeInterval
        capacity
        status
      }
    }
  }
`;

export const QUERY_GET_RADIOLOGY_BY_ID = gql`
  query Query($radiologyId: ID) {
    getRadiologyById(radiologyId: $radiologyId) {
      id
      nameOfCenter
      centerAddress
      villageName
      state
      district
      pincode
      contact
      email
      websiteLink
      ownerName
      bharpDiscount
      vendorMargin
      ownerContact
      termCondition
      ownerEmail
      radiologistDetail {
        name
        qualification
        experience
        rpId
      }
      privateSectorCentralGov
      publicSectorOther
      privateSectorIndividual
      privateSectorOther
      registrationNo
      picture
      openingtime
      closingtime
      lisenceBy
      services {
        id
        serviceName
        servicePrice
      }
      createdDateTime
      password
      latitude
      longitude
      status
      agentId
      rejectStatus
      rejectComment
      slotDetails {
        id
        slotTimeInterval
        capacity
        status
      }
    }
  }
`;

export const QUERY_GET_HOPITAL_BY_ID = gql`
  query GetHospitalById($hospitalId: ID) {
    getHospitalById(hospitalId: $hospitalId) {
      id
      hospitalName
      hospitalType
      otherHospitalType
      otherHealthCareProvider
      hospitalHealthCareProviderType
      hospitalRegistrationNumber
      hospitalState
      hospitalCity
      hospitalBlock
      hospitalVillage
      hospitalDistrict
      hospitalAddress
      hospitalPincode
      hospitalEmail
      hospitalContact
      hospitalWebsiteLink
      hospitalEstablishmentYear
      password
      cPName
      cPDesignation
      cPEmail
      cPContact
      noOfMedicalConsultants
      noOfNurses
      noOfBeds
      noOfPrivateBeds
      noOfEmergencyBeds
      isEmergencyServiceAvailable
      isAmbulanceServiceAvailable
      hospitalCertificateImg
      hospitalLicenseImg
      beneficialServices
      hospitalImages
      hospitalGstCertificateImg
      hospitalLatitude
      hospitalLongitude
      totalDcoctor
      agentId
      createdDateTime
      status
      rejectStatus
      rejectComment
      addService {
        id
        serviceName
        servicePrice
      }
      doctorDetail {
        doctorName
        qualification
        experience
        speciality
        licenceNo
        ward
      }
    }
  }
`;
export const QUERY_GET_PENDING_AMBULANCE = gql`
  query GetPendingAmbulance {
    getPendingAmbulance {
      id
      ownerfName
      ownerlName
      ownerContact
      ownerEmail
      vehicleNo
      licenceNo
      licenceImg
      vehicleType
      driverFName
      driverlName
      driverContact
      driverEmail
      driverImg
      bharpDiscount
      vendorMargin
      emengencyContact
      ambulanceOwnerShip
      longitude
      latitude
      header
      agentId
      userName
      password
      vehicleTypeOther
      ambulanceOwnerShipOther
      localThresholderKm
      localBasePrice
      localPriceKm
      outLocalBasePrice
      outLocalPerKmPrice
      acAvailable
      acPerKmPrice
      createDateTime
      status
      rejectStatus
      termCondition
      otp
      rejectComment
      fcmToken
      vehicleImg
      subAdminId
      facility {
        id
        facilityName
        facilityPrice
      }
    
    }
  }
`;
export const QUERY_GET_ACTIVE_AMBULANCE = gql`
  query GetActiveAmbulance {
    getActiveAmbulance {
      id
      ownerfName
      ownerlName
      ownerContact
      ownerEmail
      vehicleNo
      licenceNo
      licenceImg
      vehicleType
      vehicleImg
      driverFName
      driverlName
      driverContact
      driverEmail
      driverImg
      bharpDiscount
      vendorMargin
      emengencyContact
      ambulanceOwnerShip
      longitude
      latitude
      header
      agentId
      userName
      password
      vehicleTypeOther
      ambulanceOwnerShipOther
      localThresholderKm
      localBasePrice
      localPriceKm
      outLocalBasePrice
      outLocalPerKmPrice
      acAvailable
      acPerKmPrice
      facility {
        id
        facilityName
        facilityPrice
      }
      createDateTime
      status
      rejectStatus
      termCondition
      otp
      rejectComment
      fcmToken
    }
  }
`;

export const QUERY_GET_REJECT_AMBULANCE = gql`
  query GetRejectAmbulance {
    getRejectAmbulance {
      id
      ownerfName
      ownerlName
      ownerContact
      ownerEmail
      vehicleNo
      licenceNo
      licenceImg
      vehicleType
      vehicleImg
      driverFName
      driverlName
      driverContact
      driverEmail
      driverImg
      emengencyContact
      ambulanceOwnerShip
      longitude
      latitude
      header
      agentId
      userName
      password
      createDateTime
      status
    }
  }
`;
export const QUERY_GET_PENDING_BLOODBANK = gql`
  query GetPendingBloodBank {
    getPendingBloodBank {
      id
      centerName
      centerContact
      centerEmail
      address
      city
      district
      state
      pincode
      picture
      nodalPersonFName
      nodalPersonLName
      nodalPersonContact
      nodalPersonEamil
      emergencyContact
      longitude
      latitude
      bloodBankType
      bloodBankOwnerShip
      bloodBankAPos {
        quantity
        available
      }
      bloodBankANeg {
        quantity
        available
      }
      bloodBankBPos {
        quantity
        available
      }
      bloodBankBNeg {
        quantity
        available
      }
      bloodBankOPos {
        quantity
        available
      }
      bloodBankONeg {
        quantity
        available
      }
      bloodBankABPos {
        quantity
        available
      }
      bloodBankABNeg {
        quantity
        available
      }
      agentId
      createDateTime
      status
    }
  }
`;
export const QUERY_GET_ACTIVE_BLOODBANK = gql`
  query GetActiveBloodBank {
    getActiveBloodBank {
      id
      centerName
      centerContact
      centerEmail
      address
      city
      district
      state
      pincode
      nodalPersonFName
      nodalPersonLName
      nodalPersonContact
      nodalPersonEamil
      emergencyContact
      longitude
      latitude
      bloodBankType
      bloodBankOwnerShip
      bloodBankAPos {
        quantity
        available
      }
      bloodBankANeg {
        quantity
        available
      }
      bloodBankBPos {
        quantity
        available
      }
      bloodBankBNeg {
        quantity
        available
      }
      bloodBankOPos {
        quantity
        available
      }
      bloodBankONeg {
        quantity
        available
      }
      bloodBankABPos {
        quantity
        available
      }
      bloodBankABNeg {
        quantity
        available
      }
      agentId
      createDateTime
      status
      registrationNo
      password
    }
  }
`;
export const QUERY_GET_REJECT_BLOODBANK = gql`
  query GetRejectBloodBank {
    getRejectBloodBank {
      id
      centerName
      centerContact
      centerEmail
      address
      city
      district
      state
      pincode
      nodalPersonFName
      nodalPersonLName
      nodalPersonContact
      nodalPersonEamil
      emergencyContact
      longitude
      latitude
      bloodBankType
      bloodBankOwnerShip
      bloodBankAPos {
        quantity
        available
      }
      bloodBankANeg {
        quantity
        available
      }
      bloodBankBPos {
        quantity
        available
      }
      bloodBankBNeg {
        quantity
        available
      }
      bloodBankOPos {
        quantity
        available
      }
      bloodBankONeg {
        quantity
        available
      }
      bloodBankABPos {
        quantity
        available
      }
      bloodBankABNeg {
        quantity
        available
      }
      agentId
      createDateTime
      status
    }
  }
`;
export const QUERY_GET_AMBULANCE_BY_ID = gql`
  query GetAmbulanceById($ambulanceId: ID) {
    getAmbulanceById(ambulanceId: $ambulanceId) {
      id
      ownerfName
      ownerlName
      ownerContact
      ownerEmail
      vehicleNo
      licenceNo
      licenceImg
      vehicleType
      vehicleImg
      driverFName
      driverlName
      driverContact
      driverEmail
      driverImg
      bharpDiscount
      vendorMargin
      emengencyContact
      ambulanceOwnerShip
      longitude
      latitude
      header
      agentId
      userName
      password
      vehicleTypeOther
      ambulanceOwnerShipOther
      localThresholderKm
      localBasePrice
      localPriceKm
      outLocalBasePrice
      outLocalPerKmPrice
      acAvailable
      acPerKmPrice
      createDateTime
      status
      rejectStatus
      termCondition
      otp
      rejectComment
      facility {
        id
        facilityName
        facilityPrice
      }
    }
  }
`;
export const QUERY_GET_BLOODBANK_BY_ID = gql`
  query GetBloodBankById($bloodBankId: ID) {
    getBloodBankById(bloodBankId: $bloodBankId) {
      id
      centerName
      centerContact
      centerEmail
      address
      city
      district
      state
      pincode
      picture
      nodalPersonFName
      nodalPersonLName
      nodalPersonContact
      nodalPersonEamil
      emergencyContact
      longitude
      latitude
      bloodBankType

      bloodBankOwnerShip

      bloodBankAPos {
        quantity
        available
      }
      bloodBankANeg {
        quantity
        available
      }
      bloodBankBPos {
        quantity
        available
      }
      bloodBankBNeg {
        quantity
        available
      }
      bloodBankOPos {
        quantity
        available
      }
      bloodBankONeg {
        quantity
        available
      }
      bloodBankABPos {
        quantity
        available
      }
      bloodBankABNeg {
        quantity
        available
      }
      agentId
      createDateTime
      status
      bloodBankTypeOther
      bloodBankOwnerShipOther
    }
  }
`;

export const QUERY_BLOOD_BANK_BY_ID = gql`
  query GetBloodBankById($bloodBankId: ID) {
    getBloodBankById(bloodBankId: $bloodBankId) {
      id
      centerName
      centerContact
      centerEmail
      address
      city
      district
      state
      pincode
      nodalPersonFName
      nodalPersonLName
      nodalPersonContact
      nodalPersonEamil
      emergencyContact
      longitude
      latitude
      bloodBankType
      bloodBankOwnerShip
      agentId
      password
      bloodBankTypeOther
      bloodBankOwnerShipOther
      registrationNo
      createDateTime
      status
      picture
      rejectStatus
      rejectComment
      bloodBankAPos {
        quantity
        available
        id
      }
      bloodBankANeg {
        quantity
        available
        id
      }
      bloodBankBPos {
        quantity
        available
        id
      }
      bloodBankBNeg {
        quantity
        available
        id
      }
      bloodBankOPos {
        quantity
        available
        id
      }
      bloodBankONeg {
        quantity
        available
        id
      }
      bloodBankABPos {
        quantity
        available
        id
      }
      bloodBankABNeg {
        quantity
        available
        id
      }
    }
  }
`;

export const QUERY_PATHOLOGY_BY_ID = gql`
  query GetPathologyById($pathologyId: ID) {
    getPathologyById(pathologyId: $pathologyId) {
      id
      nameOfCenter
      centerAddress
      villageName
      state
      district
      pincode
      contact
      email
      websiteLink
      ownerName
      ownerContact
      ownerEmail
      privateSectorCentralGov
      publicSectorOther
      privateSectorIndividual
      privateSectorOther
      registrationNo
      picture
      createdDateTime
      password
      latitude
      longitude
      status
      agentId
      rejectStatus
      termCondition
      rejectComment
      services {
        id
        serviceName
        servicePrice
      }
      lisenceBy
      pathologistDetail {
        name
        qualification
        experience
      }
    }
  }
`;

export const QUERY_RADIOLOGY_BY_ID = gql`
  query GetRadiologyById($radiologyId: ID) {
    getRadiologyById(radiologyId: $radiologyId) {
      id
      nameOfCenter
      centerAddress
      villageName
      state
      district
      pincode
      contact
      email
      websiteLink
      ownerName
      ownerContact
      ownerEmail
      radiologistDetail {
        name
        qualification
        experience
        rpId
      }
      privateSectorCentralGov
      publicSectorOther
      privateSectorIndividual
      privateSectorOther
      registrationNo
      picture
      lisenceBy
      services {
        id
        serviceName
        servicePrice
      }
      createdDateTime
      password
      latitude
      longitude
      status
      agentId
      rejectStatus
      rejectComment
      termCondition
    }
  }
`;

export const QUERY_GET_BOOKING_BY_RADIOLOGY_ID = gql`
  query GetRadiologyById($radiologyId: ID) {
    getRadiologyBookingByRadiologyId(radiologyId: $radiologyId) {
      id
      radiologyId
      readologyName
      userId
      userName
      userContact
      userEmail
      serviceName
      servicePrice
      paymentType
      paymentId
      startTime
      endTime
      appointmentDate
      createDateTime
      status
    }
  }
`;

export const QUERY_GET_RADIOLOGY_BOOKING_BY_ID = gql`
  query GetRadiologyBookingById($radiologyId: ID) {
    getRadiologyBookingById(RadiologyId: $radiologyId) {
      id
      radiologyId
      readologyName
      userId
      userName
      userContact
      userEmail
      serviceName
      servicePrice
      paymentType
      paymentId
      startTime
      endTime
      appointmentDate
      createDateTime
      status
    }
  }
`;

export const QUERY_GET_BOOKING_BY_PATHOLOGY_ID = gql`
  query GetPathologyBookingByPathologyId($pathologyId: ID) {
    getPathologyBookingByPathologyId(pathologyId: $pathologyId) {
      id
      pathologyId
      pathologyName
      userId
      userName
      userContact
      userEmail
      serviceName
      servicePrice
      paymentType
      paymentId
      startTime
      endTime
      appointmentDate
      createDateTime
      status
    }
  }
`;

export const QUERY_GET_PATHOLOGY_BOOKING_BY_ID = gql`
  query Query($pathologyId: ID) {
    getPathologyBookingById(pathologyId: $pathologyId) {
      id
      pathologyId
      pathologyName
      userId
      userName
      userContact
      userEmail
      serviceName
      servicePrice
      paymentType
      paymentId
      startTime
      endTime
      appointmentDate
      createDateTime
      status
    }
  }
`;
export const QUERY_GET_BOOKING_BY_HOSPTIAL_ID = gql`
  query GetHospitalBookingByHospitalId($hospitalId: ID) {
    getHospitalBookingByHospitalId(hospitalId: $hospitalId) {
      id
      hopsitalId
      hopsitalName
      userId
      userName
      userContact
      userEmail
      serviceName
      servicePrice
      paymentType
      paymentId
      startTime
      endTime
      appointmentDate
      createDateTime
      status
    }
  }
`;

export const QUERY_GET_BOOKING_BY_BLOOD_BANK_ID = gql`
  query Query($bloodBankId: ID) {
    getBloodBankBookingByBloodBankId(BloodBankId: $bloodBankId) {
      id
      bloodBankId
      bloodBankName
      userId
      userName
      userContact
      userEmail
      bloodGroup
      bloodQuantity
      bloodPrice
      paymentType
      paymentId
      startTime
      endTime
      appointmentDate
      createDateTime
      status
    }
  }
`;

export const QUERY_PATHOLOGY_SLOT_BY_PATHOLOGY_ID = gql`
  query GetPathologySlotByPathologyId($pathologyId: ID) {
    getPathologySlotByPathologyId(pathologyId: $pathologyId) {
      id
      pathologyid
      time
      slot
      createDate
      status
    }
  }
`;

export const QUERY_RADIOLOGY_SLOT_BY_RADIOLOGY_ID = gql`
  query Query($radiologyId: ID) {
    getRadiologySlotByRadiologyId(radiologyId: $radiologyId) {
      id
      radiologyid
      closeDay
      time
      slot
      createDate
      status
    }
  }
`;

export const QUERY_AMBULANCE_BY_ID = gql`
  query Query($ambulanceId: ID) {
    getAmbulanceById(ambulanceId: $ambulanceId) {
      id
      ownerfName
      ownerlName
      ownerContact
      ownerEmail
      vehicleNo
      licenceNo
      licenceImg
      vehicleType
      vehicleImg
      driverFName
      driverlName
      driverContact
      driverEmail
      driverImg
      bharpDiscount
      vendorMargin
      emengencyContact
      ambulanceOwnerShip
      longitude
      latitude
      header
      agentId
      userName
      password
      vehicleTypeOther
      ambulanceOwnerShipOther
      localThresholderKm
      localBasePrice
      localPriceKm
      outLocalBasePrice
      outLocalPerKmPrice
      acAvailable
      acPerKmPrice
      createDateTime
      status
      rejectStatus
      termCondition
      otp
      rejectComment
      facility {
        id
        facilityName
        facilityPrice
      }
    }
  }
`;

export const QUERY_GET_ALL_AMBULANCE_BOOKING = gql`
  query Query($ambulanceId: ID) {
    getAmbulanceBookingByAmbulanceId(ambulanceId: $ambulanceId) {
      id
      ambulanceId
      ambulanceBookingId
      ambulanceName
      userId
      userName
      userEmail
      userContact
      pickUpAddress
      userDiscount
      vendorDiscount
      userPrice
      vendorPrice
      localType
      pickUpAddressLongitude
      pickUpAddressLatitude
      dropAddress
      dropAddressLongitude
      dropAddressLatitude
      pickFullAddress
      dropFullAddress
      createDateTime
      distance
      pricePerKms
      status
      paymentType
      allservice {
        facilityId
        facilityName
        facilityPrice
      }
    }
  }
`;

export const QUERY_GET_SLOT_VENDOR_ID_SLOT_ID = gql`
  query GetSlotBookingByVendorIdSlotId($slotId: ID, $vendorId: ID) {
    getSlotBookingByVendorIdSlotId(slotId: $slotId, vendorId: $vendorId) {
      id
      venderId
      slotId
      slotTime
      serviceType
      userId
      userName
      userContact
      userEmail
      otpNumber
      referenceNo
      paymentType
      venderName
      userPrice
      vendorPrice
      userDiscount
      vendorDiscount
      totalAmount
      actualAmount
      discountAmount
      bookingDate
      paymentId
      createDateTime
      status
      service {
        serviceId
        serviceName
        servicePrice
      }
    }
  }
`;

export const QUERY_SEND_OTP = gql`
  query Query($contact: String!, $otp: String!) {
    sendLoginOtp(contact: $contact, otp: $otp)
  }
`;

export const QUERY_SLOT_BOOKING_BY_VENDOR_ID = gql`
  query Query($vendorId: ID) {
    getAllSlotBookingByVendorId(vendorId: $vendorId) {
      id
      venderId
      slotId
      slotTime
      serviceType
      userId
      userName
      userContact
      userEmail
      otpNumber
      referenceNo
      paymentType
      venderName
      userPrice
      vendorPrice
      userDiscount
      vendorDiscount
      totalAmount
      actualAmount
      discountAmount
      bookingDate
      paymentId
      createDateTime
      status
      service {
        serviceId
        serviceName
        servicePrice
      }
    }
  }
`;
export const QUERY_GET_AMBULANCE_ALL_BOOKINGS = gql`
  query GetAmbulanceAllBookings {
    getAmbulanceAllBookings {
      id
      ambulanceId
      ambulanceBookingId
      ambulanceName
      userId
      userName
      userEmail
      userContact
      pickUpAddress
      userDiscount
      vendorDiscount
      userPrice
      vendorPrice
      localType
      allservice {
        facilityId
        facilityName
        facilityPrice
      }
      pickUpAddressLongitude
      pickUpAddressLatitude
      dropAddress
      dropAddressLongitude
      dropAddressLatitude
      pickFullAddress
      dropFullAddress
      createDateTime
      distance
      pricePerKms
      acService
      isCancel
      cancelDateTime
      refundStatus
      refundMethod
      refundPercentage
      refundAmount
      status
      paymentType
    }
  }
`;
export const QUERY_USER_BY_ID = gql`
  query GetUserById($userId: ID) {
    getUserById(userId: $userId) {
      id
      fName
      lName
      contact
      email
      address
      city
      state
      district
      bankName
      pincode
      bankAccountNo
      bankAccountHolderName
      bankIfsc
      userImage
      contactVerify
      emailVerify
      deviceToken
      wallet
      paymentId
      paymentDate
      createDateTime
      status
    }
  }
`;

export const QUERY_ALL_USER = gql`
  query GetAllUser {
    getAllUser {
      id
      fName
      lName
      contact
      email
      address
      city
      state
      district
      bankName
      pincode
      bankAccountNo
      bankAccountHolderName
      bankIfsc
      userImage
      contactVerify
      emailVerify
      deviceToken
      wallet
      paymentId
      paymentDate
      ayushmanCardImg
      ayushmanCardNo
      ayushmanCardVerification
      nationalHealthCardImg
      nationalHealthCardNo
      nationalHealthCardVerification
      bplCardImg
      bplCardNo
      bplCardVerification
      createDateTime
      status
    }
  }
`;

export const QUERY_CANCEL_AMBULANCEBOOKING = gql`
  query GetCancelAmbulanceBooking {
    getCancelAmbulanceBooking {
      id
      ownerfName
      ownerlName
      ownerContact
      ownerEmail
      vehicleNo
      licenceNo
      licenceImg
      vehicleType
      vehicleImg
      driverFName
      driverlName
      driverContact
      driverEmail
      driverImg
      bharpDiscount
      vendorMargin
      emengencyContact
      ambulanceOwnerShip
      longitude
      latitude
      header
      agentId
      userName
      password
      vehicleTypeOther
      ambulanceOwnerShipOther
      localThresholderKm
      localBasePrice
      localPriceKm
      outLocalBasePrice
      outLocalPerKmPrice
      acAvailable
      acPerKmPrice
      createDateTime
      status
      rejectStatus
      termCondition
      otp
      rejectComment
      fcmToken
      facility {
        id
        facilityName
        facilityPrice
      }
    }
  }
`;
export const GET_USER_SOS = gql`
  query GetUserSos {
    getUserSos {
      id
      userId
      fName
      lName
      email
      contact
      createDateTime
      status
    }
  }
`;
export const GET_USER_SETTING = gql`
  query GetUserSetting {
    getUserSetting {
      adminId
      password
      createDateTime
      status
    }
  }
`;
export const GET_ALL_SUB_ADMIN = gql`
  query GetSubAdmin {
    getSubAdmin {
      id
      fName
      lName
      contact
      email
      password
      deviceToken
      createdDateTime
      status
    }
  }
`
;
