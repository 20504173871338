import { useQuery } from '@apollo/client'
import React from 'react'
import { Table, Spinner,Button,Modal } from "react-bootstrap"
import { QUERY_GET_ALL_AMBULANCE_BOOKING } from '../../graphql/queries'
import "../../ComponentCss/NewDashboard.css"
import { Bar, Pie } from "react-chartjs-2";
import { FaAlignJustify, FaHome, FaUsers, FaUsersCog, FaHeadset, FaKey, FaSignOutAlt ,FaExternalLinkAlt,FaInfoCircle,FaEye} from "react-icons/fa";
import { UserData } from '../SuperAdmin/Data';
import { Chart as ChartJS } from "chart.js/auto";
import { LinkContainer } from 'react-router-bootstrap'
import { useState } from 'react'

export default function AllAmbulanceBooking() {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let count = 1;

  const ambulanceId = localStorage.getItem("ambulanceId")

  setInterval(() => {
    refetch()
  }, 1000);

  const { data, loading, refetch } = useQuery(QUERY_GET_ALL_AMBULANCE_BOOKING, {
    variables: {
      "ambulanceId": `${ambulanceId}`
    },
    pollInterval:1000,
  })
 
  console.log("data",data)

  const[getService,setGetService] =useState([])

  console.log("getService",getService)

  function handleService(service){
    handleShow()
    setGetService(service)
  }


  function handleMenu() {

    let toggle = document.querySelector(".toggle");
let navigation = document.querySelector(".navigation");
let main = document.querySelector(".main");
    navigation.classList.toggle("active");
    main.classList.toggle("active");
};



  return (
    <>

<div >
                <div className="navigation">
                    <br></br> 
                  
                <span className="title" style={{ fontSize: 20, fontFamily: 'DM Sans', fontWeight: 'bold',color:"#fff",textAlign:"center",marginLeft:20,marginTop:20 }}>Dashboard</span>
                    <ul>
                        <br></br><br></br><br></br>
                       
                        <li>
                        <LinkContainer to="/">
                            <a href="#">
                                <span className="icon">

                                </span>
                                <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }} eventKey="first"><FaHome /> Home</span>
                              
                            </a>
                            </LinkContainer>
                        </li>
                        <li>
                        <LinkContainer to="/AmbulanceProfile">
                            <a href="#">
                                <span className="icon">
                                </span>
                                <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }}><FaUsers /> Profile</span>
                            </a>
                            </LinkContainer>
                        </li>
                        <li>
                        <LinkContainer to="/AllAmbulanceBooking">
                            <a href="#">
                                <span className="icon"> </span>
                                <span className="title" style={{ fontSize: 15, fontFamily: 'DM Sans' }}><FaExternalLinkAlt /> All Booking</span>
                            </a>
                            </LinkContainer>
                        </li>
                        <li>
                        <LinkContainer to="/Termcondition">
                            <a href="#">
                                <span className="icon"> </span>
                                <span className="title" style={{ fontSize: 12, fontFamily: 'DM Sans' }}><FaInfoCircle /> Terms & Condition</span>
                            </a>
                            </LinkContainer>
                        </li>
                       
                       
                    </ul>
                    
                </div>
                

                <div className="main">
                    <div className="topbar">
                        <div>
                            <FaAlignJustify onClick={() => handleMenu()}  className="toggle"/>
                        </div>
                        <div className="search">
                            <label>
                                <input type="text" placeholder="Search here" />
                            </label>
                        </div>
                        <div className="user">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/2048px-Circle-icons-profile.svg.png" alt="" />
                        </div>
                    </div>
                    {
        loading ?
          <div className='mx-auto d-block'>
            <Spinner animation="border" />;
          </div>

          :
          <Table striped bordered hover style={{ fontSize: 12, fontFamily: "Dm sans" }}>
            <thead>
              <tr>
                <th>S No.</th>
                <th>User name</th>
                <th>User email</th>
                <th>User Contact</th>
                <th>Pick Up location</th>
                <th>Drop Up location</th>
                <th>Local Type</th>
                <th>Service</th>
                <th>Distance</th>
                <th>Status</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {
                data && data.getAmbulanceBookingByAmbulanceId.slice().reverse().map(item => {
                  return (
                    <tr>
                      <td>{count++}</td>
                      <td>{item.userName}</td>
                      <td>{item.userEmail}</td>
                      <td>5744455</td>
                      <td>{item.pickUpAddress}</td>
                      <td>{item.dropAddress}</td>
                      <td>{item.localType}</td>
                      <td><Button onClick={()=>handleService(item.allservice)}><FaEye/></Button></td>
                      <td>{item.distance}</td>
                      <td>{item.status}</td>
                      <td>{item.pricePerKms}</td>
                    </tr>
                  )})
              }
            </tbody>
          </Table>
      }
            </div>
              
            </div>






            <Modal show={show} onHide={handleClose}>
              <h6 style={{textAlign:"center",marginTop:20,fontWeight:"bold"}}>User Selected Service</h6>
             
            <ul>
              {
                getService && getService.map(list=>{
                  return(
                    <li style={{color:"#000"}}>{list.facilityName}</li>
                  )
                })

              }
         
         </ul>

      </Modal>
   


    </>
  )
}
